import { Button, Checkbox, Fade, Menu, MenuItem } from "@mui/material";
import { Box } from "@mui/system";
import { useCallback, useMemo, useState } from "react";
import { Lists } from "../../../../../api/compute/Lists";
import { useBroadcast } from "../../../../../hooks/useBroadcast";
import { useEnvironment } from "../../../../../hooks/useEnvironment";
import { messageError, messageSuccess } from "../../../../../js/app/utils";
import { PanelForLists } from "../../../../PanelForLists/PanelForLists";
import CreateListDialog from "../../../../SecurityTooltip/CreateListDialog";
import { newGetSymbols } from "../../../../SecurityTooltip/SecurityTooltipCore";
import { TableV2 } from "../../TableCoreV2";

type AddToButtonProps = {
  tableInstance: TableV2;
  selectedRows: any[];
};

export function AddToButton({ tableInstance, selectedRows }: AddToButtonProps) {
  const [showPortfolioModal, setShowPortfolioModal] = useState(false);
  const [showBasketModal, setShowBasketModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [userCollections, setUserCollections] = useState<any>([]);
  const [panelList, setPanelList] = useState(false);

  const environment = useEnvironment();
  const { broadcast } = useBroadcast();
  const open = Boolean(anchorEl);

  const addToDisabled = useMemo(
    () => selectedRows.length === 0,
    [selectedRows.length]
  );

  const listsAPI = useMemo(
    () => new Lists(environment.get("setup")),
    [environment]
  );

  const handleClickAddTo = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      setAnchorEl(event.currentTarget);
    },
    []
  );

  const handleClose = useCallback((e) => {
    e.stopPropagation();
    setAnchorEl(null);
  }, []);

  const getUserCollections = useCallback(async () => {
    const userCollectionsIds = await listsAPI.getRaw();
    const userObjectsResponse = await listsAPI.portfolioFetch(
      userCollectionsIds,
      ["ownerId", "name", "type"]
    );

    const userId = environment.get("account")?.user?.id;

    const collections: any = [];
    let isSubscribed: any = null;

    for (const collection of userObjectsResponse) {
      isSubscribed = collection.ownerId !== userId;

      if (!isSubscribed) {
        collections.push({
          ...collection,
          type: collection.type === "BASKET" ? "Baskets" : "Portfolios",
        });
      }
    }

    setUserCollections(collections);
  }, [environment, listsAPI]);

  const onSelectAll = useCallback(() => {
    const table = tableInstance;

    if (table) {
      const rows = table.getRows();

      for (const row of rows) {
        row.select();
      }
    }
  }, [tableInstance]);

  const onDeselectAll = useCallback(() => {
    const table = tableInstance;

    if (table) {
      const rows = table.getRows();

      for (const row of rows) {
        row.deselect();
      }
    }
  }, [tableInstance]);

  const onCheckboxChange = useCallback(
    (evt, checked) => {
      if (checked === true) {
        onSelectAll();
      } else {
        onDeselectAll();
      }
    },
    [onDeselectAll, onSelectAll]
  );

  const onClickAddTo = useCallback(
    (e) => {
      handleClickAddTo(e);
    },
    [handleClickAddTo]
  );

  const addToActionsDispatch = useCallback(
    async (
      e,
      action: "createBasket" | "createPortfolio" | "selectExisting"
    ) => {
      switch (action) {
        case "createPortfolio": {
          setShowPortfolioModal(true);
          break;
        }

        case "createBasket": {
          setShowBasketModal(true);
          break;
        }

        case "selectExisting": {
          await getUserCollections();
          setPanelList(true);
          break;
        }
      }

      handleClose(e);
    },
    [getUserCollections, handleClose]
  );

  const addToHelper = useCallback(
    async (positions, response) => {
      const list = response;
      const newPositions = positions;

      list["positions"] = list["positions"].concat(newPositions);

      try {
        await listsAPI.update(list);

        //Use the non updated list to get name and type used to be sended to the topic
        const oldList = list;

        const message = {
          from: "Routes",
          content: {
            type: "success",
            text:
              "Item/s saved in " +
              oldList.type.toLowerCase() +
              " <strong>" +
              oldList.name +
              "</strong>.",
          },
        };

        const [channel, msg] = messageSuccess(message.content.text);
        broadcast(channel as string, msg);

        //********************** USAGE *************************
        var usage = window.App.usage;
        var info = {
          action: "ADD_TO",
          actionParam: list["id"],
          function: "MESSAGE",
        };
        usage.record(info);
        //********************** USAGE *************************
      } catch (error) {
        const [channel, msg] = messageError(
          `Failed to update <strong>${list.name}</strong>`
        );

        broadcast(channel as string, msg);
      }
    },
    [broadcast, listsAPI]
  );

  const onAddToExisting = useCallback(
    async (selectedId) => {
      const selectedCollection = userCollections.find(
        (item) => item.id === selectedId
      );
      const collectionType =
        selectedCollection.type === "Portfolios" ? "PORTFOLIO" : "BASKET";
      const response = await listsAPI.get(selectedId);
      var newPositions = newGetSymbols([...selectedRows], collectionType, true);
      await addToHelper(newPositions, response);
    },
    [addToHelper, listsAPI, selectedRows, userCollections]
  );

  const areAllSelected = useCallback(() => {
    const rowsNumber = tableInstance.getRows().length;

    if (rowsNumber === 0) {
      return false;
    }

    return rowsNumber === selectedRows.length;
  }, [selectedRows.length, tableInstance]);

  return (
    <Box display="flex" gap={1} alignItems="center">
      <PanelForLists
        showDialog={panelList}
        closeDialog={() => setPanelList(false)}
        list={userCollections}
        sectionsTag={["Portfolios", "Baskets"]}
        selectItem={onAddToExisting}
        headerTitle={"Collections"}
      />
      {showPortfolioModal && (
        <CreateListDialog
          showModal={showPortfolioModal}
          setShowModal={setShowPortfolioModal}
          security={selectedRows}
          environment={environment}
          type={"PORTFOLIO"}
        />
      )}

      {showBasketModal && (
        <CreateListDialog
          showModal={showBasketModal}
          setShowModal={setShowBasketModal}
          security={selectedRows}
          environment={environment}
          type={"BASKET"}
        />
      )}
      <Checkbox
        onChange={onCheckboxChange}
        size="small"
        sx={{ padding: "3px" }}
        checked={areAllSelected()}
      />
      <Button
        onClick={onClickAddTo}
        disabled={addToDisabled}
        sx={{ padding: "3px 5px", minHeight: 0, height: "auto" }}
        variant="contained"
        size="small"
      >
        Add to
      </Button>
      <Menu
        id="fade-menu"
        MenuListProps={{
          "aria-labelledby": "fade-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <MenuItem onClick={(e) => addToActionsDispatch(e, "createBasket")}>
          New Basket
        </MenuItem>
        <MenuItem onClick={(e) => addToActionsDispatch(e, "createPortfolio")}>
          New Portfolio
        </MenuItem>
        <MenuItem onClick={(e) => addToActionsDispatch(e, "selectExisting")}>
          Select an existing list
        </MenuItem>
      </Menu>
    </Box>
  );
}
