import { useMemo } from "react";
import { ColumnDefinition } from "tabulator-tables";
import { TableEventsV2 } from "../../../../../../../components/table/v2/TableCoreV2";
import { TrendratingTableV2 } from "../../../../../../../components/table/v2/TableV2";
import { useFormatter } from "../../../../../../../hooks/useFormatter";
import { Box } from "@mui/system";

type ComparisonTableWidgetTableProps = {
  name1: string;
  name2: string;
  rowClickHandler: (e, row) => void;
  title: string;
  value: any;
};

const weightFormatter = (cell, field, key) => {
  const data = cell.getData();

  if (key != null) {
    if (data[key] === "BASKET") {
      return '<span style="height: 10px; width: 10px; border-radius: 50%; background-color: #333; display: inline-block;"></span>';
    } else {
      return data[field] !== 0 ? `${(data[field] * 100).toFixed(2)}%` : "-";
    }
  }

  return data[field] !== 0 ? `${(data[field] * 100).toFixed(2)}%` : "-";
};

export function ComparisonTableWidgetTable({
  name1,
  name2,
  rowClickHandler,
  title,
  value,
}: ComparisonTableWidgetTableProps) {
  const formatter = useFormatter();

  const columns = useMemo<ColumnDefinition[]>(
    () => [
      {
        title: title,
        field: "segment",
        hozAlign: "left",
        widthGrow: 2,
        sorter: "string",
      },
      {
        title: `${name1}`,
        headerHozAlign: "center",
        columns: [
          {
            title: "TCR",
            headerHozAlign: "center",
            hozAlign: "center",
            field: "rate1",
            formatter: (cell) =>
              `<div style="display: flex; justify-content: flex-start; width: 50%; margin-left: 48%;">${formatter.tcr(
                cell.getData()["rate1"],
                "HTML"
              )}</div>`,
            sorter: "number",
          },
          {
            title: "Weight",
            field: "weight1",
            headerHozAlign: "center",
            hozAlign: "center",
            formatter: (cell) =>
              weightFormatter(cell, "weight1", "firstListType"),
            sorter: "number",
          },
        ],
      },
      {
        title: `${name2}`,
        headerHozAlign: "center",
        columns: [
          {
            title: "TCR",
            field: "rate2",
            headerHozAlign: "center",
            formatter: (cell) =>
              `<div style="display: flex; justify-content: flex-start; width: 50%; margin-left: 48%;">${formatter.tcr(
                cell.getData()["rate2"],
                "HTML"
              )}</div>`,
            sorter: "number",
          },

          {
            title: "Weight",
            field: "weight2",
            headerHozAlign: "center",
            hozAlign: "center",
            formatter: (cell) =>
              weightFormatter(cell, "weight2", "secondListType"),
            sorter: "number",
          },
        ],
      },
      {
        title: "Difference",
        field: "weightDiff",
        formatter: (cell) => weightFormatter(cell, "weightDiff", null),
        sorter: "number",
      },
    ],
    [formatter, name1, name2, title]
  );

  const tableOptions = useMemo(
    () => ({
      tableOption: {
        ajaxSorting: false,
        selectableRows: 1,
        columns: columns,
        data: value ?? [],
        defaultSort: { field: "weightDiff", direction: "desc" },
      },
    }),
    [columns, value]
  );

  const tableEvents: TableEventsV2 = useMemo(() => {
    return {
      rowClick: rowClickHandler,
    };
  }, [rowClickHandler]);

  return (
    <Box display={"flex"} flex={1} minHeight={0} minWidth={0}>
      <TrendratingTableV2
        tableOptions={tableOptions}
        tableEvents={tableEvents}
      />
    </Box>
  );
}
