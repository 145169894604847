import { CellComponent } from "tabulator-tables";
import { Formatter } from "../../../../trendrating/utils/Formatter";
import { AppEnvironment } from "../../../../types/Defaults";
import { TableHelperCommon } from "./TableHelperCommon";
import { Properties } from "../../../../api/Properties";
import { deepClone } from "../../../../deepClone";

export class ColumnsHelper extends TableHelperCommon {
  formatter: Formatter;
  flatProperties: any;

  constructor(environment: AppEnvironment) {
    super(environment);

    this.formatter = environment.formatter;

    const propertiesInstance = new Properties({
      properties: this.environment.properties,
    });
    this.flatProperties = {};

    for (const propertyGroup in propertiesInstance.properties) {
      for (const property in propertiesInstance.properties[propertyGroup]) {
        this.flatProperties[property] = deepClone(
          propertiesInstance.properties[propertyGroup][property]
        );
      }
    }
  }

  tabulatorColumnV2(col: { field: string } & Record<string, any>) {
    const preparedCol = { ...col };

    if (!("title" in col)) {
      preparedCol["title"] = this.flatProperties?.[col.field]?.name?.[0] ?? "";
    }

    if (!("formatter" in col) && this.flatProperties?.[col.field] != null) {
      preparedCol["formatter"] = (cell) => this.getFormatter(cell, col.field);
    }

    preparedCol["headerSortStartingDir"] = this.getSortInitialDirection(
      col.field,
      "security"
    );

    if (!("sorter" in preparedCol) || preparedCol.sorter == null) {
      preparedCol["sorter"] = () => 0;
    }

    return preparedCol;
  }

  getFormatter(cell: CellComponent, property) {
    if (property === "contribution") {
      return this.formatter.custom("bar", {
        options: {
          hasPositiveSign: true,
          isPercentage: true,
          notAvailable: {
            input: null,
            output: "",
          },
          width: "2.25em",
        },
        output: "HTML",
        value: (cell.getData() as any).contribution,
        valueHelper: {
          normalizationThreshold: cell.getData()["_s_normalizationThreshold"],
        },
      });
    }

    const cellData = cell.getData();

    try {
      if (property) {
        const value = this.formatter.table(property, "table", cellData);

        return value;
      }

      return "";
    } catch (error) {
      console.log(error);
      return cell.getValue();
    }
  }
}
