import {
  Box,
  Card,
  CardContent,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { useSearchParams } from "react-router-dom";
import { decodePeerId } from "../../../../../api/utils";
import { AlertsSelect } from "../../../../../components/AlertsSelect/AlertsSelect";
import RatingCheckbox from "../../../../../components/RatingCheckbox/RatingCheckbox";
import { Spinner } from "../../../../../components/Spinner/Spinner";
import { useTaxonomyByType } from "../../../../../hooks/useTaxonomyByType";
import { PeerDetailSegments } from "../detail/PeerDetail";
import InstrumentsTableForAnalytics from "../ETFs/widgets/ContentETFMarkets/widgets/InstrumentsTableForAnalytics/InstrumentsTableForAnalytics";
import { SecuritiesTable } from "../ETFs/widgets/ContentETFMarkets/widgets/SecuritiesView/SecuritiesTable/SecuritiesTable";

type SecuritiesTabDetailProps = {
  data: { children: any; securities: any };
  peerCardinality: number;
  nameFormatter: Function;
  segment: PeerDetailSegments;
  type: "ETF" | "Stock";
  dispatch: (action) => any;
  updateParams: (params) => void;
  isLoading: boolean;
  id: string;
};

const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);

export const SecuritiesTabDetail = forwardRef(
  (
    {
      data,
      peerCardinality,
      nameFormatter,
      segment,
      type,
      dispatch,
      updateParams,
      id,
      isLoading,
    }: SecuritiesTabDetailProps,
    ref
  ) => {
    const [searchParams] = useSearchParams();

    const configFromUrl = useMemo(() => {
      const settingsKey = searchParams.get("config");

      const setOfParams = {
        upgrades: {
          field: "alert",
          value: "upgrades_today",
        },
        upgrades_last_week: {
          field: "alert",
          value: "upgrades_last_5_days",
        },
        upgrades_last_month: {
          field: "alert",
          value: "upgrades_last_20_days",
        },
        downgrades: {
          field: "alert",
          value: "downgrades_today",
        },
        downgrades_last_week: {
          field: "alert",
          value: "downgrades_last_5_days",
        },
        downgrades_last_month: {
          field: "alert",
          value: "downgrades_last_20_days",
        },
        ab_perc: {
          field: "rating",
          value: { A: true, B: true, C: false, D: false },
        },
        cd_perc: {
          field: "rating",
          value: { A: false, B: false, C: true, D: true },
        },
      };

      if (settingsKey) {
        return setOfParams[settingsKey];
      }
    }, [searchParams]);

    const [rating, setRating] = useState(
      configFromUrl && configFromUrl?.field === "rating"
        ? configFromUrl?.value
        : {
            A: false,
            B: false,
            C: false,
            D: false,
          }
    );
    const [page, setPage] = useState(1);
    const [alert, setAlerts] = useState<
      | "Any"
      | "upgrades_today"
      | "upgrades_last_5_days"
      | "upgrades_last_20_days"
      | "upgrades_last_60_days"
      | "downgrades_today"
      | "downgrades_last_5_days"
      | "downgrades_last_20_days"
      | "downgrades_last_60_days"
      | "positive_movers"
      | "negative_movers"
      | null
    >(
      configFromUrl && configFromUrl?.field === "alert"
        ? configFromUrl?.value
        : "Any"
    );

    const [target, setTarget] = useState(id);
    const [sorter, setSorter] = useState({ field: "marketcap", rev: false });
    const tableRef = useRef<any>(null);
    const { taxonomiesMapX, taxonomiesMapY, rootNodeX, rootNodeY } =
      useTaxonomyByType(type === "ETF" ? "ETF" : "stock");

    // Update title label on peer change
    useEffect(() => {
      setTarget(id);
    }, [id]);

    const dataTotalCount = useMemo(() => {
      return data?.securities?.dataTotalCount ?? 0;
    }, [data?.securities?.dataTotalCount]);

    const title = useMemo(() => {
      const { where, what, zDimension } = decodePeerId(target);
      const whatName = taxonomiesMapY[what]?.name ?? what;
      const whereName = taxonomiesMapX[where]?.name ?? where;

      let name = "";

      if (whatName !== rootNodeY) {
        name = whereName;
      }

      if (whereName !== rootNodeX.name) {
        name += ` - ${whatName}`;
      }

      if (zDimension !== "microLarge") {
        const sizes = zDimension.split(/(?=[A-Z])/);
        const from = sizes[0]?.toLowerCase();
        const to = sizes[1]?.toLowerCase();

        name += ` - ${capitalize(from)}`;

        if (to != null) {
          name += ` - ${capitalize(to)} Cap`;
        } else {
          name += ` Cap`;
        }
      }

      if (name.length === 0) {
        name = "Any";
      }

      return name;
    }, [target, rootNodeX, rootNodeY, taxonomiesMapX, taxonomiesMapY]);

    const clearState = useCallback(() => {
      setRating({
        A: false,
        B: false,
        C: false,
        D: false,
      });
      setAlerts(null);
    }, []);

    useImperativeHandle(ref, () => {
      return {
        getSecuritiesTabParams: () => ({ page, alert, rating, sorter }),
        setSecuritiesTabParams: (value, field: "alert" | "rating" | "page") => {
          const callbacks = {
            alert: () => setAlerts(value),
            rating: () => setRating(value),
            page: () => setPage(value),
          };

          callbacks[field]();
        },
      };
    });

    const onChangeTabSettings = useCallback(
      (value, field: "page" | "rating" | "alert" | "sort") => {
        const settings: any = { page, rating, alert, sorter, id: target };
        settings[field] = value;

        switch (field) {
          case "alert": {
            setAlerts(value);
            settings["page"] = 1;
            setPage(1);

            break;
          }

          case "page": {
            setPage(value);

            break;
          }

          case "rating": {
            setRating(value);
            settings["page"] = 1;
            setPage(1);

            break;
          }

          case "sort": {
            settings["sorter"] = value;
            setPage(1);
            settings["page"] = 1;
            setSorter(value);
          }
        }

        updateParams(settings);
      },
      [page, rating, alert, sorter, target, updateParams]
    );

    const sortHandler = useCallback(
      (value) => {
        onChangeTabSettings(value, "sort");
      },
      [onChangeTabSettings]
    );

    const onTableRowClick = useCallback(
      (peerId) => {
        const params = {
          page,
          rating,
          alert,
          sorter: { field: "marketcap", rev: true },
        };
        setPage(1);
        params["page"] = 1;
        params["id"] = peerId;
        setTarget(peerId);

        updateParams(params);
      },
      [alert, page, rating, updateParams]
    );

    const onChangeUrlParams = useCallback(() => {
      const params = configFromUrl;

      if (params != null) {
        clearState();
        const settings: any = {
          page: 1,
          rating: null,
          alert: null,
          sorter: { field: "marketcap", rev: true },
          id: target,
        };

        switch (params.field) {
          case "alert": {
            setAlerts(params.value);
            setPage(1);
            settings["alert"] = params.value;
            settings["rating"] = {
              A: false,
              B: false,
              C: false,
              D: false,
            };

            break;
          }

          case "rating": {
            setRating(params.value);
            setPage(1);
            settings["alert"] = null;
            settings["rating"] = params.value;
          }
        }

        updateParams(settings);
      }
    }, [clearState, configFromUrl, target, updateParams]);

    useEffect(() => {
      onChangeUrlParams();
    }, [onChangeUrlParams]);

    return (
      <>
        <Box display={isLoading === true ? "block" : "none"} width={"100%"}>
          <SkeletonLoader />
        </Box>
        <Box
          mt={1}
          display={isLoading === false ? "flex" : "none"}
          flex={1}
          boxShadow={3}
          borderRadius={2}
          minWidth={0}
          minHeight={0}
          p={1}
        >
          {data?.securities ? (
            <>
              {peerCardinality > 0 ? (
                <Box
                  gap={1}
                  flex={1}
                  display={"flex"}
                  minHeight={0}
                  minWidth={0}
                >
                  <Box flex={1} display={"flex"} minHeight={0} minWidth={0}>
                    {/* Instruments Table */}
                    {data.children && data.children?.data?.length ? (
                      <InstrumentsTableForAnalytics
                        nameFormatter={nameFormatter}
                        segment={segment as any}
                        data={data}
                        type={type}
                        dispatch={dispatch}
                        paramsUpdate={onTableRowClick}
                      />
                    ) : (
                      <Card sx={{ display: "flex", flex: 1 }}>
                        <CardContent
                          sx={{
                            display: "flex",
                            flex: 1,
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <strong>
                            No {type} was found with those filters
                          </strong>
                        </CardContent>
                      </Card>
                    )}
                  </Box>
                  <Box
                    display={"flex"}
                    flex={3}
                    minHeight={0}
                    minWidth={0}
                    overflow={"hidden"}
                    flexDirection={"column"}
                  >
                    <Card
                      sx={{
                        marginBottom: "10px",
                        border: "1px solid #2a7090",
                        display: "flex",
                        minHeight: 0,
                        minWidth: 0,
                      }}
                    >
                      <CardContent
                        sx={{
                          paddingTop: "5px",
                          paddingBottom: "5px!important",
                          display: "flex",
                          alignItems: "center",
                          flex: 1,
                          minHeight: 0,
                          minWidth: 0,
                        }}
                      >
                        <Typography>{title}</Typography>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginLeft: "10px",
                          }}
                        >
                          <label
                            className="filter-bar__field-label"
                            style={{ color: "#2a7090" }}
                          >
                            Rating
                          </label>
                          <RatingCheckbox
                            stateGetter={(value) =>
                              onChangeTabSettings(value, "rating")
                            }
                            initState={rating}
                          />
                        </div>
                        <AlertsSelect
                          setValue={(value) =>
                            onChangeTabSettings(value, "alert")
                          }
                          initValue={alert}
                        />
                      </CardContent>
                    </Card>
                    {/* </Grid> */}
                    {/* Securities drilldown table */}
                    <Card
                      sx={{
                        display: "flex",
                        flex: 1,
                        minHeight: 0,
                        minWidth: 0,
                      }}
                    >
                      <CardContent
                        sx={{
                          display: "flex",
                          flex: 1,
                          flexDirection: "column",
                          minHeight: 0,
                          minWidth: 0,
                          height: "100%",
                        }}
                      >
                        <SecuritiesTable
                          dt={dataTotalCount}
                          currentSort={sorter}
                          type={type}
                          data={data?.securities}
                          ref={tableRef}
                          onSortChange={sortHandler}
                        />
                      </CardContent>
                    </Card>
                  </Box>
                </Box>
              ) : (
                <Card sx={{ flex: 1 }}>
                  <CardContent>
                    <Typography fontSize={"14px"} fontWeight={"bold"}>
                      Any {type} was found
                    </Typography>
                  </CardContent>
                </Card>
              )}
            </>
          ) : (
            <Card sx={{ flex: 1 }}>
              <CardContent>
                <div
                  style={{
                    flex: 1,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Spinner />
                </div>
              </CardContent>
            </Card>
          )}
        </Box>
      </>
    );
  }
);

const SkeletonLoader = () => {
  return (
    <Box p={1} mt={1} borderRadius={"4px"} boxShadow={3} display={"flex"}>
      <Box display={"flex"} flex={3} mr={1}>
        <Card sx={{ flex: 1 }}>
          <CardContent>
            <Stack spacing={1}>
              <Skeleton
                variant="text"
                sx={{
                  fontSize: "20px",
                  marginBottom: "20px",
                }}
              />

              {/* For other variants, adjust the size with `width` and `height` */}
              <Skeleton variant="rectangular" width={"100%"} height={30} />
              <Skeleton variant="rectangular" width={"100%"} height={30} />
              <Skeleton variant="rectangular" width={"100%"} height={30} />
              <Skeleton variant="rectangular" width={"100%"} height={30} />
            </Stack>
          </CardContent>
        </Card>
      </Box>
      <Box display={"flex"} flex={9}>
        <Card sx={{ flex: 1 }}>
          <CardContent>
            <Stack spacing={1}>
              <Skeleton
                variant="text"
                sx={{
                  fontSize: "20px",
                  marginBottom: "20px",
                }}
              />
              <Skeleton
                variant="text"
                sx={{
                  fontSize: "20px",
                  marginBottom: "20px",
                }}
              />

              {/* For other variants, adjust the size with `width` and `height` */}
              <Skeleton variant="rectangular" width={"100%"} height={30} />
              <Skeleton variant="rectangular" width={"100%"} height={30} />
              <Skeleton variant="rectangular" width={"100%"} height={30} />
              <Skeleton variant="rectangular" width={"100%"} height={30} />
              <Skeleton variant="rectangular" width={"100%"} height={30} />
              <Skeleton variant="rectangular" width={"100%"} height={30} />
            </Stack>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};
