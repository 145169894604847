import { Box, Tab, Tabs } from "@mui/material";

import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ColumnSet } from "../../../../../components/table/tableUtilities/ColumnSet";
import { deepClone } from "../../../../../deepClone";
import { useBroadcast } from "../../../../../hooks/useBroadcast";
import { useEnvironment } from "../../../../../hooks/useEnvironment";
import { useEventBus } from "../../../../../hooks/useEventBus";
import { useReport } from "../../../../../hooks/useReport";
import {
  ExportAction,
  ExportActionLoader,
  TableWrapper,
  WarningExportModal,
} from "../../../components/app-infrastructure/workflowBar/actions/export/Export";
import { DialogRelations } from "../../../components/app-infrastructure/workflowBar/actions/remove/Remove";
import { config } from "../../../config-ts";
import { PortfolioHomeStorage } from "../../../storage/PortfolioHomeStorage";
import { DialogSaveComponent } from "../../../ui/commons/DialogSave/DialogSaveComponent";
import { Basket } from "./Basket";
import { Portfolio } from "./Portfolio";
import { InputStubReport, _getDefaultColumns } from "./utils";

type Timeframe = "daily" | "weekly" | "monthly";

type TabPanelProps = {
  children?: React.ReactNode;
  index: number;
  value: number;
  sx?: any;
};

type PortfolioHomeContextType =
  | {
      timeframe: Timeframe;
    }
  | undefined;

const timeframeMap = { 1: "daily", 4: "weekly", 19: "monthly" };

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

// This is an API to model the data and pass them to The TableWrapper Class
export class InputStubExport {
  filter: any = null;
  list: any = null;
  tools: any = null;
  viewer: any = null;

  UNIVERSE_CARDINALITY = 100000;

  constructor(params) {
    this.filter = {
      get: (fakeParam) => {
        // fakeParam = value
        if ("filter" in params) {
          return params["filter"];
        }

        return {};
      },
    };

    if ("list" in params) {
      this.list = params["list"];

      if (this.list && this.list.hasOwnProperty("positions")) {
        this.UNIVERSE_CARDINALITY = this.list.positions.length;
      }
    }

    this.tools = {
      get: (property) => {
        switch (property) {
          case "page":
          default: {
            return {
              page: 1,
              rows: this.UNIVERSE_CARDINALITY,
            };
          }
        }
      },
      paginator: {
        get: (fakeParam) => {
          // fakeParam = value
          if ("total" in params) {
            return this.UNIVERSE_CARDINALITY;
          }

          return this.UNIVERSE_CARDINALITY;
        },
      },
      widgetColumnConfigurator: {
        get: (fakeParam) => {
          // fakeParam = value
          if ("tools" in params) {
            return params["tools"];
          }

          return { tableColumns: [] };
        },
      },
    };

    this.viewer = {
      get: (key) => {
        switch (key) {
          case "columns": {
            if ("viewer" in params && "columns" in params["viewer"]) {
              return params["viewer"]["columns"];
            }

            return { tableColumns: [] };
          }
          case "sortCriteria": {
            if ("viewer" in params && "sortCriteria" in params["viewer"]) {
              return params["viewer"]["sortCriteria"];
            }

            return {
              descending: true,
              property: "weight",
            };
          }

          // no default
        }
      },
    };
  }
}

export const PortfolioHomeContext =
  createContext<PortfolioHomeContextType>(undefined);

export function PortfolioHome() {
  const refContainer = useRef<HTMLDivElement>(null);
  const environment = useEnvironment();
  const configuration = useMemo(
    () => environment.get("account").product?.configuration,
    [environment]
  );
  const [timeframe, setTimeframe] = useState<Timeframe>(
    timeframeMap?.[configuration?.analysis_list?.defaultTimeFrame] ?? "daily"
  );

  //#region - setting tab title
  useEffect(() => {
    const account = environment.get("account");
    const configuration = account?.product?.configuration.analysis_list;
    let title = configuration.menu_label.toLowerCase();
    const titleCapitalized = title.charAt(0).toUpperCase() + title.slice(1);
    document.title = titleCapitalized;
  }, [environment]);
  //#endregion

  const [value, setValue] = useState(0);
  const [workflow, setWorkflow] = useState("s0");
  const [selectedList, setSelectedList] = useState<any>(undefined);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { on, remove, dispatch } = useEventBus();
  const [itemToSave, setItemToSave] = useState<any>(undefined);
  const [sort, setSort] = useState<
    | "name"
    | "name_rev"
    | "TCR"
    | "TCR_rev"
    | "CD"
    | "CD_rev"
    | "AB"
    | "AB_rev"
    | "upgrades"
    | "downgrades"
    | "movers_up"
    | "movers_down"
  >("TCR");
  const [exportWarning, setExportWarning] = useState(false);
  const [loadingDump, setLoadingDump] = useState(false);

  const storage = useMemo(
    () => new PortfolioHomeStorage(environment),
    [environment]
  );
  const preferences = useMemo(
    () => environment.get("preferences"),
    [environment]
  );
  const { broadcast } = useBroadcast();
  const viewType: "tile" | "table" = useMemo(() => {
    const viewFromPreferences = preferences.get("analysisList")?.["view"];
    const product = environment.get("account")["product"];
    const config = product?.configuration?.["analysis_list"];
    let fallbackCase = "tile";

    if (config && config.defaultHomePortfolioView) {
      fallbackCase = config.defaultHomePortfolioView;
    }

    return viewFromPreferences ?? fallbackCase;
  }, [environment, preferences]);
  const [viewAs, setViewAs] = useState(viewType);
  const [removeTarget, setRemoveTarget] = useState<any>();

  const showRelationsDialog = useMemo(
    () => removeTarget != null,
    [removeTarget]
  );

  const goToAnalyze = useCallback(
    (target) => {
      var list = target;
      var listId = list.id;
      var uriTemplate = `/app/analysis/lists/${listId}/analyze/`;

      //************************** USAGE *****************************
      var usage = window.App.usage;
      var info = {
        action: "LANDING",
        actionParam: listId,
        function: "PORTFOLIO_ANALYSIS",
      };
      usage.record(info);
      //************************** USAGE *****************************

      navigate(uriTemplate);
    },
    [navigate]
  );

  useEffect(() => {
    storage.invalidateCachedIds();
  }, [storage, value]);

  // Handle workflow bar
  useEffect(() => {
    const actions: any = [];
    let action: any = null;

    switch (workflow) {
      case "s1": {
        // portfolio selected: analyze
        action = {
          componentJSX: (
            <li
              className="menu__item"
              title={`Analyze ${selectedList?.name ?? ""}`}
              onClick={() => goToAnalyze(selectedList)}
            >
              {t("c_list_action_analyze")}
            </li>
          ),
        };

        actions.push(action);

        break;
      }
      case "s2": {
        // clean up workflow bar while loading data

        break;
      }
      case "s0":
      default: {
        // no portfolio selected: create action available
        action = {
          componentJSX: (
            <li
              className="menu__item"
              title={`Create a new ${t("c_list_portfolio").toLowerCase()}`}
              onClick={() => navigate("/app/analysis/lists/create/portfolio/")}
            >{`Create ${t("c_list_portfolio").toLowerCase()}`}</li>
          ),
        };

        actions.push(action);

        // also add basket creation
        action = {
          componentJSX: (
            <li
              className="menu__item"
              title={`Create a new ${t("c_list_basket").toLowerCase()}`}
              onClick={() => navigate("/app/analysis/lists/create/basket/")}
            >{`Create ${t("c_list_basket").toLowerCase()}`}</li>
          ),
        };

        actions.push(action);
      }
    }

    var message = {
      from: "PORTFOLIO_HOME",
      content: {
        actions: actions,
      },
    };

    broadcast(config["channels"]["workflow"]["input"], message);
  }, [broadcast, goToAnalyze, navigate, selectedList, t, workflow]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  // useResizer({ ref: refContainer });

  // Hide page loader
  useEffect(() => {
    document.getElementById("data-loader")?.classList.add("hide");
  }, []);

  const context: PortfolioHomeContextType = {
    timeframe: timeframe,
  };

  const goTo = useCallback(
    (list, action) => {
      const listId = list.id;
      let url = "/app";

      switch (action) {
        case "analyze": {
          url += `/analysis/lists/${listId}/analyze/`;

          break;
        }
        case "compare": {
          url += `/analysis/lists/${listId}/analyze/compare/`;

          break;
        }
        case "edit": {
          url += `/analysis/lists/${listId}/edit/`;

          break;
        }
        case "optimize": {
          url += `/analysis/lists/${listId}/optimize/`;

          break;
        }
        case "ranking": {
          url += `/analysis/lists/${listId}/analyze/ranking/`;

          break;
        } // no default
      }

      navigate(url);
    },
    [navigate]
  );

  const overview = useCallback(
    (list, event) => {
      event.stopPropagation();

      //************************** USAGE *****************************
      var usage = window.App.usage;
      var info = {
        action: "LANDING",
        actionParam: list.id,
        function: "PORTFOLIO_ANALYSIS",
      };
      usage.record(info);
      //************************** USAGE *****************************

      goTo(list, "analyze");
    },
    [goTo]
  );

  const compare = useCallback(
    (list, event) => {
      event.stopPropagation();

      //************************** USAGE *****************************
      var usage = window.App.usage;
      var info = {
        action: "LANDING",
        actionParam: list.id,
        function: "PORTFOLIO_COMPARE",
      };
      usage.record(info);
      //************************** USAGE *****************************

      goTo(list, "compare");
    },
    [goTo]
  );

  const edit = useCallback(
    (list, event) => {
      event.stopPropagation();

      //************************** USAGE *****************************
      var usage = window.App.usage;
      var info = {
        action: "LANDING",
        actionParam: list.id,
        function: "PORTFOLIO_EDIT",
      };
      usage.record(info);
      //************************** USAGE *****************************

      goTo(list, "edit");
    },
    [goTo]
  );

  const optimize = useCallback(
    (list, event) => {
      event.stopPropagation();

      //************************** USAGE *****************************
      var usage = window.App.usage;
      var info = {
        action: "LANDING",
        actionParam: list.id,
        function: "PORTFOLIO_OPTIMIZE",
      };
      usage.record(info);
      //************************** USAGE *****************************

      goTo(list, "optimize");
    },
    [goTo]
  );

  const ranking = useCallback(
    (list, event) => {
      event.stopPropagation();

      //************************** USAGE *****************************
      var usage = window.App.usage;
      var info = {
        action: "LANDING",
        actionParam: list.id,
        function: "PORTFOLIO_RANKING",
      };
      usage.record(info);
      //************************** USAGE *****************************

      goTo(list, "ranking");
    },
    [goTo]
  );

  const getDefaultColumns = useCallback(() => {
    const user = window.App.user;

    // default table columns (export and report)
    const _configuration = user["product"]["configuration"]["analysis_list"];
    const elements =
      _configuration["overview_tabs"][1]["widgets"]["viewer"]["table"];
    const properties = window.App.properties;
    const columnSetFactory = new ColumnSet({
      customConfiguration: null,
      elements: elements,
      properties: properties,
    });
    const availableColumns = columnSetFactory.generate("security");

    let column: any = null;
    const defaultColumns: any = [];
    for (let i = 0, length = availableColumns.length; i < length; i++) {
      column = availableColumns[i];
      if (column["selected"] === true) {
        defaultColumns.push(column);
      }
    }
    defaultColumns.sort(function (a, b) {
      if (a["order"] > b["order"]) {
        return 1;
      }
      if (a["order"] < b["order"]) {
        return -1;
      }
      return 0;
    });

    return defaultColumns;
  }, []);

  const http = useMemo(() => environment.get("http"), [environment]);
  const { directDoAction } = useReport({
    page: "analysisList",
  });

  // const [readyToDoAction, setReadyToDoAction] = useState(false);
  // useEffect(() => {
  //   if (readyToDoAction) {
  //     doAction();
  //     setReadyToDoAction(false);
  //   }
  // }, [doAction, readyToDoAction]);

  const reportDataPrep = useCallback(
    (target, rankingCache, strategyCache, page) => {
      const listType = target["type"];
      return http["portfolioTableTemplates"].get().then(async (response) => {
        var _columns: any[] = []; // raw columns
        var item: any = null;
        for (let i = 0, length = response.length; i < length; i++) {
          item = response[i];
          if (item["name"] === "DEFAULT_PORTFOLIO_SECURITY") {
            _columns = item["configuration"]["tableColumns"];
          }
        }
        if (_columns.length === 0) {
          _columns = _getDefaultColumns(environment, page);
        }

        var columns: any[] = [];
        for (let i = 0, length = _columns.length; i < length; i++) {
          item = _columns[i];
          columns.push({
            label: item["label"],
            property: item["field"],
          });
        }

        var holdings = new InputStubReport({
          columns: columns,
        });

        if (listType.toLowerCase() === "portfolio") {
          // ActionReportWithoutWysiwygState
          const valueToSet = {
            strategyCache: null,
            rankingCache: null,
            page: page,
            target: target,
            // uiState: uiState,
            title: target.name,
            widgets: {
              table: holdings,
              dispersion: undefined,
            },
          };
          directDoAction(valueToSet);
        } else {
          // ActionReportWysiwygState
          const valueToSet = {
            strategyCache: null,
            rankingCache: null,
            page: "dispersionBasketTab",
            target: target,
            title: target.name,
            // uiState: uiState,
            widgets: {
              table: holdings,
              dispersion: undefined,
            },
          };
          directDoAction(valueToSet);
        }

        // var list = target;
        // var report = new ActionReport({
        //     label: i18n["button_report"],
        //     page: this.page,
        //     rankingCache: rankingCache,
        //     strategyCache: strategyCache,
        //     target: list,
        //     title: string.substitute(i18n["title_report"], list),
        //     usage: this.usage,
        //     widgets: {
        //         table: holdings,
        //         dispersion: dispersionTab,
        //     },
        // });
        // report.doAction();
      });
    },
    [directDoAction, environment, http]
  );

  const reportAction = useCallback(
    async (list) => {
      const apiLists = environment.get("http")["lists"];
      const listAssetTypes = list["assetTypes"];
      apiLists.get(list["id"]).then((response) => {
        response["assetType"] = listAssetTypes;
        reportDataPrep(response, null, null, "analysisList");
      });
    },
    [environment, reportDataPrep]
  );

  const doActionExport = useCallback(async (actionParams) => {
    const action = new ExportAction(actionParams);

    var dataTotalCount = actionParams.widgets["table"].get("dataTotalCount");
    if (dataTotalCount > 3000) {
      setExportWarning(true);
    } else {
      setLoadingDump(true);

      try {
        await action.exportAction();
      } catch (error) {
        console.log(error);
      } finally {
        setLoadingDump(false);
      }
    }
  }, []);

  const exportAction = useCallback(
    async (list) => {
      const apiList = environment.get("http")["lists"];
      const apiTemplates = environment.get("http")["portfolioTableTemplates"];

      const promises = [apiList.get(list.id), apiTemplates.get()];
      const response = await Promise.all(promises);

      const listResponse = response[0];
      const templates = response[1];

      let configuration: any = null;
      let item: any = null;
      for (var i = 0, length = templates.length; i < length; i++) {
        item = templates[i];
        if (item["name"] === "DEFAULT_PORTFOLIO_SECURITY") {
          configuration = item["configuration"];
        }
      }

      if (configuration == null) {
        configuration = {
          tableColumns: getDefaultColumns(),
        };
      }

      var inputStubExport = new InputStubExport({
        list: listResponse,
        tools: configuration,
        viewer: {
          columns: configuration["tableColumns"],
        },
      });

      var fileName = list["name"] + ".csv";

      const actionExportParams = {
        fileName: fileName,
        list: listResponse,
        widgets: {
          table: new TableWrapper({
            filter: inputStubExport.filter,
            grid: inputStubExport.viewer,
            tools: inputStubExport.tools,
          }),
        },
        environment: environment.get("setup"),
      };

      doActionExport(actionExportParams);
    },
    [doActionExport, environment, getDefaultColumns]
  );

  const listenerActionRemove = useCallback(
    async (event) => {
      const list = event.value;
      window.App["user"]["data"].collections.invalidate();

      const promise = new Promise((resolve) => {
        resolve({
          data: null,
          status: "OK",
        });
      });

      let message: any = null;

      try {
        const response: any = await promise;

        if (response["status"] === "OK") {
          message = {
            from: "LISTS",
            content: {
              type: "success",
              text: `<strong>${list.name}</strong> has been deleted.`,
            },
          };
        }
      } catch (error) {
        message = {
          from: "LISTS",
          content: {
            type: "error",
            text: `Error deleting <strong>${list.name}</strong>.`,
          },
        };
      }

      broadcast(config["channels"]["feedback"]["input"], message);
      setRemoveTarget(undefined);
      // re-render the page in order to update the interface after list delete
      dispatch("refresh-view");
      setSelectedList(undefined);
    },
    [broadcast, dispatch]
  );

  const rename = useCallback(
    async (inputValue) => {
      const name = inputValue;
      const listTarget = deepClone(itemToSave);
      let message = {};
      const substitution = {
        nameSource: listTarget["name"],
        nameTarget: name,
      };

      if (listTarget) {
        try {
          const apiList = environment.get("http")["lists"];
          listTarget["name"] = name;

          // Update
          const listFromServer = await apiList.get(listTarget["id"]);
          listFromServer["name"] = listTarget["name"];

          await apiList.update(listFromServer);

          message = {
            from: "LISTS",
            content: {
              type: "success",
              text: `"<strong>${substitution.nameSource}</strong> has been renamed as <strong>${substitution.nameTarget}</strong>.",`,
            },
          };
        } catch (e) {
          message = {
            from: "LISTS",
            content: {
              type: "error",
              text: `"Error renaming <strong>${substitution.nameSource}</strong>. <strong>${substitution.nameTarget}</strong> already exists.`,
            },
          };
        }

        broadcast(config["channels"]["feedback"]["input"], message);
        // re-render the page in order to update the interface after list delete
        dispatch("refresh-view");
        setSelectedList(undefined);
        // Close the save dialog
        setItemToSave(undefined);
      }
    },
    [broadcast, dispatch, environment, itemToSave]
  );

  const duplicate = useCallback(
    async (list) => {
      const apiLists = environment.get("http")["lists"];
      let message: any = null;

      try {
        const targetList = await apiLists.get(list.id);
        targetList["name"] = targetList["name"] + " (copy)";
        delete targetList["id"];

        const cloned = await apiLists.add({ list: targetList });

        if (cloned["status"] === "OK") {
          message = {
            from: "LISTS",
            content: {
              type: "success",
              text: `<strong>${list.name}</strong> has been duplicated.`,
            },
          };
        }

        broadcast(config["channels"]["feedback"]["input"], message);
      } catch (e) {
        message = {
          from: "LISTS",
          content: {
            type: "error",
            text: `Error duplicating <strong>${list.name}</strong>.`,
          },
        };
      }

      // re-render the page in order to update the interface after list delete
      dispatch("refresh-view");
      setSelectedList(undefined);
    },
    [broadcast, dispatch, environment]
  );

  const onMenuActionClick = useCallback(
    (event) => {
      const action = event["detail"]["action"];
      const list = event["detail"]["list"];

      switch (action) {
        case "actionAnalyze": {
          overview(list, event);

          break;
        }
        case "actionCompare": {
          compare(list, event);

          break;
        }
        case "actionDelete": {
          setRemoveTarget(list);

          break;
        }
        case "actionDuplicate": {
          duplicate(list);

          break;
        }
        case "actionEdit": {
          edit(list, event);

          break;
        }
        case "actionExport": {
          exportAction(list);

          break;
        }
        case "actionOptimize": {
          optimize(list, event);

          break;
        }
        case "actionRank": {
          ranking(list, event);

          break;
        }
        case "actionRename": {
          setItemToSave(list);

          break;
        }
        case "actionReport": {
          reportAction(list);

          break;
        }

        // no default
      }
    },
    [
      compare,
      duplicate,
      edit,
      exportAction,
      optimize,
      overview,
      ranking,
      reportAction,
    ]
  );

  useEffect(() => {
    on("menu-click", onMenuActionClick);

    return () => remove("menu-click", onMenuActionClick);
  }, [on, onMenuActionClick, remove]);

  const toggleViewType = useCallback(() => {
    const view = viewAs === "table" ? "tile" : "table";
    setViewAs(view);
    setSelectedList(undefined);
  }, [viewAs]);

  const closeRelationDialog = useCallback(() => {
    setRemoveTarget(undefined);
  }, []);

  const closeExportModal = useCallback(() => {
    setExportWarning(false);
  }, []);

  return (
    <PortfolioHomeContext.Provider value={context}>
      <ExportActionLoader show={loadingDump} />
      <WarningExportModal
        show={exportWarning}
        onClose={closeExportModal}
        hasExportBtn={false}
      />
      {showRelationsDialog && (
        <DialogRelations
          close={closeRelationDialog}
          itemToDelete={removeTarget}
          onRemoveDone={listenerActionRemove}
          isUnsubscribe={false}
        />
      )}
      {itemToSave && (
        <DialogSaveComponent
          dialogType={"portfolio"}
          onRename={rename}
          item={itemToSave}
          onSave={null}
          onSaveAs={null}
          reset={undefined}
          hide={() => setItemToSave(undefined)}
        />
      )}{" "}
      <Box
        display={"flex"}
        ref={refContainer}
        flexDirection={"column"}
        overflow={"hidden"}
        height={"100%"}
      >
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="Portfolios tabs"
            sx={{
              minHeight: "0!important",

              "& .Mui-selected": {
                fontSize: "1vw",
                color: "#2a7090!important",
                borderBottomColor: "#2a7090!important",
                paddingTop: "5px!important",
              },
              "& .MuiTabs-indicator": {
                backgroundColor: "#2a7090!important",
              },
            }}
          >
            <Tab
              sx={{
                color: "black!important",
                fontSize: "1vw",
                paddingTop: "5px!important",
              }}
              label={"Portfolios"}
              {...a11yProps(0)}
            />
            <Tab
              sx={{
                color: "black!important",
                fontSize: "1vw",
                paddingTop: "5px!important",
              }}
              label={"Baskets"}
              {...a11yProps(1)}
            />
          </Tabs>
        </Box>
        <TabPanel
          sx={{ display: "flex", minHeight: 0, width: "100%", height: "100%" }}
          children={
            <Portfolio
              viewAs={viewAs}
              setTimeframe={setTimeframe}
              sort={sort}
              setSort={setSort}
              onChangeView={toggleViewType}
              setSelectedList={setSelectedList}
              selectedList={selectedList}
              updateWorkflow={setWorkflow}
              storage={storage}
            />
          }
          value={value}
          index={0}
        />

        <TabPanel
          value={value}
          index={1}
          sx={{ display: "flex", minHeight: 0, width: "100%", height: "100%" }}
        >
          <Basket
            setSort={setSort}
            sort={sort}
            setTimeframe={setTimeframe}
            setSelectedList={setSelectedList}
            selectedList={selectedList}
            updateWorkflow={setWorkflow}
            storage={storage}
          />
        </TabPanel>
      </Box>
    </PortfolioHomeContext.Provider>
  );
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, sx /*...other*/ } = props;

  // return (
  //   <div
  //     role="tabpanel"
  //     hidden={value !== index}
  //     id={`simple-tabpanel-${index}`}
  //     aria-labelledby={`simple-tab-${index}`}
  //     {...other}
  //     style={style}
  //   >
  //     {value === index && <Box>{children}</Box>}
  //   </div>
  // );

  return value === index ? <Box sx={sx}>{children}</Box> : null;
};
