import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@mui/material";
import {
  Fragment,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import { deepClone } from "../../../../../deepClone";
import { ErrorBoundary } from "../../../../../ErrorBoundary";
import { useBroadcast } from "../../../../../hooks/useBroadcast";
import { useEnvironment } from "../../../../../hooks/useEnvironment";
import { DialogSaveComponent } from "../../../../../js/app/ui/commons/DialogSave/DialogSaveComponent";
import { messageError, messageSuccess } from "../../../../../js/app/utils";
import List from "../../../../DragAndDropList/List";
import Modal from "../../../../Modal/Modal";
import { ColumnSet } from "../../../tableUtilities/ColumnSet";
import styles from "./TableColumnsEditor.module.scss";

export type TableColumnsEditorProps = {
  defaultTemplateNameBase:
    | "DEFAULT_PORTFOLIO"
    | "DEFAULT_PORTFOLIO_POINT_IN_TIME"
    | "DEFAULT_SCREENING"
    | "DEFAULT_SYSTEMATIC_PRODUCT_ALLOCATION"
    | "DEFAULT_SYSTEMATIC_PRODUCT_HOLDING"
    | "DEFAULT_PORTFOLIO_POINT_IN_TIME_ANALYZE_PERIOD";
  hasToSkipLastApplied: boolean;
  configurations: any;
  securityType: string;
  isSaveLastUsedConfigurationColumnsEnabled?: boolean;
  setTableColumns: (cols) => any;
  setInitCols?: (cols) => void;
};

type EditorContentBoxProps = {
  label: string;
  children: JSX.Element | JSX.Element[];
};

type ConfiguratorDialogProps = {
  show: boolean;
  handleShow: (show: boolean) => void;
  templateAPI?: any;
  defaultTemplateNameBase:
    | "DEFAULT_PORTFOLIO"
    | "DEFAULT_PORTFOLIO_POINT_IN_TIME"
    | "DEFAULT_SCREENING"
    | "DEFAULT_SYSTEMATIC_PRODUCT_ALLOCATION"
    | "DEFAULT_SYSTEMATIC_PRODUCT_HOLDING"
    | "DEFAULT_PORTFOLIO_POINT_IN_TIME_ANALYZE_PERIOD";
  hasToSkipLastApplied: boolean;
  configurations: any;
  isSaveLastUsedConfigurationColumnsEnabled?: boolean;
  securityType: string;
  setTableColumns: (cols) => any;
  setInitCols?: (cols) => void;
};

type CheckboxesColumnProps = {
  column: {
    label: string;
    children: {
      label: string;
      labelPanel: string;
      selected: boolean;
      tag: string;
      value: string;
    }[];
  };
  selectedValues: string[];
  onChangeValue: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
    value: string
  ) => void;
};

type TemplateEditorProps = {
  hasToSkipLastApplied: boolean;
  activeTemplateId?: string;
  loadTemplate: (tmpl) => void;
  valueEdited: boolean;
  showDialogSave: boolean;
  setShowDialogSave: (value: boolean) => void;
  listeners: {
    edit: {
      onSave: null | ((args?: any) => any);
      onRename: null | ((args?: any) => any);
      onSaveAs: null | ((args?: any) => any);
    };
    create: {
      onSave: null | ((args?: any) => any);
      onRename: null | ((args?: any) => any);
      onSaveAs: null | ((args?: any) => any);
    };
    delete: {
      delete: null | ((args?: any) => any);
    };
  };
  saveActionsEnabled?: boolean;
};

type SelectableColumnsProps = {
  columns: CheckboxesColumnProps["column"][];
  updateValue: (value: string[]) => void;
  initValue: string[];
};

type TableEditorContext = {
  configurations: any;
  securityType: string;
  defaultTemplateNameBase: string;
  templates: any;
  properties: any;
};

const TEMPLATE_NAME_DICT = {
  DEFAULT_PORTFOLIO: "portfolioTableTemplates",
  DEFAULT_PORTFOLIO_POINT_IN_TIME: "portfolioPointInTimeTableTemplates",
  DEFAULT_PORTFOLIO_POINT_IN_TIME_ANALYZE_PERIOD: "",
  DEFAULT_SCREENING: "screeningTableTemplates",
  DEFAULT_SYSTEMATIC_PRODUCT_ALLOCATION:
    "systematicProductAllocationTableTemplates",
  DEFAULT_SYSTEMATIC_PRODUCT_HOLDING: "systematicProductHoldingTableTemplates",
};

const TableConfiguratorContext = createContext<TableEditorContext>(undefined!);

export function TableColumnsEditor({
  defaultTemplateNameBase,
  hasToSkipLastApplied,
  configurations,
  isSaveLastUsedConfigurationColumnsEnabled = true,
  securityType,
  setTableColumns,
  setInitCols,
}: TableColumnsEditorProps) {
  const environment = useEnvironment();
  const httpAPI = useMemo(() => {
    return environment.get("http")[TEMPLATE_NAME_DICT[defaultTemplateNameBase]];
  }, [defaultTemplateNameBase, environment]);

  const [showEditor, setShowEditor] = useState(false);

  const openModal = useCallback(() => setShowEditor(true), []);
  return (
    <ErrorBoundary fallback={<></>}>
      <ConfiguratorDialog
        setInitCols={setInitCols}
        configurations={configurations}
        defaultTemplateNameBase={defaultTemplateNameBase}
        securityType={securityType}
        templateAPI={httpAPI}
        hasToSkipLastApplied={hasToSkipLastApplied}
        show={showEditor}
        handleShow={setShowEditor}
        isSaveLastUsedConfigurationColumnsEnabled={
          isSaveLastUsedConfigurationColumnsEnabled
        }
        setTableColumns={setTableColumns}
      />
      <div className="tableColumnConfigurator">
        <button
          className={styles.openDialogButton}
          title="Customize table fields"
          onClick={openModal}
        >
          <span className="i-edit"></span>
        </button>
      </div>
    </ErrorBoundary>
  );
}

const ConfiguratorDialog = ({
  show,
  handleShow,
  hasToSkipLastApplied,
  templateAPI,
  securityType,
  configurations,
  defaultTemplateNameBase,
  isSaveLastUsedConfigurationColumnsEnabled,
  setTableColumns,
  setInitCols,
}: ConfiguratorDialogProps) => {
  const closeEditor = useCallback(() => handleShow(false), [handleShow]);
  const [columns, setColumns] = useState<CheckboxesColumnProps["column"][]>([]);
  const [selectedFields, setSelectedFields] = useState<string[]>([]);
  const [valueChanged, setValueChanged] = useState(false);
  const [templates, setTemplates] = useState<any>();
  const [showDialogSave, setShowDialogSave] = useState(false);
  const [activeTemplate, setActiveTemplate] = useState();
  const [emptyError, setEmptyError] = useState(false);

  const dndRef = useRef<any>(null);

  const environment = useEnvironment();
  const properties = useMemo(() => {
    const propertiesDivided = environment.get("properties")?.properties;

    if (propertiesDivided) {
      return {
        ...propertiesDivided.etf,
        ...propertiesDivided.index,
        ...propertiesDivided.security,
        ...propertiesDivided.stock,
      };
    }

    return {};
  }, [environment]);

  const columnSetAPI = useMemo(
    () =>
      new ColumnSet({
        customConfiguration: null,
        elements: configurations,
        properties,
      }),
    [configurations, properties]
  );
  const { t } = useTranslation();

  const getDefaultTemplateName = useCallback(() => {
    const defaultTemplateName = defaultTemplateNameBase + "_" + securityType;

    return defaultTemplateName.toUpperCase();
  }, [defaultTemplateNameBase, securityType]);

  const getTemplates = useCallback(async () => {
    const userTemplates = templateAPI != null ? await templateAPI.get() : [];

    const columnSet = columnSetAPI.generate(securityType);
    const tableColumns: any = [];

    for (const column of columnSet) {
      if (column["selected"]) {
        tableColumns[column["order"]] = {
          field: column["field"],
          width: null,
        };
      }
    }

    const defaultTemplate = {
      configuration: {
        tableColumns: tableColumns,
        tableWidth: null,
      },
      id: "DEFAULT",
      name: t("default_screening"),
    };

    userTemplates.splice(0, 0, defaultTemplate);

    setTemplates(userTemplates);

    return userTemplates;
  }, [columnSetAPI, securityType, t, templateAPI]);

  const getValueFromTemplate = useCallback(() => {
    let selectedFields: string[] = [];

    if (activeTemplate) {
      const template = templates.find(
        (template) => template.id === activeTemplate
      );

      if (template) {
        const configuration = template.configuration;
        selectedFields = configuration.tableColumns.map((col) => col.field);
      }
    }

    return selectedFields;
  }, [activeTemplate, templates]);

  const getInitColsValue = useCallback(() => {
    if (templates && setInitCols) {
      let templateToLoad: any = null;
      const defaultTemplateName = `${defaultTemplateNameBase}_${securityType.toUpperCase()}`;
      const lastTemplateApplied = templates.find(
        (template) => template.name === defaultTemplateName
      );

      if (hasToSkipLastApplied || !lastTemplateApplied) {
        templateToLoad = templates.find(
          (template) => template.id === "DEFAULT"
        );
        templateToLoad.configuration.tableColumns =
          templateToLoad.configuration.tableColumns.map((col) => ({
            ...col,
            label: properties[col.field]?.name?.[0] ?? "",
          }));
      } else {
        templateToLoad = lastTemplateApplied;
      }

      const columns = templateToLoad?.configuration?.tableColumns ?? [];

      if (columns.length) {
        setInitCols(templateToLoad.configuration.tableColumns);
      } else {
        const defaultTemplate = templates.find(
          (template) => template.id === "DEFAULT"
        );
        defaultTemplate.configuration.tableColumns =
          defaultTemplate.configuration.tableColumns.map((col) => ({
            ...col,
            label: properties[col.field]?.name?.[0] ?? "",
          }));
        setInitCols(defaultTemplate.configuration.tableColumns);
      }
    }
  }, [
    defaultTemplateNameBase,
    hasToSkipLastApplied,
    properties,
    securityType,
    setInitCols,
    templates,
  ]);

  /**
   * Used to retrive default columns for table if columns are non explicity defined
   */
  useEffect(() => {
    getInitColsValue();
  }, [getInitColsValue]);

  useEffect(() => {
    getTemplates();

    return () => setTemplates(undefined);
  }, [getTemplates]);

  // It should run only once
  useEffect(() => {
    const selectedFields = getValueFromTemplate();

    setSelectedFields(selectedFields);

    return () => setSelectedFields([]);
  }, [getValueFromTemplate]);

  const updateSelectedColumns = useCallback((newValue: string[]) => {
    // const oldValue = getValueFromTemplate();
    const oldValue = dndRef.current.getState().map((item) => item.value);

    if (oldValue.length !== newValue.length) {
      setValueChanged(true);
    } else {
      let isChangedFromTemplate = false;

      for (const field of newValue) {
        if (!oldValue.includes(field)) {
          isChangedFromTemplate = true;
        }
      }

      setValueChanged(isChangedFromTemplate);
    }

    setSelectedFields(newValue);
  }, []);

  const prepareColumns = useCallback(
    (rawCols) => {
      if (configurations != null && properties != null) {
        var columnSet = new ColumnSet({
          properties: properties,
        });

        const _columns = rawCols;
        // data for columnSelect
        var columnsAvailable = columnSet.tagColumns(
          deepClone(_columns),
          properties
        );
        columnsAvailable = columnSet.groupByTag(columnsAvailable);
        // data for previewer
        let column: any;
        let columns: any = [];

        for (let i = 0, length = _columns.length; i < length; i++) {
          column = _columns[i];
          if (column["selected"]) {
            columns.push(deepClone(column));
          }
        }
      }

      return columnsAvailable;
    },
    [configurations, properties]
  );

  const generateColsFromFields = useCallback(
    (fields) => {
      var params = {
        customConfiguration: fields,
        elements: configurations,
        properties: properties,
      };
      var columnSet = new ColumnSet(params);
      const columns = columnSet.generate(securityType);

      return columns;
    },
    [configurations, properties, securityType]
  );

  const updateModel = useCallback(
    (customConfiguration) => {
      if (configurations != null && securityType != null) {
        const columns = generateColsFromFields(customConfiguration);

        setColumns(prepareColumns(columns));
      }
    },
    [configurations, generateColsFromFields, prepareColumns, securityType]
  );

  const listenerTemplateLoad = useCallback(
    (template) => {
      // if there's not any last applied template, so load default
      // for the current securityTpe
      if (template == null && hasToSkipLastApplied === false) {
        updateModel(null);
        return;
      }

      const columns: any =
        template == null ? [] : template["configuration"]["tableColumns"];

      var customConfiguration: any = [];
      for (let i = 0, length = columns.length; i < length; i++) {
        customConfiguration.push(columns[i]["field"]);
      }

      updateModel(customConfiguration);

      if (template) {
        setActiveTemplate(template.id);
      }
      setValueChanged(false);
    },
    [hasToSkipLastApplied, updateModel]
  );

  const filterSelectedCols = useCallback(
    (activeFields) => {
      const selectedCols = generateColsFromFields(activeFields);
      const orderList = activeFields;
      const orderedColumns: any[] = [];
      let orderIndex: number | null = null;

      for (const col of selectedCols) {
        if (col.selected === false) {
          continue;
        }

        orderIndex = orderList.indexOf(col.field);
        if (orderIndex != null && orderIndex >= 0) {
          orderedColumns[orderIndex] = col;
        }
      }

      return orderedColumns;
    },
    [generateColsFromFields]
  );

  const getSelectedColumns = useCallback(() => {
    const selected = dndRef?.current?.getState()?.map((col) => col.value) ?? [];
    return filterSelectedCols(selected);
  }, [filterSelectedCols]);

  const { broadcast } = useBroadcast();

  const actionSave = useCallback(
    async (name, id?, sendFeedBack = true) => {
      const valueToCols = getSelectedColumns();

      const tableColumns = valueToCols.map((selectedCol) => ({
        field: selectedCol.field,
        isPointInTime: selectedCol?.isPointInTime ?? null,
        label: selectedCol.label,
        sortable: selectedCol?.sortable ?? null,
        width: null,
        hasHistogram: selectedCol.hasHistogram ?? null,
      }));

      const templateToSave = {
        configuration: { tableColumns, tableWidth: 0 },
        id: id ?? null,
        name,
        type: null,
      };

      try {
        const savedTemplate = await templateAPI.save(templateToSave);
        if (sendFeedBack) {
          let [channel, msg] = messageSuccess(
            `<strong>${name}</strong> has been saved successfully`
          );

          broadcast(channel as string, msg);
        }

        // Refresh template list
        getTemplates();
        setActiveTemplate(savedTemplate.id);
      } catch (error) {
        if (sendFeedBack) {
          const [channel, msg] = messageError(
            `Error while saving <strong>${name}</strong>`
          );
          broadcast(channel as string, msg);
        }
      } finally {
        setShowDialogSave(false);
      }
    },
    [broadcast, getSelectedColumns, getTemplates, templateAPI]
  );

  const listenerSaveAsTemplate = useCallback(
    async (name) => {
      await actionSave(name);
    },
    [actionSave]
  );

  const listenerSaveTemplate = useCallback(async () => {
    const name =
      templates?.find((item) => item.id === activeTemplate)?.["name"] ?? null;
    const id = activeTemplate;

    if (name && id) {
      await actionSave(name, id);
    }
  }, [actionSave, activeTemplate, templates]);

  const listenerRenameTemplate = useCallback(
    async (name) => {
      if (name && activeTemplate) {
        actionSave(name, activeTemplate);
      }
    },
    [actionSave, activeTemplate]
  );

  const actionDelete = useCallback(
    async (template) => {
      if (template) {
        try {
          await templateAPI.remove(template);
          // messageSuccess()
          getTemplates();
          setActiveTemplate(undefined);

          const [channel, msg] = messageSuccess(
            `<strong>${template.name}</strong> has been deleted successfully`
          );

          broadcast(channel as string, msg);
        } catch (error) {
          const [channel, msg] = messageSuccess(
            `An error occured while removing <strong>${template.name}</strong>`
          );

          broadcast(channel as string, msg);
        }
      }
    },
    [broadcast, getTemplates, templateAPI]
  );

  const actionAvailable = useMemo(
    () => ({
      edit: {
        onSave: listenerSaveTemplate,
        onSaveAs: null,
        onRename: listenerRenameTemplate,
      },
      create: {
        onSave: null,
        onSaveAs: listenerSaveAsTemplate,
        onRename: null,
      },
      delete: {
        delete: actionDelete,
      },
    }),
    [
      actionDelete,
      listenerRenameTemplate,
      listenerSaveAsTemplate,
      listenerSaveTemplate,
    ]
  );

  // const dragAndDropPreviewerValue = useMemo(() => {
  //   const selectedCols = filterSelectedCols(selectedFields);

  //   return selectedCols.map((col) => ({ field: col.field, label: col.label }));
  // }, [filterSelectedCols, selectedFields]);

  const dndData = useMemo(() => {
    const selectedCols = filterSelectedCols(selectedFields);

    return selectedCols.map((col) => ({
      value: col.field,
      content: (
        <Box
          sx={{ bgcolor: "white" }}
          border={"solid 1px #2a7092"}
          p={1}
          borderRadius={1}
          flex={1}
          whiteSpace={"nowrap"}
          overflow={"hidden"}
          textOverflow={"ellipsis"}
          title={col.label}
        >
          <Typography>{col.label}</Typography>
        </Box>
      ),
    }));
  }, [filterSelectedCols, selectedFields]);

  const onClickApply = useCallback(async () => {
    let templateId = null;
    const valueToCols = getSelectedColumns();

    const tableColumns = valueToCols.map((selectedCol) => ({
      field: selectedCol.field,
      isPointInTime: selectedCol?.isPointInTime ?? null,
      label: selectedCol.label,
      sortable: selectedCol?.sortable ?? null,
      width: null,
      hasHistogram: selectedCol.hasHistogram ?? null,
    }));

    if (!tableColumns.length) {
      setEmptyError(true);
      return;
    }

    setEmptyError(false);

    const configuration = { tableColumns, tableWidth: 0 };

    const defaultTemplateName = getDefaultTemplateName();

    const lastTemplateApplied = templates.find(
      (template) => template.name === defaultTemplateName
    );

    if (lastTemplateApplied) {
      templateId = lastTemplateApplied["id"];
    }

    if (isSaveLastUsedConfigurationColumnsEnabled === true) {
      setTableColumns(configuration.tableColumns);
      const sendFeedBack = false;
      try {
        await actionSave(defaultTemplateName, templateId, sendFeedBack);
      } catch (error) {
        console.error(error);
      }
    } else {
      setTableColumns(configuration.tableColumns);
    }

    handleShow(false);
  }, [
    actionSave,
    getDefaultTemplateName,
    getSelectedColumns,
    handleShow,
    isSaveLastUsedConfigurationColumnsEnabled,
    setTableColumns,
    templates,
  ]);

  const modalBtns = useMemo(
    () => [
      { name: "Apply", callback: onClickApply },
      { name: "Cancel", callback: closeEditor, variant: "cancel" },
    ],
    [closeEditor, onClickApply]
  );

  const onDndListChange = useCallback(
    (e) => {
      const fields = e.map((item) => item.value);
      updateSelectedColumns(fields);
    },
    [updateSelectedColumns]
  );

  return show ? (
    <TableConfiguratorContext.Provider
      value={{
        properties,
        configurations,
        templates,
        securityType,
        defaultTemplateNameBase,
      }}
    >
      <Modal
        headerConfig={{
          headerContent: "Configure table",
          hasBackground: true,
        }}
        customCss={{ maxWidth: "80vw", minWidth: "auto" }}
        onClose={closeEditor}
        buttonsEnalbed
        buttons={modalBtns}
        closeIcon={false}
      >
        {emptyError === true ? (
          <Typography sx={{ color: "red", marginBottom: "8px" }}>
            Please select at least one column
          </Typography>
        ) : (
          <></>
        )}
        <Box className={styles.modalContentBox} mb={1}>
          <TemplateEditor
            saveActionsEnabled={templateAPI != null}
            hasToSkipLastApplied={hasToSkipLastApplied}
            activeTemplateId={activeTemplate}
            showDialogSave={showDialogSave}
            setShowDialogSave={setShowDialogSave}
            listeners={actionAvailable}
            valueEdited={valueChanged}
            loadTemplate={listenerTemplateLoad}
          />
          <SelectableColumns
            updateValue={updateSelectedColumns}
            columns={columns}
            initValue={selectedFields}
          />
        </Box>
        {/* <Box>
          <Card sx={{ boxShadow: 3 }}>
            <CardContent sx={{ pb: "16px !important" }}>
              <Typography>Drag and drop column to change order.</Typography>
              <DnD
                ref={dndRef}
                direction={"horizontal"}
                listContentObjBuilder={dragAndDropPreviewerValue}
              />
            </CardContent>
          </Card>
        </Box> */}
        <Box>
          <Card sx={{ boxShadow: 3 }}>
            <CardContent>
              <Typography>Drag and drop column to change order.</Typography>
              <List
                ref={dndRef}
                setState={(e) => onDndListChange(e)}
                content={dndData}
                orientation={"horizontal"}
              />
            </CardContent>
          </Card>
        </Box>
      </Modal>
    </TableConfiguratorContext.Provider>
  ) : (
    <></>
  );
};

const TemplateEditor = ({
  loadTemplate,
  valueEdited,
  listeners,
  showDialogSave,
  setShowDialogSave,
  activeTemplateId,
  hasToSkipLastApplied,
  saveActionsEnabled = true,
}: TemplateEditorProps) => {
  const { templates, defaultTemplateNameBase, securityType } = useContext(
    TableConfiguratorContext
  );

  const [hasToLoadLastApplied, setHasToLoadLastApplied] = useState(
    !hasToSkipLastApplied
  );
  const [action, setAction] = useState<"edit" | "create">("create");
  const [hasFinishLoad, setHasFinishLoad] = useState(false);

  const loadDefault = useCallback(() => {
    if (templates == null) {
      return;
    }

    const defaultTemplateId = `DEFAULT`;
    const lastTemplate = templates.find(
      (template) => template.id === defaultTemplateId
    );

    if (lastTemplate) {
      const templateToLoad = {
        configuration: deepClone(lastTemplate.configuration),
        id: lastTemplate.id,
        name: lastTemplate.name,
        type: lastTemplate.type,
      };

      loadTemplate(templateToLoad);
    } else {
      loadTemplate(null);
    }

    // First render is gone now don't reload last apply on each re-render
    setHasToLoadLastApplied(false);
  }, [loadTemplate, templates]);

  const loadLastApplied = useCallback(() => {
    if (templates == null) {
      return;
    }

    const defaultTemplateName = `${defaultTemplateNameBase}_${securityType.toUpperCase()}`;
    const lastTemplate = templates.find(
      (template) => template.name === defaultTemplateName
    );

    if (lastTemplate) {
      const templateToLoad = {
        configuration: deepClone(lastTemplate.configuration),
        id: lastTemplate.id,
        name: lastTemplate.name,
        type: lastTemplate.type,
      };

      loadTemplate(templateToLoad);
    } else {
      loadDefault();
    }

    // First render is gone now don't reload last apply on each re-render
    setHasToLoadLastApplied(false);
  }, [
    defaultTemplateNameBase,
    loadTemplate,
    securityType,
    templates,
    loadDefault,
  ]);

  const startup = useCallback(() => {
    if (!hasFinishLoad) {
      if (hasToLoadLastApplied) {
        loadLastApplied();
      } else {
        loadDefault();
      }

      setHasFinishLoad(true);
    }
  }, [hasFinishLoad, hasToLoadLastApplied, loadDefault, loadLastApplied]);

  useEffect(() => {
    startup();
  }, [startup]);

  const templateNamePattern = useMemo(
    () => new RegExp("^((?!" + defaultTemplateNameBase + "_).)*$", "i"),
    [defaultTemplateNameBase]
  );

  const handleSaveBtnClick = useCallback(() => {
    setAction("create");
    setShowDialogSave(true);
  }, [setShowDialogSave]);

  const handleEditBtnClick = useCallback(
    (e) => {
      e.stopPropagation();
      setAction("edit");
      setShowDialogSave(true);
    },
    [setShowDialogSave]
  );

  const handleDeleteBtnClick = useCallback(
    (e, template) => {
      e.stopPropagation();

      if (listeners["delete"].delete) {
        listeners["delete"].delete(template);
      }
    },
    [listeners]
  );

  return (
    <Box className={styles.templateCol}>
      <EditorContentBox label={"Configurations"}>
        <ul className={styles.templateNamesList}>
          {templates.map((template) => {
            if (templateNamePattern.test(template.name)) {
              return (
                <li
                  key={uuidv4()}
                  onClick={() => loadTemplate(template)}
                  className={`${styles.templateNamesListItem} ${
                    activeTemplateId && activeTemplateId === template.id
                      ? styles.activeTemplateItem
                      : ""
                  }`}
                >
                  <Box>
                    <Typography>{template.name}</Typography>
                  </Box>
                  {template.id !== "DEFAULT" && saveActionsEnabled && (
                    <Box className={styles.templateNamesListItemActions}>
                      <span
                        className="i-edit"
                        onClick={handleEditBtnClick}
                      ></span>
                      <span
                        className="i-delete"
                        onClick={(e) => handleDeleteBtnClick(e, template)}
                      ></span>
                    </Box>
                  )}
                </li>
              );
            } else {
              return <Fragment key={uuidv4()} />;
            }
          })}
        </ul>
      </EditorContentBox>
      {valueEdited && saveActionsEnabled ? (
        <Box display={"flex"} justifyContent={"flex-end"} mt={1}>
          <Button onClick={handleSaveBtnClick} variant="contained">
            Save as new
          </Button>
        </Box>
      ) : (
        <></>
      )}
      {showDialogSave && (
        <DialogSaveComponent
          hide={() => setShowDialogSave(false)}
          item={{ name: "" }}
          dialogType="Table configuration"
          onSave={listeners[action].onSave}
          onSaveAs={listeners[action].onSaveAs}
          onRename={listeners[action].onRename}
        />
      )}{" "}
    </Box>
  );
};

const SelectableColumns = ({
  columns,
  updateValue,
  initValue,
}: SelectableColumnsProps) => {
  const [activeFilters, setActiveFilters] = useState<string[]>([]);

  useEffect(() => {
    setActiveFilters(initValue);
  }, [initValue]);

  const onCheckboxChange = useCallback(
    (
      event: React.ChangeEvent<HTMLInputElement>,
      checked: boolean,
      value: string
    ) => {
      if (checked) {
        setActiveFilters((prev) => {
          const newState = [...prev];

          newState.push(value);

          updateValue(newState);
          return newState;
        });
      } else {
        setActiveFilters((prev) => {
          const newState = [...prev];

          const state = newState.filter((values) => values !== value);

          updateValue(state);
          return state;
        });
      }
    },
    [updateValue]
  );

  return (
    <Box className={styles.checkboxesCol}>
      <EditorContentBox label={"Available fields"}>
        <Box className={styles.columnsAvailableBox}>
          {columns &&
            columns.map((col) => (
              <ChecboxesColumn
                key={uuidv4()}
                selectedValues={activeFilters}
                onChangeValue={onCheckboxChange}
                column={col}
              />
            ))}
        </Box>
      </EditorContentBox>
    </Box>
  );
};

const EditorContentBox = ({ children, label }: EditorContentBoxProps) => {
  return (
    <>
      <Card>
        <CardContent className={styles.editorContentCardContent}>
          <Box className={styles.editorContentBoxHeader}>{label}</Box>
          <Box className={styles.editorContentBoxContent}>{children}</Box>
        </CardContent>
      </Card>
    </>
  );
};

const ChecboxesColumn = ({
  column,
  selectedValues,
  onChangeValue,
}: CheckboxesColumnProps) => {
  return (
    <Box className={styles.checkboxGroupWrapper}>
      <Typography>{column.label}</Typography>
      <FormGroup className={styles.checkboxGroup}>
        {column.children.map((field) => (
          <FormControlLabel
            key={uuidv4()}
            control={
              <Checkbox
                size="small"
                checked={selectedValues.includes(field.value)}
                onChange={(e, checked) =>
                  onChangeValue(e, checked, field.value)
                }
              />
            }
            label={field.labelPanel}
          />
        ))}
      </FormGroup>
    </Box>
  );
};
