import { Box, Card, CardContent, Skeleton, Stack } from "@mui/material";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { decodePeerId, encodePeerId } from "../../../../../api/utils";
import { useEnvironment } from "../../../../../hooks/useEnvironment";
import { useEventBus } from "../../../../../hooks/useEventBus";
import { useFormatter } from "../../../../../hooks/useFormatter";
import { useTaxonomyByType } from "../../../../../hooks/useTaxonomyByType";
import { config } from "../../../config-ts";
import { PeerDetailStorage } from "../../../storage/PeerDetailStorage";
import { PeerInfoRow } from "../ETFs/widgets/ContentETFMarkets/ContentETFMarkets";
import MarketsETFTableTCR from "../ETFs/widgets/ContentETFMarkets/widgets/MarketsETFTableTCR/MarketsETFTableTCR";
import { RatingHistoryView } from "../ETFs/widgets/ContentETFMarkets/widgets/RatingHistoryView/RatingHistoryView";
import { DispersionByTabDetail } from "../widgets/DispersionByTabDetail";
import { DispersionTabDetail } from "../widgets/DispersionTabDetail";
import { PerformanceSinceTrendTab } from "../widgets/PerformanceSinceTrend/PerformanceSinceTrendTab";
import { SecuritiesTabDetail } from "../widgets/SecuritiesTabDetail";
import { HeaderBar } from "./widgets/HeaderBar";

// Workflow actions import
import { useBroadcast } from "../../../../../hooks/useBroadcast";
import ReportButton from "../../../widgets/app-infrastructure/workflowBar/actions/report/ReportButton";
import { DirectDispersionReport } from "../actions/DirectDispersionReport";

export type PeerDetailTabs =
  | "TCR"
  | "history"
  | "dispersion"
  | "securities"
  | "dispersionBy"
  | "performanceSinceRated";

export type PeerDetailSegments =
  | "1 Industry"
  | "3 Sector"
  | "Region"
  | "Area"
  | "Country"
  | "3 Level"
  | "4 Subsector";

export type PeerDetailTimeframe = "today" | "lastWeek" | "lastMonth";

const timeframeToNum = (timeframe: PeerDetailTimeframe) => {
  switch (timeframe) {
    case "today":
    default:
      return 0;

    case "lastWeek":
      return 4;

    case "lastMonth":
      return 19;
  }
};

const initialState = {
  history: undefined,
  dispersion: undefined,
  securities: undefined,
  dispersionBy: undefined,
  performanceSinceRating: undefined,
};

const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);

const TABS_NAME_DICT = {
  TCR: "TCR",
  dispersion: "dispersion",
  dispersionBySector: "dispersionBy",
  performanceSinceRated: "performanceSinceRated",
  ratingHistory: "history",
  securities: "securities",
};

export function PeerDetail() {
  const urlParams: { id?: string; constraintsMnemonic?: PeerDetailTimeframe } =
    useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const viewRef = useRef<HTMLDivElement>(null);
  const [peer, setPeer] = useState<any>();
  const [children, setChildren] = useState<any>();
  const [timeframe, setTimeframe] = useState<PeerDetailTimeframe>("lastMonth");
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingContent, setIsLoadingContent] = useState(true);
  const [dataForCurrentView, setDataForCurrentView] = useState<{
    history: any;
    dispersion: any;
    securities: any;
    dispersionBy: any;
    performanceSinceRating: any;
  }>(initialState);
  const [segment, setSegment] = useState<PeerDetailSegments>("1 Industry");

  // Handle tab init state to start with the first tab in configuraiton
  const environment = useEnvironment();
  const product = useMemo(
    () =>
      environment.get("account")["product"]["configuration"]["analysis_peer"],
    [environment]
  );
  const tabsAvailable = useMemo(() => product?.tabOrder, [product?.tabOrder]);
  const isSecurityTabEnabled = useMemo(() => {
    const tabsConfig = product.tabs["Stocks"];
    return tabsConfig?.securities?.enabled ?? false;
  }, [product]);

  const [tab, setTab] = useState<PeerDetailTabs>(
    (searchParams.get("tab") as PeerDetailTabs) ??
      TABS_NAME_DICT?.[tabsAvailable?.[0] ?? "TCR"]
  );

  const tooltipInfoBarConfig = useMemo(() => {
    return product.peerInfoBarTooltips;
  }, [product]);

  const navigate = useNavigate();
  const { dispatch, on, remove } = useEventBus();
  const formatter = useFormatter();

  const { t } = useTranslation();

  const historyTabRef = useRef<any>(null);
  const dispersionTabRef = useRef<any>(null);
  const securitiesTabRef = useRef<any>(null);
  const dispersionByTabRef = useRef<any>(null);

  const [reportDispersionParams, setReportDispersionParams] = useState({
    performanceTimeframe: "3_months",
    intervals: 4,
    trimOutliers: false,
  });

  const storage = useMemo(
    () => new PeerDetailStorage(environment.get("setup")),
    [environment]
  );

  const { taxonomiesMapY, taxonomiesMapX, rootNodeX, rootNodeY } =
    useTaxonomyByType("stock");

  const getTitle = useCallback(() => {
    const peerId = urlParams.id;

    const { where, what, zDimension } = decodePeerId(peerId);
    const whatName = taxonomiesMapY[what]?.name ?? what;
    const whereName = taxonomiesMapX[where]?.name ?? where;

    let name = "";

    if (whatName !== rootNodeY) {
      name = whereName;
    }

    if (whereName !== rootNodeX.name) {
      name += ` - ${whatName}`;
    }

    if (zDimension !== "microLarge") {
      const sizes = zDimension.split(/(?=[A-Z])/);
      const from = sizes[0]?.toLowerCase();
      const to = sizes[1]?.toLowerCase();

      name += ` - ${capitalize(from)}`;

      if (to != null) {
        name += ` - ${capitalize(to)} Cap`;
      } else {
        name += ` Cap`;
      }
    }

    if (name.length === 0) {
      name = "Any";
    }

    return name;
  }, [rootNodeX.name, rootNodeY, taxonomiesMapX, taxonomiesMapY, urlParams.id]);

  const { broadcast } = useBroadcast();

  // Workflow bar actions
  useEffect(() => {
    let action: any = null;
    let actions: any = [];

    const dispersionByConstraints = {
      ...reportDispersionParams,
    };

    // back
    action = {
      componentJSX: (
        <li
          className={"menu__item"}
          onClick={() => navigate("/app/analysis/markets")}
        >
          {t("Back")}
        </li>
      ),
    };

    actions.push(action);

    const workflowEnabledActions = product.workflow;

    const isReportEnabled =
      "report" in workflowEnabledActions &&
      workflowEnabledActions.report?.enabled === true;
    const isDispersionReportEnabled =
      "dispersionReport" in workflowEnabledActions &&
      workflowEnabledActions.dispersionReport?.enabled === true;

    if (peer != null && isReportEnabled) {
      actions.push({
        componentJSX: (
          <ReportButton
            page={"analysisMarketsDetail"}
            target={peer}
            // uiState={action._getUiState("analysisMarketsDetail")}
            rankingCache={undefined}
            storage={{
              peers: storage,
            }}
            usage={{
              function: "MARKET",
            }}
            title={peer?.name}
            timeframe={timeframe}
            trimOutliers={false}
            dispersionByConstraints={dispersionByConstraints}
            segment={segment}
            sortState={{
              descending: false,
              property: "_s_label",
            }}
          />
        ),
      });
    }

    if (isDispersionReportEnabled) {
      action = {
        componentJSX: (
          <DirectDispersionReport
            dispersionTabRef={dispersionByTabRef}
            formatter={formatter}
            dispersionByConstraints={dispersionByConstraints as any}
            title={getTitle() ?? peer?.name ?? ""}
            timeframe={timeframe}
            segment={segment}
            environment={environment}
            peer={peer}
          />
        ),
      };

      actions.push(action);
    }

    var message = {
      from: "PEER_DETAIL",
      content: {
        actions: actions,
      },
    };

    broadcast(config["channels"]["workflow"]["input"], message);
  }, [
    broadcast,
    environment,
    formatter,
    getTitle,
    navigate,
    peer,
    product.workflow,
    reportDispersionParams,
    segment,
    storage,
    t,
    timeframe,
  ]);

  const runWithLoading = useCallback(async (fn: () => Promise<any>) => {
    setIsLoadingContent(true);
    await fn();
    setIsLoadingContent(false);
  }, []);

  const dataGet = useCallback(async () => {
    setIsLoading(true);
    const peerInfo = decodePeerId(urlParams.id);
    const peer = await storage.getPeer(peerInfo);
    setPeer(peer);
    setIsLoading(false);
  }, [storage, urlParams.id]);

  const getChildren = useCallback(async () => {
    await runWithLoading(async () => {
      const peerInfo = decodePeerId(urlParams.id);
      const children =
        segment !== "3 Level"
          ? await storage.getPeerChildren(peerInfo, segment)
          : await storage.getChildrenBySize(peerInfo);
      setChildren(children);
    });
  }, [runWithLoading, segment, storage, urlParams.id]);

  const getHistory = useCallback(
    async (payload: { metrics: string[]; years: number }, id?: string) => {
      await runWithLoading(async () => {
        const history = await storage.getPeerHistoy(
          id ?? urlParams.id,
          payload.metrics,
          payload.years
        );
        setDataForCurrentView((prev) => ({ ...prev, history }));
      });
    },
    [runWithLoading, storage, urlParams.id]
  );

  const getDispersion = useCallback(
    async (params: {
      peerId?: string;
      performance: "1_week" | "1_month" | "3_months" | "6_months" | "12_months";
      intervals: 4 | 10 | 20;
      trimOutliers: boolean;
    }) => {
      await runWithLoading(async () => {
        const { performance, intervals, trimOutliers } = params;
        const paramsDispersion = {
          performance,
          intervals,
          trimOutliers,
        };

        if ("peerId" in params) {
          paramsDispersion["peerId"] = params.peerId;
        } else {
          paramsDispersion["peerId"] = urlParams.id;
        }

        setReportDispersionParams({
          performanceTimeframe: performance,
          intervals,
          trimOutliers,
        });

        const dispersion = await storage.getPeerDispersion(
          paramsDispersion as any
        );
        setDataForCurrentView((prev) => ({ ...prev, dispersion }));
      });
    },
    [runWithLoading, storage, urlParams.id]
  );

  const getSecurities = useCallback(
    async ({
      page,
      alert,
      id,
      rating,
      sorter,
    }: {
      page: number;
      alert:
        | "Any"
        | null
        | "upgrades_today"
        | "upgrades_last_5_days"
        | "upgrades_last_20_days"
        | "upgrades_last_60_days"
        | "downgrades_today"
        | "downgrades_last_5_days"
        | "downgrades_last_20_days"
        | "downgrades_last_60_days"
        | "positive_movers"
        | "negative_movers";
      rating: { A: boolean; B: boolean; C: boolean; D: boolean };
      sorter: { field: string; rev: boolean };
      id: string;
    }) => {
      await runWithLoading(async () => {
        const pagination = { itemsPerPage: 20000, page };
        const securities = await storage.getSecurities(
          id,
          pagination,
          alert,
          rating,
          sorter
        );
        setDataForCurrentView((prev) => ({ ...prev, securities }));
      });
    },
    [runWithLoading, storage]
  );

  const getDispersionBy = useCallback(
    async ({
      id,
      performance,
      intervals,
      trimOutliers,
    }: {
      intervals: 4 | 10 | 20;
      performance: "1_week" | "1_month" | "3_months" | "6_months" | "12_months";
      trimOutliers: boolean;
      id: string | undefined;
    }) => {
      if (!id) {
        return;
      }

      setReportDispersionParams({
        performanceTimeframe: performance,
        intervals,
        trimOutliers,
      });

      await runWithLoading(async () => {
        const dispersionBy = await storage.getDispersionBy({
          peerId: id,
          performance,
          intervals,
          segment,
          trimOutliers,
        });
        setDataForCurrentView((prev) => ({ ...prev, dispersionBy }));
      });
    },
    [runWithLoading, segment, storage]
  );

  const getPerformanceSinceRated = useCallback(
    async (e?: CustomEvent) => {
      await runWithLoading(async () => {
        const performanceSinceRating = await storage.getPerformanceSinceRated(
          urlParams.id,
          e?.detail?.performanceAt ?? "sinceRated"
        );
        setDataForCurrentView((prev) => ({ ...prev, performanceSinceRating }));
      });
    },
    [runWithLoading, storage, urlParams.id]
  );

  const getDataByView = useCallback(async () => {
    switch (tab) {
      default:
        break;

      case "history": {
        const defaultPayload = {
          metrics: ["abHistoryPerc", "cdHistoryPerc"],
          years: 1,
        };

        let historyTabParams = null;

        if (historyTabRef.current) {
          historyTabParams = historyTabRef.current.getHistoryTabParams();
        }

        await getHistory(historyTabParams ?? defaultPayload);

        break;
      }

      case "dispersion": {
        const defaultPayload = {
          peerId: urlParams.id,
          performance: "3_months",
          trimeOutliers: false,
          intervals: 4,
        };

        let dispersionParams: any = null;

        if (dispersionTabRef.current) {
          dispersionParams = dispersionTabRef.current.getDispersionTabParams();
        }

        await getDispersion(dispersionParams ?? defaultPayload);

        break;
      }

      case "securities": {
        const securitiesTabParams =
          securitiesTabRef?.current?.getSecuritiesTabParams();
        let id = securitiesTabParams.id ?? urlParams.id;
        let page = securitiesTabParams.page ?? 1;
        let alert = securitiesTabParams.alert ?? null;
        let rating = securitiesTabParams.rating ?? {
          A: false,
          B: false,
          C: false,
          D: false,
        };
        let sorter = securitiesTabParams.sorter ?? null;

        await getSecurities({ id, page, alert, rating, sorter });
        break;
      }

      case "dispersionBy": {
        const dispersionByTabParams =
          dispersionByTabRef?.current?.getdispersionByTabParams();
        const params = {
          id: urlParams.id,
          performance: dispersionByTabParams?.performance ?? "3_months",
          intervals: dispersionByTabParams?.intervals ?? 4,
          trimOutliers: dispersionByTabParams?.trimOutliers ?? false,
        };
        await getDispersionBy(params);
        break;
      }

      case "performanceSinceRated": {
        await getPerformanceSinceRated();

        break;
      }
    }
  }, [
    getDispersion,
    getDispersionBy,
    getHistory,
    getPerformanceSinceRated,
    getSecurities,
    tab,
    urlParams.id,
  ]);

  const getPeerInfoForHeaderBar = useCallback(async () => {
    return await storage.avgPerfUpDown(urlParams.id);
  }, [storage, urlParams.id]);

  // Fetch the target paeer
  useEffect(() => {
    dataGet();
  }, [dataGet]);

  // Fetch peer children in a separate useEffect to avoid extra call to the server (ask for main peer when change only the drilldown)
  useEffect(() => {
    getChildren();
  }, [getChildren]);

  // Get data based on tab
  useEffect(() => {
    getDataByView();
  }, [getDataByView, urlParams.id, segment, timeframe]);

  useEffect(() => {
    on("update-performance-since-trend-at", getPerformanceSinceRated);

    return () =>
      remove("update-performance-since-trend-at", getPerformanceSinceRated);
  }, [getPerformanceSinceRated, on, remove]);

  const clearUrlFromQueryParams = useCallback(() => {
    setSearchParams({});
  }, [setSearchParams]);

  const changePeer = useCallback(
    (value: any, params?: string) => {
      clearUrlFromQueryParams();
      const payload = value.payload;
      const id = decodePeerId(urlParams.id);
      const size = id.zDimension;

      const peerDimensions = {
        what: payload.yDim,
        where: payload.xDim,
        zDimension: size,
        type: "stock",
      };
      const newPeerId = encodePeerId(peerDimensions);

      const event = { peer: newPeerId };

      if (params) {
        event["params"] = params;
      }

      dispatch("peer-change", event);
    },
    [clearUrlFromQueryParams, dispatch, urlParams.id]
  );

  //Remove loader
  useEffect(() => {
    if (urlParams.id) {
      document.getElementById("data-loader")?.classList.add("hide");
    }
  }, [urlParams.id]);

  const goToSecuritiesTab = useCallback(
    (field?: "upgrades" | "downgrades" | "ab_perc" | "cd_perc") => {
      if (field) {
        let param = field;
        if (field === "upgrades" || field === "downgrades") {
          switch (timeframe) {
            case "lastWeek":
              param += "_last_week";

              break;
            case "lastMonth":
              param += "_last_month";
              break;
          }
        }
        setSearchParams({ config: param });
      }
      setTab("securities");
    },
    [setSearchParams, timeframe]
  );

  const peerInfoBarData = useMemo(() => {
    return {
      abChanges:
        peer?.["statistic"]?.["abPercentage"]?.[
          timeframe === "today" ? "yesterday" : timeframe
        ],
      tcr: peer?.tcr?.today,
      cardinality: peer?.info?.cardinality,
      marketCap: peer?.info?.marketcap,
      upgradesNumber: peer?.statistic?.upgrades?.[timeframe]?.number,
      upgradesPercentage: peer?.statistic?.upgrades?.[timeframe]?.percentage,
      downgradesNumber: peer?.statistic?.downgrades?.[timeframe]?.number,
      downgradesPercentage:
        peer?.statistic?.downgrades?.[timeframe]?.percentage,
      ab_perc: peer?.statistic?.["abPercentage"]?.["today"],
      cd_perc: peer?.statistic?.["cdPercentage"]?.["today"],
    };
  }, [peer, timeframe]);

  const peerTableData = useMemo(() => {
    return { peer, children: { data: children } };
  }, [children, peer]);

  const historyData = useMemo(() => {
    return {
      peer: peer,
      children: { data: children },
      history: dataForCurrentView.history,
    };
  }, [children, dataForCurrentView.history, peer]);

  const dispersionData = useMemo(() => {
    return {
      peer: peer,
      children: { data: children },
      dispersion: dataForCurrentView.dispersion,
    };
  }, [children, dataForCurrentView.dispersion, peer]);

  const securitiesData = useMemo(() => {
    return {
      peer: peer,
      children: { data: children },
      securities: dataForCurrentView.securities,
    };
  }, [children, dataForCurrentView.securities, peer]);

  const dispersionByData = useMemo(() => {
    return { dispersionBy: dataForCurrentView.dispersionBy };
  }, [dataForCurrentView.dispersionBy]);

  const changeTab = useCallback(
    (value: PeerDetailTabs) => {
      if (value !== tab) {
        setDataForCurrentView(initialState);

        if (tab === "securities") {
          clearUrlFromQueryParams();
        }

        setTab(value);
      }
    },
    [clearUrlFromQueryParams, tab]
  );

  const tcrTableRowHandler = useCallback(
    (peer, field) => {
      if (isSecurityTabEnabled) {
        const { what, where } = peer;
        let param = field;

        if (field === "upgrades" || field === "downgrades") {
          switch (timeframe) {
            case "lastWeek":
              param += "_last_week";

              break;
            case "lastMonth":
              param += "_last_month";
              break;
          }
        }

        changePeer(
          {
            type: "CHANGE_PEER",
            payload: { xDim: where, yDim: what },
          },
          param ? `?tab=securities&config=${param}` : "?tab=securities"
        );
      }
    },
    [changePeer, isSecurityTabEnabled, timeframe]
  );

  useEffect(() => {
    const tab = searchParams.get("tab");

    if (tab) {
      setTab(tab as PeerDetailTabs);
    }
  }, [goToSecuritiesTab, searchParams]);

  return urlParams.id ? (
    <Box
      ref={viewRef}
      px={2}
      minHeight={0}
      display={"flex"}
      flexDirection={"column"}
      height={"calc(100% - 20px)"}
    >
      <HeaderBar
        segment={segment}
        setSegment={setSegment}
        peerId={urlParams.id}
        timeframe={timeframe}
        setTimeframe={setTimeframe}
        tab={tab}
        setTab={changeTab}
      />
      {isLoading ? (
        <PeerBarSkeleton numOfBlocks={6} />
      ) : peer && tab !== "performanceSinceRated" ? (
        <PeerInfoRow
          tooltipConfig={tooltipInfoBarConfig}
          type={"stock"}
          goToSecuritiesTab={
            isSecurityTabEnabled ? goToSecuritiesTab : undefined
          }
          data={peerInfoBarData}
          timeframe={timeframeToNum(timeframe)}
        />
      ) : (
        <></>
      )}

      {tab === "TCR" && (
        <ContentWrapper>
          {isLoadingContent ? (
            <TableSkeleton />
          ) : (
            <Box flex={1} display={"flex"} minHeight={0} minWidth={0}>
              <MarketsETFTableTCR
                data={peerTableData}
                type={"Stock"}
                hasPeerSummaryRow={false}
                segment={segment as any}
                timeframe={timeframeToNum(timeframe)}
                formatterHelper={storage.tableFormatterByField}
                dispatch={changePeer}
                goToSecuritiesTab={tcrTableRowHandler}
              />
            </Box>
          )}
        </ContentWrapper>
      )}

      {tab === "history" && (
        <RatingHistoryView
          ref={historyTabRef}
          id={urlParams.id}
          setParams={getHistory}
          data={historyData}
          type={"Stock"}
          segment={segment as any}
          tableCellNameFormatter={storage.tableFormatterByField}
          dispatch={changePeer}
        />
      )}

      {tab === "dispersion" && (
        <DispersionTabDetail
          id={urlParams.id}
          isLoading={isLoadingContent}
          data={dispersionData}
          type={"Stock"}
          nameFormatterHelper={storage.tableFormatterByField}
          segment={segment}
          dispatch={changePeer}
          peerCardinality={peer?.info?.cardinality ?? 0}
          ref={dispersionTabRef}
          updateParams={getDispersion}
          onChangeParams={getDispersion}
        />
      )}

      {tab === "securities" && (
        <SecuritiesTabDetail
          id={urlParams.id}
          ref={securitiesTabRef}
          data={securitiesData}
          peerCardinality={peer?.info?.cardinality ?? 0}
          nameFormatter={storage.tableFormatterByField}
          segment={segment}
          type={"Stock"}
          dispatch={changePeer}
          updateParams={getSecurities}
          isLoading={isLoadingContent}
        />
      )}

      {tab === "dispersionBy" && (
        <DispersionByTabDetail
          ref={dispersionByTabRef}
          isLoading={isLoadingContent}
          id={urlParams.id}
          data={dispersionByData}
          segment={segment}
          type={"Stock"}
          peerCardinality={peer?.info?.cardinality}
          changePeer={changePeer}
          updateTabSettings={getDispersionBy}
        />
      )}

      {tab === "performanceSinceRated" && (
        <ContentWrapper p={0}>
          <PerformanceSinceTrendTab
            isLoading={isLoadingContent}
            data={dataForCurrentView.performanceSinceRating}
            peerType={"Stock"}
            getHeaderData={getPeerInfoForHeaderBar}
          />
        </ContentWrapper>
      )}
    </Box>
  ) : (
    <></>
  );
}

const ContentWrapper = ({ children, p }: { children; p?: number }) => {
  return (
    <Box
      p={p ?? 1}
      mt={1}
      borderRadius={"4px"}
      boxShadow={3}
      minWidth={0}
      minHeight={0}
      display={"flex"}
      flex={1}
    >
      {children}
    </Box>
  );
};
const PeerBarSkeleton = ({ numOfBlocks }: { numOfBlocks: number }) => {
  const renderBlocks = useCallback(() => {
    const blocks: any = [];

    for (let i = 0; i <= numOfBlocks; i++) {
      blocks.push(
        <Card key={uuidv4()} sx={{ flex: 1 }}>
          <CardContent>
            <Stack spacing={1}>
              <Skeleton
                variant="text"
                sx={{
                  fontSize: "20px",
                  marginBottom: "20px",
                }}
              />

              {/* For other variants, adjust the size with `width` and `height` */}
              <Skeleton variant="rectangular" width={"100%"} height={30} />
              <Skeleton variant="rectangular" width={"100%"} height={30} />
              <Skeleton variant="rectangular" width={"100%"} height={30} />
              <Skeleton variant="rectangular" width={"100%"} height={30} />
            </Stack>
          </CardContent>
        </Card>
      );
    }

    return blocks.map((block) => block);
  }, [numOfBlocks]);

  return (
    <Box
      display={"flex"}
      gap={1}
      padding={1}
      boxShadow={3}
      borderRadius={"4px"}
    >
      {renderBlocks()}
    </Box>
  );
};

export const TableSkeleton = ({ rows = 10 }: { rows?: number }) => {
  const rowSet = useMemo(() => {
    const rowSet: any = [];

    let i = 0;

    while (i !== rows) {
      rowSet.push(
        <Skeleton
          key={uuidv4()}
          variant="rectangular"
          width={"100%"}
          height={30}
        />
      );

      i++;
    }

    return rowSet;
  }, [rows]);

  return (
    <Card sx={{ flex: 1 }}>
      <CardContent>
        <Stack spacing={1}>
          <Skeleton
            variant="text"
            sx={{
              fontSize: "20px",
              marginBottom: "20px",
            }}
          />

          {/* For other variants, adjust the size with `width` and `height` */}
          {rowSet.map((row) => row)}
        </Stack>
      </CardContent>
    </Card>
  );
};
