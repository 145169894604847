import { Box, MenuItem, Select, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { useCallback, useEffect, useMemo, useState } from "react";
import { v4 as uuidv4 } from "uuid";

type RankDateSelectProps = {
  onChange: (
    s:
      | "PREVIOUS_DAY"
      | "PREVIOUS_WEEK"
      | "PREVIOUS_2_WEEKS"
      | "PREVIOUS_MONTH"
      | "PREVIOUS_3_MONTHS"
      | undefined
  ) => void;
  label?: string;
  dateInitValue?:
    | "PREVIOUS_DAY"
    | "PREVIOUS_WEEK"
    | "PREVIOUS_2_WEEKS"
    | "PREVIOUS_MONTH"
    | "PREVIOUS_3_MONTHS";
};

const StyledSelect = styled(
  (props: { children: any; className?: any; value: any }) => (
    <Select
      value={props.value}
      fullWidth
      size="small"
      variant="outlined"
      classes={{ select: props.className }}
    >
      {props.children}
    </Select>
  )
)({
  fontSize: "12px !important",
  paddingTop: "2px !important",
  paddingBottom: "2px !important",
});

export function RankDateSelect({
  onChange,
  dateInitValue,
  label,
}: RankDateSelectProps) {
  const [value, setValue] = useState<
    | "PREVIOUS_DAY"
    | "PREVIOUS_WEEK"
    | "PREVIOUS_2_WEEKS"
    | "PREVIOUS_MONTH"
    | "PREVIOUS_3_MONTHS"
    | "no_changes"
  >(dateInitValue ?? "no_changes");

  useEffect(() => {
    setValue(dateInitValue ?? "no_changes");
  }, [dateInitValue]);

  const options = useMemo(
    () => [
      { label: "No changes", value: "no_changes" },
      { label: "Previous day", value: "PREVIOUS_DAY" },
      { label: "Last Friday", value: "PREVIOUS_WEEK" },
      { label: "Previous week", value: "PREVIOUS_2_WEEKS" },
      { label: "Last month", value: "PREVIOUS_MONTH" },
      { label: "Last 3 months", value: "PREVIOUS_3_MONTHS" },
    ],
    []
  );

  const onValueChage = useCallback(
    (value) => {
      setValue(value);

      let v = value;

      if (value === "no_changes") {
        v = undefined;
      }

      onChange(v);
    },
    [onChange]
  );

  return (
    <Box display={"flex"} alignItems={"center"} gap={1}>
      <Box display={"flex"} flex={1} gap={1} alignItems={"center"}>
        {label && <Typography whiteSpace={"nowrap"}>{label}</Typography>}
        <StyledSelect value={value}>
          {options.map((option) => (
            <MenuItem
              key={uuidv4()}
              value={option.value}
              onClick={() => onValueChage(option.value as any)}
            >
              <Typography>{option.label}</Typography>
            </MenuItem>
          ))}
        </StyledSelect>{" "}
      </Box>
    </Box>
  );
}
