import { CellComponent, RowComponent } from "tabulator-tables";
import { ClusterAnalytics } from "../../../../../api/compute/ClusterAnalytics";
import {
  formatTaxonPrefixingParent,
  getTaxonById,
} from "../../../../../api/compute/Taxon";
import { decodePeerId, encodePeerId } from "../../../../../api/utils";
import { Environment } from "../../../../../Environment";
import { MarketsStorage } from "../../../storage/MarketsStorage";

type PeerConstraints = {
  timeframe: 0 | 4 | 19;
  segment:
    | "WWW"
    | "1 Industry"
    | "3 Sector"
    | "4 Subsector"
    | "Area"
    | "Region"
    | "ETFSEGMENT"
    | "Country";
  analytic:
    | "tcr"
    | "history"
    | "dispersion"
    | "securities"
    | "dispersionBy"
    | "performanceSinceRated";
  xDim: string;
  yDim: string;
  zDim: string;
};

type TableField =
  | "name"
  | "tcr"
  | "tcr_changes"
  | "abPerc"
  | "cdPerc"
  | "cardinality"
  | "ab_changes"
  | "cd_changes"
  | "upgrades"
  | "downgrades"
  | "upgrades_perc"
  | "downgrades_perc";

type PeerDefinitionConstraints = {
  xDim: PeerConstraints["xDim"];
  yDim: PeerConstraints["yDim"];
  zDim: PeerConstraints["zDim"];
  segment: PeerConstraints["segment"];
  timeframe: PeerConstraints["timeframe"];
};

type PerformanceDispersion =
  | "1_week"
  | "1_month"
  | "3_months"
  | "6_months"
  | "12_months";

type DispersionIntervals = 4 | 10 | 20;

type Alerts =
  | null
  | "upgrades_today"
  | "upgrades_last_5_days"
  | "upgrades_last_20_days"
  | "upgrades_last_60_days"
  | "downgrades_today"
  | "downgrades_last_5_days"
  | "downgrades_last_20_days"
  | "downgrades_last_60_days"
  | "positive_movers"
  | "negative_movers";
export class AnalysisMarketsETFHelper {
  environment: Environment;
  private storage;
  private formatter;
  private taxonomies;
  private taxonomiesFieldsMap;

  constructor(environment) {
    this.environment = environment;
    this.storage = new MarketsStorage(environment.get("setup"));
    this.formatter = environment.get("formatter");
    this.taxonomies = environment.get("setup")["taxonomies"];
    this.taxonomiesFieldsMap = environment.get("setup")["taxonomyFields"];
  }

  /**
   * Caching contraints
   *
   * Analytic is the parameter that handle the operative view in UI.
   * When the view changes we need to call the services to get updated data to populate
   * view's widgets.
   *
   * To do this we call the build object method but if the segment or the dimensions doesn't change
   * we don't need to fetch data for the peer because it's the same.
   *
   * Otherway we invalidate the cache and we simply call buildPeerObject method
   */
  segment: PeerConstraints["segment"] | null = null;
  xDim: PeerConstraints["xDim"] | null = null;
  yDim: PeerConstraints["yDim"] | null = null;
  zDimension: PeerConstraints["zDim"] | null = null;
  timeframe: PeerConstraints["timeframe"] | null = null;

  /**
   * Cache peer object
   *
   * We need to keep peers and peer's children because only them
   * are related to the x, y, and z dimensions
   */
  peerObject: {
    peer: any;
    peerChildren: any;
  } | null = null;

  /**
   * Invalidate the cached variables by setting them to null
   */
  private invalidateCache() {
    this.xDim = null;
    this.yDim = null;
    this.zDimension = null;
    this.peerObject = null;
    this.timeframe = null;
  }

  /**
   *
   * @param {object}
   * @returns a boolean that indicates if the incoming constraints are equal to the cached ones
   */
  private areConstraintsChanging({
    xDim,
    yDim,
    zDim,
    segment,
    timeframe,
  }: PeerDefinitionConstraints) {
    if (
      this.xDim !== xDim ||
      this.yDim !== yDim ||
      this.zDimension !== zDim ||
      this.segment !== segment ||
      this.timeframe !== timeframe
    ) {
      return true;
    }

    return false;
  }

  /**
   *
   * @param {object}
   *
   * This method store peer's constraints definition in the cache
   */
  private cachePeerDefinitionConstraints({
    xDim,
    yDim,
    zDim,
    segment,
    timeframe,
  }: PeerDefinitionConstraints) {
    // Chaching constraints
    this.xDim = xDim;
    this.yDim = yDim;
    this.zDimension = zDim;
    this.segment = segment;
    this.timeframe = timeframe;
  }

  /**
   *
   * @param {param} constraints
   * @param {any} viewParams params used to make apiCalls specifics for the view tab
   * @returns {object}
   *
   * Retrive the peer object based on the view type selected
   */
  async getPeerObject({
    segment,
    analytic,
    xDim,
    yDim,
    zDim,
    timeframe,
  }: PeerConstraints) {
    const peerID = encodePeerId({
      what: yDim,
      where: xDim,
      zDimension: zDim,
      type: "ETF",
    });

    return await this.buildPeerObject(
      peerID,
      analytic,
      segment,
      xDim,
      yDim,
      zDim,
      timeframe,
      null
    );
  }

  /**
   *
   * @param {string} analytic
   * @param {any} viewParams
   *
   * @returns {object} The peer object enriched with the data for the view tab selected.
   *
   * The purpouse of this function is to avoid to rebuild and call the peer object every time
   * that the user switch tab. Instead of this, this function expose a private method to get data
   * based on the view tab and retrives the cached peer object enriched with the data fetched by the API
   */
  async getPeerDataByAnalytic(constraints: PeerConstraints, viewParams?: any) {
    const peerInfo = {
      where: constraints.xDim,
      what: constraints.yDim,
      zDimension: constraints.zDim,
      type: "ETF",
    };
    const peerId = encodePeerId(peerInfo);
    return await this.buildPeerObject(
      peerId,
      constraints.analytic,
      constraints.segment as PeerConstraints["segment"],
      constraints.xDim,
      constraints.yDim,
      constraints.zDim,
      constraints.timeframe,
      viewParams
    );
  }

  // Build response peer object by analytic selected in UI
  private async buildPeerObject(
    peerId,
    analytic: PeerConstraints["analytic"],
    segment: PeerConstraints["segment"],
    xDim,
    yDim,
    zDim,
    timeframe,
    viewParams
  ) {
    const newPeerDefinitionConstraints = {
      xDim,
      yDim,
      zDim,
      segment,
      timeframe,
    };
    if (this.areConstraintsChanging(newPeerDefinitionConstraints)) {
      // 1. Peer's definition constraints are not valid anymore so we cleen the cache
      this.invalidateCache();

      // 2. Update cache with new values
      this.cachePeerDefinitionConstraints(newPeerDefinitionConstraints);

      const peerObject = {
        peer: await this.getPeerAnalyticsETFs(peerId, { timeframe }),
      };

      // As default behaviour get peer and his children
      peerObject["children"] = await this.getPeerChildren(
        peerObject["peer"],
        peerId,
        segment,
        timeframe ?? 0
      );

      return await this.getPeerDataByView(peerObject, analytic, viewParams);
    } else {
      if (this.peerObject !== null && this.peerObject.peerChildren !== null) {
        return await this.getPeerDataByView(
          this.peerObject,
          analytic,
          viewParams
        );
      }
    }
  }

  /**
   *
   * @param {object} peerObject
   * @param {string} analytic
   * @param {string} segment
   * @param {string} peerId
   * @param {any} viewParams optional object used to pass params to the api call related to the view
   *
   * @returns {object} with the data needed by the view
   *
   * ? It's very important to assign as the key of peerObject in which store data, equal to the analytic
   * ? because the UI use that key to clean the object see AnalyticMarketsETF.tsx file
   */
  private async getPeerDataByView(
    peerObject: any,
    analytic: PeerConstraints["analytic"],
    viewParams?: any
  ) {
    // Caching PeerObject
    this.peerObject = peerObject;

    const peerInfo = {
      where: peerObject.peer["where"],
      what: peerObject.peer["what"],
      type: peerObject.peer["type"],
      zDimension: peerObject.peer["size"],
    };
    const peerId = encodePeerId(peerInfo);

    let key: PeerConstraints["analytic"] = "tcr";

    switch (analytic) {
      default:
      case "tcr":
        return peerObject;
      case "history":
        key = "history";

        if (viewParams) {
          const metrics = viewParams?.metrics ?? null;
          const years = viewParams?.years ?? null;
          // If the peer is specificated in view params it means that the peerObject has to be the same
          // but the data for the current tab requires a new peer.
          const peerDefinition = viewParams?.peerId ?? peerId;

          peerObject[key] = await this.getRatingHistory(
            peerDefinition,
            metrics,
            years
          );

          return peerObject;
        }

        return (peerObject[key] = null);

      case "dispersion":
        key = "dispersion";

        if (viewParams) {
          if (peerObject?.peer?.info?.cardinality) {
            const trimOutliers = viewParams?.trimOutliers;
            const performance = viewParams?.performance;
            const intervals = viewParams?.intervals;
            const peerDefinition = viewParams?.peerId ?? peerId;

            peerObject[key] = await this.getPeerDispersion(
              peerDefinition,
              performance,
              intervals,
              trimOutliers
            );
          } else {
            peerObject[key] = {};
          }
        }

        return peerObject;

      case "securities":
        key = "securities";

        if (viewParams) {
          const pagination = viewParams?.pagination ?? {
            itemsPerPage: 25,
            page: 1,
          };
          const alert = viewParams?.alert ?? null;
          const rating = viewParams?.rating ?? {
            A: false,
            B: false,
            C: false,
            D: false,
          };
          const sorter = viewParams?.sorter ?? {
            field: "marketcap",
            rev: false,
          };
          const peerDefinition = viewParams?.peerId ?? peerId;

          peerObject[key] = await this.getPeerInstruments(
            peerDefinition,
            pagination,
            alert,
            rating,
            sorter
          );
        }

        return peerObject;

      case "dispersionBy":
        key = "dispersionBy";

        if (viewParams) {
          if (peerObject?.peer?.info?.cardinality) {
            const trimOutliers = viewParams?.trimOutliers;
            const performance = viewParams?.performance;
            const intervals = viewParams?.intervals;
            const segment = this.segment as PeerConstraints["segment"];

            peerObject[key] = await this.getPeerDispersionBy(
              peerId,
              performance,
              intervals,
              segment,
              trimOutliers
            );
          } else {
            peerObject[key] = {};
          }
        }

        return peerObject;

      // case "performanceSinceRated":
      //   key = "performanceSinceRated";

      //   peerObject[key] = await this.getPerformanceSinceRated(peerId);

      //   return peerObject;
    }
  }

  async getPeerInstruments(
    peerId: string,
    pagination: { itemsPerPage: number; page: number },
    alert: Alerts,
    rating: { A: boolean; B: boolean; C: Boolean; D: Boolean },
    sorter: { field: string; rev: boolean }
  ) {
    const peerInfo = decodePeerId(peerId);
    const constraints: any = {
      filters: [{ dimension: "type", segments: [peerInfo.type] }],
    };

    let range: any = null;

    if (alert) {
      switch (alert) {
        default:
          break;
        case "upgrades_today": {
          range = [
            {
              dimension: "direction",
              segments: [{ max: 4, min: 0 }],
            },
            {
              dimension: "lr",
              segments: [{ max: 0, min: 0 }],
            },
          ];

          constraints["ranges"] = range;

          break;
        }

        case "upgrades_last_5_days": {
          range = [
            {
              dimension: "direction",
              segments: [{ max: 4, min: 0 }],
            },
            {
              dimension: "lr",
              segments: [{ max: 4, min: 0 }],
            },
          ];

          constraints["ranges"] = range;

          break;
        }

        case "upgrades_last_20_days": {
          range = [
            {
              dimension: "direction",
              segments: [{ max: 4, min: 0 }],
            },
            {
              dimension: "lr",
              segments: [{ max: 19, min: 0 }],
            },
          ];

          constraints["ranges"] = range;

          break;
        }

        case "upgrades_last_60_days": {
          range = [
            {
              dimension: "direction",
              segments: [{ max: 4, min: 0 }],
            },
            {
              dimension: "lr",
              segments: [{ max: 59, min: 0 }],
            },
          ];

          constraints["ranges"] = range;

          break;
        }

        case "downgrades_today": {
          range = [
            {
              dimension: "direction",
              segments: [{ max: 0, min: -4 }],
            },
            {
              dimension: "lr",
              segments: [{ max: 0, min: 0 }],
            },
          ];

          constraints["ranges"] = range;

          break;
        }

        case "downgrades_last_5_days": {
          range = [
            {
              dimension: "direction",
              segments: [{ max: 0, min: -4 }],
            },
            {
              dimension: "lr",
              segments: [{ max: 4, min: 0 }],
            },
          ];

          constraints["ranges"] = range;

          break;
        }

        case "downgrades_last_20_days": {
          range = [
            {
              dimension: "direction",
              segments: [{ max: 0, min: -4 }],
            },
            {
              dimension: "lr",
              segments: [{ max: 19, min: 0 }],
            },
          ];

          constraints["ranges"] = range;

          break;
        }

        case "downgrades_last_60_days": {
          range = [
            {
              dimension: "direction",
              segments: [{ max: 0, min: -4 }],
            },
            {
              dimension: "lr",
              segments: [{ max: 59, min: 0 }],
            },
          ];

          constraints["ranges"] = range;

          break;
        }

        case "positive_movers": {
          range = [
            {
              dimension: "rc",
              segments: [
                {
                  max: null,
                  min: 0,
                },
              ],
            },
            {
              dimension: "px",
              segments: [
                {
                  max: 0,
                  min: 0,
                },
              ],
            },
            {
              dimension: "lr",
              segments: [
                {
                  max: null,
                  min: 19,
                },
              ],
            },
          ];

          constraints["ranges"] = range;

          break;
        }

        case "negative_movers": {
          range = [
            {
              dimension: "rc",
              segments: [
                {
                  max: 0,
                  min: null,
                },
              ],
            },
            {
              dimension: "px",
              segments: [
                {
                  max: 0,
                  min: 0,
                },
              ],
            },
            {
              dimension: "lr",
              segments: [
                {
                  max: null,
                  min: 19,
                },
              ],
            },
          ];

          constraints["ranges"] = range;

          break;
        }
      }
    }

    // Transform rating object into numeric array
    let ratingArr = Object.entries(rating).map(([key, value]) => {
      if (value === true) {
        switch (key) {
          case "A":
            return 2;
          case "B":
            return 1;
          case "C":
            return -1;
          case "D":
            return -2;

          default:
            break;
        }
      }

      return 0;
    });

    //clean rating array from wrong values
    ratingArr = ratingArr.filter((value) => value !== 0);

    if (ratingArr.length) {
      if (constraints?.["ranges"]) {
        constraints.ranges.push({
          dimension: "rc",
          segments: [
            {
              min: Math.min(...ratingArr),
              max: Math.max(...ratingArr),
            },
          ],
        });
      } else {
        constraints["ranges"] = [
          {
            dimension: "rc",
            segments: [
              {
                min: Math.min(...ratingArr),
                max: Math.max(...ratingArr),
              },
            ],
          },
        ];
      }
    }

    const params = {
      constraints,
      peerId,
      sortBy: { property: sorter.field, descending: sorter.rev },
      pagination: {
        page: pagination.page,
        rows: pagination.itemsPerPage,
      },
    };

    return await this.storage.getInstruments(params);
  }

  async getPeerDispersionBy(
    peerId: string,
    performance: PerformanceDispersion,
    intervals: DispersionIntervals,
    segment: PeerConstraints["segment"],
    trimOutliers: boolean
  ) {
    let analytic: any = null;

    switch (performance) {
      case "1_week":
        analytic = "pw";
        break;
      case "1_month":
        analytic = "pm";
        break;
      case "3_months":
        analytic = "pq";
        break;
      case "6_months":
        analytic = "ps";
        break;
      case "12_months":
        analytic = "py";
        break;
      default:
        throw new Error(`Unknown performance ${performance}`);
    }

    let percentage;

    switch (intervals) {
      case 4:
        percentage = 25;
        break;
      case 10:
        percentage = 10;
        break;
      case 20:
        percentage = 5;
        break;
      default:
        throw new Error(`Unknown percentage ${intervals}`);
    }

    const peerInfo = decodePeerId(peerId);
    const peerType = peerInfo.type;

    let etfLevel = "AssetClass";

    switch (segment) {
      case "1 Industry":
      default:
        break;

      case "3 Sector":
        etfLevel = "Specialty";

        break;

      case "4 Subsector":
        etfLevel = "ETF_Subsector";

        break;

      case "Country":
        etfLevel = "etfgeo";

        break;

      case "Region":
        etfLevel = "etfgeoRegion";

        break;

      case "Area":
        etfLevel = "etfgeoArea";

        break;
    }

    return await this.storage.analytics({
      analytics: [
        `${analytic}#avg#false`,
        `${analytic}#max#false`,
        `${analytic}#min#false`,
        "cardinality",
      ],
      clusters: [
        {
          dimension: analytic,
          transform: {
            function: "topbottom",
            params: {
              perc: percentage,
              trimOutliers: trimOutliers,
            },
          },
        },
      ],
      peerId: peerId,
      segment: peerType === "ETF" ? etfLevel : segment,
    });
  }

  async getPeerDispersion(
    peerId: string,
    performance: PerformanceDispersion,
    intervals: DispersionIntervals,
    trimOutliers: boolean
  ) {
    let analytic: string | null = null;

    switch (performance) {
      case "1_week":
        analytic = "pw";
        break;
      case "1_month":
        analytic = "pm";
        break;
      case "3_months":
        analytic = "pq";
        break;
      case "6_months":
        analytic = "ps";
        break;
      case "12_months":
        analytic = "py";
        break;
      default:
        throw new Error(`Unknown performance ${performance}`);
    }

    let percentage: number | null = null;

    switch (intervals) {
      case 4:
        percentage = 25;
        break;
      case 10:
        percentage = 10;
        break;
      case 20:
        percentage = 5;
        break;
      default:
        throw new Error(`Unknown percentage ${intervals}`);
    }

    const requestParams = {
      analytics: [
        `${analytic}#avg#false`,
        `${analytic}#max#false`,
        `${analytic}#min#false`,
        "A_%",
        "B_%",
        "C_%",
        "D_%",
        "N_%",
        "A",
        "B",
        "C",
        "D",
        "N",
        "cardinality",
      ],
      clusters: [
        {
          dimension: analytic,
          transform: {
            function: "topbottom",
            params: {
              perc: percentage,
              trimOutliers: trimOutliers,
            },
          },
        },
      ],
      peerId: peerId,
      segment: null,
    };

    return await this.storage.analytics(requestParams);
  }

  async getRatingHistory(peerId: string, metrics: string[], year: number) {
    return this.storage.getHistory(peerId, metrics, year);
  }

  setPeerName(peerId, peer) {
    const { type } = decodePeerId(peerId);
    const instrumentType = type.toLowerCase();
    let taxon: any = null;
    const taxonomies = this.taxonomies;
    const fieldMap = this.taxonomiesFieldsMap;
    const whatRootNode = Object.values<any>(
      taxonomies[
        fieldMap[instrumentType === "etf" ? "ETF" : "security"][
          instrumentType === "etf" ? "etfclass" : "sector"
        ]
      ]
    ).find((node) => node.parent === null)["id"];
    const whereRootNode = Object.values<any>(
      taxonomies[
        fieldMap[instrumentType === "etf" ? "ETF" : "security"][
          instrumentType === "etf" ? "etfgeo" : "country"
        ]
      ]
    ).find((node) => node.parent === null)["id"];
    let field: any = null;

    if (peer["what"] === whatRootNode && peer["where"] === whereRootNode) {
      peer["name"] = "__ROOT__"; // special name: managed by render
    } else if (
      peer["what"] === whatRootNode &&
      peer["where"] !== whereRootNode
    ) {
      // where only
      field =
        fieldMap[instrumentType === "etf" ? "ETF" : "security"][
          instrumentType === "etf" ? "etfgeo" : "country"
        ];
      taxon = Object.values<any>(taxonomies[field]).find(
        (item) => item.id === peer["where"]
      );
      peer["name"] = taxon["name"];
    } else if (
      peer["what"] !== whatRootNode &&
      peer["where"] === whereRootNode
    ) {
      // what only
      field =
        fieldMap[instrumentType === "etf" ? "ETF" : "security"][
          instrumentType === "etf" ? "etfclass" : "sector"
        ];
      taxon = Object.values<any>(taxonomies[field]).find(
        (item) => item.id === peer["what"]
      );
      peer["name"] = taxon["name"];
    } else {
      // cross: using , as separator. The UI can split the name
      // in substring for formatting purposes
      const whereField =
        fieldMap[instrumentType === "etf" ? "ETF" : "security"][
          instrumentType === "etf" ? "etfgeo" : "country"
        ];
      const whatField =
        fieldMap[instrumentType === "etf" ? "ETF" : "security"][
          instrumentType === "etf" ? "etfclass" : "sector"
        ];
      peer["name"] = [
        Object.values<any>(taxonomies[whereField]).find(
          (item) => item.id === peer["where"]
        )["name"],
        Object.values<any>(taxonomies[whatField]).find(
          (item) => item.id === peer["what"]
        )["name"],
      ].join(",");
    }

    return peer;
  }

  /**
   *
   * @param peerId @string that contains all the peer info ("where", "what", "size", "type") format: "US-ETF_CO-ETF-ALL"
   * @returns Peer analytics
   *
   * Do the same thing as the Peers.get() ("fetch") but using the cluster analytics.
   */
  private async getPeerAnalyticsETFs(
    peerId,
    viewParams,
    segment?: PeerConstraints["segment"]
  ) {
    const { timeframe } = viewParams;
    let analyticsTimeBased: any = [];

    switch (timeframe) {
      default:
      case 0:
        analyticsTimeBased = ["TCR_D", "ABchange_%", "CDchange_%"];

        break;

      case 4:
        analyticsTimeBased = ["TCR_W", "ABchange_%_W", "CDchange_%_W"];

        break;

      case 19:
        analyticsTimeBased = ["TCR_M", "ABchange_%_M", "CDchange_%_M"];

        break;
    }

    // Necessary analytics
    const analyticsList = [
      "TCR",
      "A_%",
      "B_%",
      "C_%",
      "D_%",
      "A",
      "B",
      "C",
      "D",
      "cardinality",
      "upgrades_M",
      "downgrades_M",
      "upgrades_%_M",
      "downgrades_%_M",

      "upgrades_Q",
      "downgrades_Q",
      "upgrades_%_Q",
      "downgrades_%_Q",

      "upgrades_W",
      "downgrades_W",
      "upgrades_%_W",
      "downgrades_%_W",

      "upgrades",
      "downgrades",
      "upgrades_%",
      "downgrades_%",
    ].concat(analyticsTimeBased);
    // No clusters are needed
    const clusters = [];

    const apiClusterAnalytics: ClusterAnalytics =
      this.environment.get("http")["clusterAnalytics"];

    let response: any = null;

    if (segment != null) {
      let segmentCluster = "AssetClass";

      switch (segment) {
        default:
        case "ETFSEGMENT":
          segmentCluster = "ETFSEGMENT";

          break;
        case "1 Industry":
          break;

        case "3 Sector":
          segmentCluster = "Specialty";

          break;

        case "4 Subsector":
          segmentCluster = "ETF_Subsector";

          break;

        case "WWW":
          segmentCluster = "etfRootGeo";

          break;

        case "Area":
          segmentCluster = "etfgeoArea";

          break;

        case "Region":
          segmentCluster = "etfgeoRegion";

          break;

        case "Country":
          segmentCluster = "etfgeo";

          break;
      }

      response = await apiClusterAnalytics
        .createConfiguration()
        .clusters(clusters)
        .segment(segmentCluster)
        .method("INTERSECTION")
        .analytics(analyticsList)
        .universeFromPeer(peerId)
        .fetchAnalytics();

      const dataMap = response?.clustersStats?.stats;
      const dataList = Object.entries<any>(dataMap).map(([key, value]) => {
        let where: any = this.xDim;
        let what: any = this.yDim;

        if (
          segment === "ETFSEGMENT" ||
          segment === "1 Industry" ||
          segment === "3 Sector" ||
          segment === "4 Subsector"
        ) {
          what = key;
        } else if (
          segment === "WWW" ||
          segment === "Area" ||
          segment === "Region" ||
          segment === "Country"
        )
          where = key;

        const id = encodePeerId({
          zDimension: this.zDimension,
          what,
          where,
          type: "ETF",
        });
        return { ...value, id };
      });

      return dataList.map((peer) =>
        this.formatEtfClusterPeerResponse(peer, timeframe)
      );
    } else {
      response = await apiClusterAnalytics
        .createConfiguration()
        .clusters(clusters)
        .method("INTERSECTION")
        .analytics(analyticsList)
        .universeFromPeer(peerId)
        .fetchAnalytics();

      // SERVER returns a cluster key that is equal to ANY because
      // we have not specificated any cluster in request
      const CLUSTER_TAG = "ANY";
      const responseData = response?.clustersStats?.stats[CLUSTER_TAG];

      return this.formatEtfClusterPeerResponse(responseData, timeframe, peerId);
    }
  }

  formatEtfClusterPeerResponse = (
    responseData,
    timeframe: PeerConstraints["timeframe"],
    peerId?: string
  ) => {
    const resultObjPrototype = {
      id: peerId ?? responseData["id"],
      info: {
        cardinality: null,
        marketcap: null,
      },
      name: null,
      size: null,
      alerts: this.alertsPeer(responseData),
      statistic: {
        abPercentage: {
          today: null,
          yesterday: null,
          lastWeek: null,
          lastMonth: null,
        },
        cdPercentage: {
          today: null,
          yesterday: null,
          lastWeek: null,
          lastMonth: null,
        },
        downgrades: {
          today: {
            number: null,
            percentage: null,
          },
          yesterday: {
            number: null,
            percentage: null,
          },
          lastWeek: {
            number: null,
            percentage: null,
          },
          lastMonth: {
            number: null,
            percentage: null,
          },
        },
        upgrades: {
          today: {
            number: null,
            percentage: null,
          },
          yesterday: {
            number: null,
            percentage: null,
          },
          lastWeek: {
            number: null,
            percentage: null,
          },
          lastMonth: {
            number: null,
            percentage: null,
          },
        },
        abChanges: {
          today: null,
          yesterday: null,
          lastWeek: null,
          lastMonth: null,
        },
        cdChanges: {
          today: null,
          yesterday: null,
          lastWeek: null,
          lastMonth: null,
        },
        ratings: {
          today: {
            A: { percentage: null, number: null },
            B: { percentage: null, number: null },
            C: { percentage: null, number: null },
            D: { percentage: null, number: null },
          },
          yesterday: null,
          lastMonth: null,
          lastWeek: null,
        },
      },
      tcr: {
        today: null,
        yesterday: null,
        lastMonth: null,
        lastWeek: null,
      },
      type: null,
      what: null,
      where: null,
    };

    const timeframeKey = this.timeframeToPlainText(timeframe);

    resultObjPrototype["info"]["cardinality"] = responseData["cardinality"];

    // default week
    let timeframeKeyId = "_W";

    switch (timeframeKey) {
      case "today":
        timeframeKeyId = "";
        break;
      default:
      case "lastWeek":
        break;
      case "lastMonth":
        timeframeKeyId = "_M";
    }

    resultObjPrototype["statistic"]["abPercentage"][timeframeKey] =
      (responseData?.["A_%"] ?? 0) + (responseData?.["B_%"] ?? 0);
    resultObjPrototype["statistic"]["cdPercentage"][timeframeKey] =
      (responseData?.["C_%"] ?? 0) + (responseData?.["D_%"] ?? 0);
    //TCR today must be there everytime
    resultObjPrototype["tcr"]["today"] = responseData?.["TCR"];

    resultObjPrototype["statistic"]["ratings"]["today"]["A"]["percentage"] =
      responseData?.["A_%"] ?? 0;
    resultObjPrototype["statistic"]["ratings"]["today"]["B"]["percentage"] =
      responseData?.["B_%"] ?? 0;
    resultObjPrototype["statistic"]["ratings"]["today"]["C"]["percentage"] =
      responseData?.["C_%"] ?? 0;
    resultObjPrototype["statistic"]["ratings"]["today"]["D"]["percentage"] =
      responseData?.["D_%"] ?? 0;

    resultObjPrototype["statistic"]["ratings"]["today"]["A"]["number"] =
      responseData?.["A"] ?? 0;
    resultObjPrototype["statistic"]["ratings"]["today"]["B"]["number"] =
      responseData?.["B"] ?? 0;
    resultObjPrototype["statistic"]["ratings"]["today"]["C"]["number"] =
      responseData?.["C"] ?? 0;
    resultObjPrototype["statistic"]["ratings"]["today"]["D"]["number"] =
      responseData?.["D"] ?? 0;

    resultObjPrototype["tcr"][timeframeKey] =
      responseData?.[`TCR${timeframeKeyId}`];
    // This is explicitated because timeframe in UI cannot be set to yesterday
    // but needed for TCR alerts
    resultObjPrototype["tcr"]["yesterday"] = responseData?.["TCR_D"];

    // Upgrades asked all for report print
    resultObjPrototype["statistic"]["upgrades"]["today"]["percentage"] =
      responseData?.[`upgrades_%`];
    resultObjPrototype["statistic"]["upgrades"]["lastWeek"]["percentage"] =
      responseData?.[`upgrades_%_W`];
    resultObjPrototype["statistic"]["upgrades"]["lastMonth"]["percentage"] =
      responseData?.[`upgrades_%_M`];
    resultObjPrototype["statistic"]["upgrades"]["today"]["number"] =
      responseData?.[`upgrades`];
    resultObjPrototype["statistic"]["upgrades"]["lastWeek"]["number"] =
      responseData?.[`upgrades_W`];
    resultObjPrototype["statistic"]["upgrades"]["lastMonth"]["number"] =
      responseData?.[`upgrades_M`];

    // Downgrades
    resultObjPrototype["statistic"]["downgrades"]["today"]["percentage"] =
      responseData?.[`downgrades_%`];
    resultObjPrototype["statistic"]["downgrades"]["lastWeek"]["percentage"] =
      responseData?.[`downgrades_%_W`];
    resultObjPrototype["statistic"]["downgrades"]["lastMonth"]["percentage"] =
      responseData?.[`downgrades_%_M`];
    resultObjPrototype["statistic"]["downgrades"]["today"]["number"] =
      responseData?.[`downgrades`];
    resultObjPrototype["statistic"]["downgrades"]["lastWeek"]["number"] =
      responseData?.[`downgrades_W`];
    resultObjPrototype["statistic"]["downgrades"]["lastMonth"]["number"] =
      responseData?.[`downgrades_M`];

    resultObjPrototype["statistic"]["abChanges"][timeframeKey] =
      responseData?.[`ABchange_%${timeframeKeyId}`];
    resultObjPrototype["statistic"]["cdChanges"][timeframeKey] =
      responseData?.[`CDchange_%${timeframeKeyId}`];

    const { what, where, type, zDimension } = decodePeerId(
      peerId ?? resultObjPrototype["id"]
    );

    resultObjPrototype["what"] = what;
    resultObjPrototype["where"] = where;
    resultObjPrototype["size"] = zDimension;
    resultObjPrototype["type"] = type;

    return this.setPeerName(
      peerId ?? resultObjPrototype["id"],
      resultObjPrototype
    );
  };

  /**
   *
   * @param peer
   * @param peerId
   * @param segment
   * @param timeframe
   * @returns {promise} used to get all peer's children
   */
  private async getPeerChildren(
    peer: any,
    peerId: string,
    segment: PeerConstraints["segment"],
    timeframe: PeerConstraints["timeframe"]
  ) {
    return await this.getChildren(peer, peerId, segment, timeframe);
  }

  private async getPerformanceSinceRated(peerId: string) {
    return await this.storage.getPerformanceSinceTrend(peerId);
  }

  /**
   *
   * @param peer
   * @param peerId
   * @param segment
   * @returns all childrens of a determined peer
   */
  private async getChildren(
    peer,
    peerId,
    segment: PeerConstraints["segment"],
    timeframe: PeerConstraints["timeframe"]
  ) {
    const taxonomies = this.environment.get("setup")["taxonomies"];
    const fieldsMap = this.environment.get("setup")["taxonomyFields"];
    const peerType = peer.type === "ETF" ? "ETF" : "security";
    const fieldX = peerType === "ETF" ? "etfgeo" : "country";
    const fieldY = peerType === "ETF" ? "etfclass" : "icb";

    const rootNodeY = Object.values<any>(
      taxonomies[fieldsMap[peerType][fieldY]]
    ).find((node) => node.parent == null)["id"];
    const rootNodeX = Object.values<any>(
      taxonomies[fieldsMap[peerType][fieldX]]
    ).find((node) => node.parent == null)["id"];

    var peerWhatWhere = this.storage.getWhatWhereInfo(peerId);

    var what = peerWhatWhere["what"];
    var whatStore = peerWhatWhere["whatStore"];
    var whatType = peerWhatWhere["whatType"];
    var where = peerWhatWhere["where"];
    var whereStore = peerWhatWhere["whereStore"];
    var whereType = peerWhatWhere["whereType"];

    if (whatType === "4 Subsector" && whereType === "Country") {
      // tree leaf: no children
      return null;
    }

    var params: any = [[]];
    switch (segment) {
      // in the UI the label is Sector :-/
      case "4 Subsector":
      case "1 Industry":
      // in the UI the label is Industry :-/
      // eslint-disable-next-line no-fallthrough
      case "3 Sector": {
        let children = whatStore.filter((item) => item.type === segment);

        if (what !== rootNodeY && segment === "3 Sector") {
          children = [];
          const peerType = peer.type;
          const taxonomyType = peerType === "ETF" ? "ETF" : "security";
          const field = peerType === "ETF" ? "etfclass" : "icb";
          const sectorTaxonomy = taxonomies[fieldsMap[taxonomyType][field]];
          let parentLevel = null;

          for (const taxonomy in sectorTaxonomy) {
            const sector = sectorTaxonomy[taxonomy];

            if (sector.type === segment) {
              parentLevel =
                peerType === "ETF"
                  ? sector.parent
                  : sectorTaxonomy[sector.parent].parent;

              if (parentLevel === what) {
                children.push(sector);
              }
            }
          }
        } else if (what !== rootNodeY) {
          children = whatStore.filter((child) => child.parent === what);
        }

        children = children.sort(this.sortBy("name"));

        for (const child of children) {
          params[0].push({
            zDimension: peer.size,
            type: peer.type,
            what: child.id,
            where: where,
          });
        }

        break;
      }
      case "Area":
      case "Country":
      case "Region":
      case "WWW": {
        let children = whereStore.filter((item) => item.type === segment);

        if (where !== rootNodeX && segment === "Country") {
          // To get all countries from an area, it needs
          // to get all Regions from that area, then
          // add the filter
          if (whereType === "Area") {
            let areaChildren = whereStore
              .filter((item) => item.parent === where)
              .sort(this.sortBy("name"));

            var parents = areaChildren.map((child) => child.id);

            children = children.filter((item) => parents.includes(item.parent));
          } else {
            children = children.filter((item) => item.parent === where);
          }
        } else if (
          where !== rootNodeX &&
          (segment === "Region" || segment === "Area")
        ) {
          children = whereStore.filter((item) => item.parent === where);
        }

        children = children.sort(this.sortBy("name"));

        for (const child of children) {
          params[0].push({
            zDimension: peer.size,
            type: peer.type,
            what: what,
            where: child.id,
          });
        }

        break;
      }
      default: {
        console.log("Unknown childrenType", segment);
      }
    }

    const childrenPeerRaw = await this.getPeerAnalyticsETFs(
      peerId,
      { timeframe },
      this.segment ?? "1 Industry"
    );

    return {
      data: childrenPeerRaw,
      what: what,
      whatType: whatType,
      where: where,
      whereType: whereType,
    };
  }

  /**
   *
   * @param property
   * @param descending
   * @returns a callback used to sort an array by the property and direction provided
   */
  private sortBy(property, descending = false) {
    return (a, b) => {
      const valueA = property.split(".").reduce((item, prop) => item[prop], a);
      const valueB = property.split(".").reduce((item, prop) => item[prop], b);

      if (valueA > valueB) {
        return descending ? -1 : 1;
      }
      if (valueA < valueB) {
        return descending ? 1 : -1;
      }
      return 0;
    };
  }

  private alertsPeer(peerResponse) {
    function alertsRatio(upgrades: any, downgrades: any) {
      if (upgrades != null && downgrades != null) {
        if (upgrades === 0 && downgrades === 0) {
          return -500;
        } else if (downgrades === 0) {
          return upgrades * 1000;
        } else if (upgrades === 0) {
          return -1000 * downgrades;
        }
        return upgrades / downgrades;
      } else {
        return null;
      }
    }

    const alerts = {
      last3Months: {
        downgrades: peerResponse?.downgrades_Q,
        ratio: alertsRatio(
          peerResponse?.upgrades_Q,
          peerResponse?.downgrades_Q
        ),
        upgrades: peerResponse?.upgrades_Q,
      },
      lastMonth: {
        downgrades: peerResponse?.downgrades_M,
        ratio: alertsRatio(
          peerResponse?.upgrades_M,
          peerResponse?.downgrades_M
        ),
        upgrades: peerResponse?.downgrades_M,
      },
      lastWeek: {
        downgrades: peerResponse?.downgrades_W,
        ratio: alertsRatio(
          peerResponse?.upgrades_W,
          peerResponse?.downgrades_W
        ),
        upgrades: peerResponse?.upgrades_W,
      },
      today: {
        downgrades: peerResponse?.downgrades,
        ratio: alertsRatio(peerResponse?.upgrades, peerResponse?.downgrades),
        upgrades: peerResponse?.upgrades,
      },
    };

    return alerts;
  }

  /*********** FORMATTERS FOR UI ************/

  tableFormatterByField = (field: TableField) => {
    switch (field) {
      case "name":
        return this.formatName;
      case "tcr":
        return this.formatTcr;
      case "cardinality":
        return this.formatCardinality;
      case "abPerc":
        return this.formatABCDPerc;
      case "cdPerc":
        return this.formatABCDPerc;
      case "cd_changes":
      case "ab_changes":
        return this.formatPercentageChanges;
      case "tcr_changes":
        return this.formatTcrChanges;
      case "upgrades":
        return this.formatUpgradeDowngradeNumber;
      case "downgrades":
        return this.formatUpgradeDowngradeNumber;
      case "upgrades_perc":
        return this.formatUpgradesDowngradesPerc;
      case "downgrades_perc":
        return this.formatUpgradesDowngradesPerc;
      default:
        throw new Error(
          "The field you are looking for seems not to be associated with any formatter"
        );
    }
  };

  private turnSegmentInLabel = (sg: PeerConstraints["segment"]) => {
    switch (sg) {
      case "1 Industry":
        return "Asset Classes";

      case "3 Sector":
        return "Specialties";

      case "4 Subsector":
        return "Themes";

      case "Area":
        return "Areas";

      case "Region":
        return "Regions";

      case "WWW":
        return "World Wide";

      default:
        return sg;
    }
  };

  private timeframeToPlainText = (timeframe) => {
    switch (timeframe) {
      case 0:
      default:
        return "today";

      case 4:
        return "lastWeek";

      case 19:
        return "lastMonth";
    }
  };

  private formatNumber = (num: number, isPerc?: boolean) => {
    return num !== 0
      ? this.formatter.custom("number", {
          options: {
            isPercentage: isPerc ?? false,
            notAvailable: {
              input: null,
              output: "",
            },
          },
          output: "HTML",
          value: num,
          valueHelper: {
            normalizationThreshold: 1,
          },
        }) ?? ""
      : "";
  };

  private formatTcr = (cell: CellComponent, timeframe: 0 | 4 | 19) => {
    const data = cell.getData();
    const tcr = (data as any).tcr;
    const timeSegment = this.timeframeToPlainText(timeframe);

    return this.formatter.tcr(tcr[timeSegment], "HTML") ?? "";
  };

  private formatTcrChanges = (cell: CellComponent, timeframe: 0 | 4 | 19) => {
    const data = cell.getData();
    const tcr = (data as any).tcr["today"];
    const timeframeKey = this.timeframeToPlainText(timeframe);
    const tcrFrom =
      timeframe === 0
        ? (data as any).tcr["yesterday"]
        : (data as any).tcr[timeframeKey];

    if (tcr !== tcrFrom) {
      const stringPrefix = tcrFrom > tcr ? "downgraded to" : "upgraded to";

      const formatterOutputFormat = "HTML";
      const tcrHTML = this.formatter.tcr(tcr, formatterOutputFormat);
      const tcrFromHTML = this.formatter.tcr(tcrFrom, formatterOutputFormat);

      return `${stringPrefix} ${tcrHTML} from ${tcrFromHTML}`;
    } else {
      return "";
    }
  };

  private formatCardinality = (cell: CellComponent) => {
    const data = cell.getData();
    const cardinality = (data as any).info.cardinality;

    return `${cardinality ?? ""}`;
  };

  private formatABCDPerc = (
    cell: CellComponent,
    fieldKey: "abPercentage" | "cdPercentage",
    timeframe: 0 | 4 | 19
  ) => {
    const data = cell.getData();
    const statistics = (data as any).statistic;
    const isPercentage = true;
    const timeSegment = this.timeframeToPlainText(timeframe);

    return this.formatNumber(statistics[fieldKey][timeSegment], isPercentage);
  };

  private formatPercentageChanges = (
    cell: CellComponent,
    timeframe: 0 | 4 | 19,
    field: "abChanges" | "cdChanges"
  ) => {
    const data = cell.getData();
    const timeSegment = this.timeframeToPlainText(timeframe);

    // Adjust timeframe for changes
    const percentageFrom = data["statistic"][field][timeSegment];

    // 0: no change, 1: positive, -1: negative
    const percentageChanges =
      percentageFrom > 0 ? 1 : percentageFrom < 0 ? -1 : 0;

    let arrow = "";
    switch (percentageChanges) {
      case 1: {
        arrow = "&uarr;";

        break;
      }
      case -1: {
        arrow = "&darr;";

        break;
      }
      case 0:
      default: {
        arrow = "";

        break;
      }
    }

    const isPercentage = true;
    const percentageValueHTML = this.formatNumber(percentageFrom, isPercentage);

    return "<span>" + arrow + " " + percentageValueHTML + "</span>";
  };

  private formatUpgradeDowngradeNumber = (
    cell: CellComponent,
    fieldKey: "upgrades" | "downgrades",
    timeframe: 0 | 4 | 19
  ) => {
    const data = cell.getData();
    const timeSegment = this.timeframeToPlainText(timeframe);
    const numberByFieldKey = data["statistic"][fieldKey][timeSegment]["number"];
    const numberColor = fieldKey === "upgrades" ? "#008005" : "#F01100";

    return numberByFieldKey !== 0
      ? `<span style="cursor: pointer; color: ${numberColor}">${numberByFieldKey}</span>`
      : "";
  };

  private formatUpgradesDowngradesPerc = (
    cell: CellComponent,
    fieldKey: "upgrades" | "downgrades",
    timeframe: 0 | 4 | 19
  ) => {
    const data = cell.getData();
    const timeSegment = this.timeframeToPlainText(timeframe);
    const numberByFieldKey =
      data["statistic"][fieldKey][timeSegment]["percentage"];
    const isPercentage = true;

    return this.formatNumber(numberByFieldKey, isPercentage);
  };

  private formatName = (
    cell: CellComponent | RowComponent,
    segment: PeerConstraints["segment"]
  ) => {
    const data = cell.getData();
    let name = (data as any).name;

    if (name === "__ROOT__") {
      // if getRow property is in cell component it means that cell is of type CellComponent otherwise is of type RowComponent
      const row = "getRow" in cell ? cell?.getRow() : cell;
      const rowHtml = row?.getElement();
      if (rowHtml) {
        rowHtml.style.fontSize = "1.2em";
        rowHtml.style.fontWeight = "bold";
        rowHtml.style.backgroundColor = "rgba(255, 192, 1, 0.2)";
      }

      return `${this.turnSegmentInLabel(segment)}`;
    }

    const taxonomies = this.taxonomies;
    const taxonomiesFieldsMap = this.taxonomiesFieldsMap;
    const taxonomiesMapX =
      taxonomies[
        taxonomiesFieldsMap[(data as any).type === "ETF" ? "ETF" : "security"][
          (data as any).type === "ETF" ? "etfgeo" : "country"
        ]
      ];
    const taxonomiesMapY =
      taxonomies[
        taxonomiesFieldsMap[(data as any).type === "ETF" ? "ETF" : "security"][
          (data as any).type === "ETF" ? "etfclass" : "icb"
        ]
      ];

    switch (segment) {
      case "ETFSEGMENT":
      case "1 Industry":
      case "3 Sector":
      case "4 Subsector": {
        const nameTag = (data as any).what;
        const taxonomyName = taxonomiesMapY?.[nameTag]?.["name"];

        if (segment === "1 Industry" || segment === "3 Sector") {
          name =
            segment === "1 Industry"
              ? taxonomyName
              : formatTaxonPrefixingParent(
                  getTaxonById(nameTag, [taxonomiesMapY], 0),
                  [taxonomiesMapY],
                  "3 Sector"
                );
        } else if (segment === "4 Subsector") {
          name = formatTaxonPrefixingParent(
            getTaxonById(nameTag, [taxonomiesMapY], 0),
            [taxonomiesMapY],
            "4 Subsector"
          );
        }

        break;
      }

      case "Area":
      case "Country":
      case "Region":
      case "WWW":
        const nameTag = (data as any).where;
        const taxonomyName = taxonomiesMapX?.[nameTag]?.["name"];
        name =
          segment === "Region"
            ? formatTaxonPrefixingParent(
                getTaxonById(nameTag, [taxonomiesMapX], 0),
                [taxonomiesMapX],
                "Region"
              )
            : taxonomyName;

        break;
    }

    return name;
  };
}
