import { deepClone } from "../../../deepClone";
import { httpAll } from "../../../httpAll";
import { defaultTemplateReportPeer } from "../defaultTemplateReportPeer-ts";
import { defaultTemplateReportPeerDetail } from "../defaultTemplateReportPeerDetail-ts";
import { defaultTemplateReportPortfolio } from "../defaultTemplateReportPortfolio-ts";
import { defaultTemplateReportListDispersionTab } from "../defaultTemplateReportListDispersionTab-ts";
import { defaultTemplateReportRanking } from "../defaultTemplateReportRanking-ts";
import { defaultTemplateReportScreening } from "../defaultTemplateReportScreening-ts";
import { defaultTemplateReportStrategy } from "../defaultTemplateReportStrategy-ts";
import { defaultTemplateReportStrategiesAlphaFinder } from "../defaultTemplateReportStrategiesAlphaFinder-ts";
import { defaultTemplateReportStrategyLongShort } from "../defaultTemplateReportStrategyLongShort-ts";
import { defaultTemplateReportSystematicPortfolios } from "../defaultTemplateReportSystematicPortfolios-ts";
import { defaultTemplateReportPortfolioRiskControl } from "../defaultTemplateReportPortfolioRiskControl-ts";
import { defaultTemplateReportStrategyTracker } from "../defaultTemplateReportStrategyTracker-ts";
import { defaultTemplateAlertsReport } from "../deafultTemplateAlertsReport";

const baseUrl = appConfig.paths["trendrating-report/resources/images"];
export class Section {
  environment: any = null;
  productFlavourCode: any = null;

  constructor(env) {
    if (env == null) {
      throw new Error("[CRITICAL] No environment!");
    }
    this.environment = env;
    this.productFlavourCode =
      env["configuration"].get("product")["flavourCode"];
  }

  _sections = {
    // #region -------------------------- common sections to all reports
    REPORT_COMMON_ALLOCATION: {
      fieldPreview: {
        imageHeight: 27,
        imageUrl: baseUrl + "field_allocation.png",
        imageWidth: 100,
      },
      fieldType: "preview",

      label: "Allocation (Pie)",

      widgetClass:
        "trendrating-report/widgets/form/fields/allocation/Allocation",
      widgetClassName: "tFieldReportCommonAllocation",
      widgetType: "REPORT_COMMON_ALLOCATION",
      widgetValue: {
        content: {
          firstRows: 12,
          firstType: "MARKET",
          headline: {
            content: "Allocation",
            isEnabled: true,
          },
          secondRows: 12,
          secondType: "INDUSTRY",
          sortByWeight: true,
        },
        isEdited: false,
        presentation: {
          colorByPosition: false,
          first: true,
          second: true,
        },
        sections: null,
      },
    },
    REPORT_COMMON_COVER: {
      fieldPreview: {
        imageHeight: 27,
        imageUrl: baseUrl + "field_cover.png",
        imageWidth: 100,
      },
      fieldType: "preview",

      label: "Cover",

      widgetClass: "trendrating-report/widgets/form/fields/cover/Cover",
      widgetClassName: "tFieldReportCommonCover",
      widgetType: "REPORT_COMMON_COVER",
      widgetValue: {
        content: {
          subTitle: "",
          // eslint-disable-next-line no-template-curly-in-string
          title: "${name}",
        },
        isEdited: false,
        presentation: null,
        sections: null,
      },
    },
    REPORT_COMMON_DISCLAIMER: {
      fieldPreview: {
        imageHeight: 27,
        imageUrl: baseUrl + "field_disclaimer.png",
        imageWidth: 100,
      },
      fieldType: "preview",

      label: "Disclaimer",

      widgetClass:
        "trendrating-report/widgets/form/fields/disclaimer/Disclaimer",
      widgetClassName: "tFieldReportCommonDisclaimer",
      widgetType: "REPORT_COMMON_DISCLAIMER",
      widgetValue: {
        content: null,
        isEdited: false,
        presentation: null,
        sections: null,
      },
    },
    REPORT_COMMON_HEADER_1: {
      fieldPreview: {
        imageHeight: 27,
        imageUrl: baseUrl + "field_header1.png",
        imageWidth: 100,
      },
      fieldType: "preview",

      label: "Heading",

      widgetClass: "trendrating-report/widgets/form/fields/header1/Header1",
      widgetClassName: "tFieldReportCommonHeader",
      widgetType: "REPORT_COMMON_HEADER_1",
      widgetValue: {
        content: {
          text: "Heading",
        },
        isEdited: false,
        presentation: null,
        sections: null,
      },
    },
    REPORT_COMMON_PAGE_BREAK: {
      fieldPreview: {
        imageHeight: 27,
        imageUrl: baseUrl + "field_page_break.png",
        imageWidth: 100,
      },
      fieldType: "preview",

      label: "Page break",

      widgetClass: "trendrating-report/widgets/form/fields/pageBreak/PageBreak",
      widgetClassName: "tFieldReportCommonPageBreak",
      widgetType: "REPORT_COMMON_PAGE_BREAK",
      widgetValue: {
        content: null,
        isEdited: false,
        presentation: null,
        sections: null,
      },
    },
    REPORT_COMMON_PARAGRAPH: {
      fieldPreview: {
        imageHeight: 27,
        imageUrl: baseUrl + "field_paragraph.png",
        imageWidth: 100,
      },
      fieldType: "preview",

      label: "Paragraph",

      widgetClass: "trendrating-report/widgets/form/fields/paragraph/Paragraph",
      widgetClassName: "tFieldReportCommonParagraph",
      widgetType: "REPORT_COMMON_PARAGRAPH",
      widgetValue: {
        content: {
          fontSize: "normal",
          text: "",
        },
        isEdited: false,
        presentation: null,
        sections: null,
      },
    },
    REPORT_COMMON_SECURITY_CHART: {
      fieldPreview: {
        imageHeight: 27,
        imageUrl: baseUrl + "field_holdings_chart.png",
        imageWidth: 100,
      },
      fieldType: "preview",

      label: "Holdings (charts)",

      widgetClass:
        "trendrating-report/widgets/form/fields/holdingChart/HoldingChart",
      widgetClassName: "tFieldReportCommonHoldingChart",
      widgetType: "REPORT_COMMON_SECURITY_CHART",
      widgetValue: {
        content: {
          headline: {
            content: "Holdings",
            isEnabled: true,
          },
          rate: { A: false, B: false, C: false, D: false },
          sortBy: null,
          top: {
            content: 50,
            isEnabled: true,
          },
        },
        isEdited: false,
        presentation: null,
        sections: null,
      },
    },
    REPORT_LIST_ALERTS_TABLE: {
      fieldPreview: {
        imageHeight: 27,
        imageUrl: baseUrl + "field_holdings_table.png",
        imageWidth: 100,
      },
      fieldType: "preview",

      label: "Alerts",
      widgetClass:
        "trendrating-report/widgets/form/fields/holdingTable/HoldingTable",
      widgetClassName: "tFieldReportCommonHoldingTable",
      widgetType: "REPORT_LIST_ALERTS_TABLE",
      widgetValue: {
        content: {
          headline: {
            content: "",
            isEnabled: false,
          },
          sortBy: {
            property: "TCR",
            descending: true,
          },
          timeframe: "today",
          listType: "BASKET",
        },
        presentation: {
          columns: [
            {
              label: "Name",
              property: "name",
            },
            {
              label: "TCR",
              property: "TCR",
            },
            {
              label: "Upgrades",
              property: "upgrades",
            },
            {
              label: "Downgrades",
              property: "downgrades",
            },
            {
              label: "Movers Up",
              property: "moversUp",
            },
            {
              label: "Movers Down",
              property: "moversDown",
            },
          ],
        },
        sections: null,
      },
    },
    REPORT_COMMON_SECURITY_TABLE: {
      fieldPreview: {
        imageHeight: 27,
        imageUrl: baseUrl + "field_holdings_table.png",
        imageWidth: 100,
      },
      fieldType: "preview",

      label: "Holdings (table)",

      widgetClass:
        "trendrating-report/widgets/form/fields/holdingTable/HoldingTable",
      widgetClassName: "tFieldReportCommonHoldingTable",
      widgetType: "REPORT_COMMON_SECURITY_TABLE",
      widgetValue: {
        content: {
          headline: {
            content: "Holdings",
            isEnabled: true,
          },
          rate: { A: false, B: false, C: false, D: false },
          sortBy: null,
          top: {
            content: 5,
            isEnabled: false,
          },
        },
        isEdited: false,
        presentation: {
          columns: [
            {
              label: "Ticker",
              property: "ticker",
            },
            {
              label: "Name",
              property: "name",
            },
            {
              label: "Price",
              property: "vc",
            },
            {
              label: "Rating",
              property: "rc",
            },
            {
              label: "Rated on",
              property: "dr",
            },
            {
              label: "Smart momentum",
              property: "mc",
            },
            {
              label: "Retracement",
              property: "px",
            },
            {
              label: "New High-Low",
              property: "lhl",
            },
            {
              label: "Since rated",
              property: "pr",
            },
            {
              label: "Last week",
              property: "pw",
            },
            {
              label: "Market cap",
              property: "marketcap",
            },
            {
              label: "Sector",
              property: "industry",
            },
          ],
          rank: false,
          sortByRank: false,
          useWysiwyg: false,
        },
        sections: null,
      },
    },
    REPORT_CUSTOMIZABLE_PEER_TABLE: {
      fieldPreview: {
        imageHeight: 27,
        imageUrl: baseUrl + "field_holdings_table.png",
        imageWidth: 100,
      },
      fieldType: "preview",

      label: "Customizable Table",

      widgetClass:
        "trendrating-report/widgets/form/fields/holdingTable/HoldingTable",
      widgetClassName: "tFieldReportCommonHoldingTable",
      widgetType: "REPORT_CUSTOMIZABLE_PEER_TABLE",
      widgetValue: {
        content: {
          headline: {
            content: "",
            isEnabled: true,
            showTimeframeInTitle: true,
          },
          sort: {
            property: "tcr",
            descending: true,
          },
          timeframe: "lastMonth",
          segment: "1 Industry",
          hideWhenSingleRow: false,
          addPageBreak: false,
        },
        isEdited: false,
        presentation: {
          columns: [
            {
              label: "Name",
              property: "name",
            },
            {
              label: "TCR",
              property: "tcr",
            },
            {
              label: "Stocks",
              property: "cardinality",
            },
            {
              label: "AB%",
              property: "abPercentage",
            },
            {
              label: "CD%",
              property: "cdPercentage",
            },
            {
              label: "TCR Alert",
              property: "tcrAlerts",
            },
            {
              label: "AB changes",
              property: "abChanges",
            },
            {
              label: "Upgrades",
              property: "upgrades",
            },
            {
              label: "Downgrades",
              property: "downgrades",
            },
            {
              label: "Upgrades %",
              property: "upgradesPerc",
            },
            {
              label: "Downgrades %",
              property: "downgradesPerc",
            },
          ],
          useWysiwyg: false,
        },
        sections: null,
      },
    },
    REPORT_COMMON_SPACING: {
      fieldPreview: {
        imageHeight: 27,
        imageUrl: baseUrl + "field_vertical_spacing.png",
        imageWidth: 100,
      },
      fieldType: "preview",

      label: "Vertical spacing",

      widgetClass: "trendrating-report/widgets/form/fields/spacing/Spacing",
      widgetClassName: "tFieldReportCommonSpacing",
      widgetType: "REPORT_COMMON_SPACING",
      widgetValue: {
        content: {
          lines: 1,
        },
        isEdited: false,
        presentation: null,
        sections: null,
      },
    },
    REPORT_COMMON_TITLE: {
      fieldPreview: {
        imageHeight: 27,
        imageUrl: baseUrl + "field_title.png",
        imageWidth: 100,
      },
      fieldType: "preview",

      label: "Title",

      widgetClass: "trendrating-report/widgets/form/fields/title/Title",
      widgetClassName: "tFieldReportCommonTitle",
      widgetType: "REPORT_COMMON_TITLE",
      widgetValue: {
        content: {
          text: "Title",
        },
        isEdited: false,
        presentation: null,
        sections: null,
      },
    },
    // #endregion ------------------------------------------------------

    // #region ------------------------------- custom sections for peers
    REPORT_PEER_TCR_FOCUS: {
      fieldPreview: {
        imageHeight: 27,
        imageUrl: baseUrl + "field_peerOverview.png",
        imageWidth: 100,
      },
      fieldType: "preview",

      label: "Focus on TCR",

      widgetClass: "trendrating-report/widgets/form/fields/peer/sector/TCR",
      widgetClassName: "tFieldReportPeerSectorOverview",
      widgetType: "REPORT_PEER_TCR_FOCUS",
      widgetValue: {
        content: {
          headline: {
            content: "Focus on TCR",
            isEnabled: true,
          },
          segment: "3 Level",
          timeframe: "today",
        },
        isEdited: false,
        presentation: null,
        sections: null,
      },
    },
    REPORT_PEER_FOCUS_UPGRADES_DOWNGRADES: {
      fieldPreview: {
        imageHeight: 27,
        imageUrl: baseUrl + "field_peerOverview.png",
        imageWidth: 100,
      },
      fieldType: "preview",

      label: "Focus on Upgrades vs Downgrades",

      widgetClass: "trendrating-report/widgets/form/fields/peer/sector/TCR",
      widgetClassName: "tFieldReportPeerSectorOverview",
      widgetType: "REPORT_PEER_FOCUS_UPGRADES_DOWNGRADES",
      widgetValue: {
        content: {
          headline: {
            content: "Focus on Upgrades vs Downgrades",
            isEnabled: true,
          },
          segment: "3 Level",
          timeframe: "lastMonth",
        },
        isEdited: false,
        presentation: null,
        sections: null,
      },
    },
    REPORT_PEER_DISPERSION: {
      fieldPreview: {
        imageHeight: 27,
        imageUrl: baseUrl + "field_peerDispersion.png",
        imageWidth: 100,
      },
      fieldType: "preview",

      label: "Performance Dispersion",

      widgetClass:
        "trendrating-report/widgets/form/fields/peer/dispersion/Dispersion",
      widgetClassName: "tFieldReportPeerDispersion",
      widgetType: "REPORT_PEER_DISPERSION",
      widgetValue: {
        content: {
          headline: {
            content: "Performance Dispersion",
            isEnabled: true,
          },
          intervals: 4,
          timeframe: "3_months",
        },
        isEdited: false,
        presentation: null,
        sections: null,
      },
    },
    REPORT_PEER_DISPERSION_CHILDREN: {
      fieldPreview: {
        imageHeight: 27,
        imageUrl: baseUrl + "field_peerDispersionChildren.png",
        imageWidth: 100,
      },
      fieldType: "preview",

      label: "Performance Dispersion Breakdown",

      widgetClass:
        "trendrating-report/widgets/form/fields/peer/dispersionChildren/DispersionChildren",
      widgetClassName: "tFieldReportPeerDispersionChildren",
      widgetType: "REPORT_PEER_DISPERSION_CHILDREN",
      widgetValue: {
        content: {
          focusOn: "what",
          headline: {
            content: "Performance Dispersion Breakdown",
            isEnabled: true,
          },
          intervals: 4,
          timeframe: "3_months",
        },
        isEdited: false,
        presentation: null,
        sections: null,
      },
    },

    REPORT_PEER_TCR: {
      fieldPreview: {
        imageHeight: 27,
        imageUrl: baseUrl + "field_peerOverview.png",
        imageWidth: 100,
      },
      fieldType: "preview",

      label: "Market TCR",

      widgetClass: "trendrating-report/widgets/form/fields/peer/tcr/Tcr",
      widgetClassName: "tFieldReportPeerTcr",
      widgetType: "REPORT_PEER_TCR",
      widgetValue: {
        content: {
          headline: {
            content: "TCR",
            isEnabled: true,
          },
          timeframe: "lastMonth",
        },
        isEdited: false,
        presentation: null,
        sections: null,
      },
    },
    REPORT_PEER_AB_CHANGES: {
      fieldPreview: {
        imageHeight: 27,
        imageUrl: baseUrl + "field_peerAbChanges.png",
        imageWidth: 100,
      },
      fieldType: "preview",

      label: "Sector AB % changes",

      widgetClass:
        "trendrating-report/widgets/form/fields/peer/sectorAbChanges/SectorAbChanges",
      widgetClassName: "tFieldReportPeerAbChanges",
      widgetType: "REPORT_PEER_AB_CHANGES",
      widgetValue: {
        content: {
          headline: {
            content: "AB % changes",
            isEnabled: true,
          },
          timeframe: "lastMonth",
        },
        isEdited: false,
        presentation: null,
        sections: null,
      },
    },
    REPORT_PEER_WHAT_AB_CHANGES: {
      fieldPreview: {
        imageHeight: 27,
        imageUrl: baseUrl + "field_peerAbChanges.png",
        imageWidth: 100,
      },
      fieldType: "preview",

      label: "Sector AB % changes",

      widgetClass:
        "trendrating-report/widgets/form/fields/peer/sectorAbChanges/SectorAbChanges",
      widgetClassName: "tFieldReportPeerAbChanges",
      widgetType: "REPORT_PEER_WHAT_AB_CHANGES",
      widgetValue: {
        content: {
          headline: {
            content: "AB % changes",
            isEnabled: true,
          },
          timeframe: "lastMonth",
        },
        isEdited: false,
        presentation: null,
        sections: null,
      },
    },
    REPORT_PEER_OVERVIEW: {
      fieldPreview: {
        imageHeight: 27,
        imageUrl: baseUrl + "field_peerOverview.png",
        imageWidth: 100,
      },
      fieldType: "preview",

      label: "Overview",

      widgetClass:
        "trendrating-report/widgets/form/fields/peer/sectorOverview/SectorOverview",
      widgetClassName: "tFieldReportPeerSectorOverview",
      widgetType: "REPORT_PEER_OVERVIEW",
      widgetValue: {
        content: {
          headline: {
            content: "Sectors",
            hasChildren: false,
            isEnabled: true,
          },
        },
        isEdited: false,
        presentation: null,
        sections: null,
      },
    },
    REPORT_PEER_WHAT_OVERVIEW: {
      fieldPreview: {
        imageHeight: 27,
        imageUrl: baseUrl + "field_peerOverview.png",
        imageWidth: 100,
      },
      fieldType: "preview",

      label: "Sectors Overview",

      widgetClass:
        "trendrating-report/widgets/form/fields/peer/sectorOverview/SectorOverview",
      widgetClassName: "tFieldReportPeerSectorOverview",
      widgetType: "REPORT_PEER_WHAT_OVERVIEW",
      widgetValue: {
        content: {
          headline: {
            content: "Sectors",
            hasChildren: false,
            isEnabled: true,
          },
        },
        isEdited: false,
        presentation: null,
        sections: null,
      },
    },
    REPORT_PEER_TCR_CHANGES: {
      fieldPreview: {
        imageHeight: 27,
        imageUrl: baseUrl + "field_peerTcrChanges.png",
        imageWidth: 100,
      },
      fieldType: "preview",

      label: "Sector TCR Changes",

      widgetClass:
        "trendrating-report/widgets/form/fields/peer/sectorTcrChanges/SectorTcrChanges",
      widgetClassName: "tFieldReportPeerSectorTcrChanges",
      widgetType: "REPORT_PEER_TCR_CHANGES",
      widgetValue: {
        content: {
          headline: {
            content: "TCR Changes",
            isEnabled: true,
          },
          timeframe: "lastMonth",
        },
        isEdited: false,
        presentation: null,
        sections: null,
      },
    },
    REPORT_PEER_WHAT_TCR_CHANGES: {
      fieldPreview: {
        imageHeight: 27,
        imageUrl: baseUrl + "field_peerTcrChanges.png",
        imageWidth: 100,
      },
      fieldType: "preview",

      label: "Sector TCR Changes",

      widgetClass:
        "trendrating-report/widgets/form/fields/peer/sectorTcrChanges/SectorTcrChanges",
      widgetClassName: "tFieldReportPeerSectorTcrChanges",
      widgetType: "REPORT_PEER_WHAT_TCR_CHANGES",
      widgetValue: {
        content: {
          headline: {
            content: "TCR Changes",
            isEnabled: true,
          },
          timeframe: "lastMonth",
        },
        isEdited: false,
        presentation: null,
        sections: null,
      },
    },
    REPORT_PEER_UPGRADES_DOWNGRADES: {
      fieldPreview: {
        imageHeight: 27,
        imageUrl: baseUrl + "field_peerUpgradesDowngrades.png",
        imageWidth: 100,
      },
      fieldType: "preview",

      label: "Sector Upgrades / Downgrades",

      widgetClass:
        "trendrating-report/widgets/form/fields/peer/sectorUpgradesDowngrades/SectorUpgradesDowngrades",
      widgetClassName: "tFieldReportPeerSectorUpgradesDowngrades",
      widgetType: "REPORT_PEER_UPGRADES_DOWNGRADES",
      widgetValue: {
        content: {
          headline: {
            content: "Upgrades / Downgrades",
            isEnabled: true,
          },
          timeframe: "lastMonth",
        },
        isEdited: false,
        presentation: null,
        sections: null,
      },
    },
    REPORT_PEER_WHAT_UPGRADES_DOWNGRADES: {
      fieldPreview: {
        imageHeight: 27,
        imageUrl: baseUrl + "field_peerUpgradesDowngrades.png",
        imageWidth: 100,
      },
      fieldType: "preview",

      label: "Sector Upgrades / Downgrades",

      widgetClass:
        "trendrating-report/widgets/form/fields/peer/sectorUpgradesDowngrades/SectorUpgradesDowngrades",
      widgetClassName: "tFieldReportPeerSectorUpgradesDowngrades",
      widgetType: "REPORT_PEER_WHAT_UPGRADES_DOWNGRADES",
      widgetValue: {
        content: {
          headline: {
            content: "Upgrades / Downgrades",
            isEnabled: true,
          },
          timeframe: "lastMonth",
        },
        isEdited: false,
        presentation: null,
        sections: null,
      },
    },
    REPORT_PEER_WHERE_AB_CHANGES: {
      fieldPreview: {
        imageHeight: 27,
        imageUrl: baseUrl + "field_peerAbChanges.png",
        imageWidth: 100,
      },
      fieldType: "preview",

      label: "Market AB % changes",

      widgetClass:
        "trendrating-report/widgets/form/fields/peer/marketAbChanges/MarketAbChanges",
      widgetClassName: "tFieldReportPeerMarketAbChanges",
      widgetType: "REPORT_PEER_WHERE_AB_CHANGES",
      widgetValue: {
        content: {
          headline: {
            content: "AB % changes",
            isEnabled: true,
          },
          timeframe: "lastMonth",
        },
        isEdited: false,
        presentation: null,
        sections: null,
      },
    },
    REPORT_PEER_WHERE_OVERVIEW: {
      fieldPreview: {
        imageHeight: 27,
        imageUrl: baseUrl + "field_peerOverview.png",
        imageWidth: 100,
      },
      fieldType: "preview",

      label: "Market overview",

      widgetClass:
        "trendrating-report/widgets/form/fields/peer/marketOverview/MarketOverview",
      widgetClassName: "tFieldReportPeerMarketOverview",
      widgetType: "REPORT_PEER_WHERE_OVERVIEW",
      widgetValue: {
        content: {
          headline: {
            content: "Market overview",
            isEnabled: true,
          },
        },
        isEdited: false,
        presentation: null,
        sections: null,
      },
    },
    REPORT_PEER_WHERE_TCR_CHANGES: {
      fieldPreview: {
        imageHeight: 27,
        imageUrl: baseUrl + "field_peerTcrChanges.png",
        imageWidth: 100,
      },
      fieldType: "preview",

      label: "Market TCR Changes",

      widgetClass:
        "trendrating-report/widgets/form/fields/peer/marketTcrChanges/MarketTcrChanges",
      widgetClassName: "tFieldReportPeerMarketTcrChanges",
      widgetType: "REPORT_PEER_WHERE_TCR_CHANGES",
      widgetValue: {
        content: {
          headline: {
            content: "TCR Changes",
            isEnabled: true,
          },
          timeframe: "lastMonth",
        },
        isEdited: false,
        presentation: null,
        sections: null,
      },
    },
    REPORT_PEER_WHERE_UPGRADES_DOWNGRADES: {
      fieldPreview: {
        imageHeight: 27,
        imageUrl: baseUrl + "field_peerUpgradesDowngrades.png",
        imageWidth: 100,
      },
      fieldType: "preview",

      label: "Market Upgrades / Downgrades",

      widgetClass:
        "trendrating-report/widgets/form/fields/peer/marketUpgradesDowngrades/MarketUpgradesDowngrades",
      widgetClassName: "tFieldReportPeerMarketUpgradesDowngrades",
      widgetType: "REPORT_PEER_WHERE_UPGRADES_DOWNGRADES",
      widgetValue: {
        content: {
          headline: {
            content: "Upgrades / Downgrades",
            isEnabled: true,
          },
          timeframe: "lastMonth",
        },
        isEdited: false,
        presentation: null,
        sections: null,
      },
    },
    // #endregion ------------------------------------------------------

    // #region -------------- custom sections for peers (markets detail)
    REPORT_DISPERSION_BY_CHART: {
      fieldPreview: {
        imageHeight: 27,
        imageUrl: baseUrl + "field_peerDispersionByChart.png",
        imageWidth: 100,
      },
      fieldType: "preview",

      label: "Dispersion by sector chart",

      widgetClass:
        "trendrating-report/widgets/form/fields/peer/dispersionByChart/DispersionByChart",
      widgetClassName: "tFieldReportPeerDispersionByChart",
      widgetType: "REPORT_DISPERSION_BY_CHART",
      widgetValue: {
        content: {
          headline: {
            content: "Dispersion by sector chart",
            isEnabled: true,
          },
          title: {
            content: "",
            isEnabled: false,
          },
          intervals: 4,
          timeframe: "3_months",
          segment: "1 Industry",
          trimOutliers: false,
          hideIfOneResult: false,
          addPageBreak: false,
          showTimeframeInTitle: true,
        },
        isEdited: false,
        presentation: {
          logAxis: true,
          legend: true,
          size: "SMALL",
          align: "left",
        },
        sections: null,
      },
    },
    REPORT_DISPERSION_BY_SECTORS: {
      fieldPreview: {
        imageHeight: 27,
        imageUrl: baseUrl + "field_peerDispersionBySectors.png",
        imageWidth: 100,
      },
      fieldType: "preview",

      label: "Performance dispersion by sector",

      widgetClass:
        "trendrating-report/widgets/form/fields/peer/dispersionByChart/DispersionBySectors",
      widgetClassName: "tFieldReportPeerDispersionBySectors",
      widgetType: "REPORT_DISPERSION_BY_SECTORS",
      widgetValue: {
        content: {
          headline: {
            content: "Performance dispersion table",
            isEnabled: false,
          },
          intervals: 4,
          timeframe: "3_months",
          segment: "1 Industry",
          trimOutliers: false,
          hideIfOneResult: true,
          addPageBreak: false,
          showTimeframeInTitle: true,
        },
        isEdited: false,
        presentation: null,
        sections: null,
      },
    },
    REPORT_DISPERSION_TCR_TABLE: {
      fieldPreview: {
        imageHeight: 27,
        imageUrl: baseUrl + "field_peerDispersionTcrTable.png",
        imageWidth: 100,
      },
      fieldType: "preview",

      label: "Rating by sector",

      widgetClass:
        "trendrating-report/widgets/form/fields/peer/dispersionByChart/DispersionTcrTable",
      widgetClassName: "tFieldReportPeerDispersionTcrTable",
      widgetType: "REPORT_DISPERSION_TCR_TABLE",
      widgetValue: {
        content: {
          headline: {
            content: "Rating by sector",
            isEnabled: true,
          },
          segment: "1 Industry",
          sort: {
            property: "rc",
            descending: false,
          },
          hideIfOneResult: false,
        },
        isEdited: false,
        presentation: null,
        sections: null,
      },
    },
    REPORT_DISPERSION_RATIO_TABLE: {
      fieldPreview: {
        imageHeight: 27,
        imageUrl: baseUrl + "field_peerDispersionRatioTable.png",
        imageWidth: 100,
      },
      fieldType: "preview",

      label: "Ratio upgrades downgrades",

      widgetClass:
        "trendrating-report/widgets/form/fields/peer/dispersionByChart/DispersionRatioTable",
      widgetClassName: "tFieldReportPeerDispersionRatioTable",
      widgetType: "REPORT_DISPERSION_RATIO_TABLE",
      widgetValue: {
        content: {
          headline: {
            content: "Ratio upgrades downgrades - ",
            isEnabled: true,
          },
          segment: "1 Industry",
          timeframe: "today",
          hideIfOneResult: false,
          useWysiwyg: true,
        },
        isEdited: false,
        presentation: null,
        sections: null,
      },
    },
    // #endregion ------------------------------------------------------

    // #region -------------------------------- custom sections for list
    REPORT_DISPERSION_OVERVIEW: {
      fieldPreview: {
        imageHeight: 27,
        imageUrl: baseUrl + "field_peerDispersion.png",
        imageWidth: 100,
      },
      fieldType: "preview",

      label: "Performance Dispersion",

      widgetClass:
        "trendrating-report/widgets/form/fields/peer/dispersion/Dispersion",
      widgetClassName: "tFieldReportPeerDispersion",
      widgetType: "REPORT_DISPERSION_OVERVIEW",
      widgetValue: {
        content: {
          headline: {
            content: "Performance Dispersion",
            isEnabled: true,
          },
          intervals: 4,
          timeframe: "1_month",
        },
        isEdited: false,
        presentation: null,
        sections: null,
      },
    },
    REPORT_BASKET_DISPERSION_BY_CHART: {
      fieldPreview: {
        imageHeight: 27,
        imageUrl: baseUrl + "field_dispersion_chart.png",
        imageWidth: 100,
      },
      fieldType: "preview",

      label: "Performance dispersion chart",

      widgetClass:
        "trendrating-report/widgets/form/fields/dispersionByChartBasket/DispersionByChartBasket",
      widgetClassName: "tFieldReportPeerDispersionByChart",
      widgetType: "REPORT_BASKET_DISPERSION_BY_CHART",
      widgetValue: {
        content: {
          headline: {
            content: "Dispersion by sector chart",
            isEnabled: true,
          },
          title: {
            content: "",
            isEnabled: false,
          },
          intervals: 4,
          timeframe: "3_months",
          segment: "1 Industry",
          trimOutliers: false,
          hideIfOneResult: false,
          addPageBreak: false,
          showTimeframeInTitle: true,
          useWysiwyg: true,
        },
        isEdited: false,
        presentation: {
          logAxis: true,
          legend: true,
          size: "SMALL",
          align: "left",
        },
        sections: null,
      },
    },
    REPORT_BASKET_DISPERSION_BY_SECTORS: {
      fieldPreview: {
        imageHeight: 27,
        imageUrl: baseUrl + "field_holdings_table.png",
        imageWidth: 100,
      },
      fieldType: "preview",

      label: "Performance dispersion table",

      widgetClass:
        "trendrating-report/widgets/form/fields/dispersionBasketBy/dispersionBasketBy",
      widgetClassName: "tFieldReportPeerDispersionBySectors",
      widgetType: "REPORT_BASKET_DISPERSION_BY_SECTORS",
      widgetValue: {
        content: {
          headline: {
            content: "Performance dispersion table",
            isEnabled: true,
          },
          intervals: 4,
          timeframe: "3_months",
          segment: "1 Industry",
          trimOutliers: false,
          hideIfOneResult: false,
          addPageBreak: false,
          showTimeframeInTitle: true,
          useWysiwyg: true,
        },
        isEdited: false,
        presentation: null,
        sections: null,
      },
    },
    REPORT_PORTFOLIO_MOMENTUM_BREAKDOWN: {
      fieldPreview: {
        imageHeight: 27,
        imageUrl: baseUrl + "field_momentumBreakdown.png",
        imageWidth: 100,
      },
      fieldType: "preview",

      label: "Trend Capture Breakdown",

      widgetClass:
        "trendrating-report/widgets/form/fields/momentumBreakdown/MomentumBreakdown",
      widgetClassName: "tFieldReportPortfolioMomentumBreakdown",
      widgetType: "REPORT_PORTFOLIO_MOMENTUM_BREAKDOWN",
      widgetValue: {
        content: {
          dataCross: "MARKET_INDUSTRY",
          headline: {
            content: "Trend Capture Breakdown",
            isEnabled: true,
          },
          isBreadth: false,
        },
        isEdited: false,
        presentation: null,
        sections: null,
      },
    },
    REPORT_PEER_TRENDS_TRACKER: {
      fieldPreview: {
        imageHeight: 27,
        imageUrl: baseUrl + "field_new_high_new_low.png",
        imageWidth: 100,
      },
      fieldType: "preview",

      label: "Trends Tracker",

      widgetClass:
        "trendrating-report/widgets/form/fields/newHighLow/NewHighLow",
      widgetClassName: "tFieldReportPortfolioNewHighLow",
      widgetType: "REPORT_PEER_TRENDS_TRACKER",
      widgetValue: {
        content: {
          heading1: "Comparison of recent ratings",
          heading2: "Comparison of all issued ratings",
          headline: {
            content: "Trends Tracker",
            isEnabled: true,
          },
        },
        presentation: {
          Upgrades: true,
          Downgrades: true,
        },
        sections: null,
        isEdited: false,
      },
    },
    REPORT_PORTFOLIO_NEW_HIGH_NEW_LOW: {
      fieldPreview: {
        imageHeight: 27,
        imageUrl: baseUrl + "field_new_high_new_low.png",
        imageWidth: 100,
      },
      fieldType: "preview",

      label: "New Highs and New Lows",

      widgetClass:
        "trendrating-report/widgets/form/fields/newHighLow/NewHighLow",
      widgetClassName: "tFieldReportPortfolioNewHighLow",
      widgetType: "REPORT_PORTFOLIO_NEW_HIGH_NEW_LOW",
      widgetValue: {
        content: {
          headline: {
            content: "New highs and new lows",
            isEnabled: true,
          },
          numberOfItems: 5,
        },
        isEdited: false,
        presentation: {
          newHigh: true,
          newLow: true,
        },
        sections: null,
      },
    },
    REPORT_PORTFOLIO_PERFORMER: {
      fieldPreview: {
        imageHeight: 27,
        imageUrl: baseUrl + "field_performer_positive.png",
        imageWidth: 100,
      },
      fieldType: "preview",

      label: "Top Bottom Performers",

      widgetClass: "trendrating-report/widgets/form/fields/performer/Performer",
      widgetClassName: "tFieldReportPortfolioPerformer",
      widgetType: "REPORT_PORTFOLIO_PERFORMER",
      widgetValue: {
        content: {
          headline: {
            content: "A&B Top performers",
            isEnabled: true,
          },
          numberOfItems: 5,
          type: "POSITIVE",
        },
        isEdited: false,
        presentation: {
          "05_days": true,
          "20_days": true,
          "60_days": true,
        },
        sections: null,
      },
    },
    REPORT_PORTFOLIO_RATING_CHANGE: {
      fieldPreview: {
        imageHeight: 27,
        imageUrl: baseUrl + "field_rating_change.png",
        imageWidth: 100,
      },
      fieldType: "preview",

      label: "Rating changes",

      widgetClass:
        "trendrating-report/widgets/form/fields/ratingChange/RatingChange",
      widgetClassName: "tFieldReportPortfolioRatingChange",
      widgetType: "REPORT_PORTFOLIO_RATING_CHANGE",
      widgetValue: {
        content: {
          daysFromToday: null,
          headline: {
            content: "Rating Changes",
            isEnabled: true,
          },
          numberOfItems: 5,
        },
        isEdited: false,
        presentation: {
          downgrade: true,
          upgrade: true,
        },
        sections: null,
      },
    },
    REPORT_PORTFOLIO_TCR: {
      fieldPreview: {
        imageHeight: 27,
        imageUrl: baseUrl + "field_tcr.png",
        imageWidth: 100,
      },
      fieldType: "preview",

      label: "TCR",

      widgetClass: "trendrating-report/widgets/form/fields/tcr/Tcr",
      widgetClassName: "tFieldReportPortfolioTcr",
      widgetType: "REPORT_PORTFOLIO_TCR",
      widgetValue: {
        content: {
          headline: {
            content: "TCR",
            isEnabled: true,
          },
          marketAllocation: "MARKET",
          marketRows: 8,
          sectorAllocation: "SECTOR",
          sectorRows: 8,
        },
        isEdited: false,
        presentation: {
          portfolioMomentumRating: true,
          ratingWeight: true,
          alert: true,
          marketAllocation: true,
          sectorAllocation: true,
        },
        sections: null,
      },
    },
    // #endregion ------------------------------------------------------

    // #region ---- custom sections for strategies/systematic portfolios
    REPORT_STRATEGY_AS_OF_TODAY_PERFORMANCE: {
      fieldPreview: {
        imageHeight: 27,
        imageUrl: baseUrl + "field_as_of_today_performance.png",
        imageWidth: 100,
      },
      fieldType: "preview",

      label: "As of today Performance",

      widgetClass:
        "trendrating-report/widgets/form/fields/strategy/asOfTodayPerformance/AsOfTodayPerformance",
      widgetClassName: "tFieldReportStrategyAsOfTodayPerformance",
      widgetType: "REPORT_STRATEGY_AS_OF_TODAY_PERFORMANCE",
      widgetValue: {
        content: {
          benchmark: {
            content: "Benchmark",
            isEnabled: true,
          },
          delta: {
            content: "Difference",
            isEnabled: true,
          },
          headline: {
            content: "Performance",
            isEnabled: true,
          },
          performance10Years: {
            content: "10 years",
            isEnabled: true,
            strict: true,
            annualized: true,
          },
          performance1Day: {
            content: "1 day",
            isEnabled: false,
          },
          performance1Month: {
            content: "1 month",
            isEnabled: true,
          },
          performance1Week: {
            content: "1 week",
            isEnabled: false,
          },
          performance1Year: {
            content: "1 year",
            isEnabled: true,
            strict: false,
            annualized: true,
          },
          performance3Months: {
            content: "3 months",
            isEnabled: true,
          },
          performance3Years: {
            content: "3 years",
            isEnabled: true,
            strict: false,
            annualized: true,
          },
          performance5Years: {
            content: "5 years",
            isEnabled: true,
            strict: false,
            annualized: true,
          },
          performanceLTD: {
            content: "Starting date",
            isEnabled: true,
            annualized: true,
          },
          performanceMTD: {
            content: "MTD",
            isEnabled: true,
          },
          performanceQTD: {
            content: "QTD",
            isEnabled: true,
          },
          performanceYTD: {
            content: "YTD",
            isEnabled: true,
          },
          strategy: {
            content: "Strategy",
            isEnabled: true,
          },
        },
        isEdited: false,
        presentation: null,
        sections: null,
      },
    },
    REPORT_STRATEGY_BREAKDOWN: {
      fieldPreview: {
        imageHeight: 27,
        imageUrl: baseUrl + "field_breakdown.png",
        imageWidth: 100,
      },
      fieldType: "preview",

      label: "Allocation (Table)",

      widgetClass:
        "trendrating-report/widgets/form/fields/strategy/breakdown/Breakdown",
      widgetClassName: "tFieldReportStrategyBreakdown",
      widgetType: "REPORT_STRATEGY_BREAKDOWN",
      widgetValue: {
        content: {
          headline: {
            content: "Allocation",
            isEnabled: true,
          },
          dimension: "MARKET",
          rows: 11,
        },
        isEdited: false,
        presentation: {
          holdings: true,
          totalWeight: true,
        },
        sections: null,
      },
    },
    REPORT_STRATEGY_CHART: {
      fieldPreview: {
        imageHeight: 27,
        imageUrl: baseUrl + "field_holdings_strategy_chart.png",
        imageWidth: 100,
      },
      fieldType: "preview",

      label: "Chart",

      widgetClass:
        "trendrating-report/widgets/form/fields/strategy/chart/Chart",
      widgetClassName: "tFieldReportStrategyChart",
      widgetType: "REPORT_STRATEGY_CHART",
      widgetValue: {
        content: {
          benchmark: true,
          headline: {
            content: "",
            isEnabled: false,
          },
          title: {
            content: "",
            isEnabled: true,
          },
        },
        isEdited: false,
        presentation: {
          align: "left",
          logAxis: true,
          legend: true,
          size: "large",
        },
        sections: null,
      },
    },
    REPORT_STRATEGY_FACTS: {
      fieldPreview: {
        imageHeight: 27,
        imageUrl: baseUrl + "field_facts.png",
        imageWidth: 100,
      },
      fieldType: "preview",

      label: "Characteristics",

      widgetClass:
        "trendrating-report/widgets/form/fields/strategy/facts/Facts",
      widgetClassName: "tFieldReportStrategyFacts",
      widgetType: "REPORT_STRATEGY_FACTS",
      widgetValue: {
        content: {
          currency: {
            content: "",
            label: "Currency",
            isEnabled: true,
          },
          customText1: {
            content: "",
            label: "",
            isEnabled: false,
          },
          customText2: {
            content: "",
            label: "",
            isEnabled: false,
          },
          customText3: {
            content: "",
            label: "",
            isEnabled: false,
          },
          headline: {
            content: "Characteristics",
            isEnabled: true,
          },
          launchDate: {
            content: "",
            label: "Starting date",
            isEnabled: true,
          },
          endingDate: {
            content: "",
            label: "As of date",
            isEnabled: true,
          },
          numberOfHoldings: {
            content: "",
            label: "Number of holdings",
            isEnabled: true,
          },
          performance: {
            content: "",
            label: "Performance",
            isEnabled: true,
          },
          rebalancingFrequency: {
            content: "",
            label: "Rebalancing frequency",
            isEnabled: true,
          },
        },
        isEdited: false,
        presentation: null,
        sections: null,
      },
    },
    REPORT_STRATEGY_HOLDINGS: {
      fieldPreview: {
        imageHeight: 27,
        imageUrl: baseUrl + "field_holdings_table.png",
        imageWidth: 100,
      },
      fieldType: "preview",

      label: "Holdings",

      widgetClass:
        "trendrating-report/widgets/form/fields/strategy/holdings/Holdings",
      widgetClassName: "tFieldReportStrategyHoldings",
      widgetType: "REPORT_STRATEGY_HOLDINGS",
      widgetValue: {
        content: {
          headline: {
            content: "Holdings",
            isEnabled: true,
          },
          sortBy: {
            property: "marketcap",
            descending: true,
          },
          top: {
            content: 0,
            isEnabled: false,
          },
        },
        isEdited: false,
        presentation: {
          columns: [
            {
              label: "Weight",
              property: "weight",
            },
            {
              label: "Ticker",
              property: "ticker",
            },
            {
              label: "Name",
              property: "name",
            },
            {
              label: "Market",
              property: "country",
            },
            {
              label: "Currency",
              property: "currency",
            },
            {
              label: "Sub-Sector",
              property: "icb",
            },
            {
              label: "Rating",
              property: "rc",
            },
            {
              label: "Performance",
              property: "performance",
            },
            {
              label: "Contribution",
              property: "contribution",
            },
          ],
        },
        sections: null,
      },
    },
    REPORT_STRATEGY_KEY_FACTS: {
      fieldPreview: {
        imageHeight: 27,
        imageUrl: baseUrl + "field_key_facts.png",
        imageWidth: 100,
      },
      fieldType: "preview",

      label: "Key Facts",

      widgetClass:
        "trendrating-report/widgets/form/fields/strategy/keyFacts/KeyFacts",
      widgetClassName: "tFieldReportStrategyKeyFacts",
      widgetType: "REPORT_STRATEGY_KEY_FACTS",
      widgetValue: {
        content: {
          annualized: {
            content: "Annualized",
            isEnabled: true,
          },
          averageYearlyDrawdown: {
            content: "Average yearly drawdown",
            isEnabled: true,
          },
          benchmark: {
            content: "Benchmark",
            isEnabled: true,
          },
          beta: {
            content: "Beta",
            isEnabled: true,
          },
          cumulative: {
            content: "Cumulative",
            isEnabled: true,
          },
          delta: {
            content: "Difference",
            isEnabled: true,
          },
          headlineKeyRatio: {
            content: "Key Ratios",
            isEnabled: true,
          },
          headlinePerformance: {
            content: "Performance",
            isEnabled: true,
          },
          headlineRisk: {
            content: "Risk",
            isEnabled: true,
          },
          maxConsecutiveNegativeMonths: {
            content: "Max consecutive negative months",
            isEnabled: true,
          },
          maxConsecutivePositiveMonths: {
            content: "Max consecutive positive months",
            isEnabled: true,
          },
          maxDrawdown: {
            content: "Max drawdown",
            isEnabled: true,
          },
          monthlyStandardDeviation: {
            content: "Monthly Standard Deviation",
            isEnabled: true,
          },
          oneWayTurnover: {
            content: "One Way Turnover",
            isEnabled: true,
          },
          percentPositiveMonths: {
            content: "% Positive Months",
            isEnabled: true,
          },
          sharpeRatio: {
            content: "Sharpe Ratio",
            isEnabled: true,
          },
          sortinoRatio: {
            content: "Sortino Ratio",
            isEnabled: true,
          },
          sterlingRatio: {
            content: "Sterling Ratio",
            isEnabled: true,
          },
          strategy: {
            content: "Strategy",
            isEnabled: true,
          },
          informationRatio: {
            content: "Information Ratio",
            isEnabled: true,
          },
          trackingError: {
            content: "Tracking Error",
            isEnabled: true,
          },
          treynorRatio: {
            content: "Treynor ratio",
            isEnabled: true,
          },
          yearlyAverage: {
            content: "Yearly average",
            isEnabled: true,
          },
        },
        isEdited: false,
        presentation: null,
        sections: null,
      },
    },
    REPORT_STRATEGY_MONTHLY_ANALYTICS: {
      fieldPreview: {
        imageHeight: 27,
        imageUrl: baseUrl + "field_monthly_analytics.png",
        imageWidth: 100,
      },
      fieldType: "preview",

      label: "Monthly Strategy Analytics",

      widgetClass:
        "trendrating-report/widgets/form/fields/strategy/monthlyAnalytics/MonthlyAnalytics",
      widgetClassName: "tFieldReportStrategyMonthlyAnalytics",
      widgetType: "REPORT_STRATEGY_MONTHLY_ANALYTICS",
      widgetValue: {
        content: {
          headline: {
            content: "Monthly Analytics (%)",
            isEnabled: true,
          },
          maxDrawdown: {
            content: "Max drawdown",
            isEnabled: true,
          },
          monthlyPerformance: {
            content: "Performance",
            isEnabled: true,
          },
          monthlyStandardDeviation: {
            content: "Std dev.",
            isEnabled: true,
          },
          years: "ROWS",
        },
        isEdited: false,
        presentation: null,
        sections: null,
      },
    },
    REPORT_STRATEGY_QUARTERLY_ANALYTICS: {
      fieldPreview: {
        imageHeight: 27,
        imageUrl: baseUrl + "field_quarterly_analytics.png",
        imageWidth: 100,
      },
      fieldType: "preview",

      label: "Quarterly Strategy Analytics",

      widgetClass:
        "trendrating-report/widgets/form/fields/strategy/quarterlyAnalytics/QuarterlyAnalytics",
      widgetClassName: "tFieldReportStrategyQuarterlyAnalytics",
      widgetType: "REPORT_STRATEGY_QUARTERLY_ANALYTICS",
      widgetValue: {
        content: {
          headline: {
            content: "Quarterly Analytics (%)",
            isEnabled: true,
          },
          maxDrawdown: {
            content: "Max drawdown",
            isEnabled: true,
          },
          quarterlyPerformance: {
            content: "Performance",
            isEnabled: true,
          },
          quarterlyStandardDeviation: {
            content: "Std dev.",
            isEnabled: true,
          },
          years: "ROWS",
        },
        isEdited: false,
        presentation: null,
        sections: null,
      },
    },
    REPORT_STRATEGY_SUMMARY: {
      fieldPreview: {
        imageHeight: 27,
        imageUrl: baseUrl + "field_summary.png",
        imageWidth: 100,
      },
      fieldType: "preview",

      label: "Systematic Strategy",

      widgetClass:
        "trendrating-report/widgets/form/fields/strategy/summary/Summary",
      widgetClassName: "tFieldReportStrategySummary",
      widgetType: "REPORT_STRATEGY_SUMMARY",
      widgetValue: {
        content: {
          headline: {
            content: "Systematic Strategy",
            isEnabled: true,
          },
        },
        isEdited: false,
        presentation: null,
        sections: null,
      },
    },
    REPORT_STRATEGY_YEARLY_ANALYTICS: {
      fieldPreview: {
        imageHeight: 27,
        imageUrl: baseUrl + "field_yearly_analytics.png",
        imageWidth: 100,
      },
      fieldType: "preview",

      label: "Calendar Year Analytics",

      widgetClass:
        "trendrating-report/widgets/form/fields/strategy/yearlyAnalytics/YearlyAnalytics",
      widgetClassName: "tFieldReportStrategyYearlyAnalytics",
      widgetType: "REPORT_STRATEGY_YEARLY_ANALYTICS",
      widgetValue: {
        content: {
          annualized: {
            content: "Annualized",
            isEnabled: true,
          },
          benchmark: {
            content: "Benchmark",
            isEnabled: true,
          },
          delta: {
            content: "Difference",
            isEnabled: true,
          },
          headline: {
            content: "Calendar Year Analytics (%)",
            isEnabled: true,
          },
          maxDrawdown: {
            content: "Max drawdown",
            isEnabled: true,
          },
          monthlyStandardDeviation: {
            content: "Monthly return std dev.",
            isEnabled: true,
          },
          strategy: {
            content: "Strategy",
            isEnabled: true,
          },
          turnover: {
            content: "One way turnover",
            isEnabled: true,
          },
          yearlyPerformance: {
            content: "Yearly Performance",
            isEnabled: true,
          },
          years: "ROWS",
        },
        isEdited: false,
        presentation: null,
        sections: null,
      },
    },
    REPORT_STRATEGY_YEAR_TO_DATE_ANALYTICS: {
      fieldPreview: {
        imageHeight: 27,
        imageUrl: baseUrl + "field_year_to_date_analytics.png",
        imageWidth: 100,
      },
      fieldType: "preview",

      label: "YTD Analytics",

      widgetClass:
        "trendrating-report/widgets/form/fields/strategy/yearToDateAnalytics/YearToDateAnalytics",
      widgetClassName: "tFieldReportStrategyYearToDateAnalytics",
      widgetType: "REPORT_STRATEGY_YEAR_TO_DATE_ANALYTICS",
      widgetValue: {
        content: {
          benchmark: {
            content: "Benchmark",
            isEnabled: true,
          },
          delta: {
            content: "Difference",
            isEnabled: true,
          },
          headline: {
            content: "YTD Analytics",
            isEnabled: true,
          },
          maxDrawdown: {
            content: "Max drawdown",
            isEnabled: true,
          },
          monthlyPerformance: {
            content: "Performance",
            isEnabled: true,
          },
          monthlyStandardDeviation: {
            content: "Std dev.",
            isEnabled: true,
          },
          strategy: {
            content: "Strategy",
            isEnabled: true,
          },
        },
        isEdited: false,
        presentation: {
          table: "ROWS",
        },
        sections: null,
      },
    },
    // #endregion ------------------------------------------------------
  };

  /**
   * Get a section as field suitable for trendrating-widgets/form/select/Select
   *
   * @param {string} sectionId - the ID of the section: one of keys in _sections
   *
   * @returns {object} - the section as field
   */
  getAsField = (sectionId) => {
    var _section = this._sections[sectionId];
    var field = {
      label: _section["label"],
      preview: _section["fieldPreview"],
      type: _section["fieldType"],
      value: sectionId,
    };

    return field;
  };

  /**
   * Get a section as widget info suitable for using as clean input/output (server format)
   *
   * @param {string} sectionId - the ID of the section: one of keys in _sections
   *
   * @returns {object} - the section as widget info
   */
  getAsSection = (sectionId) => {
    var _section = this._sections[sectionId];
    var section = {
      content: null,
      isEdited: null,
      presentation: null,
      sections: null,
      type: _section["widgetType"],
    };
    section["content"] = _section["widgetValue"]["content"];
    section["isEdited"] = _section["widgetValue"]["isEdited"];
    section["presentation"] = _section["widgetValue"]["presentation"];
    section["sections"] = _section["widgetValue"]["sections"];

    return deepClone(section);
  };

  /**
   * Get a section as widget info suitable for instantiation
   *
   * @param {string} sectionId - the ID of the section: one of keys in _sections
   *
   * @returns {object} - the section as widget info
   */
  getAsWidget = (sectionId) => {
    var _section = this._sections[sectionId];
    var section = {
      fieldPreview: _section["fieldPreview"],
      widgetClass: _section["widgetClass"],
      widgetClassName: _section["widgetClassName"],
      widgetLabel: _section["label"],
      widgetType: _section["widgetType"],
      widgetValue: _section["widgetValue"],
    };

    return section;
  };

  /**
   * Returns the default fields for the specified page report
   *
   * @param {string} page - the page for whom get the fields
   */
  getDefaultFields = (page) => {
    var _fields: any[] = [];

    switch (page) {
      case "alerts": {
        _fields = ["REPORT_LIST_ALERTS_TABLE"];

        break;
      }
      case "dispersionBasketTab": {
        _fields = [
          "REPORT_COMMON_COVER",
          "REPORT_COMMON_TITLE",
          "REPORT_COMMON_HEADER_1",
          "REPORT_COMMON_PARAGRAPH",
          "REPORT_COMMON_PAGE_BREAK",
          "REPORT_COMMON_SPACING",
          "REPORT_DISPERSION_OVERVIEW",
          "REPORT_BASKET_DISPERSION_BY_SECTORS",
          "REPORT_BASKET_DISPERSION_BY_CHART",
          "REPORT_PORTFOLIO_TCR",
          "REPORT_COMMON_ALLOCATION",
          "REPORT_PORTFOLIO_RATING_CHANGE",
          "REPORT_PORTFOLIO_NEW_HIGH_NEW_LOW",
          "REPORT_PORTFOLIO_PERFORMER",
          "REPORT_PORTFOLIO_MOMENTUM_BREAKDOWN",
          "REPORT_COMMON_SECURITY_TABLE",
          "REPORT_COMMON_SECURITY_CHART",
          "REPORT_COMMON_DISCLAIMER",
        ];

        break;
      }
      case "analysisList": {
        _fields = [
          "REPORT_DISPERSION_OVERVIEW",
          "REPORT_BASKET_DISPERSION_BY_SECTORS",
          "REPORT_BASKET_DISPERSION_BY_CHART",
          "REPORT_COMMON_COVER",
          "REPORT_PORTFOLIO_TCR",
          "REPORT_COMMON_ALLOCATION",
          "REPORT_PORTFOLIO_RATING_CHANGE",
          "REPORT_PORTFOLIO_NEW_HIGH_NEW_LOW",
          "REPORT_PORTFOLIO_PERFORMER",
          "REPORT_PORTFOLIO_MOMENTUM_BREAKDOWN",
          "REPORT_COMMON_SECURITY_TABLE",
          "REPORT_COMMON_SECURITY_CHART",
          "REPORT_COMMON_DISCLAIMER",
          "REPORT_COMMON_TITLE",
          "REPORT_COMMON_HEADER_1",
          "REPORT_COMMON_PARAGRAPH",
          "REPORT_COMMON_PAGE_BREAK",
          "REPORT_COMMON_SPACING",
        ];

        break;
      }
      case "analysisMarkets":
      case "analysisMarketsDetail": {
        _fields = [
          "REPORT_COMMON_COVER",
          "REPORT_CUSTOMIZABLE_PEER_TABLE",
          "REPORT_PEER_TRENDS_TRACKER",

          "REPORT_PEER_TCR",
          "REPORT_PEER_TCR_FOCUS",
          "REPORT_PEER_FOCUS_UPGRADES_DOWNGRADES",
          "REPORT_PEER_DISPERSION",
          "REPORT_PEER_DISPERSION_CHILDREN",
          "REPORT_DISPERSION_BY_SECTORS",
          "REPORT_DISPERSION_BY_CHART",
          "REPORT_DISPERSION_RATIO_TABLE",
          "REPORT_DISPERSION_TCR_TABLE",

          "REPORT_PEER_WHAT_OVERVIEW",
          "REPORT_PEER_OVERVIEW",
          "REPORT_PEER_WHAT_TCR_CHANGES",
          "REPORT_PEER_WHAT_AB_CHANGES",
          "REPORT_PEER_WHAT_UPGRADES_DOWNGRADES",

          "REPORT_PEER_WHERE_OVERVIEW",
          "REPORT_PEER_WHERE_TCR_CHANGES",
          "REPORT_PEER_WHERE_AB_CHANGES",
          "REPORT_PEER_WHERE_UPGRADES_DOWNGRADES",

          "REPORT_COMMON_TITLE",
          "REPORT_COMMON_HEADER_1",
          "REPORT_COMMON_PARAGRAPH",
          "REPORT_COMMON_PAGE_BREAK",
          "REPORT_COMMON_SPACING",
          "REPORT_COMMON_DISCLAIMER",
        ];

        break;
      }
      case "ranking": {
        _fields = [
          "REPORT_COMMON_COVER",
          "REPORT_COMMON_SECURITY_TABLE",
          "REPORT_COMMON_SECURITY_CHART",
          "REPORT_COMMON_TITLE",
          "REPORT_COMMON_HEADER_1",
          "REPORT_COMMON_PARAGRAPH",
          "REPORT_COMMON_PAGE_BREAK",
          "REPORT_COMMON_SPACING",
          "REPORT_COMMON_DISCLAIMER",
        ];

        break;
      }
      case "screening": {
        _fields = [
          "REPORT_COMMON_COVER",
          "REPORT_COMMON_SECURITY_TABLE",
          "REPORT_COMMON_SECURITY_CHART",
          "REPORT_COMMON_TITLE",
          "REPORT_COMMON_HEADER_1",
          "REPORT_COMMON_PARAGRAPH",
          "REPORT_COMMON_PAGE_BREAK",
          "REPORT_COMMON_SPACING",
          "REPORT_COMMON_DISCLAIMER",
        ];

        break;
      }
      case "strategyBuilder": {
        _fields = [
          "REPORT_COMMON_COVER",
          "REPORT_COMMON_TITLE",
          "REPORT_STRATEGY_FACTS",
          "REPORT_STRATEGY_CHART",
          "REPORT_STRATEGY_AS_OF_TODAY_PERFORMANCE",
          "REPORT_STRATEGY_KEY_FACTS",
          "REPORT_COMMON_ALLOCATION",
          "REPORT_STRATEGY_HOLDINGS",
          "REPORT_STRATEGY_YEAR_TO_DATE_ANALYTICS",
          "REPORT_STRATEGY_YEARLY_ANALYTICS",
          "REPORT_STRATEGY_MONTHLY_ANALYTICS",
          "REPORT_STRATEGY_QUARTERLY_ANALYTICS",
          "REPORT_STRATEGY_BREAKDOWN", // not in systematicPortfolios
          "REPORT_STRATEGY_SUMMARY",
          "REPORT_COMMON_DISCLAIMER",
          "REPORT_COMMON_PARAGRAPH",
          "REPORT_COMMON_HEADER_1",
          "REPORT_COMMON_PAGE_BREAK",
          "REPORT_COMMON_SPACING",
        ];

        break;
      }
      case "strategyLongShort": {
        _fields = [
          "REPORT_COMMON_COVER",
          "REPORT_STRATEGY_AS_OF_TODAY_PERFORMANCE",
          "REPORT_STRATEGY_YEAR_TO_DATE_ANALYTICS",
          "REPORT_STRATEGY_FACTS",
          "REPORT_STRATEGY_CHART",
          "REPORT_STRATEGY_KEY_FACTS",
          "REPORT_STRATEGY_YEARLY_ANALYTICS",
          "REPORT_STRATEGY_MONTHLY_ANALYTICS",
          "REPORT_STRATEGY_QUARTERLY_ANALYTICS",
          "REPORT_COMMON_TITLE",
          "REPORT_COMMON_HEADER_1",
          "REPORT_COMMON_PARAGRAPH",
          "REPORT_COMMON_PAGE_BREAK",
          "REPORT_COMMON_SPACING",
          "REPORT_COMMON_DISCLAIMER",
          "REPORT_COMMON_ALLOCATION",
          "REPORT_COMMON_SECURITY_TABLE",
          "REPORT_COMMON_SECURITY_CHART",
        ];

        break;
      }
      case "systematicPortfolios": {
        _fields = [
          "REPORT_COMMON_COVER",
          "REPORT_COMMON_TITLE",
          "REPORT_STRATEGY_FACTS",
          "REPORT_STRATEGY_CHART",
          "REPORT_STRATEGY_AS_OF_TODAY_PERFORMANCE",
          "REPORT_STRATEGY_KEY_FACTS",
          "REPORT_COMMON_ALLOCATION",
          "REPORT_COMMON_SECURITY_TABLE",
          "REPORT_COMMON_SECURITY_CHART",
          "REPORT_STRATEGY_YEAR_TO_DATE_ANALYTICS",
          "REPORT_STRATEGY_YEARLY_ANALYTICS",
          "REPORT_STRATEGY_MONTHLY_ANALYTICS",
          "REPORT_STRATEGY_QUARTERLY_ANALYTICS",
          "REPORT_STRATEGY_SUMMARY",
          "REPORT_COMMON_DISCLAIMER",
          "REPORT_COMMON_PARAGRAPH",
          "REPORT_COMMON_HEADER_1",
          "REPORT_COMMON_PAGE_BREAK",
          "REPORT_COMMON_SPACING",
        ];

        break;
      }
      case "test": {
        _fields = [
          "REPORT_COMMON_COVER",
          "REPORT_COMMON_ALLOCATION",

          "REPORT_STRATEGY_AS_OF_TODAY_PERFORMANCE",
          "REPORT_STRATEGY_CHART",
          "REPORT_STRATEGY_FACTS",
          "REPORT_STRATEGY_SUMMARY",
          "REPORT_STRATEGY_HOLDINGS",
          "REPORT_STRATEGY_KEY_FACTS",
          "REPORT_STRATEGY_BREAKDOWN",
          "REPORT_STRATEGY_MONTHLY_ANALYTICS",
          "REPORT_STRATEGY_YEARLY_ANALYTICS",
          "REPORT_STRATEGY_YEAR_TO_DATE_ANALYTICS",

          "REPORT_PEER_DISPERSION",
          "REPORT_PEER_DISPERSION_CHILDREN",
          "REPORT_PEER_TCR",
          "REPORT_PEER_WHAT_AB_CHANGES",
          "REPORT_PEER_WHAT_OVERVIEW",
          "REPORT_PEER_OVERVIEW",

          "REPORT_PEER_WHAT_TCR_CHANGES",
          "REPORT_PEER_WHAT_UPGRADES_DOWNGRADES",
          "REPORT_PEER_WHERE_AB_CHANGES",
          "REPORT_PEER_WHERE_OVERVIEW",
          "REPORT_PEER_WHERE_TCR_CHANGES",
          "REPORT_PEER_WHERE_UPGRADES_DOWNGRADES",

          "REPORT_PORTFOLIO_TCR",
          "REPORT_COMMON_ALLOCATION",
          "REPORT_PORTFOLIO_RATING_CHANGE",
          "REPORT_PORTFOLIO_NEW_HIGH_NEW_LOW",
          "REPORT_PORTFOLIO_PERFORMER",
          "REPORT_PORTFOLIO_MOMENTUM_BREAKDOWN",
          "REPORT_COMMON_SECURITY_TABLE",
          "REPORT_COMMON_SECURITY_CHART",
          "REPORT_COMMON_TITLE",
          "REPORT_COMMON_HEADER_1",
          "REPORT_COMMON_PARAGRAPH",
          "REPORT_COMMON_PAGE_BREAK",
          "REPORT_COMMON_SPACING",
          "REPORT_COMMON_DISCLAIMER",
        ];

        break;
      }
      default: {
        throw new Error("No valid page id used in getDefaultFields");
      }
    }

    var field: any = null;
    var fields: any[] = [];
    for (let i = 0, length = _fields.length; i < length; i++) {
      // Filter out unsupported widgets
      if (
        this.productFlavourCode !== "SYSTEMATIC_ENGINE" &&
        _fields[i] === "REPORT_STRATEGY_SUMMARY"
      ) {
        continue;
      }

      field = this.getAsField(_fields[i]);
      fields.push(field);
    }

    return deepClone(fields);
  };

  /**
   * Returns the default template for the specified page report
   *
   * @param {string} page - the page for whom get the template
   * @returns {Promise}
   */
  getDefaultTemplate = (page) => {
    var _template: any[] = [];

    var peerTemplates = defaultTemplateReportPeer;
    // defaultTemplateReportPeerWizard is an object, must be converted
    // in an array of objects

    const peerDetailTemplates = Object.values(defaultTemplateReportPeerDetail);
    var portfolioTemplates = defaultTemplateReportPortfolio;
    var basketDispersionTemplates = defaultTemplateReportListDispersionTab;
    var rankingTemplates = defaultTemplateReportRanking;
    var screeningTemplates = defaultTemplateReportScreening;
    var strategyTemplates = defaultTemplateReportStrategy;
    var strategyAlphaFinder = defaultTemplateReportStrategiesAlphaFinder;
    var strategyLongShortTemplates = defaultTemplateReportStrategyLongShort;
    var systematicPortfoliosTemplates =
      defaultTemplateReportSystematicPortfolios;
    var portfolioRiskControlTemplates =
      defaultTemplateReportPortfolioRiskControl;
    var strategyTrackerTemplates = defaultTemplateReportStrategyTracker;
    var alertsReportTemplates = defaultTemplateAlertsReport;

    var requests = [];

    if (page !== "test") {
      try {
        const PAGE_NAMES_DICT = {
          alerts: { pageName: "alerts", key: "primary" },
          analysisList: { pageName: "analysis_list", key: "primary" },
          performanceRise: { pageName: "performanceRise", key: "primary" },
          dispersionBasketTab: {
            pageName: "analysis_list",
            key: "dispersion",
          },
          analysisMarkets: { pageName: "analysis_market", key: "primary" },
          analysisMarketsDetail: {
            pageName: "analysis_peer",
            key: "primary",
          },
          ranking: { pageName: "ranking", key: "primary" },
          screening: { pageName: "screening", key: "primary" },
          strategyBuilder: { pageName: "strategy_builder", key: "primary" },
          strategyLongShort: {
            pageName: "strategy_builder",
            key: "combined",
          },
          systematicPortfolios: {
            pageName: "systematic_portfolios",
            key: "primary",
          },
        };

        const appPage = PAGE_NAMES_DICT[page];

        const templatesByConfigTag =
          this.environment?.account.product.configuration?.[appPage.pageName]?.[
            "report_default_templates"
          ]?.[appPage.key];

        const TEMPLATE_DICT = {
          alertsTemplates: alertsReportTemplates,
          marketTemplates: peerTemplates,
          peerTemplates: peerDetailTemplates,
          portfolioTemplates: portfolioTemplates,
          portfolioDispersionTemplates: basketDispersionTemplates,
          rankTemplates: rankingTemplates,
          strategyTemplates: strategyTemplates,
          screeningTemplates: screeningTemplates,
          strategyTemplates_AlphaFinder: strategyAlphaFinder,
          strategyConbinedTemplates: strategyLongShortTemplates,
          smsTemplates: systematicPortfoliosTemplates,
          portfolioRiskControl: portfolioRiskControlTemplates,
          strategyTrackerTemplates: strategyTrackerTemplates,
        };

        _template = TEMPLATE_DICT[templatesByConfigTag];
      } catch (error) {
        throw new Error("No valid page id used in getDefaultTemplate");
      }
    } else {
      _template = [];
      for (let i = 0; i < portfolioTemplates.length; i++) {
        _template.push(portfolioTemplates[i]);
      }
      for (let i = 0; i < rankingTemplates.length; i++) {
        _template.push(rankingTemplates[i]);
      }
      for (let i = 0; i < screeningTemplates.length; i++) {
        _template.push(screeningTemplates[i]);
      }
      for (let i = 0; i < strategyTemplates.length; i++) {
        _template.push(strategyTemplates[i]);
      }
    }

    return httpAll(requests).then((response) => {
      for (var i = 0, length = response.length; i < length; i++) {
        var templateSet = response[i];
        for (var j = 0, lengthJ = templateSet.length; j < lengthJ; j++) {
          _template.push(templateSet[j]);
        }
      }

      // Fix all data from default templates
      for (let i = 0, length = _template.length; i < length; i++) {
        var template = _template[i];
        template["id"] = null;
        template["ownerId"] = null;
        template["isEditable"] = false;

        if (
          template["configuration"] != null &&
          template["configuration"]["sections"] != null
        ) {
          var sections: any[] = [];
          for (
            let j = 0;
            j < template["configuration"]["sections"].length;
            j++
          ) {
            var section = template["configuration"]["sections"][j];
            // Filter out unsupported widgets
            if (
              this.productFlavourCode !== "SYSTEMATIC_ENGINE" &&
              section["type"] === "REPORT_STRATEGY_SUMMARY"
            ) {
              continue;
            }

            sections.push(section);
          }
          template["configuration"]["sections"] = sections;
        }
      }

      return deepClone(_template);
    });
  };
}
