import { Box } from "@mui/material";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Accordion } from "../../../../../../../components/Accordion/Accordion";
import { ComparisonTableWidgetSelector } from "./ComparisonTableWidgetSelector";
import { ComparisonTableWidgetTable } from "./ComparisonTableWidgetTable";
import { PieChartWidget } from "./PieChartWidget";
import { SummaryTable } from "./SummaryTable";

export type Cluster =
  | "Country"
  | "1 Industry"
  | "3 Sector"
  | "Currency"
  | "etfgeo"
  | "AssetClass"
  | "Specialty"
  | "3 Level"
  | "Area"
  | "Region"
  | "Type";

type ComparisonTableWidgetProps = {
  clusterLevel: Cluster;
  fetchFilteredData: (filters, securities, setData) => void;
  name1: string;
  name2: string;
  setClusterLevel: (segment: Cluster) => void;
  summaryTableData: any;
  symbols: any;
  value: {
    pie: any;
    pie2: any;
    mainTableData: any;
  };
  positionsType:
    | "Sector"
    | "Currency"
    | "Commodity"
    | "Index"
    | "Stock"
    | "ETF"
    | "Instrument";
};

export function ComparisonTableWidget({
  clusterLevel,
  fetchFilteredData,
  name1,
  name2,
  setClusterLevel,
  summaryTableData,
  symbols,
  value,
  positionsType,
}: ComparisonTableWidgetProps) {
  const [isOpen, setIsOpen] = useState(true);
  const [activeDrillDown, setActiveDrillDown] = useState(false);
  const [drillDownData, setDrillDownData] = useState(summaryTableData);

  const pointerRef = useRef<any>(null);

  useEffect(() => {
    setActiveDrillDown(false);
  }, [value]);

  //Reset cluster level on change portfolio 1 or 2
  useEffect(() => {
    setClusterLevel(positionsType === "ETF" ? "etfgeo" : "Country");
  }, [name1, name2, positionsType, setClusterLevel]);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
    setActiveDrillDown(false);
  };

  const decodeTitle = useMemo(() => {
    let title = "";

    switch (clusterLevel) {
      case "Country":
        title = "Markets";
        break;
      case "etfgeo":
        title = "Inv. Region";
        break;
      case "AssetClass":
        title = "Asset class";
        break;
      case "Specialty":
        title = "Specialty";
        break;
      case "1 Industry":
        title = "Sectors";
        break;
      case "3 Sector":
        title = "Industries";
        break;
      case "3 Level":
        title = "Size";
        break;
      case "Currency":
        title = "Currency";
        break;
      case "Region":
        title = "Regions";
        break;
      case "Area":
        title = "Areas";
        break;
      case "Type":
        title = "Type";
    }

    return title;
  }, [clusterLevel]);

  const rowClickHandler = useCallback(
    (e, row) => {
      const eventValue = row.getData();
      const id = eventValue.id;
      const rowElement = row.getElement();
      rowElement.classList.add("selected");
      pointerRef.current = rowElement.getBoundingClientRect();
      row.select();
      setActiveDrillDown(true);

      const filters: any = {
        country: [],
        sector: [],
        industry: [],
        currency: [],
        size: [],
        region: [],
        area: [],
        type: [],
        etfclass: [],
        etfgeo: [],
        specialty: [],
      };

      switch (clusterLevel) {
        case "Country":
          filters["country"] = [id];

          break;

        case "Area":
          filters["area"] = [id];

          break;

        case "Region":
          filters["region"] = [id];

          break;

        case "etfgeo":
          filters["etfgeo"] = [id];

          break;

        case "AssetClass":
          filters["etfclass"] = [id];

          break;

        case "Specialty":
          filters["specialty"] = [id];

          break;
        case "1 Industry":
          filters["sector"] = [id];

          break;
        case "3 Sector":
          filters["industry"] = [id];

          break;
        case "Currency":
          filters["currency"] = [id];

          break;
        case "3 Level":
          filters["size"] = [id];

          break;
        case "Type":
          filters["type"] = [id];
      }

      fetchFilteredData(filters, symbols, setDrillDownData);
    },
    [clusterLevel, fetchFilteredData, symbols]
  );

  useEffect(() => {
    setActiveDrillDown(false);
  }, [clusterLevel]);

  const accordionLabel = <h3>{`${decodeTitle}`}</h3>;
  const arrowDirection = isOpen ? "down" : "up";
  const widgetTitle = (
    <div className="accordion-compare-title">
      <div className="compare-portfolio-widget-title">
        {isOpen ? (
          <ComparisonTableWidgetSelector
            positionsType={positionsType}
            clusterLevel={clusterLevel}
            setClusterLevel={setClusterLevel}
          />
        ) : (
          accordionLabel
        )}
      </div>
      <span onClick={toggleAccordion} className={`i-${arrowDirection}`}></span>
    </div>
  );
  return (
    <div className="rating-weights-block">
      <Accordion titleJSX={widgetTitle} isOpen={isOpen}>
        <>
          <div
            style={{
              flex: 1,
              display: "flex",
              maxHeight: 400,
              minHeight: 250,
              gap: "8px",
            }}
          >
            <Box
              flex={activeDrillDown ? 2 : 3}
              display={"flex"}
              minHeight={0}
              minWidth={0}
            >
              <ComparisonTableWidgetTable
                name1={name1}
                name2={name2}
                rowClickHandler={rowClickHandler}
                title={decodeTitle}
                value={value.mainTableData}
              />
            </Box>
            <Box
              display={activeDrillDown ? "flex" : "none"}
              flex={1}
              p={1}
              borderRadius={"8px"}
              border={"1px solid #2a7090"}
              position={"relative"}
              ref={(node: any) => {
                if (node) {
                  const pos = node.getBoundingClientRect();
                  const posRow = pointerRef.current?.top;

                  if (posRow) {
                    const triangle =
                      document.getElementById("pointerCompTable");
                    const triangleBg =
                      document.getElementById("pointerCompTableBg");

                    if (triangle && triangleBg) {
                      triangle.style.visibility = "visible";
                      triangle.style.top = posRow - pos.top + "px";

                      triangleBg.style.visibility = "visible";
                      triangleBg.style.top = posRow - pos.top + "px";
                      triangleBg.style.left = "-14px";
                    }
                  }
                }
              }}
              minHeight={0}
              minWidth={0}
            >
              <Box
                id={"pointerCompTable"}
                position={"absolute"}
                visibility={"hidden"}
                left={"-15px"}
                top={0}
                width={0}
                height={0}
                borderTop={"15px solid transparent"}
                borderBottom={"15px solid transparent"}
                borderRight={"15px solid #2a7090"}
              >
                {" "}
              </Box>
              <Box
                id={"pointerCompTableBg"}
                position={"absolute"}
                visibility={"hidden"}
                left={"-15px"}
                top={0}
                width={0}
                height={0}
                borderTop={"15px solid transparent"}
                borderBottom={"15px solid transparent"}
                borderRight={"15px solid white"}
              >
                {" "}
              </Box>
              <Box
                display={"flex"}
                height={"100%"}
                width={"100%"}
                minHeight={0}
                minWidth={0}
              >
                <SummaryTable
                  data={drillDownData}
                  portfolio1Name={name1}
                  portfolio2Name={name2}
                  hasPrice={false}
                />
              </Box>
            </Box>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
                minHeight: 0,
                minWidth: 0,
              }}
            >
              <PieChartWidget
                dataSerie={value.pie}
                name={name1}
                selector={clusterLevel}
                positionsType={positionsType}
              />

              <PieChartWidget
                dataSerie={value.pie2}
                name={name2}
                selector={clusterLevel}
                positionsType={positionsType}
              />
            </div>
          </div>
        </>
      </Accordion>
    </div>
  );
}
