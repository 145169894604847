import { useState } from "react";
import SecurityChartModal from "../../../../../../../components/SecurityChartModal/SecurityChartModal";
import SecurityTooltip from "../../../../../../../components/SecurityTooltip/SecurityTooltip";
import { useEnvironment } from "../../../../../../../hooks/useEnvironment";
import { Formatter } from "../../../../../../../trendrating/utils/Formatter";
import { SecurityPriceChart } from "../../../../../../../components/SecurityPriceChart/SecurityPriceChart";

type InstrumentProps = {
  data: InstrumentType;
};

type InstrumentType = {
  chartUri: string;
  currency: string;
  date: number;
  id: string;
  name: string;
  price: number;
  rate: number;
  symbol: string;
  ticker: string;
  type: "instrument" | "peer";
};

const prepareUriParams = (object) => {
  let urlParams: any = null;

  switch (object["type"].toLowerCase()) {
    case "commodity":
    case "currency":
    case "etf":
    case "index":
    case "instrument":
    case "sector":
    case "stock": {
      urlParams = { symbol: object["symbol"] };

      return urlParams;
    }
    case "peer": {
      urlParams = {
        size:
          object["size"] === "microLarge" || object["size"] == null
            ? "-"
            : object["size"],
        type: "stock_commons-stocks",
        what:
          object["what"] === "ICB" || object["what"] == null
            ? "-"
            : object["what"],
        where:
          object["where"] === "WWW" || object["where"] == null
            ? "-"
            : object["where"],
      };

      return urlParams;
    }
    default: {
      return urlParams;
    }
  }
};

export function Instrument({ data }: InstrumentProps) {
  const environment = useEnvironment();
  const formatter = new Formatter(environment.get("setup"));
  const [showModal, setShowModal] = useState(false);
  const [securityData, setSecurityData] = useState();
  const price = formatter.custom("price", {
    options: {
      notAvailable: {
        input: null,
        output: "Not available",
      },
    },
    output: "HTML",
    value: data["price"],
    valueHelper: {
      currency: data["currency"],
      date: data["date"],
    },
  });
  const rating = formatter.custom("rating", {
    options: {
      notAvailable: { input: 0, output: "-" },
    },
    output: "HTML",
    value: data["rate"],
    valueHelper: {
      rateChange: null,
      rateDate: null,
      ratePrev: null,
    },
  });

  const chartTitle = data?.name ?? "Name of instrument";
  const securityLink =
    environment.get("configuration")["configuration"]["pages"]["alerts"][
      "securityLink"
    ];

  const handleInstrumentClick = () => {
    const urlParams = prepareUriParams(data);
    const { symbol } = urlParams;
    const url = `/analysis/instrument/${symbol}/`;
    window.App.client.referer = window.location.href;
    (window as any).__page_navigate(url);
  };

  const onClickChartButton = () => {
    setShowModal(true);
  };

  return securityLink === "popup_instrument" ? (
    <>
      <SecurityChartModal
        environment={environment}
        showModal={showModal}
        security={securityData}
        onClose={() => setShowModal(false)}
      />
      <SecurityTooltip
        setSecurity={setSecurityData}
        symbol={data?.symbol}
        chartButtonHandler={onClickChartButton}
      >
        <li className="tPinnedObject">
          <div className="tPinnedObject-spacer"></div>
          <div className="tPinnedObject-title tLayout">
            <div className="tPinnedObject-title-name" title={data?.name ?? ""}>
              {data?.ticker ?? ""}
            </div>
            <div
              className="tPinnedObject-title-price"
              dangerouslySetInnerHTML={{ __html: price }}
            ></div>
            <div
              className="tPinnedObject-title-rate"
              dangerouslySetInnerHTML={{ __html: rating }}
            ></div>
          </div>
          <div className="tPinnedObject-chart" title={chartTitle}>
            <SecurityPriceChart symbol={data["symbol"]} title={chartTitle} />
          </div>
        </li>
      </SecurityTooltip>
    </>
  ) : (
    <li className="tPinnedObject" onClick={handleInstrumentClick}>
      <div className="tPinnedObject-spacer"></div>
      <div className="tPinnedObject-title tLayout">
        <div className="tPinnedObject-title-name" title={data?.name ?? ""}>
          {data?.ticker ?? ""}
        </div>
        <div
          className="tPinnedObject-title-price"
          dangerouslySetInnerHTML={{ __html: price }}
        ></div>
        <div
          className="tPinnedObject-title-rate"
          dangerouslySetInnerHTML={{ __html: rating }}
        ></div>
      </div>
      <div className="tPinnedObject-chart" title={chartTitle}>
        <SecurityPriceChart symbol={data["symbol"]} title={chartTitle} />
      </div>
    </li>
  );
}
