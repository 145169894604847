/**
 * @author Trendrating <info@trendrating.net>
 *
 * @module api/Configuration
 * @summary Parse configuration and return objects suitable for UI
 *
 */

import { deepClone } from "../deepClone";
import { Properties } from "./Properties";

export class Configuration {
  // parsed and ready to use configuration
  configuration: any;

  product: any;
  properties: any;

  /**
   * @constructor
   *
   * @param {object} params
   * @param {object} params.product - raw product configuration
   * @param {object} params.properties - instrument's properties
   *
   * @returns {undefined}
   */
  constructor({ product, properties }: any) {
    this.product = {
      flavourCode: product.productCode,
      flavourName: product.codeName,
      id: product.id,
      name: "Trendrating Web App",
    };
    this.properties = properties;

    this.generate(deepClone(product.configuration));
  }

  checkColumns(item: any) {
    //
    // columns available
    //
    // if the field is IGNORE, the current product does not have
    // to use the field
    //
    item.columns_available = item.columns_available.filter(
      (column) => column.field !== "IGNORE"
    );
    return item;
  }

  generate(items: any) {
    // partial: only in some pages
    const configuration: any = {
      landing: {
        options: this._snakeCase2CamelCase(items.landing_page_options),
        page: this._snakeCase2CamelCase([items.landing_page])[0],
      },
      pages: {
        alerts: {
          enabled: false,
          widgets: null,
          workflow: null,
        },
        analysisInstrument: {
          enabled: false,
          widgets: null,
          workflow: null,
        },
        analysisList: {
          enabled: false,
          widgets: null,
          workflow: null,
        },
        analysisPeer: {
          enabled: false,
          widgets: null,
          workflow: null,
        },
        ranking: {
          enabled: false,
          widgets: null,
          workflow: null,
        },
        screening: {
          enabled: false,
          widgets: null,
          workflow: null,
        },
        strategyBuilder: {
          enabled: false,
          widgets: null,
          workflow: null,
        },
        strategyTracker: {
          enabled: false,
          widgets: null,
          workflow: null,
        },
        systematicPortfolios: {
          enabled: false,
          widgets: null,
          workflow: null,
        },
        performanceRise: {
          enabled: false,
          widgets: null,
          workflow: null,
        },
        reportsHub: {
          enabled: false,
          widgets: null,
          workflow: null,
        },
      },
    };

    if ("alerts" in items) {
      configuration.pages.alerts.enabled = items.alerts.enabled;
      configuration.pages.alerts.securityLink =
        items.alerts.securityLink ?? "analysis_instrument";
    }

    if ("analysis_instrument" in items) {
      configuration.pages.analysisInstrument.enabled =
        items.analysis_instrument.enabled;
    }

    if ("analysis_list" in items) {
      configuration.pages.analysisList.enabled = items.analysis_list.enabled;
      configuration.pages.analysisList.tabs = [];
      configuration.pages.analysisList.tabsIndex = {};

      for (let i = 0; i < items.analysis_list.overview_tabs.length; i++) {
        const item = items.analysis_list.overview_tabs[i];
        if ("widgets" in item) {
          if ("ranking" in item.widgets) {
            item.widgets.ranking = this._ranking(item.widgets.ranking);
          }

          if ("viewer" in item.widgets) {
            item.widgets.viewer = this.checkColumns(item.widgets.viewer.table);
          }

          if ("viewerFilter" in item.widgets) {
            item.widgets.viewerFilter = item.widgets.viewerFilter.table;
          }
        }

        configuration.pages.analysisList.tabs.push(item);
        configuration.pages.analysisList.tabsIndex[item["id"]] = i;
      }

      configuration.pages.analysisList.workflow = items.analysis_list.workflow;
    }

    if ("performanceRise" in items) {
      configuration.pages.performanceRise.enabled =
        items.performanceRise.enabled;
      configuration.pages.performanceRise.tabs = [];

      for (let i = 0; i < items.performanceRise.overview_tabs.length; i++) {
        const item = items.performanceRise.overview_tabs[i];
        if ("widgets" in item) {
          if ("ranking" in item.widgets) {
            item.widgets.ranking = this._ranking(item.widgets.ranking);
          }

          if ("viewer" in item.widgets) {
            item.widgets.viewer = this.checkColumns(item.widgets.viewer.table);
          }

          if ("viewerFilter" in item.widgets) {
            item.widgets.viewerFilter = item.widgets.viewerFilter.table;
          }
        }

        configuration.pages.performanceRise.tabs.push(item);
      }

      configuration.pages.performanceRise.workflow =
        items.performanceRise.workflow;
    }

    if ("analysis_peer" in items) {
      configuration.pages.analysisPeer.enabled = items.analysis_peer.enabled;

      configuration.pages.analysisPeer.widgets = {
        viewer: {
          what: null,
          where: null,
          whereWhat: null,
        },
        viewerFilter: {
          what: null,
          where: null,
          whereWhat: null,
        },
      };

      for (const viewer in items.analysis_peer.widgets.viewer) {
        switch (viewer) {
          case "market": {
            configuration.pages.analysisPeer.widgets.viewer.where =
              items.analysis_peer.widgets.viewer[viewer];

            configuration.pages.analysisPeer.widgets.viewerFilter.where =
              items.analysis_peer.widgets.viewerFilter[viewer];

            break;
          }
          case "market_sector": {
            configuration.pages.analysisPeer.widgets.viewer.whereWhat =
              items.analysis_peer.widgets.viewer[viewer];

            configuration.pages.analysisPeer.widgets.viewerFilter.whereWhat =
              items.analysis_peer.widgets.viewerFilter[viewer];

            break;
          }
          case "sector": {
            configuration.pages.analysisPeer.widgets.viewer.what =
              items.analysis_peer.widgets.viewer[viewer];

            configuration.pages.analysisPeer.widgets.viewerFilter.what =
              items.analysis_peer.widgets.viewerFilter[viewer];

            break;
          }
        }
      }
    }

    if ("ranking" in items) {
      configuration.pages.ranking["enabled"] = items.ranking.enabled;

      if (items.ranking.widgets != null) {
        configuration.pages.ranking.widgets = {};

        if ("ranking" in items.ranking.widgets) {
          configuration.pages.ranking.widgets.ranking = this._ranking(
            items.ranking.widgets.ranking
          );
        }

        if ("viewer" in items.ranking.widgets) {
          configuration.pages.ranking.widgets["viewer"] =
            items.ranking.widgets.viewer.table;
        }

        if ("viewerFilter" in items.ranking.widgets) {
          configuration.pages.ranking.widgets.viewerFilter =
            items.ranking.widgets.viewerFilter;
        }
      }

      if (items.ranking.workflow != null) {
        configuration.pages.ranking.workflow = items.ranking.workflow;
      }
    }

    if ("screening" in items) {
      configuration.pages.screening.enabled = items.screening.enabled;

      configuration.pages.screening.widgets = {
        ranking: null,
        viewer: null,
        viewerFilter: null,
      };

      if ("ranking" in items.screening.widgets) {
        configuration.pages.screening.widgets.ranking = this._ranking(
          items.screening.widgets.ranking
        );
      }

      if ("viewer" in items.screening.widgets) {
        configuration.pages.screening.widgets.viewer = this.checkColumns(
          items.screening.widgets.viewer.table
        );
      }

      if ("viewerFilter" in items.screening.widgets) {
        configuration.pages.screening.widgets.viewerFilter =
          items.screening.widgets.viewerFilter.table;
      }
    }

    if ("strategy_tracker" in items) {
      configuration.pages.strategyTracker.enabled =
        items.strategy_tracker.enabled;

      if (items.strategy_tracker.enabled === true) {
        configuration.pages.strategyTracker.maxCardinality =
          items.strategy_tracker.maxCardinality;
      }
    }

    if ("strategy_builder" in items) {
      configuration.pages.strategyBuilder.enabled =
        items.strategy_builder.enabled;

      // 2022-01-10 Always add configuration even if it is disabled
      // if (items.strategy_builder.enabled === true) {
      // shared
      configuration.pages.strategyBuilder.hedging = this._selection(
        items.strategy_builder.hedging
      );
      configuration.pages.strategyBuilder.ranking = this._ranking(
        items.strategy_builder.ranking
      );

      configuration.pages.strategyBuilder.selection = this._selection(
        items.strategy_builder.selection
      );

      // form advanced
      configuration.pages.strategyBuilder.formAdvanced = {
        enabled: items.strategy_builder.form_advanced.enabled,
      };
      // 2021-12-16 Always insert data even if form_advanced is disabled
      configuration.pages.strategyBuilder.formAdvanced.widgets = {
        // hedging:
        //     "hedging" in items.strategy_builder["form_advanced"]
        //         ? this._selection(
        //               items.strategy_builder["form_advanced"]["hedging"]
        //           )
        //         : null,
        selectionUniverse:
          "selection_universe" in items.strategy_builder.form_advanced
            ? this._selection(
                items.strategy_builder.form_advanced.selection_universe
              )
            : null,
        smartBeta:
          "smartBeta" in items.strategy_builder.form_advanced
            ? this._smartBeta(items.strategy_builder.form_advanced.smartBeta)
            : null,
      };

      // form alpha
      configuration.pages.strategyBuilder.formAlpha = {
        enabled: items.strategy_builder.form_alpha.enabled,
      };
      // form macro rotation
      configuration.pages.strategyBuilder.formMacroRotation = {
        enabled: items.strategy_builder.form_macro_rotation.enabled,
      };
      // form smart beta
      configuration.pages.strategyBuilder.formSmartBeta = {
        enabled: items.strategy_builder.form_smart_beta.enabled,
      };
      // form compare
      configuration.pages.strategyBuilder.formCompare = {
        enabled: items.strategy_builder.form_compare.enabled,
      };
      // form long short
      configuration.pages.strategyBuilder.formLongShort = {
        enabled: items.strategy_builder.form_long_short.enabled,
      };

      const strategyBuilderConfig = items["strategy_builder"];
      // Is the only tab that contain a tale with customizable columns
      const allocationTab = strategyBuilderConfig.tabs.find(
        (tab) => tab.id === "allocation"
      );

      if (!allocationTab) {
        configuration.pages.strategyBuilder.widgets = {
          viewer: {
            columns: {
              security: [
                "weight",
                "ticker",
                "name",
                "currency",
                "rc",
                "country",
                "icb",
                "performance",
                "contribution",
              ],
            },
            columns_available: [
              { field: "weight" },
              { field: "ticker" },
              { field: "name" },
              { field: "currency" },
              { field: "rc" },
              { field: "country" },
              { field: "icb" },
              { field: "performance" },
              { field: "contribution" },
            ],
          },
          viewerFilter: null,
        };
      } else {
        configuration.pages.strategyBuilder.widgets = {
          viewer: {
            columns: {
              security: allocationTab.widgets.viewer.table.columns.security,
            },
            columns_available:
              allocationTab.widgets.viewer.table.columns_available,
          },
          viewerFilter: null,
        };
      }

      configuration.pages.strategyBuilder.workflow =
        items.strategy_builder.workflow;
      // }
    }

    if ("systematic_portfolios" in items) {
      configuration.pages.systematicPortfolios.enabled =
        items.systematic_portfolios.enabled;

      if (items.systematic_portfolios.enabled === true) {
        configuration.pages.systematicPortfolios.tabs = [];

        for (const item of items.systematic_portfolios.tabs) {
          if ("widgets" in item) {
            if ("viewer" in item.widgets) {
              item.widgets.viewer = this.checkColumns(
                item.widgets.viewer.table
              );
            }

            if ("viewerFilter" in item.widgets) {
              item.widgets.viewerFilter = item.widgets.viewerFilter.table;
            }
          }

          configuration.pages.systematicPortfolios.tabs.push(item);
        }
      }
    }

    if ("systematic_portfolios" in items) {
      configuration.pages.reportsHub.enabled = items.reports_hub.enabled;
    }

    this.configuration = configuration;

    return configuration;
  }

  get(property: any) {
    const configuration: any = this.configuration;
    switch (property) {
      case "landing": {
        return configuration.landing;
      }
      case "product": {
        return this.product;
      }
      default: {
        if (property in configuration.pages) {
          return configuration.pages[property];
        }

        return null;
      }
    }
  }

  /**
   * Convert an old snake case page ID in JS format (camelCase)
   * @param {string} pageId - String in snake_case format
   *
   * @returns {string} String in camelCase format
   */
  legacyPageId2StandardPageId(pageId: any) {
    return this._snakeCase2CamelCase([pageId])[0];
  }

  // ----------------------------------------------------- private methods

  _factorConstraint(actionItems: any) {
    const properties = this.properties;
    const label = new Properties({ properties: properties });

    const options: any = [];
    const _functions = actionItems.functions;
    const _operators = actionItems.operators;
    const _options = actionItems.options;
    const _transformations: any = {};

    for (const _option of _options) {
      const option: any = {
        functions: [],
        operators: [],
        property: null,
      };

      // functions
      for (const _functionName of _option.functions) {
        const _function = _functions[_functionName];
        _function.value = _functionName;
        option.functions.push(_function);
      }
      // property
      option.property = {
        isRemovable: _option.isRemovable == null ? true : _option.isRemovable,
        label: label.get(
          _option.property,
          _option.propertyLabelIndex,
          "auto" // 2021-01-04 used for fundamental properties
        ),
        tag: label.getTag(_option.property, 0, "auto"),
        value: _option.property,
        builderGroup: label.getBuilderGroup(_option.property, "auto"),
      };
      // operators
      for (let j = 0; j < _option.operators.length; j++) {
        option.operators[j] = [];
        for (const _operatorName of _option.operators[j]) {
          const _operator = _operators[_operatorName];
          _operator.value = _operatorName;
          option.operators[j].push(_operator);
        }
      }
      // transformation
      _transformations[_option["property"]] = null;
      if ("transformation" in _option && _option.transformation != null) {
        _transformations[_option.property] = _option.transformation;
      }
      // value
      option.value = null;
      if ("value" in _option && _option.value != null) {
        option.value = _option.value;
      }

      options.push(option);
    }

    const item = {
      columnHeaders: null,
      defaultTemplate: actionItems.default_template,
      functions: _functions,
      presetTemplates: actionItems.preset_templates,
      operators: _operators,
      options: options,
      transformations: _transformations,
    };

    return item;
  }

  _ranking(items: any) {
    const item: any = {
      create: this._factorConstraint(items.create),
      edit: this._factorConstraint(items.edit),
    };

    const columnHeaders: any = {};
    for (let i = 0; i < item.edit.options.length; i++) {
      const _item = item.edit.options[i];

      const _functions = _item.functions;
      const _operators = _item.operators;
      const _property = _item.property.value;

      columnHeaders[_property] = {};

      // for each function there 1 operator
      for (let j = 0; j < _functions.length; j++) {
        const _function = _functions[j];

        // 0 because ranking operators.length is always equal to 1
        const _operator = _operators[j][0].widget.options;

        columnHeaders[_property][_function.value] = {
          label: _function.label,
        };

        for (let k = 0; k < _operator.length; k++) {
          columnHeaders[_property][_function.value][_operator[k].value] =
            _operator[k].label;
        }
      }
    }

    item.edit.columnHeaders = columnHeaders;

    return item;
  }

  _selection(items: any) {
    const item = {
      create: this._factorConstraint(items.create),
      edit: this._factorConstraint(items.edit),
    };

    return item;
  }

  _smartBeta(items: any) {
    return this._factorConstraint(items);
  }

  /**
   *
   * @param {array<string>} snakeCaseStrings
   *
   * @ignore
   */
  _snakeCase2CamelCase(snakeCaseStrings: any) {
    const converted: string[] = [];

    for (let i = 0; i < snakeCaseStrings.length; i++) {
      const tokens: string[] = snakeCaseStrings[i].split("_");

      if (tokens.length > 1) {
        for (let j = 1; j < tokens.length; j++) {
          tokens[j] = tokens[j][0].toUpperCase() + tokens[j].substring(1);
        }
      }

      converted.push(tokens.join(""));
    }

    return converted;
  }
}
