import { Properties } from "../../../../api/Properties";
import { Color } from "../../../../trendrating/formatter/Color";
import { Formatter } from "../../../../trendrating/utils/Formatter";
import { AppEnvironment } from "../../../../types/Defaults";
import { TableHelperCommon } from "./TableHelperCommon";

export class RankingHelper extends TableHelperCommon {
  rankingConfiguration: any;
  formatter: Formatter;
  propertiesAPI: Properties;
  colorAPI: Color;

  constructor(environment: AppEnvironment) {
    super(environment);
    const configuration = environment["configuration"];

    const pageConfiguration = configuration.get("screening");

    const ranking = pageConfiguration["widgets"]["ranking"];

    this.rankingConfiguration = ranking;

    this.formatter = environment["formatter"];

    this.propertiesAPI = new Properties({
      properties: environment["properties"],
    });
    this.colorAPI = new Color({
      formatter: this.formatter,
    });
  }

  rankingColumnHeader(col) {
    const columnHeaders = this.rankingConfiguration["edit"]["columnHeaders"];
    const rankingFunction = col["function"];
    const rankingProperty = col.property;
    const rankingSort = col["operatorParams"]["value"];

    const properties = this.propertiesAPI;

    var _label = [properties.get(rankingProperty, 0, "auto")];

    if (rankingFunction === "outlier") {
      var rankingFunctionValue =
        col["functionParams"] != null ? col["functionParams"]["value"] : null;
      if (rankingFunctionValue != null) {
        _label.push(
          " " +
            this.formatter.custom("number", {
              options: {
                decimals: 0,
                isPercentage: true,
                notAvailable: {
                  input: null,
                  output: "",
                },
              },
              output: "HTML",
              value: rankingFunctionValue,
              valueHelper: null,
            })
        );
      }
    }

    _label.push("<br/>");
    _label.push(columnHeaders[rankingProperty][rankingFunction][rankingSort]);

    return _label.join("");
  }

  configureAsRankColV2(col, target, isHighlighted) {
    const className = "ranking";

    if (col.field === "rank") {
      target.push({
        cssClass: className + " ranking-separator " + className + "--highlight",
        field: "rank",
        title: "Ranking",
        headerSortStartingDir: "desc",
        sorter: () => 0,
        formatter: (cell) => {
          const data = cell.getData();
          const value = data["rank"];
          const node = cell.getElement();
          const dataTotalCount = col.dataTotalCount;
          if (value != null) {
            this.rankingColumnFormatter(
              "rank",
              null, // rank has not any associated rule
              isHighlighted,
              dataTotalCount,
              data,
              value,
              node,
              {}
            );

            return node.textContent;
          }
          return "";
        },
      });
    } else if (col.field === "rankList") {
      target.push({
        cssClass: className,
        field: "rankList",
        title: "In " + (col?.options?.listName ?? ""),
        headerSortStartingDir: "desc",
        sorter: () => 0, // This avoid the sorter to be applied and overwrite the server sort if we set the property header sort to false the sort arrow wouldn't be shown
        formatter: (cell) => {
          const data = cell.getData();
          const value = data["rankList"];
          const node = cell.getElement();

          if (value != null) {
            var rank = data["rank"] + 1;
            var weight = this.formatter.custom("number", {
              options: {
                isPercentage: true,
                notAvailable: {
                  input: null,
                  output: "",
                },
              },
              output: "HTML",
              value: value,
              valueHelper: null,
            });

            if (col?.options?.["listType"] === "PORTFOLIO") {
              node.innerHTML = [rank, " (", weight, ")"].join("");
            } else {
              node.innerHTML = rank;
            }
          } else {
            node.innerHTML = "";
          }

          return node.innerHTML;
        },
      });
    } else if (col.field === "rankFromDate") {
      target.push({
        cssClass: className + "-rankFromDate",
        field: "rankFromDate",
        title:
          "Ranking at<br>" +
          this.formatter.custom("date", {
            options: col.options.formatterDateOptions,
            output: "HTML",
            value: col.fromDate,
            valueHelper: null,
          }),
        sorter: () => 0, // This avoid the sorter to be applied and overwrite the server sort if we set the property header sort to false the sort arrow wouldn't be shown
        formatter: (cell) => {
          const object = cell.getData();
          const value = object["rankFromDate"];
          if (value != null) {
            return this.rankingColumnFormatterFromDate(value);
          }
          return "";
        },
      });

      target.push({
        cssClass: className + "-rankDiff",
        field: "rankDelta",
        title: "Difference",
        formatter: (cell) => {
          const data = cell.getData();
          const value = data["rankDelta"];
          if (value != null) {
            return this.rankingColumnFormatterRankDelta(value);
          }
          return "";
        },
        sorter: () => 0, // This avoid the sorter to be applied and overwrite the server sort if we set the property header sort to false the sort arrow wouldn't be shown
      });
    } else {
      target.push({
        cssClass: className,
        field: col.field,
        title: this.rankingColumnHeader(col),
        sorter: () => 0,
        formatter: (cell) => {
          const data = cell.getData();
          const value = data[col.field];
          const node = cell.getElement();
          const rankRule = {
            function: col.function,
            functionParams: col.functionParams,
            property: col.property,
          };

          const dataTotalCount = col.dataTotalCount;
          if (value != null) {
            this.rankingColumnFormatter(
              col.field,
              rankRule,
              isHighlighted,
              dataTotalCount,
              data,
              value,
              node,
              {}
            );

            return node.innerHTML;
          }

          return "";
        },
      });
    }
  }

  rankingColumnFormatterFromDate(value) {
    return value + 1;
  }

  rankingColumnFormatterRankDelta(value) {
    return this.formatter.custom("number", {
      options: {
        colored: "positive",
        decimals: 0,
        hasPositiveSign: true,
        notAvailable: {
          input: null,
          output: "",
        },
      },
      output: "HTML",
      value: value,
      valueHelper: null,
    });
  }

  rankingColumnFormatter(
    rankProperty,
    rankRule,
    isHighlighted,
    cardinality,
    object,
    value,
    node,
    options
  ) {
    this.colorAPI.highlightRankingCell(
      rankProperty,
      rankRule,
      isHighlighted,
      cardinality,
      object,
      value,
      node
    );
  }
}
