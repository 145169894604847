import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import { Currency } from "../../types/Api";
import styles from "./PortfolioAmountInput.module.scss";

type AmountInputParams = {
  amount: number;
  currency: Currency;
};

type PorfolioAmountInputProps = {
  onSubmit: (input: AmountInputParams) => void;
  show: boolean;
  initAmount?: number;
  initCurrency?: Currency;
  onReset: () => void;
};

const formatWithDots = (rawValue: string) => {
  return rawValue.replace(/\B(?=(\d{3})+(?!\d))/g, "."); // Format with dots
};

export function PorfolioAmountInput({
  onSubmit,
  onReset,
  initAmount,
  initCurrency,
  show,
}: PorfolioAmountInputProps) {
  const [currency, setCurrency] = useState<Currency>(initCurrency ?? "USD");
  const [cash, setCash] = useState(
    initAmount != null ? formatWithDots(`${initAmount}`) : ""
  );
  const [disabled, setDisabled] = useState(true);
  const [errorMsg, setErrorMsg] = useState<string>();
  const [changeToReset, setChangeToReset] = useState(false);

  const handleNumberChange = (e) => {
    setErrorMsg(undefined);
    setDisabled(false);
    setChangeToReset(false);
    const rawValue = e.target.value.replace(/[^0-9]/g, ""); // Make available only numbers charachters
    const formattedValue = formatWithDots(rawValue);
    setCash(formattedValue);
  };

  const handleSubmit = useCallback(() => {
    if (!cash.length || cash === "0") {
      setErrorMsg("Required field");
      return;
    }
    const numericValue = parseInt(cash.replace(/\./g, ""), 10); // Converte il numero a un valore intero

    const input = {
      currency,
      amount: numericValue,
    };

    onSubmit(input);
    setChangeToReset(true);
  }, [cash, currency, onSubmit]);

  const handleCurrencyChange = useCallback((event) => {
    setChangeToReset(false);
    setCurrency(event.target.value);
  }, []);

  const handleReset = useCallback(() => {
    setCash(initAmount != null ? formatWithDots(`${initAmount}`) : "");
    setCurrency(initCurrency ?? "USD");

    setDisabled(true);
    setChangeToReset(false);
    onReset();
  }, [initAmount, initCurrency, onReset]);

  const isInputValid = useMemo(() => {
    return errorMsg == null;
  }, [errorMsg]);

  return show ? (
    <Box className={styles.widgetWrap}>
      <Box className={styles.widgetWrap__inputBox}>
        <FormControl>
          <Select
            size="small"
            value={currency}
            id="currency-select"
            labelId="currency-select-label"
            sx={{ fontSize: "0.7vw" }}
            onChange={handleCurrencyChange}
          >
            <MenuItem value={"local"}>
              <Typography>Local</Typography>
            </MenuItem>
            <MenuItem value={"USD"}>
              <Typography>USD</Typography>
            </MenuItem>
            <MenuItem value={"EUR"}>
              <Typography>EUR</Typography>
            </MenuItem>
            <MenuItem value={"GBP"}>
              <Typography>GBP</Typography>
            </MenuItem>
            <MenuItem value={"CHF"}>
              <Typography>CHF</Typography>
            </MenuItem>
            <MenuItem value={"CAD"}>
              <Typography>CAD</Typography>
            </MenuItem>
            <MenuItem value={"JPY"}>
              <Typography>JPY</Typography>
            </MenuItem>
            <MenuItem value={"AUD"}>
              <Typography>AUD</Typography>
            </MenuItem>
          </Select>
        </FormControl>
        <TextField
          placeholder={formatWithDots("1000000")}
          id={"portfolio-amount-input"}
          label={errorMsg}
          error={!isInputValid}
          value={cash}
          onChange={handleNumberChange}
          InputProps={{ inputMode: "numeric" }}
          InputLabelProps={{ shrink: true, color: "error" }}
          size={"small"}
          variant="outlined"
        />
      </Box>
      {changeToReset ? (
        <Button onClick={handleReset}>Remove</Button>
      ) : (
        <Button disabled={disabled} onClick={handleSubmit}>
          Set
        </Button>
      )}
    </Box>
  ) : (
    <></>
  );
}
