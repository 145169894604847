import { Box, Card, CardContent, Typography } from "@mui/material";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Instruments } from "../../../../../../../../../../api/compute/Instruments";
import {
  extractForDataIngestion,
  extractSymbols,
} from "../../../../../../../../../../api/compute/commons";
import RatingCheckbox from "../../../../../../../../../../components/RatingCheckbox/RatingCheckbox";
import { TableV2 } from "../../../../../../../../../../components/table/v2/TableCoreV2";
import { TrendratingTableV2 } from "../../../../../../../../../../components/table/v2/TableV2";
import { useBroadcast } from "../../../../../../../../../../hooks/useBroadcast";
import { useEnvironment } from "../../../../../../../../../../hooks/useEnvironment";
import { SystematicProduct } from "../../../../../../../../../../types/Api";
import { FormOptions } from "../../../../../../../../../trendrating-widgets/form/FormOptions";
import {
  Export,
  TableWrapper,
} from "../../../../../../../../components/app-infrastructure/workflowBar/actions/export/Export";
import { config } from "../../../../../../../../config-ts";
import ReportButton from "../../../../../../../../widgets/app-infrastructure/workflowBar/actions/report/ReportButton";
import { InputStubExport } from "../../../../../../../analysisLists/widgets/PortfolioHome";
import { FilterBarHelper } from "../../../../../../../screening/FilterBar/FilterBarHelper";
import { FilterCard } from "../../../../../../../screening/FilterBar/FilterRowContraints";
import { OptionsFilter } from "../../../../../../../screening/FilterBar/FilterWidgets/OptionFilter/OptionFilter";

type ProductHoldingsProps = {
  holdings: any[];
  systematicPortfolio: SystematicProduct;
  isCombined?: boolean;
  storage: any;
  instanceType: "combined" | "strategy" | "product";
};

export function ProductHoldings({
  holdings,
  systematicPortfolio,
  instanceType,
  storage,
}: ProductHoldingsProps) {
  const [ratings, setRatings] = useState<any>([]);
  const [dataTotalCount, setDataTottalCount] = useState(holdings?.length ?? 0);
  const [alerts, setAlerts] = useState("None");
  const [foundRows, setFoundRows] = useState<string[]>([]);

  // Used for export
  const [tableData, setTableData] = useState([]);
  const [tableColumns, setTableColumns] = useState([]);
  const [sorter, setSorter] = useState({
    property: "weight",
    descending: false,
  });
  // -----------------------------------------------------------------------------

  const tableRef = useRef<{
    getInstance: () => TableV2;
    refreshSearch: () => void;
  }>();
  const itemsPerPage = useMemo(() => 25, []);
  const { broadcast } = useBroadcast();
  const environment = useEnvironment();
  const configuration = useMemo(
    () =>
      environment.get("account")?.product?.configuration?.[
        "systematic_portfolios"
      ]?.["tabs"],
    [environment]
  );

  const fieldsAPI = useMemo(() => {
    const properties = environment.get("properties");

    const flatMap = {};

    for (const key in properties.properties) {
      for (const leaf in properties.properties[key]) {
        flatMap[leaf] = properties.properties[key][leaf];
      }
    }

    return flatMap;
  }, [environment]);

  const defaultSorter = useMemo(() => {
    return { dimension: "weight", rev: true };
  }, []);

  const holdingsTabConfig = useMemo(() => {
    const holdingsTabSettings = configuration.find(
      (item) => item.id === "holdings"
    );

    if (holdingsTabSettings) {
      const widgetsSettings = holdingsTabSettings?.widgets?.viewer;

      return widgetsSettings;
    }
  }, [configuration]);

  const appSetup = useMemo(() => environment.get("setup"), [environment]);

  const optionsAlerts = useMemo(() => FormOptions.get("ALERT"), []);

  const instrumentsAPI = useMemo(() => new Instruments(appSetup), [appSetup]);

  const filterBarHelper = useMemo(() => new FilterBarHelper(), []);

  const isSearchActive = useMemo(
    () => foundRows.length > 0,
    [foundRows.length]
  );

  const ratingDict = useMemo(
    () => ({
      A: 2,
      B: 1,
      C: -1,
      D: -2,
    }),
    []
  );

  const createHoldingsMap = useCallback(
    () =>
      holdings.reduce((prev, current) => {
        prev[current.symbol] = current;

        return prev;
      }, {}),
    [holdings]
  );

  const getSorter = useCallback(() => {
    const table = tableRef.current?.getInstance();
    const sorterObj: any = { sorter: [{ ...defaultSorter }], injestion: null };
    const currentSort = table?.getSorter();

    let sorter = {
      field: defaultSorter.dimension,
      dir: defaultSorter.rev === false ? "asc" : "desc",
    };

    if (currentSort?.length && currentSort[0] != null) {
      sorter = currentSort[0];
    }

    const { dir, field } = sorter;

    const property = fieldsAPI?.[field];

    let sortProperty = field;

    if (property) {
      sortProperty = property?.backendPropertySort;

      if (property["requireInjection"]) {
        const injectionPayload = extractForDataIngestion(
          holdings,
          "symbol",
          field
        );
        var sortPropertyId = "_:" + field;
        sorterObj["injestion"] = {
          data: injectionPayload,
          field: sortPropertyId,
          type: "number",
        };

        sortProperty = sortPropertyId;
      }
    }

    sorterObj.sorter.unshift({
      dimension: sortProperty,
      rev: dir === "desc",
    });

    return sorterObj;
  }, [defaultSorter, fieldsAPI, holdings]);

  const fetchInstrumentsFields = useCallback(
    async (fields: string[], symbols: string[], page: number) => {
      const sorter = getSorter();

      const payload: any = {
        constraints: [
          [
            {
              dimension: "symbol",
              operator: "equals",
              segments: symbols,
            },
          ],
        ],
        page: { page, rows: itemsPerPage },
        sort: sorter.sorter,
      };

      if (sorter.injestion != null) {
        payload["injestion"] = sorter["injestion"];
      }

      const searchContext: any = {
        sort: sorter.sorter,
        page: { page: 1, rows: 20000 },
        filters: [{ dimension: "symbol", segments: symbols }],
      };

      if (ratings.length) {
        payload.constraints[0].push({
          dimension: "rc",
          operator: "equals",
          segments: ratings,
        });

        searchContext.filters.push({ dimension: "rc", segments: ratings });
      }

      const alertsFilters = filterBarHelper.formatWidgetState("alert")(alerts);

      if (alertsFilters?.filter && alertsFilters?.filter.length) {
        const filtersToAdd = instrumentsAPI.convertToIndexBuilderSyntax({
          ranges: alertsFilters.filter,
        } as any);

        searchContext["ranges"] = alertsFilters.filter;

        payload.constraints = [
          payload.constraints[0].concat(filtersToAdd.constraints[0]),
        ];
      }
      const screeningResponse = await instrumentsAPI.screening(payload, true);
      setDataTottalCount(screeningResponse.dataTotalCount);

      const table = tableRef.current?.getInstance();
      table?.updateSearchContext(searchContext);

      const properties = fields.map((field) => ({
        date: null,
        property: field,
      }));

      const response = await instrumentsAPI.fetch({
        properties,
        symbols: screeningResponse.data,
        type: "security",
      });

      return response?.data ?? undefined;
    },
    [alerts, filterBarHelper, getSorter, instrumentsAPI, itemsPerPage, ratings]
  );

  const getFieldsToFetch = useCallback(
    (columns) => {
      const holdingsMap = createHoldingsMap();

      const firstItem = holdingsMap[holdings[0].symbol];

      // Fields related to the context (contribution, contributiionCurrency, weight ecc.)
      const contextualFieldsMap = Object.keys(firstItem).reduce(
        (prev, current) => {
          prev[current] = true;
          return prev;
        },
        {}
      );

      const fieldsTofetch: any = [];
      let field: any = null;

      columns.forEach((column) => {
        field = column.field;

        if (!(field in contextualFieldsMap)) {
          fieldsTofetch.push(field);
        }
      });

      return fieldsTofetch;
    },
    [createHoldingsMap, holdings]
  );

  const updateData = useCallback(
    async (pageNumber, isSearchActive) => {
      const table = tableRef.current?.getInstance();
      const columns = table?.getColumns().map((col) => col.getDefinition());

      if (!columns || !holdings || holdings.length === 0) {
        return;
      }

      const holdingsMap = createHoldingsMap();

      const fields = getFieldsToFetch(columns);
      let symbols = extractSymbols(holdings);

      if (isSearchActive) {
        symbols = [...foundRows];
      }

      const data = await fetchInstrumentsFields(fields, symbols, pageNumber);

      const mergedData = data.map((row) => ({
        ...row,
        ...holdingsMap[row.symbol],
      }));

      table?.insertData(mergedData ?? []);
      setTableData(mergedData ?? []);
    },
    [
      createHoldingsMap,
      fetchInstrumentsFields,
      foundRows,
      getFieldsToFetch,
      holdings,
    ]
  );

  const updateColumns = useCallback(
    (columns) => {
      // setCurrentCols(columns);
      if (columns.length) {
        updateData(1, isSearchActive);
        setTableColumns(columns.map((col) => col.getDefinition()));
      }
    },
    [updateData, isSearchActive]
  );

  const mergeHoldingsFields = useCallback(
    (data) => {
      if (holdings) {
        const holdingsMap = createHoldingsMap();

        const mergedData = data.map((row) => ({
          ...row,
          ...holdingsMap[row.symbol],
        }));

        return mergedData;
      }

      return [];
    },
    [createHoldingsMap, holdings]
  );

  const onDataSorted = useCallback(
    async ({ field, direction }: any) => {
      setSorter({ property: field, descending: direction === "asc" });
      const table = tableRef.current?.getInstance();
      const columns = table?.getColumns().map((col) => col.getDefinition());

      if (!columns || !holdings || holdings.length === 0) {
        return;
      }

      const holdingsMap = createHoldingsMap();

      const fields = getFieldsToFetch(columns);
      let symbols = extractSymbols(holdings);

      if (isSearchActive) {
        symbols = [...foundRows];
      }

      const data = await fetchInstrumentsFields(fields, symbols, 1);

      const mergedData = data.map((row) => ({
        ...row,
        ...holdingsMap[row.symbol],
      }));

      table?.insertData(mergedData ?? []);
      setTableData(mergedData ?? []);
    },
    [
      createHoldingsMap,
      fetchInstrumentsFields,
      foundRows,
      getFieldsToFetch,
      holdings,
      isSearchActive,
    ]
  );

  const onPageChange = useCallback(
    (page) => {
      updateData(page, isSearchActive);
    },
    [isSearchActive, updateData]
  );

  const filterData = useCallback(
    async (ratingFilter) => {
      const table = tableRef.current?.getInstance();
      tableRef.current?.refreshSearch();
      setFoundRows([]);
      const columns = table?.getColumns().map((col) => col.getDefinition());
      const fields = getFieldsToFetch(columns);
      const symbols = extractSymbols(holdings);

      const rcSegments: any = [];

      Object.keys(ratingFilter).forEach((key) => {
        if (ratingFilter[key] === true) {
          rcSegments.push(ratingDict[key]);
        }
      });

      setRatings(rcSegments);
      const sorter = getSorter();

      const payload: any = {
        constraints: [
          [
            {
              dimension: "symbol",
              operator: "equals",
              segments: symbols,
            },
          ],
        ],
        page: { page: 1, rows: itemsPerPage },
        sort: sorter.sorter,
      };

      if (sorter.injestion != null) {
        payload["injestion"] = sorter["injestion"];
      }

      const searchContext: any = {
        sort: sorter.sorter,
        page: { page: 1, rows: 20000 },
        filters: [{ dimension: "symbol", segments: symbols }],
      };

      if (rcSegments.length) {
        payload.constraints[0].push({
          dimension: "rc",
          operator: "equals",
          segments: rcSegments,
        });

        searchContext.filters.push({ dimension: "rc", segments: rcSegments });
      }

      const alertsFilters = filterBarHelper.formatWidgetState("alert")(alerts);

      if (alertsFilters?.filter && alertsFilters?.filter.length) {
        const filtersToAdd = instrumentsAPI.convertToIndexBuilderSyntax({
          ranges: alertsFilters.filter,
        } as any);

        searchContext["ranges"] = alertsFilters.filter;

        payload.constraints = [
          payload.constraints[0].concat(filtersToAdd.constraints[0]),
        ];
      }

      table?.updateSearchContext(searchContext);
      const screeningResponse = await instrumentsAPI.screening(payload, true);

      const properties = fields.map((field) => ({
        date: null,
        property: field,
      }));

      const response = await instrumentsAPI.fetch({
        properties,
        symbols: screeningResponse.data,
        type: "security",
      });

      setDataTottalCount(screeningResponse?.dataTotalCount ?? 0);
      table?.insertData(mergeHoldingsFields(response?.data) ?? []);
      setTableData(mergeHoldingsFields(response?.data) ?? []);
    },
    [
      alerts,
      filterBarHelper,
      getFieldsToFetch,
      getSorter,
      holdings,
      instrumentsAPI,
      itemsPerPage,
      mergeHoldingsFields,
      ratingDict,
    ]
  );

  const filterByAlerts = useCallback(
    async (alertsFilters) => {
      const filters = filterBarHelper.formatWidgetState("alert")(alertsFilters);
      tableRef.current?.refreshSearch();
      setFoundRows([]);

      const table = tableRef.current?.getInstance();
      const columns = table?.getColumns().map((col) => col.getDefinition());
      const fields = getFieldsToFetch(columns);
      const symbols = extractSymbols(holdings);

      setAlerts(alertsFilters);
      const sorter = getSorter();

      const payload: any = {
        constraints: [
          [
            {
              dimension: "symbol",
              operator: "equals",
              segments: symbols,
            },
          ],
        ],
        page: { page: 1, rows: itemsPerPage },
        sort: sorter.sorter,
      };

      if (sorter.injestion != null) {
        payload["injestion"] = sorter["injestion"];
      }

      const searchContext: any = {
        page: { page: 1, rows: 20000 },
        sort: sorter.sorter,
        filters: [{ dimension: "symbol", segments: symbols }],
      };

      if (ratings && ratings.length) {
        payload.constraints[0].push({
          dimension: "rc",
          operator: "equals",
          segments: ratings,
        });

        searchContext.filters.push({ dimension: "rc", segments: ratings });
      }

      if (filters?.filter && filters?.filter.length) {
        const alertsFilters = instrumentsAPI.convertToIndexBuilderSyntax({
          ranges: filters.filter,
        } as any);

        searchContext["ranges"] = filters.filter;

        payload.constraints = [
          payload.constraints[0].concat(alertsFilters.constraints[0]),
        ];
      }

      table?.updateSearchContext(searchContext);
      const screeningResponse = await instrumentsAPI.screening(payload, true);

      const properties = fields.map((field) => ({
        date: null,
        property: field,
      }));

      const response = await instrumentsAPI.fetch({
        properties,
        symbols: screeningResponse.data,
        type: "security",
      });

      setDataTottalCount(screeningResponse?.dataTotalCount ?? 0);
      table?.insertData(mergeHoldingsFields(response?.data) ?? []);
      setTableData(mergeHoldingsFields(response?.data) ?? []);
    },
    [
      filterBarHelper,
      getFieldsToFetch,
      getSorter,
      holdings,
      instrumentsAPI,
      itemsPerPage,
      mergeHoldingsFields,
      ratings,
    ]
  );

  useEffect(() => {
    const actions: any = [];
    let action: any = null;
    const data: any = tableData ?? [];
    const columns: any = tableColumns ?? [];

    const colsToExport = columns.map((col) =>
      "label" in col
        ? col
        : {
            ...col,
            label: col.title,
          }
    );

    const exportList = {
      positions: [...holdings],
      positionsIndex: {},
    };

    let holding: any = null;

    for (let i = 0; i < exportList.positions.length; i++) {
      holding = exportList.positions[i];
      exportList.positionsIndex[holding.symbol] = i;
    }

    var inputStubExport = new InputStubExport({
      list: exportList,
      tools: [],
      viewer: {
        columns: colsToExport,
        sortCriteria: sorter,
      },
      total: data.length,
    });

    var fileName = systematicPortfolio.name + ".csv";

    action = {
      componentJSX: (
        <Export
          rankingCache={undefined}
          fileName={fileName}
          list={exportList}
          logFunction={(event) => {
            // **************** USAGE ******************
            var usage = window.App.usage;
            var info = {
              action: "EXPORT",
              actionParam: systematicPortfolio.id,
              function: "SYSTEMATIC_PORTFOLIOS",
            };
            usage.record(info);
            // **************** USAGE ******************
          }}
          widgets={{
            table: new TableWrapper({
              filter: inputStubExport.filter,
              grid: inputStubExport.viewer,
              tools: inputStubExport.tools,
            }),
          }}
        />
      ),
    };

    actions.push(action);

    switch (instanceType) {
      case "combined": {
        action = {
          componentJSX: (
            <ReportButton
              widgets={{
                table: new TableWrapper({
                  filter: inputStubExport.filter,
                  grid: inputStubExport.viewer,
                  tools: inputStubExport.tools,
                }),
              }}
              page="strategyLongShort"
              rankingCache={null}
              storage={storage}
              target={systematicPortfolio}
              title={`Create a PDF report for ${systematicPortfolio.name}`}
              usage={{
                function: "STRATEGY_LONG_SHORT",
              }}
            />
          ),
        };

        break;
      }
      case "product": {
        action = {
          componentJSX: (
            <ReportButton
              page={"systematicPortfolios"}
              target={systematicPortfolio}
              title={`Create a PDF report for ${systematicPortfolio.name}`}
              usage={window.App.usage}
              widgets={{
                table: new TableWrapper({
                  filter: inputStubExport.filter,
                  grid: inputStubExport.viewer,
                  tools: inputStubExport.tools,
                }),
              }}
            />
          ),
        };

        break;
      }
    }

    actions.push(action);

    var message = {
      from: "systematicProducts",
      content: {
        actions,
      },
    };

    // Because if is a strategy the actions are handled by the strategy builder page
    if (instanceType !== "strategy") {
      broadcast(config["channels"]["workflow"]["input"], message);
    }
  }, [
    broadcast,
    holdings,
    instanceType,
    sorter,
    storage,
    systematicPortfolio,
    tableColumns,
    tableData,
  ]);

  const handleSearchTitle = useCallback(
    async (symbols: string[]) => {
      setFoundRows(symbols);
      setDataTottalCount(symbols.length);
      const table = tableRef.current?.getInstance();
      const columns = table?.getColumns().map((col) => col.getDefinition());

      if (!columns || !holdings || holdings.length === 0) {
        return;
      }

      const holdingsMap = createHoldingsMap();

      const fields = getFieldsToFetch(columns);

      const data = await fetchInstrumentsFields(fields, symbols, 1);

      const mergedData = data.map((row) => ({
        ...row,
        ...holdingsMap[row.symbol],
      }));

      table?.insertData(mergedData ?? []);
    },
    [createHoldingsMap, fetchInstrumentsFields, getFieldsToFetch, holdings]
  );

  const onClearSearch = useCallback(() => {
    setFoundRows([]);
    updateData(1, false);
  }, [updateData]);

  const toolsEvents = useMemo(
    () => ({
      onChangePage: onPageChange,
      handleSearchResult: handleSearchTitle,
      onClearSearch,
    }),
    [handleSearchTitle, onClearSearch, onPageChange]
  );

  const tableEvents = useMemo(
    () => ({
      headerSort: onDataSorted,
      onColumnsEdit: updateColumns,
      columnsLoaded: updateColumns,
    }),
    [onDataSorted, updateColumns]
  );

  const toolsConfig: any = useMemo(() => {
    return {
      configurator: {
        hasToSkipLastApplied: false,
        defaultTemplateNameBase: "DEFAULT_SYSTEMATIC_PRODUCT_HOLDING",
        configurations: holdingsTabConfig.table,
        securityType: "security",
        isSaveLastUsedConfigurationColumnsEnabled: true,
      },
      pagination: {
        dataTotalCount,
      },
      viewAsListButton: true,
      search: true,
    };
  }, [dataTotalCount, holdingsTabConfig.table]);

  return holdingsTabConfig ? (
    <Box p={1} height={"98%"} gap={1} display={"flex"} flexDirection={"column"}>
      <Box display={"flex"} gap={1}>
        <Box display={"flex"}>
          <FilterCard label="Rating">
            <RatingCheckbox stateGetter={filterData} />
          </FilterCard>
        </Box>
        <Box>
          <FilterCard label={"Alert"}>
            <OptionsFilter
              value={alerts}
              setValue={filterByAlerts}
              widgetParams={{ options: optionsAlerts }}
              handleClickOnGroupLabel={(v) => console.log(v)}
            />
          </FilterCard>
        </Box>
      </Box>
      <Card sx={{ height: "100%", minHeight: 0 }}>
        <CardContent
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            minHeight: 0,
          }}
        >
          <Box flex={1} minHeight={0} display={"flex"}>
            <TrendratingTableV2
              ref={tableRef}
              tools={toolsConfig}
              tableEvents={tableEvents}
              toolsEvents={toolsEvents}
              rowTooltipFormatter
            />
          </Box>
          <Typography>
            <strong>
              Number of positions: {holdings?.length ?? 0} &nbsp; &nbsp;
              Contributions and performances are calculated from the rebalancing
              date.
            </strong>
          </Typography>
        </CardContent>
      </Card>
    </Box>
  ) : (
    <></>
  );
}
