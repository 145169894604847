import { Box, MenuItem, Select, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { useCallback, useMemo, useState } from "react";
import { v4 as uuidv4 } from "uuid";

type RowNumberSelectorProps = {
  onChange: (n: number) => void;
  label?: string;
  initialValue?: number;
};

const StyledSelect = styled(
  (props: { children: any; className?: any; value: any }) => (
    <Select
      value={props.value}
      fullWidth
      size="small"
      variant="outlined"
      classes={{ select: props.className }}
    >
      {props.children}
    </Select>
  )
)({
  fontSize: "12px !important",
  paddingTop: "2px !important",
  paddingBottom: "2px !important",
});

export function RowNumberSelector({
  onChange,
  label,
  initialValue,
}: RowNumberSelectorProps) {
  const [value, setValue] = useState(initialValue ?? 25);

  const options = useMemo(
    () => [
      { label: 25, value: 25 },
      { label: 50, value: 50 },
      { label: 100, value: 100 },
      { label: 1000, value: 1000 },
    ],
    []
  );

  const onValueChage = useCallback(
    (value) => {
      setValue(value);
      onChange(value);
    },
    [onChange]
  );

  return (
    <Box display={"flex"} alignItems={"center"} gap={1}>
      <Box display={"flex"} flex={1} gap={1} alignItems={"center"}>
        {label && <Typography whiteSpace={"nowrap"}>{label}</Typography>}
        <StyledSelect value={value}>
          {options.map((option) => (
            <MenuItem
              key={uuidv4()}
              value={option.value}
              onClick={() => onValueChage(option.value as any)}
            >
              <Typography>{option.label}</Typography>
            </MenuItem>
          ))}
        </StyledSelect>
      </Box>
    </Box>
  );
}
