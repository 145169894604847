import { Mutex } from "../../../Utility/Mutex";
import { Analytics, Entity } from "../../../api/compute/Analytics/Analytics";
import { SystematicProducts } from "../../../api/compute/SystematicProducts";
import { deepClone } from "../../../deepClone";
import { TDate } from "../../../trendrating/date/TDate";
import { Currency, Strategy } from "../../../types/Api";
import { AppEnvironment } from "../../../types/Defaults";
import { StrategyComputedTabType } from "../pages/strategies/builder/editors/Advanced/Result/Result";
import { getYesterdayMinus_N_Years } from "../pages/strategies/builder/editors/Advanced/utils";
import { StrategiesStorage, rawAnalytics } from "./StrategiesStorage";

export interface CombineEntitiesEngine {
  setEntitites: (entities: CombineEntitiesType) => void;
  setCombineParams: (params: CombineEntitiesParams) => void;
  prepareEntitiesForCombine: () => void;
  getCurves: () => Promise<{
    CURVES: {
      H: undefined | any[];
      B: undefined | any[];
      long: any[][];
      short: any[][];
    };
    POS: { H: undefined | any[]; B: undefined | any[] };
  }>;
  getAnalytics: (tab: StrategyComputedTabType) => Promise<any>;
}

export type CombineEntitiesParams =
  | {
      benchmark: string | null;
      currency: Currency;
      period: {
        period: { type: "DAY" | "YEAR"; value: number | string };
      };
    }
  | undefined;

export type CombineEntitiesType = {
  id: string | undefined;
  weight: number | undefined;
}[];

export type LoadingCallbacks = {
  startNew: Function;
  update: Function;
  complete: Function;
};

export class CombinedStrategiesStorage
  extends StrategiesStorage
  implements CombineEntitiesEngine
{
  entities: CombineEntitiesType;
  combineParams: CombineEntitiesParams;
  loadingCallbacks: LoadingCallbacks;
  mutex: Mutex;
  strategyCache: {
    [key: string]: Strategy;
  };
  smsApi: SystematicProducts;
  strategies: any;
  ITEM_TYPE: "COMBINED_STRATEGY" | "COMBINED_PRODUCT";

  constructor(
    environment: AppEnvironment,
    loadingBehaviours: LoadingCallbacks
  ) {
    super(environment);
    this.entities = [];
    this.combineParams = undefined;
    this.loadingCallbacks = loadingBehaviours;
    this.mutex = new Mutex();
    this.strategyCache = {};
    this.smsApi = new SystematicProducts(environment);
    this.ITEM_TYPE = "COMBINED_STRATEGY";
    this.strategies = undefined;
  }

  public setEntitites(strategies: CombineEntitiesType) {
    this.entities = strategies;
    this.strategyCache = {};
  }

  public setCombineParams(params: CombineEntitiesParams) {
    this.combineParams = params;
  }

  public async prepareEntitiesForCombine() {
    let benchmarkEntity: any = undefined;

    if (this.combineParams && this.combineParams.benchmark) {
      benchmarkEntity = await this.fromBenchmarkToEntity(
        this.combineParams.benchmark
      );
    }

    const weightsMap = {};
    const strategies: { type: "STRATEGY"; entity: any }[] = [];
    this.strategies = [];

    for (const [i, strategyInfo] of this.entities.entries()) {
      if (strategyInfo.id) {
        weightsMap[i] = strategyInfo.weight;
      }

      try {
        const strategy: any = await this.getStrategy(strategyInfo.id);

        if (strategy) {
          this.strategies.push(strategy);
          strategy.params.busId = this.progressBarInit(
            this.loadingCallbacks,
            strategy.name
          );

          strategy.params.backtesting.period.type =
            this.combineParams?.period.period.type;
          strategy.params.backtesting.period.value =
            this.combineParams?.period.period.value;

          strategies.push({ type: "STRATEGY", entity: deepClone(strategy) });
        }
      } catch (error) {
        console.error(error);
        const errorDetails = {
          status: 500,
          errorDetails: { code: "UNIVERSE_EMPTY" },
        };
        throw errorDetails;
      }
    }

    const date =
      this.combineParams?.period.period.type === "DAY"
        ? this.combineParams?.period.period.value
        : getYesterdayMinus_N_Years(
            this.combineParams?.period.period.value as number
          );

    const firstEntity: Entity = {
      type: "COMBINE_ENTITIES",
      entity: {
        entitiesToCombine: strategies,
        combineParams: {
          currency: this.combineParams?.currency ?? "local",
          date: date as string,
          allocation: weightsMap,
        },
      },
      includeFromDay: date as string,
    };

    this.analyticsCollector = await Analytics.initialize(
      this.environment,
      firstEntity,
      benchmarkEntity
    );
  }

  public async getAnalytics(tab: StrategyComputedTabType) {
    return this.mutex.runWithMutex(async () => {
      const factoryTag = this.analyticsCollector?.aTag;

      if (factoryTag == null) {
        console.error(
          "Cannot Build tags for analytics. factoryTag function is undefined it probably means that getAnalytics method was called before the processStrategy method"
        );
        return;
      }

      let analytics: string[] = [];
      const hasValidBenchmark = this.combineParams?.benchmark != null;
      const parameterSet: any[][] = deepClone(rawAnalytics[tab].H.default);

      if (hasValidBenchmark === true) {
        parameterSet.push(
          ...rawAnalytics[tab].B.default,
          ...rawAnalytics[tab].D.default
        );

        if ("withBenchmark" in rawAnalytics[tab].H) {
          parameterSet.push(...rawAnalytics[tab].H.withBenchmark);
        }
      }

      let analytic: string | null = null;

      const decodeMap = {};

      for (const set of parameterSet) {
        analytic = factoryTag(set[0], set[1], set[2], set?.[3]);

        if (analytic != null) {
          decodeMap[analytic] = null;
          analytics.push(analytic);
        }
      }

      let response: any = null;

      // removes duplicated analytics
      analytics = [...new Set(analytics)];

      response = await this.analyticsCollector?.getAnalytics(analytics);

      if ("status" in response && response.status !== 200) {
        // An error occured
        throw response;
      }

      if (response.data) {
        for (const [key, value] of Object.entries(response.data)) {
          decodeMap[key] = value;
        }

        const result = {};

        for (const analytic of parameterSet) {
          result[analytic[analytic.length - 1]] =
            decodeMap[
              factoryTag(analytic[0], analytic[1], analytic[2], analytic?.[3])
            ];
        }

        response = result;

        return this.responseTranformer(tab, response);
      }
    });
  }

  public async getCurves() {
    return this.mutex.runWithMutex(async () => {
      const defaultResult: {
        CURVES: {
          H: undefined | any[];
          B: undefined | any[];
          long: any[][];
          short: any[][];
        };
        POS: { H: undefined | any[]; B: undefined | any[] };
      } = {
        CURVES: { H: undefined, B: undefined, long: [], short: [] },
        POS: { H: undefined, B: undefined },
      };

      if (this.analyticsCollector) {
        try {
          const H =
            (await this.analyticsCollector.get("H", "prices")) ?? undefined;
          const B =
            (await this.analyticsCollector.get("B", "prices")) ?? undefined;
          const HPOS =
            (await this.analyticsCollector.get("H", "allocations")) ??
            undefined;
          const BPOS =
            (await this.analyticsCollector.get("B", "allocations")) ??
            undefined;

          const components = await this.analyticsCollector?.get(
            "H",
            "components"
          );

          if (components) {
            for (const component of components) {
              defaultResult.CURVES[component.type].push({
                prices: component.CURVES.H,
                name: component.name,
              });
            }
          }

          defaultResult.CURVES.H = H;
          defaultResult.CURVES.B = B;
          defaultResult.POS.H = HPOS;
          defaultResult.POS.B = BPOS;
        } catch (error) {
          console.log(error);
        }
      }

      return defaultResult;
    });
  }

  private async getStrategy(id) {
    if (id in this.strategyCache) {
      return this.strategyCache[id];
    }

    const strategy = await this.apiStrategies.select(id);

    this.strategyCache[strategy.id] = strategy;

    return this.strategyCache[id];
  }

  protected async getFinestGranularity() {
    const GRANULARITY_DICT_ENCODER = {
      "05_DAYS": 1,
      "20_DAYS": 2,
      "60_DAYS": 3,
    };

    const GRANULARITY_DICT_DECODER = {
      1: "05_DAYS",
      2: "20_DAYS",
      3: "60_DAYS",
    };

    const GRANULARITY_RESOLVER = {
      "05_DAYS": "WEEKLY",
      "20_DAYS": "MONTHLY",
      "60_DAYS": "QUARTERLY",
    };

    const strategies = await Promise.all(
      this.entities.map((strategyInfo) => this.getStrategy(strategyInfo.id))
    );

    const granularities: number[] = [];
    let granularity: any = null;

    for (const strategy of strategies) {
      granularity = strategy?.params?.strategy?.rebalance;
      if (granularity != null && granularity in GRANULARITY_DICT_ENCODER)
        granularities.push(GRANULARITY_DICT_ENCODER[granularity]);
    }

    const id = GRANULARITY_DICT_DECODER[Math.min(...granularities)];

    return GRANULARITY_RESOLVER[id];
  }

  protected async fromBenchmarkToEntity(benchmarkTag: string): Promise<any> {
    const params = this.combineParams;

    const granularity = await this.getFinestGranularity();

    let startDate: any = undefined;

    if (params?.period.period.type === "YEAR") {
      const _value = params?.period?.period?.value;

      startDate = getYesterdayMinus_N_Years(_value as number);
    } else {
      startDate = params?.period?.period?.value;
    }

    if (benchmarkTag.startsWith(this.BLENDED_TAG)) {
      const explodedTag = benchmarkTag.split(":");
      const portfolioId = explodedTag[1];

      const list = await this.apiLists.get(parseInt(portfolioId));

      this.benchmarkInfo = { name: list?.name ?? "", symbol: benchmarkTag };

      const entity = {
        portfolio: list,
        params: {
          inceptionDay: startDate,
          inceptionValue: 100,
          method: "none",
          spanGranularity: granularity,
        },
        includeFromDay: startDate,
      };

      return { type: "LIST", entity };
    }

    try {
      const response = await this.apiInstruments.fetch({
        symbols: [benchmarkTag],
        type: "security",
        properties: [{ date: null, property: "name" }],
      });

      const instrumentName = response?.data?.[0]?.name ?? "";

      this.benchmarkInfo = { name: instrumentName, symbol: benchmarkTag };
    } catch (error) {
      console.log(error);
    }

    return {
      type: "INSTRUMENT",
      entity: {
        symbol: benchmarkTag,
        params: {
          inceptionDay: startDate,
          inceptionValue: 100,
          method: "none",
          spanGranularity: granularity,
          currency: params?.currency,
        },
      },
      includeFromDay: startDate,
    };
  }

  protected async getCurve(
    who: "H" | "B",
    what: "prices" | "allocations" | "components"
  ) {
    return await this.analyticsCollector!.get(who, what);
  }

  async holdings() {
    const listHistory = await this.getCurve("H", "allocations");
    const currency = this.combineParams?.currency ?? "local";
    const lastAllocation = listHistory?.[listHistory.length - 1];
    const snapshot = await this.snapshot();

    const paramsAllocationAt = {
      cutoff: "last",
      date: lastAllocation?.d,
      listHistory: { POS: listHistory, currency },
      product: { id: null },
    };

    let startIndex: any = null;
    for (let i = 0, length = listHistory.length; i < length; i++) {
      if (listHistory[i]["d"] <= lastAllocation.d) {
        startIndex = i;
      }
    }
    if (startIndex == null) {
      startIndex = listHistory.length - 1;
    }

    const allocationAt = await this.smsApi.allocationAt(paramsAllocationAt);

    // snapshot holdings weight are actualized at
    // today
    const snapshotHoldingsMap = {};
    let holding: any = null;
    for (var i = 0, length = snapshot["positions"].length; i < length; i++) {
      holding = snapshot["positions"][i];
      snapshotHoldingsMap[holding["symbol"]] = holding;
    }
    // merging actualized weights with contributions
    // data
    const holdings: any = [];
    for (let i = 0; i < allocationAt["positions"].length; i++) {
      holding = allocationAt["positions"][i];
      // skip expired/unavailable
      if (holding["symbol"] in snapshotHoldingsMap) {
        holding["weight"] = snapshotHoldingsMap[holding["symbol"]]["weight"];

        holdings.push(holding);
      }
    }

    return { holdings };
  }

  async snapshot() {
    const listHistory = await this.getCurve("H", "allocations");
    const currency = this.combineParams?.currency ?? "local";

    let positionsToday: any = null;
    const lastAllocation = listHistory?.[listHistory.length - 1];
    const postionsOfLastAllocation = lastAllocation.v.map((pos) => ({
      symbol: pos.S,
      weight: pos.A,
    }));
    const asOfDate = TDate.daysToIso8601(this.environment.today.today);
    const fromDate = TDate.daysToIso8601(lastAllocation.d);

    const responseWeights = await this.apiLists._updateWeights(
      postionsOfLastAllocation,
      currency,
      fromDate,
      asOfDate
    );

    positionsToday = responseWeights.v;

    const paramsRatingAt = {
      adjustWeights: true,
      currency,
      equalWeighted: false,
      normalize: false,
      perfMetricMode: "active",
      v: positionsToday,
    };

    const response = await this.smsApi.ratingAt(paramsRatingAt);

    return response;
  }

  async info() {
    let products: any = null;
    let name = "";

    if (this.strategies && this.strategies.length) {
      products = [];

      for (const p of this.strategies) {
        products.push(deepClone(p));
      }

      const names = products.map((p) => p.name);

      name = names.join(" - ");
    }

    const combinedStrategy: any = {
      benchmark: this.combineParams?.benchmark,
      currency: this.combineParams?.currency,
      id: null,
      name,
      period: {
        type: this.combineParams?.period.period.type,
        value: this.combineParams?.period.period.value,
      },
      type: this.ITEM_TYPE,
    };

    for (let i = 0, N = this.entities.length; i < N; i++) {
      combinedStrategy[`product${i + 1}`] = this.entities[i];
    }

    return combinedStrategy;
  }
}
