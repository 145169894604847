import { Box, Button, Card, CardContent, Typography } from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import { createRoot } from "react-dom/client";
import { ColumnDefinition } from "tabulator-tables";
import SecurityChartModal from "../../../../../components/SecurityChartModal/SecurityChartModal";
import SecurityTooltipCore from "../../../../../components/SecurityTooltip/SecurityTooltipCore";
import {
  TableEventsV2,
  TableV2,
} from "../../../../../components/table/v2/TableCoreV2";
import { TrendratingTableV2 } from "../../../../../components/table/v2/TableV2";
import { useEnvironment } from "../../../../../hooks/useEnvironment";
import { useFormatter } from "../../../../../hooks/useFormatter";
import ReactDialogRationale from "../../../widgets/dialog/rationale/ReactDialogRationale";

type Props = {
  positions: any[];
  getRationaleData: any;
  optimizationMethodology:
    | "tacticalAllocation"
    | "implementStrategy"
    | "upgradePositionRatings"
    | null;
  tcrStart?: number | null;
};

export default function OptimizedPortfolio({
  positions,
  tcrStart,
  optimizationMethodology,
  getRationaleData,
}: Props) {
  const format = useFormatter();
  const [showRationale, setShowRationale] = useState(false);
  const [rationaleData, setRationaleData] = useState(null);
  const [isRationaleLoading, setIsRationaleLoading] = useState(false);
  const [showSecurityChart, setShowSecurityChart] = useState(false);
  const environment = useEnvironment();
  const [securityFocus, setSecurityFocus] = useState<any>(null);

  const columns = useMemo<ColumnDefinition[]>(() => {
    return [
      {
        title: "Weight",
        field: "weight",
        formatter: (cell) => {
          let value = cell.getData();

          return format.custom("number", {
            options: {
              hasPositiveSign: false,
              isPercentage: true,
              notAvailable: {
                input: null,
                output: "",
              },
            },
            output: "HTML",
            value: value["weight"],
            valueHelper: null,
          });
        },
        sorter: "number",
      },
      {
        title: "Ticker",
        field: "ticker",
        formatter: (cell) => {
          let _data = cell.getRow().getData();
          return `<div style="display:flex;align-items:center; gap:8px" class="content">${_data?.ticker}</div>`;
        },
        sorter: "string",
        cellMouseEnter: (e, cell) => {
          const cellElement = cell
            .getElement()
            .getElementsByClassName("content")[0];
          let _data = cell.getRow().getData();
          const nodeToAppend = document.createElement("span");
          nodeToAppend.classList.add(_data?.symbol.split(" ").join("-"));
          const root = createRoot(nodeToAppend);
          root.render(
            <>
              <SecurityTooltipCore
                symbol={_data?.symbol ?? ""}
                environment={environment}
                chartButtonHandler={(security) => {
                  setSecurityFocus(security);
                  setShowSecurityChart(true);
                }}
                setSecurityData={() => {}}
                additionalButtons={[
                  {
                    label: "Security analysis",
                    callback: () => {
                      window.location.href = `/app/analysis/instrument/${_data?.symbol}/`;
                    },
                  },
                ]}
              />
            </>
          );
          cellElement.appendChild(nodeToAppend);
        },
        cellMouseLeave: (e, cell) => {
          const cellElement = cell.getElement();
          let _data = cell.getRow().getData();
          const element = cellElement.getElementsByClassName(
            _data?.symbol.split(" ").join("-")
          )[0];
          if (element) {
            element.remove();
          }
        },
      },
      {
        title: "Rating",
        field: "rc",
        formatter: (cell, value, node) => {
          const data = cell.getData();
          return format.table("rc", "table", data);
        },
        sorter: "number",
      },
      { title: "Name", field: "name", sorter: "string" },

      {
        title: "Price",
        field: "vc",
        formatter: (cell, value, node) => {
          const data = cell.getData();
          return format.table("vc", "table", data);
        },
        sorter: "number",
      },
    ];
  }, [environment, format]);

  const tcr = useMemo(() => {
    let _tcr = format.tcr({ today: tcrStart }, "HTML");
    return _tcr ? (
      <span
        dangerouslySetInnerHTML={{
          __html: format.tcr(tcrStart, "HTML"),
        }}
      ></span>
    ) : (
      <span>-</span>
    );
  }, [format, tcrStart]);

  const tableRef = useRef<{ getInstance: () => TableV2 }>();
  const tableOptions = useMemo(() => {
    return {
      tableOption: {
        columns,
        ajaxSorting: false,
        movableColumns: false,
        resizableColumns: false,
      },
    };
  }, [columns]);
  const [tableReady, setTableReady] = useState(false);
  useEffect(() => {
    if (tableReady) {
      const table = tableRef.current?.getInstance();
      table?.insertData(positions);
    }
  }, [positions, tableReady]);
  const tableEvents: TableEventsV2 = useMemo(
    () => ({
      onTableBuilt: () => setTableReady(true),
      onTableDestroyed: () => {
        setTableReady(false);
      },
    }),
    []
  );

  return (
    <Card sx={{ boxShadow: 3, flex: 1 }}>
      <CardContent
        sx={{
          overflow: "hidden",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          py: 1,
          px: 0,
          gap: 1,
        }}
      >
        {showSecurityChart && securityFocus != null && (
          <SecurityChartModal
            environment={environment}
            security={securityFocus}
            showModal={showSecurityChart}
            onClose={() => setShowSecurityChart(false)}
          />
        )}
        <Box px={1} display={"flex"} flexDirection={"column"} gap={1}>
          <Box display={"flex"} gap={1} alignItems={"center"}>
            {/* <span
              dangerouslySetInnerHTML={{
                __html: format.tcr(tcrStart, "HTML") ?? "-",
              }}
            ></span> */}
            {tcr}
            <Typography variant="subtitle1">Optimized portfolio</Typography>
            {positions?.length > 0 &&
              optimizationMethodology &&
              optimizationMethodology !== "upgradePositionRatings" && (
                <Button
                  size="small"
                  onClick={() => {
                    setIsRationaleLoading(true);
                    setShowRationale(true);
                    getRationaleData().then((response) => {
                      setRationaleData(response);
                      setIsRationaleLoading(false);
                    });
                  }}
                >
                  Rationale
                </Button>
              )}
          </Box>
        </Box>{" "}
        {positions?.length > 0 && (
          <Box px={1} overflow={"auto"}>
            <TrendratingTableV2
              ref={tableRef}
              tableEvents={tableEvents}
              tableOptions={tableOptions}
            />
          </Box>
        )}
        {showRationale && (
          <ReactDialogRationale
            tableData={rationaleData}
            closeFn={() => setShowRationale(false)}
            isLoading={isRationaleLoading}
          />
        )}
      </CardContent>
    </Card>
  );
}
