import { SecurityPriceChart } from "../../../../../../../components/SecurityPriceChart/SecurityPriceChart";

type SummaryTableChartProps = {
  data: any;
  portfolio: any;
  portfolio2: any;
};

export default function SummaryTableChart({
  data,
  portfolio,
  portfolio2,
}: SummaryTableChartProps) {
  return (
    <div className="summary-table-chart-section blockWrapper">
      <p className="security-name">{`${data.securityTicker} ${data.securityName}`}</p>
      <div>
        {data.weightP1 && data.weightP2 && (
          <>
            <div className="security-table-charts-weights">
              <p>{`${portfolio}: ${data.weightP1}%`}</p>
              <p>{`${portfolio2}: ${data.weightP2}%`}</p>
            </div>
          </>
        )}
        {data.symbol && (
          <div style={{ width: "100%", height: "100%" }}>
            <SecurityPriceChart symbol={data.symbol} />
          </div>
        )}
      </div>
    </div>
  );
}
