import {
  Box,
  Card,
  CardContent,
  MenuItem,
  Select,
  Tab,
  Tabs,
} from "@mui/material";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { Properties } from "../../../../../../../api/Properties";
import { Instruments } from "../../../../../../../api/compute/Instruments";
import { Lists } from "../../../../../../../api/compute/Lists";
import { Strategies } from "../../../../../../../api/compute/Strategies";
import { Utils } from "../../../../../../../api/compute/Utils";
import { extractForDataIngestion } from "../../../../../../../api/compute/commons";
import { RadioButtonGroup } from "../../../../../../../components/RadioButtonGroup/RadioButtonGroup";
import { clone, deepClone } from "../../../../../../../deepClone";
import { useBroadcast } from "../../../../../../../hooks/useBroadcast";
import { useEnvironment } from "../../../../../../../hooks/useEnvironment";
import { useFormatter } from "../../../../../../../hooks/useFormatter";
import { httpAll } from "../../../../../../../httpAll";
import { TDate } from "../../../../../../../trendrating/date/TDate";
import { _rate } from "../../../../../../../trendrating/formatter/_rate";
import { config } from "../../../../../config-ts";
import { PortfolioAnalyzeStorage } from "../../../../../storage/PortfolioAnalyzeStorage";
import { CSV } from "../../../../../utils/SingletonCSV";
import DatePicker from "../../../../strategies/builder/editors/advancedWidgets/AdvancedFieldControllers/BacktestingController/components/DatePicker";
import styles from "./TabPointInTime.module.scss";
import { TrendratingTableV2 } from "../../../../../../../components/table/v2/TableV2";
import { TableV2 } from "../../../../../../../components/table/v2/TableCoreV2";

type TabPointInTimeProps = {
  dataManager: PortfolioAnalyzeStorage;
};

type AnalysisPeriodProps = {
  dataManager: PortfolioAnalyzeStorage;
};

type FactorComboBoxProps = {
  onChangeFactor: (event: any) => void;
};
type TimeframeComboBoxProps = {
  onChangeTimeframe: (event: any) => void;
};

type DateAnalysisWidgetProps = {
  onChangeDate: (value: Date) => void;
};

const defaultPage = { page: 1, rows: 20000 };
const defaultSort = { dimension: "marketcap", rev: true };

const defaultDate = new Date(TDate.daysToIso8601(TDate.yesterday()));

export function TabPointInTime({ dataManager }: TabPointInTimeProps) {
  const [currentDate, setCurrentDate] = useState(defaultDate);
  const [tableStatus, setTableStatus] = useState<{
    colmns: boolean;
    built: boolean;
  }>({
    colmns: false,
    built: false,
  });
  const tableRef = useRef<{ getInstance: () => TableV2 }>();

  const positionsPointInTime = useRef<any>();

  const environment = useEnvironment();

  const configuration = useMemo(() => {
    const analysisList =
      environment.get("account")["product"]["configuration"]["analysis_list"][
        "overview_tabs"
      ];

    return analysisList.find((item) => item.id === "pointInTime");
  }, [environment]);

  const listsAPI = useMemo(() => {
    return new Lists(environment.get("setup"));
  }, [environment]);

  const instrumentsAPI = useMemo(() => {
    return new Instruments(environment.get("setup"));
  }, [environment]);

  const utilityAPI = useMemo(
    () => new Utils(environment.get("setup")),
    [environment]
  );

  const urlParams = useParams();

  const properties = useMemo(
    () => environment.get("rawProperties"),
    [environment]
  );

  const currentPortfolioId = useMemo(() => urlParams.id, [urlParams.id]);

  const getPositions = useCallback(async () => {
    if (positionsPointInTime.current != null) {
      return positionsPointInTime.current;
    }
  }, []);

  const isAnalysisPeriodEnabled = useMemo(
    () => configuration.tabs.analysisPeriod.enabled,
    [configuration.tabs.analysisPeriod.enabled]
  );

  const [tab, setTab] = useState<0 | 1>(isAnalysisPeriodEnabled ? 1 : 0);

  const getPositionsToday = useCallback(async () => {
    // Starting point: we need positions today if anyone ha requested other positions
    try {
      const response = await dataManager.get("pointInTime");

      if (response?.["positionsToday"]) {
        return response?.["positionsToday"];
      }
    } catch (error) {
      console.log(error);
    }
  }, [dataManager]);

  const updatePointInTimePositions = useCallback((pos) => {
    positionsPointInTime.current = pos;
  }, []);

  const getPointInTimeParams = useCallback(async (rawParams) => {
    var asOf = TDate.daysToIso8601(rawParams.date);

    var yesterday = TDate.daysToIso8601(TDate.yesterday());

    if (asOf === yesterday) {
      return null;
    }

    var params = {
      asOf: asOf,
    };

    return params;
  }, []);

  const addWeights = useCallback(
    async (response) => {
      const portfolioPositions = await getPositions();

      const weightsIndex = portfolioPositions.reduce((prev, current) => {
        prev[current.symbol] = current.weight;

        return prev;
      }, {});

      const result = response.map((item) => ({
        ...item,
        weight: weightsIndex[item.symbol],
      }));

      return result;
    },
    [getPositions]
  );

  const getRows = useCallback(
    async (date, columns, sort?, pagination?) => {
      try {
        const params = {};
        const page = pagination ?? defaultPage;
        const sortBy = sort ?? defaultSort;

        const positions = await getPositions();

        const table = tableRef.current?.getInstance();

        const filters: any = [];

        filters.push({
          dimension: "symbol",
          segments: positions.map((item) => item.symbol),
        });

        params["filters"] = filters;
        params["page"] = page;

        const sortProperty = properties?.[sortBy.dimension] ?? null;

        if (sortProperty) {
          let field = sortProperty?.["backendPropertySort"] ?? sortBy.dimension;

          const pointInTimeProperties = [
            "dc",
            "dr",
            "fm",
            "fq",
            "fy",
            "mc",
            "rc",
            "vc",
          ];

          const isPointInTimeField =
            pointInTimeProperties.indexOf(sortBy.dimension) !== -1;

          const requireInjection =
            ("requireInjection" in sortProperty &&
              sortProperty["requireInjection"] === true) ||
            (date != null && isPointInTimeField);

          if (requireInjection) {
            const currentData = table?.getRows().map((row) => row.getData());
            var symbolsAndProperty = extractForDataIngestion(
              currentData ?? [],
              "symbol",
              sortBy["dimension"]
            );

            var sortPropertyId = currentPortfolioId + ":" + sortBy["dimension"];
            params["injestion"] = {
              data: symbolsAndProperty,
              field: sortPropertyId,
              type: "number",
            };
            params["sort"] = [
              {
                dimension: sortPropertyId,
                rev: sortBy.rev,
              },
            ];
          } else {
            params["sort"] = {
              dimension: field,
              rev: sortBy.rev,
            };
          }
        } else {
          params["sort"] = defaultSort;
        }

        let response: any = await instrumentsAPI.screening(params);

        if (response) {
          const symbols = response?.data;

          const fetchColumns = columns.map((col) => {
            return { date: null, property: col.field };
          }, []);

          const d = date ? TDate.dateToDays(new Date(date)) : undefined;
          let result: any = [];

          if (d) {
            response = await instrumentsAPI.fetch({
              type: "cube",
              properties: fetchColumns,
              symbols,
              multiDates: [d],
            });

            result = [];

            for (const symbol of symbols) {
              result.push({ ...response[symbol][d], symbol });
            }
          } else {
            response = await instrumentsAPI.fetch({
              date: d,
              properties: fetchColumns,
              symbols,
              type: "security",
            });

            result = response.data;
          }

          const data = await addWeights(result);

          if (data) {
            table?.insertData(data);
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    [addWeights, currentPortfolioId, getPositions, instrumentsAPI, properties]
  );

  const driftWeights = useCallback(
    async (asOf) => {
      try {
        const serverStatus = await utilityAPI.today();

        let dateSource = TDate.daysToIso8601(serverStatus.today);
        let listInfo = await listsAPI.portfolioFetch(
          [parseInt(currentPortfolioId!)],
          ["positionsToday", "currency", "weightsDate"]
        );

        listInfo = listInfo?.[0];

        const list = {
          weightsManagement: {
            currency: listInfo.currency ?? null, // null if type === "fixed"
            date: listInfo.weightsDate ?? null, // null if type === "fixed"
            type: listInfo.weightsDate != null ? "drifting" : "fixed", // or "drifting"
          },
          positions: listInfo?.positionsToday,
        };

        if (list?.weightsManagement?.type === "drifting") {
          dateSource = list.weightsManagement.date;
        }

        const responseWeights = await listsAPI._updateWeights(
          list.positions,
          list.weightsManagement.currency,
          dateSource,
          asOf
        );

        return responseWeights?.v.map((row) => ({
          weight: row.A,
          symbol: row.S,
        }));
      } catch (error) {
        console.log(error);
      }
    },
    [currentPortfolioId, listsAPI, utilityAPI]
  );

  const dataGet = useCallback(
    async (date, columns, sort?, page?) => {
      const rawParams = {
        date: TDate.dateToDays(date),
        performanceMetric: "active",
        weightingStrategy: null, //'WEIGHT_KEEP'
      };

      var pointInTimeParams: any =
        rawParams != null ? await getPointInTimeParams(clone(rawParams)) : null;

      try {
        if (pointInTimeParams != null) {
          const response = await driftWeights(pointInTimeParams.asOf);

          updatePointInTimePositions(response);
          await getRows(pointInTimeParams.asOf, columns, sort, page);
        } else {
          const positions = await getPositionsToday();
          updatePointInTimePositions(positions);
          await getRows(null, columns, sort, page);
        }
      } catch (error) {
        console.log(error);
      }
    },
    [
      driftWeights,
      getPointInTimeParams,
      getPositionsToday,
      getRows,
      updatePointInTimePositions,
    ]
  );

  const getCurrentColumns = useCallback(() => {
    const table = tableRef.current?.getInstance();
    const columns = table?.getColumns().map((col) => col.getDefinition());

    return columns;
  }, []);

  const componentInit = useCallback(() => {
    const columns = getCurrentColumns();
    dataGet(defaultDate, columns);
  }, [dataGet, getCurrentColumns]);

  const onChangeDate = useCallback(
    (date: Date) => {
      setCurrentDate(date);
      dataGet(date, getCurrentColumns(), defaultSort, {
        page: 1,
        rows: 20000,
      });
    },
    [dataGet, getCurrentColumns]
  );

  const sortRows = useCallback(
    ({ field, direction }) => {
      const columns = getCurrentColumns();
      dataGet(
        currentDate,
        columns,
        { dimension: field, rev: direction === "desc" },
        { page: 1, rows: 20000 }
      );
    },
    [currentDate, dataGet, getCurrentColumns]
  );

  const changeColumns = useCallback(
    (columns) => {
      dataGet(currentDate, columns, defaultSort, {
        page: 1,
        rows: 20000,
      });
    },
    [currentDate, dataGet]
  );

  const tableReady = useMemo(
    () => tableStatus.built && tableStatus.colmns,
    [tableStatus.built, tableStatus.colmns]
  );

  const changeTab = useCallback((evt, value) => {
    setTab(value);
    setTableStatus({ colmns: false, built: false });
  }, []);

  const tableEvents = useMemo(
    () => ({
      headerSort: sortRows,
      onTableBuilt: () =>
        setTableStatus((current) => ({ ...current, built: true })),
      columnsLoaded: (columns) => {
        if (columns.length) {
          setTableStatus((current) => ({ ...current, colmns: true }));
        }
      },
    }),
    [sortRows]
  );

  const toolsEvents = useMemo(
    () => ({
      onColumnsEdit: changeColumns,
    }),
    [changeColumns]
  );

  useEffect(() => {
    if (tableReady) {
      componentInit();
    }
  }, [componentInit, tableReady]);

  return (
    <Box display={"flex"} gap={1} height={"100%"} flexDirection={"column"}>
      {isAnalysisPeriodEnabled && (
        <Tabs value={tab} onChange={changeTab}>
          <Tab label={"Analyze period"} value={1} />
          <Tab label={"Analyze date"} value={0} />
        </Tabs>
      )}
      {tab === 0 ? (
        <Card sx={{ height: "100%", minHeight: 0 }}>
          <CardContent sx={{ height: "100%", display: "flex", flex: 1 }}>
            <TrendratingTableV2
              ref={tableRef}
              tools={{
                configurator: {
                  hasToSkipLastApplied: false,
                  defaultTemplateNameBase: "DEFAULT_PORTFOLIO_POINT_IN_TIME",
                  configurations:
                    configuration.tabs.analysisDate.widgets.viewer.table,
                  securityType: "security",
                  isSaveLastUsedConfigurationColumnsEnabled: true,
                },
                customTools: {
                  children: (
                    <Box>
                      <DateAnalysisWidget onChangeDate={onChangeDate} />
                    </Box>
                  ),
                },
              }}
              toolsEvents={toolsEvents}
              tableEvents={tableEvents}
              rowTooltipFormatter
            />
          </CardContent>
        </Card>
      ) : (
        <AnalysisPeriod dataManager={dataManager} />
      )}
    </Box>
  );
}

const AnalysisPeriod = ({ dataManager }: AnalysisPeriodProps) => {
  // Cached to do differences look at onChangeMode funciton
  const [mainDataCache, setMainDataCache] = useState<any>();
  const [granularity, setGranularity] = useState("WEEKLY__6");
  const [factor, setFactor] = useState("ts");
  const [mode, setMode] = useState<"VALUE" | "DIFF">("VALUE");
  const [portfolioInfo, setPortfolioInfo] = useState<{
    id: number | null;
    name: string | null;
  }>({ id: null, name: null });
  const [widgetModeKey, setWidgetModeKey] = useState(Date.now());
  const [highlightDiff, setHighlightDiff] = useState(false);

  const { broadcast } = useBroadcast();

  const tableRef = useRef<{ getInstance: () => TableV2 }>();
  const getPositionsToday = useCallback(async () => {
    // Starting point: we need positions today if anyone ha requested other positions
    try {
      const response = await dataManager.get("pointInTimePeriod");

      setPortfolioInfo({ id: response["id"], name: response["name"] });

      if (response?.["positionsToday"]) {
        return response?.["positionsToday"];
      }
    } catch (error) {
      console.log(error);
    }
  }, [dataManager]);

  const environment = useEnvironment();
  const utilsAPI = useMemo(
    () => new Utils(environment.get("setup")),
    [environment]
  );
  const strategyAPI = useMemo(
    () => new Strategies(environment.get("setup")),
    [environment]
  );
  const instrumentsAPI = useMemo(
    () => new Instruments(environment.get("setup")),
    [environment]
  );
  const configuration = useMemo(() => {
    const analysisList =
      environment.get("account")["product"]["configuration"]["analysis_list"][
        "overview_tabs"
      ];

    return analysisList.find((item) => item.id === "pointInTime");
  }, [environment]);
  const format = useFormatter();
  const rawProperties = useMemo(
    () => environment.get("rawProperties"),
    [environment]
  );

  const defaultColumns = useMemo(() => {
    const defaultColumns =
      configuration.tabs.analysisPeriod.viewer.table.columns.security;
    return defaultColumns.map((field) => ({
      field,
      title: rawProperties[field].name[0],
    }));
  }, [
    configuration.tabs.analysisPeriod.viewer.table.columns.security,
    rawProperties,
  ]);

  const formatRCdelta = useCallback((value) => {
    var hasTrendArrow = true;
    var notAvailable = {
      input: null,
      output: "",
    };

    // shallow comparison wanted (like null for undefined / null)
    // eslint-disable-next-line eqeqeq
    if (value == notAvailable["input"]) {
      return notAvailable["output"];
    }

    var className: any = null;
    var formatted: any = "";
    var scale = _rate["rating"];
    var rateMeta = scale[parseInt(value)];
    const isInt = Number.isInteger(value);

    if (!isInt) {
      formatted = [
        '<strong class="',
        rateMeta["class"],
        '">',
        rateMeta["label"],
        "</strong>",
      ];

      if (hasTrendArrow === true) {
        className =
          value === 1.1 || value === 2.1 || value === -1.1 || value === -2.1
            ? "format-alert i-upgrade"
            : "format-alert i-downgrade";

        formatted.push(['<span class="', className, '"></span>'].join(""));

        formatted = formatted.join("");
      }
    } else {
      var rateMetaTitle = null;
      // If there is no rateDate, do not generate the
      // title attribute on element
      formatted = [
        '<strong class="',
        rateMeta["class"],
        rateMetaTitle != null ? '" title="' + rateMetaTitle : "",
        '">',
        rateMeta["label"],
        "</strong>",
      ].join("");
    }

    return formatted;
  }, []);

  const prepareStory = useCallback(async () => {
    const todayDate: any = await utilsAPI.today();
    const todayDays = todayDate.today;
    let today = todayDays;
    today = TDate.daysToDate(today);

    today.setFullYear(today.getFullYear() - 2);

    const twoYearsBackDate = TDate.dateToDays(today);

    const story: { H: { d: number; v: 100.0 }[] } = { H: [] };

    for (let i = twoYearsBackDate; i <= todayDays; i++) {
      story.H.push({ d: i, v: 100.0 });
    }

    return story;
  }, [utilsAPI]);

  const getWeek = useCallback((date: Date) => {
    // Imposta il giorno a lunedì della settimana corrente
    date.setHours(0, 0, 0, 0);
    date.setDate(date.getDate() + 3 - ((date.getDay() + 6) % 7));
    var week1 = new Date(date.getFullYear(), 0, 4);

    // Calcola il numero della settimana
    return (
      1 +
      Math.round(
        ((date.getTime() - week1.getTime()) / 86400000 -
          3 +
          ((week1.getDay() + 6) % 7)) /
          7
      )
    );
  }, []);

  const getMonth = useCallback(
    (date: Date) => date.toLocaleString("en-EN", { month: "long" }),
    []
  );

  const periodLabel = useCallback(
    (mode, granularity, dates) => {
      function getQuarter(date) {
        var q = Math.floor(date.getMonth() / 3) + 1;
        const label = {
          1: "1st",
          2: "2nd",
          3: "3th",
          4: "4th",
        };
        return label[q];
      }
      var labels: any = [];
      const subLabel = {
        WEEKLY: "Week",
        MONTHLY: "Month",
        QUARTERLY: "Quarter",
      };
      const gLabel = subLabel[granularity];
      var date;
      if (mode === "VALUE") {
        labels.push("Current");

        for (let i = 1; i < dates.length; i++) {
          date = new Date(TDate.daysToMilliseconds(dates[i]));
          switch (granularity) {
            case "WEEKLY":
              labels.push(gLabel + " " + getWeek(date));
              break;
            case "MONTHLY":
            case "QUARTERLY":
            default:
              labels.push(getMonth(date));
              break;
          }
        }
      } else if (mode === "DIFF") {
        labels.push("From previous " + gLabel);

        for (let i = 1; i < dates.length; i++) {
          date = dates[i];
          const [to] = date.split("__");
          date = new Date(TDate.daysToMilliseconds(parseInt(to)));
          switch (granularity) {
            case "WEEKLY":
              labels.push(gLabel + " " + getWeek(date));
              break;
            case "MONTHLY":
              labels.push(getMonth(date));
              break;
            case "QUARTERLY":
              labels.push(getQuarter(date) + " Q " + date.getFullYear());
              break;
            default:
              labels.push("");
              break;
          }
        }
      }
      return labels;
    },
    [getMonth, getWeek]
  );

  const prepareDiffColumns = useCallback(
    (originalCols, periods, factor, granularity, highlight) => {
      const cols: any = [];
      let isKnownProperty = false;
      let sortByFormattedProperty = false;
      let columnVisible = true;

      for (const col of originalCols) {
        const fieldInfo = rawProperties[col.field] ?? null;
        isKnownProperty = Boolean(fieldInfo);

        if (factor === "rc" && col.field === "rc") {
          continue;
        }

        if (isKnownProperty) {
          sortByFormattedProperty =
            fieldInfo["formatter"]["table"]["type"] === "taxon";

          cols.push({
            field: col.field,
            title: col.title,
            minWidth: 3,
            visible: columnVisible,
            formatter: (cell) => {
              const cellData = cell.getData();
              return format.table(col.field, "table", cellData);
            },
            sorter: sortByFormattedProperty
              ? (a, b, aRow, bRow) => {
                  const backendProperty =
                    rawProperties[col.field]["backendProperty"];
                  const aData = aRow.getData();
                  const bData = bRow.getData();
                  const formattedA = format.table(col.field, "table", {
                    [backendProperty]: aData[backendProperty],
                  });
                  const formattedB = format.table(col.field, "table", {
                    [backendProperty]: bData[backendProperty],
                  });

                  if (formattedA > formattedB) {
                    return 1;
                  } else if (formattedA < formattedB) {
                    return -1;
                  }

                  return 0;
                }
              : (a, b) => {
                  if (a > b) {
                    return 1;
                  } else if (a < b) {
                    return -1;
                  }
                  return 0;
                },
            sorterParams: {
              alignEmptyValues: "bottom",
            },
            hozAlign: "left",
            skipTransformation: true,
          });
        }
      }

      periods.sort((a, b) => (a > b ? -1 : 1), []);
      const [gran] = granularity.split("__");
      const labels = periodLabel("DIFF", gran, periods);
      const labelsToHeaderStruct = {};

      for (let i = 0; i < periods.length; i++) {
        labelsToHeaderStruct[periods[i]] = labels[i];
      }

      let splittedPeriod: any = null;

      for (const date of periods) {
        splittedPeriod = date.split("__");

        cols.push({
          title: labelsToHeaderStruct[date],
          headerHozAlign: "right",
          columns: [
            {
              field: date,
              minWidth: 3,
              title: `${format.custom("date", {
                options: {
                  format: ["D", "M", "Y"],
                  isMillisecond: false,
                  notAvailable: {
                    input: null,
                    output: "",
                  },
                  separator: " ",
                },
                output: "TEXT",
                value: splittedPeriod[0],
                valueHelper: null,
              })} from ${format.custom("date", {
                options: {
                  format: ["D", "M", "Y"],
                  isMillisecond: false,
                  notAvailable: {
                    input: null,
                    output: "",
                  },
                  separator: " ",
                },
                output: "TEXT",
                value: splittedPeriod[1],
                valueHelper: null,
              })}`,

              formatter: (cell) => {
                if (factor === "rc") {
                  const rc = cell.getValue();
                  const formatted = formatRCdelta(rc);
                  return formatted;
                } else {
                  const value = cell.getValue();

                  if (highlight) {
                    if (value !== 0) {
                      const HTML = cell.getElement();
                      HTML.style.backgroundColor =
                        value > 0
                          ? "rgba(0, 255, 0, 0.2)"
                          : "rgba(255, 24, 24, 0.2)";

                      return `<span color="black!important">${format.table(
                        factor,
                        "table",
                        {
                          [factor]: value,
                        }
                      )}</span>`;
                    } else {
                      return "-";
                    }
                  }

                  if (value !== 0) {
                    return format.table(factor, "table", {
                      [factor]: value,
                    });
                  }

                  return "-";
                }
              },
            },
          ],
          skipTransformation: true,
        });
      }

      return cols;
    },
    [format, formatRCdelta, periodLabel, rawProperties]
  );

  const prepareColumns = useCallback(
    (originalCols, periods, factor, granularity) => {
      const cols: any = [];
      let isKnownProperty = false;
      let sortByFormattedProperty = false;
      let columnVisible = true;

      for (const col of originalCols) {
        const fieldInfo = rawProperties[col.field] ?? null;
        isKnownProperty = Boolean(fieldInfo);
        columnVisible = !(factor === "rc" && col.field === "rc");

        if (isKnownProperty) {
          sortByFormattedProperty =
            fieldInfo["formatter"]["table"]["type"] === "taxon";

          cols.push({
            field: col.field,
            title: col.title,
            visible: columnVisible,
            formatter: (cell) => {
              const cellData = cell.getData();
              return format.table(col.field, "table", cellData);
            },
            sorter: sortByFormattedProperty
              ? (a, b, aRow, bRow) => {
                  const backendProperty =
                    rawProperties[col.field]["backendProperty"];
                  const aData = aRow.getData();
                  const bData = bRow.getData();
                  const formattedA = format.table(col.field, "table", {
                    [backendProperty]: aData[backendProperty],
                  });
                  const formattedB = format.table(col.field, "table", {
                    [backendProperty]: bData[backendProperty],
                  });

                  if (formattedA > formattedB) {
                    return 1;
                  } else if (formattedA < formattedB) {
                    return -1;
                  }

                  return 0;
                }
              : (a, b) => {
                  if (a > b) {
                    return 1;
                  } else if (a < b) {
                    return -1;
                  }
                  return 0;
                },
            sorterParams: {
              alignEmptyValues: "bottom",
            },
            hozAlign: "left",
            skipTransformation: true,
          });
        }
      }

      periods.sort((a, b) => (a > b ? -1 : 1), []);
      const [gran] = granularity.split("__");
      const labels = periodLabel("VALUE", gran, periods);
      const labelsToHeaderStruct = {};

      for (let i = 0; i < periods.length; i++) {
        labelsToHeaderStruct[periods[i]] = labels[i];
      }

      for (const date of periods) {
        cols.push({
          title: labelsToHeaderStruct[date],
          headerHozAlign: "right",
          columns: [
            {
              minWidth: 3,
              field: JSON.stringify(date),
              title: `<span style="padding-left: 20px;">${format.custom(
                "date",
                {
                  options: {
                    format: ["D", "M", "Y"],
                    isMillisecond: false,
                    notAvailable: {
                      input: null,
                      output: "",
                    },
                    separator: " ",
                  },
                  output: "TEXT",
                  value: date,
                  valueHelper: null,
                }
              )}</span>`,
              formatter: (cell) => {
                return format.table(factor, "table", {
                  [factor]: cell.getValue(),
                });
              },
            },
          ],
          skipTransformation: true,
        });
      }

      return cols;
    },
    [format, periodLabel, rawProperties]
  );

  const getData = useCallback(
    async (factor: string, timeframeInfo: string, columns: any) => {
      const [granularity, length] = timeframeInfo.split("__");

      try {
        const story = await prepareStory();

        const analytic = `r#${granularity},1,CALENDAR,${granularity},1,TD,H`;
        const datesCalendar = await strategyAPI.priceAnalytics(story.H, [
          analytic,
        ]);

        const dateNumber = parseInt(length) + 1;

        const datesColumns: number[] = [];
        const datesArray = Object.keys(datesCalendar[analytic]);
        for (
          let i = datesArray.length - 1;
          i > datesArray.length - dateNumber;
          i--
        ) {
          datesColumns.push(parseInt(datesArray[i]));
        }

        let symbols = await getPositionsToday();
        symbols = symbols.map((item) => item.symbol);

        const indexedSymbols = {};

        for (const s of symbols) {
          indexedSymbols[s] = {
            symbol: s,
          };

          for (const col of columns) {
            if ("field" in col) {
              const backendProperty =
                rawProperties?.[col.field]?.["backendProperty"] ?? null;
              indexedSymbols[s][backendProperty] = null;
            } else if ("columns" in col) {
              for (const _col of col.columns) {
                const backendProperty =
                  rawProperties?.[_col.field]?.["backendProperty"] ?? null;
                if (backendProperty) {
                  indexedSymbols[s][backendProperty] = null;
                }
              }
            }
          }

          for (const d of datesColumns) {
            indexedSymbols[s][d] = null;
          }
        }

        const properties: any = [];

        for (const col of columns) {
          if ("field" in col) {
            properties.push({
              date: null,
              property: col.field,
            });
          } else if ("columns" in col) {
            for (const _col of col.columns) {
              properties.push({
                date: null,
                property: _col.field,
              });
            }
          }
        }

        const fetchFields = instrumentsAPI.fetch({
          type: "security",
          properties,
          symbols,
        });

        const fetchPoinIntime = instrumentsAPI.fetch({
          type: "cube",
          properties: [{ date: null, property: factor }],
          symbols,
          multiDates: datesColumns,
        });

        const { fetch, pointInTime } = await httpAll({
          fetch: fetchFields,
          pointInTime: fetchPoinIntime,
        });

        const fetchResultMap = fetch.data.reduce((prev, current) => {
          prev[current.symbol] = { ...current };

          return prev;
        }, {});

        for (const symbol in indexedSymbols) {
          for (const field in indexedSymbols[symbol]) {
            if (field in pointInTime[symbol]) {
              indexedSymbols[symbol][field] =
                pointInTime?.[symbol]?.[field]?.[factor] ?? null;
            } else {
              indexedSymbols[symbol][field] = fetchResultMap[symbol]?.[field];
            }
          }
        }

        return {
          rows: Object.values(indexedSymbols),
          columns: prepareColumns(columns, datesColumns, factor, granularity),
        };
      } catch (error) {
        console.log(error);

        return { rows: undefined, columns: [] };
      }
    },
    [
      getPositionsToday,
      instrumentsAPI,
      prepareColumns,
      prepareStory,
      rawProperties,
      strategyAPI,
    ]
  );

  const updateData = useCallback(
    async (factor, granularity, columns) => {
      const { rows, columns: cols } = await getData(
        factor,
        granularity,
        columns
      );

      const table = tableRef.current?.getInstance();
      setMainDataCache(rows);
      table?.insertColumns(cols);
      table?.insertData(rows);
    },
    [getData]
  );

  const componentInit = useCallback(async () => {
    updateData("ts", "WEEKLY__6", defaultColumns);
  }, [defaultColumns, updateData]);

  useEffect(() => {
    componentInit();
  }, [componentInit]);

  const refreshWidgetMode = useCallback(() => {
    setMode("VALUE");
    setHighlightDiff(false);
    setWidgetModeKey(Date.now());
  }, []);

  const onChangeTimeframe = useCallback(
    async (timeframeInfo) => {
      const table = tableRef.current?.getInstance();
      const columns = table?.getColumns().map((col) => col.getDefinition());
      refreshWidgetMode();
      setGranularity(timeframeInfo);
      updateData(factor, timeframeInfo, columns);
    },
    [factor, refreshWidgetMode, updateData]
  );

  const onChangeFactor = useCallback(
    async (factor) => {
      const table = tableRef.current?.getInstance();
      const columns = table?.getColumns().map((col) => col.getDefinition());
      setFactor(factor);
      refreshWidgetMode();
      updateData(factor, granularity, columns);
    },
    [granularity, refreshWidgetMode, updateData]
  );

  const prepareCSVHeader = useCallback(
    (field, columnsRow) => {
      const isPropertyField = Boolean(rawProperties[field]);

      if (isPropertyField) {
        columnsRow.push(`"${rawProperties[field]["name"][0]}"`);
      } else if (mode === "VALUE") {
        // Format the date
        columnsRow.push(
          `"${format.custom("date", {
            options: {
              format: ["D", "M", "Y"],
              isMillisecond: false,
              notAvailable: {
                input: null,
                output: "",
              },
              separator: " ",
            },
            output: "TEXT",
            value: field,
            valueHelper: null,
          })}"`
        );
      } else if (mode === "DIFF") {
        const [date1, date2] = field.split("__");
        // Format the date
        columnsRow.push(
          `"${format.custom("date", {
            options: {
              format: ["D", "M", "Y"],
              isMillisecond: false,
              notAvailable: {
                input: null,
                output: "",
              },
              separator: " ",
            },
            output: "TEXT",
            value: date1,
            valueHelper: null,
          })} from ${format.custom("date", {
            options: {
              format: ["D", "M", "Y"],
              isMillisecond: false,
              notAvailable: {
                input: null,
                output: "",
              },
              separator: " ",
            },
            output: "TEXT",
            value: date2,
            valueHelper: null,
          })}"`
        );
      }
    },
    [format, mode, rawProperties]
  );

  const addCSVRow = useCallback(
    (row, field, line) => {
      const datum = row[field];

      const isPropertyField = Boolean(rawProperties[field]);

      if (isPropertyField) {
        line.push(
          '"' + format.text(field, "table", datum, row, "Stock", true) + '"'
        );
      } else {
        line.push(`"${row[field]}"`);
      }
    },
    [format, rawProperties]
  );

  const exportToCsv = useCallback(async () => {
    const exportFileName = portfolioInfo.name + ".csv";
    const tableComponent = tableRef.current?.getInstance();
    const tableColumns =
      tableComponent
        ?.getColumns()
        .map((columnComponent) => columnComponent.getDefinition()) ?? [];
    const tableData =
      tableComponent?.getRows().map((row) => row.getData()) ?? [];

    const table: any = [];

    const columnsRow: any = [];

    const currentSort = tableComponent?.getSorter();

    let sort: any = null;

    if (currentSort) {
      if (currentSort.length > 0) {
        sort = { field: currentSort[0].field, dir: currentSort[0].dir };
      }
    }

    const dataClone = deepClone(tableData);

    if (sort) {
      if (
        rawProperties[sort.field] != null &&
        rawProperties[sort.field]["formatter"]["table"]["type"] === "taxon"
      ) {
        dataClone.sort((a, b) => {
          let direction = sort.dir === "asc" ? 1 : -1;

          const aData = a;
          const bData = b;
          const formattedA = format.table(sort.field, "table", aData);
          const formattedB = format.table(sort.field, "table", bData);

          if (formattedA > formattedB) {
            return direction;
          } else if (formattedA < formattedB) {
            return -direction;
          }

          return 0;
        });
      } else {
        dataClone.sort((a, b) => {
          let direction = sort.dir === "asc" ? 1 : -1;
          return a[sort.field] > b[sort.field] ? direction : -direction;
        });
      }
    }

    for (const col of tableColumns) {
      if ("field" in col) {
        prepareCSVHeader(col.field, columnsRow);
      } else if ("columns" in col) {
        for (const _col of col.columns!) {
          prepareCSVHeader(_col.field, columnsRow);
        }
      }
    }

    table.push(columnsRow);

    let line: any = null;

    for (const row of dataClone) {
      line = [];

      for (const col of tableColumns) {
        if ("field" in col) {
          addCSVRow(row, col.field, line);
        } else if ("columns" in col) {
          for (const _col of col.columns!) {
            addCSVRow(row, _col.field, line);
          }
        }
      }

      table.push(line);
    }

    await CSV.create(table, exportFileName);
  }, [addCSVRow, format, portfolioInfo.name, prepareCSVHeader, rawProperties]);

  const manageWorkflow = useCallback(() => {
    let actions: any = [];
    let action: any = null;

    action = {
      componentJSX: (
        <li
          title={"Export to MS Excel"}
          onClick={exportToCsv}
          className="menu__item"
        >
          Export
        </li>
      ),
    };

    actions.push(action);

    var message = {
      from: "analysisList",
      content: {
        actions,
      },
    };

    broadcast(config["channels"]["workflow"]["input"], message);
  }, [broadcast, exportToCsv]);

  useEffect(() => {
    manageWorkflow();

    return () => {
      var message = {
        from: "analysisList",
        content: {
          actions: [],
        },
      };

      broadcast(config["channels"]["workflow"]["input"], message);
    };
  }, [broadcast, manageWorkflow]);

  const onChangeMode = useCallback(
    (newMode, highlight = false) => {
      let diffs: { colField: string; value: number }[] = [];
      const table = tableRef.current?.getInstance();
      const columns =
        table?.getColumns().map((col) => col.getDefinition()) ?? [];

      if (newMode === "DIFF") {
        const dataClone = deepClone(mainDataCache);

        const newDataset: any = [];

        for (const row of dataClone) {
          const newAllocation = {};
          const datesFields: string[] = [];

          for (const key in row) {
            // if is not a date field store it
            if (Number.isNaN(parseInt(key))) {
              newAllocation[key] = row[key];
            } else {
              datesFields.push(key);
            }
          }
          datesFields.sort((a, b) => (a > b ? -1 : 1));
          diffs = [];

          let current: any = null;
          let next: any = null;

          for (let i = 0; i < datesFields.length; i++) {
            current = row?.[datesFields[i]];
            next = row?.[datesFields[i + 1]];

            if (current != null && next != null) {
              if (factor === "rc") {
                let delta = 0;

                if (current !== next) {
                  const upgrade = current >= 0 ? 0.1 : -0.1;
                  const downgrade = current >= 0 ? 0.2 : -0.2;
                  delta = current > next ? upgrade : downgrade;
                }

                diffs.push({
                  colField: `${datesFields[i]}__${datesFields[i + 1]}`,
                  value: current + delta,
                });
              } else {
                diffs.push({
                  colField: `${datesFields[i]}__${datesFields[i + 1]}`,
                  value: current - next,
                });
              }
            }
          }

          for (const cell of diffs) {
            newAllocation[cell.colField] = cell.value;
          }

          newDataset.push(newAllocation);
        }

        const periods = diffs.map((item) => item.colField);
        table?.insertData(newDataset);

        const diffCols = prepareDiffColumns(
          columns,
          periods,
          factor,
          granularity,
          highlight
        );

        table?.insertColumns(diffCols);
      } else {
        updateData(factor, granularity, defaultColumns);
        setHighlightDiff(false);
      }

      setMode(newMode);
    },
    [
      defaultColumns,
      factor,
      granularity,
      mainDataCache,
      prepareDiffColumns,
      updateData,
    ]
  );

  const changeHighlightDiff = useCallback(() => {
    setHighlightDiff(!highlightDiff);
    onChangeMode("DIFF", !highlightDiff);
  }, [highlightDiff, onChangeMode]);

  return (
    <Box
      flex={1}
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        minHeight: 0,
      }}
    >
      <Box display={"flex"} gap={1}>
        <FactorComboBox onChangeFactor={onChangeFactor} />
        <TimeframeComboBox onChangeTimeframe={onChangeTimeframe} />
        <ModeButtonGroup key={widgetModeKey} onChangeViz={onChangeMode} />
        {mode === "DIFF" && (
          <DiffHighlight
            onChangeHighlight={changeHighlightDiff}
            isActive={highlightDiff}
          />
        )}
      </Box>
      <Box sx={{ flex: 1, minHeight: 0, display: "flex" }}>
        <TrendratingTableV2 ref={tableRef} rowTooltipFormatter />
      </Box>
    </Box>
  );
};

const FactorComboBox = ({ onChangeFactor }: FactorComboBoxProps) => {
  const environment = useEnvironment();
  const configuration = useMemo(() => {
    const analysisList =
      environment.get("account")["product"]["configuration"]["analysis_list"][
        "overview_tabs"
      ];

    return analysisList.find((item) => item.id === "pointInTime");
  }, [environment]);

  const widgetConfig = useMemo(
    () => configuration.tabs.analysisPeriod,
    [configuration.tabs.analysisPeriod]
  );
  const defaultValue = useMemo(() => {
    return widgetConfig.analytic.default;
  }, [widgetConfig.analytic.default]);
  const options = useMemo(() => {
    const analytics = widgetConfig.analytic.avaliables;
    const properties = new Properties({
      properties: environment.get("setup")["properties"],
    });

    const opts: { label: string; value: string }[] = [];

    for (const analytic of analytics) {
      opts.push({
        label: properties.get(analytic.field, analytic.panelIndex, null),
        value: analytic.field,
      });
    }

    return opts;
  }, [environment, widgetConfig.analytic.avaliables]);

  const [value, setValue] = useState(defaultValue ?? "ts");

  const handleChange = useCallback(
    (event) => {
      const _value = event.target.value;
      onChangeFactor(_value);
      setValue(_value);
    },
    [onChangeFactor]
  );

  return (
    <div className="tMarketConstraintsBar-section">
      <label
        className="tMarketConstraintsBar-label"
        style={{ marginBottom: "4px" }}
      >
        Analytic
      </label>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Select
          value={value}
          onChange={handleChange}
          SelectDisplayProps={{ style: { padding: "0.3em 2.5em 0.3em 0.5em" } }}
        >
          {options.map((opt) => (
            <MenuItem key={uuidv4()} value={opt.value}>
              {opt.label}
            </MenuItem>
          ))}
        </Select>
      </div>
    </div>
  );
};

const DiffHighlight = ({ onChangeHighlight, isActive }) => {
  return (
    <div
      className="tMarketConstraintsBar-section"
      style={{ display: "flex", flexDirection: "column" }}
    >
      <label className="tMarketConstraintsBar-label">Highlight</label>
      <div style={{ display: "flex", flex: 1 }}>
        <button
          className={`${styles.colorBtn} ${isActive ? styles.active : ""}`}
          onClick={onChangeHighlight}
        >
          <span className="i-color"></span>
        </button>
      </div>
    </div>
  );
};

const ModeButtonGroup = ({ onChangeViz }) => {
  return (
    <div className="tMarketConstraintsBar-section">
      <label className="tMarketConstraintsBar-label">Visualization</label>
      <div>
        <RadioButtonGroup
          options={[
            { label: "Value", value: "VALUE" },
            { label: "Differences", value: "DIFF" },
          ]}
          onChange={onChangeViz}
        />
      </div>
    </div>
  );
};

const TimeframeComboBox = ({ onChangeTimeframe }: TimeframeComboBoxProps) => {
  const environment = useEnvironment();
  const configuration = useMemo(() => {
    const analysisList =
      environment.get("account")["product"]["configuration"]["analysis_list"][
        "overview_tabs"
      ];

    return analysisList.find((item) => item.id === "pointInTime");
  }, [environment]);

  const widgetConfig = useMemo(
    () => configuration.tabs.analysisPeriod,
    [configuration.tabs.analysisPeriod]
  );

  const options = useMemo(() => {
    const periods = widgetConfig.periods;

    const opts: {
      label: string;
      value: string;
    }[] = [];

    for (const period of periods) {
      if (period.mode !== "fetch") {
        continue;
      }
      opts.push({
        label: period.label,
        value: period.granularity + "__" + period.len,
      });
    }

    return opts;
  }, [widgetConfig.periods]);

  const handleChange = useCallback(
    (event) => {
      const _value = event;
      onChangeTimeframe(_value);
    },
    [onChangeTimeframe]
  );
  return (
    <div className="tMarketConstraintsBar-section">
      <label className="tMarketConstraintsBar-label">Timeframe</label>
      <div>
        <RadioButtonGroup options={options} onChange={handleChange} />
      </div>
    </div>
  );
};

const DateAnalysisWidget = ({ onChangeDate }: DateAnalysisWidgetProps) => {
  /**
   * Return the Date object UTC of today and yesterday
   *
   * @returns {object} - Date objects UTC of today and yesterday
   */
  const getTodayYesterday = useCallback(() => {
    var today = TDate.today();
    var yesterday = today - 1;

    return {
      today: today,
      yesterday: yesterday,
    };
  }, []);

  const formatter = useFormatter();

  const formatDate = useCallback(
    (dateMilli) => {
      const value = formatter.custom("date", {
        options: {
          format: ["Y", "M", "D"],
          isMillisecond: false,
          notAvailable: {
            input: null,
            output: null,
          },
          separator: "-",
        },
        output: "TEXT",
        value: dateMilli,
      });

      return value;
    },
    [formatter]
  );

  const maxDate = useMemo(() => {
    return formatDate(getTodayYesterday().yesterday);
  }, [formatDate, getTodayYesterday]);

  const minDate = useMemo(() => {
    const todayDate = new Date();

    const tenYearsAgo = new Date(
      todayDate.getFullYear() - 10,
      todayDate.getMonth(),
      todayDate.getDate()
    );

    const formatted = formatDate(TDate.dateToDays(tenYearsAgo));

    return formatted;
  }, [formatDate]);

  const [value, setValue] = useState(maxDate);

  const onChangeDateHandler = useCallback(
    (date) => {
      setValue(date);
      onChangeDate(new Date(date));
    },
    [onChangeDate]
  );

  return (
    <Box display={"flex"} alignItems={"center"} gap={1}>
      <DatePicker
        minDate={minDate}
        maxDate={maxDate}
        input={value}
        onChangeDate={onChangeDateHandler}
      />
    </Box>
  );
};
