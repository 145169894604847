import { useMemo } from "react";
import { ColumnDefinition } from "tabulator-tables";
import { TrendratingTableV2 } from "../../../../../../../components/table/v2/TableV2";
import { useFormatter } from "../../../../../../../hooks/useFormatter";

type PerformersTableProps = {
  data: any;
  trend: "Top" | "Bottom";
  period: "pd" | "pw" | "pm" | "pq" | "ps" | "py";
  label: string;
};

const columnFormatter = (cell, formatter, property) => {
  const object = cell.getData();

  return formatter.table(property, "table", object);
};

export function PerformersTable({
  data,
  trend,
  period,
  label,
}: PerformersTableProps) {
  const formatter = useFormatter();

  const columns: ColumnDefinition[] = useMemo(() => {
    return [
      {
        title: "Weight",
        field: "weight",
        formatter: (cell) => columnFormatter(cell, formatter, "weight"),
        sorter: "number",
      },
      {
        title: "Ticker",
        field: "ticker",
        sorter: "string",
      },
      {
        title: "Name",
        field: "name",
        sorter: "string",
      },
      {
        title: "Price",
        field: "vc",
        formatter: (cell) => columnFormatter(cell, formatter, "vc"),
        sorter: "number",
      },
      {
        title: "Rating",
        field: "rc",
        formatter: (cell) => columnFormatter(cell, formatter, "rc"),
        sorter: "number",
        hozAlign: "center",
      },
      {
        title: `${label}`,
        field: period,
        formatter: (cell) => columnFormatter(cell, formatter, period),
        sorter: "number",
      },
    ];
  }, [formatter, label, period]);

  const tableOptions = useMemo(() => {
    return {
      tableOption: {
        resizableColumns: false,
        movableColumns: false,
        cellHozAlign: "left",
        columns,
        data,
      },
    };
  }, [columns, data]);

  return (
    <div
      className="blockWrapper performance-block"
      style={{ flex: "1 0 auto" }}
    >
      <h2>{trend} 5 Performers</h2>
      {data.length ? (
        <div>
          <TrendratingTableV2 tableOptions={tableOptions} rowTooltipFormatter />
        </div>
      ) : (
        <p>No {trend} performer was found</p>
      )}
    </div>
  );
}
