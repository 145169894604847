import BarChartIcon from "@mui/icons-material/BarChart";
import GridOnIcon from "@mui/icons-material/GridOn";
import InfoIcon from "@mui/icons-material/Info";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  MenuItem,
  Select,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import Tippy from "@tippyjs/react";
import Highcharts from "highcharts";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import { ClusterAnalytics } from "../../../../../../api/compute/ClusterAnalytics";
import { Strategies } from "../../../../../../api/compute/Strategies";
import { formatTaxonPrefixingParent } from "../../../../../../api/compute/Taxon";
import { Properties } from "../../../../../../api/Properties";
import { Chart } from "../../../../../../components/Chart";
import Modal from "../../../../../../components/Modal/Modal";
import {
  TableEventsV2,
  TableV2,
} from "../../../../../../components/table/v2/TableCoreV2";
import { TrendratingTableV2 } from "../../../../../../components/table/v2/TableV2";
import { deepClone } from "../../../../../../deepClone";
import { useEnvironment } from "../../../../../../hooks/useEnvironment";
import { useFormatter } from "../../../../../../hooks/useFormatter";
import { areFloatsEqual } from "../../../../utils";
import { CSV } from "../../../../utils/SingletonCSV";
import { Transparency } from "./Transparency";
import styles from "./Transparency.module.scss";

type TabsContainerProps = {
  children: JSX.Element | JSX.Element[];
};

type TabsType = "universe" | "selection" | "ranking" | "weighting";

type TabsRowProps = {
  changeTab: (tab: TabsType) => void;
  currentTab: TabsType;
  view: "table" | "chart";
  changeView: (value: "table" | "chart") => void;
};

type TrasparencyTabProps = {
  trasparency: Transparency;
  tab: string;
};

type WeightsChartTabProps = {
  trasparency: Transparency;
};

type WeightsChartProps = {
  drilldown: string;
  data: any;
};

type FunnelProps = {
  funnelData: any;
  changeTab: (value: TabsType) => void;
};

type SummaryChartProps = {
  summary: any;
  changeTab: (value: TabsType) => void;
};

type TransparencyDialogProps = {
  show: boolean;
  onClose: () => void;
  trasparency: Transparency;
  date: string;
};

type TransparencyButtonProps = {
  exAntePortfolio: { d: number; v: any[] };
  rebalanceDate: number;
  strategy: any;
};

type LegacyRationaleProps = {
  trasparency: Transparency;
  wrapTrasparencyReply: (response) => any;
  prepareRationaleColumns: (trasparency) => any;
};

const FUNNEL_COLORS_DICT = {
  universe: "darkGray",
  investmentUniverse: "#39c",
  entitled: "#de2c3b",
  candidate: "#ffc800",
  constituents: "#2c9f42",
};

export function TransparencyButton({
  strategy,
  exAntePortfolio,
  rebalanceDate,
}: TransparencyButtonProps) {
  const [loading, setLoading] = useState(false);
  const [issues, setIssues] = useState([]);
  const [showTrasparency, setShowTransparency] = useState(false);
  const [initTrasparency, setInitTrasparency] = useState(false);
  const environment = useEnvironment();
  const setup = useMemo(() => environment.get("setup"), [environment]);
  const issuesDetected = useMemo(() => issues.length > 0, [issues.length]);
  const transparency = useMemo(() => {
    return new Transparency(strategy, exAntePortfolio, rebalanceDate, setup);
  }, [exAntePortfolio, rebalanceDate, setup, strategy]);

  const formatter = useFormatter();

  const lookForWarnings = useCallback(async () => {
    setLoading(true);

    const warnings = await transparency.get("warnings");

    setIssues(warnings);

    setLoading(false);
  }, [transparency]);

  const onMount = useCallback(() => {
    lookForWarnings();
  }, [lookForWarnings]);

  const openDeepTransparency = useCallback(async () => {
    setInitTrasparency(true);
    try {
      await transparency.initialize();
      await transparency.set();
      setInitTrasparency(false);
      setShowTransparency(true);
    } catch (error) {
      setInitTrasparency(false);
    }
  }, [transparency]);

  const closeDeepTransparency = useCallback(() => {
    setShowTransparency(false);
  }, []);

  const date = useMemo(() => {
    if (rebalanceDate) {
      let date = rebalanceDate;

      return formatter.custom("date", {
        options: {
          isMillisecond: false,
          notAvailable: {
            input: null,
            output: null,
          },
          separator: "-",
        },
        output: "TEXT",
        value: date,
      });
    }

    return "";
  }, [formatter, rebalanceDate]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  return (
    <>
      <TransparencyDialog
        show={showTrasparency}
        onClose={closeDeepTransparency}
        trasparency={transparency}
        date={date}
      />
      {issues != null && issues.length ? (
        <Tippy
          theme="security-tooltip"
          content={
            <Box p={1} className={styles.tooltip__content}>
              {issues.length ? (
                <>
                  <ul className={styles.tooltip__content__issues__list}>
                    {issues.map((issue) => (
                      <li
                        key={uuidv4()}
                        className={styles.tooltip__content__issues__list__item}
                      >
                        {issue}
                      </li>
                    ))}
                  </ul>
                  <Typography>
                    Click to get the rationale of the process
                  </Typography>
                </>
              ) : (
                <></>
              )}
            </Box>
          }
        >
          <Button
            onClick={openDeepTransparency}
            endIcon={
              issuesDetected ? (
                <WarningAmberIcon sx={{ color: "yellow" }} />
              ) : (
                <InfoIcon sx={{ color: "white" }} />
              )
            }
          >
            {loading ? (
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <CircularProgress size={"20px"} sx={{ color: "white" }} />
              </Box>
            ) : (
              <Typography>Rationale</Typography>
            )}
          </Button>
        </Tippy>
      ) : (
        <>
          <Button
            onClick={openDeepTransparency}
            endIcon={
              issuesDetected ? (
                <WarningAmberIcon sx={{ color: "yellow" }} />
              ) : (
                <InfoIcon sx={{ color: "white" }} />
              )
            }
          >
            {loading ? (
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <CircularProgress size={"20px"} sx={{ color: "white" }} />
              </Box>
            ) : (
              <Typography>Rationale</Typography>
            )}
          </Button>
        </>
      )}
      {initTrasparency && (
        <Modal closeIcon={false}>
          <Box
            display={"flex"}
            flex={1}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              gap={1}
            >
              <CircularProgress />
              <Typography>Loading...</Typography>
            </Box>
          </Box>
        </Modal>
      )}
    </>
  );
}

const TransparencyDialog = ({
  show,
  onClose,
  trasparency,
  date,
}: TransparencyDialogProps) => {
  const [tab, setTab] = useState<TabsType>("weighting");
  const [summaryData, setSummaryData] = useState(undefined);
  const [weightingView, setWeightingView] = useState<"chart" | "table">(
    "chart"
  );
  const [rationaleType, setRationaleType] = useState<"legacy" | "advanced">(
    "advanced"
  );
  const [warnings, setWarnings] = useState([]);

  const renderChart = useMemo(() => summaryData != null, [summaryData]);
  const { t } = useTranslation();
  const environment = useEnvironment();
  const formatter = useFormatter();
  const propertiesAPI = useMemo(
    () =>
      new Properties({
        properties: environment.get("setup")["properties"],
      }),
    [environment]
  );
  const strategyAPI = useMemo(() => {
    return new Strategies(environment.get("setup"));
  }, [environment]);

  const formattedDate = useMemo(() => {
    return formatter.custom("date", {
      options: {
        format: ["d", "M", "Y"],
        isMillisecond: true,
        notAvailable: {
          input: null,
          output: "",
        },
        separator: " ",
      },
      output: "HTML",
      value: date,
      valueHelper: null,
    });
  }, [date, formatter]);

  const onDialogOpen = useCallback(async () => {
    try {
      const summary = await trasparency.get("summary");
      setSummaryData(summary);
      const warnings = await trasparency.get("warnings");
      setWarnings(warnings);
    } catch (error) {
      console.log(error);
    }
  }, [trasparency]);

  const handleTabChange = useCallback((value: TabsType) => {
    setTab(value);
  }, []);

  const removeFieldPrefix = useCallback((property) => {
    if (property) {
      const regex =
        /selection(_[0-9]+)?_|hold(_[0-9]+)?_|ranking(_[0-9]+)?_|weighting(_[0-9]+)?_/;
      let response = property.replace(regex, "");
      return response;
    }
  }, []);

  const listenerExportCsvButton = useCallback(
    (columns, data) => {
      var headerColumnLabels: any[] = [];
      var headerColumns: any[] = [];
      var result: any[] = [];

      columns.forEach((item) => {
        var columnWrapper = item;
        columnWrapper.children.forEach((col) => {
          headerColumnLabels.push(col.label);
          headerColumns.push(col);
        });
      });

      result.push(headerColumnLabels);

      data.forEach((item) => {
        var row: any[] = [];
        headerColumns.forEach((column) => {
          const key = column.field;
          if (key in item) {
            const value = item[key];
            switch (removeFieldPrefix(key)) {
              case "beforeCapWeight":
              case "capWeight":
              case "exWeight":
              case "multifactor":
              case "Weight": {
                if (value === -1) {
                  row.push("");
                } else {
                  row.push(
                    formatter.custom("number", {
                      options: {
                        isPercentage: true,
                        notAvailable: {
                          input: null,
                          output: "",
                        },
                        zero: false,
                      },
                      output: "TEXT",
                      value: value,
                      valueHelper: null,
                    })
                  );
                }

                break;
              }
              case "candidate":
              case "costituent": {
                if (value === 1) {
                  row.push("true");
                } else {
                  row.push("");
                }

                break;
              }
              case "exists": {
                if (value === 1) {
                  row.push("true");
                } else {
                  row.push("");
                }

                break;
              }
              case "rank": {
                row.push(value + 1);

                break;
              }
              default:
                let columnRule = column["rule"];
                if (column["function"] != null) {
                  // overwrite (if exist) rule
                  columnRule = {
                    function: column["function"],
                    operator: column["operator"],
                  };
                }

                if (columnRule != null) {
                  switch (columnRule["function"]) {
                    case "outlier": {
                      if (value === 1) {
                        row.push("");
                      } else {
                        row.push("outlier");
                      }
                      // TODO this break was added later
                      break;
                    }
                    case "quantile": {
                      row.push("q:" + value);

                      break;
                    }
                    case "threshold": {
                      if (value === 1) {
                        row.push("over");
                      } else {
                        row.push("under");
                      }

                      break;
                    }
                    case "value": {
                      switch (columnRule["operator"]) {
                        case "bottom":
                        case "top": {
                          row.push(value);

                          break;
                        }
                        case "value":
                        default: {
                          row.push(
                            formatter.rationale(
                              // real property
                              removeFieldPrefix(key),
                              // alias property
                              key,
                              item,
                              "TEXT"
                            )
                          );
                        }
                      }

                      break;
                    }

                    // no default
                  }
                } else {
                  row.push(
                    formatter.rationale(
                      // real property
                      removeFieldPrefix(key),
                      // alias property
                      key,
                      item,
                      "TEXT"
                    )
                  );
                }
            }
          } else {
            row.push("");
          }
        });
        result.push(row);
      });

      CSV.create(result, "rationale.csv");
    },
    [formatter, removeFieldPrefix]
  );

  const formatterPercentage = useCallback(
    (cell) => {
      return formatter.custom("number", {
        options: {
          isPercentage: true,
          notAvailable: {
            input: null,
            output: "",
          },
          zero: true,
        },
        output: "HTML",
        value: cell.getValue(),
        valueHelper: null,
      });
    },
    [formatter]
  );

  const formatters = useCallback(
    (column) => {
      //this function is used to generate dynamically the table columns
      switch (removeFieldPrefix(column.field)) {
        case "beforeCapWeight":
        case "capWeight":
        case "exWeight":
        case "multifactor":
        case "Weight": {
          return {
            title: column.label,
            field: column.field,
            formatter: (cell) => {
              if (cell.getValue() !== null) {
                return formatterPercentage(cell);
              } else {
                cell.getElement().style.backgroundColor = "#eee";
                return "";
              }
            },
          };
        }
        case "candidate":
        case "holds":
        case "costituent": {
          return {
            title: column.label,
            field: column.field,
            formatter: (cell) => {
              if (cell.getValue() === 1) {
                return "<div style='text-align: center'><span style='background-color: #333;border-radius: 50%; display: inline-block; height: 10px; width: 10px'></span></div>";
              } else {
                cell.getElement().style.backgroundColor = "#eee";
                return "";
              }
            },
          };
        }
        case "exists": {
          return {
            title: column.label,
            field: column.field,
            formatter: (cell) => {
              if (cell.getValue() === 1) {
                return "true";
              } else {
                cell.getElement().style.backgroundColor = "#eee";
                return "";
              }
            },
          };
        }
        case "rank": {
          return {
            title: column.label,
            field: column.field,
            formatter: (cell) => {
              const row = cell.getRow();
              const selectionCell = row.getCell("selection_candidate");

              if (selectionCell) {
                const value = selectionCell.getValue();

                if (value !== 1) {
                  cell.getElement().style.backgroundColor = "#eee";
                  return "";
                }
                return cell.getValue() + 1;
              }

              return cell.getValue() + 1;
            },
          };
        }
        default: {
          let columnRule = column["rule"];
          if (column["function"] != null) {
            // overwrite (if exist) rule
            columnRule = {
              function: column["function"],
              operator: column["operator"],
            };
          }

          if (columnRule != null) {
            switch (columnRule["function"]) {
              case "outlier": {
                return {
                  title: column.label,
                  field: column.field,
                  formatter: (cell) => {
                    if (cell.getData()[column["field"]] === 1) {
                      return "";
                    } else {
                      return "outlier";
                    }
                  },
                };
              }
              case "quantile": {
                return {
                  title: column.label,
                  field: column.field,
                  formatter: (cell) => {
                    if (cell.getData()[column["field"]]) {
                      return "q:" + cell.getData()[column["field"]];
                    } else {
                      cell.getElement().style.backgroundColor = "#eee";
                      return "";
                    }
                  },
                };
              }
              case "threshold": {
                return {
                  title: column.label,
                  field: column.field,
                  formatter: (cell) => {
                    if (cell.getData()[column["field"]] === 1) {
                      return "over";
                    } else {
                      return "under";
                    }
                  },
                };
              }
              case "value": {
                switch (columnRule["operator"]) {
                  case "bottom":
                  case "top": {
                    return {
                      title: column.label,
                      field: column.field,
                      formatter: (cell) => {
                        if (cell.getData()[column.field]) {
                          return cell.getData()[column.field];
                        } else {
                          cell.getElement().style.backgroundColor = "#eee";
                          return "";
                        }
                      },
                    };
                  }
                  case "value":
                  default: {
                    return {
                      title: column.label,
                      field: column.field,
                      formatter: (cell) => {
                        const temp = formatter.rationale(
                          removeFieldPrefix(column["field"]),
                          column["field"],
                          cell.getData()
                        );
                        if (temp) {
                          return temp;
                        } else {
                          cell.getElement().style.backgroundColor = "#eee";
                          return "";
                        }
                      },
                    };
                  }
                }
              }
            }
          } else {
            return {
              title: column.label,
              field: column.field,
              formatter: (cell) => {
                const temp = formatter.rationale(
                  removeFieldPrefix(column["field"]),
                  column["field"],
                  cell.getData()
                );
                if (temp) {
                  return temp;
                } else {
                  cell.getElement().style.backgroundColor = "#eee";
                  return "";
                }
              },
            };
          }
        }
      }
    },
    [formatter, formatterPercentage, removeFieldPrefix]
  );

  const prepareExportColumns = useCallback(
    (data) => {
      var _data = deepClone(data);
      var _children: any = null;
      var _columns: any[] = _data["columns"];
      var _column: any = null;
      var columns: any[] = [];
      var field: any = null;
      var label = propertiesAPI;
      for (var i = 0, lengthI = _columns.length; i < lengthI; i++) {
        _children = _columns[i]["children"];
        // parents
        columns.push({
          children: [],
          label: _columns[i]["label"],
          rule: _columns[i]["rule"],
        });
        // children

        const fieldToIgnore = [
          "currency",
          "nWeight",
          "orWeight",
          "symbol",
          "type",
        ];

        const labelMap = {
          beforeCapWeight: t("c_before_capping"),
          candidate: t("c_entitled"),
          capWeight: t("c_after_capping"),
          costituent: t("c_constituent"),
          exWeight: t("c_ex_ante"),
          multifactor: t("c_smart_beta"),
          rank: t("c_rank"),
          Weight: t("c_weight"),
          hold_0_pq: t("c_holding"),
          hold_holds: t("c_holding_holds"),
        };

        for (var j = 0, lengthJ = _children.length; j < lengthJ; j++) {
          _column = _children[j];

          field = removeFieldPrefix(_column["field"]);

          const testField = field;
          if (fieldToIgnore.some((el) => el === testField)) {
            continue;
          }

          if (labelMap[field] != null) {
            _column["label"] = labelMap[field];
          } else {
            if (field === "tradedvalue") {
              _column["label"] = label.get(field, 1, "auto");
            } else if (field === "exists") {
              _column["label"] = label.get(field, 0, "auto");
            } else {
              _column["label"] = label.get(field, 0, "auto");
            }
          }

          _column["renderCell"] = formatters(_column);
          _column["renderCell"]["sorterParams"] = {
            alignEmptyValues: "bottom",
          };

          columns[i]["children"].push(_column);
        }
      }

      return (_data["columns"] = columns);
    },
    [formatters, propertiesAPI, removeFieldPrefix, t]
  );

  const wrapTrasparencyReply = useCallback(
    async (trasparencyReply) => {
      const instrumentProperties = [
        {
          date: null,
          property: "currency",
        },
        {
          date: null,
          property: "name",
        },
        {
          date: null,
          property: "ticker",
        },
        {
          date: null,
          property: "symbol",
        },
        {
          date: null,
          property: "type",
        },
      ];
      const strategy = await trasparency.get("strategy");
      const wrappedTrasparency = await strategyAPI._normalizeRationale(
        strategy.params,
        instrumentProperties,
        {
          data: { trasparency: trasparencyReply },
        }
      );

      return wrappedTrasparency;
    },
    [strategyAPI, trasparency]
  );

  const onClickExport = useCallback(async () => {
    const trasparencyReply = await trasparency.get("trasparency");
    const trasparencyData = await wrapTrasparencyReply(trasparencyReply);
    const columnsToExport = prepareExportColumns(trasparencyData);
    listenerExportCsvButton(columnsToExport, trasparencyData.data);
  }, [
    listenerExportCsvButton,
    prepareExportColumns,
    trasparency,
    wrapTrasparencyReply,
  ]);

  const switchRationaleType = useCallback(() => {
    setRationaleType((currentValue) => {
      if (currentValue === "advanced") {
        return "legacy";
      } else {
        return "advanced";
      }
    });
  }, []);

  const toggleViewBtnName = useMemo(() => {
    if (rationaleType === "legacy") {
      return "Funnel view";
    } else {
      return "Grid view";
    }
  }, [rationaleType]);

  useEffect(() => {
    if (show) {
      onDialogOpen();
    }
  }, [onDialogOpen, show]);

  return show ? (
    <Modal
      closeIcon={true}
      onClose={onClose}
      headerConfig={{ headerContent: `Allocation at ${formattedDate}` }}
      customCss={{ maxWidth: "90vw", maxHeight: "90vh", height: "100%" }}
      closeOnClickAway={false}
      buttonsEnalbed
      buttons={[
        {
          name: toggleViewBtnName,
          callback: switchRationaleType,
          align: "left",
        },
        {
          name: "Export CSV",
          callback: onClickExport,
        },
        {
          name: "Cancel",
          callback: onClose,
          variant: "cancel",
        },
      ]}
    >
      {rationaleType === "advanced" ? (
        <>
          {renderChart && (
            <>
              {warnings.length ? (
                <Box
                  className={styles.feedback__message}
                  sx={{
                    color: "#640",
                    backgroundColor: "#fcf8e3",
                    outlineColor: "#ffc001",
                    // padding: 1,
                    // marginBottom: 1,
                  }}
                >
                  <Box p={1} className={styles.tooltip__content}>
                    <ul className={styles.tooltip__content__issues__list}>
                      {warnings.map((issue) => (
                        <li
                          key={uuidv4()}
                          className={
                            styles.tooltip__content__issues__list__item
                          }
                        >
                          {issue}
                        </li>
                      ))}
                    </ul>
                  </Box>
                </Box>
              ) : (
                <></>
              )}
              <Box
                height={warnings.length ? "90%" : "100%"}
                className={styles.tabsContainer}
              >
                <SummaryChart
                  summary={summaryData}
                  changeTab={handleTabChange}
                />
                <Box className={styles.tabsContainer__rightBox}>
                  <TabsRow
                    changeTab={handleTabChange}
                    currentTab={tab}
                    changeView={setWeightingView}
                    view={weightingView}
                  />
                  {tab === "universe" && (
                    <UniverseTab tab={"universe"} trasparency={trasparency} />
                  )}
                  {tab === "selection" && (
                    <SelectionTab tab={"selection"} trasparency={trasparency} />
                  )}
                  {tab === "ranking" && (
                    <RankingTab tab={"ranking"} trasparency={trasparency} />
                  )}
                  {tab === "weighting" && (
                    <>
                      {weightingView === "table" ? (
                        <WeightingTab
                          tab={"weighting"}
                          trasparency={trasparency}
                        />
                      ) : (
                        <WeightsChartTab trasparency={trasparency} />
                      )}
                    </>
                  )}
                </Box>
              </Box>
            </>
          )}
        </>
      ) : (
        <LegacyRationale
          trasparency={trasparency}
          wrapTrasparencyReply={wrapTrasparencyReply}
          prepareRationaleColumns={prepareExportColumns}
        />
      )}
    </Modal>
  ) : (
    <></>
  );
};

const LegacyRationale = ({
  trasparency,
  wrapTrasparencyReply,
  prepareRationaleColumns,
}: LegacyRationaleProps) => {
  const [columns, setColumns] = useState<any[]>([]);
  const [updatedData, setUpdatedData] = useState([]);

  const onComponentMount = useCallback(async () => {
    const trasparencyReply = await trasparency.get("trasparency");
    const wrappedTrasparencyReply = await wrapTrasparencyReply(
      trasparencyReply
    );

    // Preprare table Data
    let tableData;
    if (wrappedTrasparencyReply) {
      tableData = wrappedTrasparencyReply.data.map((item, index) => {
        return { ...item, index: index };
      });
    }

    // Prepare table columns
    const columns = prepareRationaleColumns(wrappedTrasparencyReply);
    let clmns: any = columns.map((item) => {
      return {
        title: item.label,
        field: item.field ? item.field : "",
        columns: item.children.map((child) => child.renderCell),
      };
    });
    clmns = [
      {
        title: "",
        field: "index",
        formatter: (cell) => {
          const row = cell.getRow();
          return row.getPosition().toString();
        },
        widthGrow: 1,
      },
      ...clmns,
    ];
    setColumns(clmns);
    setUpdatedData(tableData);
  }, [prepareRationaleColumns, trasparency, wrapTrasparencyReply]);

  useEffect(() => {
    onComponentMount();
  }, [onComponentMount]);

  //#region - TrendratingTableV2 SETUP AND HANDLERS
  const tableRef = useRef<{ getInstance: () => TableV2 }>();
  const [columnsLoaded, setColumnsLoaded] = useState(false);
  const [tableReady, setTableReady] = useState(false);
  const tableOptions = useMemo(() => {
    return {
      tableOption: {
        ajaxSorting: false,
        layout: "fitColumns" as any,
      },
      tableTooltipParams: {
        enabled: true,
      },
    };
  }, []);
  useEffect(() => {
    if (tableReady && columnsLoaded) {
      const table = tableRef.current?.getInstance();
      table?.insertData(updatedData ?? []);
    }
  }, [columnsLoaded, tableReady, updatedData]);
  useEffect(() => {
    if (tableReady) {
      const table = tableRef?.current?.getInstance();
      table?.insertColumns(columns);
    }
  }, [columns, tableReady]);
  const tableEvents: TableEventsV2 = useMemo(
    () => ({
      onTableBuilt: () => {
        setTableReady(true);
      },
      onTableDestroyed: () => {
        setTableReady(false);
      },
      columnsLoaded: (columns) => {
        if (columns.length) {
          setColumnsLoaded(true);
        }
      },
    }),
    []
  );

  //#endregion

  return (
    <Box width={"100%"} height={"100%"} display={"flex"}>
      <TrendratingTableV2
        rowTooltipFormatter
        ref={tableRef}
        tableEvents={tableEvents}
        tableOptions={tableOptions}
      />
    </Box>
  );
};

const SummaryChart = ({ summary, changeTab }: SummaryChartProps) => {
  return (
    <Card className={styles.funnel__card}>
      <CardContent className={styles.funnel__card__content}>
        <Funnel changeTab={changeTab} funnelData={summary.funnel} />
      </CardContent>
    </Card>
  );
};

const WeightsChartTab = ({ trasparency }: WeightsChartTabProps) => {
  const [data, setData] = useState<any>();
  const [drilldown, setDrilldown] = useState("positions");
  const options = useMemo(
    () => [
      { label: "Positions", value: "positions" },
      { label: "Countries", value: "Country|country" },
      { label: "Sectors", value: "1 Industry|icb" },
      { label: "Industries", value: "3 Sector|icb" },
      { label: "Regions", value: "Region|country" },
      { label: "Areas", value: "Area|country" },
      { label: "Inv.Region (ETF)", value: "Country|etfgeo" },
      { label: "Asset Class (ETF)", value: "1 Industry|etfclass" },
      { label: "Specialty (ETF)", value: "3 Sector|etfclass" },
      { label: "Theme (ETF)", value: "4 Subsector|etfclass" },
    ],
    []
  );

  const changeDrilldown = useCallback((event) => {
    const selectedValue = event.target.value;

    setDrilldown(selectedValue);
  }, []);

  const setDefaultDrilldown = useCallback((data) => {
    let drilldownLevel =
      data?.limits?.cluster?.countryLevel ??
      data?.limits?.cluster?.sectorLevel ??
      "positions";

    const type = data?.limits?.cluster?.type;

    if (type === "ETF") {
      switch (drilldownLevel) {
        case "Country":
          drilldownLevel = "etfgeo";

          break;

        case "1 Industry":
          drilldownLevel = "etfclass";

          break;
        case "3 Sector":
          drilldownLevel = "specialty";

          break;

        case "4 Subsector":
          drilldownLevel = "theme";

          break;
      }
    }

    const drilldownOptionsDict = {
      Country: { label: "Countries", value: "Country|country" },
      "1 Industry": { label: "Sectors", value: "1 Industry|icb" },
      "3 Sector": { label: "Industries", value: "3 Sector|icb" },
      Region: { label: "Regions", value: "Region|country" },
      Area: { label: "Areas", value: "Area|country" },
      etfgeo: { label: "Inv.Region (ETF)", value: "Country|etfgeo" },
      etfclass: { label: "Asset Class (ETF)", value: "1 Industry|etfclass" },
      specialty: { label: "Specialty (ETF)", value: "3 Sector|etfclass" },
      theme: { label: "Theme (ETF)", value: "4 Subsector|etfclass" },
    };

    setDrilldown(drilldownOptionsDict?.[drilldownLevel]?.value ?? "positions");
  }, []);

  const getData = useCallback(async () => {
    const data = await trasparency.get("summary");

    setDefaultDrilldown(data);
    setData(data);
  }, [setDefaultDrilldown, trasparency]);

  const onMount = useCallback(async () => {
    getData();
  }, [getData]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  return (
    <TabsContainer>
      {data != null ? (
        <Box className={styles.weights__chart__tab}>
          <Box className={styles.weights__chart__tab__title}>
            <Typography>Allocation Before vs After Capping by: </Typography>
            <Select size="small" value={drilldown} onChange={changeDrilldown}>
              {options.map((item) => (
                <MenuItem key={uuidv4()} value={item.value}>
                  <Typography>{item.label}</Typography>
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Box height={"90%"}>
            {data != null && <WeightsChart drilldown={drilldown} data={data} />}
          </Box>
        </Box>
      ) : (
        <></>
      )}
    </TabsContainer>
  );
};

const Funnel = ({ funnelData, changeTab }: FunnelProps) => {
  const normalize = useCallback((series) => {
    const values = series.map((item) => item.N);
    const min = 0;

    let invUniverseSerie: any = null;

    for (const serie of series) {
      if (serie.colorTag === "investmentUniverse") {
        invUniverseSerie = serie;

        break;
      }
    }

    const max = Math.min(2 * invUniverseSerie.N, Math.max(...values));

    let value: any = null;
    let normalized: any = null;

    for (const bar of series) {
      value = bar.N;

      normalized = ((Math.min(value, max) - min) / (max - min)) * 100;

      bar.width = normalized;
    }

    return series;
  }, []);

  const configuration = useMemo(() => {
    let series = Object.values(funnelData ?? {}).sort(sortByIdx);

    series = series.filter((serie: any) => serie.idx != null);

    normalize(series);

    const tree = {
      universe: {
        label: "Investment Universe",
        children: [],
        idx: [-1, -2],
        tab: "universe",
      },
      selection: {
        label: "Selection",
        children: [],
        idx: [],
        tab: "selection",
      },
      ranking: {
        label: "Ranking",
        children: [],
        idx: [1002],
        tab: "ranking",
      },
      weighting: {
        label: "Weighting",
        children: [],
        idx: [1003],
        tab: "weighting",
      },
    };

    const serieIdxMap: any = series.reduce((prev: any, current: any) => {
      prev[current.idx] = { ...current };

      return prev;
    }, {});

    for (const section in tree) {
      if (tree[section]["idx"].length) {
        for (const idx of tree[section]["idx"]) {
          tree[section]["children"].push(serieIdxMap[idx]);
        }
      } else {
        tree[section]["children"] = series.filter(
          (item: any) => item.idx < 1000 && item.idx > -1
        );
      }

      tree[section]["children"].sort(sortByIdx);
    }

    return tree;
  }, [funnelData, normalize]);

  return (
    <Box className={styles.funnel__list}>
      {Object.values(configuration).map((group) => {
        return (
          <Box
            key={uuidv4()}
            className={styles.funnel__row}
            onClick={() => changeTab(group.tab as TabsType)}
          >
            <Box className={styles.funnel__row__gropLabel}>
              <span dangerouslySetInnerHTML={{ __html: group.label }} />
            </Box>
            <Box className={styles.funnel__row__subTable}>
              {group.children.map((serie: any) => (
                <Box key={uuidv4()} className={styles.funnel__list__bar}>
                  <Box className={styles.funnel__list__bar__legend}>
                    <span
                      dangerouslySetInnerHTML={{ __html: serie.label }}
                    ></span>
                  </Box>
                  <Box className={styles.funnel__list__bar__indicator}>
                    <Box
                      className={styles.funnel__list__bar__indicator__bar}
                      width={serie.width + "%"}
                      bgcolor={FUNNEL_COLORS_DICT[serie.colorTag]}
                      minHeight={"46px"}
                    ></Box>
                  </Box>
                  <Box className={styles.funnel__list__bar__number}>
                    <Typography>
                      {serie.N} {serie.type}
                    </Typography>
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

const WeightsChart = ({ drilldown, data }: WeightsChartProps) => {
  const [config, setConfig] = useState<any>();
  const environment = useEnvironment();
  const clusterAPI = useMemo(
    () => new ClusterAnalytics(environment.get("setup")),
    [environment]
  );
  const formatter = useFormatter();

  const runCluster = useCallback(
    async (positions, field, level) => {
      const cluster = {};

      const reply = await clusterAPI
        .createConfiguration()
        .analytics(["weight"])
        .clusters([
          {
            dimension: field,
            transform: { function: "taxonomy", params: { level: level } },
          },
        ])
        .method("INTERSECTION")
        .universeFromPositions(
          positions.map((pos) => ({ symbol: pos.S, weight: pos.A }))
        )
        .fetchAnalytics();

      let data = reply.clustersStats.stats;
      for (let id in data) {
        cluster[id] = data[id].weight;
      }

      return cluster;
    },
    [clusterAPI]
  );

  //--------------------------------------------------------------------
  const cappingLine = useCallback((limits, drilldown, peerLevel, peerField) => {
    var lines: any = [];
    var oneLine = {
      color: "#FF0000",
      width: 1,
      value: 0,
      zIndex: 5,
      label: {
        text: "",
        align: "left",
        textAlign: "right",
        rotation: -90,
        x: 10, // Adjust the horizontal position
        y: 30, // Adjust the vertical position to create a margin
        style: {
          color: "black",
          backgroudColor: "white",
          fontSize: "12px",
        },
      },
    };
    if (drilldown === "positions") {
      if (limits.positions.max !== null) {
        let line = structuredClone(oneLine);
        line.value = limits.positions.max;
        line.label.text = "Capping Max: " + formatWeight(line.value * 100);
        lines.push(line);
      }
      if (limits.positions.min) {
        let line = structuredClone(oneLine);
        line.value = limits.positions.min;
        line.label.text = "Capping Min: " + formatWeight(line.value * 100);
        lines.push(line);
      }
    } else {
      let peerCountryField =
        limits.cluster.type === "ETF" ? "etfgeo" : "country";
      let peerSectorField = limits.cluster.type === "ETF" ? "etfclass" : "icb";

      if (limits.cluster.max !== null) {
        if (
          (limits.cluster.countryLevel !== null &&
            peerField === peerCountryField &&
            limits.cluster.countryLevel === peerLevel) ||
          (limits.cluster.sectorLevel != null &&
            peerField === peerSectorField &&
            limits.cluster.sectorLevel === peerLevel)
        ) {
          let line = structuredClone(oneLine);
          line.value = limits.cluster.max;
          line.label.text = "Capping Max: " + formatWeight(line.value * 100);
          lines.push(line);
        }
      }
    }
    return lines;
  }, []);

  const formatTaxonomy = useCallback(
    (property, value, type, level) => {
      if (level === "3 Sector" || level === "4 Subsector") {
        const taxonomies = environment.get("rawTaxonomies");
        const fields = environment.get("taxonomyFields");
        const field =
          fields[type === "ETF" ? "ETF" : "security"][
            type === "ETF" ? "etfclass" : "icb"
          ];
        const taxonomy = taxonomies[field];

        const node = taxonomy[value];

        if (node) {
          const v = formatTaxonPrefixingParent(node, [taxonomy], level);

          return v;
        }

        return value;
      }

      return formatter.table(property, "table", { [property]: value, type });
    },
    [environment, formatter]
  );

  const getChartConfiguration = useCallback(async () => {
    let serieBefore: any = [];
    let serieAfter: any = [];
    let categories: any = [];

    let lines = [];
    let positions = data.positions;
    let isWeightCapped = false;

    positions.sort(function (a, b) {
      return a.after > b.after ? 1 : -1;
    });

    let d: any = null;

    if (drilldown === "positions") {
      for (let i = 0, N = positions.length; i < N; i++) {
        d = positions[i];

        if (d != null && isWeightCapped === false) {
          isWeightCapped = !areFloatsEqual(d.after, d.before);
        }

        categories.push(d.ticker + " " + d.name);
        serieBefore.push({ y: d.before });
        serieAfter.push({ y: d.after });
      }
      lines = cappingLine(data.limits, drilldown, null, null);
    } else {
      let peerLevel = drilldown.split("|")[0];
      let peerField = drilldown.split("|")[1];
      let type =
        peerField === "etfgeo" || peerField === "etfclass" ? "ETF" : "security";

      let positionsBefore: any = [];
      let positionsAfter: any = [];
      for (let i = 0, N = positions.length; i < N; i++) {
        d = positions[i];

        if (d != null && isWeightCapped === false) {
          isWeightCapped = !areFloatsEqual(d.after, d.before);
        }

        if (d.before > 0) {
          positionsBefore.push({ S: d.symbol, A: d.before });
        }
        if (d.after > 0) {
          positionsAfter.push({ S: d.symbol, A: d.after });
        }
      }
      let clusterBefore = await runCluster(
        positionsBefore,
        peerField,
        peerLevel
      );
      let clusterAfter = await runCluster(positionsAfter, peerField, peerLevel);
      var map = {};
      for (let value in clusterBefore) {
        if (map[value] === undefined) {
          map[value] = {
            category: formatTaxonomy(peerField, value, type, peerLevel),
            before: clusterBefore[value],
            after: 0,
          };
        }
      }
      for (let value in clusterAfter) {
        if (map[value] === undefined) {
          map[value] = {
            category: formatTaxonomy(peerField, value, type, peerLevel),
            before: 0,
            after: clusterAfter[value],
          };
        } else {
          map[value].after = clusterAfter[value];
        }
      }
      for (let category in map) {
        categories.push(map[category].category);
        serieBefore.push({ y: map[category].before });
        serieAfter.push({ y: map[category].after });
      }
      lines = cappingLine(data.limits, drilldown, peerLevel, peerField);
    }

    let showCategories = categories.length < 30;

    const chartConfig = {
      chart: { type: "bar" },
      title: { text: null },
      animation: false,
      credits: { enabled: false },
      accessibility: { enabled: false },
      exporting: { enabled: false },
      xAxis: [
        {
          categories: categories,
          title: { text: null },
          gridLineWidth: 0,
          lineWidth: 0,
          labels: {
            style: { fontSize: "0.9em", step: 1 },
            enabled: showCategories,
          },

          opposite: false,
        },
      ],
      yAxis: {
        visible: true,
        plotLines: lines,
        title: { text: null },
        labels: {
          formatter: function () {
            const self: any = this;
            return formatWeight(self.value * 100);
          },
        },
      },
      plotOptions: {
        bar: {
          animation: false,
          grouping: false,
          borderRadius: "6px",
        },
      },
      tooltip: {
        shared: true,
        formatter: function () {
          const self: any = this;

          const afterCappingSentence = self.points?.[1]?.y
            ? "after  capping:" + formatWeight100(self.points[1].y)
            : "";
          return (
            self.points[0].x +
            "</br>" +
            "before capping:" +
            formatWeight100(self.points[0].y) +
            "</br>" +
            afterCappingSentence
          );
        },
      },
      legend: {
        borderWidth: 1,
        backgroundColor:
          Highcharts?.defaultOptions?.legend?.backgroundColor || "#FFFFFF",
        shadow: true,
      },
      series: [
        {
          name: "Before Capping",
          data: serieBefore,
          dataLabels: {
            enabled: false,
            style: { fontSize: "1.0em" },
            align: "left",
            inside: false,
          },
          color: "rgba(0, 0, 0, 0.02)",
          borderWidth: 1,
          borderColor: "darkGray",
          visible: isWeightCapped,
        },
        {
          name: "After Capping",
          data: serieAfter,
          dataLabels: {
            enabled: false,
            style: { fontSize: "1.0em" },
            align: "left",
            inside: false,
          },
          color: "#39c",
        },
      ],
    };

    return chartConfig;
  }, [
    cappingLine,
    data.limits,
    data.positions,
    drilldown,
    formatTaxonomy,
    runCluster,
  ]);

  const onComponentMount = useCallback(async () => {
    const chartSetup = await getChartConfiguration();

    setConfig(chartSetup);
  }, [getChartConfiguration]);

  useEffect(() => {
    onComponentMount();
  }, [onComponentMount]);

  return config != null ? (
    <Chart options={config} constructorType="chart" />
  ) : (
    <></>
  );
};

const withData = (Component) => {
  return ({ trasparency, tab }: TrasparencyTabProps) => {
    const [trasparencyData, setTrasparencyData] = useState<any>();

    const getUniverseData = useCallback(
      async () => await trasparency.get(tab),
      [tab, trasparency]
    );

    const onTabMount = useCallback(async () => {
      const data = await getUniverseData();

      setTrasparencyData(data);
    }, [getUniverseData]);

    useEffect(() => {
      onTabMount();
    }, [onTabMount]);

    return (
      <Component>
        <TrasparencyTable trasparencyData={trasparencyData} />
      </Component>
    );
  };
};

const TabsContainer = ({ children }: TabsContainerProps) => {
  return (
    <Card sx={{ width: "100%", height: "90%" }}>
      <CardContent sx={{ width: "100%", height: "100%" }}>
        {children}
      </CardContent>
    </Card>
  );
};

const UniverseTab = withData(TabsContainer);
const SelectionTab = withData(TabsContainer);
const RankingTab = withData(TabsContainer);
const WeightingTab = withData(TabsContainer);

const TrasparencyTable = ({ trasparencyData }) => {
  const environment = useEnvironment();
  const formatter = useFormatter();

  const propertiesAPI = useMemo(() => {
    let properties = {};
    const propertiesDivided = environment.get("properties")?.properties;

    if (propertiesDivided) {
      properties = {
        ...propertiesDivided.etf,
        ...propertiesDivided.index,
        ...propertiesDivided.security,
        ...propertiesDivided.stock,
      };
    }

    return new Properties({ properties });
  }, [environment]);

  const highlightTitle = useCallback((cell) => {
    // const headerCellHTML = cell.getElement();
    // const cellParent = headerCellHTML.parentElement;

    // headerCellHTML.style.borderTop = "1px solid #2a7090";
    // headerCellHTML.style.borderRight = "1px solid #2a7090";
    // headerCellHTML.style.borderLeft = "1px solid #2a7090";

    return cell.getValue();
  }, []);

  const fieldsToColumns = useCallback(
    (fields, columnsOverwrites) => {
      function deepCloneWithFunctions(oldObj) {
        let newObj = {};
        for (let property in oldObj) {
          newObj[property] = oldObj[property];
        }
        return newObj;
      }
      //-------------------------------------------------------------
      const standardColumns = {
        ticker: {
          field: "ticker",
          title: "Ticker",
          falign: "right",
          formatter: (cell) => formatVal(cell.getValue()),
          sortable: true,
          sorter: function (a, b) {
            return a > b ? 1 : -1;
          },
        },
        name: {
          field: "name",
          title: "Name",
          falign: "right",
          formatter: (cell) => formatVal(cell.getValue()),
          sortable: true,
          sorter: function (a, b) {
            return a > b ? 1 : -1;
          },
        },
        symbol: { field: "symbol", hidden: true },
        candidate: {
          field: "candidate",
          title: "Entitled",
          align: "center",
          formatter: (cell) => formatCheck(cell),
          sortable: true,
          sorter: sorterNullAtBottom,
        },
        rank: {
          field: "rank",
          title: "Ranking",
          align: "center",
          formatter: (cell) => formatRanking(cell.getValue()),
          sortable: true,
          sorter: sorterNullAtBottom,
        },
        costituent: {
          field: "costituent",
          title: "Costituent",
          align: "center",
          formatter: (cell) => formatCheck(cell),
          sortable: true,
          sorter: sorterConstituentsNullAtBottom,
          titleFormatter: highlightTitle,
          cssClass: styles.headerHighlight,
        },
        rankchangedByCapping: {
          field: "rankchangedByCapping",
          title: "",
          align: "center",
          formatter: (cell) => formatSubstitution(cell.getValue()),
          sortable: true,
          sorter: sorterNullAtBottom,
        },
        holds: {
          field: "holds",
          title: "Hold",
          align: "center",
          formatter: (cell) => formatCheck(cell),
          sortable: true,
          sorter: function (a, b) {
            return a > b ? 1 : -1;
          },
        },
        exists: {
          field: "exists",
          title: "Is already an holding",
          align: "right",
          formatter: (cell) => formatExists(cell.getValue()),
          sortable: true,
          sorter: function (a, b) {
            return a > b ? 1 : -1;
          },
        },
      };

      const transparencyFields = {
        exWeight: {
          order: 1,
          field: "exWeight",
          title: "Ex-ante",
          align: "right",
          formatter: (cell) => formatWeight100(cell.getValue()),
        },
        nWeight: {
          order: 2,
          field: "nWeight",
          title: "Ex-ante",
          align: "right",
          formatter: (cell) => formatWeight100(cell.getValue()),
        },
        orWeight: {
          order: 3,
          field: "orWeight",
          title: "orWeight",
          align: "right",
          formatter: (cell) => formatWeight100(cell.getValue()),
        },
        multifactor: {
          order: 4,
          field: "multifactor",
          title: "SmartBeta",
          align: "right",
          formatter: ineritBySobtitution,
        },
        beforeCapWeight: {
          order: 5,
          field: "beforeCapWeight",
          title: "before capping",
          align: "right",
          formatter: (cell) => formatWeight100(cell.getValue()),
        },
        Weight: {
          order: 7,
          field: "Weight",
          title: "Weight",
          align: "right",
          formatter: (cell) => {
            const cellElement = cell.getElement();
            cellElement.style.borderLeft = "1px solid #2a7090";
            cellElement.style.borderRight = "1px solid #2a7090";

            return formatWeight100(cell.getValue());
          },
          titleFormatter: highlightTitle,
          cssClass: styles.headerHighlight,
        },
      };

      let columns: any = [];
      let fieldObj;
      let col;
      for (let i = 0, N = fields.length; i < N; i++) {
        fieldObj = fields[i];
        if (standardColumns[fieldObj.field] !== undefined) {
          col = deepCloneWithFunctions(standardColumns[fieldObj.field]);
        } else if (transparencyFields[fieldObj.field] !== undefined) {
          col = deepCloneWithFunctions(transparencyFields[fieldObj.field]);
        } else if (propertiesAPI.get(fieldObj.field, 0, null) !== undefined) {
          const title = propertiesAPI.get(fieldObj.field, 0, null);
          col = {
            title,
            field: fieldObj.key,
            formatter: (cell) => {
              const data = cell.getData();
              const property = fields[i].field;
              return formatter.table(property, "table", {
                ...data,
                [property]: data[fields[i].key],
              });
            },
            sortable: true,
            sorter: function (a, b) {
              return a > b ? 1 : -1;
            },
          };
        } else {
          col = {
            field: fieldObj.field,
            title: fieldObj.field,
            formatter: function (cell) {
              const value = cell.getValue();
              return value;
            },
          };
        }

        if (columnsOverwrites !== undefined) {
          if (columnsOverwrites[fieldObj.key] !== undefined) {
            let overwrite = columnsOverwrites[fieldObj.key];
            for (let property in overwrite) {
              if (
                property === "formatByColor" &&
                overwrite["quantile"] === false
              ) {
                col.deepFormatter = col["formatter"];
                const deepFormatter = col.deepFormatter;
                col.formatter = function (cell) {
                  const row = cell.getData();
                  const field = cell.getField();

                  let passed = row[field + "_passed"];
                  let color = passed ? "white" : "#eee";

                  const cellElement = cell.getElement();

                  cellElement.style.backgroundColor = color;

                  return deepFormatter(cell);
                };
              } else if (property === "formatInUniverse") {
                col.deepFormatter = col["formatter"];
                const deepFormatter = col.deepFormatter;

                col.formatter = (cell) => {
                  const data = cell.getData();

                  let color =
                    "inUniverse" in data && data.inUniverse === true
                      ? "white"
                      : "#eee";

                  const cellElement = cell.getElement();

                  cellElement.style.backgroundColor = color;

                  return deepFormatter(cell);
                };
              } else if (property === "quantile" && overwrite[property]) {
                col.title += " quantile";
                col.deepFormatter = col["formatter"];
                const deepFormatter = col.deepFormatter;

                if (fieldObj.section === "Selection") {
                  col.formatter = function (cell) {
                    const row = cell.getData();
                    const field = cell.getField();

                    let quantile = row[field + "_quantile"];
                    let passed = row[field + "_passed"];
                    let color = passed ? "white" : "#eee";

                    const cellElement = cell.getElement();

                    cellElement.style.backgroundColor = color;
                    return (
                      deepFormatter(cell) +
                      (quantile != null ? ` Q:${formatVal(quantile)}` : "")
                    );
                  };
                } else {
                  col.formatter = function (cell) {
                    const row = cell.getData();
                    const field = cell.getField();

                    let quantile = row[field + "_quantile"];

                    if (quantile) {
                      return deepFormatter(cell) + ` Q:${formatVal(quantile)}`;
                    } else {
                      return "";
                    }
                  };
                }
              } else if (property === "top" && overwrite[property]) {
                col.title += " rank";
                col.deepFormatter = col["formatter"];
                const deepFormatter = col.deepFormatter;
                col.formatter = function (cell) {
                  const row = cell.getData();
                  const field = cell.getField();

                  let topGroup = row[field + "_top"];
                  let passed = row[field + "_passed"];
                  let color = passed ? "white" : "#eee";

                  const cellElement = cell.getElement();

                  cellElement.style.backgroundColor = color;
                  return (
                    deepFormatter(cell) +
                    (topGroup != null ? `: ${formatVal(topGroup)} ` : "")
                  );
                };
              } else if (property === "sorter" && overwrite[property]) {
                var sorter = columnsOverwrites[fieldObj.key]["sorter"];
                col.sorter = sorter;
              } else {
                col[property] = columnsOverwrites[fieldObj.key][property];
              }
            }
          }
        }
        columns.push(col);
      }
      return columns;
    },
    [formatter, highlightTitle, propertiesAPI]
  );

  const tableColumns = useMemo(() => {
    let columns: any = [];
    if (trasparencyData) {
      const filterType = trasparencyData.fields.filter(
        (item) => item.field !== "type"
      );
      const syntheticColumns = fieldsToColumns(
        filterType,
        trasparencyData.overwrites
      );

      const defaultSorter = (a, b) => (a > b ? 1 : -1);

      for (const col of syntheticColumns) {
        columns.push({
          field: col.field,
          title: col.title,
          formatter: col.formatter,
          sorter: col.sorter ?? defaultSorter,
          titleFormatter: col?.titleFormatter,
          cssClass: col?.cssClass,
        });
      }
    }

    return [
      {
        title: "",
        field: "",
        formatter: "rownum",
        sortable: false,
        widthGrow: 0.1,
      },
      ...columns,
    ];
  }, [fieldsToColumns, trasparencyData]);

  const tableData = useMemo(() => {
    const data = [];

    if (trasparencyData) {
      return trasparencyData?.rows ?? [];
    }

    return data;
  }, [trasparencyData]);

  const renderTable = useMemo(() => trasparencyData != null, [trasparencyData]);

  //#region - TrendratingTableV2 SETUP AND HANDLERS
  const tableRef = useRef<{ getInstance: () => TableV2 }>();
  const [tableReady, setTableReady] = useState(false);
  useEffect(() => {
    if (tableReady) {
      const table = tableRef?.current?.getInstance();
      table?.insertData(tableData);
    }
  }, [tableData, tableReady]);
  const tableOptions = useMemo(() => {
    return {
      tableOption: {
        ajaxSorting: false,
        columns: tableColumns,
        layout: "fitDataColumns" as any,
      },
    };
  }, [tableColumns]);
  const tableEvents: TableEventsV2 = useMemo(
    () => ({
      onTableBuilt: () => {
        setTableReady(true);
      },
      onTableDestroyed: () => {
        setTableReady(false);
      },
    }),
    []
  );
  //#endregion

  return renderTable ? (
    <Box width={"100%"} height={"100%"} display={"flex"}>
      <TrendratingTableV2
        ref={tableRef}
        tableEvents={tableEvents}
        tableOptions={tableOptions}
      />
    </Box>
  ) : (
    <></>
  );
};

const TabsRow = ({ currentTab, changeTab, view, changeView }: TabsRowProps) => {
  const options = useMemo(
    () => [
      { label: "Investment Universe", value: "universe" },
      { label: "Selection Rules", value: "selection" },
      { label: "Ranking Rules", value: "ranking" },
      { label: "Weighting Rules", value: "weighting" },
    ],
    []
  );

  const wrapChangeTab = useCallback(
    (event, tab) => {
      changeTab(tab);
      changeView("table");
    },
    [changeTab, changeView]
  );

  const changeViewType = useCallback(() => {
    const value = view === "chart" ? "table" : "chart";

    changeView(value);
  }, [changeView, view]);

  return (
    <Box className={styles.tabsRow__box}>
      <Tabs value={currentTab} onChange={wrapChangeTab}>
        {options.map((tab) => {
          return <Tab key={uuidv4()} label={tab.label} value={tab.value} />;
        })}
      </Tabs>
      {currentTab === "weighting" && (
        <Button
          variant="tr_button_cancel"
          onClick={changeViewType}
          title={
            view === "chart" ? "Switch to table view" : "Switch to chart view"
          }
        >
          {view === "chart" ? (
            <GridOnIcon sx={{ color: "#2a7090" }} />
          ) : (
            <BarChartIcon sx={{ color: "#2a7090" }} />
          )}
        </Button>
      )}
    </Box>
  );
};

// ------------------- helpers -----------------------------------

const sortByIdx = (a, b) => {
  if (a.idx > b.idx) {
    return 1;
  } else if (a.idx < b.idx) {
    return -1;
  }

  return 0;
};

// ------------------- Custom Formatters ---------------------------

const formatWeight = (val) => {
  if (val == null) return "";
  let v = val.toFixed(2);
  return v + "%";
};

const formatWeight100 = (val) => {
  if (val == null) return "";
  let v = (val * 100).toFixed(2);
  return v + "%";
};

const formatVal = (value) => {
  if (value == null) return "";
  return value;
};

const formatCheck = (cell) => {
  const val = cell.getValue();
  const cellElement = cell.getElement();
  cellElement.style.borderLeft = "1px solid #2a7090";
  cellElement.style.borderRight = "1px solid #2a7090";

  if (val === null || val === 0) {
    cellElement.style.backgroundColor = "#eee";

    return "";
  }

  return '<span style="background-color: #333;border-radius: 50%; display: inline-block; height: 10px; width: 10px"></span>';
};

const formatRanking = (val) => {
  if (val == null) {
    return "";
  }

  if (val < 0) {
    return "Hold";
  }
  return val + 1;
};

const ineritBySobtitution = (cell) => {
  const val = cell.getValue();
  const row = cell.getData();
  if (val == null && row.exWeight == null) {
    return '<span style=color:gray;font-size:smaller;">inherited from the replaced</span>';
  } else {
    return format4Dec(val);
  }
};

const format4Dec = (val) => {
  if (val == null || typeof val === "string") return "";
  let v = val.toFixed(4);
  return v;
};

const formatExists = (value) => {
  return value === 1 ? "hold" : "";
};

function formatSubstitution(val) {
  if (val == null || val === 0) {
    return "";
  }
  let message = "replaced due to capping";
  let color = "red";
  if (val === 1) {
    message = "added as a replacement";
    color = "green";
  } else if (val === 2) {
    message = "addition violates capping";
    color = "gray";
  }

  return '<span style="color:' + color + '">' + message + "</span>";
}

const sorterConstituentsNullAtBottom = (
  a,
  b,
  aRow,
  bRow,
  column,
  dir,
  sorterParams
) => {
  // Tabulator does not work with this logic
  if (a === null && b !== null) return 1;
  if (b === null && a !== null) return 1;
  if (b === a) {
    return sorterNullAtBottom(aRow.getData().rank, bRow.getData().rank);
  }
  return a > b ? 1 : -1;
};

const sorterNullAtBottom = (
  a,
  b,
  aRow?,
  bRow?,
  column?,
  dir?,
  sorterParams?
) => {
  // Tabulator does not work with this logic
  if (a === null && b !== null) return 1;
  if (b === null && a !== null) return 1;
  if (b === a) {
    return 0;
  }

  return a > b ? 1 : -1;
};
