import {
  Box,
  Card,
  CardContent,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { CellComponent, ColumnDefinition } from "tabulator-tables";
import { Instruments } from "../../../../../../api/compute/Instruments";
import { Spinner } from "../../../../../../components/Spinner/Spinner";
import {
  TableEventsV2,
  TableV2,
} from "../../../../../../components/table/v2/TableCoreV2";
import { TrendratingTableV2 } from "../../../../../../components/table/v2/TableV2";
import { useEnvironment } from "../../../../../../hooks/useEnvironment";
import { useEventBus } from "../../../../../../hooks/useEventBus";
import { useFormatter } from "../../../../../../hooks/useFormatter";
import { useTaxonomyByType } from "../../../../../../hooks/useTaxonomyByType";
import { SelectorETFMarkets } from "../../ETFs/widgets/FiltersBarMarketDetail/FiltersBarMarketDetail";
import styles from "./PerformanceSinceTrendTab.module.scss";
import { useWhyDidYouUpdate } from "../../../../../../hooks/useWhyDidYouUpdate";

type PeerInfoRowProps = {
  data: HeaderData;
  handleBtnClick: (
    analytic: "upgrades_pm" | "upgrades_pq" | "downgrades_pm" | "downgrades_pq"
  ) => void;
};

type HeaderData = {
  upgrades: {
    oneMonth: number;
    threeMonths: number;
    avgPerfOneMonth: number;
    avgPerfThreeMonths: number;
  };
  downgrades: {
    oneMonth: number;
    threeMonths: number;
    avgPerfOneMonth: number;
    avgPerfThreeMonths: number;
  };
};

type PerformanceSinceTrendTabProps = {
  data?: {
    AB: {
      peerAvg: number | null;
      quartileAvg: {
        1: null | number;
        2: null | number;
        3: null | number;
        4: null | number;
      };
      securities: string[];
      quartilesSymbols: {
        [key: string]: number;
      };
    };
    CD: {
      peerAvg: null | number;
      quartileAvg: {
        1: null | number;
        2: null | number;
        3: null | number;
        4: null | number;
      };
      securities: string[];
      quartilesSymbols: {
        [key: string]: number;
      };
    };
    peerInfo: {
      type: string;
      zDimension: string;
      where: string;
      what: string;
    };
    formatterSectorBy: string | null;
  };
  peerType: "Stock" | "ETF";
  isLoading: boolean;
  getHeaderData: () => Promise<HeaderData>;
};

type PerformanceSinceTrendTabContentProps = {
  data: PerformanceSinceTrendTabProps["data"];
  peerType: PerformanceSinceTrendTabProps["peerType"];
  performanceAt: "sinceRated" | "3_months" | "6_months" | "12_months";
  setPerformanceAt?: (
    value: "sinceRated" | "3_months" | "6_months" | "12_months"
  ) => void;
  enablePerformanceSelection?: boolean;
  alerts?: "upgrades_pm" | "upgrades_pq" | "downgrades_pm" | "downgrades_pq";
  setAlerts: (value) => void;
};

type PerformanceQuartilesCardProps = {
  label?: string;
  tabSelector: "AB" | "CD";
  firstQuartile: string;
  secondQuartile: string;
  thirdQuartile: string;
  fourthQuartile: string;
  cumulativeAvg: string;
  outputInfo:
    | "no-additional-info"
    | "empty-universe"
    | "cannot-calculate-quartiles";
  hasMarginTop?: boolean;
  hasTitle?: boolean;
  isCardActive?: boolean;
  selectTab?: (tab: "AB" | "CD") => void;
  cardinality?: number | null;
  performanceAt: "sinceRated" | "3_months" | "6_months" | "12_months";
};

type PerfCardProps = {
  alertType: "upgrade" | "downgrade";
  cardinalityOneMonth: number;
  cardinalityThreeMonths: number;
  avgPerfOneMonth: number;
  avgPerfThreeMonths: number;
  lookAlerts: (analytic: "pm" | "pq") => void;
};

type PerfCardTableProps = {
  timeframe: "20_days" | "60_days";
  count: string;
  avgPerf: string;
  upDownClassName: string;
  handleTableClick: (analytic: "pm" | "pq") => void;
};

const performanceOptions = [
  { label: "Since Rated", value: "sinceRated" },
  { label: "3 Months", value: "3_months" },
  { label: "6 Months", value: "6_months" },
  { label: "12 Months", value: "12_months" },
];

const performanceDict = {
  sinceRated: "pr",
  "1_month": "pm",
  "3_months": "pq",
  "6_months": "ps",
  "12_months": "py",
};

const titleDict = {
  sinceRated: "Performance Since Rated",
  "1_month": "Performance Last Month",
  "3_months": "Performance Last 3 Months",
  "6_months": "Performance Last 6 Months",
  "12_months": "Performance Last 12 Months",
};

const quartilesColorMap = {
  1: "#8fd8fc",
  2: "#abe1fc",
  3: "#d1efff",
  4: "#e5f6ff",
};

export function PerformanceSinceTrendTab({
  data,
  peerType,
  isLoading,
  getHeaderData,
}: PerformanceSinceTrendTabProps) {
  // Place here to avoid reset of the state at render cause the content is unmounted an rebuilt on during loading
  const [performanceAt, setPerformanceAt] = useState<
    "sinceRated" | "3_months" | "6_months" | "12_months"
  >("sinceRated");
  const [headerData, setHeaderData] = useState<HeaderData>();
  const [loadingHeader, setLoadingHeader] = useState(true);
  const [alerts, setAlerts] = useState<
    "upgrades_pm" | "upgrades_pq" | "downgrades_pm" | "downgrades_pq"
  >();

  const retriveDataForHeader = useCallback(async () => {
    setLoadingHeader(true);
    try {
      const data = await getHeaderData();
      setHeaderData(data);
      setLoadingHeader(false);
    } catch (error) {
      setLoadingHeader(false);
    }
  }, [getHeaderData]);

  useEffect(() => {
    retriveDataForHeader();
  }, [retriveDataForHeader]);

  const handleBtnClick = useCallback(
    (
      analytic:
        | "upgrades_pm"
        | "upgrades_pq"
        | "downgrades_pm"
        | "downgrades_pq"
    ) => {
      setAlerts(analytic);
    },
    []
  );

  return data != null && !isLoading ? (
    <Box
      display={"flex"}
      flexDirection={"column"}
      height={"100%"}
      width={"100%"}
      gap={1}
    >
      {headerData != null && !loadingHeader && (
        <PeerInfoRow data={headerData} handleBtnClick={handleBtnClick} />
      )}
      <PerformanceSinceTrendTabContent
        performanceAt={performanceAt}
        setPerformanceAt={setPerformanceAt}
        peerType={peerType}
        data={data}
        alerts={alerts}
        setAlerts={setAlerts}
      />
    </Box>
  ) : (
    <PerformanceSinceRatedSkeleton />
  );
}

type AlertsTableProps = {
  alertAnalytic?: string;
  alertType?: string;
  type: "ETF" | "Stock";
  what?: string;
  where?: string;
  zDimension?: string;
};

const PerformanceSinceTrendTabContent = ({
  data,
  peerType,
  performanceAt,
  setPerformanceAt,
  enablePerformanceSelection = false,
  alerts,
  setAlerts,
}: PerformanceSinceTrendTabContentProps) => {
  const [tabSelector, setTabSelector] = useState<"AB" | "CD">("AB");
  const formatter = useFormatter();
  const { taxonomiesMapX, rootNodeX, rootNodeY, getTaxonomyMap } =
    useTaxonomyByType(peerType === "ETF" ? "ETF" : "stock");
  const [outputInfoAB, setOutputInfoAB] = useState<
    "no-additional-info" | "empty-universe" | "cannot-calculate-quartiles"
  >("no-additional-info");
  const [outputInfoCD, setOutputInfoCD] = useState<
    "no-additional-info" | "empty-universe" | "cannot-calculate-quartiles"
  >("no-additional-info");
  const [itemsPerPage, setItemsPerPage] = useState(100);
  const [pageNmuber, setPageNumber] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [err, setError] = useState(false);

  const initialSort = useMemo(() => {
    return {
      field: performanceDict[performanceAt],
      direction: tabSelector === "AB" ? "asc" : "desc",
    };
  }, [performanceAt, tabSelector]);
  const [tableStatus, setTableStatus] = useState({
    columns: false,
    built: false,
  });
  const [securities, setSecurities] = useState<
    {
      symbol: string;
      pr: number;
      dr: number;
      rc: number;
      name: string;
      ticker: string;
    }[]
  >([]);
  const [totalSecurities, setTotalSecurities] = useState(0);
  const environment = useEnvironment();
  const apiInstruments = useMemo(
    () => environment.get("http")["instruments"],
    [environment]
  );
  const properties = environment.get("properties");
  const tableLabels = useMemo(
    () => ({
      ticker: properties.get("ticker", 0, "auto"),
      name: properties.get("name", 0, "auto"),
      rc: properties.get("rc", 0, "auto"),
      pr: properties.get(performanceDict[performanceAt], 0, "auto"),
      dr: properties.get("dr", 0, "auto"),
      market: properties.get(
        peerType === "ETF" ? "etfgeo" : "country",
        0,
        "auto"
      ),
      sector: properties.get("industry", 0, "auto"),
      q: "Quartile",
    }),
    [peerType, performanceAt, properties]
  );

  const tableRef = useRef<{ getInstance: () => TableV2 }>();

  const { dispatch } = useEventBus();

  const getSecuritiesByCluster = useCallback(
    async (
      selector: "AB" | "CD",
      page: number,
      orderBy?: { field: string; direction: string }
    ) => {
      const peerType = data?.peerInfo?.type ?? "Stock";
      const yDim =
        data?.peerInfo?.what && data?.peerInfo?.what !== rootNodeY
          ? data?.peerInfo?.what
          : null;
      const xDim =
        data?.peerInfo?.where && data?.peerInfo?.where !== rootNodeX
          ? data?.peerInfo?.where
          : null;
      let zDimensionTaxonomies = null;
      let rootNodeZ: any = null;

      if (peerType === "ETF") {
        zDimensionTaxonomies = getTaxonomyMap("ETF", "subtype");
      } else {
        zDimensionTaxonomies =
          environment.get("setup")["taxonomies"]["SizeClassification"];
      }

      if (zDimensionTaxonomies) {
        rootNodeZ = Object.values<any>(zDimensionTaxonomies).find(
          (node) => node.parent == null
        );
      }

      const sorter = orderBy ?? {
        field: initialSort.field,
        direction: initialSort.direction,
      };

      const filterParams = {
        page: {
          page: page,
          rows: itemsPerPage,
        },
        filters: [
          {
            dimension: "type",
            segments: [peerType === "ETF" ? "ETF" : "Stock"],
          },
        ],
        ranges: [
          {
            dimension: "rc",
            segments: [
              selector === "AB" ? { max: 2, min: 1 } : { max: -1, min: -2 },
            ],
          },
        ],
        sort: [
          {
            dimension:
              sorter.field === "q"
                ? performanceDict[performanceAt]
                : sorter.field,
            rev: sorter.direction === "asc",
          },
        ],
      };

      if (peerType !== "ETF") {
        filterParams.filters.push({
          dimension: "stockclass",
          segments: ["STOCK"],
        });
      }

      if (xDim) {
        filterParams.filters.push({
          dimension: peerType === "ETF" ? "etfgeo" : "country",
          segments: [data?.peerInfo?.where ?? ""],
        });
      }

      if (yDim) {
        filterParams.filters.push({
          dimension: peerType === "ETF" ? "etfclass" : "icb",
          segments: [data?.peerInfo?.what ?? ""],
        });
      }

      if (
        data?.peerInfo.zDimension != null &&
        rootNodeZ != null &&
        data?.peerInfo.zDimension !== rootNodeZ.id
      ) {
        filterParams.filters.push({
          dimension: "sizeClassification",
          segments: [data?.peerInfo?.zDimension ?? ""],
        });
      }

      const properties = [
        { property: "symbol", date: null },
        { property: performanceDict[performanceAt], date: null },
        { property: "dr", date: null },
        { property: "rc", date: null },
        { property: "name", date: null },
        { property: "ticker", date: null },
        {
          property: peerType === "ETF" ? "etfgeo" : "country",
          date: null,
        },
        {
          property: peerType === "ETF" ? "etfclass" : "icb",
          date: null,
        },
      ];

      try {
        setIsLoading(true);
        setError(false);
        const response = await apiInstruments.newFilterAndFetch(
          filterParams,
          "security",
          properties,
          false
        );
        const total = response?.dataTotalCount ?? 0;
        setTotalSecurities(total);
        const securities = (response?.data ?? []).map((security) => ({
          ...security,
          q:
            data?.[tabSelector].quartilesSymbols?.[security?.symbol ?? ""] ??
            null,
        }));
        setSecurities(securities ?? []);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        setError(true);
      }
    },
    [
      apiInstruments,
      data,
      environment,
      getTaxonomyMap,
      initialSort.direction,
      initialSort.field,
      itemsPerPage,
      performanceAt,
      rootNodeX,
      rootNodeY,
      tabSelector,
    ]
  );

  const formatQuartiles = useCallback((cell: CellComponent) => {
    const data: any = cell.getData();
    const cellElement = cell.getElement();

    if (data?.q) {
      cellElement.style.backgroundColor = quartilesColorMap[data.q];
      return `q:${data.q}`;
    }

    cellElement.style.fontSize = "0.6vw";
    cellElement.style.color = "#333";

    return '<span title="The quartile statistic only includes ratings older than two weeks">less than 2 weeks</span>';
  }, []);

  useEffect(() => {
    if (data) {
      getSecuritiesByCluster(tabSelector, 1);
    }
  }, [data, getSecuritiesByCluster, tabSelector]);

  useEffect(() => {
    if (data) {
      const selectedCluster = data[tabSelector];

      if (selectedCluster) {
        let info:
          | "no-additional-info"
          | "empty-universe"
          | "cannot-calculate-quartiles" = "no-additional-info";

        if (selectedCluster.securities.length < 4) {
          info = "cannot-calculate-quartiles";
        }

        if (!selectedCluster.peerAvg) {
          info = "empty-universe";
          setSecurities([]);
        }

        if (tabSelector === "AB") {
          setOutputInfoAB(info);
        } else {
          setOutputInfoCD(info);
        }
      }
    }
  }, [data, tabSelector]);

  const formatNumber = useCallback(
    (number: number | null, hasPositiveSign?: boolean) => {
      const applyPositiveSign =
        hasPositiveSign !== null ? hasPositiveSign : false;
      return formatter.custom("number", {
        options: {
          hasPositiveSign: applyPositiveSign,
          isPercentage: true,
          notAvailable: {
            input: null,
            output: "",
          },
        },
        output: "TEXT",
        value: number,
        valueHelper: null,
      });
    },
    [formatter]
  );

  const handleTabSelection = useCallback(
    (value: "AB" | "CD") => {
      setAlerts(undefined);
      setPageNumber(1);
      setTabSelector(value);
      const table = tableRef.current?.getInstance();
      table?.sort(initialSort.field, initialSort.direction as any);
    },
    [initialSort.direction, initialSort.field, setAlerts]
  );

  const handleChangeItemsPerPage = useCallback((rows) => {
    setItemsPerPage(rows);
    setPageNumber(1);
  }, []);

  const sort = useCallback(
    async ({ field, direction }) => {
      if (field === "q") {
        field = performanceDict[performanceAt];
      }

      setIsLoading(true);
      await getSecuritiesByCluster(tabSelector, 1, { field, direction });

      if (pageNmuber !== 1) {
        setPageNumber(1);
      }
    },
    [getSecuritiesByCluster, pageNmuber, performanceAt, tabSelector]
  );

  const securitiesTableConfiguration: ColumnDefinition[] = useMemo(
    () => [
      {
        title: tableLabels.ticker,
        field: "ticker",
        widthGrow: 1,
      },
      {
        title: tableLabels.name,
        field: "name",
        widthGrow: 2,
      },
      {
        title: tableLabels.rc,
        field: "rc",
        formatter: (cell) => {
          const data: any = cell.getData();
          return formatter.custom("rating", {
            options: {
              notAvailable: {
                input: 0,
                output: "-",
              },
            },
            output: "HTML",
            value: data["rc"],
            valueHelper: {
              rateChange: null,
              rateDate: null,
              ratePrev: null,
            },
          });
        },
        widthGrow: 1,
      },
      {
        title: tableLabels.dr,
        field: "dr",
        formatter: (cell) => {
          const data = cell.getData();
          return formatter.custom("date", {
            options: {
              format: ["D", "M", "Y"],
              isMillisecond: false,
              notAvailable: {
                input: "",
                output: "",
              },
              separator: " ",
            },
            output: "HTML",
            value: data["dr"],
            valueHelper: null,
          });
        },
        widthGrow: 1,
      },
      {
        title: tableLabels.pr,
        field: performanceDict[performanceAt],
        formatter: (cell) => {
          const data: any = cell.getData();
          return formatNumber(data[performanceDict[performanceAt]], true);
        },
        widthGrow: 1,
      },
      {
        title: tableLabels.market,
        field: peerType === "ETF" ? "etfgeo" : "country",
        formatter: (cell) => {
          const field = cell.getField();
          const cellData: any = cell.getData();

          return taxonomiesMapX?.[cellData[field]]?.name ?? cellData[field];
        },
        widthGrow: 2,
      },
      {
        title: tableLabels.sector,
        field: peerType === "ETF" ? "etfclass" : "icb",
        widthGrow: 3,
      },
      {
        title: tableLabels.q,
        field: "q",
        formatter: (cell) => formatQuartiles(cell),
        widthGrow: 1,
      },
    ],
    [
      formatNumber,
      formatQuartiles,
      formatter,
      peerType,
      performanceAt,
      tableLabels.dr,
      tableLabels.market,
      tableLabels.name,
      tableLabels.pr,
      tableLabels.q,
      tableLabels.rc,
      tableLabels.sector,
      tableLabels.ticker,
      taxonomiesMapX,
    ]
  );

  const firstQuartileAB = useMemo(
    () => formatNumber(data?.["AB"]?.quartileAvg?.["1"] ?? null, true),
    [data, formatNumber]
  );
  const secondQuartileAB = useMemo(
    () => formatNumber(data?.["AB"]?.quartileAvg?.["2"] ?? null, true),
    [data, formatNumber]
  );
  const thirdQuartileAB = useMemo(
    () => formatNumber(data?.["AB"]?.quartileAvg?.["3"] ?? null, true),
    [data, formatNumber]
  );
  const fourthQuartileAB = useMemo(
    () => formatNumber(data?.["AB"]?.quartileAvg?.["4"] ?? null, true),
    [data, formatNumber]
  );
  const cumulativeAvgAB = useMemo(
    () => formatNumber(data?.["AB"]?.peerAvg ?? null, true),
    [data, formatNumber]
  );

  const firstQuartileCD = useMemo(
    () => formatNumber(data?.["CD"]?.quartileAvg?.["1"] ?? null, true),
    [data, formatNumber]
  );
  const secondQuartileCD = useMemo(
    () => formatNumber(data?.["CD"]?.quartileAvg?.["2"] ?? null, true),
    [data, formatNumber]
  );
  const thirdQuartileCD = useMemo(
    () => formatNumber(data?.["CD"]?.quartileAvg?.["3"] ?? null, true),
    [data, formatNumber]
  );
  const fourthQuartileCD = useMemo(
    () => formatNumber(data?.["CD"]?.quartileAvg?.["4"] ?? null, true),
    [data, formatNumber]
  );
  const cumulativeAvgCD = useMemo(
    () => formatNumber(data?.["CD"]?.peerAvg ?? null, true),
    [data, formatNumber]
  );

  const onChangePerformanceAt = useCallback(
    (value) => {
      if (enablePerformanceSelection === true && setPerformanceAt) {
        setPerformanceAt(value);
      }
      dispatch("update-performance-since-trend-at", { performanceAt: value });
    },
    [dispatch, enablePerformanceSelection, setPerformanceAt]
  );

  const tableEvents: TableEventsV2 = useMemo(() => {
    return {
      onTableBuilt: () =>
        setTableStatus((current) => ({ ...current, built: true })),
      onTableDestroyed: () =>
        setTableStatus((current) => ({ ...current, built: false })),
      columnsLoaded: (clmns) => {
        if (clmns.length) {
          setTableStatus((current) => ({ ...current, columns: true }));
        }
      },
      headerSort: sort,
    };
  }, [sort]);

  const columnsLoaded = useMemo(
    () => tableStatus.columns,
    [tableStatus.columns]
  );
  const tableBuilt = useMemo(() => tableStatus.built, [tableStatus.built]);

  useEffect(() => {
    // Handle table columns insert
    if (tableBuilt) {
      const table = tableRef.current?.getInstance();
      table?.insertColumns(securitiesTableConfiguration);
    }
  }, [securitiesTableConfiguration, tableBuilt]);

  useEffect(() => {
    // Handle table data insert
    if (tableBuilt && columnsLoaded) {
      const table = tableRef.current?.getInstance();
      table?.insertData(securities);

      const sorters = table?.getSorter();

      if (sorters && sorters?.length > 0) {
        table?.sort(sorters[0].field, sorters[0].dir);
      } else {
        table?.sort(initialSort.field, initialSort.direction as any);
      }
    }
  }, [
    columnsLoaded,
    initialSort.direction,
    initialSort.field,
    securities,
    tableBuilt,
  ]);

  const toolsConfiguration = useMemo(() => {
    return {
      pagination: { dataTotalCount: totalSecurities },
      rowsNumberSelect: { enabled: true, initialValue: 100 },
    };
  }, [totalSecurities]);

  const toolsEvents = useMemo(() => {
    return {
      onChangePage: (pg) => getSecuritiesByCluster(tabSelector, pg),
      onChangeRowNumber: handleChangeItemsPerPage,
    };
  }, [getSecuritiesByCluster, handleChangeItemsPerPage, tabSelector]);

  const alertInfo = useMemo(() => {
    if (alerts) {
      const [type, analytic] = alerts.split("_");

      return { type, analytic };
    }

    return { type: undefined, analytic: undefined };
  }, [alerts]);

  const alertsLabel = useMemo(() => {
    if (alerts) {
      const [type, timeframe] = alerts.split("_");

      if (type === "upgrades") {
        if (timeframe === "pm") {
          return "Upgrades last 20 days";
        } else {
          return "Upgrades last 60 days";
        }
      } else {
        if (timeframe === "pm") {
          return "Downgrades last 20 days";
        } else {
          return "Downgrades last 60 days";
        }
      }
    }

    return "";
  }, [alerts]);

  return (
    <Box
      height={"100%"}
      width={"100%"}
      display={"flex"}
      minHeight={0}
      flexDirection={"column"}
      minWidth={0}
    >
      <Box p={1} minHeight={0} display={"flex"} height={"100%"} minWidth={0}>
        <Box
          mr={1}
          p={1}
          borderRadius={"4px"}
          boxShadow={3}
          minWidth={0}
          overflow={"auto"}
          minHeight={0}
          bgcolor={"white"}
        >
          {enablePerformanceSelection && (
            <Box display={"flex"} gap={1} alignItems={"center"} mb={1}>
              <Typography>Performance</Typography>
              <SelectorETFMarkets
                options={performanceOptions}
                selectedOption={performanceAt}
                selectOption={onChangePerformanceAt}
              />
            </Box>
          )}
          <PerformanceQuartilesTable
            tabSelector={"AB"}
            performanceAt={performanceAt}
            firstQuartile={firstQuartileAB}
            secondQuartile={secondQuartileAB}
            thirdQuartile={thirdQuartileAB}
            fourthQuartile={fourthQuartileAB}
            cumulativeAvg={cumulativeAvgAB}
            outputInfo={outputInfoAB}
            isCardActive={tabSelector === "AB" && !alerts}
            selectTab={handleTabSelection}
            // cardinality={data?.["AB"]?.securities?.length ?? null}
          />

          <PerformanceQuartilesTable
            performanceAt={performanceAt}
            tabSelector={"CD"}
            hasMarginTop={true}
            firstQuartile={firstQuartileCD}
            secondQuartile={secondQuartileCD}
            thirdQuartile={thirdQuartileCD}
            fourthQuartile={fourthQuartileCD}
            cumulativeAvg={cumulativeAvgCD}
            outputInfo={outputInfoCD}
            isCardActive={tabSelector === "CD" && !alerts}
            selectTab={handleTabSelection}
            // cardinality={data?.["CD"]?.securities?.length ?? null}
          />
        </Box>
        <Box
          display={"flex"}
          p={1}
          flex={3}
          height={"100%"}
          borderRadius={"4px"}
          boxShadow={3}
          minHeight={0}
          minWidth={0}
          bgcolor={"white"}
          flexDirection={"column"}
        >
          {!err ? (
            <>
              <Box display={isLoading ? "flex" : "none"} flex={1}>
                <Loader />
              </Box>
              <Box display={isLoading ? "none" : "flex"}>
                <Box
                  flex={1}
                  fontSize={"1.4vw"}
                  display={"flex"}
                  justifyContent={"space-between"}
                  mb={2}
                >
                  {alerts != null ? (
                    <Typography
                      sx={{
                        textTransform: "uppercase",
                        color: "#2a7090",
                        fontSize: "1vw",
                      }}
                    >
                      {alertsLabel}
                    </Typography>
                  ) : (
                    <>
                      {tabSelector === "AB" ? (
                        <span>
                          <strong className="rate rate--A">A</strong>
                          <strong className="rate rate--B">B</strong>
                        </span>
                      ) : (
                        <span onClick={() => handleTabSelection("CD")}>
                          <strong className="rate rate--C">C</strong>
                          <strong className="rate rate--D">D</strong>
                        </span>
                      )}
                    </>
                  )}
                </Box>
              </Box>

              <Box
                display={!isLoading ? "flex" : "none"}
                minHeight={0}
                minWidth={0}
                height={"100%"}
              >
                {/* <Box flex={1} minHeight={0} minWidth={0} display={"flex"}> */}
                {alerts != null ? (
                  <AlertsTable
                    alertAnalytic={alertInfo.analytic}
                    alertType={alertInfo.type}
                    zDimension={data?.peerInfo.zDimension}
                    type={data?.peerInfo.type === "ETF" ? "ETF" : "Stock"}
                    what={data?.peerInfo.what}
                    where={data?.peerInfo.where}
                  />
                ) : (
                  <TrendratingTableV2
                    ref={tableRef}
                    tableEvents={tableEvents}
                    tools={toolsConfiguration}
                    toolsEvents={toolsEvents}
                    rowTooltipFormatter
                  />
                )}
                {/* </Box> */}
              </Box>
            </>
          ) : (
            <Card sx={{ marginTop: 1 }}>
              <CardContent>
                <span>
                  <strong style={{ color: "red" }}>
                    Sorry somenthing goes wrong while loading securities, if the
                    problem persists contact our customer support
                  </strong>
                </span>
              </CardContent>
            </Card>
          )}
        </Box>
      </Box>
    </Box>
  );
};

const AlertsTable = memo(
  ({
    alertAnalytic,
    zDimension,
    alertType,
    what,
    where,
    type,
  }: AlertsTableProps) => {
    const [currentRows, setCurrentRows] = useState(100);
    const [sorter, setSorter] = useState({
      field: alertAnalytic,
      direction: "desc",
    });
    const [totalSecurities, setTotlaSecurities] = useState(0);
    const [tableStatus, setTableStatus] = useState({
      built: false,
      columns: false,
    });

    const tableRef = useRef<{ getInstance: () => TableV2 }>();
    const environment = useEnvironment();

    const taxonomies = useMemo(
      () => environment.get("rawTaxonomies"),
      [environment]
    );

    const taxonomyFields = useMemo(
      () => environment.get("taxonomyFields"),
      [environment]
    );

    const instrumentsAPI = useMemo(
      () => new Instruments(environment.get("setup")),
      [environment]
    );

    const toolsConfiguration = useMemo(() => {
      return {
        pagination: { dataTotalCount: totalSecurities },
        rowsNumberSelect: { enabled: true, initialValue: 100 },
      };
    }, [totalSecurities]);

    const screenAndFetch = useCallback(
      async (page, rows, sortField, sortDir) => {
        const filters: any = [];
        filters.push({
          dimension: "type",
          operator: "equals",
          segments: [type],
        });

        const peerType = type === "ETF" ? "ETF" : "security";
        const whereField = type === "ETF" ? "etfgeo" : "country";
        const whatField = type === "ETF" ? "etfclass" : "icb";

        const whereTaxonomy = taxonomies[taxonomyFields[peerType][whereField]];
        const whatTaxonomy = taxonomies[taxonomyFields[peerType][whatField]];

        if (type !== "ETF") {
          filters.push(
            {
              dimension: "sizeClassification",
              operator: "equals",
              segments: [zDimension],
            },
            { dimension: "stockclass", operator: "equals", segments: ["STOCK"] }
          );
        }

        const isRootNode = (taxon, node) => {
          let isRoot = false;

          if (taxon?.[node]?.parent != null) {
            isRoot = false;
          } else {
            isRoot = true;
          }

          return isRoot;
        };

        if (!isRootNode(whereTaxonomy, where)) {
          filters.push({
            dimension: whereField,
            operator: "equals",
            segments: [where],
          });
        }

        if (!isRootNode(whatTaxonomy, what)) {
          filters.push({
            dimension: whatField,
            operator: "equals",
            segments: [what],
          });
        }

        if (alertType === "upgrades") {
          if (alertAnalytic === "pm") {
            filters.push(
              {
                dimension: "lr",
                operator: "range",
                segments: [{ ">=": 0, "<=": 19 }],
              },
              {
                dimension: "direction",
                operator: "range",
                segments: [{ ">=": 0, "<=": 4 }],
              }
            );
          } else if (alertAnalytic === "pq") {
            filters.push(
              {
                dimension: "lr",
                operator: "range",
                segments: [{ ">=": 0, "<=": 59 }],
              },
              {
                dimension: "direction",
                operator: "range",
                segments: [{ ">=": 0, "<=": 4 }],
              }
            );
          }
        } else if (alertType === "downgrades") {
          if (alertAnalytic === "pm") {
            filters.push(
              {
                dimension: "lr",
                operator: "range",
                segments: [{ ">=": 0, "<=": 19 }],
              },
              {
                dimension: "direction",
                operator: "range",
                segments: [{ ">=": -4, "<=": 0 }],
              }
            );
          } else if (alertAnalytic === "pq") {
            filters.push(
              {
                dimension: "lr",
                operator: "range",
                segments: [{ ">=": 0, "<=": 59 }],
              },
              {
                dimension: "direction",
                operator: "range",
                segments: [{ ">=": -4, "<=": 0 }],
              }
            );
          }
        }

        const payload = {
          constraints: [filters],
          sort: [{ dimension: sortField, rev: sortDir === "desc" }],
          page: { page, rows },
        };

        if (sortField !== "marketcap") {
          payload.sort.push({ dimension: "marketcap", rev: false });
        }

        try {
          const response = await instrumentsAPI.screening(payload, true);

          if (response?.data) {
            const properties = [
              { date: null, property: "ticker" },
              { date: null, property: "name" },
              { date: null, property: "rc" },
              { date: null, property: "dr" },
              { date: null, property: "country" },
              { date: null, property: "icb" },
              { date: null, property: alertAnalytic! },
            ];

            setTotlaSecurities(response.dataTotalCount);
            const fetchResponse = await instrumentsAPI.fetch({
              properties,
              symbols: response.data,
              type: "security",
            });

            return fetchResponse?.data;
          }
        } catch (error) {
          console.log(error);
          return;
        }
      },
      [
        alertAnalytic,
        alertType,
        instrumentsAPI,
        taxonomies,
        taxonomyFields,
        type,
        what,
        where,
        zDimension,
      ]
    );

    const setData = useCallback((data) => {
      if (data) {
        const table = tableRef.current?.getInstance();

        table?.insertData(data);
      }
    }, []);

    const sort = useCallback(
      async ({ field, direction }) => {
        setSorter({ field, direction });
        const data = await screenAndFetch(1, currentRows, field, direction);

        setData(data);
      },
      [currentRows, screenAndFetch, setData]
    );

    const changePage = useCallback(
      async (page) => {
        const data = await screenAndFetch(
          page,
          currentRows,
          sorter.field,
          sorter.direction
        );

        setData(data);
      },
      [currentRows, screenAndFetch, setData, sorter.direction, sorter.field]
    );

    const onChangeRowNumber = useCallback(
      async (rows) => {
        setCurrentRows(rows);
        const data = await screenAndFetch(
          1,
          rows,
          sorter.field,
          sorter.direction
        );

        setData(data);
      },
      [screenAndFetch, setData, sorter.direction, sorter.field]
    );

    const toolsEvents = useMemo(() => {
      return {
        onChangePage: changePage,
        onChangeRowNumber: onChangeRowNumber,
      };
    }, [changePage, onChangeRowNumber]);

    const tableEvents: TableEventsV2 = useMemo(() => {
      return {
        onTableBuilt: () =>
          setTableStatus((current) => ({ ...current, built: true })),
        onTableDestroyed: () =>
          setTableStatus((current) => ({ ...current, built: false })),
        columnsLoaded: (clmns) => {
          if (clmns.length) {
            setTableStatus((current) => ({ ...current, columns: true }));
          }
        },
        headerSort: sort,
      };
    }, [sort]);

    const onComponentMount = useCallback(async () => {
      const data = await screenAndFetch(
        1,
        100,
        alertAnalytic,
        alertType === "upgrades" ? "desc" : "asc"
      );

      setData(data);
    }, [alertAnalytic, alertType, screenAndFetch, setData]);

    useWhyDidYouUpdate("", { alertAnalytic, screenAndFetch, setData });

    useEffect(() => {
      if (alertAnalytic != null) {
        if (tableStatus.built) {
          const table = tableRef.current?.getInstance();

          table?.insertColumns([
            { field: "ticker" },
            { field: "name" },
            { field: "rc" },
            { field: "dr" },
            { field: "country" },
            { field: "icb", title: "Industry" },
            { field: alertAnalytic },
          ]);

          table?.sort(alertAnalytic, alertType === "upgrades" ? "desc" : "asc");
        }
      }
    }, [alertAnalytic, alertType, tableStatus.built]);

    useEffect(() => {
      onComponentMount();
    }, [onComponentMount]);

    return (
      <TrendratingTableV2
        ref={tableRef}
        tableEvents={tableEvents}
        tools={toolsConfiguration}
        toolsEvents={toolsEvents}
        rowTooltipFormatter
      />
    );
  }
);

const Loader = () => {
  return (
    <Box
      display={"flex"}
      flex={1}
      alignItems={"center"}
      justifyContent={"center"}
    >
      <Spinner />
    </Box>
  );
};

export const PerformanceQuartilesTable = ({
  outputInfo,
  tabSelector,
  firstQuartile,
  secondQuartile,
  thirdQuartile,
  fourthQuartile,
  cumulativeAvg,
  hasMarginTop = false,
  hasTitle = true,
  isCardActive,
  selectTab,
  cardinality,
  performanceAt,
  label,
}: PerformanceQuartilesCardProps) => {
  return (
    <table
      onClick={() => (selectTab ? selectTab(tabSelector) : undefined)}
      className={`${styles.quartiles__table} ${
        !selectTab ? styles.unhoverable : ""
      }  ${isCardActive ? styles.quartiles__table__active : ""}`}
    >
      <thead>
        <tr>
          <th>
            {tabSelector === "AB" ? (
              <span
                style={{
                  textAlign: "left",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <strong className="rate rate--A">A</strong>
                <strong className="rate rate--B">B</strong>

                <p
                  style={{
                    marginLeft: "10px",
                  }}
                >
                  {cardinality ?? ""}
                </p>
              </span>
            ) : (
              <span
                style={{
                  textAlign: "left",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <strong className="rate rate--C">C</strong>
                <strong className="rate rate--D">D</strong>

                <p
                  style={{
                    marginLeft: "10px",
                  }}
                >
                  {cardinality ?? ""}
                </p>
              </span>
            )}
          </th>
          <th>
            {hasTitle && (
              <span>
                <span>{label ?? titleDict[performanceAt]}</span>
              </span>
            )}
          </th>
        </tr>
      </thead>

      <tbody>
        <tr>
          <td>
            <span
              style={{
                flex: 1,
              }}
            >
              {tabSelector === "AB" ? "1st" : "4th"}
            </span>
            <span style={{ flex: 6, marginLeft: "5px" }}>Quartile</span>
          </td>
          <td>
            <span style={{ flex: 4 }}>
              {tabSelector === "AB" ? firstQuartile : fourthQuartile}
            </span>
          </td>
        </tr>
        <tr>
          <td>
            <span
              style={{
                flex: 1,
              }}
            >
              {tabSelector === "AB" ? "2nd" : "3rd"}
            </span>
            <span style={{ flex: 6, marginLeft: "5px" }}>Quartile</span>
          </td>
          <td>
            <span style={{ flex: 4 }}>
              {tabSelector === "AB" ? secondQuartile : thirdQuartile}
            </span>
          </td>
        </tr>
        <tr>
          <td>
            <span
              style={{
                flex: 1,
              }}
            >
              {tabSelector === "AB" ? "3rd" : "2nd"}
            </span>
            <span style={{ flex: 6, marginLeft: "5px" }}>Quartile</span>
          </td>
          <td>
            <span style={{ flex: 4 }}>
              {tabSelector === "AB" ? thirdQuartile : secondQuartile}
            </span>
          </td>
        </tr>
        <tr>
          <td>
            <span
              style={{
                flex: 1,
              }}
            >
              {tabSelector === "AB" ? "4th" : "1st"}
            </span>
            <span style={{ flex: 6, marginLeft: "5px" }}>Quartile</span>
          </td>
          <td>
            <span style={{ flex: 4 }}>
              {tabSelector === "AB" ? fourthQuartile : firstQuartile}
            </span>
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td>
            <span style={{ flex: 1 }}>Average:</span>
          </td>
          <td>
            <span style={{ flex: 1, textAlign: "right" }}>
              <strong>{cumulativeAvg}</strong>
            </span>
          </td>
        </tr>
      </tfoot>
    </table>
  );
};

const PerformanceSinceRatedSkeleton = () => {
  return (
    <Box
      p={1}
      mt={1}
      borderRadius={"4px"}
      boxShadow={3}
      display={"flex"}
      flex={1}
    >
      <Box display={"flex"} flex={3} mr={1}>
        <Card sx={{ flex: 1 }}>
          <CardContent>
            <Stack spacing={1}>
              <Skeleton
                variant="text"
                sx={{
                  fontSize: "20px",
                  marginBottom: "20px",
                }}
              />

              {/* For other variants, adjust the size with `width` and `height` */}
              <Skeleton variant="rectangular" width={"100%"} height={30} />
              <Skeleton variant="rectangular" width={"100%"} height={30} />
              <Skeleton variant="rectangular" width={"100%"} height={30} />
              <Skeleton variant="rectangular" width={"100%"} height={30} />
            </Stack>
          </CardContent>
        </Card>
      </Box>
      <Box display={"flex"} flex={9}>
        <Card sx={{ flex: 1 }}>
          <CardContent>
            <Stack spacing={1}>
              <Skeleton
                variant="text"
                sx={{
                  fontSize: "20px",
                  marginBottom: "20px",
                }}
              />

              {/* For other variants, adjust the size with `width` and `height` */}
              <Skeleton
                variant="text"
                sx={{
                  fontSize: "20px",
                  marginBottom: "20px",
                }}
              />
              <Skeleton variant="rectangular" width={"100%"} height={300} />
              <Skeleton variant="rectangular" width={"100%"} height={30} />
            </Stack>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

const PerfCardTable = ({
  timeframe,
  count,
  avgPerf,
  upDownClassName,
  handleTableClick,
}: PerfCardTableProps) => {
  const labelConut = useMemo(
    () => (timeframe === "20_days" ? "Last 20 days" : "Last 60 days"),
    [timeframe]
  );

  const avgLabel = useMemo(
    () =>
      timeframe === "20_days"
        ? "Average perf. 1 month"
        : "Average perf. 3 months",
    [timeframe]
  );

  const onClickTable = useCallback(() => {
    const analytic = timeframe === "20_days" ? "pm" : "pq";
    handleTableClick(analytic);
  }, [handleTableClick, timeframe]);

  return (
    <table className={styles.clickableTable} onClick={onClickTable}>
      <tbody>
        <tr>
          <td style={{ verticalAlign: "middle" }}>
            <span style={{ fontSize: "110%" }}>{labelConut}</span>
          </td>
          <td style={{ textAlign: "left" }}>
            <span
              style={{ fontSize: "250%", fontWeight: "bold" }}
              className={upDownClassName}
              dangerouslySetInnerHTML={{
                __html: count,
              }}
            />
          </td>
          <td></td>
        </tr>
        <tr>
          <td style={{ verticalAlign: "middle" }}>
            <span style={{ fontSize: "110%" }}>{avgLabel}</span>
          </td>
          <td style={{ textAlign: "left" }}>
            <span
              style={{ fontSize: "250%", fontWeight: "bold" }}
              className="tPageAnalysisMarketWhereFacts-factValueHelper"
              dangerouslySetInnerHTML={{
                __html: avgPerf,
              }}
            />
          </td>
        </tr>
      </tbody>
    </table>
  );
};

const PerfCard = ({
  alertType,
  cardinalityOneMonth,
  cardinalityThreeMonths,
  avgPerfOneMonth,
  avgPerfThreeMonths,
  lookAlerts,
}: PerfCardProps) => {
  const title = useMemo(() => {
    return alertType === "upgrade" ? "UPGRADES" : "DOWNGRADES";
  }, [alertType]);

  const formatOptions = useMemo(
    () => ({
      number: {
        decimals: 0,
        notAvailable: {
          input: 0,
          output: "-",
        },
      },
      percentage: {
        isPercentage: true,
        notAvailable: {
          input: 0,
          output: "-",
        },
      },
    }),
    []
  );

  const upDownClassName = useMemo(
    () =>
      alertType === "upgrade"
        ? "tPageAnalysisMarketWhereFacts-factValue--positive"
        : "tPageAnalysisMarketWhereFacts-factValue--negative",
    [alertType]
  );

  const formatter = useFormatter();

  const oneMonthCardinality = useMemo(() => {
    return formatter.custom("number", {
      options: formatOptions["number"],
      output: "HTML",
      value: cardinalityOneMonth,
      valueHelper: null,
    });
  }, [cardinalityOneMonth, formatOptions, formatter]);
  const threeMonthsCardinality = useMemo(() => {
    return formatter.custom("number", {
      options: formatOptions["number"],
      output: "HTML",
      value: cardinalityThreeMonths,
      valueHelper: null,
    });
  }, [cardinalityThreeMonths, formatOptions, formatter]);

  const oneMonthAvgPerf = useMemo(() => {
    return formatter.custom("number", {
      options: formatOptions["percentage"],
      output: "HTML",
      value: avgPerfOneMonth,
      valueHelper: null,
    });
  }, [avgPerfOneMonth, formatOptions, formatter]);
  const threeMonthsAvgPerf = useMemo(() => {
    return formatter.custom("number", {
      options: formatOptions["percentage"],
      output: "HTML",
      value: avgPerfThreeMonths,
      valueHelper: null,
    });
  }, [avgPerfThreeMonths, formatOptions, formatter]);

  return (
    <Card
      sx={{ display: "flex", flex: 1 }}
      // onClick={() => handleBtnClick("3_months")}
    >
      <CardContent
        sx={{
          display: "flex",
          flex: 1,
          paddingTop: "5px!important",
          paddingBottom: "5px!important",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flex: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
            }}
          >
            <h2
              className="tPageAnalysisMarketWhereFacts-factLabel"
              style={{ fontSize: "180%" }}
            >
              {title}
            </h2>
            <Box display={"flex"} gap={1}>
              <Box borderRight={"1px solid #ddd"} flex={1}>
                <PerfCardTable
                  handleTableClick={lookAlerts}
                  timeframe={"20_days"}
                  count={oneMonthCardinality}
                  avgPerf={oneMonthAvgPerf}
                  upDownClassName={upDownClassName}
                />
              </Box>
              <Box flex={1}>
                <PerfCardTable
                  handleTableClick={lookAlerts}
                  timeframe={"60_days"}
                  count={threeMonthsCardinality}
                  avgPerf={threeMonthsAvgPerf}
                  upDownClassName={upDownClassName}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

const PeerInfoRow = ({ data, handleBtnClick }: PeerInfoRowProps) => {
  const lookUpgrades = useCallback(
    (analytic: "pm" | "pq") => {
      const alert = "upgrades_" + analytic;
      handleBtnClick(alert as any);
    },
    [handleBtnClick]
  );

  const lookDowngrades = useCallback(
    (analytic: "pm" | "pq") => {
      const alert = "downgrades_" + analytic;
      handleBtnClick(alert as any);
    },
    [handleBtnClick]
  );

  return (
    <Box
      width={"100%"}
      display={"flex"}
      boxShadow={3}
      p={1}
      borderRadius={2}
      gap={1}
    >
      {/* Upgrades */}
      <PerfCard
        lookAlerts={lookUpgrades}
        alertType={"upgrade"}
        cardinalityOneMonth={data.upgrades.oneMonth}
        cardinalityThreeMonths={data.upgrades.threeMonths}
        avgPerfOneMonth={data.upgrades.avgPerfOneMonth}
        avgPerfThreeMonths={data.upgrades.avgPerfThreeMonths}
      />

      {/* Downgrades */}
      <PerfCard
        lookAlerts={lookDowngrades}
        alertType={"downgrade"}
        cardinalityOneMonth={data.downgrades.oneMonth}
        cardinalityThreeMonths={data.downgrades.threeMonths}
        avgPerfOneMonth={data.downgrades.avgPerfOneMonth}
        avgPerfThreeMonths={data.downgrades.avgPerfThreeMonths}
      />
    </Box>
  );
};
