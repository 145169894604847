import { Tooltip } from "../../../../components/Tooltip/Tooltip";
import ideaLamp from "../../../../styles/css_new/img/lamp-idea.svg";
type InfoTooltipProps = {
  text: string;
  title: string;
  callToAction?: string;
  actionInfo?: string;
  style?: { fontSize?: number; top?: number; left?: number };
  child?: JSX.Element;
};

export function InfoTooltip({
  text,
  title,
  callToAction,
  actionInfo,
  style,
  child,
}: InfoTooltipProps) {
  return (
    <Tooltip
      styles={style}
      tooltipIcon={child == null}
      content={
        <div className="tooltip-content-wrapper">
          <div className="tooltip-leftPanel">
            <img
              src={ideaLamp}
              alt="lamp-icon"
              className="alerts-tooltip-icon"
            />
          </div>
          <div className="tooltip-rightPanel">
            {callToAction && <h2 className="call-to-action">{callToAction}</h2>}
            <h3 className="title">{title}</h3>
            <p className="paragraph">{text}</p>
            {actionInfo && <p className="subtext">{actionInfo}</p>}
          </div>
        </div>
      }
    >
      {child ?? <span className="alerts-info-tooltip-icon i-help"></span>}
    </Tooltip>
  );
}
