import {
  ReleaseNote,
  ReleaseNoteItem,
  ReleaseNoteSection,
} from "./HelpContentReleaseNotes";

type HelpContentReleaseNotesPremiumProps = {
  assetsUrl: string;
};

export const HelpContentReleaseNotesPremium = ({
  assetsUrl,
}: HelpContentReleaseNotesPremiumProps) => {
  return (
    <div className="releaseNotes no-product-systematicEngine no-product-standard">
      <h1 className="releaseNotes-title">Release notes</h1>
      <ReleaseNote
        title="14 January 2025 Version 2.52 - What's new"
        filename={`${assetsUrl}/Trendrating_premium--RELEASE_NOTES--v2.52.pdf`}
      >
        <ReleaseNoteSection type="New" title="Search Bar">
          <ReleaseNoteItem>
            Easily search for specific stocks within the Screening and Portfolio
            Analysis sections using our new search bar. Quickly locate
            securities by Ticker, Name or ISIN to streamline your analysis.
          </ReleaseNoteItem>
        </ReleaseNoteSection>

        <ReleaseNoteSection
          type="New"
          title="Size tab in the Allocation within the Strategy Builder"
        >
          <ReleaseNoteItem>
            Analyze historical allocation with the new Size Tab in Strategy
            Builder, offering deeper insights into size distribution for better
            portfolio construction.
          </ReleaseNoteItem>
        </ReleaseNoteSection>
      </ReleaseNote>

      <ReleaseNote
        title="13 February 2023 Version 2.45 - What's new"
        filename={`${assetsUrl}/Trendrating_premium--RELEASE_NOTES--v2.45.pdf`}
      >
        <ReleaseNoteSection type="New" title="Addition of Screening Rules ">
          <ReleaseNoteItem>
            The Screening now supports a larger set of settings to perform
            refined searches for Stocks, Sectors, ETFs, Indices, Currencies and
            Commodities.
          </ReleaseNoteItem>
        </ReleaseNoteSection>
        <ReleaseNoteSection
          type="New"
          title="Benchmark comparison vs. Rating History of all the stocks"
        >
          <ReleaseNoteItem>
            Inside Markets panel, the Rating History now allows for the
            comparison of the AB vs CD rating evolution with a benchmark of
            interest.
          </ReleaseNoteItem>
        </ReleaseNoteSection>
        <ReleaseNoteSection
          type="Improvement"
          title="Report Drag & Drop Functionalities"
        >
          <ReleaseNoteItem>
            The Report Builder now includes a drag and drop approach to
            customize report sections quickly and efficiently.
          </ReleaseNoteItem>
        </ReleaseNoteSection>
      </ReleaseNote>

      <ReleaseNote
        title="22 November 2022 Version 2.44 - What's new"
        filename={`${assetsUrl}/Trendrating_premium--RELEASE_NOTES--v2.44.pdf`}
      >
        <ReleaseNoteSection type="New" title="Market">
          <ReleaseNoteItem>
            The new ETF Dashboard provides a comprehensive view of trend
            developments across the global ETF.
          </ReleaseNoteItem>
        </ReleaseNoteSection>
        <ReleaseNoteSection type="Improvement" title="Portfolio Analysis">
          <ReleaseNoteItem>
            The improve tab shows which securities can be valid alternatives to
            risky positions, showing for each negatively rated security a
            similar but positively rated security.
          </ReleaseNoteItem>
        </ReleaseNoteSection>
      </ReleaseNote>

      <ReleaseNote title="6 July 2022 Version 2.43 - What's new">
        <ReleaseNoteSection type="New" title="Portfolio Analysis">
          <ReleaseNoteItem>
            It is now possible to analyze the performance dispersion of a basket
            of stocks.
          </ReleaseNoteItem>
        </ReleaseNoteSection>
      </ReleaseNote>

      <ReleaseNote
        title="3 March 2022 Version 2.42 - What's new"
        filename={`${assetsUrl}/Trendrating--RELEASE_NOTES--v2.42.pdf`}
      >
        <ReleaseNoteSection
          type="New"
          title="Performance dispersion by country, sector, industry and size"
        >
          <ReleaseNoteItem>
            It is now possible to analyze the performance dispersion via tables
            and bar charts to compare the statistics of top and bottom
            performers across markets, sectors, industries and Market Cap
            Universes.
          </ReleaseNoteItem>
        </ReleaseNoteSection>
        <ReleaseNoteSection type="New" title="Portfolio and basket comparison">
          <ReleaseNoteItem>
            Compare each portfolio and basket with another portfolio and analyze
            the differences in ratings, alerts, performance and exposure.
          </ReleaseNoteItem>
        </ReleaseNoteSection>
        <ReleaseNoteSection
          type="Improvement"
          title="Portfolio import from file"
        >
          <ReleaseNoteItem>
            When importing a portfolio or basket from file, it is now possible
            to indicate not only the column that contains the identifier, but
            also any columns that contain the market or currency. This allows
            you to automatically resolve the matching of tickers or ISINs in
            different markets.
          </ReleaseNoteItem>
        </ReleaseNoteSection>
      </ReleaseNote>

      <ReleaseNote
        title="11 January 2022 Version 2.41 - What's new"
        filename={`${assetsUrl}/Trendrating_premium--RELEASE_NOTES--v2.41.pdf`}
      >
        <ReleaseNoteSection type="New" title="Alerts Page">
          <ReleaseNoteItem>
            When importing a portfolio or basket from file, it is now possible
            to indicate not only the column that contains the identifier, but
            also any columns that contain the market or currency. This allows
            you to automatically resolve the matching of tickers or ISINs in
            different markets.
          </ReleaseNoteItem>
          <ReleaseNoteItem>
            The monthly view is now available to capture major changes in the
            medium-long term.
          </ReleaseNoteItem>
          <ReleaseNoteItem>
            New filters have been added for stocks and ETFs to optimize
            relevancy.
          </ReleaseNoteItem>
        </ReleaseNoteSection>
      </ReleaseNote>

      <ReleaseNote
        title="1 December 2021 Version 2.40 - What's new"
        filename={`${assetsUrl}/Trendrating--RELEASE_NOTES--v2.40.pdf`}
      >
        <ReleaseNoteSection
          type="New"
          title="Performance dispersion and % of captured top and bottom performers"
        >
          <ReleaseNoteItem>
            The updated stats of the % of captured top and bottom performers
            have been added in the performance dispersion section on the Markets
            page.
          </ReleaseNoteItem>
          <ReleaseNoteItem>
            Track and compare the numbers across markets.
          </ReleaseNoteItem>
        </ReleaseNoteSection>

        <ReleaseNoteSection type="New" title="Portfolio Alerts">
          <ReleaseNoteItem>
            Spot in time portfolios which are deteriorating their exposure to
            the trend. Capture major TCR ® changes of portfolios and baskets
            over different time horizons.
          </ReleaseNoteItem>
          <ReleaseNoteItem>
            Spot emerging upgrades and downgrades across global markets,
            regions, sectors and industries inside your portfolios and baskets.
          </ReleaseNoteItem>
        </ReleaseNoteSection>

        <ReleaseNoteSection
          type="New"
          title="Upgrades/Downgrades report template"
        >
          <ReleaseNoteItem>
            Print the report to get and share all the upgrades and downgrades on
            individual portfolio and basket holdings. Act in time to avoid
            substantial losses.
          </ReleaseNoteItem>
        </ReleaseNoteSection>

        <ReleaseNoteSection type="New" title="Tutorials">
          <ReleaseNoteItem>
            Online on-screen tutorials have been added in the Help page. Get the
            opportunity to gain a variety of knowledge bases and to develop
            themes and workflows.
          </ReleaseNoteItem>
        </ReleaseNoteSection>
      </ReleaseNote>

      <ReleaseNote
        title="17 June 2021 Version 2.36 - What's new"
        filename={`${assetsUrl}/Trendrating_premium--RELEASE_NOTES--v2.36.pdf`}
      >
        <ReleaseNoteSection type="New" title="Strategy Builder">
          <ReleaseNoteItem>
            From today you can backtest strategies at total return level.
          </ReleaseNoteItem>
        </ReleaseNoteSection>

        <ReleaseNoteSection type="New" title="Rank">
          <ReleaseNoteItem>
            Trendrating now incorporate 6 new default rankings criteria's
            templates that combine fundamentals and trend validation metrics to
            boost your analysis.
          </ReleaseNoteItem>
          <ReleaseNoteItem>
            An important improvement occurred in the default ranking template.
            Retracement is now entered as a threshold, to enhance the monitoring
            of instruments.
          </ReleaseNoteItem>
          <ReleaseNoteItem>
            Show ranking changes over a selected period has been moved on the
            top right of the table.
          </ReleaseNoteItem>
        </ReleaseNoteSection>

        <ReleaseNoteSection type="Improvement" title="Report">
          <ReleaseNoteItem>
            Print current view template has been added to the instruments table
            section in the Screening, Portfolio Analysis and Rank pages.
          </ReleaseNoteItem>
        </ReleaseNoteSection>

        <ReleaseNoteSection type="Improvement" title="Help">
          <ReleaseNoteItem>
            Rank section with Documents and Excel template is now available in
            the Help page.
          </ReleaseNoteItem>
        </ReleaseNoteSection>
      </ReleaseNote>

      <ReleaseNote
        title="11 May 2021 Version 2.34 - What's new"
        filename={`${assetsUrl}/Trendrating_premium--RELEASE_NOTES--v2.34.pdf`}
      >
        <ReleaseNoteSection type="New" title="Fundamental analytics">
          <ReleaseNoteItem>
            Trendrating platform now includes a broad variety of fundamental
            data in a straightforward, user-friendly format.
          </ReleaseNoteItem>
          <ReleaseNoteItem>
            The ability to look at these two different types of data together
            and perform meaningful analysis with them often leads to significant
            insights.
          </ReleaseNoteItem>
          <ReleaseNoteItem>
            Click on the button with the pencil icon above the Screening,
            Portfolio Analysis and Rank table to insert them.
          </ReleaseNoteItem>
        </ReleaseNoteSection>

        <ReleaseNoteSection type="New" title="Rankings in subscription">
          <ReleaseNoteItem>
            Generate automatic and fast rankings on major Indexes through the
            new rankings in subscription available in the platform.
          </ReleaseNoteItem>
        </ReleaseNoteSection>

        <ReleaseNoteSection type="Improvement" title="Rank">
          <ReleaseNoteItem>
            Added fundamentals data as ranking criteria.
          </ReleaseNoteItem>
          <ReleaseNoteItem>
            Added "Show changes from" at the top left of the table to easily
            view the changes in the ranking on different time intervals.
          </ReleaseNoteItem>
          <ReleaseNoteItem>Added 1 day analysis period.</ReleaseNoteItem>
        </ReleaseNoteSection>

        <ReleaseNoteSection type="Improvement" title="Report">
          <ReleaseNoteItem>
            Print what you see on the screen functionality has been added to the
            holdings table section.
          </ReleaseNoteItem>
        </ReleaseNoteSection>
      </ReleaseNote>

      <ReleaseNote
        title="01 March 2021 Version 2.30 - What's new"
        filename={`${assetsUrl}/Trendrating_premium--RELEASE_NOTES--v2.30.pdf`}
      >
        <ReleaseNoteSection type="New" title="Markets">
          <ReleaseNoteItem>
            In depth analysis of trend evolution, dispersion and constituents of
            region, market or sector
          </ReleaseNoteItem>
          <ReleaseNoteItem>
            Evaluate by sector classification, geography and size
          </ReleaseNoteItem>
        </ReleaseNoteSection>

        <ReleaseNoteSection type="New" title="Portfolio Analysis">
          <ReleaseNoteItem>
            The portfolio weights can be fixed or drifting
          </ReleaseNoteItem>
        </ReleaseNoteSection>
      </ReleaseNote>

      <ReleaseNote
        title="18 January 2021 Version 2.28 - What's new"
        filename={`${assetsUrl}/Trendrating--RELEASE_NOTES--v2.28.pdf`}
      >
        <ReleaseNoteSection type="New" title="Market Report">
          <ReleaseNoteItem>
            From today, you'll notice a new Report button on the Markets page.
            You can easily generate a PDF on any market or sector of your
            interest!
          </ReleaseNoteItem>
        </ReleaseNoteSection>
      </ReleaseNote>

      <ReleaseNote
        title="9 December 2020 Version 2.26 - What's new"
        filename={`${assetsUrl}/Trendrating--RELEASE_NOTES--v2.26--PMP.pdf`}
      >
        <ReleaseNoteSection type="New" title="Rank page">
          <ReleaseNoteItem>
            Explore Top-Ranked securities in every sector and market with just
            two clicks.
            <br />
            Click on the saved Rank to start.
          </ReleaseNoteItem>
        </ReleaseNoteSection>

        <ReleaseNoteSection type="Improvement" title="Market page">
          <ReleaseNoteItem>
            Capture AB% changes over a day, a week and a month.
          </ReleaseNoteItem>
          <ReleaseNoteItem>
            Capture Up/Downgrades over a day, a week and a month.
          </ReleaseNoteItem>
          <ReleaseNoteItem>
            Catch which industries are pulling the trend at the sector level by
            expanding the view.
          </ReleaseNoteItem>
        </ReleaseNoteSection>
      </ReleaseNote>

      <ReleaseNote
        title="4 June 2020 Version 2.8.0 - What's new"
        filename={`${assetsUrl}/Trendrating--RELEASE_NOTES--v2.8.0.pdf`}
      >
        <ReleaseNoteSection type="New" title="Rank page">
          <ReleaseNoteItem>
            Generate automatic and fast ranking through the new 'Rank' page
            available in the platform.
          </ReleaseNoteItem>
        </ReleaseNoteSection>

        <ReleaseNoteSection type="New" title="Alert page">
          <ReleaseNoteItem>
            Set specific alerts on the markets of your interest at the top of
            the page.
          </ReleaseNoteItem>
          <ReleaseNoteItem>
            Analyze new alerts on your portfolios and baskets.
          </ReleaseNoteItem>
          <ReleaseNoteItem>
            Get the full list of ratings changes in markets and sectors.
          </ReleaseNoteItem>
        </ReleaseNoteSection>

        <ReleaseNoteSection type="New" title="Movers">
          <ReleaseNoteItem>
            Trendrating introduces a new type of alert; Positive and Negative
            Movers.
          </ReleaseNoteItem>
          <ReleaseNoteItem>
            Monitor winning and losing positions in all your portfolios and
            baskets on a daily basis.
          </ReleaseNoteItem>
          <ReleaseNoteItem>
            Evaluate movers in the markets and sectors of your interest.
          </ReleaseNoteItem>
        </ReleaseNoteSection>

        <ReleaseNoteSection
          type="New"
          title="Market - 1 Week changes, 1 Week Reversal"
        >
          <ReleaseNoteItem>
            A new timeframe is available in the Markets page.
          </ReleaseNoteItem>
        </ReleaseNoteSection>

        <ReleaseNoteSection type="New" title="Subscriptions">
          <ReleaseNoteItem>
            Subscribe Baskets which automatically update to speed up your
            workflow.
          </ReleaseNoteItem>
        </ReleaseNoteSection>

        <ReleaseNoteSection
          type="Improvement"
          title="Workflow in Portfolio Analysis"
        >
          <ReleaseNoteItem>
            When analyzing a specific portfolio, each section now has its own
            set of functionalities in the bottom black bar.
          </ReleaseNoteItem>
        </ReleaseNoteSection>

        <ReleaseNoteSection type="Improvement" title="Settings panel">
          <ReleaseNoteItem>
            The settings icon button has been substitute with the word
            'Settings'.
          </ReleaseNoteItem>
          <ReleaseNoteItem>
            The logout is now inside the Settings panel.
          </ReleaseNoteItem>
        </ReleaseNoteSection>

        <ReleaseNoteSection
          type="Removed"
          title="Replicate an existing portfolio"
        >
          <ReleaseNoteItem>
            To replicate an existing portfolio, click on the Duplicate
            functionality in the main action panel.
          </ReleaseNoteItem>
        </ReleaseNoteSection>
      </ReleaseNote>

      <ReleaseNote
        title="10 March 2020 Version 2.7.0 - What's new"
        filename={`${assetsUrl}/Trendrating--RELEASE_NOTES--v2.7.0.pdf`}
      >
        <ReleaseNoteSection
          type="New"
          title="Screening, Portfolio & Strategy Reporting"
        >
          <ReleaseNoteItem>
            New set of features to generate on-demand and customized reporting
          </ReleaseNoteItem>
          <ReleaseNoteItem>
            New set of default reporting templates
          </ReleaseNoteItem>
          <ReleaseNoteItem>Save report templates</ReleaseNoteItem>
        </ReleaseNoteSection>

        <ReleaseNoteSection type="New" title="Market TCR changes">
          <ReleaseNoteItem>
            Compare Trend Capture Rating (TCR) changes to previous day and month
            to support rotation and allocation decisions
          </ReleaseNoteItem>
          <ReleaseNoteItem>
            Capture upgrades and downgrades across global markets, regions,
            sectors and industries
          </ReleaseNoteItem>
        </ReleaseNoteSection>

        <ReleaseNoteSection type="New" title="Rationale">
          <ReleaseNoteItem>
            New Rationale field in Portfolio Analysis and Strategy Builder
            provides full transparency on the optimization process
          </ReleaseNoteItem>
        </ReleaseNoteSection>

        <ReleaseNoteSection type="New" title="Help page"></ReleaseNoteSection>

        <ReleaseNoteSection type="Improvement" title="Rank">
          <ReleaseNoteItem>
            Maintain ranking order of securities when sorting by market,
            industry, sector or currency
          </ReleaseNoteItem>
        </ReleaseNoteSection>

        <ReleaseNoteSection
          type="Improvement"
          title="Portfolio Import"
        ></ReleaseNoteSection>

        <ReleaseNoteSection
          type="Improvement"
          title="Asset allocation pie charts in the Strategy Builder"
        ></ReleaseNoteSection>

        <ReleaseNoteSection
          type="Update"
          title="Strategy Builder User guides"
        ></ReleaseNoteSection>
      </ReleaseNote>

      <ReleaseNote
        title="20 January 2020 New widget: Performance Dispersion"
        filename={`${assetsUrl}/Trendrating--RELEASE_NOTES--20-01-2020.pdf`}
      >
        <ReleaseNoteSection type="New" title="Markets">
          <ReleaseNoteItem>
            A new widget Dispersion is available in the MARKETS section showing
            the performance dispersion across markets and sectors at different
            timeframe.
          </ReleaseNoteItem>
        </ReleaseNoteSection>
      </ReleaseNote>
    </div>
  );
};
