import { Box, Typography } from "@mui/material";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { decodePeerId, encodePeerId } from "../../../../../../api/utils";
import { PeerSize } from "../../../../../../components/PeerSize/PeerSize";
import { TaxonomySelect } from "../../../../../../components/TaxonomySelect/TaxonomySelect";
import { useEnvironment } from "../../../../../../hooks/useEnvironment";
import { useTaxonomyByType } from "../../../../../../hooks/useTaxonomyByType";
import { FormOptions } from "../../../../../../trendrating/app/formatter/FormOptions";
import { SelectorETFMarkets } from "../../ETFs/widgets/FiltersBarMarketDetail/FiltersBarMarketDetail";
import { v4 as uuidv4 } from "uuid";
import styles from "./HeaderBar.module.scss";
import {
  PeerDetailSegments,
  PeerDetailTabs,
  PeerDetailTimeframe,
} from "../PeerDetail";
import { useEventBus } from "../../../../../../hooks/useEventBus";

type HeaderBarProps = {
  segment: PeerDetailSegments;
  setSegment: (value) => void;
  tab: PeerDetailTabs;
  timeframe: PeerDetailTimeframe;
  peerId: string;
  setTimeframe: (value) => void;
  setTab: (value) => void;
};

const PAGE_KEY = "analysis_peer";

const CHILDREN_TYPES = {
  "1 Industry": {
    label: "Sectors",
    selected: false,
    value: "1 Industry",
    etfLabel: "Asset class",
  },
  "3 Sector": {
    label: "Industries",
    selected: false,
    value: "3 Sector",
    etfLabel: "Specialty",
  },
  World: {
    label: "World wide",
    selected: false,
    value: "World",
    etfLabel: "World wide",
  },
  Area: {
    label: "Areas",
    selected: false,
    value: "Area",
    etfLabel: "Areas",
  },
  Region: {
    label: "Regions",
    selected: false,
    value: "Region",
    etfLabel: "Regions",
  },
  Country: {
    label: "Markets",
    selected: false,
    value: "Country",
    etfLabel: "Markets",
  },
  "3 Level": {
    label: "Size",
    selected: false,
    value: "3 Level",
  },
};

const SEGMENT_DICT = {
  "1 Industry": "Sector",
  "3 Sector": "Industries",
  "3 Level": "Size",
  WWW: "World Wide",
  Area: "Area",
  Region: "Region",
  Country: "Country",
};

const CONFIG_TABS_DICT = {
  TCR: "TCR",
  dispersion: "dispersion",
  dispersionBy: "dispersionBySector",
  performanceSinceRated: "performanceSinceRated",
  history: "ratingHistory",
  securities: "securities",
};

export function HeaderBar({
  timeframe,
  tab,
  peerId,
  setTimeframe,
  setTab,
  segment,
  setSegment,
}: HeaderBarProps) {
  const taxonXPanelRef = useRef<HTMLDivElement>(null);
  const taxonYPanelRef = useRef<HTMLDivElement>(null);
  const marketLabelRef = useRef<HTMLSpanElement>(null);
  const sectorLabelRef = useRef<HTMLSpanElement>(null);
  const validLevels = useMemo(() => ["1 Industry", "3 Sector"], []);
  const [flagPath, setFlagPath] = useState<string | undefined>();
  const environment = useEnvironment();
  const appEnv = environment.get("setup");
  const formOptions = useMemo(() => new FormOptions(appEnv), [appEnv]);
  const columnsDefinition = useMemo(
    () => [
      [{ taxonomyId: "20" }, { taxonomyId: "60" }],
      [{ taxonomyId: "25" }, { taxonomyId: "50" }],
      [{ taxonomyId: "15" }, { taxonomyId: "35" }, { taxonomyId: "10" }],
      [{ taxonomyId: "40" }, { taxonomyId: "45" }],
      [{ taxonomyId: "55" }, { taxonomyId: "30" }],
    ],
    []
  );

  const WIDGETS_OPTIONS: any = useMemo(() => {
    const tabsCollection: any = [
      { label: "TCR", value: "TCR" },
      { label: "Rating History", value: "history" },
      { label: "Dispersion", value: "dispersion" },
      { label: "Securities", value: "securities" },
      {
        label: `Dispersion By ${SEGMENT_DICT[segment]}`,
        value: "dispersionBy",
      },
      { label: "Trend Tracker", value: "performanceSinceRated" },
    ];

    const optionsMap: any = {
      timeframe: [
        { label: "Today", value: "today" },
        { label: "1w", value: "lastWeek" },
        { label: "1m", value: "lastMonth" },
      ],
      tabs: [],
      navigator: [
        { label: "Stock", value: "stock" },
        { label: "ETF", value: "etf" },
      ],
      size: [],
      segment: [],
    };

    const configuration =
      environment.get("account")?.product?.configuration?.[PAGE_KEY];
    const configurationsTabs = configuration?.tabs;
    const order = configuration?.tabOrder;

    if (configurationsTabs) {
      const availableTabs = configurationsTabs["Stocks"];
      const tabs: any = [];
      let tabConfig: any = null;

      for (const tab of tabsCollection) {
        tabConfig = availableTabs[CONFIG_TABS_DICT[tab.value]];
        if (tabConfig && tabConfig.enabled === true) {
          tabs.push(tab);
        }
      }

      if (order) {
        tabs.sort((a, b) => {
          return order?.indexOf(CONFIG_TABS_DICT?.[a.value]) >
            order?.indexOf(CONFIG_TABS_DICT?.[b.value])
            ? 1
            : -1;
        }, []);
      }

      optionsMap.tabs = tabs;
    } else {
      optionsMap.tabs = tabsCollection;
    }
    return optionsMap;
  }, [environment, segment]);

  const peerInfo = useMemo(() => decodePeerId(peerId), [peerId]);

  const showAssetClassSelector = useMemo(() => {
    const configuration =
      environment.get("account")?.product?.configuration?.["analysis_market"];
    const assetClasses = configuration.assetClasses;

    const isStockEnabled =
      "stock" in assetClasses && assetClasses.stock === true;
    const isEtfEnabled = "etf" in assetClasses && assetClasses.etf === true;

    return isStockEnabled && isEtfEnabled;
  }, [environment]);

  const [country, setCountry] = useState(peerInfo.where);
  const [sector, setSector] = useState(peerInfo.what);
  const [size, setSize] = useState(peerInfo.zDimension);
  const [key, setKey] = useState(uuidv4());
  const [searchParams, setSearchParams] = useState<string | undefined>(
    undefined
  );

  const { on, remove } = useEventBus();
  const navigate = useNavigate();

  const updatePeer = useCallback((event) => {
    const peerInfo = decodePeerId(event.detail.peer);

    setCountry(peerInfo.where);
    setSector(peerInfo.what);
    setSize(peerInfo.zDimension);

    if (event.detail.params) {
      setSearchParams(event.detail.params);
    } else {
      setSearchParams(undefined);
    }
  }, []);

  useEffect(() => {
    on("peer-change", updatePeer);

    return () => remove("peer-change", updatePeer);
  }, [on, remove, updatePeer]);

  useEffect(() => {
    const peerDimensions = {
      what: sector,
      where: country,
      zDimension: size !== null ? size : "microLarge",
      type: "stock",
    };
    const newPeerId = encodePeerId(peerDimensions);

    if (newPeerId !== peerId) {
      const uri = `/app/analysis/markets/${newPeerId}/${timeframe}`;

      const navigationObj = {
        pathname: uri,
      };

      if (searchParams) {
        navigationObj["search"] = searchParams;
      }

      navigate(navigationObj);
      setSearchParams(undefined);
    }
  }, [country, navigate, peerId, searchParams, sector, size, timeframe]);

  const { getTaxonomyById, rootNodeX, rootNodeY } = useTaxonomyByType("stock");

  // Set the country flag
  useEffect(() => {
    const countryNode = getTaxonomyById(country, "country");

    if (countryNode && countryNode.type === "Country") {
      const flagPath = `/img/flags/${country}.png`;
      setFlagPath(flagPath);
    } else {
      setFlagPath(undefined);
    }
  }, [country, getTaxonomyById]);

  const marketLabel = useMemo(() => {
    let label = country ? getTaxonomyById(country, "country")?.name ?? "" : "";

    if (label === rootNodeX.name) {
      label = "";
    }

    return label;
  }, [country, getTaxonomyById, rootNodeX.name]);

  const sectorLabel = useMemo(() => {
    let label = sector ? getTaxonomyById(sector, "icb")?.name ?? "" : "";
    const node = sectorLabelRef.current;

    if (node) {
      if (label === rootNodeY.name || label === "Any") {
        node.style.opacity = "0";
      } else {
        node.style.opacity = "1";
      }
    }

    return label;
  }, [getTaxonomyById, rootNodeY.name, sector]);

  const openPanel = useCallback((ref) => {
    if (ref.current) {
      ref.current.showTreePanel(true);
    }
  }, []);

  const onChangeCountry = useCallback(
    (id) => {
      setCountry(id);
    },
    [setCountry]
  );

  const onChangeSector = useCallback(
    (id) => {
      setSector(id);
    },
    [setSector]
  );

  const getChildrenTypeOptions = useCallback(
    (peerIdInfo: {
      zDimension: string;
      type: string;
      what: string;
      where: string;
    }) => {
      const options: any = [];

      const taxonomies = environment.get("setup")["taxonomies"];
      const txFields = environment.get("setup")["taxonomyFields"];

      // Sectors / Industry
      const whatTaxonomy =
        peerIdInfo.type === "ETF"
          ? taxonomies[txFields["ETF"]["etfclass"]]
          : taxonomies[txFields["security"]["sector"]];
      const whatInfo = whatTaxonomy[peerIdInfo["what"]];

      if (whatInfo.type === "0 root") {
        options.push(CHILDREN_TYPES["1 Industry"]);
        options.push(CHILDREN_TYPES["3 Sector"]);
      } else if (whatInfo.type === "1 Industry") {
        options.push(CHILDREN_TYPES["3 Sector"]);
      }

      // Markets
      var whereTaxonomy =
        peerIdInfo.type === "ETF"
          ? taxonomies[txFields["ETF"]["etfgeo"]]
          : taxonomies[txFields["security"]["country"]];
      var whereInfo = whereTaxonomy[peerIdInfo["where"]];
      if (whereInfo.type === "World") {
        options.push(CHILDREN_TYPES["Area"]);
        options.push(CHILDREN_TYPES["Region"]);
        options.push(CHILDREN_TYPES["Country"]);
      } else if (whereInfo.type === "Area") {
        options.push(CHILDREN_TYPES["Region"]);
        options.push(CHILDREN_TYPES["Country"]);
      } else if (whereInfo.type === "Region") {
        options.push(CHILDREN_TYPES["Country"]);
      } else {
        // Do nothing, already at the lowest level
      }

      // Size classification
      if (peerIdInfo.type !== "ETF") {
        if (peerIdInfo["zDimension"] === "microLarge") {
          options.push(CHILDREN_TYPES["3 Level"]);
        }
      }

      if (options.length > 0) {
        options[0]["selected"] = true;
      }

      if (options.length) {
        if (!options.some((opt) => opt.value === segment)) {
          setSegment(options[0].value);
        }
      }

      return options;
    },
    [environment, segment, setSegment]
  );

  // Get size options and segments options
  useEffect(() => {
    const sizeOtions = formOptions.getField("SIZE");
    let zDimension = size;

    if (zDimension === null) {
      zDimension = "microLarge";
    }

    if (zDimension && sector && country) {
      const segmentsOptions = getChildrenTypeOptions({
        zDimension,
        what: sector,
        where: country,
        type: "stock",
      });

      WIDGETS_OPTIONS["segment"] = segmentsOptions ?? [];
    }

    WIDGETS_OPTIONS["size"] = sizeOtions;
  }, [
    WIDGETS_OPTIONS,
    country,
    formOptions,
    getChildrenTypeOptions,
    sector,
    size,
  ]);

  const updateSize = useCallback((value) => {
    // Rerender the peer size widget when the state change to make the widget start with the new value.
    // this is necessary because the component is an uncontrolled component so to destroy his stare and reload it
    // with the correct value is necessary to update his key attribute. This make an unmoun and  a new mount of the component
    setKey(uuidv4());
    setSize(value);
  }, []);

  const goToAnalysisEtf = useCallback(() => {
    const info = {
      action: "LANDING",
      function: "MARKET_ETF",
    };
    window.App.usage.record(info);
    navigate("/app/analysis/markets/ETF");
  }, [navigate]);

  return (
    <Box className={styles.headerWrapper}>
      <Box className={styles.firstRow}>
        <Box display={"flex"} flex={1} alignItems={"center"} gap={1} ml={2}>
          <Box p={"5px"} className={!showAssetClassSelector ? "hide" : ""}>
            <Typography
              sx={{
                color: "#2A7091",
                marginBottom: "5px",
                fontSize: "0.7vw",
              }}
            >
              Asset Class
            </Typography>
            <SelectorETFMarkets
              options={WIDGETS_OPTIONS.navigator}
              selectOption={goToAnalysisEtf}
              selectedOption={"stock"}
            />
          </Box>

          <TaxonomySelect
            ref={taxonXPanelRef}
            taxonomyInfo={{ type: "security", field: "country" }}
            panelTitle={"Market"}
            hasMultipleChoices={false}
            selectValue={onChangeCountry}
            defaultValue={country ? [country] : []}
          >
            <Box className={styles.outlined__box} component={"fieldset"}>
              <legend className={styles.outlined__box__label}>Market</legend>
              <Box className={styles.outlined__box__content}>
                <Typography
                  ref={marketLabelRef}
                  className={styles.title__label}
                  onClick={() => openPanel(taxonXPanelRef)}
                >
                  {flagPath && (
                    <Box component={"span"} mr={1}>
                      <img alt="market-flag" src={flagPath} />
                    </Box>
                  )}
                  {marketLabel}
                </Typography>
              </Box>
            </Box>
          </TaxonomySelect>
          <TaxonomySelect
            ref={taxonYPanelRef}
            validTreeNodes={validLevels}
            taxonomyInfo={{ type: "security", field: "icb" }}
            panelTitle={"Sector"}
            hasMultipleChoices={false}
            selectValue={onChangeSector}
            defaultValue={sector ? [sector] : []}
            columnsDefinition={columnsDefinition}
          >
            <Box component={"fieldset"} className={styles.outlined__box}>
              <legend className={styles.outlined__box__label}>Sector</legend>
              <Box className={styles.outlined__box__content}>
                <Typography
                  ref={sectorLabelRef}
                  className={styles.title__label}
                  onClick={() => openPanel(taxonYPanelRef)}
                >
                  {sectorLabel}
                </Typography>
              </Box>
            </Box>
          </TaxonomySelect>
        </Box>
      </Box>
      <Box className={styles.secondRow}>
        {WIDGETS_OPTIONS.size.length ? (
          <Box p={"5px"} borderRight={"1px solid #ddd"}>
            <Typography
              sx={{
                color: "#2A7091",
                marginBottom: "5px",
                fontSize: "10px",
              }}
            >
              Size
            </Typography>
            <PeerSize
              key={key}
              options={(WIDGETS_OPTIONS.size as any) ?? []}
              isNullWhenAllAreSelected={true}
              onClickItem={updateSize}
              defaultValue={size}
            />
          </Box>
        ) : (
          <></>
        )}

        {tab !== "performanceSinceRated" && (
          <Box p={"5px"} borderRight={"1px solid #ddd"}>
            <Typography
              sx={{
                color: "#2A7091",
                marginBottom: "5px",
                fontSize: "10px",
              }}
            >
              Timeframe
            </Typography>
            <SelectorETFMarkets
              options={WIDGETS_OPTIONS.timeframe}
              selectOption={setTimeframe}
              selectedOption={timeframe}
            />
          </Box>
        )}

        {WIDGETS_OPTIONS.segment.length ? (
          <Box p={"5px"} borderRight={"1px solid #ddd"}>
            <Typography
              sx={{
                color: "#2A7091",
                marginBottom: "5px",
                fontSize: "10px",
              }}
            >
              Segment
            </Typography>
            <SelectorETFMarkets
              options={WIDGETS_OPTIONS.segment}
              selectOption={setSegment}
              selectedOption={segment ?? ""}
            />
          </Box>
        ) : (
          <></>
        )}

        <Box p={"5px"}>
          <Typography
            sx={{
              color: "#2A7091",
              marginBottom: "5px",
              fontSize: "10px",
            }}
          >
            Analytics
          </Typography>
          <SelectorETFMarkets
            options={WIDGETS_OPTIONS.tabs}
            selectOption={setTab}
            selectedOption={tab}
          />
        </Box>
      </Box>
    </Box>
  );
}
