import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Chip,
  FormControlLabel,
  Stack,
  Typography,
} from "@mui/material";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { RowComponent } from "tabulator-tables";
import { v4 as uuidv4 } from "uuid";
import { Report } from "../../../../../../../../../../api/report/Report";
import Modal from "../../../../../../../../../../components/Modal/Modal";
import {
  TableEventsV2,
  TableV2,
} from "../../../../../../../../../../components/table/v2/TableCoreV2";
import { TrendratingTableV2 } from "../../../../../../../../../../components/table/v2/TableV2";
import { deepClone } from "../../../../../../../../../../deepClone";
import { useEnvironment } from "../../../../../../../../../../hooks/useEnvironment";
import { useFormatter } from "../../../../../../../../../../hooks/useFormatter";
import { Formatter } from "../../../../../../../../../../trendrating/utils/Formatter";
import { AppEnvironment } from "../../../../../../../../../../types/Defaults";
import { themeBlueAndGrey } from "../../../../../../../../widgets/dialog/report/themeBlueAndGrey-ts";
import { CatalogDashboard, InsightsContext } from "../Insights/Insights";
import styles from "./Catalog.module.scss";

type CatalogProps = {
  catalogDashboard: CatalogDashboard | undefined;
  askBeforeRedirect: boolean;
};

type CatalogTableProps = {
  strategies: CatalogDashboard;
  handleRowSelect: (value) => void;
};

type FiltersColumProps = {
  strategies: CatalogDashboard;
  filterFn: (filters) => void;
};

type Tag = {
  allName: string;
  id: string;
  name: string;
  type: "multiple" | "single";
  display: string[];
  filters: {
    id: string;
    name: string;
    type: string;
  }[];
};

type Tags = Tag[];

type FilterCardProps = {
  filter: {
    label: string;
    checkboxes: { label: string; value: string }[];
    groupId: string;
  };
  filterFn: (filters) => void;
};

type FilterShortcutProps = {
  removeFilter: (filters) => void;
  isUniverseEmpty: boolean;
  currentFitlers: any;
};

type TalbeRows = {
  year_10_perf_H: number;
  year_10_perf_DIFF: number;
  ytd: number;
  ytd_DIFF: number;
  sharpeRatio: number;
  avg_yearly_drawdown: number;
  turnover: number;
  syntheticId: string;
  name: string;
  id: number;
  type: string[];
}[];

const ANALYTICS_DICT = {
  year_10_perf_H: "r#null,null,FULL,null,null,FULL,H,YEARLY",
  year_10_perf_DIFF: "r#null,null,FULL,null,null,FULL,DIFF,YEARLY",
  ytd: "r#null,null,FULL,YEARLY,1,TD,H",
  ytd_DIFF: "r#null,null,FULL,YEARLY,1,TD,DIFF",
  sharpeRatio: "sharpe#null,null,FULL,null,null,null,H",
  avg_yearly_drawdown: "avg_md_Y#null,null,FULL,null,null,FULL,H",
  turnover: "turnover#NULL,NULL,FULL,NULL,NULL,FULL,YEARLY",
};

const COLUMNS_FIELDS_DICT = {
  tenYearsReturn: "year_10_perf_H",
  tenYearsReturnDiff: "year_10_perf_DIFF",
  ytd: "ytd",
  ytdDiff: "ytd_DIFF",
  sharpeRatio: "sharpeRatio",
  drawdown: "avg_yearly_drawdown",
  turnover: "turnover",
  id: "syntheticId",
  name: "name",
  strategyId: "id",
  type: "type",
};

const nameFormatter = (name) => {
  const nameSplitted = name.split("__");

  if (nameSplitted?.[1] != null) {
    return `${nameSplitted[0]}</br>${nameSplitted[1]}`;
  }

  return nameSplitted[0];
};

const format = (formatter, tag, value) => {
  switch (tag) {
    case COLUMNS_FIELDS_DICT["name"]:
      return nameFormatter(value);
    case COLUMNS_FIELDS_DICT["sharpeRatio"]:
      return formatter.custom("number", {
        options: {
          notAvailable: {
            input: null,
            output: "",
          },
          decimals: 4,
        },
        output: "HTML",
        value,
      });

    case COLUMNS_FIELDS_DICT["type"]:
      return value.join(", ");

    default:
      let innerHTML = "";

      innerHTML = formatter.custom("number", {
        options: {
          isPercentage: true,
          notAvailable: {
            input: null,
            output: "",
          },
        },
        output: "HTML",
        value: value,
        valueHelper: {
          normalizationThreshold: 1,
        },
      });

      return innerHTML;
  }
};

const getAnalytic = (analyticTag: keyof typeof COLUMNS_FIELDS_DICT, row) => {
  const key = ANALYTICS_DICT[COLUMNS_FIELDS_DICT[analyticTag]];

  if (key) {
    let analyticType =
      key === ANALYTICS_DICT["turnover"] ? "hPosAnalytics" : "priceAnalytics";
    const analytic = row[analyticType].stats[key];
    if (analytic) {
      return Object.values(analytic)[0] as number;
    } else {
      return null;
    }
  }

  return undefined;
};

const getType = (row, tags: Tags) => {
  const type = row.info["group-theme"];

  if (type) {
    const typeTag = tags.find((item) => item.id === "group-theme");
    let values: string[] = [];

    for (const value of type) {
      const tagFilter = typeTag?.filters.find((item) => item.id === value);

      if (tagFilter) {
        values.push(tagFilter.name);
      }
    }

    return values;
  }

  return [];
};

const wrapDataInRows = (strategySet: CatalogDashboard, tags: Tags) => {
  const rows: TalbeRows = [];

  for (const strategy of strategySet) {
    rows.push({
      year_10_perf_H: getAnalytic("tenYearsReturn", strategy) ?? 0,
      year_10_perf_DIFF: getAnalytic("tenYearsReturnDiff", strategy) ?? 0,
      ytd: getAnalytic("ytd", strategy) ?? 0,
      ytd_DIFF: getAnalytic("ytdDiff", strategy) ?? 0,
      sharpeRatio: getAnalytic("sharpeRatio", strategy) ?? 0,
      avg_yearly_drawdown: getAnalytic("drawdown", strategy) ?? 0,
      turnover: getAnalytic("turnover", strategy) ?? 0,
      syntheticId: strategy.info["synteticId"],
      name: strategy.info.name,
      id: strategy.info.id,
      type: getType(strategy, tags),
    });
  }

  return rows;
};

export function Catalog({ catalogDashboard, askBeforeRedirect }: CatalogProps) {
  const ctx = useContext(InsightsContext);
  const initFilters = useMemo(() => {
    const currentState = ctx.get();

    return currentState.catalogState.activeFilters;
  }, [ctx]);
  const [activeFilters, setActiveFilters] =
    useState<{ level: string; filters: string[] }[]>(initFilters);
  const [strategyIdToRedirect, setStrategyIdToRedirect] = useState<any>(null);
  const [showModal, setShowModal] = useState(false);

  const environment = useEnvironment();

  const dashboard = useMemo(() => {
    const catalogStrategies = catalogDashboard;
    let rows: CatalogDashboard = [];

    const isProductAvailable = (availableProducts) => {
      const productId = environment.get("account").user.product.id.toString();

      if (Array.isArray(availableProducts)) {
        return availableProducts.some((product) => product === productId);
      } else {
        return availableProducts === productId;
      }
    };

    if (catalogStrategies) {
      for (const strategy of catalogStrategies) {
        if (
          strategy.info["group-status"] === "recents" &&
          isProductAvailable(strategy.info["group-product"])
        ) {
          rows.push(strategy);
        }
      }
    }

    return rows;
  }, [catalogDashboard, environment]);

  const checkFilterPass = useCallback(
    (row) => {
      let value: any = null;
      let passed = true;

      OuterLoop: for (const filter of activeFilters) {
        passed = true;
        value = row?.[filter.level];

        if (value) {
          if (Array.isArray(value)) {
            for (const v of value) {
              if (!filter.filters.includes(v)) {
                passed = false;

                break OuterLoop;
              }
            }
          } else {
            if (!filter.filters.includes(value)) {
              passed = false;

              break;
            }
          }
        } else {
          passed = false;

          break;
        }
      }

      return passed;
    },
    [activeFilters]
  );

  const strategies = useMemo(() => {
    const catalogStrategies = catalogDashboard;
    let rows: CatalogDashboard = [];

    const isProductAvailable = (availableProducts) => {
      const productId = environment.get("account").user.product.id.toString();

      if (Array.isArray(availableProducts)) {
        return availableProducts.some((product) => product === productId);
      } else {
        return availableProducts === productId;
      }
    };

    let valid = false;

    if (catalogStrategies) {
      for (const strategy of catalogStrategies) {
        valid = false;

        if (
          strategy.info["group-status"] === "recents" &&
          isProductAvailable(strategy.info["group-product"])
        ) {
          if (activeFilters.length > 0) {
            valid = checkFilterPass(strategy.info);

            if (valid) {
              rows.push(strategy);
            }
          } else {
            rows.push(strategy);
          }
        }
      }
    }

    return rows;
  }, [activeFilters.length, catalogDashboard, checkFilterPass, environment]);

  const pdf = useMemo(
    () =>
      new PDF(strategies, environment.get("setup"), () => {
        const currentState = deepClone(ctx.get());

        return {
          sorter: currentState.catalogState.sorter,
          activeFilters: currentState.catalogState.activeFilters,
        } as any;
      }),
    [ctx, environment, strategies]
  );

  const download = useCallback(() => {
    pdf.print();
  }, [pdf]);

  const filterStrategies = useCallback(
    (value) => {
      if (value.values.length > 0) {
        setActiveFilters((current) => {
          const newState = [...current];

          let filterAlreadyIn = false;

          for (const currentFitler of newState) {
            if (currentFitler.level === value.level) {
              filterAlreadyIn = true;

              currentFitler.filters = value.values;
            }
          }

          if (!filterAlreadyIn) {
            newState.push({ level: value.level, filters: value.values });
          }

          const currentState = deepClone(ctx.get());
          currentState.catalogState.activeFilters = newState;
          ctx.set(currentState);

          return newState;
        });
      } else {
        setActiveFilters((current) => {
          const newState = current.filter(
            (currentFilter) => currentFilter.level !== value.level
          );

          const currentState = deepClone(ctx.get());
          currentState.catalogState.activeFilters = newState;
          ctx.set(currentState);

          return newState;
        });
      }
    },
    [ctx]
  );

  const selectStrategy = useCallback(
    (id) => {
      if (id) {
        if (askBeforeRedirect) {
          setStrategyIdToRedirect(id);
          setShowModal(true);
        } else {
          const newState = deepClone(ctx.get());
          newState.selectedStrategy = {
            type: "CATALOG",
            id: JSON.stringify(id),
          };
          ctx.set(newState);
          ctx.get().catalogState.handleRowClick(id);
        }
      }
    },
    [askBeforeRedirect, ctx]
  );

  const closeDialog = useCallback(() => setShowModal(false), []);

  const overrideCurrentStrategy = useCallback(() => {
    closeDialog();
    const currentState = deepClone(ctx.get());
    currentState.selectedStrategy = {
      type: "CATALOG",
      id: strategyIdToRedirect,
    };
    ctx.set(currentState);
    const override = currentState.catalogState.overideStrategy;
    if (override) {
      override(strategyIdToRedirect);
    }
  }, [closeDialog, ctx, strategyIdToRedirect]);

  const closeInfoDialog = useCallback(() => {
    setShowModal(false);
  }, []);

  const onTableRowClick = useCallback(
    (id) => {
      selectStrategy(id);
    },
    [selectStrategy]
  );

  return (
    <>
      {showModal && (
        <Modal
          closeIcon={false}
          headerConfig={{ headerContent: "Info", hasBackground: true }}
          onClose={closeInfoDialog}
        >
          <Box display={"flex"} flexDirection={"column"} gap={2}>
            <Typography>
              Are you sure you want to leave without save the current changes on
              the strategy?
            </Typography>
            <Box display={"flex"} gap={1} justifyContent={"flex-end"}>
              <Button variant="contained" onClick={overrideCurrentStrategy}>
                Yes
              </Button>
              <Button variant="tr_button_cancel" onClick={closeInfoDialog}>
                No
              </Button>
            </Box>
          </Box>
        </Modal>
      )}

      <Box className={styles.main}>
        <Card className={styles.main__card}>
          <CardContent className={styles.main__card__content}>
            <Box className={styles.main__card__content__section__title}>
              <Typography
                sx={{ fontSize: "1.2em", textTransform: "uppercase" }}
              >
                Strategy Directory{" "}
                <Typography sx={{ textTransform: "none" }}>
                  (<strong>{strategies.length}</strong> Strategy analytics as of{" "}
                  <strong>{dashboard?.[0]?.date ?? ""}</strong>. Performance
                  figures over 1 year are annualized)
                </Typography>
              </Typography>
              <Button variant="contained" onClick={download}>
                Download PDF
              </Button>
            </Box>
            <Box className={styles.main__card__content__section__content}>
              <FiltersColumn
                strategies={dashboard}
                filterFn={filterStrategies}
              />
              <Box
                className={
                  styles.main__card__content__section__content__scrollable
                }
              >
                <FilterShortcut
                  removeFilter={filterStrategies}
                  isUniverseEmpty={!strategies.length}
                  currentFitlers={activeFilters}
                />
                <CatalogTable
                  handleRowSelect={onTableRowClick}
                  strategies={strategies}
                />
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </>
  );
}

const CatalogTable = ({ strategies, handleRowSelect }: CatalogTableProps) => {
  const ctx = useContext(InsightsContext);
  const formatter = useFormatter();
  const environment = useEnvironment();
  const tags = useMemo(() => environment.get("setup").tags, [environment]);
  const [tableReady, setTableReady] = useState(false);

  const getColumnField = (key: keyof typeof COLUMNS_FIELDS_DICT) => {
    return COLUMNS_FIELDS_DICT?.[key] ?? key;
  };

  const formatByTag = useCallback(
    (cell) => {
      const tag = cell.getField();
      const value = cell.getValue();

      if (tag === "name") {
        const data = cell.getData();
        const currentState = ctx.get();
        let id = data.id;
        if (id) {
          let selectedStrategyId: string | number | null =
            currentState.selectedStrategy?.id ?? null;
          if (selectedStrategyId != null) {
            selectedStrategyId = parseInt(selectedStrategyId);
            if (id === selectedStrategyId) {
              const row = cell.getRow();
              row.getElement().style.backgroundColor = "rgba(255, 192, 1, 0.2)";
            }
          }
        }
      }

      return format(formatter, tag, value);
    },
    [ctx, formatter]
  );

  const sorterCallback = useCallback(
    (event) => {
      const sorter = event.value;
      const newState = deepClone(ctx.get());

      newState.catalogState.sorter = {
        field: sorter.field,
        rev: sorter.direction === "desc",
      };

      ctx.set(newState);
    },
    [ctx]
  );

  const sortType = useCallback((a, b) => {
    return a[0] > b[0] ? 1 : -1;
  }, []);

  const columns: any = useMemo(() => {
    return [
      {
        title: "Type",
        field: getColumnField("type"),
        hozAlign: "left",
        sorter: sortType,
        formatter: formatByTag,
      },
      {
        title: "Id",
        field: getColumnField("id"),
        hozAlign: "left",
        sorter: "string",
      },
      {
        title: "Name",
        field: getColumnField("name"),
        hozAlign: "left",
        sorter: "string",
        formatter: formatByTag,
      },
      {
        title: "10 Years Return",
        columns: [
          {
            title: "Strategy",
            field: getColumnField("tenYearsReturn"),
            formatter: formatByTag,
            sorter: "number",
          },
          {
            title: "Excess Return",
            field: getColumnField("tenYearsReturnDiff"),
            formatter: formatByTag,
            sorter: "number",
          },
        ],
      },

      {
        title: "YTD Return",
        columns: [
          {
            title: "Strategy",
            field: getColumnField("ytd"),
            formatter: formatByTag,
            sorter: "number",
          },
          {
            title: "Excess Return",
            field: getColumnField("ytdDiff"),
            formatter: formatByTag,
            sorter: "number",
          },
        ],
      },

      {
        title: "Sharpe Ratio",
        field: getColumnField("sharpeRatio"),
        formatter: formatByTag,
        sorter: "number",
      },
      {
        title: "Avg Yearly Drawdown",
        field: getColumnField("drawdown"),
        formatter: formatByTag,
        widthGrow: 1.5,
        sorter: "number",
      },
      {
        title: "Turnover",
        field: getColumnField("turnover"),
        formatter: formatByTag,
        sorter: "number",
      },
    ];
  }, [formatByTag, sortType]);

  const tableRef = useRef<{ getInstance: () => TableV2 }>();

  const onRowClick = useCallback(
    (e, row: RowComponent) => {
      const strategy = row.getData();
      const table = row.getTable();
      for (const r of table.getRows()) {
        r.getElement().style.backgroundColor = "white";
      }

      row.getElement().style.backgroundColor = "rgba(255, 192, 1, 0.2)";
      handleRowSelect(strategy.id);
    },
    [handleRowSelect]
  );

  const getDefaultSorter = useCallback(() => {
    const globalState = ctx.get();

    const sorter = globalState.catalogState.sorter;

    return {
      field: sorter.field,
      direction: sorter.rev === false ? "desc" : "asc",
    };
  }, [ctx]);

  const tableEvents: TableEventsV2 = useMemo(() => {
    return {
      headerSort: ({ field, direction }) => {
        sorterCallback({
          type: "sort",
          value: {
            direction: direction,
            field: field,
          },
        });
      },
      rowClick: onRowClick,
      onTableBuilt: () => setTableReady(true),
      onTableDestroyed: () => setTableReady(false),
    };
  }, [onRowClick, sorterCallback]);

  const tableSetup = useMemo(() => {
    const initialSort = getDefaultSorter();
    return {
      tableOption: {
        layout: "fitData",
        columns,
        initialSort: [
          { column: initialSort.field, dir: initialSort.direction },
        ],
      },
    } as any;
  }, [columns, getDefaultSorter]);

  useEffect(() => {
    if (tableReady) {
      const table = tableRef.current?.getInstance();
      const rows = wrapDataInRows(strategies, tags);

      table?.insertData(rows);
    }
  }, [strategies, tableReady, tags]);

  return strategies.length ? (
    <Card className={styles.tableCard} sx={{ boxShadow: 3 }}>
      <CardContent className={styles.tableCard__content}>
        <TrendratingTableV2
          ref={tableRef}
          tableOptions={tableSetup}
          tableEvents={tableEvents}
        />
      </CardContent>
    </Card>
  ) : (
    <></>
  );
};

const FiltersColumn = ({ strategies, filterFn }: FiltersColumProps) => {
  const environment = useEnvironment();
  const setup = useMemo(() => environment.get("setup"), [environment]);
  const tags = useMemo(() => {
    const DISPLAY_KEY = "catalog";
    const fitleredTags: Tags = [];

    let canDisplayTag = false;

    for (const tag of setup.tags) {
      canDisplayTag = tag.display.indexOf(DISPLAY_KEY) > -1;

      if (canDisplayTag) {
        fitleredTags.push(tag);
      }
    }

    return fitleredTags;
  }, [setup.tags]);

  const fitlers = useMemo(() => {
    const filtersValuesMap = {};

    for (const strategy of strategies) {
      for (let key in strategy.info) {
        // Makes a map of the values without duplicates to understand which filters has at least one strategy
        // if a filter as no strategy the filter is not displayed
        filtersValuesMap[strategy.info[key]] = true;
      }
    }

    const availableFilters: any = [];

    for (const tag of tags) {
      const filterSection: any = {
        label: tag.name,
        groupId: tag.id,
        checkboxes: [],
      };

      for (const filter of tag.filters) {
        // Check if the value of the filter has at least one strategy that match
        if (filtersValuesMap[filter.id]) {
          filterSection.checkboxes.push({
            label: filter.name,
            value: filter.id,
          });
        }
      }

      if (filterSection.checkboxes.length) {
        availableFilters.push(filterSection);
      }
    }

    return availableFilters;
  }, [strategies, tags]);

  return (
    <Box className={styles.main__card__content__section__content__filters}>
      {fitlers.map(
        (filter) => (
          <FilterCard key={uuidv4()} filterFn={filterFn} filter={filter} />
        ),
        []
      )}
    </Box>
  );
};

const FilterCard = ({ filter, filterFn }: FilterCardProps) => {
  const ctx = useContext(InsightsContext);
  const initCheckboxes = useMemo(() => {
    const currentState = ctx.get();

    if (currentState.catalogState.activeFilters.length > 0) {
      const checkboxes: any = [];

      for (const f of currentState.catalogState.activeFilters) {
        if (f.level === filter.groupId) {
          checkboxes.push(...f.filters);
        }
      }

      return checkboxes;
    }

    return [];
  }, [ctx, filter.groupId]);
  const [activeCheckboxes, setActiveCheckboxes] =
    useState<string[]>(initCheckboxes);

  const onChangeCheckbox = useCallback(
    (e, checked, value) => {
      if (checked) {
        const newList = [...activeCheckboxes];

        newList.push(value);
        setActiveCheckboxes(newList);
        filterFn({ level: filter.groupId, values: [...newList] });
      } else {
        const newList = activeCheckboxes.filter((item) => item !== value);
        setActiveCheckboxes(newList);
        filterFn({ level: filter.groupId, values: [...newList] });
      }
    },
    [activeCheckboxes, filter.groupId, filterFn]
  );

  return (
    <Card sx={{ overflow: "visible" }}>
      <CardContent sx={{ display: "flex", padding: 1 }}>
        <Box display={"flex"} flex={1} flexDirection={"column"}>
          <Box display={"flex"} flex={1} alignItems={"center"}>
            <Typography sx={{ color: "#2a7090", fontWeight: "bold" }} gap={1}>
              {filter.label}
            </Typography>
          </Box>

          <Box display={"flex"} flexDirection={"column"}>
            {filter.checkboxes.map((checkbox) => {
              return (
                <FormControlLabel
                  key={uuidv4()}
                  control={
                    <Checkbox
                      checked={activeCheckboxes.indexOf(checkbox.value) > -1}
                      size={"small"}
                      onChange={(event, checked) =>
                        onChangeCheckbox(event, checked, checkbox.value)
                      }
                    />
                  }
                  label={checkbox.label}
                />
              );
            })}
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

const FilterShortcut = ({
  currentFitlers,
  removeFilter,
  isUniverseEmpty,
}: FilterShortcutProps) => {
  const environment = useEnvironment();
  const tags = useMemo(() => environment.get("setup").tags, [environment]);

  const activeFilters = useMemo(() => {
    const filters = currentFitlers;

    if (filters.length) {
      let activeFilters: { value: string; groupId: string; name: string }[] =
        [];
      let groupId: string;
      let tag: Tag;
      let tagName = "";

      const findTag = (item) => item.id === groupId;

      for (const filter of filters) {
        groupId = filter.level;
        tag = tags.find(findTag);

        for (const f of filter.filters) {
          tagName = tag.filters.find((item) => item.id === f)?.name ?? "";
          activeFilters.push({ groupId, value: f, name: tagName });
        }
      }

      return activeFilters;
    }

    return [];
  }, [currentFitlers, tags]);

  const handleReleaseFilter = useCallback(
    (value, groupId) => {
      const filters = currentFitlers;
      let updatedFilter: any = null;

      for (const filter of filters) {
        if (filter.level === groupId) {
          updatedFilter = {
            level: groupId,
            values: filter.filters.filter((item) => item !== value),
          };

          break;
        }
      }

      removeFilter(updatedFilter);
    },

    [currentFitlers, removeFilter]
  );

  return activeFilters.length ? (
    <Stack
      direction={"row"}
      spacing={1}
      sx={{ minWidth: 0, overflow: "auto", overflowY: "hidden" }}
    >
      {activeFilters.map((filter) => (
        <Chip
          sx={!isUniverseEmpty ? { border: "none!important" } : {}}
          key={uuidv4()}
          label={filter.name}
          variant="outlined"
          onDelete={() => handleReleaseFilter(filter.value, filter.groupId)}
        />
      ))}
    </Stack>
  ) : (
    <></>
  );
};

class PDF {
  rawData: CatalogDashboard;
  environment: AppEnvironment;
  getWysiwyg: () => {
    sorter: { field: string; rev: boolean };
    activeFilters: any;
  };
  formatter: Formatter;
  reportAPI: Report;
  widgets: any;

  constructor(
    jsonData: CatalogDashboard,
    environment: AppEnvironment,
    getUIParams: () => {
      sorter: { field: string; rev: boolean };
      activeFilters: any;
    }
  ) {
    this.rawData = jsonData;
    this.environment = environment;
    this.getWysiwyg = getUIParams;
    this.formatter = new Formatter(this.environment);
    this.reportAPI = new Report(this.environment);

    const asOfDate = jsonData?.[0]?.date ?? "";

    this.widgets = {
      title: {
        data: {
          text: "Strategy Directory",
        },
        type: "title",
      },
      subtitle: {
        data: {
          text: `Strategy anlytics as of ${asOfDate}`,
        },
        type: "header1",
      },
      pageBreak: {
        data: null,
        type: "pageBreak",
      },
      space: {
        data: {
          text: "<br/><br/>",
        },
        type: "text",
      },
      tableTitle: {
        data: {
          text: "SCREENING",
        },
        type: "header1",
      },
      table: {
        data: {
          body: [[]],
          head: [
            [
              { style: null, value: "" },
              { style: null, value: "" },
              { style: null, value: "" },
              { style: null, value: "10 Years" },
              { style: null, value: "" },
              { style: null, value: "YTD" },
              { style: null, value: "" },
              { style: null, value: "" },
              { style: null, value: "" },
              { style: null, value: "" },
            ],
            [
              {
                style: { width: "1000px" },
                value: "Type",
              },
              {
                style: { width: "1000px" },
                value: "Id",
              },
              {
                style: null,
                value: "Name",
              },
              {
                style: null,
                value: "Annualized Return",
              },
              {
                style: null,
                value: "Excess Return",
              },
              {
                style: null,
                value: "Cumulative Return",
              },
              {
                style: null,
                value: "Excess Return",
              },
              {
                style: null,
                value: "Sharpe Ratio",
              },
              {
                style: null,
                value: "Avg Yearly Drawdown",
              },
              {
                style: null,
                value: "Turnover",
              },
            ],
          ],
        },
        type: "table",
      },
      disclaimer: {
        data: {
          text: "",
        },
        type: "text",
      },
    };
  }

  getUserPreference() {
    const preferences =
      this.environment.account.user?.preferences?.preferences ?? null;

    let userPreference = preferences?.report?.general ?? null;
    if (userPreference == null) {
      // Prepare default values
      userPreference = {
        disclaimer: null,
        logo: null,
        theme: null,
      };
    }
    userPreference["theme"] = themeBlueAndGrey;

    return userPreference;
  }

  async preparePrintParams() {
    let hasCover = false;
    let title = "Insights on key fundamental parameters";

    const fileName = "Trendrating - " + title.replace("/", "_") + ".pdf";

    const params = {
      content: await this.buildReportContent(),
      cover: hasCover,
      fileName: fileName,
      meta: {
        author: "Trendrating",
        title: title,
      },
      orientation: "portrait",
      headerConfig: {
        logo: "small",
        date: true,
      },
      userPreference: this.getUserPreference(),
    };

    return params;
  }

  async buildReportContent() {
    const content: any = [];

    this.addSpace(content);
    this.addTitle(content);
    this.addSubTitle(content);
    this.addTable(content);
    this.addSpace(content);
    await this.addDisclaimer(content);

    return content;
  }

  addPageBreak(content) {
    content.push(this.widgets.pageBreak);
  }

  addSpace(content) {
    content.push(deepClone(this.widgets.space));
  }

  addTitle(content) {
    const titleWget = deepClone(this.widgets.title);

    let text = titleWget.data.text;

    const activeFilters = this.getWysiwyg().activeFilters;
    const length = activeFilters.length;

    if (length) {
      const tags = this.environment.tags;
      let tagGroup: any = null;
      let strValues: any = [];
      let voiceObj: any = null;
      let i = 0;

      for (const filter of activeFilters) {
        strValues = [];

        tagGroup = tags.find((tag) => tag.id === filter.level);

        if (tagGroup) {
          for (const id of filter.filters) {
            voiceObj = tagGroup.filters.find((value) => value.id === id);

            if (voiceObj) {
              strValues.push(voiceObj.name);
            }
          }
        }

        text += ` (${strValues.join(",")})`;

        if (i < length - 1) {
          text += " - ";
        }

        i++;
      }

      titleWget.data.text = text;
    }

    content.push(titleWget);
  }

  addSubTitle(content) {
    content.push(deepClone(this.widgets.subtitle));
  }

  addTableTitle(content, label) {
    const tableTitleWget = deepClone(this.widgets.tableTitle);

    tableTitleWget.data.text = label;

    content.push(deepClone(tableTitleWget));
  }

  addTable(content) {
    const data = this.rawData;

    if (data) {
      let tableBody: any = [];
      let row: any = [];
      let tableWget: any = null;

      tableWget = deepClone(this.widgets.table);

      tableBody = [];
      const strategies = wrapDataInRows(data, this.environment.tags);

      this.applySort(strategies);

      for (const strategy of strategies) {
        row = [];

        row.push({
          style: null,
          value: strategy.type.join(", "),
        });
        row.push({ style: null, value: strategy.syntheticId });
        row.push({
          style: null,
          value: format(this.formatter, "name", strategy.name),
        });
        row.push({
          style: null,
          value: format(
            this.formatter,
            COLUMNS_FIELDS_DICT["tenYearsReturn"],
            strategy[COLUMNS_FIELDS_DICT["tenYearsReturn"]]
          ),
        });
        row.push({
          style: null,
          value: format(
            this.formatter,
            COLUMNS_FIELDS_DICT["tenYearsReturnDiff"],
            strategy[COLUMNS_FIELDS_DICT["tenYearsReturnDiff"]]
          ),
        });
        row.push({
          style: null,
          value: format(
            this.formatter,
            COLUMNS_FIELDS_DICT["ytd"],
            strategy[COLUMNS_FIELDS_DICT["ytd"]]
          ),
        });
        row.push({
          style: null,
          value: format(
            this.formatter,
            COLUMNS_FIELDS_DICT["ytdDiff"],
            strategy[COLUMNS_FIELDS_DICT["ytdDiff"]]
          ),
        });
        row.push({
          style: null,
          value: format(
            this.formatter,
            COLUMNS_FIELDS_DICT["sharpeRatio"],
            strategy[COLUMNS_FIELDS_DICT["sharpeRatio"]]
          ),
        });
        row.push({
          style: null,
          value: format(
            this.formatter,
            COLUMNS_FIELDS_DICT["drawdown"],
            strategy[COLUMNS_FIELDS_DICT["drawdown"]]
          ),
        });
        row.push({
          style: null,
          value: format(
            this.formatter,
            COLUMNS_FIELDS_DICT["turnover"],
            strategy[COLUMNS_FIELDS_DICT["turnover"]]
          ),
        });

        tableBody.push([...row]);
      }

      tableWget.data.body = [...tableBody];
      content.push(deepClone(tableWget));

      this.addPageBreak(content);
    }
  }

  async addDisclaimer(content) {
    const disclaimerWget = deepClone(this.widgets.disclaimer);

    let disclaimerText: any = this.getUserPreference().disclaimer;

    if (disclaimerText == null) {
      disclaimerText = await this.reportAPI.disclaimer();
    }

    disclaimerWget.data.text = disclaimerText;

    content.push(disclaimerWget);
  }

  async print() {
    try {
      const params = await this.preparePrintParams();

      const report = this.reportAPI;

      const response = await report.print(params);
      this.download(response, params.fileName);
    } catch (error: any) {
      console.log(error);
    }
  }

  download(response, fileName) {
    const blob = new Blob([response], {
      type: "application/pdf",
    });

    let downloadLink: HTMLAnchorElement | null = null;

    if ((navigator as any).msSaveBlob) {
      // IE 11
      downloadLink = document.createElement("a");
      downloadLink.setAttribute("href", "#" + fileName);
      document.body.appendChild(downloadLink);

      downloadLink.addEventListener(
        "click",
        function (event) {
          (navigator as any).msSaveBlob(blob, fileName);
        },
        false
      );
    } else {
      downloadLink = document.createElement("a");

      const attributes = [
        { attr: "class", value: "a11y" },
        { attr: "href", value: window.URL.createObjectURL(blob) },
        { attr: "target", value: "_blank" },
      ];

      attributes.forEach((attribute) => {
        downloadLink?.setAttribute(attribute.attr, attribute.value);
      });

      document.body.appendChild(downloadLink);

      downloadLink.click();
      downloadLink.remove();
    }
  }

  applySort(target) {
    let sortField = "name";
    let direction = "desc";

    const wysiwyg = this.getWysiwyg();

    const sorter = wysiwyg?.sorter ?? null;

    if (sorter) {
      sortField = sorter.field;
      direction = sorter.rev ? "asc" : "desc";

      target.sort((a, b) => {
        a = a[sortField];
        b = b[sortField];

        if (a > b) {
          return direction === "asc" ? 1 : -1;
        } else if (a < b) {
          return direction === "asc" ? -1 : 1;
        }

        return 0;
      });
    }
  }
}
