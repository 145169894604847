import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { ColumnDefinition } from "tabulator-tables";
import {
  TableEventsV2,
  TableV2,
} from "../../../../../../components/table/v2/TableCoreV2";
import { TrendratingTableV2 } from "../../../../../../components/table/v2/TableV2";
import { useEnvironment } from "../../../../../../hooks/useEnvironment";
import { useFormatter } from "../../../../../../hooks/useFormatter";
import { NewsFilterETF } from "./NewsFilterETF";
import {
  formatTaxonPrefixingParent,
  getTaxonById,
} from "../../../../../../api/compute/Taxon";

type EtfWidgetProps = {
  data: any;
  etfProps: {
    stateChangeHandler: (filters: any) => void;
    saveHandler: (filters: any) => void;
  };
  accordionHandler: Function;
  accordionState: boolean;
};

const formatterTcr = (cell, formatter) => {
  const object = cell.getData();
  const value = cell.getValue();

  const tcr = [
    formatter.custom("rating", {
      options: {
        hasTrendArrow: false,
        notAvailable: {
          input: 0,
          output: "",
        },
      },
      output: "HTML",
      value: object["rrr"],
      valueHelper: {
        rateChange: null,
        rateDate: null,
        ratePrev: null,
      },
    }),
    '<span class="tTableAlerts-arrow i-arrow-r"></span>',
    formatter.custom("rating", {
      options: {
        hasTrendArrow: false,
        notAvailable: {
          input: 0,
          output: "",
        },
      },
      output: "HTML",
      value: value,
      valueHelper: {
        rateChange: null,
        rateDate: null,
        ratePrev: null,
      },
    }),
  ].join(" ");

  return tcr;
};

export function EtfWidget({
  data,
  etfProps,
  accordionHandler,
  accordionState,
}: EtfWidgetProps) {
  const [tableReady, setTableReady] = useState(false);

  const { saveHandler, stateChangeHandler } = etfProps;
  const formatter = useFormatter();
  const environment = useEnvironment();
  const tableRef = useRef<{ getInstance: () => TableV2 }>();

  const securityLink =
    environment.get("configuration")["configuration"]["pages"]["alerts"][
      "securityLink"
    ];

  const onRowClickHandler = useCallback(
    (event) => {
      if (securityLink !== "popup_instrument" && event.type === "rowClick") {
        const data = event.value.getData();
        const symbol = data?.symbol ?? null;
        if (symbol) {
          const url = `/app/analysis/instrument/${symbol}/`;
          window.location.href = url;
        }
      }
    },
    [securityLink]
  );

  const taxonomies = useMemo(() => {
    const taxon = environment.get("rawTaxonomies");
    const fields = environment.get("taxonomyFields");

    return {
      what: taxon[fields["ETF"].etfclass],
      where: taxon[fields["ETF"].etfgeo],
    };
  }, [environment]);

  const columns: ColumnDefinition[] = useMemo(() => {
    return [
      {
        field: "ticker",
        title: "Ticker",
        resizable: false,
        formatter: (cell) => {
          return (
            formatter.table(cell.getField(), "table", cell.getData()) ?? ""
          );
        },
        widthGrow: 1,
        sorter: "string",
      },
      {
        field: "name",
        title: "Name",
        resizable: false,
        formatter: (cell) => {
          return (
            formatter.table(cell.getField(), "table", cell.getData()) ?? ""
          );
        },
        widthGrow: 3,
        sorter: "string",
      },
      {
        field: "rc",
        resizable: false,
        title: "Rating",
        formatter: (cell) => formatterTcr(cell, formatter),
        sorter: "number",
      },
      {
        field: "etfclass",
        title: "Asset Class",
        resizable: false,
        formatter: (cell) => {
          const value = cell.getValue();
          const taxonomy = taxonomies.what;

          return formatTaxonPrefixingParent(
            getTaxonById(value, [taxonomy], null),
            [taxonomy],
            "4 Subsector"
          );
        },
        sorter: (a, b) => {
          const taxonomy = taxonomies.what;
          a = formatTaxonPrefixingParent(
            getTaxonById(a, [taxonomy], null),
            [taxonomy],
            "4 Subsector"
          );
          b = formatTaxonPrefixingParent(
            getTaxonById(b, [taxonomy], null),
            [taxonomy],
            "4 Subsector"
          );

          if (a > b) {
            return 1;
          } else if (a < b) {
            return -1;
          }

          return 0;
        },
      },
      {
        field: "etfgeo",
        title: "Inv. Region",
        resizable: false,
        sorter: (a, b) => {
          a = taxonomies.where[a].name.toLowerCase();
          b = taxonomies.where[b].name.toLowerCase();

          if (a > b) {
            return 1;
          } else if (a < b) {
            return -1;
          }

          return 0;
        },
      },
    ];
  }, [formatter, taxonomies.what, taxonomies.where]);

  useEffect(() => {
    if (tableReady) {
      const table = tableRef.current?.getInstance();
      table?.insertData(data);
    }
  }, [data, tableReady]);

  const tableOptions = useMemo(() => {
    return {
      tableOption: {
        columns,
        ajaxSorting: false,
        movableColumns: false,
        resizableColumns: false,
      },
    };
  }, [columns]);

  const tableEvents: TableEventsV2 = useMemo(
    () => ({
      onTableBuilt: () => setTableReady(true),
      rowClick: onRowClickHandler,
      onTableDestroyed: () => {
        setTableReady(false);
      },
    }),
    [onRowClickHandler]
  );

  return (
    <div className="tNews-item">
      <div className="tNews-accordionTitle" onClick={() => accordionHandler()}>
        <h2 className="tNews-accordionTitle-title">
          ETFs
          <div className="tooltip-wrapper-icon"></div>
        </h2>
        <span
          className={`i-${accordionState ? "down" : "up"} tNews-accordionIcon`}
        ></span>
      </div>

      {accordionState && (
        <div data-dojo-attach-point="nodeETFAccordionContainer">
          <div>
            <NewsFilterETF
              setFiltersETF={stateChangeHandler}
              saveFiltersETF={saveHandler}
            />
          </div>

          {data && data.length ? (
            <div className="table-with-filters">
              <TrendratingTableV2
                ref={tableRef}
                tableEvents={tableEvents}
                tableOptions={tableOptions}
                rowTooltipFormatter
              />
            </div>
          ) : (
            <div className="empty-news-data">No alert found</div>
          )}
        </div>
      )}
    </div>
  );
}
