import React, { ReactElement } from "react";

type ListItemProps = {
  content: string | ReactElement;
  value: string | number;
  onDragStart: (e: React.DragEvent<HTMLDivElement>) => void;
  onDragOver: (e: React.DragEvent<HTMLDivElement>) => void;
  onDragEnd: (e: React.DragEvent<HTMLDivElement>) => void;
};
export default function ListItem({
  content,
  value,
  onDragStart,
  onDragOver,
  onDragEnd,
}: ListItemProps) {
  return (
    <div
      data-value={value}
      draggable
      style={{ cursor: "grab", width: "100%", overflow: "hidden" }}
      onDragStart={onDragStart}
      onDragOver={onDragOver}
      onDragEnd={onDragEnd}
    >
      {content}
    </div>
  );
}
