import { ClusterAnalytics } from "../../../api/compute/ClusterAnalytics";
import { Instruments } from "../../../api/compute/Instruments";
import { Peers } from "../../../api/compute/Peers";
import {
  formatTaxonPrefixingParent,
  getChildrenAtLevel,
  getTaxonById,
} from "../../../api/compute/Taxon";
import { addQueryParam, decodePeerId, encodePeerId } from "../../../api/utils";
import { deepClone } from "../../../deepClone";
import { AppEnvironment } from "../../../types/Defaults";
import { PeerDetailStorage } from "./PeerDetailStorage";

function sortBy(property, descending = false) {
  return (a, b) => {
    try {
      const valueA = property.split(".").reduce((item, prop) => item[prop], a);
      const valueB = property.split(".").reduce((item, prop) => item[prop], b);

      if (valueA === null) {
        return 1;
      }

      if (valueB === null) {
        return -1;
      }

      if (valueA > valueB) {
        return descending ? -1 : 1;
      }
      if (valueA < valueB) {
        return descending ? 1 : -1;
      }
      return 0;
    } catch (error) {
      console.log(error);
      return 0;
    }
  };
}

export class MarketsStorage {
  clusterAPI: ClusterAnalytics;
  peersAPI: Peers;
  environment: AppEnvironment;
  instrumentsAPI: Instruments;

  constructor(environment: AppEnvironment) {
    this.clusterAPI = new ClusterAnalytics(environment);
    this.instrumentsAPI = new Instruments(environment);
    this.peersAPI = new Peers(environment);
    this.environment = environment;
  }

  state: any = null;

  threshold = {
    abPercentage: {
      cardinality: 1,
    },
    children: {
      cardinality: 1,
    },
    tcr: {
      cardinality: 1,
    },
    upgradesDowngrades: {
      cardinality: 1,
    },
  };

  stateHistory: any = null;

  async get(params) {
    var state = this.state;
    var isPivotingOnAnalytic = this.isPivotingOnAnalytic(
      state == null ? null : state["paramsTable"],
      params
    );
    var isPivotingOnSize =
      // params.instrumentType !== "ETF" ?
      this.isPivotingOnSize(
        state == null ? null : state["paramsTable"],
        params
      );
    // : false;

    var isSortBy = this.isSortBy(
      state == null ? null : state["paramsTable"],
      params
    );

    // reset or clone (and merge)
    if (
      isPivotingOnAnalytic === false &&
      isPivotingOnSize === false &&
      isSortBy === false
    ) {
      this.state = this.statePrototype();
    } else {
      this.state = deepClone(this.state); // like redux
    }

    this.state["paramsTable"] = params;

    if (isPivotingOnAnalytic === true) {
      // not need requests
      return this.state;
    }

    try {
      let response = await this.getWhereWhat(params, isPivotingOnSize);
      let result = await this.prepareWhereWhat(
        params,
        isPivotingOnSize,
        response
      );
      response = await this.getWhereWhatCross(params, result);
      result = this.prepareWhereWhatCross(params, response);

      return result;
    } catch (error) {
      console.log(error);
    }
  }

  // params are the same of get()
  prepareWhereWhatCross(params, response) {
    for (let i = 0, length = response.length; i < length; i++) {
      this.state["table"]["body"][i] = this.state["table"]["body"][i].concat(
        this.setName(params, response[i])
      );

      this.state.storeData = this.state.storeData.concat(response[i]);
    }

    this.state.store = this.state.storeData;

    return this.state;
  }

  async getWhereWhatCross(params, state) {
    var instrumentType = params["instrumentType"];
    var zDimension = params["zDimension"];
    var what = state["matrix"]["what"];
    var where = state["matrix"]["where"];

    var _params: any = [];
    var _paramsRow: any = null;
    for (let i = 0, lengthI = what.length; i < lengthI; i++) {
      _paramsRow = [];
      for (let j = 0, lengthJ = where.length; j < lengthJ; j++) {
        _paramsRow.push({
          zDimension,
          type: instrumentType,
          what: what[i]["what"],
          where: where[j]["where"],
        });
      }
      _params.push(_paramsRow);
    }

    return await this.peersAPI.get(_params);
  }

  //
  // params are the same of get()
  //
  // isPivotingOnSize: if true, threshold check sort must not be
  // applied
  //
  prepareWhereWhat(params, isPivotingOnSize, response) {
    var where = response[0];
    var what = response[1];

    var analyticType = params["analyticType"];
    var threshold = this.threshold[analyticType]["cardinality"];

    // prepare where
    var peersWhere: any = [];

    for (const peer of where) {
      if (isPivotingOnSize) {
        peersWhere.push(peer);
      } else if (
        peer["info"]["cardinality"] &&
        peer["info"]["cardinality"] >= threshold
      ) {
        peersWhere.push(peer);
      }
    }

    // prepare what
    var peersWhat: any = [];

    for (const peer of what) {
      if (
        peer["info"]["cardinality"] &&
        peer["info"]["cardinality"] >= threshold
      ) {
        peersWhat.push(peer);
      }
    }

    // adding name information: used to sort by name
    peersWhat = this.setName(params, peersWhat);
    peersWhere = this.setName(params, peersWhere);

    // sort by
    const peerType = params.instrumentType;
    const xLevel = params.whereType;
    const sortXProperty = params.whereSortBy.property;
    const sortXDirection = params.whereSortBy.descending; // Boolean

    if (isPivotingOnSize === false) {
      peersWhere = peersWhere.map((p) =>
        this.extendingPeerWithWhereInfoForSorting(p)
      );
      if (peerType !== "ETF") {
        peersWhat = this.sortBy(params["whatSortBy"], peersWhat);
      }
      peersWhere = this.sortBy(params["whereSortBy"], peersWhere);
      // peersWhere fix for Developed / Emerging / Frontier sort
      // if there is not this check, the market table will appear
      // completely mixed (areas, in respect to the regions)

      // TODO do not use directly the codes, but use something more
      // dynamic, like taxonomy + parent order
      peersWhere.sort((a, b) => {
        if (peerType !== "ETF") {
          // TODO hardcoded, please use parent order if possible
          // Order: R_D - R_E - R_F
          const whereA = a?.where?.slice(0, 3) ?? "";
          const whereB = b?.where?.slice(0, 3);

          if (whereA === whereB) {
            return 0;
          }

          if (whereA === "WWW") {
            return -1;
          }

          if (whereA === "M_D") {
            return -1;
          }

          if (whereA === "M_E") {
            if (whereB === "R_E") {
              return -1;
            } else {
              return 1;
            }
          }

          // if the where from both are different, ...
          if (whereA === "R_D") {
            return -1; // always before R_D and R_F
          }
          if (whereB === "R_D") {
            return 1; // always after others
          }

          // Now whereA and whereB cannot be R_D
          if (whereA === "R_E") {
            return -1; // always before R_F
          }
          if (whereB === "R_E") {
            return 0; // "a" is R_F
          }

          // Cannot go here, just return 0
          return 0;
        } else {
          if (sortXProperty === "default") {
            if (xLevel === "Region") {
              return this.sortBroadNodesFirstInRegion(
                a,
                b,
                sortXProperty,
                sortXDirection
              );
            } else {
              return this.sortBroadNodesFirst(
                a,
                b,
                sortXProperty,
                sortXDirection
              );
            }
          } else {
            if (xLevel === "Region") {
              return this.sortRegionByProperty(
                a,
                b,
                sortXProperty,
                sortXDirection
              );
            } else {
              return this.sortByProperty(a, b, sortXProperty, sortXDirection);
            }
          }
        }
      });
    }

    this.state["matrix"]["what"] = peersWhat;
    this.state["matrix"]["where"] = peersWhere;

    // first cell of head is null. It is the column for what
    this.state["table"]["head"] = [[null].concat(peersWhere)];
    this.state["table"]["body"] = [];
    for (let i = 0, length = peersWhat.length; i < length; i++) {
      this.state["table"]["body"].push([peersWhat[i]]);
    }

    this.state.storeData = [].concat(peersWhat).concat(peersWhere);

    return this.state;
  }

  sortBroadNodesFirstInRegion(a, b, sortingProperty, descending) {
    const aIsDeveloped = a.taxonomyType === "developed";
    const bIsDeveloped = b.taxonomyType === "developed";

    if (aIsDeveloped && !bIsDeveloped) {
      return -1;
    }

    if (bIsDeveloped && !aIsDeveloped) {
      return 1;
    }

    return this.sortBroadNodesFirst(a, b, sortingProperty, descending);
  }

  sortBroadNodesFirst(a, b, sortingProperty, descending) {
    const aIsBroad = a.isBroad;
    const bIsBroad = b.isBroad;

    if (aIsBroad && !bIsBroad) {
      return descending ? -1 : 1;
    } else if (bIsBroad && !aIsBroad) {
      return descending ? 1 : -1;
    }

    if (aIsBroad && bIsBroad) {
      const aIsDeveloped = a.taxonomyType === "developed";
      const bIsDeveloped = b.taxonomyType === "developed";

      if (aIsDeveloped && !bIsDeveloped) {
        return descending ? -1 : 1;
      }

      if (bIsDeveloped && !aIsDeveloped) {
        return descending ? 1 : -1;
      }
    }

    // ? As default we decide to sort by cardinality if others conditions are not verified
    return this.sortByProperty(a, b, sortingProperty, descending);
  }

  sortRegionByProperty(a, b, sortingProperty, descending) {
    const aIsDeveloped = a.taxonomyType === "developed";
    const bIsDeveloped = b.taxonomyType === "developed";

    if (aIsDeveloped && !bIsDeveloped) {
      return -1;
    }

    if (bIsDeveloped && !aIsDeveloped) {
      return 1;
    }

    return this.sortByProperty(a, b, sortingProperty, descending);
  }

  /**
   *
   * @param {*object} a
   * @param {*object} b
   * @param {*string} property "marketcap" | "name" | "tcr" | "cardinality"
   * @returns
   */
  sortByProperty(a, b, property, descending) {
    let aElement = a;
    let bElement = a;

    switch (property) {
      case "name":
        aElement = a.name;
        bElement = b.name;

        break;
      case "marketcap":
        aElement = a.info.marketcap;
        bElement = b.info.marketcap;

        break;
      case "downgrades": {
        aElement = a.statistic.numberDowngrade;
        bElement = b.statistic.numberDowngrade;

        break;
      }
      case "tcr": {
        aElement = a.tcr.today;
        bElement = b.tcr.today;

        break;
      }

      case "upgrades": {
        aElement = a.statistic.numberUpgrade;
        bElement = b.statistic.numberUpgrade;

        break;
      }

      default:
        aElement = a.info.cardinality;
        bElement = b.info.cardinality;
    }

    if (aElement > bElement) {
      return descending ? -1 : 1;
    } else if (aElement < bElement) {
      return descending ? 1 : -1;
    }

    return 0;
  }

  sortBy(sortByObject, peers) {
    var descending = sortByObject["descending"];
    var property: any = null;

    switch (sortByObject["property"]) {
      case "downgrades": {
        property = "statistic.numberDowngrade";

        break;
      }
      case "default":
      case "marketcap": {
        property = "info.marketcap";

        break;
      }
      case "name": {
        property = "name";

        break;
      }
      case "tcr": {
        property = "tcr.today";

        break;
      }

      case "upgrades": {
        property = "statistic.numberUpgrade";

        break;
      }

      case "topBottom_top": {
        property = "statistic.dispersion.pq.top";

        break;
      }

      case "topBottom_bottom": {
        property = "statistic.dispersion.pq.bottom";
      }

      // no default
    }

    const toBeSorted = deepClone(peers);
    return toBeSorted.sort(sortBy(property, descending));
  }

  extendingPeerWithWhereInfoForSorting(peer) {
    const id = peer.where;
    const isBroad = id.slice(-2) === "_B"; // is broad if taxonomy name id's last 2 charachters are equal to "_B"
    let taxonomyType: any = null;

    switch (id.slice(1, 3)) {
      case "_D":
        taxonomyType = "developed";

        break;

      case "_E":
        taxonomyType = "emerging";
        break;
      case "_F":
        taxonomyType = "frontier";

        break;
      default:
        break;
    }
    peer["isBroad"] = isBroad;
    peer["taxonomyType"] = taxonomyType;

    return peer;
  }

  setName(params, peers) {
    var instrumentType = params["instrumentType"].toLowerCase();
    var taxon: any = null;
    var taxonomies = this.environment["taxonomies"];
    const fieldMap = this.environment["taxonomyFields"];
    const whatRootNode = Object.values<any>(
      taxonomies[
        fieldMap[instrumentType === "etf" ? "ETF" : "security"][
          instrumentType === "etf" ? "etfclass" : "sector"
        ]
      ]
    ).find((node) => node.parent === null)["id"];
    const whereRootNode = Object.values<any>(
      taxonomies[
        fieldMap[instrumentType === "etf" ? "ETF" : "security"][
          instrumentType === "etf" ? "etfgeo" : "country"
        ]
      ]
    ).find((node) => node.parent === null)["id"];
    let field: any = null;
    for (const peer of peers) {
      if (peer["what"] === whatRootNode && peer["where"] === whereRootNode) {
        peer["name"] = "__ROOT__"; // special name: managed by render
      } else if (
        peer["what"] === whatRootNode &&
        peer["where"] !== whereRootNode
      ) {
        // where only
        field =
          fieldMap[instrumentType === "etf" ? "ETF" : "security"][
            instrumentType === "etf" ? "etfgeo" : "country"
          ];
        taxon = Object.values<any>(taxonomies[field]).find(
          (item) => item.id === peer["where"]
        );
        peer["name"] = taxon["name"];
      } else if (
        peer["what"] !== whatRootNode &&
        peer["where"] === whereRootNode
      ) {
        // what only
        field =
          fieldMap[instrumentType === "etf" ? "ETF" : "security"][
            instrumentType === "etf" ? "etfclass" : "sector"
          ];
        taxon = Object.values<any>(taxonomies[field]).find(
          (item) => item.id === peer["what"]
        );
        peer["name"] = taxon["name"];
      } else {
        // cross: using , as separator. The UI can split the name
        // in substring for formatting purposes
        const whereField =
          fieldMap[instrumentType === "etf" ? "ETF" : "security"][
            instrumentType === "etf" ? "etfgeo" : "country"
          ];
        const whatField =
          fieldMap[instrumentType === "etf" ? "ETF" : "security"][
            instrumentType === "etf" ? "etfclass" : "sector"
          ];
        peer["name"] = [
          Object.values<any>(taxonomies[whereField]).find(
            (item) => item.id === peer["where"]
          )["name"],
          Object.values<any>(taxonomies[whatField]).find(
            (item) => item.id === peer["what"]
          )["name"],
        ].join(",");
      }
    }

    return peers;
  }

  /**
   * Decode a peerId in order to be suitable for API requests
   *
   * @param {string} peerId - the peer ID. It is a string with this
   *      structure
   *
   *          where-what-instrumentType-size
   *
   *      e.g. CH-50-Stock-microLarge
   *
   * @returns {object}
   */
  decodeId(peerId) {
    return decodePeerId(peerId);
  }

  //
  // Get the two axis of the matrix
  // These are used  for futher requests or for sorting purposes (client
  // side)
  //
  // To avoid to perform 2 requests, this request has two rows:
  //  1 - where (using MarketFinancial taxonony)
  //  2 - what (using ICB(GICS)/ETFclassification taxonomy)
  //
  // params are the same of get()
  //
  // isPivotingOnSize: if true, the request must use the "where" in
  // state.matrix
  //
  async getWhereWhat(params, isPivotingOnSize) {
    var instrumentType = params["instrumentType"];
    var zDimension = params["zDimension"];
    var taxonomies = this.environment["taxonomies"];
    var fieldMap = this.environment["taxonomyFields"];
    var whatType = params["whatType"];
    var whereType = params["whereType"];
    let field: any = null;
    // where
    var where: any = [];
    if (isPivotingOnSize === true) {
      where = this.state["matrix"]["where"].map((item) => {
        var decodedId = this.decodeId(item["id"]);
        return {
          id: decodedId["where"],
        };
      });
    } else {
      field =
        fieldMap[instrumentType === "ETF" ? "ETF" : "security"][
          instrumentType === "ETF" ? "etfgeo" : "country"
        ];
      where = Object.values<any>(taxonomies[field]).filter(
        (item) => item.type === whereType && item.showInTree
      );

      const pageConfiguration =
        this.environment["account"]?.product?.configuration?.analysis_market;
      const availableNodes = pageConfiguration?.countryRoots;

      if (availableNodes.length && whereType === "Country") {
        const availableChildren: any = [];

        const traversingTree = (node) =>
          Object.values<any>(taxonomies[field])
            .filter((item) => item.parent === node)
            .map((child) => {
              if (child.showInTree === true) {
                availableChildren.push(child.id);
              }
              return traversingTree(child.id);
            });

        for (const node of availableNodes) {
          traversingTree(node);
        }

        where = where.filter((item) => availableChildren.includes(item.id));
      }

      var user: any = this.environment["account"]["user"];

      if (
        whereType === "Country" &&
        user["preferences"] != null &&
        user["preferences"]["preferences"] != null &&
        user["preferences"]["preferences"]["analysisMarket"] != null &&
        user["preferences"]["preferences"]["analysisMarket"]["where"][
          "markets"
        ] != null &&
        user["preferences"]["preferences"]["analysisMarket"]["where"]["markets"]
          .length !== 0 &&
        instrumentType !== "ETF"
      ) {
        const userPreferencesNodes =
          user["preferences"]["preferences"]["analysisMarket"]["where"][
            "markets"
          ];
        where = [];

        let childrenNodes = [];

        userPreferencesNodes.forEach((node) => {
          childrenNodes = getChildrenAtLevel(
            node,
            "Country",
            taxonomies[field]
          );

          where = where.concat(childrenNodes);
        });
      }
    }

    const peerType = instrumentType === "ETF" ? "ETF" : "security";
    const fieldY = peerType === "ETF" ? "etfclass" : "icb";
    const fieldX = peerType === "ETF" ? "etfgeo" : "country";
    // The what root node
    const rootNodeY = Object.values<any>(
      taxonomies[fieldMap[peerType][fieldY]]
    ).find((node) => node.parent == null)["id"];
    const rootNodeX = Object.values<any>(
      taxonomies[fieldMap[peerType][fieldX]]
    ).find((node) => node.parent == null)["id"];

    var paramsWhere: any = [];
    for (let i = 0, length = where.length; i < length; i++) {
      paramsWhere.push({
        zDimension,
        type: instrumentType,
        what: rootNodeY,
        where: where[i]["id"],
      });
    }
    // what
    field =
      fieldMap[instrumentType === "ETF" ? "ETF" : "security"][
        instrumentType === "ETF" ? "etfclass" : "sector"
      ];
    var what = Object.values<any>(taxonomies[field])
      .filter((item) => item.type === whatType && item.showInTree)
      .sort(sortBy("name"));

    var paramsWhat: any = [];
    for (let i = 0; i < what.length; i++) {
      paramsWhat.push({
        zDimension,
        type: instrumentType,
        what: what[i]["id"],
        where: rootNodeX,
      });
    }

    return await this.peersAPI.get([paramsWhere, paramsWhat]);
  }

  statePrototype() {
    return {
      // matrix: containt filtered and sorted where and what used
      // to requests all cells of the table
      matrix: {
        what: null,
        where: null,
      },

      // parameters that define the current state of peer/where page
      paramsPeer: null,

      // parameters that define the current state of peer table
      paramsTable: null,

      // contains renderable table data
      table: {
        body: null, // array of array
        head: null, // array of array
      },

      // At the end of the requests chain, it is containing all peers.
      store: null,

      // An array with all peers to be used to create the data store.
      // Peers are concatened at the of each step of data preparation
      storeData: null,
    };
  }

  /**
   * Check
   */
  isPivotingOnAnalytic(oldParams, newParams) {
    if (
      oldParams != null &&
      //
      // analyticKey is the discriminant
      //
      newParams["analyticKey"] !== oldParams["analyticKey"] &&
      //
      //
      newParams["instrumentType"] === oldParams["instrumentType"] &&
      newParams["size"] === oldParams["size"] &&
      newParams["whatSortBy"]["descending"] ===
        oldParams["whatSortBy"]["descending"] &&
      newParams["whatSortBy"]["property"] ===
        oldParams["whatSortBy"]["property"] &&
      newParams["whatType"] === oldParams["whatType"] &&
      newParams["whereSortBy"]["descending"] ===
        oldParams["whereSortBy"]["descending"] &&
      newParams["whereSortBy"]["property"] ===
        oldParams["whereSortBy"]["property"] &&
      newParams["whereType"] === oldParams["whereType"]
    ) {
      return true;
    }

    return false;
  }

  /**
   * Check if the new parameters have to trigger a partial state update
   * (return true) or a complete state update (return false).
   *
   * Reason
   * We want to offer to our user the possibility to compare, visually and
   * on the fly, the current analytic information just switching the size.
   *
   * @param {object}  newParams
   * @param {string}  newParams.analytic -
   * @param {string}  newParams.analyticType - "abPercentage", "tcr" or
   *      "upgradesDowngrades"
   * @param {string}  newParams.instrumentType - etf, stock
   * @param {string}  newParams.size - "micro", "small", "mid", "large",
   *      "microSmall", "microMid" ...
   *
   * @param {object}  newParams.whatSortBy - sort by
   * @param {boolean} newParams.whatSortBy.descending -
   * @param {string}  newParams.whatSortBy.property -
   *
   * @param {string}  newParams.whatType - a ICB (GICS) type
   *
   * @param {object}  newParams.whereSortBy -
   * @param {boolean} newParams.whereSortBy.descending -
   * @param {string}  newParams.whatSortBy.property -
   *
   * @param {string}  newParams.whereType - a MarketFinancials type
   *
   * @returns {boolean} - true if it is required a partial state update,
   *  otherwise false (complete state update)
   */
  isPivotingOnSize(oldParams, newParams) {
    if (
      oldParams != null &&
      //
      // size is the discriminant
      newParams["zDimension"] !== oldParams["zDimension"] &&
      //
      //
      newParams["analyticKey"] === oldParams["analyticKey"] &&
      newParams["analyticType"] === oldParams["analyticType"] &&
      newParams["instrumentType"] === oldParams["instrumentType"] &&
      newParams["whatSortBy"]["descending"] ===
        oldParams["whatSortBy"]["descending"] &&
      newParams["whatSortBy"]["property"] ===
        oldParams["whatSortBy"]["property"] &&
      newParams["whatType"] === oldParams["whatType"] &&
      newParams["whereSortBy"]["descending"] ===
        oldParams["whereSortBy"]["descending"] &&
      newParams["whereSortBy"]["property"] ===
        oldParams["whereSortBy"]["property"] &&
      newParams["whereType"] === oldParams["whereType"]
    ) {
      return true;
    }

    return false;
  }

  isSortBy(oldParams, newParams) {
    if (
      oldParams != null &&
      //
      // sorting is the discriminant
      (newParams["whatSortBy"]["descending"] !==
        oldParams["whatSortBy"]["descending"] ||
        newParams["whatSortBy"]["property"] !==
          oldParams["whatSortBy"]["property"] ||
        newParams["whatType"] !== oldParams["whatType"] ||
        newParams["whereSortBy"]["descending"] !==
          oldParams["whereSortBy"]["descending"] ||
        newParams["whereSortBy"]["property"] !==
          oldParams["whereSortBy"]["property"]) &&
      //
      //
      newParams["analyticKey"] === oldParams["analyticKey"] &&
      newParams["analyticType"] === oldParams["analyticType"] &&
      newParams["instrumentType"] === oldParams["instrumentType"] &&
      newParams["size"] === oldParams["size"] &&
      newParams["whereType"] === oldParams["whereType"]
    ) {
      return true;
    }

    return false;
  }

  /**
   * Generate a peer id
   *
   * @param {object} peerBasicInfo - basic information about a peer
   * @param {string} peerBasicInfo.size - one of microLarge, etc.
   * @param {string} peerBasicInfo.type - "ETF" or "Stock"
   * @param {string} peerBasicInfo.what - GICS id or ETFclassification id
   * @param {string} peerBasicInfo.where - MarketFinancial id
   *
   * @returns {string} - a peer id
   */
  encodeId(peerBasicInfo) {
    return encodePeerId(peerBasicInfo);
  }

  /**
   * Encode sort by parameters
   *
   * @param {object} sortBy
   * @param {boolean} sortBy.descending
   * @param {string} sortBy.property
   *
   * @returns {string} - encoded sort by option
   */
  encodeSortBy(sortBy) {
    return [
      sortBy["property"],
      sortBy["descending"] === true ? "desc" : "asc",
    ].join("_");
  }

  //
  // It has been decided that microLarge and null are the same thing.
  // microLarge is the default size
  //
  // Used for queries and PeerSize widget
  //
  fixPeerSize(value) {
    if (value == null) {
      return "microLarge";
    }

    if (value === "microLarge") {
      return null;
    }

    return value;
  }

  async analytics({ analytics, clusters, peerId, segment }) {
    let configuration = this.clusterAPI
      .createConfiguration()
      .analytics(analytics)
      .clusters(clusters)
      .method("DRILL_DOWN")
      .universeFromPeer(peerId);

    if (segment) {
      configuration = configuration.segment(segment);
    }

    const result = await configuration.fetchAnalytics();
    return this.clusterAPI.decodeAnalytics(result, peerId, segment);
  }

  async getPerformanceSinceTrend(peerId, performanceAt) {
    const peerInfo = decodePeerId(peerId);
    const data: any = {
      AB: {
        peerAvg: null,
        quartileAvg: {
          1: null,
          2: null,
          3: null,
          4: null,
        },
        securities: [],
        quartilesSymbols: {},
      },
      CD: {
        peerAvg: null,
        quartileAvg: {
          1: null,
          2: null,
          3: null,
          4: null,
        },
        securities: [],
        quartilesSymbols: {},
      },
      peerInfo,
      formatterSectorBy: null,
    };

    const analyticDict = {
      sinceRated: "pr#avg#false",
      "3_months": "pq#avg#false",
      "6_months": "ps#avg#false",
      "12_months": "py#avg#false",
    };

    const analytic = [analyticDict[performanceAt]];
    const clusters = [
      {
        dimension: "rc",
        transform: {
          function: "ranges",
          params: { segments: [{ "<": 0 }, { ">=": 0 }] },
        },
      },
      {
        dimension: "pr",
        transform: {
          function: "quantile",
          params: { n: 4, trimOutliers: false },
        },
      },
    ];

    let configuration = this.clusterAPI
      .createConfiguration()
      .analytics(analytic)
      .clusters(clusters)
      .method("DRILL_DOWN")
      .universeFromPeer(peerId, [{ dimension: "lr", segments: [{ min: 10 }] }]);

    const result = await configuration.fetchAnalytics();

    const stats = result?.clustersStats?.stats ?? {};

    for (const [key, value] of Object.entries<any>(stats)) {
      const splittedKey = key.split("|");
      const isAB = splittedKey[0] === "1";
      const quartileNumber = parseInt(splittedKey["1"]);
      const accessor = isAB ? "AB" : "CD";

      data[accessor].quartileAvg[quartileNumber] = value[analytic[0]];
    }

    const tickersAB: any = [];
    const tickersCD: any = [];

    const clustersResult = result?.clustersStats?.clusters ?? {};
    for (const [key, value] of Object.entries<any>(clustersResult)) {
      const splittedKey = key.split("|");
      const isAB = splittedKey[0] === "1";

      const quartileAccessor = splittedKey[1];
      const breakQuartileAccessor = quartileAccessor.split(".");
      const quartileIndex = breakQuartileAccessor[0];

      if (isAB) {
        value.forEach(
          (symbol) =>
            (data.AB.quartilesSymbols[symbol] = parseInt(quartileIndex))
        );
        tickersAB.push(...value);
      } else {
        value.forEach(
          (symbol) =>
            (data.CD.quartilesSymbols[symbol] = parseInt(quartileIndex))
        );
        tickersCD.push(...value);
      }
    }

    data["AB"]["securities"] = tickersAB;
    data["CD"]["securities"] = tickersCD;

    // This is the key that came back from server when no cluster is specified
    // const noSpecifiedClustersKey = "ANY";

    // Make the last clusterAnalytics to get the cumulative avg
    if (tickersAB.length) {
      // configuration = http["clusterAnalytics"]
      //   .createConfiguration()
      //   .analytics(analytic)
      //   .clusters([])
      //   .method("DRILL_DOWN")
      //   .universeFromInstruments(tickersAB, [
      //     { dimension: "lr", segments: [{ min: 10 }] },
      //   ]);

      // const cumulativeAvgAB = await configuration.fetchAnalytics();

      // data.AB.peerAvg =
      //   cumulativeAvgAB.clustersStats.stats?.[noSpecifiedClustersKey]?.[
      //     analytic?.[0]
      //   ] ?? null;

      let avgSum = 0;
      const clustersNumber = 4;

      for (const key in data["AB"].quartileAvg) {
        avgSum += data["AB"].quartileAvg[key];
      }

      let avg = avgSum / clustersNumber;
      data.AB.peerAvg = avg;
    }

    if (tickersCD.length) {
      // configuration = http["clusterAnalytics"]
      //   .createConfiguration()
      //   .analytics(analytic)
      //   .clusters([])
      //   .method("DRILL_DOWN")
      //   .universeFromInstruments(tickersCD, [
      //     { dimension: "lr", segments: [{ min: 10 }] },
      //   ]);

      // const cumulativeAvgCD = await configuration.fetchAnalytics();

      // data.CD.peerAvg =
      //   cumulativeAvgCD.clustersStats.stats?.[noSpecifiedClustersKey]?.[
      //     analytic?.[0]
      //   ] ?? null;

      let avgSum = 0;
      const clustersNumber = 4;

      for (const key in data["CD"].quartileAvg) {
        avgSum += data["CD"].quartileAvg[key];
      }

      let avg = avgSum / clustersNumber;
      data.CD.peerAvg = avg;
    }

    const peerType = peerInfo.type.toLowerCase();
    const peerDimY = peerInfo.what;
    const env = this.environment;
    const taxonomies = env["taxonomies"];
    const fieldsMap = env["taxonomyFields"];
    const type = peerType === "etf" ? "ETF" : "security";
    const field = type === "ETF" ? "etfclass" : "icb";
    const taxonomyMapY = taxonomies[fieldsMap[type][field]];
    const node = taxonomyMapY[peerDimY];
    let segment: any = "1 Industry";

    switch (node.type) {
      default:
        segment = null;

        break;

      case "0 root":
        break;

      case "1 Industry":
        segment = "3 Sector";
    }

    data["formatterSectorBy"] = segment;
    return data;
  }

  /**
   *
   * @param {*} param0
   * @returns Clusters used to get data based on segment without defining a cluster explicity
   */
  async analyticsBySegment({ analytics, peerId, segment }) {
    const { type } = decodePeerId(peerId);
    let configuration = this.clusterAPI
      .createConfiguration()
      .analytics(analytics)
      .segment(segment)
      .method("DRILL_DOWN")
      .universeFromPeer(peerId);

    const response = await configuration.fetchAnalytics();
    const stats = response["clustersStats"]["stats"];

    const rawTaxonomies = this.environment["taxonomies"];
    const txFields = this.environment["taxonomyFields"];
    const formattedStats = {};

    let field = "country";

    switch (segment) {
      case "Country":
      case "Region":
      case "Area":
        break;

      case "etfgeoArea":
      case "etfgeoRegion":
      case "etfgeo":
        field = "etfgeo";

        break;

      case "AssetClass":
      case "Specialty":
      case "Theme":
        field = "etfclass";

        break;

      case "1 Industry":
      case "3 Sector":
        field = "icb";

        break;

      case "3 Level":
        field = "SizeClassification";
      //no default
    }

    const peerType = type === "ETF" ? "ETF" : "security";
    const taxon =
      segment !== "3 Level"
        ? rawTaxonomies[txFields[peerType][field]]
        : rawTaxonomies[field];

    const taxonomies = [taxon, rawTaxonomies["Markets"]];

    const parentLevel = {
      Region: "Region",
      Theme: "4 Subsector",
      Specialty: "3 Sector",
      etfgeoRegion: "Region",
    };

    for (const [key, value] of Object.entries(stats)) {
      const nameFormatted =
        segment !== "Region" &&
        segment !== "etfgeoRegion" &&
        segment !== "Specialty" &&
        segment !== "Theme"
          ? getTaxonById(key, taxonomies, null)["name"]
          : formatTaxonPrefixingParent(
              getTaxonById(key, taxonomies, null),
              taxonomies,
              parentLevel[segment]
            );

      formattedStats[nameFormatted] = value;
    }

    response["clustersStats"]["stats"] = formattedStats;
    return response;
  }

  /**
   * Return area taxons suitable to render table row header for regions
   *
   * @param {string} instrumentType - etf, stock
   *
   * @returns {Array<object>} - areas
   */
  getAreas(instrumentType) {
    const taxonomies = this.environment["taxonomies"];
    const fieldsMap = this.environment["taxonomyFields"];
    var where = Object.values<any>(
      taxonomies[
        fieldsMap[instrumentType === "ETF" ? "ETF" : "security"][
          instrumentType === "ETF" ? "etfgeo" : "country"
        ]
      ]
    );
    var data = where
      .filter((item) => item.type === "Area" && item.showInTree)
      .sort(sortBy("name"));

    var areas: any = [];
    var colSpan = 0;

    for (const datum of data) {
      colSpan = where.filter((item) => item.parent === datum.id).length;

      if (colSpan !== 0) {
        areas.push({
          colSpan,
          id: datum.id,
          name: datum.name,
          type: datum.type,
        });
      }
    }

    return areas;
  }

  /**
   * Get a peer by ID
   *
   * @param {string} peerId - the peer ID. It is a string with this
   *      structure
   *
   *          where-what-instrumentType-size
   *
   *      e.g. CH-50-Stock-microLarge
   *
   * @returns {dojo/promise/Promise} a promise fulfilled with the
   *       handled data of the response
   */
  getById(peerId) {
    if (this.state != null && this.state.store != null) {
      var store = this.state.store;
      const peer = store.find((item) => item.id === peerId);
      if (peer == null) {
        return this._getById(peerId);
      }

      return Promise.resolve(peer);
    }

    this.state = this.statePrototype();
    return this._getById(peerId);
  }

  /**
   * Get the children of the peer
   *
   * @param {string} peerId - the peer ID. It is a string with this
   *      structure
   *
   *          where-what-instrumentType-size
   *
   *      e.g. CH-50-Stock-microLarge
   *
   * @param {string} childrenType - the type to filter
   *
   * @returns {dojo/promise/Promise} a promise fulfilled with the
   *       handled data of the response
   */
  async getChildren(peerId, childrenType) {
    const peer = await this.getById(peerId);

    const taxonomies = this.environment["taxonomies"];
    const fieldsMap = this.environment["taxonomyFields"];
    const peerType = peer.type === "ETF" ? "ETF" : "security";
    const fieldX = peerType === "ETF" ? "etfgeo" : "country";
    const fieldY = peerType === "ETF" ? "etfclass" : "icb";

    const rootNodeY = Object.values<any>(
      taxonomies[fieldsMap[peerType][fieldY]]
    ).find((node) => node.parent == null)["id"];
    const rootNodeX = Object.values<any>(
      taxonomies[fieldsMap[peerType][fieldX]]
    ).find((node) => node.parent == null)["id"];

    var peerWhatWhere = this.getWhatWhereInfo(peerId);

    var what = peerWhatWhere["what"];
    var whatStore = peerWhatWhere["whatStore"];
    var whatType = peerWhatWhere["whatType"];
    var where = peerWhatWhere["where"];
    var whereStore = peerWhatWhere["whereStore"];
    var whereType = peerWhatWhere["whereType"];

    if (whatType === "3 Sector" && whereType === "Country") {
      // tree leaf: no children
      return null;
    }

    var params: any = [[]];
    switch (childrenType) {
      // in the UI the label is Sector :-/
      case "3 Level":
      case "1 Industry":
        // in the UI the label is Industry :-/
        // eslint-disable-next-line no-fallthrough
        const taxon = whatStore.reduce((current, acc) => {
          current[acc.id] = acc;

          return current;
        }, {});
        let children = getChildrenAtLevel(what,/*"3 Sector"*/childrenType, taxon);

        children = children.sort(sortBy("name"));

        for (const child of children) {
          params[0].push({
            zDimension: peer.size,
            type: peer.type,
            what: child.id,
            where: where,
          });
        }

        break;
      case "3 Sector":
      case "4 Subsector": {
        let children = whatStore.filter((item) => item.type === childrenType);

        if (what !== rootNodeY && childrenType === "3 Sector") {
          children = [];
          const peerType = peer.type;
          const taxonomyType = peerType === "ETF" ? "ETF" : "security";
          const field = peerType === "ETF" ? "etfclass" : "icb";
          const sectorTaxonomy = taxonomies[fieldsMap[taxonomyType][field]];
          let parentLevel = null;

          for (const taxonomy in sectorTaxonomy) {
            const sector = sectorTaxonomy[taxonomy];

            if (sector.type === childrenType) {
              parentLevel =
                peerType === "ETF"
                  ? sector.parent
                  : sectorTaxonomy[sector.parent].parent;

              if (parentLevel === what) {
                children.push(sector);
              }
            }
          }
        }

        if (children.length === 0) {
          console.error("Children in Markets detail dispersion are empty");
          console.log(
            "1.",
            peerId,
            "2.",
            childrenType,
            "3.",
            peer,
            "4.",
            //   sectorTaxonomy,
            "5.",
            what
          );
        }

        children = children.sort(sortBy("name"));

        for (const child of children) {
          params[0].push({
            zDimension: peer.size,
            type: peer.type,
            what: child.id,
            where: where,
          });
        }

        break;
      }
      case "Area":
      case "Country":
      case "Region":
      case "World": {
        let children = whereStore.filter((item) => item.type === childrenType);

        if (where !== rootNodeX && childrenType === "Country") {
          // To get all countries from an area, it needs
          // to get all Regions from that area, then
          // add the filter
          if (whereType === "Area") {
            let areaChildren = whereStore
              .filter((item) => item.parent === where)
              .sort(sortBy("name"));

            var parents = areaChildren.map((child) => child.id);

            children = children.filter((item) => parents.includes(item.parent));
          } else if (whereType === "Region") {
            children = children.filter((item) => item.parent === where);
          } else if (whereType === "Country") {
            children = children.filter((item) => item.id === where);
          }
        } else if (where !== rootNodeX && childrenType === "Region") {
          if (whereType === "Area") {
            children = children.filter((item) => item.parent === where);
          }
        }

        children = children.sort(sortBy("name"));

        for (const child of children) {
          params[0].push({
            zDimension: peer.size,
            type: peer.type,
            what: what,
            where: child.id,
          });
        }

        break;
      }
      default: {
        console.log("Unknown childrenType", childrenType);
      }
    }

    //
    // Checking if data are already cached
    //
    // The heuristic checks if all peers are already retrieved
    // and cached. If only one is not available, cache is
    // invalidated and a new request is performed
    //
    var areAllCached = false;
    var cachedPeers: any = [];
    var store = this.state?.store ?? null;
    if (store != null) {
      areAllCached = true;

      for (let i = 0; i < params[0].length; i++) {
        const _peerId = this.encodeId(params[0][i]);
        const _peer = store.find((item) => item.id === _peerId);
        if (_peer == null) {
          areAllCached = false;

          break;
        }

        cachedPeers.push(_peer);
      }
    }

    if (areAllCached === true) {
      return {
        data: cachedPeers,
        what: what,
        whatType: whatType,
        where: where,
        whereType: whereType,
      };
    } else {
      const response = await this.peersAPI.get(params);

      var children: any = [];
      var data = response[0];
      var threshold = this.threshold["children"]["cardinality"];

      for (var i = 0, length = data.length; i < length; i++) {
        const child = data[i];

        if (child["info"]["cardinality"] >= threshold) {
          switch (childrenType) {
            // in the UI the label is Sector :-/
            // in the UI the label is Industry :-/
            case "1 Industry":
            case "3 Sector": {
              child["name"] = whatStore.find(
                (item) => item.id === child["what"]
              )["name"];

              break;
            }
            case "4 Subsector": {
              const childNode = whatStore.find(
                (item) => item.id === child["what"]
              );
              const parentNode = whatStore.find(
                (item) => item.id === childNode.parent
              );

              child["name"] = `${parentNode.name} - ${childNode.name}`;

              break;
            }
            case "Area":
            case "Country":
            case "Region":
            case "World": {
              child["name"] = whereStore.find(
                (item) => item.id === child["where"]
              )["name"];

              break;
            }

            // no default
          }
          children.push(child);
        }
      }

      return {
        data: children,
        what: what,
        whatType: whatType,
        where: where,
        whereType: whereType,
      };
    }
  }

  /**
   * Get sector children. Used to display collapsed rows
   *
   * @param {string} peerId - the peer ID. It is a string with this
   *      structure
   *
   *          where-what-instrumentType-size
   *
   *      e.g. CH-50-Stock-microLarge
   *
   * @returns {dojo/promise/Promise} a promise fulfilled with the
   *       handled data of the response
   */
  async getChildrenBySector(peerId) {
    var peerIdDecoded = this.decodeId(peerId);
    var cachedWhere = this.state["matrix"]["where"];
    const taxonomies = this.environment["taxonomies"];
    const fieldsMap = this.environment["taxonomyFields"];

    const fieldKey = peerIdDecoded["type"] === "ETF" ? "ETF" : "security";
    const taxonomyByFieldsWhat =
      taxonomies[
        fieldsMap[fieldKey === "ETF" ? "ETF" : "security"][
          fieldKey === "ETF" ? "etfclass" : "icb"
        ]
      ];
    const fieldX = fieldKey === "ETF" ? "etfgeo" : "country";
    const rootNodeX = Object.values<any>(
      taxonomies[fieldsMap[fieldKey][fieldX]]
    ).find((item) => item.parent == null)["id"];

    var whatStore = taxonomyByFieldsWhat;

    var childrenWhat: any = [];

    // const level = peerType === "ETF" ? "4 Subsector" : "3 Sector";
    const level = "3 Sector";

    for (const id in whatStore) {
      let node = whatStore[id];

      if (node.type === level) {
        let parentId =
          fieldKey === "ETF" ? node.parent : whatStore[node.parent].parent;

        if (parentId === peerIdDecoded["what"]) {
          childrenWhat.push(node);
        }
      }
    }

    childrenWhat.sort(sortBy("name"));

    var params: any = [];
    var row: any = null;
    for (let i = 0, lengthI = childrenWhat.length; i < lengthI; i++) {
      row = [];

      row.push({
        zDimension: peerIdDecoded["zDimension"],
        type: peerIdDecoded.type,
        what: childrenWhat[i]["id"],
        where: rootNodeX,
      });

      for (var j = 0, lengthJ = cachedWhere.length; j < lengthJ; j++) {
        row.push({
          zDimension: peerIdDecoded["zDimension"],
          type: peerIdDecoded.type,
          what: childrenWhat[i]["id"],
          where: cachedWhere[j]["where"],
        });
      }

      params.push(row);
    }

    const response = await this.peersAPI.get(params);

    params = this.state["paramsTable"];

    // Remove empty peers from the table by checking if the first
    // column has the property cardinality
    const matrix = response.filter((row) => row[0].info.cardinality != null);

    for (let i = 0, length = matrix.length; i < length; i++) {
      this.setName(params, matrix[i]);
    }

    return matrix;
  }

  /**
   * Get the history of the peer
   *
   * @param {string} peerId - the peer ID. It is a string with this
   *      structure
   *
   *          where-what-instrumentType-size
   *
   *      e.g. CH-50-Stock-microLarge
   *
   * @param {string[]} metrics - array of string metrics to get
   * @returns {dojo/promise/Promise} a promise fulfilled with the
   *       handled data of the response
   */
  async getHistory(peerId, metrics, years) {
    // if peerId differs, empty cache
    if (this.stateHistory != null && this.stateHistory._peerId !== peerId) {
      this.stateHistory = null;
    }

    // Cache is metrics, by peerId and years
    if (this.stateHistory == null) {
      // Create history
      this.stateHistory = {
        _peerId: peerId,
      };
    }

    const keyYears = String(years);

    let metricsToAsk: any = [];
    if (this.stateHistory[keyYears] == null) {
      this.stateHistory[keyYears] = {};
    }
    for (const metric of metrics) {
      if (this.stateHistory[keyYears][metric] == null) {
        metricsToAsk.push(metric);
      }
    }

    // Temporary cache

    var params = [
      [
        {
          ...this.decodeId(peerId),
          metrics: metricsToAsk,
          years: years,
        },
      ],
    ];

    if (metricsToAsk.length > 0) {
      const response = await this.peersAPI.history(params, true);

      //this.stateHistory[keyYears]
      for (const metric of metrics) {
        this.stateHistory[keyYears][metric] = response.data[0][0][metric];
      }
      if (this.stateHistory[keyYears]["start"] == null) {
        this.stateHistory[keyYears]["start"] = response.data[0][0]["start"];
      }
      if (this.stateHistory[keyYears]["end"] == null) {
        this.stateHistory[keyYears]["end"] = response.data[0][0]["end"];
      }

      // Restore full asked metrics
      return this.peersAPI._decodeHistory(
        { ...params, metrics: metrics },
        {
          data: [[this.stateHistory[keyYears]]],
        }
      );
    } else {
      // Restore full asked metrics, wrap on promise
      return this.peersAPI._decodeHistory(
        { ...params, metrics: metrics },
        {
          data: [[this.stateHistory[keyYears]]],
        }
      );
    }
  }

  /**
   * Get the instruments that belong to the peer
   *
   * @param {object}  params.constraints - filter constraints
   * @param {object}  params.pagination - pagination parameter
   * @param {number}  params.pagination.page - the page to be retrieved.
   *      Values are from 1 to n
   * @param {number}  params.pagination.rows - how many rows per page will
   *      be retrieved. Values are from 1 to n
   * @param {string}  params.peerId - the peer ID. It is a string with this
   *      structure
   *
   *          where-what-instrumentType-size
   *
   *      e.g. CH-50-Stock-microLarge
   * @param {Array}   params.properties - properties to be retrieved
   * @param {object}  params.sortBy - sort by criterion
   * @param {boolean} params.sortBy.descending - is sort descending or not
   * @param {string}  params.sortBy.property - the sorting property
   *
   * @returns {dojo/promise/Promise} a promise fulfilled with the
   *       handled data of the response
   */
  async getInstruments(params) {
    //
    // KEEP IN MIND
    //
    // params["constraints"] could contain already constraints about
    // country, icb and type
    //
    // The two helper functions addConstraint() and
    // isConstraintAlreadySet() take care to avoid duplicates in server
    // requests
    //
    var paramsInstruments =
      params["constraints"] == null ? {} : deepClone(params["constraints"]);
    var peerBasicInfo = this.decodeId(params["peerId"]);

    function addConstraint(constraints, constraint) {
      var key = "filters";
      if (isConstraintAlreadySet(constraints[key], constraint) === false) {
        addQueryParam(constraints, key, constraint);
      }
    }

    function isConstraintAlreadySet(constraints, constraint) {
      //
      // check if the constraint is already set in order to avoid
      // duplicates
      //
      if (constraints != null) {
        var _constraint: any = null;
        for (var i = 0, length = constraints.length; i < length; i++) {
          _constraint = constraints[i];
          if (
            _constraint["dimension"] === constraint["dimension"] &&
            _constraint["segments"][0] === constraint["segments"][0]
          ) {
            return true;
          }
        }
      }

      return false;
    }

    var constraint: any = null;
    const taxonomies = this.environment["taxonomies"];
    const fieldsMap = this.environment["taxonomyFields"];
    const peerType = peerBasicInfo.type === "ETF" ? "ETF" : "security";
    const fieldX = peerType === "ETF" ? "etfgeo" : "country";
    const fieldY = peerType === "ETF" ? "etfclass" : "icb";
    const taxonomiesX = taxonomies[fieldsMap[peerType][fieldX]];
    const rootNodeX = Object.values<any>(taxonomiesX).find(
      (node) => node.parent == null
    )["id"];
    const taxonomiesY = taxonomies[fieldsMap[peerType][fieldY]];
    const rootNodeY = Object.values<any>(taxonomiesY).find(
      (node) => node.parent == null
    )["id"];

    //
    // filters
    //
    // Keep in mind that select endpoint has a different semantic
    // We don't have to add taxonomies roots (WWW, ICB and microLarge)
    //
    // where
    if (peerBasicInfo["where"] !== rootNodeX) {
      constraint = {
        dimension: fieldX,
        segments: [peerBasicInfo["where"]],
      };

      addConstraint(paramsInstruments, constraint);
    }
    // what
    if (peerBasicInfo["what"] !== rootNodeY) {
      constraint = {
        dimension: fieldY,
        segments: [peerBasicInfo["what"]],
      };

      addConstraint(paramsInstruments, constraint);
    }
    // size
    if (peerType === "ETF") {
      constraint = {
        dimension: "subtype",
        segments: [peerBasicInfo["zDimension"]],
      };

      addConstraint(paramsInstruments, constraint);
    } else if (peerBasicInfo["zDimension"] !== "microLarge") {
      constraint = {
        dimension: "sizeClassification",
        segments: [peerBasicInfo["zDimension"]],
      };

      addConstraint(paramsInstruments, constraint);
    }
    // instrumentType / Common Stock
    constraint = {
      dimension: "type",
      segments: [peerBasicInfo["type"]],
    };
    addConstraint(paramsInstruments, constraint);
    if (peerType !== "ETF") {
      constraint = {
        dimension: "stockclass",
        segments: ["STOCK"],
      };
      addConstraint(paramsInstruments, constraint);
    }
    // pagination
    paramsInstruments["page"] = {
      page: params["pagination"]["page"],
      rows: params["pagination"]["rows"],
    };
    // sort by
    paramsInstruments["sort"] = [
      {
        dimension: params["sortBy"]["property"],
        rev: params["sortBy"]["descending"],
      },
    ];

    return await this.instrumentsAPI.newFilterAndFetch(
      paramsInstruments,
      "security",
      params["properties"]
    );
  }

  /**
   * 2022-03-28 Remade to accept the peer dispersion data and
   * adapt it (no more API calls)
   *
   * @param {object} dispersionField - field of peerDispersion, like "top"
   *                                   "middle" or "bottom"
   */
  async getWeightedAnalytics(dispersionField) {
    // TCR field removed because it is not used
    const weightedAnalytic = {
      cardinality: dispersionField?.cardinality ?? 0,
      positions: dispersionField?.data ?? [],
      statistics: {
        // "tcr": {
        //     "today": 0,
        //     "yesterday": 0,
        //     "lastMonth": 1,
        //     "lastWeek": 0
        // },
        cardinalityPerRating: {
          0: dispersionField?.N ?? null,
          1: dispersionField?.B ?? null,
          2: dispersionField?.A ?? null,
          "-1": dispersionField?.C ?? null,
          "-2": dispersionField?.D ?? null,
        },
        weightPerRating: {
          0: dispersionField?.["N_%"] ?? null,
          1: dispersionField?.["B_%"] ?? null,
          2: dispersionField?.["A_%"] ?? null,
          "-1": dispersionField?.["C_%"] ?? null,
          "-2": dispersionField?.["D_%"] ?? null,
        },
      },
    };

    return weightedAnalytic;
  }

  /**
   * Get all details about what and where
   *
   * @param {string} peerId - the peer ID. It is a string with this
   *      structure
   *
   *          where-what-instrumentType-size
   *
   *      e.g. CH-50-Stock-microLarge
   *
   * @returns {object}
   */
  getWhatWhereInfo(peerId) {
    var peerIdTokens = this.decodeId(peerId);

    var peerType = peerIdTokens["type"].toLowerCase();
    const taxonomies = this.environment["taxonomies"];
    const fieldsMap = this.environment["taxonomyFields"];
    var what = peerIdTokens["what"];
    var whatStore = Object.values<any>(
      taxonomies[
        fieldsMap[peerType === "etf" ? "ETF" : "security"][
          peerType === "etf" ? "etfclass" : "sector"
        ]
      ]
    );
    var where = peerIdTokens["where"];
    var whereStore = Object.values<any>(
      taxonomies[
        fieldsMap[peerType === "etf" ? "ETF" : "security"][
          peerType === "etf" ? "etfgeo" : "country"
        ]
      ]
    );

    var whatType = whatStore.find((item) => item.id === what)["type"];
    var whereType = whereStore.find((item) => item.id === where)["type"];

    return {
      what: what,
      whatStore: whatStore,
      whatType: whatType,
      where: where,
      whereStore: whereStore,
      whereType: whereType,
    };
  }

  invalidateCache() {
    this.state = this.statePrototype();
  }

  /**
   * @ignore
   *
   * Retrieves peer from the server
   *
   * @param {string} peerId - the peer ID. It is a string with this
   *      structure
   *
   *          where-what-instrumentType-size
   *
   *      e.g. CH-50-Stock-microLarge
   *
   * @returns {dojo/promise/Promise} a promise fulfilled with the
   *       handled data of the response
   */
  async _getById(peerId) {
    var decodedId = this.decodeId(peerId);
    var params = [[decodedId]];

    this.state["paramsPeer"] = {
      instrumentType: decodedId["type"].toLowerCase(),
      peerId: peerId,
    };

    const response = await this.peersAPI.get(params);

    var _response = this.setName(this.state["paramsPeer"], response[0]);
    return _response[0];
  }

  async _getInstrumentHistory(e) {
    const params = {
      filters: [
        {
          dimension: "symbol",
          segments: [e],
        },
      ],
    };
    const temp = await this.instrumentsAPI.newFilterAndFetch(
      params,
      "security",
      [{ property: "currency", date: null }],
      false
    );
    let obj = {
      history: await this.instrumentsAPI.historyOf({ symbol: e }),
      currency: temp["data"][0]["currency"],
    };
    return obj;
  }

  /**
   * Get the children of the peer, from Size classification
   *
   * @param {string} peerId - the peer ID. It is a string with this
   *      structure
   *
   *          where-what-instrumentType-size
   *
   *      e.g. CH-50-Stock-microLarge
   *
   * @param {string} childrenType - the type to filter
   *
   * @returns {dojo/promise/Promise} a promise fulfilled with the
   *       handled data of the response
   */
  async getChildrenSizes(peerId, childrenType) {
    const peer = await this.getById(peerId);
    var taxonomies = this.environment["taxonomies"];
    var sizeStore: any = Object.values(taxonomies["SizeClassification"]);

    var params: any = [[]];

    var children = sizeStore
      .filter((item) => {
        if (peer.size !== "microLarge") {
          return item.type === childrenType && item.id === peer.size;
        } else {
          return item.type === childrenType;
        }
      })
      .sort(sortBy("name"));

    for (let i = 0, length = children.length; i < length; i++) {
      params[0].push({
        zDimension: children[i]["id"],
        type: peer["type"],
        what: peer["what"],
        where: peer["where"],
      });
    }

    //
    // Checking if data are already cached
    //
    // The heuristic checks if all peers are already retrieved
    // and cached. If only one is not available, cache is
    // invalidated and a new request is performed
    //
    var areAllCached = false;
    var cachedPeers: any = [];
    var store =
      this.state != null && this.state.store != null ? this.state.store : null;
    if (store != null) {
      areAllCached = true;

      for (let i = 0; i < params[0].length; i++) {
        const _peerId = this.encodeId(params[0][i]);
        const _peer = store.find((item) => item.id === _peerId);
        if (_peer == null) {
          areAllCached = false;

          break;
        }

        cachedPeers.push(_peer);
      }
    }

    if (areAllCached === true) {
      return {
        data: cachedPeers,
      };
    } else {
      const response = await this.peersAPI.get(params);
      children = [];
      var data = response[0];
      var threshold = this.threshold["children"]["cardinality"];

      for (let i = 0; i < data.length; i++) {
        const child = data[i];

        if (child["info"]["cardinality"] >= threshold) {
          child["name"] = sizeStore.find((item) => item.id === child["size"])[
            "name"
          ];
          children.push(child);
        }
      }

      return {
        data: children,
      };
    }
  }

  async trendsTracker(peerId) {
    const peerDetailStorage = new PeerDetailStorage(this.environment);

    const response = await peerDetailStorage.avgPerfUpDown(peerId);
    const quartilePerf = await peerDetailStorage.getPerformanceSinceRated(
      peerId,
      "sinceRated"
    );

    const result = {
      ...response,
      quartiles: {
        AB: quartilePerf?.AB,
        CD: quartilePerf?.CD,
      },
    };

    return result;
  }
}
