import { Box } from "@mui/system";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Properties } from "../../../../../api/Properties";
import {
  TableEventsV2,
  TableV2,
} from "../../../../../components/table/v2/TableCoreV2";
import { TrendratingTableV2 } from "../../../../../components/table/v2/TableV2";
import { useEnvironment } from "../../../../../hooks/useEnvironment";
import { useFormatter } from "../../../../../hooks/useFormatter";
import { _formatAction } from "./utils";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";

type Props = {
  data: any[];
  toggleSidebar: () => void;
  amounts?: any;
  currency?: any;
};

export default function DifferenceTable({
  data,
  toggleSidebar,
  amounts,
  currency,
}: Props) {
  const [tableReady, setTableReady] = useState(false);
  const environment = useEnvironment();
  const envSetup = useMemo(() => environment.get("setup"), [environment]);
  const formatter = useFormatter();
  const { t } = useTranslation();

  const tableRef = useRef<{ getInstance: () => TableV2 }>();

  const differenceAllocationsColumns: any = useMemo(() => {
    const label = new Properties({
      properties: envSetup["properties"],
    });

    return [
      { field: "", formatter: "rownum", widthGrow: 0.25, headerSort: false },
      {
        field: "isin",
        title: label.get("isin", 0, null),
        sorter: "string",
      },
      {
        field: "ticker",
        title: label.get("ticker", 0, null),
        sorter: "string",
      },
      {
        field: "name",
        title: label.get("name", 0, null),
        sorter: "string",
      },
      {
        field: "vc",
        title: label.get("vc", 0, null),
        formatter: (cell) => {
          return formatter.table("vc", "table", cell.getData());
        },
        sorter: "number",
      },
      {
        field: "rc",
        title: label.get("rc", 0, null),
        formatter: (cell) => {
          return formatter.table("rc", "table", cell.getData());
        },
        sorter: "number",
      },
      {
        field: "dr",
        formatter: (cell) => {
          return formatter.table("dr", "table", cell.getData());
        },
        title: label.get("dr", 0, null),
        sorter: "string",
      },
      {
        field: "_synthAction",
        formatter: (cell) => {
          const data = cell.getData();
          const returningValue = _formatAction("HTML", data["_synthAction"], t);
          return returningValue ? returningValue.html : null;
        },
        title: "Action",
        sorter: "string",
      },
      {
        field: "_synthActionValue",
        formatter: (cell) => {
          const data = cell.getData();
          return formatter.custom("number", {
            options: {
              hasPositiveSign: true,
              isPercentage: true,
              notAvailable: {
                input: null,
                output: "",
              },
            },
            output: "HTML",
            value: data["_synthActionValue"],
            valueHelper: null,
          });
        },
        title: "Weight",
        sorter: "number",
      },
    ];
  }, [envSetup, formatter, t]);

  const tableOptions = useMemo(() => {
    return {
      tableOption: {
        columns: differenceAllocationsColumns,
        ajaxSorting: false,
        columnHeaderVertAlign: "top" as any,
      },
    };
  }, [differenceAllocationsColumns]);

  const toolsEvents = useMemo(
    () => ({
      onExpand: () => toggleSidebar(),
    }),
    [toggleSidebar]
  );

  const tableEvents: TableEventsV2 = useMemo(() => {
    return {
      onTableBuilt: () => setTableReady(true),
      onTableDestroyed: () => setTableReady(false),
    };
  }, []);

  useEffect(() => {
    if (tableReady) {
      const table = tableRef.current?.getInstance();

      table?.insertData(data);
    }
  }, [data, differenceAllocationsColumns, tableReady]);

  useEffect(() => {
    if (tableReady) {
      const table = tableRef.current?.getInstance();

      if (amounts && amounts.length) {
        // Merge data and add columns
        const rows = table?.getRows();
        const data = rows?.map((row) => row.getData()) ?? [];
        const amountsMap = amounts.reduce((prev, current) => {
          prev[current.S] = current;

          return prev;
        }, {});

        for (const row of data) {
          row["SH"] = amountsMap[row.symbol].SH;
          row["MG"] = amountsMap[row.symbol].MG;
          row["SH"] = amountsMap[row.symbol].SH;
          row["MGW"] = amountsMap[row.symbol].MGW;
          row["Val"] = amountsMap[row.symbol].Val;
          row["TruncVal"] = amountsMap[row.symbol].TruncVal;
        }

        const columnsToAdd = [
          {
            field: "TruncVal",
            title: "Amount",
            sorter: "number",
            hozAlign: "left",
            formatter: (cell) => {
              const data = cell.getValue();
              // Format as price to show the currency symbol
              return formatter.table("amount", "table", {
                amount: data,
                currency,
              });
            },
          },
          {
            field: "SH",
            title: "Shares",
            sorter: "number",
            formatter: (cell) => {
              const data = cell.getValue();
              return formatter.custom("number", {
                options: {
                  hasPositiveSign: false,
                  isPercentage: false,
                  decimals: 0,
                  notAvailable: {
                    input: null,
                    output: "",
                  },
                },
                output: "HTML",
                value: data,
                valueHelper: null,
              });
            },
          },
        ];

        table?.addColumns(columnsToAdd, false, "_synthActionValue");
        table?.insertData(data);
      } else {
        // Remove columns
        table?.removeColumns([{ field: "SH" }, { field: "TruncVal" }]);
      }
    }
  }, [amounts, formatter, tableReady, currency]);

  const toolsConfig = useMemo(
    () => ({
      expandTable: {
        enabled: true,
        expandedIcon: (
          <KeyboardDoubleArrowRightIcon sx={{ fontSize: "0.9vw" }} />
        ),
        collapsedIcon: (
          <KeyboardDoubleArrowLeftIcon sx={{ fontSize: "0.9vw" }} />
        ),
      },
    }),
    []
  );

  return (
    <Box height={"100%"} minHeight={0} display={"flex"}>
      <TrendratingTableV2
        ref={tableRef}
        rowTooltipFormatter
        tableOptions={tableOptions}
        tableEvents={tableEvents}
        toolsEvents={toolsEvents}
        tools={toolsConfig}
      />
    </Box>
  );
}
