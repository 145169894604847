import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import UndoIcon from "@mui/icons-material/Undo";
import { Box, Card, CardContent, Typography } from "@mui/material";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { createRoot } from "react-dom/client";
import { useNavigate } from "react-router-dom";
import { ColumnDefinition } from "tabulator-tables";
import SecurityChartModal from "../../../../../../components/SecurityChartModal/SecurityChartModal";
import SecurityTooltipCore from "../../../../../../components/SecurityTooltip/SecurityTooltipCore";
import {
  TableEventsV2,
  TableV2,
} from "../../../../../../components/table/v2/TableCoreV2";
import { TrendratingTableV2 } from "../../../../../../components/table/v2/TableV2";
import { useEnvironment } from "../../../../../../hooks/useEnvironment";
import { useFormatter } from "../../../../../../hooks/useFormatter";

type TacticalAndImplementType = {
  orders: any;
  onChangeOrder: Function;
};

const TacticalAndImplement = ({
  onChangeOrder,
  orders,
}: TacticalAndImplementType) => {
  const format = useFormatter();
  const environment = useEnvironment();
  const navigate = useNavigate();
  const weightFormatter = useCallback(
    (value) => {
      return format.custom("number", {
        options: {
          hasPositiveSign: false,
          isPercentage: true,
          notAvailable: {
            input: null,
            output: "",
          },
        },
        output: "HTML",
        value: value["weight"],
        valueHelper: null,
      });
    },
    [format]
  );

  const [showSecurityChart, setShowSecurityChart] = useState(false);
  const [securityFocus, setSecurityFocus] = useState<any>(null);

  //#region - TrendratingTableV2 V2 SETUP AND HANDLERS
  const columns = useMemo<ColumnDefinition[]>(() => {
    return [
      {
        title: "Weight",
        field: "weight",
        formatter: (cell) => {
          let value = cell.getData();
          return weightFormatter(value);
        },
        sorter: "number",
      },
      { title: "Ticker", field: "ticker", sorter: "string" },
      {
        title: "Rating",
        field: "rc",
        formatter: (cell, value, node) => {
          const data = cell.getData();
          return format.table("rc", "table", data);
        },
        sorter: "number",
      },
      {
        title: "",
        field: "actions",
        formatter: (cell) => {
          const _data: any = cell.getData();
          const rowElement = cell.getRow().getElement();
          if (_data.from === "buy") {
            if (!_data.skip) {
              rowElement.style.backgroundColor = "#ddd";
            } else {
              rowElement.style.backgroundColor = "#fff";
            }
          } else {
            if (!_data.skip) {
              rowElement.style.backgroundColor = "#fff";
            } else {
              rowElement.style.backgroundColor = "#ddd";
            }
          }
          return `<div style="display:flex" class="content"></div>`;
        },
      },
    ];
  }, [format, weightFormatter]);
  const tableRefBuy = useRef<{ getInstance: () => TableV2 }>();
  const tableRefSell = useRef<{ getInstance: () => TableV2 }>();
  const [buyTableReady, setBuyTableReady] = useState(false);
  const [sellTableReady, setSellTableReady] = useState(false);
  const tableOptions = useMemo(() => {
    return {
      tableOption: {
        columns,
        ajaxSorting: false,
        movableColumns: false,
        resizableColumns: false,
      },
    };
  }, [columns]);
  useEffect(() => {
    if (buyTableReady) {
      const table = tableRefBuy.current?.getInstance();
      table?.insertData(orders?.buy);
    }
  }, [orders?.buy, buyTableReady, orders]);
  useEffect(() => {
    if (sellTableReady) {
      const table = tableRefSell.current?.getInstance();
      table?.insertData(orders?.sell);
    }
  }, [orders?.buy, orders, sellTableReady]);
  const onRowMouseEnterCallback = useCallback(
    (event: any, _row) => {
      // const row = _row.getData();
      let cell = _row.getCell("actions");
      const cellElement = cell
        .getElement()
        .getElementsByClassName("content")[0];
      let _data = _row.getData();
      const nodeToAppend = document.createElement("span");
      nodeToAppend.classList.add(_data?.symbol.split(" ").join("-"));
      const root = createRoot(nodeToAppend);
      root.render(
        <Box
          className="actionsContainer"
          style={{ visibility: "visible" }}
          display={"flex"}
          gap={1}
          justifyContent={"center"}
        >
          <SecurityTooltipCore
            symbol={_data?.symbol ?? ""}
            environment={environment}
            chartButtonHandler={(security) => {
              setSecurityFocus(security);
              setShowSecurityChart(true);
            }}
            setSecurityData={() => {}}
            additionalButtons={[
              {
                label: "Security analysis",
                callback: () => {
                  navigate(`/app/analysis/instrument/${_data?.symbol}/`);
                },
              },
            ]}
          />

          <EditIcon
            onClick={() => {
              const weightCell = cell.getRow().getCell("weight");
              const element = weightCell.getElement();
              const root = createRoot(element);
              root.render(
                <InputForWeight
                  initValue={weightCell.getValue()}
                  onBlur={(e) => {
                    let value = e.target.value;
                    onChangeOrder({
                      security: _data,
                      action: "editWeight",
                      payload: value,
                    });
                  }}
                />
              );
            }}
            sx={{ fontSize: "0.7vw" }}
          />
          {_data?.skip ? (
            <UndoIcon
              onClick={() => {
                onChangeOrder({
                  security: _data,
                  action: "skip",
                  payload: false,
                });
              }}
              sx={{ fontSize: "0.7vw" }}
            />
          ) : (
            <DeleteIcon
              onClick={() => {
                onChangeOrder({
                  security: _data,
                  action: "skip",
                  payload: true,
                });
              }}
              sx={{ fontSize: "0.7vw" }}
            />
          )}
        </Box>
      );
      cellElement.appendChild(nodeToAppend);
    },
    [environment, navigate, onChangeOrder]
  );
  const onRowMouseLeaveCallback = useCallback((e, _row) => {
    let cell = _row.getCell("actions");
    if (cell) {
      const cellElement = cell.getElement();
      let _data = cell.getRow().getData();
      const element = cellElement.getElementsByClassName(
        _data?.symbol.split(" ").join("-")
      )[0];
      element.remove();
    }
  }, []);
  const sellTableEvents: TableEventsV2 = useMemo(
    () => ({
      onTableBuilt: () => {
        setSellTableReady(true);
      },
      onTableDestroyed: () => {
        setSellTableReady(false);
      },
      rowMouseEnter: onRowMouseEnterCallback,
      rowMouseLeave: onRowMouseLeaveCallback,
    }),
    [onRowMouseEnterCallback, onRowMouseLeaveCallback]
  );
  const buyTableEvents: TableEventsV2 = useMemo(
    () => ({
      onTableBuilt: () => {
        setBuyTableReady(true);
      },
      onTableDestroyed: () => {
        setBuyTableReady(false);
      },
      rowMouseEnter: onRowMouseEnterCallback,
      rowMouseLeave: onRowMouseLeaveCallback,
    }),
    [onRowMouseEnterCallback, onRowMouseLeaveCallback]
  );
  //#endregion

  return (
    <Box display={"flex"} gap={1} height={"100%"} width={"100%"}>
      {showSecurityChart && securityFocus != null && (
        <SecurityChartModal
          environment={environment}
          security={securityFocus}
          showModal={showSecurityChart}
          onClose={() => setShowSecurityChart(false)}
        />
      )}
      <Card sx={{ boxShadow: 2, flex: 1 }}>
        <CardContent
          sx={{ overflow: "hidden", height: "100%", p: "8px !important" }}
        >
          <Box
            width={"100%"}
            display={"flex"}
            flexDirection={"column"}
            gap={1}
            height={"100%"}
            overflow={"hidden"}
          >
            <Typography variant="tr_subtitle_blue">
              <strong>Buy</strong>
            </Typography>
            <Box overflow={"auto"}>
              <TrendratingTableV2
                ref={tableRefBuy}
                tableEvents={buyTableEvents}
                tableOptions={tableOptions}
              />
            </Box>
          </Box>
        </CardContent>
      </Card>

      <Card sx={{ boxShadow: 2, flex: 1 }}>
        <CardContent
          sx={{ overflow: "hidden", height: "100%", p: "8px !important" }}
        >
          <Box
            width={"100%"}
            display={"flex"}
            flexDirection={"column"}
            gap={1}
            height={"100%"}
            overflow={"hidden"}
          >
            <Typography variant="tr_subtitle_blue">
              <strong>Sell </strong>
            </Typography>
            <Box overflow={"auto"}>
              <TrendratingTableV2
                ref={tableRefSell}
                tableEvents={sellTableEvents}
                tableOptions={tableOptions}
              />
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};
export default TacticalAndImplement;
type InputForWeightType = {
  initValue: number;
  onBlur: (e) => void;
};
const InputForWeight = ({ initValue, onBlur }: InputForWeightType) => {
  return (
    <input
      autoFocus
      onBlur={onBlur}
      defaultValue={(initValue * 100).toFixed(2)}
      type="number"
      step={0.01}
      style={{ width: "100%" }}
    />
  );
};
