import { Box } from "@mui/material";
import { memo, useCallback, useMemo } from "react";
import styles from "./Alternatives.module.scss";
import { AlternativesDataManager } from "./AlternativesDataManager";
import { Action, AlternativesState } from "./AlternativesReducer";
import AlternativesBlocksContent from "./widgets/AlternativesBlocksContent";
import { AlternativesFilterControls } from "./widgets/AlternativesFilterControls";
import { SwapList } from "./widgets/SwapList";
import { deepClone } from "../../../../../../../deepClone";
import { useTaxon } from "../../../../../../../hooks/useTaxon";
import { useTaxonomyByType } from "../../../../../../../hooks/useTaxonomyByType";
import Block from "../../../../../../../components/LayoutElements/Block/Block";
import { dataInjector } from "../../../../../../../api/compute/commons";

type AlternativesListProps = {
  focusedSecurity: AlternativesState["focusedSecurity"];
  positions: AlternativesState["positions"];
  mainDispatch: (act: Action) => void;
  DM: AlternativesDataManager;
  sellList: AlternativesState["swapList"]["sell"];
  buyList: AlternativesState["swapList"]["buy"];
  showSwapList: boolean;
  constraints: AlternativesState["constraints"];
};

type ContentProps = {
  data?: any;
  dispatch: AlternativesListProps["mainDispatch"];
  positions: AlternativesListProps["positions"];
  focusedSecurity: AlternativesListProps["focusedSecurity"];
  constraints: AlternativesState["constraints"];
};

export const getInterval = (marketcap) => {
  const intervals: any = [
    {
      left: null,
      right: 300000000,
      checked: false,
      innerHTML: "Micro Cap",
      label: "Micro Cap ($50M-$300M)",
      node: null,
    },
    {
      left: 300000000,
      right: 2000000000,
      checked: false,
      innerHTML: "Small Cap",
      label: "Small Cap ($300M-$2B)",
      node: null,
    },
    {
      left: 2000000000,
      right: 10000000000,
      checked: false,
      innerHTML: "Mid Cap",
      label: "Mid Cap ($2B-$10B)",
      node: null,
    },
    {
      left: 10000000000,
      right: 200000000000,
      checked: false,
      innerHTML: "Large Cap",
      label: "Large Cap ($10B-$200B)",
      node: null,
    },
    {
      left: 200000000000,
      right: null,
      checked: false,
      innerHTML: "Mega Cap",
      label: "Mega Cap (over $200B)",
      node: null,
    },
  ];
  var interval: any = null;

  if (!marketcap) {
    return "";
  }

  if (marketcap >= intervals[0].left && marketcap < intervals[0].right) {
    interval = intervals[0];
  } else if (marketcap >= intervals[1].left && marketcap < intervals[1].right) {
    interval = intervals[1];
  } else if (marketcap >= intervals[2].left && marketcap < intervals[2].right) {
    interval = intervals[2];
  } else if (marketcap >= intervals[3].left && marketcap < intervals[3].right) {
    interval = intervals[3];
  } else if (marketcap >= intervals[4].left) {
    interval = intervals[4];
  }

  return interval;
};

const getSearchContext = (instrument, taxon, checkedValues, taxonomiesMapY) => {
  const {
    assetClassChecked,
    icbChecked,
    marketCapChecked,
    domicileChecked,
    countryChecked,
    etfGeoChecked,
    specialtyChecked,
    industryChecked,
  } = checkedValues;
  let interval;
  const instrumentType = instrument["type"];

  let searchContext = {
    filters: [
      {
        dimension: "type",
        segments: [instrumentType],
      },
    ],
    ranges: [
      {
        dimension: "rc",
        segments: [
          {
            max: 2,
            min: 2,
          },
        ],
      },
      {
        dimension: "pr",
        segments: [
          {
            max: null,
            min: 0,
          },
        ],
      },
    ],
    page: {
      page: 1,
      rows: 99999,
    },
  };

  var constraint1Property = "marketcap";
  var constraint2Property = "country";
  var constraint3Property = "icb";
  if (instrumentType === "ETF") {
    constraint1Property = "etfclass";
    constraint2Property = "etfgeo";
    constraint3Property = "country";
  }

  if (instrument[constraint1Property]) {
    if (constraint1Property === "marketcap") {
      if (marketCapChecked) {
        interval = getInterval(instrument[constraint1Property]);
        searchContext.ranges.push({
          dimension: constraint1Property,
          segments: [
            {
              max: interval.right,
              min: interval.left,
            },
          ],
        });
      }
    } else {
      let segments: any = [];

      if (specialtyChecked) {
        segments.push(instrument[constraint1Property]);
      } else {
        segments = [];
      }

      if (assetClassChecked) {
        const parentId = taxon.getAncestorAtLevel(
          taxonomiesMapY,
          instrument[constraint1Property],
          "1 Industry"
        );

        if (parentId && !specialtyChecked) {
          segments.push(parentId);
        }
      }

      if (segments.length) {
        searchContext.filters.push({
          dimension: constraint1Property,
          segments,
        });
      }
    }
  }

  if (instrument[constraint2Property]) {
    const propertyChecked =
      instrumentType === "ETF" ? etfGeoChecked : countryChecked;
    if (propertyChecked) {
      searchContext.filters.push({
        dimension: constraint2Property,
        segments: [instrument[constraint2Property]],
      });
    }
  }

  if (instrument[constraint3Property]) {
    if (constraint3Property === "icb") {
      let segments: any = [];
      if (industryChecked) {
        segments.push(instrument[constraint3Property]);
      } else {
        segments = [];
      }

      if (icbChecked) {
        var sectorId = taxon.getAncestorAtLevel(
          taxonomiesMapY,
          instrument[constraint3Property],
          "1 Industry"
        );

        if (sectorId && !industryChecked) {
          segments.push(sectorId);
        }
      }

      if (segments.length) {
        searchContext.filters.push({
          dimension: constraint3Property,
          segments,
        });
      }
    } else if (domicileChecked) {
      searchContext.filters.push({
        dimension: constraint3Property,
        segments: [instrument[constraint3Property]],
      });
    }
  }

  return searchContext;
};

const getSellSecurityWeight = (security, sellSecurity, positions) => {
  const securityClone = deepClone(security);
  const securityFromPos = positions?.find(
    (stock) => stock.symbol === sellSecurity.symbol
  );

  if (securityFromPos) {
    securityClone["weight"] = securityFromPos["weight"];

    return securityClone;
  } else {
    return securityClone;
  }
};

export const AlternativesList = memo(
  ({
    focusedSecurity,
    positions,
    mainDispatch,
    DM,
    sellList,
    buyList,
    showSwapList,
    constraints,
  }: AlternativesListProps) => {
    const taxon = useTaxon();
    const { taxonomiesMapY } = useTaxonomyByType(
      focusedSecurity?.type === "ETF" ? "ETF" : "stock"
    );

    const alternatives = useCallback(
      async (focusedSecurity, DM: AlternativesDataManager, taxon) => {
        if (
          focusedSecurity?.type !== "Stock" &&
          focusedSecurity?.type !== "ETF"
        ) {
          return {
            errorMessage: `Alternatives for type: ${focusedSecurity?.type} are not implemented`,
          };
        }

        if (focusedSecurity) {
          const params = {
            constraints: getSearchContext(
              focusedSecurity,
              taxon,
              constraints,
              taxonomiesMapY
            ),
            instrument: focusedSecurity,
          };

          return {
            data: await DM.getBetterAlternatives(params as any),
          };
        }
      },
      [constraints, taxonomiesMapY]
    );

    return (
      <Block
        styleProps={{ flexDirection: "column", width: "60%" }}
        className={styles.positions__list__block}
        autoResize={true}
        serviceCaller={() =>
          showSwapList ? null : alternatives(focusedSecurity, DM, taxon)
        }
      >
        {showSwapList ? (
          <SwapList
            dispatch={mainDispatch}
            fromList={sellList}
            toList={buyList}
          />
        ) : (
          <Content
            constraints={constraints}
            dispatch={mainDispatch}
            positions={positions}
            focusedSecurity={focusedSecurity}
          />
        )}
      </Block>
    );
  }
);

const Content = ({
  data,
  dispatch,
  positions,
  focusedSecurity,
  constraints,
}: ContentProps) => {
  const resData = useMemo(() => data?.data ?? null, [data]);

  const addToSwapListHandler = useCallback(
    (security) => {
      const securityWithWeight = getSellSecurityWeight(
        security,
        focusedSecurity,
        positions
      );

      dispatch({
        type: "ADD_TO_SWAP_LIST",
        payload: {
          buy: securityWithWeight,
          sell: dataInjector([focusedSecurity], positions, ["weight"]),
        },
      });
      dispatch({ type: "SET_FOCUSED_SECURITY", payload: null });
    },
    [dispatch, focusedSecurity, positions]
  );

  const instrumentType = useMemo(
    () => focusedSecurity?.type,
    [focusedSecurity]
  );

  const updateConstraints = useCallback(
    ({ key, value }: { key: string; value: boolean }) => {
      dispatch({ type: "UPDATE_CONTRAINTS", payload: { key, value } });
    },
    [dispatch]
  );

  return data?.errorMessage == null && resData ? (
    <>
      <AlternativesFilterControls
        updateConstraints={updateConstraints}
        constraints={constraints}
        type={instrumentType}
        what={
          instrumentType === "ETF"
            ? focusedSecurity.etfclass
            : focusedSecurity.icb
        }
        where={
          instrumentType === "ETF"
            ? focusedSecurity.etfgeo
            : focusedSecurity.country
        }
        domicile={
          instrumentType === "ETF" ? focusedSecurity.country : undefined
        }
        mktCap={
          instrumentType === "ETF" ? undefined : focusedSecurity["marketcap"]
        }
      />
      {resData.length ? (
        <>
          <AlternativesBlocksContent
            hasListBorder={true}
            hasCounter={true}
            list={resData}
            onClickHandler={() => {}}
            isCardSelectable={false}
            hasCardButtons={true}
            onClickAdd={(security) => addToSwapListHandler(security)}
            buttonLabel={`Add ${focusedSecurity.ticker}`}
          />
        </>
      ) : (
        <Box
          mt={2}
          alignSelf={"flex-start"}
          border={"1px solid #2a7090"}
          borderRadius={"4px"}
          p={2}
          width={"100%"}
          textAlign={"center"}
        >
          <strong>There's no security better than the select one</strong>
        </Box>
      )}
    </>
  ) : data?.errorMessage == null ? (
    <>
      {focusedSecurity != null ? (
        <Box
          mt={2}
          alignSelf={"flex-start"}
          border={"1px solid #2a7090"}
          borderRadius={"4px"}
          p={2}
          width={"100%"}
          textAlign={"center"}
        >
          <strong>No alternative found with this constraints</strong>
        </Box>
      ) : (
        <Box
          mt={2}
          alignSelf={"flex-start"}
          border={"1px solid #2a7090"}
          borderRadius={"4px"}
          p={2}
          width={"100%"}
          textAlign={"center"}
        >
          <strong>
            Please select at least one security to see swap opportunities
          </strong>
        </Box>
      )}
    </>
  ) : (
    <p>{data.errorMessage}</p>
  );
};
