import { Box, Button, Typography } from "@mui/material";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { CellComponent, ColumnDefinition } from "tabulator-tables";
import { v4 as uuidv4 } from "uuid";
import { ErrorBoundary } from "../../../../../../../../../../../ErrorBoundary";
import { Instruments } from "../../../../../../../../../../../api/compute/Instruments";
import DialogUpload from "../../../../../../../../../../../components/DialogUpload/DialogUpload";
import {
  TableEventsV2,
  TableV2,
} from "../../../../../../../../../../../components/table/v2/TableCoreV2";
import { TrendratingTableV2 } from "../../../../../../../../../../../components/table/v2/TableV2";
import { deepClone } from "../../../../../../../../../../../deepClone";
import { useEnvironment } from "../../../../../../../../../../../hooks/useEnvironment";
import { useFormatter } from "../../../../../../../../../../../hooks/useFormatter";
import { TDate } from "../../../../../../../../../../../trendrating/date/TDate";
import Search from "../../../../../../../../../widgets/ReactSearch/Search";
import { widgetsConfiguration } from "../../../../../../../../../widgets/widgetsConfiguration";
import DatePicker from "../../../../../advancedWidgets/AdvancedFieldControllers/BacktestingController/components/DatePicker";
import styles from "./AllocationEditor.module.scss";

type AllocationEditorProps = {
  collection: any[];
  property: string;
  onSave: (updatedAllocation) => void;
  onCancel: () => void;
  mode: "edit" | "insert";
  startEmpty?: boolean;
};

const statusBaseClasses = {
  expired: styles.expired,
  rebalanced: styles.rebalanced,
  unavailable: styles.unavailable,
};

export function AllocationEditor({
  collection,
  property,
  onSave,
  onCancel,
  mode,
  startEmpty = false,
}: AllocationEditorProps) {
  const [value, setValue] = useState<any>([]);
  const [positionsToInsert, setPositionsToInser] = useState<
    { name: string; weight: number; symbol: string }[]
  >([]);
  const [unrecognized, setUnrecognised] = useState<any>();
  const [multiple, setMultiple] = useState<any>();
  const [showAddPosition, setShowAddPosition] = useState(false);
  const [date, setDate] = useState(parseInt(property.replace("date_", "")));
  const [isValidDate, setIsValidDate] = useState(true);
  const environment = useEnvironment();
  const appSetup = useMemo(() => environment.get("setup"), [environment]);
  const instrumentsAPI = useMemo(() => new Instruments(appSetup), [appSetup]);
  const taxonomies = useMemo(() => appSetup.taxonomies, [appSetup.taxonomies]);
  const taxonomyFields = useMemo(() => appSetup.taxonomyFields, [appSetup]);
  const markets = useMemo(
    () => taxonomies[taxonomyFields["security"]["domicile"]],
    [taxonomies, taxonomyFields]
  );
  const formatter = useFormatter();

  const formatDate = useCallback(
    (date) => {
      return formatter.custom("date", {
        options: {
          notAvailable: {
            input: null,
            output: "",
          },
        },
        output: "HTML",
        value: date,
        valueHelper: null,
      });
    },
    [formatter]
  );

  useEffect(() => {
    if (mode !== "edit") {
      let isValid = true;

      for (const instrument of collection) {
        if (`date_${date}` in instrument) {
          isValid = false;
          break;
        }
      }

      setIsValidDate(isValid);
    }
  }, [collection, date, mode]);

  const dateToDays = useCallback((date) => {
    const dateObject = new Date(date);
    return TDate.dateToDays(dateObject);
  }, []);

  const weightFormatter = useCallback(
    (value) => {
      return formatter.custom("number", {
        options: {
          isPercentage: true,
          notAvailable: {
            input: null,
            output: "",
          },
          zero: true,
        },
        output: "HTML",
        value: value,
        valueHelper: null,
      });
    },
    [formatter]
  );

  useEffect(() => {
    const data: any = [];

    if (mode === "edit") {
      for (const item of collection) {
        if (item[property] > 0) {
          data.push({ ...item });
        }
      }

      setValue(data);
    } else if (mode === "insert") {
      // Duplicate mode

      for (const item of collection) {
        if (item[property] > 0) {
          data.push({
            name: item.name,
            weight: item[property],
            symbol: item.symbol,
          });
        }
      }

      setPositionsToInser(startEmpty ? [] : data);
    }
  }, [collection, mode, property, startEmpty]);

  const totalWeight = useMemo(() => {
    let weightsSum = 0;
    if (mode === "edit") {
      weightsSum = value.reduce((acc, current) => {
        return (acc += current[property]);
      }, 0);
    } else {
      weightsSum = positionsToInsert.reduce((acc, current) => {
        return (acc += current["weight"]);
      }, 0);
    }

    return weightFormatter(weightsSum);
  }, [mode, positionsToInsert, property, value, weightFormatter]);

  const editCell = useCallback(
    (symbol, value) => {
      if (mode === "edit") {
        setValue((currentValue) => {
          const newData: any = [];

          for (const item of currentValue) {
            if (item.symbol === symbol) {
              item[property] = value;
            }

            newData.push({ ...item });
          }

          return newData;
        });
      } else {
        setPositionsToInser((currentValue) => {
          const newData: any = [];

          for (const item of currentValue) {
            if (item.symbol === symbol) {
              item["weight"] = value;
            }

            newData.push({ ...item });
          }

          return newData;
        });
      }
    },
    [mode, property]
  );

  const columnEditor = useCallback(
    (cell, onRendered, success, cancel, editorParams) => {
      //cell - the cell component for the editable cell
      //onRendered - function to call when the editor has been rendered
      //success - function to call to pass thesuccessfully updated value to Tabulator
      //cancel - function to call to abort the edit and return to a normal cell
      //editorParams - params object passed into the editorParams column definition property

      //create and style editor
      const editor = document.createElement("input");

      editor.setAttribute("type", "number");

      //create and style input
      editor.style.padding = "3px";
      editor.style.width = "100%";
      editor.style.boxSizing = "border-box";

      const value = cell.getValue();
      const data = cell.getData();
      const symbol = data["symbol"];

      editor.value = (value * 100).toFixed(2);

      //set focus on the select box when the editor is selected (timeout allows for editor to be added to DOM)
      onRendered(function () {
        editor.focus();
      });

      //when the value has been set, trigger the cell to update
      function successFunc() {
        const unformatted = parseFloat(editor.value) / 100;
        success(unformatted);

        editCell(symbol, unformatted);
      }

      editor.addEventListener("change", successFunc);
      editor.addEventListener("blur", successFunc);

      //return the editor element
      return editor;
    },
    [editCell]
  );

  const deleteRow = useCallback(
    (e, cell: CellComponent) => {
      const data = cell.getData() as any;
      if (mode === "edit") {
        setValue((current) => {
          const newValue = [...current];

          return newValue.filter((item) => item.symbol !== data.symbol);
        });
      } else {
        setPositionsToInser((current) => {
          const newValue = [...current];

          return newValue.filter((item) => item.symbol !== data.symbol);
        });
      }
    },
    [mode]
  );

  const trColums: ColumnDefinition[] = useMemo(
    () => [
      {
        field: "name",
        title: "Instrument",
        sorter: "string",
        formatter: (cell) => {
          const value = cell.getValue();
          const data = cell.getData();
          const status = data["status"];
          const cellElement = cell.getElement();

          if (statusBaseClasses[status]) {
            cellElement.classList.add(statusBaseClasses[status] ?? "");
          }

          return value;
        },
      },
      {
        field: mode === "edit" ? property : "weight",
        title: "Weight",
        sorter: "number",
        editor: columnEditor,
        editable: true,
        formatter: (cell: CellComponent) => {
          const data = cell.getData();
          const html = cell.getElement();
          const span = document.createElement("span");
          span.classList.add("i-delete");

          html.appendChild(span);

          if (statusBaseClasses[data["status"]]) {
            html.classList.add(statusBaseClasses[data["status"]]);
          }

          return weightFormatter(data[mode === "edit" ? property : "weight"]);
        },
        cssClass: styles["no-border-right"],
      },
      {
        title: "",
        width: "5%",
        hozAlign: "center",
        formatter: () => '<span style="color: red;" class="i-delete"></span>',
        cssClass: styles.invisible,
        cellClick: deleteRow,
      },
    ],
    [columnEditor, deleteRow, mode, property, weightFormatter]
  );

  const normalize = useCallback(() => {
    const data: any = [];
    const meta = {
      cardinality: value.length,
      weight: parseFloat(totalWeight) / 100,
    };

    const instruments = (mode === "edit" ? value : positionsToInsert) ?? [];
    const instrumentsTotal = instruments.length;
    const weightProperty = mode === "edit" ? property : "weight";

    const dataMeta = meta;
    const listWeight: any = dataMeta["weight"];

    for (let i = 0, length = instruments.length; i < length; i++) {
      instruments[i][weightProperty] = listWeight
        ? parseFloat((instruments[i][weightProperty] / listWeight) as any)
        : 1 / instrumentsTotal;
      data.push(instruments[i]);
    }
    if (mode === "edit") {
      setValue(data);
    } else {
      setPositionsToInser(data);
    }
  }, [mode, positionsToInsert, property, totalWeight, value]);

  const equalize = useCallback(() => {
    const data: any = [];

    const instruments = (mode === "edit" ? value : positionsToInsert) ?? [];
    const instrumentsTotal = instruments.length;
    const instrumentsWeight = 1 / instrumentsTotal;
    const weightProperty = mode === "edit" ? property : "weight";

    let instrument: any = null;
    for (let i = 0; i < instrumentsTotal; i++) {
      instrument = instruments[i];
      instrument[weightProperty] = instrumentsWeight;
      data.push(instrument);
    }

    if (mode === "edit") {
      setValue(data);
    } else {
      setPositionsToInser(data);
    }
  }, [mode, positionsToInsert, property, value]);

  const dataPrepareGroupMatches = useCallback(
    (toBeImported, symbology, found) => {
      let item: any = null;
      let _item: any = null;
      const matches = {};
      const weightProperty = mode === "edit" ? property : `weight`;

      for (let i = 0, length = toBeImported.length; i < length; i++) {
        item = toBeImported[i];

        _item = {
          label: item["label"],
          matches: [],
          country: item["country"],
          currency: item["currency"],
        };
        _item[weightProperty] = parseFloat(
          item["weight"] && item["weight"] > 0 ? item["weight"] : 0
        );

        matches[item["label"]] = _item;
      }

      let tokens: any = null;
      let hasSameSymbol = false;
      for (let i = 0, length = found.length; i < length; i++) {
        hasSameSymbol = false;
        tokens = found[i][symbology].split("|");
        for (let j = 0, lengthJ = tokens.length; j < lengthJ; j++) {
          if (tokens[j] in matches && !hasSameSymbol) {
            matches[tokens[j]].matches.push(found[i]);
            hasSameSymbol = true;
          } else {
            // TODO - if PORTFOLIO, weights should be managed
            // which is the right weight?
            delete matches[tokens[j]];
          }
        }
      }

      return matches;
    },
    [mode, property]
  );

  const dataDisplay = useCallback(
    (data) => {
      const multiple = data["multiple"];
      const unknown = data["unknown"];

      const recognized = data["recognized"];
      const weightProperty = mode === "edit" ? property : `weight`;

      if (multiple.length > 0 || unknown.length > 0) {
        setMultiple(
          multiple.map((item) => ({ ...item, weight: item[weightProperty] }))
        );
        setUnrecognised(
          unknown.map((item) => ({ ...item, weight: item[weightProperty] }))
        );
      }

      if (mode === "edit") {
        setValue(recognized);
      } else {
        setPositionsToInser(
          recognized.map((item) => ({
            name: item.name,
            weight: item[weightProperty],
            symbol: item.symbol,
          }))
        );
      }
    },
    [mode, property]
  );

  const prepareInstruments = useCallback(
    (response, params) => {
      const found = response["data"];
      const symbology = params["symbology"];
      const toBeImported = params["instruments"];
      const _data = dataPrepareGroupMatches(toBeImported, symbology, found);

      let holding: any = null;
      let isRecognized = true;
      let item: any = null;
      const multiple: any = [];
      const recognized: any = [];
      const unknown: any = [];
      const weightProperty = mode === "edit" ? property : `weight`;
      for (let key in _data) {
        isRecognized = false;
        item = _data[key];
        // recognized instrument
        if (item.matches.length === 1) {
          holding = deepClone(item.matches[0]);
          holding[weightProperty] = item[weightProperty];

          recognized.push(holding);
        }
        // multiple instrument matches
        // if country and currency matches is considered recognized
        if (item.matches.length > 1) {
          for (
            var i = 0, length = item.matches.length;
            i < length && !isRecognized;
            i++
          ) {
            if (
              item.matches[i].country === item.country &&
              item.matches[i].currency === item.currency
            ) {
              holding = item.matches[i];
              holding[weightProperty] = item[weightProperty];

              recognized.push(holding);

              isRecognized = true;
            }
          }
          if (!isRecognized) {
            multiple.push(item);
          }
        }
        // unknown instrument
        if (item.matches.length === 0) {
          unknown.push(item);
        }
      }

      var data = {
        multiple: multiple,
        recognized: recognized,
        unknown: unknown,
      };

      dataDisplay(data);
    },
    [dataDisplay, dataPrepareGroupMatches, mode, property]
  );

  const fetchImportedInstruments = useCallback(
    async (params) => {
      const instruments = params["instruments"];
      const market = params["market"];
      const symbology = params["symbology"];

      const _params: any = {
        page: {
          page: 1,
          rows: 999999,
        },
        filters: [
          {
            dimension: symbology,
            segments: [],
          },
        ],
      };
      for (let i = 0, length = instruments.length; i < length; i++) {
        _params.filters[0].segments.push(instruments[i].label.toUpperCase());
      }

      if (market) {
        _params.filters.push({
          dimension: "country",
          segments: [market],
        });
      }

      var properties =
        widgetsConfiguration["widgets/analysis/collection/edit"]["properties"];
      properties.push({
        date: null,
        property: symbology,
      });

      const response = await instrumentsAPI.newFilterAndFetch(
        _params,
        "security",
        properties
      );

      prepareInstruments(response, params);
    },
    [instrumentsAPI, prepareInstruments]
  );

  const listenerImport = useCallback(
    async (e) => {
      const params = e.value;

      fetchImportedInstruments(params);
    },
    [fetchImportedInstruments]
  );

  const toggleAddPosition = useCallback(() => {
    setShowAddPosition((current) => !current);
  }, []);

  const addPosition = useCallback(
    async (position) => {
      const properties =
        widgetsConfiguration["widgets/analysis/collection/add-security"][
          "properties"
        ];
      const weightProperty = mode === "edit" ? property : "weight";
      const _instrument = position;

      const response = await instrumentsAPI.fetch({
        properties: properties,
        symbols: [_instrument["symbol"]],
        type: "security",
      });

      const instrument = response["data"][0];

      if (!("weight" in _instrument)) {
        instrument[weightProperty] = 0.0;
      } else {
        instrument[weightProperty] = _instrument["weight"];
      }

      if (mode === "edit") {
        setValue((currentValue) => {
          const updatedValue = [...currentValue];

          updatedValue.unshift(instrument);
          return updatedValue;
        });
      } else {
        setPositionsToInser((currentValue) => {
          const updatedValue = [...currentValue];

          updatedValue.unshift({
            name: instrument.name,
            weight: instrument[weightProperty],
            symbol: instrument.symbol,
          });
          return updatedValue;
        });
      }
    },
    [instrumentsAPI, mode, property]
  );

  const addPositonAndCloseSearch = useCallback(
    (event) => {
      if (event) {
        addPosition(event);
        setShowAddPosition(false);
      }
    },
    [addPosition]
  );

  const addFromUnrecognised = useCallback(
    (event, ticker) => {
      if (event) {
        addPosition(event);
        setUnrecognised((current) => {
          const newState = [...current];
          return newState.filter((instrument) => instrument.ticker !== ticker);
        });
      }
    },
    [addPosition]
  );

  const selectMuliple = useCallback(
    (security) => {
      addPosition(security);
      setMultiple((currentValue) => {
        const newState = [...currentValue];

        return newState.filter((item) => item.label !== security.ticker);
      });
    },
    [addPosition]
  );

  const saveAllocation = useCallback(() => {
    const data = {
      collection:
        mode === "edit"
          ? value
          : positionsToInsert.map((pos) => ({
              symbol: pos.symbol,
              [`date_${date}`]: pos.weight,
            })),
      property: mode === "edit" ? property : `date_${date}`,
    };

    if (isValidDate) {
      onSave(data);
    }
  }, [date, isValidDate, mode, onSave, positionsToInsert, property, value]);

  const changeDate = useCallback(
    (value) => {
      const updatedDate = dateToDays(value);
      setDate(updatedDate);
    },
    [dateToDays]
  );

  //#region - TrendratingTableV2 SETUP AND HANDLERS
  const tableRef = useRef<{ getInstance: () => TableV2 }>();
  const [tableReady, setTableReady] = useState(false);
  const [columnsLoaded, setColumnsLoaded] = useState(false);

  useEffect(() => {
    if (tableReady && columnsLoaded) {
      const table = tableRef?.current?.getInstance();
      table?.insertData(mode === "edit" ? value : positionsToInsert);
    }
  }, [columnsLoaded, mode, positionsToInsert, tableReady, value]);

  useEffect(() => {
    if (tableReady) {
      const table = tableRef?.current?.getInstance();
      table?.insertColumns(trColums);
    }
  }, [trColums, tableReady]);

  const tableOptions = useMemo(() => {
    return {
      tableOption: {
        ajaxSorting: false,
      },
    };
  }, []);
  const tableEvents: TableEventsV2 = useMemo(
    () => ({
      onTableBuilt: () => {
        setTableReady(true);
      },
      onTableDestroyed: () => {
        setTableReady(false);
      },
      columnsLoaded: (columns) => {
        if (columns.length) {
          setColumnsLoaded(true);
        }
      },
      rowMouseEnter(e, row) {
        // const rowComponent: RowComponent = e.value;
        const cells = row.getCells();
        const buttonCell = cells[cells.length - 1];
        if (buttonCell) {
          const btnCellElement = buttonCell.getElement();
          btnCellElement.classList.remove(styles.invisible);
        }
      },
      rowMouseLeave(e, row) {
        // const rowComponent: RowComponent = e.value;
        const cells = row.getCells();
        const buttonCell = cells[cells.length - 1];
        if (buttonCell) {
          const btnCellElement = buttonCell.getElement();
          btnCellElement.classList.add(styles.invisible);
        }
      },
    }),
    []
  );

  //#endregion

  return (
    <ErrorBoundary
      fallback={
        <Typography>
          An unexpected error occures, please try to refresh the page if the
          problem persists please try to contact our customers support
        </Typography>
      }
    >
      <Box
        display={"flex"}
        flex={1}
        minHeight={0}
        flexDirection={"column"}
        gap={1}
        minWidth={0}
      >
        {mode === "insert" && (
          <Box display={"flex"} gap={1} alignItems={"center"}>
            <Typography>Insert allocation at </Typography>
            <Box
              display={"flex"}
              flexDirection={"column"}
              alignItems={"flex-start"}
              justifyContent={"center"}
            >
              <DatePicker
                input={formatDate(date)}
                onChangeDate={changeDate}
                disableFuture
                isValid={isValidDate}
              />
              {!isValidDate && (
                <Typography sx={{ color: "red" }}>
                  An allocation on this date already exists please select
                  another date
                </Typography>
              )}
            </Box>
          </Box>
        )}
        <Box display={"flex"} gap={2} p={1}>
          <Button variant="tr_button_cancel" onClick={toggleAddPosition}>
            Add Position
          </Button>
          <Button variant="tr_button_cancel" onClick={normalize}>
            100%
          </Button>
          <Button variant="tr_button_cancel" onClick={equalize}>
            Equal weights
          </Button>
          <DialogUpload onImport={listenerImport} />
        </Box>
        {showAddPosition && (
          <Search
            showInstrumentInfoOnSelect={false}
            onSelectInstrument={addPositonAndCloseSearch}
          />
        )}
        <Box
          display={"flex"}
          flexDirection={"column"}
          flex={1}
          minHeight={0}
          minWidth={0}
          p={1}
          gap={1}
        >
          <Box display={"flex"} width={"100%"} height={"100%"}>
            <TrendratingTableV2
              ref={tableRef}
              tableEvents={tableEvents}
              tableOptions={tableOptions}
            />
          </Box>
          <Box>
            Total weight: <strong>{totalWeight}</strong> Number of positions:{" "}
            <strong>
              {mode === "edit" ? value.length : positionsToInsert.length}
            </strong>
          </Box>
          {(unrecognized != null && unrecognized.length) ||
          (multiple != null && multiple.length) ? (
            <Box flex={1} display={"flex"}>
              <div
                className={`tDataImporter-manualList ${styles["dataImporter"]}`}
                data-dojo-attach-point="nodeManual"
              >
                <h2 className="title">
                  Instruments with multiple or unrecognized matches
                </h2>
                <p>Click on the ticker to select the correct match</p>
                <div
                  className={`tDataImporter-manualList-table ${styles["dataImporter_table"]}`}
                  data-dojo-attach-point="nodeManualList"
                >
                  {multiple && multiple.length ? (
                    <>
                      {multiple.map((item) => {
                        return (
                          <div
                            key={uuidv4()}
                            className={`import__unrecognized`}
                          >
                            <span className="import__unrecognized-label">
                              {item.label}
                            </span>
                            {item.matches.map((position) => {
                              position["weight"] = item["weight"];
                              return (
                                <button
                                  onClick={() => selectMuliple(position)}
                                  key={uuidv4()}
                                  className="import__unrecognized-option"
                                  title={`${position.ticker} (${
                                    markets[position.country]
                                      ? markets[position.country]["name"]
                                      : position.country
                                  }) ${position.name} (${position.type})`}
                                >
                                  <span className="import__unrecognized-ticker">
                                    {position.ticker}
                                  </span>{" "}
                                  {`(${position.type}:${position.country})`}{" "}
                                  <span className="import__unrecognized-name">
                                    {position.name}
                                  </span>
                                </button>
                              );
                            })}
                          </div>
                        );
                      })}
                    </>
                  ) : (
                    <></>
                  )}

                  {unrecognized && unrecognized.length ? (
                    unrecognized.map((item) => {
                      return (
                        <div key={uuidv4()} className="import__unrecognized">
                          <span className="import__unrecognized-label">
                            {item.label}
                          </span>{" "}
                          <div className="search--collection-import"></div>
                          <Search
                            showInstrumentInfoOnSelect={false}
                            onSelectInstrument={(e) =>
                              addFromUnrecognised(e, item.ticker)
                            }
                          />
                        </div>
                      );
                    })
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </Box>
          ) : (
            <></>
          )}
        </Box>

        <Box display={"flex"} gap={2} p={1} justifyContent={"flex-end"}>
          <Button variant="contained" onClick={saveAllocation}>
            Save
          </Button>
          <Button variant="tr_button_cancel" onClick={onCancel}>
            Cancel
          </Button>
        </Box>
      </Box>
    </ErrorBoundary>
  );
}
