import { Box, Card, CardContent } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { WhatWherePie } from "../../../../../../../components/WhatWherePie/WhatWherePie";
import { PortfolioAnalyzeStorage } from "../../../../../storage/PortfolioAnalyzeStorage";
import { WhatWherePositions } from "./WhatWherePositions";
import { WhatWhereTable } from "./WhatWhereTable";
import { config } from "../../../../../config-ts";
import ReportButton from "../../../../../widgets/app-infrastructure/workflowBar/actions/report/ReportButton";
import { useEnvironment } from "../../../../../../../hooks/useEnvironment";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useBroadcast } from "../../../../../../../hooks/useBroadcast";

type TabAllocationProps = { dataManager: PortfolioAnalyzeStorage };

export function TabAllocation({ dataManager }: TabAllocationProps) {
  const [portfolioAssetTypes, setPortfolioAssetTypes] = useState<any>();
  const [positionsToday, setPositionsToday] = useState<any>();
  const [portfolioId, setPortfolioId] = useState<any>();
  const [portfolioType, setPortfolioType] = useState<any>();
  const [dimensions, setDimensions] = useState<any>(undefined);
  const [portfolioName, setPortfolioName] = useState("");
  const [isReadOnly, setIsReadOnly] = useState(true);

  const environment = useEnvironment();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { broadcast } = useBroadcast();

  const renderPositionsTable = useMemo(
    () => portfolioId && portfolioAssetTypes && portfolioType && positionsToday,
    [portfolioAssetTypes, portfolioId, portfolioType, positionsToday]
  );

  const renderWhatWhereTable = useMemo(() => {
    return Boolean(positionsToday);
  }, [positionsToday]);

  const configuration = useMemo(() => {
    return environment.get("account").product.configuration;
  }, [environment]);

  const configurationWorkflow = useMemo(() => {
    return configuration["analysis_list"]["workflow"];
  }, [configuration]);

  const onPageMount = useCallback(async () => {
    try {
      const portfolio = await dataManager.get("allocation");
      const assetTypes = portfolio.assetType;

      setPortfolioAssetTypes(assetTypes);
      setPortfolioName(portfolio?.name);
      setPositionsToday(portfolio?.positions);
      setPortfolioId(portfolio?.id);
      setPortfolioType(portfolio?.type);
      setIsReadOnly(portfolio?.isReadOnly);
    } catch (error) {
      console.log(error);
    }
  }, [dataManager]);

  const listenerClick = useCallback((e) => {
    const value = e.value;

    setDimensions({ type: value.type, id: [value.id] });
  }, []);

  useEffect(() => {
    onPageMount();
  }, [onPageMount]);

  const goToOptimize = useCallback(() => {
    const uri = `/app/analysis/lists/${portfolioId}/optimize/`;

    // *************************** USAGE ***************************
    var usage = window.App.usage;
    var info = {
      action: "LANDING",
      actionParam: portfolioId,
      function: "PORTFOLIO_OPTIMIZE",
    };
    usage.record(info);
    // *************************** USAGE ***************************

    navigate(uri);
  }, [navigate, portfolioId]);

  const manageWorkflow = useCallback(() => {
    let actions: any = [];
    let action: any = null;

    action = {
      componentJSX: (
        <ReportButton
          page={"analysisList"}
          target={{ id: portfolioId }}
          rankingCache={null}
          title={portfolioName ?? ""}
          usage={window.App.usage}
        />
      ),
    };

    actions.push(action);

    if (!isReadOnly && configurationWorkflow["optimize"]["enabled"] === true) {
      if (portfolioType === "PORTFOLIO") {
        action = {
          componentJSX: (
            <li className="menu__item" onClick={goToOptimize}>
              {t("Optimize")}
            </li>
          ),
        };

        actions.push(action);
      }
    }

    var message = {
      from: "analysisList",
      content: {
        actions,
      },
    };

    broadcast(config["channels"]["workflow"]["input"], message);
  }, [
    broadcast,
    configurationWorkflow,
    goToOptimize,
    isReadOnly,
    portfolioId,
    portfolioName,
    portfolioType,
    t,
  ]);

  useEffect(() => {
    manageWorkflow();
  }, [manageWorkflow]);

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      flex={1}
      height={"100%"}
      minHeight={0}
      gap={1}
    >
      <Card>
        <CardContent>
          {positionsToday && portfolioAssetTypes && (
            <WhatWherePie
              holdings={positionsToday}
              alignChartOnTop={false}
              optionsByAssetType={{
                enabled: true,
                assetType: portfolioAssetTypes,
              }}
              handlers={{
                pieClick: listenerClick,
              }}
              isPieClickable={true}
            />
          )}
        </CardContent>
      </Card>
      <Box display={"flex"} gap={1} flex={1} minHeight={0}>
        <Card sx={{ flex: 3, display: "flex", minHeight: 0, minWidth: 0 }}>
          <CardContent
            sx={{ display: "flex", flex: 1, minHeight: 0, minWidth: 0 }}
          >
            {renderWhatWhereTable && (
              <WhatWhereTable
                onCellClick={listenerClick}
                portfolioAssetType={portfolioAssetTypes}
                portfolioPositions={positionsToday}
              />
            )}
          </CardContent>
        </Card>
        <Card sx={{ flex: 1 }}>
          <CardContent
            sx={{ height: "100%", display: "flex", minHeight: 0, minWidth: 0 }}
          >
            {renderPositionsTable && (
              <WhatWherePositions
                portfolioId={portfolioId}
                assetType={portfolioAssetTypes}
                type={portfolioType}
                portfolioHoldings={positionsToday}
                dimensions={dimensions}
              />
            )}
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
}
