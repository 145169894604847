import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ColumnDefinition } from "tabulator-tables";
import {
  TableEventsV2,
  TableV2,
} from "../../../../../../components/table/v2/TableCoreV2";
import { TrendratingTableV2 } from "../../../../../../components/table/v2/TableV2";
import { useEnvironment } from "../../../../../../hooks/useEnvironment";
import { useFormatter } from "../../../../../../hooks/useFormatter";
import { InfoTooltip } from "../../InfoTooltip";

type IndicesWidgetProps = {
  data: {
    indexTableData: any[];
    sectorsTableData: any[];
  };
  accordionHandler: Function;
  accordionState: boolean;
};

const formatterTcr = (cell, formatter) => {
  const object = cell.getData();
  const value = cell.getValue();

  const tcr = [
    formatter.custom("rating", {
      options: {
        hasTrendArrow: false,
        notAvailable: {
          input: 0,
          output: "",
        },
      },
      output: "HTML",
      value: object["rrr"],
      valueHelper: {
        rateChange: null,
        rateDate: null,
        ratePrev: null,
      },
    }),
    '<span class="tTableAlerts-arrow i-arrow-r"></span>',
    formatter.custom("rating", {
      options: {
        hasTrendArrow: false,
        notAvailable: {
          input: 0,
          output: "",
        },
      },
      output: "HTML",
      value: value,
      valueHelper: {
        rateChange: null,
        rateDate: null,
        ratePrev: null,
      },
    }),
  ].join(" ");

  return tcr;
};

const nameFormatter = (cell, formatter) => {
  return formatter.table(cell.getField(), "table", cell.getData()) ?? "";
};

export function IndicesWidget({
  data,
  accordionHandler,
  accordionState,
}: IndicesWidgetProps) {
  const { t } = useTranslation();
  const { indexTableData, sectorsTableData } = data ?? {
    indexTableData: [],
    sectorsTableData: [],
  };
  const formatter = useFormatter();

  //   -----
  const environment = useEnvironment();

  const securityLink =
    environment.get("configuration")["configuration"]["pages"]["alerts"][
      "securityLink"
    ];

  const onRowClickHandler = useCallback(
    (e, row) => {
      if (securityLink !== "popup_instrument") {
        const data = row.getData();
        const symbol = data?.symbol ?? null;
        if (symbol) {
          const url = `/app/analysis/instrument/${symbol}/`;
          window.location.href = url;
        }
      }
    },
    [securityLink]
  );

  const columns: ColumnDefinition[] = useMemo(
    () => [
      {
        title: "Name",
        field: "name",
        resizable: false,
        formatter: (cell) => nameFormatter(cell, formatter),
        sorter: "string",
      },
      {
        field: "Rating",
        title: "Rating",
        resizable: false,
        headerHozAlign: "center",
        formatter: (cell) => formatterTcr(cell, formatter),
        sorter: "number",
      },
    ],
    [formatter]
  );

  const [tableReady, setTableReady] = useState(false);

  const tableIndexRef = useRef<{ getInstance: () => TableV2 }>();
  const tableSectorsRef = useRef<{ getInstance: () => TableV2 }>();

  useEffect(() => {
    if (tableReady) {
      const table = tableIndexRef.current?.getInstance();
      table?.insertData(data.indexTableData);
    }
  }, [data, tableReady]);

  useEffect(() => {
    if (tableReady) {
      const table = tableSectorsRef.current?.getInstance();
      table?.insertData(data.sectorsTableData);
    }
  }, [data, tableReady]);

  const tableOptions = useMemo(() => {
    return {
      tableOption: {
        ajaxSorting: false,
        movableColumns: false,
        columns,
      },
    };
  }, [columns]);

  const tableEvents: TableEventsV2 = useMemo(() => {
    return {
      rowClick: onRowClickHandler,
      onTableBuilt: () => setTableReady(true),
    };
  }, [onRowClickHandler]);

  const tableIndices = useMemo(() => {
    return (
      <TrendratingTableV2
        ref={tableIndexRef}
        tableOptions={tableOptions}
        tableEvents={tableEvents}
        rowTooltipFormatter
      />
    );
  }, [tableEvents, tableOptions]);

  const tableSectors = useMemo(() => {
    return (
      <TrendratingTableV2
        ref={tableSectorsRef}
        tableOptions={tableOptions}
        tableEvents={tableEvents}
        rowTooltipFormatter
      />
    );
  }, [tableEvents, tableOptions]);

  const tableWrapperStyle = useMemo(
    () => ({ display: "flex", flex: 1, minHeight: 0 }),
    []
  );

  return (
    <>
      <div className="tNews-accordionTitle" onClick={() => accordionHandler()}>
        <h2 className="tNews-accordionTitle-title">
          {`Markets ${t("instrument_index_plural")} &
            ${t("instrument_sector_plural")} Indices`}
          <div className="tooltip-wrapper-icon">
            <InfoTooltip
              text={"A snapshot of trend developments in specific indices."}
              callToAction={
                "Bring your portfolio risk management to the next level"
              }
              title={"Markets indices & Sector indices"}
            />
          </div>
        </h2>
        <span
          className={`i-${accordionState ? "down" : "up"} tNews-accordionIcon`}
          data-dojo-attach-point="indexAccordionIcon"
        ></span>
      </div>
      {accordionState && (
        <div
          className="tNewsInstruments--indices--sectors-wrapper"
          style={{
            minHeight: 0,
            minWidth: 0,
            overflow: "hidden",
            height: "100%",
          }}
        >
          <div
            data-dojo-attach-point="nodeIndices"
            style={{
              minHeight: 0,
              minWidth: 0,
              height: "100%",
              width: "40%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <h3 className="table-instruments-label">Markets indices</h3>
            {indexTableData?.length ? (
              <div style={tableWrapperStyle}>{tableIndices}</div>
            ) : (
              <div
                data-dojo-attach-point="widgetEmptyIndices"
                className="empty-news-data"
              >
                No alert found for indices
              </div>
            )}
          </div>
          <div
            data-dojo-attach-point="nodeSectors"
            style={{
              minHeight: 0,
              minWidth: 0,

              height: "100%",
              width: "40%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <h3 className="table-instruments-label">Sectors indices</h3>
            {sectorsTableData?.length ? (
              <div style={tableWrapperStyle}>{tableSectors} </div>
            ) : (
              <div
                data-dojo-attach-point="widgetEmptySectors"
                className="empty-news-data"
              >
                No alert found for sectors
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}
