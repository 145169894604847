import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ColumnDefinition } from "tabulator-tables";
import {
  reactFormatter,
  TableEventsV2,
  TableV2,
} from "../../../../../components/table/v2/TableCoreV2";
import { TrendratingTableV2 } from "../../../../../components/table/v2/TableV2";
import { deepClone } from "../../../../../deepClone";
import { Environment } from "../../../../../Environment";
import { useEnvironment } from "../../../../../hooks/useEnvironment";
import { useEventBus } from "../../../../../hooks/useEventBus";
import { useFormatter } from "../../../../../hooks/useFormatter";
import { ListViewerTileItemMenu } from "./ListViewerTileItemMenu";

type ListViewerTableProps = {
  data: any;
  rowClickListener: (list, e) => void;
  timeframe: string;
  listType: string;
  sort:
    | "name"
    | "name_rev"
    | "TCR"
    | "TCR_rev"
    | "CD"
    | "CD_rev"
    | "AB"
    | "AB_rev"
    | "upgrades"
    | "downgrades"
    | "movers_up"
    | "movers_down";
  setSort: (sorter) => void;
};

const formatterTcr = (cell, format, suffix) => {
  const object = cell.getData();
  const today = object["TCR" + suffix];
  const yesterday = object["TCR_D"];

  let tcr = "";

  if (today !== yesterday) {
    tcr = [
      format.tcr(yesterday, "HTML"),
      '<span class="i-arrow-r"></span>',
      format.tcr(today, "HTML"),
    ].join(" ");
  } else {
    tcr = format.tcr(today, "HTML");
  }

  return tcr;
};

const formatName = (cell) => {
  const cssClassName = "tTableLists-name";
  const cssClassNameA = "tTableLists-name--A";
  const cssClassNameB = "tTableLists-name--B";
  const cssClassNameC = "tTableLists-name--C";
  const cssClassNameD = "tTableLists-name--D";

  const value = cell.getValue();
  const node = cell.getElement();
  node.classList.add(cssClassName, "no-col-borders");
  const object = cell.getData();

  switch (object["tcr"]) {
    case 4:
    case 3: {
      node.classList.add(cssClassNameA);

      break;
    }
    case 2:
    case 1:
    case 0: {
      node.classList.add(cssClassNameB);

      break;
    }
    case -1:
    case -2:
    case -3: {
      node.classList.add(cssClassNameC);

      break;
    }
    case -4:
    case -5: {
      node.classList.add(node, cssClassNameD);

      break;
    }
    default: {
      // unrated
    }
  }

  return (
    value +
    (object["isReadOnly"]
      ? ' <span class="sharedObjectIndicator sharedObjectIndicator--small"></span>&nbsp;'
      : "")
  );
};

const abPercentageFormatter = (cell) => {
  const data = cell.getData();
  let abPerc = data?.["A_perc"] ?? 0;

  if (data["B_perc"]) {
    abPerc += data["B_perc"];
  }

  return `${(abPerc * 100).toFixed(2)}%`;
};

const cdPercentageFormatter = (cell) => {
  const data = cell.getData();
  let abPerc = data?.["C_perc"] ?? 0;

  if (data["D_perc"]) {
    abPerc += data["D_perc"];
  }

  return `${(abPerc * 100).toFixed(2)}%`;
};

const formatterAlert = (cell) => {
  const value = cell.getValue();
  cell.getElement().classList.add("no-col-borders");

  if (value === 0 || value === undefined) {
    return "-";
  }

  return String(value);
};

const Menu = ({
  cell,
  menuCallBack,
  environment,
}: {
  cell?: any;
  menuCallBack: (e) => void;
  environment: Environment;
}) => {
  const list = useMemo(() => cell.getData(), [cell]);

  const onClickHandler = useCallback(
    (value) => {
      const data = deepClone(value["value"]);
      data["list"] = list;
      menuCallBack(data);
    },
    [list, menuCallBack]
  );

  return list ? (
    <ListViewerTileItemMenu
      environment={environment}
      isReadOnly={list.isReadOnly}
      optionType={list.type.toLowerCase()}
      onOptionClickHandler={onClickHandler}
      smallBtn
    />
  ) : (
    <></>
  );
};

export function ListTable({
  timeframe,
  data,
  rowClickListener,
  sort,
  setSort,
}: ListViewerTableProps) {
  const { t } = useTranslation();
  const formatter = useFormatter();
  const cssClassMovers = "tTableLists-movers";
  const cssClassMoversDown = "tTableLists-moversDown";
  const cssClassMoversUp = "tTableLists-moversUp";
  const { dispatch } = useEventBus();
  const environment = useEnvironment();
  const suffix = useMemo(() => {
    switch (timeframe) {
      case "daily":
        return "";
      case "weekly":
        return "_W";
      case "monthly":
        return "_M";
    }
  }, [timeframe]);

  const menuCallBack = useCallback(
    (event) => {
      dispatch("menu-click", event);
    },
    [dispatch]
  );

  //#region - TrendratingTableV2 SETUP AND HANDLERS
  const tableRef = useRef<{ getInstance: () => TableV2 }>();
  const [tableStatus, setTableStatus] = useState({
    columns: false,
    built: false,
  });
  const tableReady = useMemo(
    () => tableStatus.columns && tableStatus.built,
    [tableStatus.built, tableStatus.columns]
  );
  const columns: ColumnDefinition[] = useMemo(
    () => [
      {
        field: "name",
        title: t("name"),
        resizable: false,
        formatter: (cell) => formatName(cell),
        widthGrow: 3,
        cssClass: "no-col-borders",
        headerHozAlign: "left",
        hozAlign: "left",
        headerClick: () => setSort("name"),
        sorterParams: { alignEmptyValues: "bottom" },
        sorter: "string",
      },
      {
        field: "TCR" + suffix,
        cssClass: "no-col-borders",
        formatter: (cell) => formatterTcr(cell, formatter, suffix),
        title: t("tcr"),
        resizable: false,
        widthGrow: 1,
        headerHozAlign: "center",
        hozAlign: "center",
        headerClick: () => setSort("TCR"),
        sorterParams: { alignEmptyValues: "bottom" },
        sorter: (a, b, aRow, bRow): any => {
          let aData = aRow.getData();
          let bData = bRow.getData();
          let _a = aData.TCR;
          let _b = bData.TCR;
          if (_a !== undefined && _b !== undefined) {
            if (_a > _b) {
              return -1;
            }
            if (_a < _b) {
              return 1;
            }
          } else if (_a !== undefined && _b === undefined) {
            return -1;
          } else if (_a === undefined && _b !== undefined) {
            return 1;
          } else {
            return -1;
          }
          return 0;
        },
      },
      {
        title: "CD%",
        field: "_s_cd",
        headerClick: () => setSort("CD"),
        resizable: false,
        widthGrow: 1,
        headerHozAlign: "center",
        hozAlign: "center",
        formatter: (cell) => cdPercentageFormatter(cell),
        sorter: (a, b, aRow, bRow, column, dir, sorterParams) => {
          const cell_a = aRow.getData();
          const cell_b = bRow.getData();

          const firstRowPerc = cell_a?._s_cd;
          const secondRowPerc = cell_b?._s_cd;

          if (firstRowPerc > secondRowPerc) {
            return 1;
          }

          if (firstRowPerc < secondRowPerc) {
            return -1;
          }

          return cell_a.name > cell_b.name ? -1 : 1;
        },
        sorterParams: { alignEmptyValues: "bottom" },
        cssClass: "no-col-borders",
      },
      {
        title: "AB%",
        field: "_s_ab",
        headerClick: () => setSort("AB"),
        resizable: false,
        widthGrow: 1,
        headerHozAlign: "center",
        hozAlign: "center",
        formatter: (cell) => abPercentageFormatter(cell),
        sorter: (a, b, aRow, bRow, column, dir, sorterParams) => {
          const cell_a = aRow.getData();
          const cell_b = bRow.getData();

          const firstRowPerc = cell_a?._s_ab;
          const secondRowPerc = cell_b?._s_ab;

          if (firstRowPerc > secondRowPerc) {
            return 1;
          }

          if (firstRowPerc < secondRowPerc) {
            return -1;
          }

          return cell_a.name > cell_b.name ? -1 : 1;
        },
        cssClass: "no-col-borders",
        sorterParams: { alignEmptyValues: "bottom" },
      },
      {
        field: "upgrades" + suffix,
        headerClick: () => setSort("upgrades"),
        cssClass: "no-col-borders clickable",
        formatter: (cell) => formatterAlert(cell),
        title: t("upgrades"),
        resizable: false,
        widthGrow: 2,
        hozAlign: "center",
        headerHozAlign: "center",
        sorterParams: { alignEmptyValues: "bottom" },
        sorter: (a, b, aRow, bRow): any => {
          let aData = aRow.getData();
          let bData = bRow.getData();
          let _a = aData["upgrades" + suffix];
          let _b = bData["upgrades" + suffix];
          if (_a > _b) {
            return 1;
          } else if (_b > _a) {
            return -1;
          }
          return 0;
        },
      },
      {
        field: "downgrades" + suffix,
        headerClick: () => setSort("downgrades"),
        cssClass: "no-col-borders clickable",
        formatter: (cell) => formatterAlert(cell),
        title: t("downgrades"),
        resizable: false,
        widthGrow: 2,
        headerHozAlign: "center",
        hozAlign: "center",
        sorterParams: { alignEmptyValues: "bottom" },
        sorter: "number",
      },
      {
        title: t("movers"),
        resizable: false,
        headerHozAlign: "center",
        hozAlign: "center",
        cssClass: `${cssClassMovers} no-col-borders`,
        widthGrow: 1,
        columns: [
          {
            headerHozAlign: "center",
            cssClass: "no-col-borders clickable",
            hozAlign: "center",
            formatter: (cell) => formatterAlert(cell),
            resizable: false,
            headerClick: () => setSort("movers_up"),
            title:
              '<span class="i-triangle-up ' + cssClassMoversUp + '"></span>',
            field: "ABnewHigh",
            sorterParams: { alignEmptyValues: "bottom" },
          },
          {
            headerHozAlign: "center",
            cssClass: "no-col-borders clickable",
            hozAlign: "center",
            field: "CDnewLow",
            formatter: (cell) => formatterAlert(cell),
            headerClick: () => setSort("movers_down"),
            resizable: false,
            title:
              '<span class="i-triangle-down ' +
              cssClassMoversDown +
              '"></span>',
            sorterParams: { alignEmptyValues: "bottom" },
          },
        ],
      },
      {
        title: "",
        field: "",
        headerSort: false,
        headerHozAlign: "center",
        cssClass: "no-col-borders",
        hozAlign: "center",
        resizable: false,
        widthGrow: 1,
        formatter: reactFormatter(
          <Menu menuCallBack={menuCallBack} environment={environment} />
        ),
      },
    ],
    [environment, formatter, menuCallBack, setSort, suffix, t]
  );
  const sorterObject = useMemo(() => {
    const sorterObj = {};
    switch (sort) {
      default:
      case "name": {
        sorterObj["field"] = "name";
        sorterObj["direction"] = "asc";
        break;
      }

      case "name_rev": {
        sorterObj["field"] = "name";
        sorterObj["direction"] = "desc";

        break;
      }

      case "TCR": {
        sorterObj["field"] = "TCR" + suffix;
        sorterObj["direction"] = "asc";

        break;
      }

      case "TCR_rev": {
        sorterObj["field"] = "TCR" + suffix;
        sorterObj["direction"] = "desc";

        break;
      }

      case "CD": {
        sorterObj["field"] = "_s_cd";
        sorterObj["direction"] = "desc";

        break;
      }

      case "CD_rev": {
        sorterObj["field"] = "_s_cd";
        sorterObj["direction"] = "asc";

        break;
      }

      case "AB": {
        sorterObj["field"] = "_s_ab";
        sorterObj["direction"] = "desc";

        break;
      }

      case "AB_rev": {
        sorterObj["field"] = "_s_ab";
        sorterObj["direction"] = "asc";

        break;
      }

      case "upgrades": {
        sorterObj["field"] = "upgrades" + suffix;

        break;
      }

      case "downgrades": {
        sorterObj["field"] = "downgrades" + suffix;

        break;
      }

      case "movers_up": {
        sorterObj["field"] = "ABnewHigh";
        sorterObj["direction"] = "desc";

        break;
      }

      case "movers_down": {
        sorterObj["field"] = "CDnewLow";
        sorterObj["direction"] = "desc";

        break;
      }
    }
    return sorterObj;
  }, [sort, suffix]);
  const tableOptions: any = useMemo(() => {
    return {
      tableOption: {
        ajaxSorting: false,
        movableColumns: false,
        headerHozAlign: "right",
        selectableRows: 1,
        virtualDom: true,
        virtualDomBuffer: 30,
      },
    };
  }, []);
  const tableEvents: TableEventsV2 = useMemo(
    () => ({
      rowClick: (e, row) => {
        const htmlRow = row.getElement();
        const rowData = row.getData();
        htmlRow.classList.add("selected");
        const list = data.find((el) => el.name === rowData.name);
        rowClickListener(list, e);
      },
      onTableBuilt: () => {
        setTableStatus((current) => ({ ...current, built: true }));
      },
      onTableDestroyed: () => {
        setTableStatus((current) => ({ ...current, built: false }));
      },
      columnsLoaded: (cols) => {
        if (cols.length) {
          setTableStatus((current) => ({ ...current, columns: true }));
        }
      },
    }),
    [data, rowClickListener]
  );
  const tableData: any = useMemo(() => data, [data]);

  useEffect(() => {
    if (tableStatus.built) {
      const table = tableRef.current?.getInstance();

      table?.insertColumns(columns);
    }
  }, [columns, tableStatus.built]);

  useEffect(() => {
    if (tableReady) {
      const table = tableRef.current?.getInstance();
      table?.insertData(tableData);
      table?.sort((sorterObject as any).field, (sorterObject as any).direction);
    }
  }, [sorterObject, tableData, tableReady]);
  //#endregion

  return (
    <TrendratingTableV2
      ref={tableRef}
      tableEvents={tableEvents}
      tableOptions={tableOptions}
    />
  );
}
