/**
 * @author Trendrating <info@trendrating.net>
 *
 * @module trendrating-report/generator/Generator
 * @summary Format and create SVG chart suitable for the PDF service
 *
 */

import insane from "insane";
import { Rankings } from "../../../api/compute/Rankings";
import {
  formatTaxonPrefixingAncestor,
  getTaxonById,
} from "../../../api/compute/Taxon";
import { Properties } from "../../../api/Properties";
import { Report } from "../../../api/report/Report";
import { deepClone } from "../../../deepClone";
import { sortBy } from "../../../trendrating/core/UtilsObject";
import { _color } from "../../../trendrating/formatter/_color";
import { _rate } from "../../../trendrating/formatter/_rate";
import { _Base } from "../_Base";
import { sectionProperties } from "../configuration/sectionProperties";
import { allocation } from "./allocation";
import { SectionLists } from "./SectionLists";
import { SectionPeer } from "./SectionPeer";
import { sectionsAsOfTodayPerformance } from "./strategyAsOfTodayPerformance";
import { sectionsBreakdown } from "./strategyBreakdown";
import { sectionsStrategyChart } from "./strategyChart";
import { sectionsFacts } from "./strategyFacts";
import { sectionsHoldings } from "./strategyHoldings";
import { sectionsKeyFacts } from "./strategyKeyFacts";
import { sectionsMonthlyAnalytics } from "./strategyMonthlyAnalytics";
import { sectionsQuarterlyAnalytics } from "./strategyQuarterlyAnalytics";
import { sectionsSummary } from "./strategySummary";
import { sectionsYearlyAnalytics } from "./strategyYearlyAnalytics";
import { sectionsYearToDateAnalytics } from "./strategyYearToDateAnalytics";

// if rows are less than expected
// add empty rows in order to guarantee height
export function addEmptyRow(rows, numberOfRows, numberOfColumns) {
  const length = rows.length;

  if (length < numberOfRows) {
    const row: any = [];
    for (let i = 0; i < numberOfColumns; i++) {
      row.push({
        value: "&nbsp;",
      });
    }

    const diffLength = numberOfRows - length;
    for (let i = 0; i < diffLength; i++) {
      rows.push(row);
    }
  }
}

export function escapeEntity(inputString) {
  if (inputString == null) {
    if (appConfig.isDebug) {
      console.warn("Generator: escapeEntity has null parameter");
      console.trace();
    }
  } else {
    return inputString.replace(/&/gi, "&amp;");
  }

  // Fallback, but if it returns empty there is something wrong at
  // the input
  return "";
}

export function format(property, datum, formatter) {
  let formatted = "";

  switch (property) {
    case "tcr": {
      let tcrValue = datum[property];
      if (datum[property] != null && datum[property]["today"] != null) {
        tcrValue = datum[property]["today"];
      }
      formatted = formatter.tcr(tcrValue, "PDF");

      break;
    }
    default: {
      formatted = formatter.pdf(
        property,
        "table",
        datum[property],
        datum,
        datum["type"]
      );
    }
  }

  return formatted;
}

export function formatTaxon(taxon, taxonomies) {
  const formatted = taxon["name"];
  // const parent = null;

  // Commented out because we never add the parent for now to act
  // like the new behaviour
  // if (taxon["type"] == "3 Sector") {
  //     parent = getTaxonById(taxon["parent"], taxonomies);

  //     formatted = parent["name"] + " - " + formatted;
  // }

  return formatted;
}

/**
 * Utility to replace formatted strings with object fields.
 *
 *
 * @param {*} inputString
 * @param {*} rawObject
 */
export function substituteVariables(inputString, sourceObject) {
  let substitutedString = inputString;
  try {
    for (const key in sourceObject) {
      substitutedString = substitutedString.replaceAll(
        "${" + key + "}",
        sourceObject[key]
      );
    }
  } catch (e) {
    console.warn(e);
  }
  substitutedString = escapeEntity(substitutedString);

  return substitutedString;
}

export function getStyleForProperty(property: string, fieldsConfiguration) {
  let field = fieldsConfiguration?.[property] ?? null;

  if (!field) {
    return null;
  }

  const mapCases = {};

  for (const key in fieldsConfiguration) {
    if (fieldsConfiguration[key]?.["formatter"]?.["table"]?.["type"]) {
      mapCases[fieldsConfiguration[key]["formatter"]["table"]["type"]] = true;
    }
  }

  const formatAs = field["formatter"]?.["table"]?.["type"] ?? null;

  switch (formatAs) {
    case "milions":
    case "number":
    case "numberBig":
    case "price":
    case "size": {
      return {
        align: "right",
      };
    }
    case "rating": {
      return {
        align: "center",
      };
    }
    case "newHighNewLow":
    case "string":
    case "taxon": {
      return {
        align: "left",
      };
    }

    default:
      return null;
  }
}

/**
 *
 * @param {string}  title
 * @param {object}  sectionData
 * @param {object}  taxonomies
 * @param {number}  paddingRows - used if there is not enough items
 *      to fill
 * @param {boolean} colorByPosition - add contrasted colors to each
 *      element
 */
export function sectionPieAllocation(
  title,
  sectionData,
  taxonomies,
  paddingRows,
  colorByPosition,
  formatter
) {
  return sectionPieHelper(
    title,
    sectionData,
    taxonomies,
    paddingRows,
    "pieAllocation",
    colorByPosition,
    formatter
  );
}

/**
 *
 * @param {string} title
 * @param {object} sectionData
 * @param {object} taxonomies
 * @param {number} paddingRows - used if there is not enough items
 *      to fill
 */
export function sectionPieTcr(
  title,
  sectionData,
  taxonomies,
  paddingRows,
  formatter
) {
  return sectionPieHelper(
    title,
    sectionData,
    taxonomies,
    paddingRows,
    "pie",
    false,
    formatter
  );
}

/**
 *
 * @param {string} title
 * @param {object} sectionData
 * @param {object} taxonomies
 * @param {number} paddingRows - used if there is not enough items
 *      to fill
 * @param {string} type - one of "pie" or "pieAllocation"
 * @param {boolean} colorByPosition - add contrasted colors to each
 *      element
 */
export function sectionPieHelper(
  title,
  sectionData,
  taxonomies,
  paddingRows,
  type,
  colorByPosition,
  formatter
) {
  if (paddingRows == null) {
    paddingRows = 5; // was previously fixed value
  }
  type = type == null ? "pie" : type;

  const section: any = {
    data: {
      legend: {
        body: [],
        head: [
          [
            {
              style: {
                align: "center",
                color: "#000000",
              },
              value: title,
            },
            { value: "" },
            { value: "" },
          ],
        ],
      },
      svg: sectionData.svg,
    },
    type: type,
  };

  let currentColor = 0;
  function getChartColor() {
    if (currentColor >= _color.trendratingColors.length) {
      currentColor = 0;
    }
    return _color.trendratingColors[currentColor++];
  }

  for (let i = 0; i < sectionData.data.length; i++) {
    const item = sectionData.data[i];

    let prefix = "";

    if (item.id !== "OTHER") {
      if (colorByPosition) {
        prefix =
          '<span color="' +
          getChartColor() +
          '" fontName="Times">&#9679;</span> ';
      }

      section.data.legend.body.push([
        {
          escapeXmlEntities: false,
          style: null,
          template: prefix + "{{ value0 }}",
          value: formatTaxon(getTaxonById(item.id, taxonomies), taxonomies),
        },
        {
          style: {
            align: "left",
          },
          value: colorByPosition
            ? ""
            : format(
                "tcr",
                {
                  tcr: item.rate,
                  type: "PEER",
                },
                formatter
              ),
        },
        {
          style: {
            align: "right",
          },
          value: format(
            "weight",
            {
              weight: item.weight,
              type: "PEER",
            },
            formatter
          ),
        },
      ]);
    } else {
      if (colorByPosition) {
        prefix = '<span color="#FFFFFF" fontName="Times">&#9679;</span> ';
      }
      section.data.legend.body.push([
        {
          style: null,
          value: prefix + "Others",
        },
        {
          style: null,
          value: "",
        },
        {
          style: {
            align: "right",
          },
          value: format(
            "weight",
            {
              weight: item.weight,
              type: "PEER",
            },
            formatter
          ),
        },
      ]);
    }
  }

  // if rows are less than expected
  // add empty rows in order to guarantee height
  addEmptyRow(section.data.legend.body, paddingRows, 3);

  return section;
}

export class Generator extends _Base {
  content: any;
  environment: any;
  data: any;
  formatter: any;
  http: any;
  printParams: any;
  portfolio: any;
  sections: any; // template sections
  taxonomies: any;
  template: any;
  userPreference: any;
  wysiwygState: any;
  fieldsConfiguration: any;

  constructor(environment, params) {
    super(params);
    if (environment == null) {
      throw new Error("[CRITICAL] No environment!");
    }
    this.environment = environment;

    this.data = params["data"];
    this.formatter = params["formatter"];
    this.http = {
      rankings: new Rankings(this.environment),
    };
    this.portfolio = params["portfolio"];
    this.sections = params["sections"];
    this.template = params["template"];
    this.userPreference = params["userPreference"];
    this.wysiwygState = params["wysiwygState"];
    this.fieldsConfiguration = this.environment.properties;
  }

  create() {
    // reset internal data
    this.content = null;
    this.printParams = null;

    const content: any = [];
    const data = this.data;
    const formatter = this.formatter;
    const sections = this.sections;
    const wysiwygState = this.wysiwygState;

    // Helper function to add multiple sections
    function aggregateMultipleSections(sections) {
      if (sections != null) {
        for (
          let sectionsIndex = 0;
          sectionsIndex < sections.length;
          sectionsIndex++
        ) {
          content.push(sections[sectionsIndex]);
        }
      }
    }

    const sectionPeer =
      wysiwygState["targetType"] === "PEER" ? new SectionPeer() : null;
    const sectionLists =
      wysiwygState["targetType"] === "LIST" ? new SectionLists() : null;

    for (let i = 0, length = data.length; i < length; i++) {
      const datum = data[i];
      const section = sections[i]; // template section

      switch (datum["type"]) {
        // #region ---------------------------------------- Alerts
        case "REPORT_LIST_ALERTS_TABLE": {
          let _data: any = {
            body: null,
            head: null,
          };

          if (section["content"]["headline"]["isEnabled"]) {
            const _section = {
              data: {
                text: escapeEntity(
                  section["content"]["headline"]["content"].toUpperCase()
                ),
              },
              type: "header1",
            };
            content.push(_section);
          }

          _data.head = [
            [
              {
                style: {
                  textAlign: "right",
                },
                value: "Name",
              },
              {
                style: null,
                value: "TCR",
              },
              {
                style: null,
                value: "Upgrades",
              },
              {
                style: null,
                value: "Downgrades",
              },
              {
                style: null,
                value: "Movers Up",
              },
              {
                style: null,
                value: "Movers Down",
              },
            ],
          ];

          _data.body = [];

          datum.forEach((item) => {
            _data.body.push([
              { style: null, value: item.name },
              {
                style: null,
                value: format(
                  "tcr",
                  {
                    tcr: item?.TCR,
                  },
                  formatter
                ),
              },
              {
                style: null,
                value:
                  item?.upgrades != null ? JSON.stringify(item.upgrades) : "-",
              },
              {
                style: null,
                value:
                  item?.downgrades != null
                    ? JSON.stringify(item.downgrades)
                    : "-",
              },
              {
                style: null,
                value:
                  item?.moversUp != null ? JSON.stringify(item.moversUp) : "-",
              },
              {
                style: null,
                value:
                  item?.moversDown != null
                    ? JSON.stringify(item.moversDown)
                    : "-",
              },
            ]);
          });

          const table = {
            data: _data,
            type: "table",
          };

          content.push(table);

          break;
        }
        // #endregion ------------------------------------------

        // #region ---------------------------------------- Peer
        case "REPORT_BASKET_DISPERSION_BY_SECTORS": {
          const _sections = sectionLists?.generateDispersionBasketTable(
            datum,
            wysiwygState,
            section
          );

          const addPageBreak = section?.content?.addPageBreak ?? false;
          const pageBreakSection = {
            data: null,
            type: "pageBreak",
          };

          if (addPageBreak === true) {
            _sections.push(pageBreakSection);
          }

          const tableSection = _sections.find((item) => item.type === "table");

          let rowNumber = 0;

          for (const key in datum.data) {
            if (key !== "Portfolio" && key !== "Basket" && key !== "All") {
              rowNumber++;
            }
          }

          if (tableSection && rowNumber < 2) {
            const hideWhenSingleRow = section.content?.hideIfOneResult;

            if (hideWhenSingleRow === false) {
              aggregateMultipleSections(_sections);
            }
          } else {
            aggregateMultipleSections(_sections);
          }

          break;
        }
        case "REPORT_DISPERSION_BY_SECTORS": {
          const _sections =
            sectionPeer?.generateDispersionBySectorIntervalTable(
              datum.datum,
              section,
              datum.peerType
            );

          const addPageBreak = section?.content?.addPageBreak ?? false;
          const pageBreakSection = {
            data: null,
            type: "pageBreak",
          };

          if (addPageBreak === true) {
            _sections.push(pageBreakSection);
          }

          const tableSection = _sections.find((item) => item.type === "table");

          let rowNumber = 0;

          for (const row of datum.datum) {
            if (!row.name.toLowerCase().includes("all")) {
              rowNumber++;
            }
          }

          if (tableSection && rowNumber < 2) {
            const hideWhenSingleRow = section.content?.hideIfOneResult;

            if (hideWhenSingleRow === false) {
              aggregateMultipleSections(_sections);
            }
          } else {
            aggregateMultipleSections(_sections);
          }

          break;
        }
        case "REPORT_BASKET_DISPERSION_BY_CHART": {
          const _sections = sectionLists?.generateDispersionChartWysiwyg(
            datum,
            wysiwygState,
            section,
            this._getPageOrientation()
          );

          const addPageBreak = section?.content?.addPageBreak ?? false;
          const pageBreakSection = {
            data: null,
            type: "pageBreak",
          };

          if (addPageBreak === true) {
            _sections.push(pageBreakSection);
          }

          if (datum?.svg != null) {
            aggregateMultipleSections(_sections);
          }

          break;
        }

        case "REPORT_DISPERSION_BY_CHART": {
          const _sections = sectionPeer?.generateDispersionChart(
            datum,
            section,
            this._getPageOrientation(),
            datum.peerType
          );

          const addPageBreak = section?.content?.addPageBreak ?? false;
          const pageBreakSection = {
            data: null,
            type: "pageBreak",
          };

          if (addPageBreak === true) {
            _sections.push(pageBreakSection);
          }

          if (datum?.svg != null) {
            aggregateMultipleSections(_sections);
          }

          break;
        }
        case "REPORT_DISPERSION_RATIO_TABLE": {
          const _sections = sectionPeer?.generateDispersionRatioTable(
            datum.data,
            section,
            datum.peerType
          );

          const tableSection = _sections.find((item) => item.type === "table");
          if (tableSection && tableSection?.data?.body?.length < 2) {
            const hideWhenSingleRow = section.content?.hideIfOneResult;

            if (hideWhenSingleRow === false) {
              aggregateMultipleSections(_sections);
            }
          } else {
            aggregateMultipleSections(_sections);
          }

          break;
        }
        case "REPORT_DISPERSION_TCR_TABLE": {
          const { dispersion, section, peerType } = datum;
          const _sections = sectionPeer?.generateDispersionByRating(
            dispersion,
            section,
            peerType
          );

          const tableSection = _sections.find((item) => item.type === "table");
          if (tableSection && tableSection?.data?.body?.length < 2) {
            const hideWhenSingleRow = section.content?.hideWhenSingleRow;

            if (hideWhenSingleRow === false) {
              aggregateMultipleSections(_sections);
            }
          } else {
            aggregateMultipleSections(_sections);
          }

          break;
        }
        case "REPORT_DISPERSION_OVERVIEW": {
          const _sections = sectionLists?.generateDispersion(
            datum.dispersion,
            section,
            datum.type
          );

          aggregateMultipleSections(_sections);

          break;
        }
        case "REPORT_PEER_DISPERSION": {
          const peerType = datum.peerType;
          const _sections = sectionPeer?.generateDispersion(
            datum.dispersion,
            section,
            peerType
          );
          aggregateMultipleSections(_sections);

          break;
        }
        case "REPORT_PEER_DISPERSION_CHILDREN": {
          const _sections = sectionPeer?.generateDispersionChildren(
            datum,
            section,
            wysiwygState.target.type
          );
          aggregateMultipleSections(_sections);

          break;
        }
        case "REPORT_PEER_TCR": {
          const _sections = sectionPeer?.generateTcr(datum, section);
          aggregateMultipleSections(_sections);

          break;
        }
        case "REPORT_CUSTOMIZABLE_PEER_TABLE": {
          const _sections = sectionPeer?.generatePeerTable(datum, section);
          const tableSection = _sections.find((item) => item.type === "table");
          const addPageBreak = section?.content?.addPageBreak ?? false;
          const pageBreakSection = {
            data: null,
            type: "pageBreak",
          };

          if (addPageBreak === true) {
            _sections.push(pageBreakSection);
          }

          if (tableSection && tableSection?.data?.body?.length < 2) {
            const hideWhenSingleRow = section.content?.hideWhenSingleRow;

            if (hideWhenSingleRow === false) {
              aggregateMultipleSections(_sections);
            }
          } else {
            aggregateMultipleSections(_sections);
          }

          break;
        }
        case "REPORT_PEER_TCR_FOCUS": {
          const _sections = sectionPeer?.generateTcrFocus(datum, section);
          aggregateMultipleSections(_sections);

          break;
        }
        case "REPORT_PEER_FOCUS_UPGRADES_DOWNGRADES": {
          const _sections = sectionPeer?.generateUpgradesDowngradesFocus(
            datum,
            section
          );
          aggregateMultipleSections(_sections);

          break;
        }
        case "REPORT_PEER_AB_CHANGES":
        case "REPORT_PEER_WHAT_AB_CHANGES": {
          const _sections = sectionPeer?.generateSectorAbChanges(
            datum,
            section
          );
          aggregateMultipleSections(_sections);

          break;
        }
        case "REPORT_PEER_OVERVIEW":
        case "REPORT_PEER_WHAT_OVERVIEW": {
          // returns multiple items
          const _sections = sectionPeer?.generateSectorOverview(datum, section);
          aggregateMultipleSections(_sections);

          break;
        }
        case "REPORT_PEER_TCR_CHANGES":
        case "REPORT_PEER_WHAT_TCR_CHANGES": {
          // returns multiple items
          const _sections = sectionPeer?.generateSectorTcrChanges(
            datum,
            section
          );
          aggregateMultipleSections(_sections);

          break;
        }
        case "REPORT_PEER_UPGRADES_DOWNGRADES": {
          const showTimeFrame = true;
          const _sections = sectionPeer?.generateSectorUpgradesDowngrades(
            datum,
            section,
            showTimeFrame
          );
          aggregateMultipleSections(_sections);

          break;
        }
        case "REPORT_PEER_WHAT_UPGRADES_DOWNGRADES": {
          // returns multiple items
          const _sections = sectionPeer?.generateSectorUpgradesDowngrades(
            datum,
            section
          );
          aggregateMultipleSections(_sections);

          break;
        }
        case "REPORT_PEER_WHERE_AB_CHANGES": {
          const _sections = sectionPeer?.generateMarketAbChanges(
            datum,
            section
          );
          aggregateMultipleSections(_sections);

          break;
        }
        case "REPORT_PEER_WHERE_OVERVIEW": {
          // returns multiple items
          const _sections = sectionPeer?.generateMarketOverview(datum, section);
          aggregateMultipleSections(_sections);

          break;
        }
        case "REPORT_PEER_WHERE_TCR_CHANGES": {
          // returns multiple items
          const _sections = sectionPeer?.generateMarketTcrChanges(
            datum,
            section
          );
          aggregateMultipleSections(_sections);

          break;
        }
        case "REPORT_PEER_WHERE_UPGRADES_DOWNGRADES": {
          // returns multiple items
          const _sections = sectionPeer?.generateMarketUpgradesDowngrades(
            datum,
            section
          );
          aggregateMultipleSections(_sections);

          break;
        }
        // #endregion ------------------------------------------
        // #region -------------------------------------- Common
        case "REPORT_COMMON_ALLOCATION": {
          // returns multiple items
          const _sections = allocation(
            wysiwygState,
            section,
            datum,
            this.formatter,
            this.environment
          );
          aggregateMultipleSections(_sections);

          break;
        }
        case "REPORT_COMMON_COVER": {
          const _sectionTitle = {
            data: {
              text: substituteVariables(
                datum["title"],
                this.wysiwygState["target"]
              ),
            },
            type: "title1",
          };
          content.push(_sectionTitle);
          const _sectionSubtitle = {
            data: {
              text: substituteVariables(
                datum["subTitle"],
                this.wysiwygState["target"]
              ),
            },
            type: "title2",
          };
          content.push(_sectionSubtitle);

          // pageBreak is automatically added/removed if
          // cover is enabled/disabled

          break;
        }
        case "REPORT_COMMON_DISCLAIMER": {
          const _section = {
            data: {
              text: datum["text"],
            },
            type: "text",
          };
          content.push(_section);

          break;
        }
        case "REPORT_COMMON_TITLE": {
          const orientation = this._getPageOrientation();
          if (orientation === "portrait") {
            const _sectionTopMargin = {
              data: {
                text: "<br/><br/>",
              },
              type: "text",
            };
            content.push(_sectionTopMargin);
          } else {
            const _sectionTopMargin = {
              data: {
                text: "<br/>",
              },
              type: "text",
            };
            content.push(_sectionTopMargin);
          }

          const _section = {
            data: {
              text: substituteVariables(
                datum["text"],
                this.wysiwygState["target"]
              ),
            },
            type: "title",
          };
          content.push(_section);

          const _sectionBottomMargin = {
            data: {
              text: "<br/><br/>",
            },
            type: "text",
          };
          content.push(_sectionBottomMargin);

          break;
        }
        case "REPORT_COMMON_HEADER_1": {
          const _section = {
            data: {
              text: substituteVariables(
                datum["text"],
                this.wysiwygState["target"]
              ),
            },
            type: "header1",
          };
          content.push(_section);

          break;
        }
        case "REPORT_COMMON_PAGE_BREAK": {
          const _section = {
            data: null,
            type: "pageBreak",
          };
          content.push(_section);

          break;
        }
        case "REPORT_COMMON_PARAGRAPH": {
          const filteredText = insane(datum["text"], {
            allowedAttributes: {},
            allowedTags: [
              "p",
              "strong",
              "em",
              "a",
              "b",
              "i",
              "span",
              "div",
              "br",
              "u",
              "table",
              "thead",
              "tbody",
              "tfoot",
              "tr",
              "th",
              "td",
              "sup",
              "sub",
            ],
          });
          const _section = {
            data: {
              style: {
                fontSize:
                  datum["fontSize"] != null ? datum["fontSize"] : "normal",
              },
              text: filteredText,
            },
            type: "text",
          };
          content.push(_section);

          break;
        }
        case "REPORT_COMMON_SECURITY_CHART": {
          if (section["content"]["headline"]["isEnabled"]) {
            const _section = {
              data: {
                text: escapeEntity(datum["headline"].toUpperCase()),
              },
              type: "header1",
            };
            content.push(_section);
          }
          const _section = this.sectionHoldingChart(
            wysiwygState,
            section,
            datum,
            formatter
          );
          content.push(_section);

          break;
        }
        case "REPORT_COMMON_SECURITY_TABLE": {
          if (section["content"]["headline"]["isEnabled"]) {
            const _section = {
              data: {
                text: escapeEntity(datum["headline"].toUpperCase()),
              },
              type: "header1",
            };
            content.push(_section);
          }

          const _section = this.sectionHoldingTable(
            wysiwygState,
            section,
            datum,
            formatter
          );
          content.push(_section);

          break;
        }
        case "REPORT_COMMON_SPACING": {
          const _section = {
            data: {
              text: "",
            },
            type: "text",
          };

          for (let j = 0; j <= datum["lines"]; j++) {
            _section["data"]["text"] = _section["data"]["text"] + "<br/>";
          }

          content.push(_section);

          break;
        }
        // #endregion ------------------------------------------

        // #region ---------------------------------------- List

        case "REPORT_STRATEGY_ALLOCATION": {
          const _section = {
            data: {
              rows: section.rows,
              svg: section.svg,
              title: section.title,
            },
            type: "strategicAllocation",
          };

          content.push(_section);

          break;
        }

        case "REPORT_PORTFOLIO_MOMENTUM_BREAKDOWN": {
          if (section["content"]["headline"]["isEnabled"]) {
            const _section = {
              data: {
                text: escapeEntity(datum["headline"].toUpperCase()),
              },
              type: "header1",
            };
            content.push(_section);
          }
          const _section = this.sectionMomentumBreakdown(
            wysiwygState,
            section,
            datum,
            formatter
          );
          content.push(_section);

          break;
        }
        case "REPORT_PEER_TRENDS_TRACKER": {
          const _section = this.sectionTrendsTracker(section, datum.data);

          content.push(_section);
          break;
        }

        case "REPORT_PORTFOLIO_NEW_HIGH_NEW_LOW": {
          if (section["content"]["headline"]["isEnabled"]) {
            const _section = {
              data: {
                text: escapeEntity(datum["headline"].toUpperCase()),
              },
              type: "header1",
            };
            content.push(_section);
          }
          const _section = this.sectionNewHighNewLow(
            wysiwygState,
            section,
            datum,
            formatter
          );
          content.push(_section);

          break;
        }
        case "REPORT_PORTFOLIO_PERFORMER": {
          if (section["content"]["headline"]["isEnabled"]) {
            const _section = {
              data: {
                text: escapeEntity(datum["headline"].toUpperCase()),
              },
              type: "header1",
            };
            content.push(_section);
          }
          const _section = this.sectionPerformer(
            wysiwygState,
            section,
            datum,
            formatter
          );
          content.push(_section);

          break;
        }
        case "REPORT_PORTFOLIO_RATING_CHANGE": {
          if (section["content"]["headline"]["isEnabled"]) {
            const _section = {
              data: {
                text: escapeEntity(datum["headline"].toUpperCase()),
              },
              type: "header1",
            };
            content.push(_section);
          }
          const _section = this.sectionRatingChange(
            wysiwygState,
            section,
            datum,
            formatter
          );
          content.push(_section);

          break;
        }
        case "REPORT_PORTFOLIO_TCR": {
          if (section["content"]["headline"]["isEnabled"]) {
            const _section = {
              data: {
                text: escapeEntity(datum["headline"].toUpperCase()),
              },
              type: "header1",
            };
            content.push(_section);
          }
          const _section = this.sectionPortfolioMomentumRating(
            wysiwygState,
            section,
            datum,
            formatter
          );
          content.push(_section);

          break;
        }
        // #endregion ------------------------------------------

        // #region ------------------------------------ Strategy
        case "REPORT_STRATEGY_AS_OF_TODAY_PERFORMANCE": {
          // returns multiple items
          const _sections = sectionsAsOfTodayPerformance(
            wysiwygState,
            section,
            datum,
            formatter
          );
          aggregateMultipleSections(_sections);

          break;
        }
        case "REPORT_STRATEGY_BREAKDOWN": {
          // returns multiple items
          const _sections = sectionsBreakdown(
            wysiwygState,
            section,
            datum,
            formatter,
            this.environment
          );
          aggregateMultipleSections(_sections);

          break;
        }
        case "REPORT_STRATEGY_CHART": {
          const _sections = sectionsStrategyChart(
            wysiwygState,
            section,
            datum,
            this._getPageOrientation()
          );
          aggregateMultipleSections(_sections);

          break;
        }
        case "REPORT_STRATEGY_FACTS": {
          // returns multiple items
          const _sections = sectionsFacts(
            wysiwygState,
            section,
            datum,
            formatter
          );
          aggregateMultipleSections(_sections);

          break;
        }
        case "REPORT_STRATEGY_SUMMARY": {
          // returns multiple items
          const _sections = sectionsSummary(
            wysiwygState,
            section,
            datum,
            formatter,
            this.environment
          );
          aggregateMultipleSections(_sections);

          break;
        }
        case "REPORT_STRATEGY_HOLDINGS": {
          // returns multiple items
          const _sections = sectionsHoldings(
            wysiwygState,
            section,
            datum,
            formatter,
            this.fieldsConfiguration
          );
          aggregateMultipleSections(_sections);

          break;
        }
        case "REPORT_STRATEGY_KEY_FACTS": {
          // returns multiple items
          const _sections = sectionsKeyFacts(
            wysiwygState,
            section,
            datum,
            formatter
          );
          aggregateMultipleSections(_sections);

          break;
        }
        case "REPORT_STRATEGY_MONTHLY_ANALYTICS": {
          // returns multiple items
          const _sections = sectionsMonthlyAnalytics(
            wysiwygState,
            section,
            datum,
            formatter
          );
          aggregateMultipleSections(_sections);

          break;
        }
        case "REPORT_STRATEGY_QUARTERLY_ANALYTICS": {
          // returns multiple items
          const _sections = sectionsQuarterlyAnalytics(
            wysiwygState,
            section,
            datum,
            formatter
          );
          aggregateMultipleSections(_sections);

          break;
        }
        case "REPORT_STRATEGY_YEARLY_ANALYTICS": {
          // returns multiple items
          const _sections = sectionsYearlyAnalytics(
            wysiwygState,
            section,
            datum,
            formatter
          );
          aggregateMultipleSections(_sections);

          break;
        }
        case "REPORT_STRATEGY_YEAR_TO_DATE_ANALYTICS": {
          // returns multiple items
          const _sections = sectionsYearToDateAnalytics(
            wysiwygState,
            section,
            datum,
            formatter
          );
          aggregateMultipleSections(_sections);

          break;
        }
        default:
          console.log("Missing type " + datum["type"]);
          break;
        // #endregion ------------------------------------------
      }
    }

    this.content = content;

    return this;
  }

  print() {
    const params = this._preparePrintParams();

    this.printParams = params;

    return new Report(this.environment).print(params);
  }

  printOnFile() {
    const params = this._preparePrintParams();

    this.printParams = params;

    return new Report(this.environment).printOnFile(params);
  }

  trendsTrackerTable(data, type: "upgrades" | "downgrades") {
    const lastMonthCardinality = data?.["oneMonth"] ?? 0;
    const lastQuarterCardinality = data?.["threeMonths"] ?? 0;
    const lastMonthAvgPerf = data?.["avgPerfOneMonth"] ?? 0;
    const lastQuarterAvgPerf = data?.["avgPerfThreeMonths"] ?? 0;

    const formatOptions = {
      number: {
        decimals: 0,
        notAvailable: {
          input: 0,
          output: "-",
        },
      },
      percentage: {
        isPercentage: true,
        notAvailable: {
          input: 0,
          output: "-",
        },
      },
    };

    const color = type === "upgrades" ? "#008000" : "#F00000";

    const table = {
      data: {
        body: [
          [
            {
              escapeXmlEntities: true,
              style: null,
              value: "Last 20 Days",
            },
            {
              style: { align: "right" },
              value:
                `<strong><span color='${color}'>` +
                this.formatter.custom("number", {
                  options: formatOptions["number"],
                  output: "HTML",
                  value: lastMonthCardinality,
                  valueHelper: null,
                }) +
                "</span></strong>",
            },
            {
              style: null,
              value: "Last 60 Days",
            },
            {
              style: { align: "right" },
              value:
                `<strong><span color='${color}'>` +
                this.formatter.custom("number", {
                  options: formatOptions["number"],
                  output: "HTML",
                  value: lastQuarterCardinality,
                  valueHelper: null,
                }) +
                "</span></strong>",
            },
          ],
          [
            {
              escapeXmlEntities: true,
              style: null,
              value: "Average perf. 1 month",
            },
            {
              style: { align: "right" },
              value: this.formatter.custom("number", {
                options: formatOptions["percentage"],
                output: "HTML",
                value: lastMonthAvgPerf,
                valueHelper: null,
              }),
            },
            {
              style: null,
              value: "Average perf. 3 months",
            },
            {
              style: { align: "right" },
              value: this.formatter.custom("number", {
                options: formatOptions["percentage"],
                output: "HTML",
                value: lastQuarterAvgPerf,
                valueHelper: null,
              }),
            },
          ],
        ],
        head: [
          [
            {
              style: {
                color: "#000000",
              },
              value: type === "upgrades" ? "UPGRADES" : "DOWNGRADES",
            },
            {
              style: null,
              value: "",
            },
            {
              style: null,
              value: "",
            },
            {
              style: null,
              value: "",
            },
          ],
        ],
      },
      type: "trendsTrackerTable",
    };

    return table;
  }

  trendsTrackerUpgradesDowngrades(data, section) {
    const upgradesTable = this.trendsTrackerTable(data.upgrades, "upgrades");
    const downgradesTable = this.trendsTrackerTable(
      data.downgrades,
      "downgrades"
    );

    return [
      {
        table: [upgradesTable, downgradesTable],
        title: section.content["heading1"],
      },
    ];
  }

  quartilesPerformanceTable(data, type: "AB" | "CD") {
    const titleLabel =
      type === "AB"
        ? '<strong><span color="#008000">A</span><span color="#8bbc00">B</span></strong>'
        : '<strong><span color="#f48400">C</span><span color="#f00000">D</span></strong>';

    const formatOptions = {
      number: {
        decimals: 0,
        notAvailable: {
          input: 0,
          output: "-",
        },
      },
      percentage: {
        hasPositiveSign: true,
        isPercentage: true,
        notAvailable: {
          input: 0,
          output: "-",
        },
      },
    };

    const abBody = [
      [
        {
          style: null,
          value: "1st Quartile",
        },
        {
          style: null,
          value: "",
        },
        {
          style: null,
          value: "",
        },
        {
          style: { align: "right" },
          value: this.formatter.custom("number", {
            options: formatOptions["percentage"],
            output: "HTML",
            value: data.quartileAvg["1"],
            valueHelper: null,
          }),
        },
      ],
      [
        {
          style: null,
          value: "2nd Quartile",
        },

        {
          style: null,
          value: "",
        },
        {
          style: null,
          value: "",
        },
        {
          style: { align: "right" },
          value: this.formatter.custom("number", {
            options: formatOptions["percentage"],
            output: "HTML",
            value: data.quartileAvg["2"],
            valueHelper: null,
          }),
        },
      ],
      [
        {
          style: null,
          value: "3rd Quartile",
        },

        {
          style: null,
          value: "",
        },
        {
          style: null,
          value: "",
        },
        {
          style: { align: "right" },
          value: this.formatter.custom("number", {
            options: formatOptions["percentage"],
            output: "HTML",
            value: data.quartileAvg["3"],
            valueHelper: null,
          }),
        },
      ],
      [
        {
          escapeXmlEntities: true,
          style: null,
          template: "{{ value0 }}",
          value: "4th Quartile",
        },

        {
          style: null,
          value: "",
        },
        {
          style: null,
          value: "",
        },
        {
          style: { align: "right" },
          value: this.formatter.custom("number", {
            options: formatOptions["percentage"],
            output: "HTML",
            value: data.quartileAvg["4"],
            valueHelper: null,
          }),
        },
      ],
      [
        {
          style: null,
          value: "Average",
        },

        {
          style: null,
          value: "",
        },
        {
          style: null,
          value: "",
        },
        {
          style: { align: "right" },
          value: this.formatter.custom("number", {
            options: formatOptions["percentage"],
            output: "HTML",
            value: data.peerAvg,
            valueHelper: null,
          }),
        },
      ],
    ];
    const cdBody = [
      [
        {
          escapeXmlEntities: true,
          style: null,
          template: "{{ value0 }}",
          value: "4th Quartile",
        },

        {
          style: null,
          value: "",
        },
        {
          style: null,
          value: "",
        },
        {
          style: { align: "right" },
          value: this.formatter.custom("number", {
            options: formatOptions["percentage"],
            output: "HTML",
            value: data.quartileAvg["4"],
            valueHelper: null,
          }),
        },
      ],
      [
        {
          style: null,
          value: "3rd Quartile",
        },

        {
          style: null,
          value: "",
        },
        {
          style: null,
          value: "",
        },
        {
          style: { align: "right" },
          value: this.formatter.custom("number", {
            options: formatOptions["percentage"],
            output: "HTML",
            value: data.quartileAvg["3"],
            valueHelper: null,
          }),
        },
      ],
      [
        {
          style: null,
          value: "2nd Quartile",
        },

        {
          style: null,
          value: "",
        },
        {
          style: null,
          value: "",
        },
        {
          style: { align: "right" },
          value: this.formatter.custom("number", {
            options: formatOptions["percentage"],
            output: "HTML",
            value: data.quartileAvg["2"],
            valueHelper: null,
          }),
        },
      ],
      [
        {
          style: null,
          value: "1st Quartile",
        },

        {
          style: null,
          value: "",
        },
        {
          style: null,
          value: "",
        },
        {
          style: { align: "right" },
          value: this.formatter.custom("number", {
            options: formatOptions["percentage"],
            output: "HTML",
            value: data.quartileAvg["1"],
            valueHelper: null,
          }),
        },
      ],
      [
        {
          style: null,
          value: "Average",
        },

        {
          style: null,
          value: "",
        },
        {
          style: null,
          value: "",
        },
        {
          style: { align: "right" },
          value: this.formatter.custom("number", {
            options: formatOptions["percentage"],
            output: "HTML",
            value: data.peerAvg,
            valueHelper: null,
          }),
        },
      ],
    ];

    const table = {
      data: {
        body: type === "AB" ? abBody : cdBody,
        head: [
          [
            {
              style: null,
              value: titleLabel,
            },

            {
              style: null,
              value: "",
            },
            {
              style: null,
              value: "",
            },
            {
              style: { align: "right" },
              value: "Performance Since Rated",
            },
          ],
        ],
      },
      type: "performanceSinceRatedQuartiles",
    };

    return table;
  }

  trendsTrackerQuartilesPerf(data, section) {
    const abQuartiles = this.quartilesPerformanceTable(data.AB, "AB");
    const cdQuartiles = this.quartilesPerformanceTable(data.CD, "CD");

    return [
      {
        table: [abQuartiles, cdQuartiles],
        title: section["content"]["heading2"],
      },
    ];
  }

  sectionTrendsTracker(section, data) {
    const content = {
      data: {
        layoutRow: [
          this.trendsTrackerUpgradesDowngrades(
            {
              upgrades: data.upgrades,
              downgrades: data.downgrades,
            },
            section
          ),
          this.trendsTrackerQuartilesPerf(data.quartiles, section),
        ],
      },
      type: "trendsTracker",
    };

    return content;
  }

  sectionNewHighNewLow(wysiwygState, configuration, data, formatter) {
    const body: any = [];
    const layout: any = {
      data: {
        layoutRow: [],
      },
      type: "layout2Col",
    };
    const layoutRow: any = [];
    let max = 0;
    const numberOfRows = configuration["content"]["numberOfItems"];

    for (let item in data) {
      const length = data[item].length;
      if (length > max) {
        max = length;
      }
    }

    configuration["content"]["numberOfItems"] = Math.min(max, numberOfRows);

    if (configuration["presentation"]["newHigh"]) {
      for (let i = 0; i < data.high.length; i++) {
        const item = data.high[i];
        const row = [
          {
            escapeXmlEntities: true,
            style: null,
            template: "{{ value0 }}",
            value: item.name,
          },
          {
            style: null,
            value: format("rc", item, formatter),
          },
          {
            style: null,
            value: format("vc", item, formatter),
          },
          {
            style: null,
            value: format("lhl", item, formatter),
          },
        ];

        body.push(row);
      }

      // if rows are less than expected
      // add empty rows in order to guarantee height
      addEmptyRow(body, configuration["content"]["numberOfItems"], 4);

      const table = {
        data: {
          body: body,
          head: [
            [
              {
                style: {
                  color: "#000000",
                },
                value: "New Highs",
              },
              {
                style: null,
                value: "",
              },
              {
                style: null,
                value: "",
              },
              {
                style: null,
                value: "",
              },
            ],
          ],
        },
        type: "newHighNewLow",
      };

      layoutRow.push(table);
    }

    if (configuration["presentation"]["newLow"]) {
      const body: any = [];
      for (let i = 0; i < data.low.length; i++) {
        const item = data.low[i];
        const row = [
          {
            escapeXmlEntities: true,
            style: null,
            template: "{{ value0 }}",
            value: item.name,
          },
          {
            style: null,
            value: format("rc", item, formatter),
          },
          {
            style: null,
            value: format("vc", item, formatter),
          },
          {
            style: null,
            value: format("lhl", item, formatter),
          },
        ];

        body.push(row);
      }

      // if rows are less than expected
      // add empty rows in order to guarantee height
      addEmptyRow(body, configuration["content"]["numberOfItems"], 4);

      const table = {
        data: {
          body: body,
          head: [
            [
              {
                style: {
                  color: "#000000",
                },
                value: "New Lows",
              },
              {
                style: null,
                value: "",
              },
              {
                style: null,
                value: "",
              },
              {
                style: null,
                value: "",
              },
            ],
          ],
        },
        type: "newHighNewLow",
      };

      layoutRow.push(table);
    }

    layout.data.layoutRow.push(layoutRow);

    return layout;
  }

  sectionHoldingChart(wysiwygState, configuration, data, formatter) {
    const dataReady = this._sectionHoldingFormat(
      sectionProperties["trendrating-report/common/security-chart"][
        "properties"
      ],
      data.position,
      true,
      formatter
    );
    const itemsPerRow = 2;
    const layoutRow: any = [];
    const numberOfRows = Math.trunc(dataReady.length / itemsPerRow);

    let j = 0;
    for (let i = 0; i < numberOfRows; i++) {
      const row: any = [];
      for (let k = 0; k < itemsPerRow; k++) {
        const datum = dataReady[j];
        row.push(
          this._sectionHoldingChartHelper(
            datum,
            data.svg[datum.chartSymbol],
            formatter
          )
        );
        j++;
      }
      layoutRow.push(row);
    }

    const computerNumberOfRows = dataReady.length % itemsPerRow;

    if (computerNumberOfRows !== 0) {
      const row: any = [];
      while (j < dataReady.length) {
        const datum = dataReady[j];
        row.push(
          this._sectionHoldingChartHelper(
            datum,
            data.svg[datum.chartSymbol],
            formatter
          )
        );
        j++;
      }
      layoutRow.push(row);
    }

    // If there are no elements, put at least an empty rows so
    // the server does not crash
    if (layoutRow.length === 0) {
      layoutRow.push([]);
    }

    const table = {
      data: {
        layoutRow: layoutRow,
      },
      type: "layout2Col",
    };

    return table;
  }

  sectionHoldingTable(wysiwygState, section, data, formatter) {
    let _data = {
      body: null,
      head: null,
    };

    if (section["content"]["rank"] != null && data["enableRank"]) {
      _data = this._sectionHoldingFormatRanking(
        wysiwygState,
        section,
        data,
        formatter
      );
    } else {
      _data = this._sectionHoldingFormatScreening(
        wysiwygState,
        section,
        data,
        formatter
      );
    }

    const table = {
      data: _data,
      type: "table",
    };

    return table;
  }

  sectionMomentumBreakdown(wysiwygState, configuration, data, formatter) {
    const table: any = {
      data: {
        body: [],
        head: [],
      },
      type:
        configuration["content"]["isBreadth"] === true
          ? "momentumBreakdownBreadth"
          : "momentumBreakdown",
    };
    const what: any = []; // sector or industry
    const txFields = this.environment["taxonomyFields"];

    const whatTaxonomies = [
      this.environment["taxonomies"][txFields["security"]["sector"]],
      this.environment["taxonomies"][txFields["ETF"]["etfclass"]],
    ];
    const where: any = []; // market or area
    const whereTaxonomy =
      this.environment["taxonomies"][txFields["security"]["country"]];

    // from ids to taxon
    // Object.assign() not suppoted in IE

    for (let i = 0; i < data.what.length; i++) {
      what[i] = data.what[i];
      const key = what[i].id;
      what[i]["name"] = formatTaxonPrefixingAncestor(
        getTaxonById(key, whatTaxonomies, null),
        whatTaxonomies,
        "1 Industry"
      );
      what[i]["type"] = "PEER";
    }

    for (let i = 0; i < data.where.length; i++) {
      where[i] = data.where[i];
      const key = where[i].id;
      where[i].name = whereTaxonomy[key].name;
      where[i].type = "PEER";
    }

    // sortBy inherited by trendrating/core/Object Utils/
    sortBy(what, "name", false);
    sortBy(where, "name", false);

    // table body
    for (let i = 0; i < what.length; i++) {
      const row: any = [];
      const whatItem = what[i];
      row.push({
        value: whatItem.name,
      });

      for (let j = 0; j < where.length; j++) {
        const whereItem = where[j];
        const key = whereItem.id + "|" + whatItem.id;
        if (key in data.whatWhere) {
          data.whatWhere[key].type = "PEER";

          if (configuration["content"]["isBreadth"] === true) {
            let colorRate = "#FFFFFF"; // default empty
            // PEER without rating must not show anything
            // (just a white block)
            if (data.whatWhere[key]["TCR"] == null) {
              colorRate = "#FFFFFF";
            } else {
              const itemRate =
                _rate["trendCaptureRating"][data.whatWhere[key]["TCR"]];
              colorRate = "#000000";

              if (itemRate != null) {
                colorRate = itemRate["color"];
              }
            }

            row.push({
              style: {
                color: "#000000",
                backgroundColor: colorRate,
              },
              value: "&nbsp;",
            });
          } else {
            // rate
            let cellData = format(
              "tcr",
              {
                tcr: data.whatWhere[key].TCR,
                type: "PEER",
              },
              formatter
            );
            // weight
            if (true) {
              cellData =
                cellData +
                " " +
                format("weight", data.whatWhere[key], formatter);
            }

            row.push({
              style: {
                align: "center",
              },
              value: cellData,
            });
          }
        } else {
          row.push({ value: "" });
        }
      }

      table.data.body.push(row);
    }
    // header
    // row = [{ 'value': '' }, { 'value': '' }];
    const row: any = [{ value: "" }];
    for (let i = 0; i < where.length; i++) {
      const whereItem = where[i];

      if (where.length > 6) {
        row.push({
          style: {
            align: "center",
            color: "#000000",
          },
          value: whereItem.id,
        });
      } else {
        row.push({
          style: {
            align: "center",
            color: "#000000",
          },
          value: this._sectionMomentumBreakdown(whereItem, whereTaxonomy),
        });
      }
    }
    table.data.head.push(row);

    return table;
  }

  sectionPerformer(wysiwygState, configuration, data, formatter) {
    const layout: any = {
      data: {
        layoutRow: [],
      },
      type: "layout3Col",
    };
    let max = 0;
    const numberOfRows = configuration["content"]["numberOfItems"];
    const row: any = [];

    for (const item in data) {
      const length = data[item].length;
      if (length > max) {
        max = length;
      }
    }

    configuration["content"]["numberOfItems"] = Math.min(max, numberOfRows);

    if (configuration["presentation"]["05_days"]) {
      const section = this._sectionPerformerHelper(
        configuration,
        data["05_days"],
        "pw",
        "1 week",
        formatter
      );
      row.push(section);
    }

    if (configuration["presentation"]["20_days"]) {
      const section = this._sectionPerformerHelper(
        configuration,
        data["20_days"],
        "pm",
        "1 month",
        formatter
      );
      row.push(section);
    }

    if (configuration["presentation"]["60_days"]) {
      const section = this._sectionPerformerHelper(
        configuration,
        data["60_days"],
        "pq",
        "3 months",
        formatter
      );
      row.push(section);
    }

    layout.data.layoutRow.push(row);

    return layout;
  }

  sectionPortfolioMomentumRating(wysiwygState, configuration, data, formatter) {
    let item;
    const layout: any = {
      data: {
        layoutRow: [],
      },
      type: "layout5ColPortfolioMomentumRating",
    };
    const row: any = [];

    let paddingRows = 5; // maximum rows to show
    paddingRows = Math.max(
      paddingRows,
      data["marketAllocation"] != null
        ? data["marketAllocation"]["data"].length
        : 0
    );
    paddingRows = Math.max(
      paddingRows,
      data["sectorAllocation"] != null
        ? data["sectorAllocation"]["data"].length
        : 0
    );
    if (configuration["content"]["sectorRows"] != null) {
      let rowsFromData = 0;
      if (data["sectorAllocation"] != null) {
        rowsFromData = data["sectorAllocation"]["data"].length;
      }
      const rowsFromConfig = parseInt(
        configuration["content"]["sectorRows"],
        10
      );
      paddingRows = Math.max(
        paddingRows,
        rowsFromData < rowsFromConfig ? rowsFromData : rowsFromConfig
      );
    }

    if (configuration["presentation"]["portfolioMomentumRating"]) {
      const sectionData = data["portfolioMomentumRating"];
      const section: any = {
        data: {
          body: [
            [
              {
                style: {
                  align: "center",
                  fontSize: "large",
                },
                value: format(
                  "tcr",
                  {
                    tcr: sectionData,
                    type: "PORTFOLIO",
                  },
                  formatter
                ),
              },
            ],
          ],
          head: [
            [
              {
                style: {
                  align: "center",
                  color: "#000000",
                },
                value: "Overall TCR&reg;",
              },
            ],
          ],
        },
        type: "portfolioMomentumRating",
      };

      // if rows are less than expected
      // add empty rows in order to guarantee height
      addEmptyRow(section.data.body, paddingRows - 4, 1);

      row.push(section);
    }

    if (configuration["presentation"]["ratingWeight"]) {
      const sectionData = data["ratingWeight"];
      const section: any = {
        data: {
          body: [],
          head: [
            [
              {
                style: {
                  align: "center",
                  color: "#000000",
                },
                value: "Rating weights",
              },
              {
                style: null,
                value: "",
              },
            ],
          ],
        },
        type: "ratingWeight",
      };

      const order: any = [];
      for (const rate in sectionData) {
        order.push(parseInt(rate));
      }
      order.sort(function (a, b) {
        if (a > b) {
          return -1;
        }
        if (a < b) {
          return 1;
        }
        return 0;
      });

      for (let i = 0; i < order.length; i++) {
        if (order[i] !== 0) {
          // unrated inserted at the end
          section.data.body.push([
            {
              style: {
                align: "right",
              },
              value: format(
                "rc",
                {
                  rc: order[i],
                  type: "SECURITY",
                },
                formatter
              ),
            },
            {
              style: {
                align: "right",
              },
              value: format(
                "weight",
                {
                  weight: sectionData[order[i]],
                  type: "SECURITY",
                },
                formatter
              ),
            },
          ]);
        } else {
          item = i;
        }
      }

      if (item !== undefined) {
        section.data.body.push([
          {
            style: {
              align: "right",
            },
            value: format(
              "rc",
              {
                rc: order[item],
                type: "SECURITY",
              },
              formatter
            ),
          },
          {
            style: {
              align: "right",
            },
            value: format(
              "weight",
              {
                weight: sectionData[order[item]],
                type: "SECURITY",
              },
              formatter
            ),
          },
        ]);
      }

      // if rows are less than expected
      // add empty rows in order to guarantee height
      addEmptyRow(section.data.body, paddingRows, 2);

      row.push(section);
    }

    if (configuration["presentation"]["alert"]) {
      const sectionData = data["alert"];
      const section: any = {
        data: {
          body: [
            [
              {
                style: null,
                value:
                  '<span color="#008000" fontName="Times">&#9650;</span> Upgrades',
              },
              {
                style: null,
                value: String(sectionData["upgrade"]),
              },
            ],
            [
              {
                style: null,
                value:
                  '<span color="#F00000" fontName="Times">&#9660;</span> Downgrades',
              },
              {
                style: null,
                value: String(sectionData["downgrade"]),
              },
            ],
            [
              {
                style: {
                  align: "center",
                },
                value: "Last 20 days",
              },
              {
                style: null,
                value: "",
              },
            ],
          ],
          head: [
            [
              {
                style: {
                  align: "center",
                  color: "#000000",
                },
                value: "Alerts",
              },
              {
                style: null,
                value: "",
              },
            ],
          ],
        },
        type: "alert",
      };

      // if rows are less than expected
      // add empty rows in order to guarantee height
      addEmptyRow(section.data.body, paddingRows - 2, 2);

      row.push(section);
    }

    if (configuration["presentation"]["marketAllocation"]) {
      const marketAllocation: any = {
        title: null,
        data: data["marketAllocation"],
        taxonomy: null,
      };
      const txFields = this.environment["taxonomyFields"];
      switch (configuration["content"]["marketAllocation"]) {
        case "INDUSTRY": {
          marketAllocation["title"] = "Sector allocation";
          marketAllocation["taxonomy"] = [
            this.environment["taxonomies"][txFields["security"]["industry"]],
            this.environment["taxonomies"][txFields["ETF"]["etfclass"]],
          ];

          break;
        }
        case "SECTOR": {
          marketAllocation["title"] = "Industry allocation";
          marketAllocation["taxonomy"] = [
            this.environment["taxonomies"][txFields["security"]["sector"]],
            this.environment["taxonomies"][txFields["ETF"]["etfclass"]],
          ];

          break;
        }
        case "MARKET": {
          marketAllocation["title"] = "Market allocation";
          marketAllocation["taxonomy"] = [
            this.environment["taxonomies"][txFields["security"]["country"]],
          ];

          break;
        }
        case "REGION": {
          marketAllocation["title"] = "Region allocation";
          marketAllocation["taxonomy"] = [
            this.environment["taxonomies"][txFields["security"]["country"]],
          ];

          break;
        }
        case "AREA": {
          marketAllocation["title"] = "Area allocation";
          marketAllocation["taxonomy"] = [
            this.environment["taxonomies"][txFields["security"]["country"]],
          ];

          break;
        }
        case "INV_REGION": {
          marketAllocation["title"] = "Inv. Region";
          marketAllocation["taxonomy"] = [
            this.environment["taxonomies"][txFields["ETF"]["etfgeo"]],
          ];

          break;
        }
        case "ASSET_CLASS": {
          marketAllocation["title"] = "Asset class";
          marketAllocation["taxonomy"] = [
            this.environment["taxonomies"][txFields["ETF"]["etfclass"]],
          ];

          break;
        }
        case "SPECIALTY": {
          marketAllocation["title"] = "Specialty";
          marketAllocation["taxonomy"] = [
            this.environment["taxonomies"][txFields["ETF"]["etfclass"]],
          ];

          break;
        }
        case "THEME": {
          marketAllocation["title"] = "Theme";
          marketAllocation["taxonomy"] = [
            this.environment["taxonomies"][txFields["ETF"]["etfclass"]],
          ];

          break;
        }
        default:
          console.log(
            "Missing marketAllocation " +
              configuration["content"]["marketAllocation"]
          );
          break;
      }
      const section = sectionPieTcr(
        marketAllocation["title"],
        marketAllocation["data"],
        marketAllocation["taxonomy"],
        paddingRows,
        formatter
      );
      row.push(section);
    }

    if (configuration["presentation"]["sectorAllocation"]) {
      const sectorAllocation: any = {
        title: null,
        data: data["sectorAllocation"],
        taxonomy: null,
      };

      const txFields = this.environment["taxonomyFields"];

      switch (configuration["content"]["sectorAllocation"]) {
        case "INDUSTRY": {
          sectorAllocation["title"] = "Sector allocation";
          sectorAllocation["taxonomy"] = [
            this.environment["taxonomies"][txFields["security"]["industry"]],
            this.environment["taxonomies"][txFields["ETF"]["etfclass"]],
          ];

          break;
        }
        case "SECTOR": {
          sectorAllocation["title"] = "Industry allocation";
          sectorAllocation["taxonomy"] = [
            this.environment["taxonomies"][txFields["security"]["sector"]],
            this.environment["taxonomies"][txFields["ETF"]["etfclass"]],
          ];

          break;
        }
        case "MARKET": {
          sectorAllocation["title"] = "Market allocation";
          sectorAllocation["taxonomy"] = [
            this.environment["taxonomies"][txFields["security"]["country"]],
          ];

          break;
        }
        case "REGION": {
          sectorAllocation["title"] = "Region allocation";
          sectorAllocation["taxonomy"] = [
            this.environment["taxonomies"][txFields["security"]["country"]],
          ];

          break;
        }
        case "AREA": {
          sectorAllocation["title"] = "Area allocation";
          sectorAllocation["taxonomy"] = [
            this.environment["taxonomies"][txFields["security"]["country"]],
          ];

          break;
        }
        case "INV_REGION": {
          sectorAllocation["title"] = "Inv. Region";
          sectorAllocation["taxonomy"] = [
            this.environment["taxonomies"][txFields["ETF"]["etfgeo"]],
          ];

          break;
        }
        case "ASSET_CLASS": {
          sectorAllocation["title"] = "Asset class";
          sectorAllocation["taxonomy"] = [
            this.environment["taxonomies"][txFields["ETF"]["etfclass"]],
          ];

          break;
        }
        case "SPECIALTY": {
          sectorAllocation["title"] = "Specialty";
          sectorAllocation["taxonomy"] = [
            this.environment["taxonomies"][txFields["ETF"]["etfclass"]],
          ];

          break;
        }
        case "THEME": {
          sectorAllocation["title"] = "Theme";
          sectorAllocation["taxonomy"] = [
            this.environment["taxonomies"][txFields["ETF"]["etfclass"]],
          ];

          break;
        }
        default:
          console.log(
            "Missing sectorAllocation " +
              configuration["content"]["sectorAllocation"]
          );
          break;
      }
      const section = sectionPieTcr(
        sectorAllocation["title"],
        sectorAllocation["data"],
        sectorAllocation["taxonomy"],
        paddingRows,
        formatter
      );
      row.push(section);
    }

    layout.data.layoutRow.push(row);

    return layout;
  }

  sectionRatingChange(wysiwygState, configuration, data, formatter) {
    const body: any = [];
    const layout: any = {
      data: {
        layoutRow: [],
      },
      type: "layout2Col",
    };
    const layoutRow: any = [];
    const numberOfRows = configuration["content"]["numberOfItems"];

    let max = 0;
    for (let item in data) {
      const length = data[item].length;
      if (length > max) {
        max = length;
      }
    }

    configuration["content"]["numberOfItems"] = Math.min(max, numberOfRows);

    if (configuration["presentation"]["upgrade"]) {
      for (let i = 0; i < data.upgrade.length; i++) {
        const item = data.upgrade[i];
        const row = [
          {
            style: null,
            value: [
              format("rrr", item, formatter),
              ' <span fontName="Times">&rarr;</span> ',
              format("rc", item, formatter),
            ].join(""),
          },
          {
            escapeXmlEntities: true,
            style: null,
            template: "{{ value0 }}",
            value: item.name,
          },
          {
            style: {
              align: "right",
            },
            value: format("dr", item, formatter),
          },
        ];

        body.push(row);
      }

      // if rows are less than expected
      // add empty rows in order to guarantee height
      addEmptyRow(body, configuration["content"]["numberOfItems"], 3);

      const table = {
        data: {
          body: body,
          head: [
            [
              {
                style: {
                  color: "#000000",
                },
                value: "Upgrades",
              },
              {
                style: null,
                value: "",
              },
              {
                style: null,
                value: "",
              },
            ],
          ],
        },
        type: "ratingChange",
      };

      layoutRow.push(table);
    }

    if (configuration["presentation"]["downgrade"]) {
      const body: any = [];
      for (let i = 0; i < data.downgrade.length; i++) {
        const item = data.downgrade[i];
        const row = [
          {
            style: null,
            value: [
              format("rrr", item, formatter),
              ' <span fontName="Times">&rarr;</span> ',
              format("rc", item, formatter),
            ].join(""),
          },
          {
            escapeXmlEntities: true,
            style: null,
            template: "{{ value0 }}",
            value: item.name,
          },
          {
            style: {
              align: "right",
            },
            value: format("dr", item, formatter),
          },
        ];

        body.push(row);
      }

      // if rows are less than expected
      // add empty rows in order to guarantee height
      addEmptyRow(body, configuration["content"]["numberOfItems"], 3);

      const table = {
        data: {
          body: body,
          head: [
            [
              {
                style: {
                  color: "#000000",
                },
                value: "Downgrades",
              },
              {
                style: null,
                value: "",
              },
              {
                style: null,
                value: "",
              },
            ],
          ],
        },
        type: "ratingChange",
      };

      layoutRow.push(table);
    }

    layout.data.layoutRow.push(layoutRow);

    return layout;
  }
  // ------------------------------------------------- private methods

  _getWhatTaxon(what) {
    const txFields = this.environment["taxonomyFields"];
    const whatTaxonomies = [
      this.environment["taxonomies"][txFields["security"]["sector"]],
      this.environment["taxonomies"][txFields["ETF"]["etfclass"]],
    ];
    return formatTaxonPrefixingAncestor(
      getTaxonById(what, whatTaxonomies, null),
      whatTaxonomies,
      "1 Industry"
    );
  }

  _getWhereTaxon(where) {
    const txFields = this.environment["taxonomyFields"];
    const whereTaxonomy =
      this.environment["taxonomies"][txFields["security"]["country"]];
    return whereTaxonomy[where].name;
  }

  _getPageOrientation() {
    const template = this.get("template");
    if (template != null) {
      return template["configuration"]["orientation"];
    }
    return "portrait"; // default
  }

  _preparePrintParams() {
    let hasCover = false;
    let title = this.wysiwygState["target"]["name"];
    if (this.data[0]["type"] === "REPORT_COMMON_COVER") {
      hasCover = true;
      title = substituteVariables(
        this.data[0]["title"],
        this.wysiwygState["target"]
      );
    }

    // cleanup special characters in name
    // otherwise problem with filesystem constraints
    const fileName = "Trendrating - " + title.replace("/", "_") + ".pdf";

    const params = {
      content: this.content,
      cover: hasCover,
      fileName: fileName,
      meta: {
        author: "Trendrating",
        title: title,
      },
      orientation: this._getPageOrientation(),
      headerConfig: this.template.configuration.headerConfig,
      userPreference: this.userPreference,
    };

    return params;
  }

  _sectionHoldingChartHelper(instrument, svg, formatter) {
    const chart = {
      data: {
        body: [[{ value: svg }, { value: "" }]],
        head: [
          [
            { value: instrument["chartRate"] },
            { value: instrument["chartTicker"] },
          ],
          [
            { value: "" },
            {
              value: format(
                "name",
                {
                  name: instrument["chartName"],
                  type: instrument["type"],
                },
                formatter
              ),
            },
          ],
          [{ value: "" }, { value: instrument["chartRateInfo"] }],
        ],
      },
      type: "chart",
    };

    return chart;
  }

  _sectionMomentumBreakdown(whereItem, whereTaxonomy) {
    const where = whereTaxonomy[whereItem["id"]];

    if (where["type"] === "Region") {
      const whereParent = whereTaxonomy[where["parent"]];
      return whereParent["name"] + "<br/>" + where["name"];
    }

    if (where["id"] === "WWW") {
      return where["name"];
    }

    return where["id"];
  }

  _sectionHoldingFormat(columns, iData, isChartView, formatter) {
    const data = deepClone(iData);

    // 2019-02-28
    // IMPORTANT - this keep source datum. Avoid for example
    // that icb is overwritten and that can not be used by industry
    const dataCloned = deepClone(iData);

    for (let i = 0, lengthI = data.length; i < lengthI; i++) {
      const datum = data[i];
      // formatting chart data
      if (isChartView) {
        datum.chartSymbol = datum.symbol;
        datum.chartTicker = datum.ticker;
        datum.chartName = escapeEntity(datum.name);
        datum.chartRate = format("rc", datum, formatter);
        datum.chartRateInfo = [
          "Rated on: ",
          format("dr", datum, formatter),
          " | Since ",
          datum.chartRate,
          ": ",
          format("pr", datum, formatter),
        ].join("");

        if (
          datum.prr &&
          ((datum.rrr > 0 && datum.rc > 0) || (datum.rrr < 0 && datum.rc < 0))
        ) {
          datum.chartRateInfo = [
            datum.chartRateInfo,
            " | Since ",
            format("rrr", datum, formatter),
            ": ",
            format("prr", datum, formatter),
          ].join("");
        }
      }
      // formatting table data
      for (let j = 0; j < columns.length; j++) {
        const cell = columns[j];

        if ("customFormatter" in cell) {
          datum[cell.property] = cell.customFormatter(dataCloned[i]);
        } else {
          datum[cell.property] = format(
            cell.property,
            dataCloned[i],
            formatter
          );
        }
      }
    }

    return data;
  }

  addAvgRow(wysiwygState, columns) {
    // Add average row if available
    const avgRow = wysiwygState.actions?.avgRow ?? null;

    if (avgRow) {
      const row: any = [];
      let value: any = null;

      for (const col of columns) {
        value = avgRow[col.property];
        if (col.property in avgRow) {
          row.push({
            style: {
              align: value === "Average" ? "left" : "right",
              fontSize: "medium",
            },
            value: `<strong><span color="${
              value === "Average" ? "#2a7090" : "black"
            }">${format(
              col.property,
              { [col.property]: avgRow[col.property], type: "stock" },
              this.formatter
            )}</span></strong>`,
          });
        } else {
          row.push({ style: null, value: "" });
        }
      }

      return row;
    }
  }

  _sectionHoldingFormatScreening(wysiwygState, section, data, formatter) {
    const body: any = [];
    const columns =
      data["columns"] != null && data["columns"].length > 0
        ? data["columns"]
        : section["presentation"]["columns"];
    const head: any = [];
    const label = new Properties({
      properties: this.environment["properties"],
    });

    const dataReady = this._sectionHoldingFormat(
      columns,
      data["position"],
      false,
      formatter
    );

    let labelIndex = 0;
    // table header
    const row: any = [];

    const isBasketOrScreenedUniverse =
      (wysiwygState["targetType"] === "LIST" &&
        wysiwygState["target"]["type"] === "BASKET") ||
      wysiwygState["targetType"] === "SCREENING";

    for (let i = 0; i < columns.length; i++) {
      const cell = columns[i];
      // TODO - modify fieldsConfiguration.json to have
      // a map instead of array of string instead of
      //
      // name: ['Label 1', 'Label 2']
      //
      // something like
      //
      // name: {
      //    'csv': ['Label 1', 'Label 2'],
      //    'pdf': ['Label 3', 'Label 4'],
      //    'web': ['Label 5', 'Label 5']
      // }
      switch (cell["property"]) {
        case "country":
        case "marketcap":
        case "pd":
        case "prr":
        case "rrr": {
          labelIndex = 1;

          break;
        }
        default: {
          labelIndex = 0;
        }
      }

      if (isBasketOrScreenedUniverse && cell["property"] === "weight") {
        continue; // Don't show weight column is type is BASKET
      }

      const headerTitle = label.get(cell["property"], labelIndex, "auto");

      row.push({
        style: getStyleForProperty(cell["property"], this.fieldsConfiguration),
        value:
          headerTitle !== cell["property"]
            ? headerTitle
            : cell?.label ?? "property", // grateful degradation if the label is not found in properties (custom titles)
      });
    }
    head.push(row);

    // table body
    for (let i = 0; i < dataReady.length; i++) {
      const datum = dataReady[i];
      const row: any = [];

      for (let j = 0; j < columns.length; j++) {
        const cell = columns[j];
        if (isBasketOrScreenedUniverse && cell["property"] === "weight") {
          // Don't show weight column is type is BASKET
          continue;
        }

        row.push({
          style: getStyleForProperty(
            cell["property"],
            this.fieldsConfiguration
          ),
          value:
            cell["property"] === "name"
              ? escapeEntity(datum[cell.property])
              : datum[cell.property],
        });
      }
      body.push(row);
    }

    const avgRow = this.addAvgRow(wysiwygState, columns);

    if (avgRow) {
      body.unshift(avgRow);
    }

    return {
      body: body,
      head: head,
    };
  }

  _sectionHoldingFormatRanking(wysiwygState, section, data, formatter) {
    const againstList =
      wysiwygState["rankingCache"] != null
        ? wysiwygState["rankingCache"]["rankingParams"]["againstList"]
        : null;
    const columns =
      data["columns"] != null
        ? data["columns"]
        : section["presentation"]["columns"];
    const columnProperties: any = [];
    const portfolio = wysiwygState["actions"]["rank"]["list"];
    const propertiesRanking = {};
    let propertiesRankingCounter = 0;
    const propertyRank = this.http["rankings"]["RANKING_PROPERTY"];
    const propertyRankList = this.http["rankings"]["RANKING_PROPERTY_LIST"];
    const rankingRules = wysiwygState["actions"]["rank"]["rules"];
    const row: any = [];
    const table: any = {
      head: [],
      body: [],
    };

    const isBasketOrScreenedUniverse =
      (wysiwygState["targetType"] === "LIST" &&
        wysiwygState["target"]["type"] === "BASKET") ||
      wysiwygState["targetType"] === "SCREENING";

    // Prepare user defined columns
    for (let i = 0; i < columns.length; i++) {
      const property = columns[i];
      if (isBasketOrScreenedUniverse && property["property"] === "weight") {
        continue; // Don't show weight column is type is BASKET
      }
      // this check prevent to add additional columns merged
      // because of charts
      if (property["label"] != null) {
        // map to help to match which rule generate this result
        if (/^rankValue/.test(property["property"])) {
          propertiesRanking[property["property"]] = propertiesRankingCounter;
          propertiesRankingCounter++;
        }
      }
      columnProperties.push(property["property"]);
      row.push({
        style: getStyleForProperty(
          property["property"],
          this.fieldsConfiguration
        ),
        value: property["label"],
      });
    }
    table["head"].push(row);
    // data
    for (let i = 0; i < data["position"].length; i++) {
      const datum = data["position"][i];
      const row: any = [];
      for (let j = 0; j < columnProperties.length; j++) {
        const property = columnProperties[j];
        if (isBasketOrScreenedUniverse && property === "weight") {
          // Don't show weight column is type is BASKET
          continue;
        }
        if (/^rank/.test(property)) {
          if (property === "rank") {
            row.push({
              style: getStyleForProperty(property, this.fieldsConfiguration),
              value:
                "<strong>" +
                formatter.rankingRule(property, null, datum, "PDF") +
                "</strong>",
            });
          } else if (property === "rankFromDate") {
            row.push({
              style: getStyleForProperty(property, this.fieldsConfiguration),
              value:
                "<strong>" +
                formatter.rankingRule(property, null, datum, "PDF") +
                "</strong>",
            });
          } else if (property === "rankDelta") {
            row.push({
              style: getStyleForProperty(property, this.fieldsConfiguration),
              value:
                "<strong>" +
                formatter.rankingRule(property, null, datum, "PDF") +
                "</strong>",
            });
          } else if (property === propertyRankList) {
            let value = "";
            if (datum[property] != null) {
              const rank = datum[propertyRank] + 1;
              const weight = formatter.text(
                "weight",
                "weight",
                datum[property],
                null,
                datum["type"]
              );

              if (againstList != null && againstList["type"] === "PORTFOLIO") {
                value = [rank, " (", weight, ")"].join("");
              } else {
                value = String(rank);
              }
            }
            row.push({
              style: getStyleForProperty(property, this.fieldsConfiguration),
              value: value,
            });
          } else {
            // If it is an outlier, just make the output in
            // bold
            const itemRankingRules = rankingRules[propertiesRanking[property]];
            if (
              itemRankingRules != null &&
              itemRankingRules["function"] === "outlier"
            ) {
              row.push({
                style: getStyleForProperty(property, this.fieldsConfiguration),
                value:
                  "<strong>" +
                  formatter.rankingRule(
                    property,
                    rankingRules[propertiesRanking[property]],
                    datum,
                    "PDF"
                  ) +
                  "</strong>",
              });
            } else {
              row.push({
                style: getStyleForProperty(property, this.fieldsConfiguration),
                value: formatter.rankingRule(
                  property,
                  rankingRules[propertiesRanking[property]],
                  datum,
                  "PDF"
                ),
              });
            }
          }
        } else if (property === "portfolioWeight") {
          let value = "";
          if (datum[property] != null) {
            const rank = datum["rank"] + 1;
            const weight = formatter.custom("number", {
              options: {
                isPercentage: true,
                notAvailable: {
                  input: null,
                  output: "",
                },
              },
              output: "PDF",
              value: datum[property],
              valueHelper: null,
            });

            if (portfolio["type"] === "PORTFOLIO") {
              value = [rank, " (", weight, ")"].join("");
            } else {
              value = String(rank);
            }
          }

          row.push({
            style: getStyleForProperty(property, this.fieldsConfiguration),
            value: value,
          });
        } else {
          row.push({
            style: getStyleForProperty(property, this.fieldsConfiguration),
            value: escapeEntity(format(property, datum, formatter)),
          });
        }
      }
      table["body"].push(row);
    }

    // Add average row if available
    const avgRow = this.addAvgRow(wysiwygState, columns);

    if (avgRow) {
      table["body"].unshift(avgRow);
    }

    return table;
  }

  _sectionPerformerHelper(configuration, data, property, title, formatter) {
    const body: any = [];
    const numberOfRows = configuration["content"]["numberOfItems"];

    for (let i = 0; i < data.length; i++) {
      const item = data[i];
      const row = [
        {
          escapeXmlEntities: true,
          style: null,
          template: "{{ value0 }}",
          value: item.name,
        },
        {
          style: {
            align: "center",
          },
          template: null,
          value: format("rc", item, formatter),
        },
        {
          style: null,
          template: null,
          value: format("vc", item, formatter),
        },
        {
          style: {
            align: "right",
          },
          template: null,
          value: format(property, item, formatter),
        },
      ];

      body.push(row);
    }

    // if rows are less than expected
    // add empty rows in order to guarantee height
    addEmptyRow(body, numberOfRows, 4);

    const table = {
      data: {
        body: body,
        head: [
          [
            {
              style: {
                color: "#000000",
              },
              value: title,
            },
            {
              style: null,
              value: "",
            },
            {
              style: null,
              value: "",
            },
            {
              style: null,
              value: "",
            },
          ],
        ],
      },
      type: "performer",
    };

    return table;
  }

  // ----------------------------------------------- getters / setters
}
