import { Box, Card, CardContent } from "@mui/material";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  TableEventsV2,
  TableV2,
} from "../../../../../../../components/table/v2/TableCoreV2";
import { TrendratingTableV2 } from "../../../../../../../components/table/v2/TableV2";
import { useBroadcast } from "../../../../../../../hooks/useBroadcast";
import { useEnvironment } from "../../../../../../../hooks/useEnvironment";
import { useResizer } from "../../../../../../../hooks/useResizer";
import { Export } from "../../../../../components/app-infrastructure/workflowBar/actions/export/Export";
import { config } from "../../../../../config-ts";
import { removeLoader } from "../../../../../utils";
import ReportButton from "../../../../../widgets/app-infrastructure/workflowBar/actions/report/ReportButton";
import { RankDialog } from "../../../../rank/RankDialog/RankDialog";
import {
  ActionRankContext,
  RankContextProvider,
} from "../../../../rank/actions/ActionRankContext/ActionRankContext";

type PortfolioRankingProps = {
  portfolioId: any;
  portfolioName: string;
  portfolioType: "PORTFOLIO" | "BASKET";
  portfolioPositions: any;
};

/**
 * This wrapper is used to make availabe rank context functions
 */
export function PortfolioRanking({
  portfolioId,
  portfolioName,
  portfolioType,
  portfolioPositions,
}: PortfolioRankingProps) {
  useEffect(() => {
    removeLoader();
  }, []);

  return (
    <RankContextProvider>
      <PortfolioRankingTab
        portfolioPositions={portfolioPositions}
        portfolioId={portfolioId}
        portfolioName={portfolioName}
        portfolioType={portfolioType}
      />
    </RankContextProvider>
  );
}

const PortfolioRankingTab = ({
  portfolioId,
  portfolioName,
  portfolioType,
  portfolioPositions,
}: PortfolioRankingProps) => {
  const [showDialogRank, setShowDialogRank] = useState(true);
  // const [tableColumns, setTableColumns] = useState<any>([]);
  const [page, setPage] = useState(1);
  const [sorter, setSorter] = useState<{ field: string; rev: boolean }>({
    field: "rank",
    rev: false,
  });
  const [itemsPerPage, setItemsPerPage] = useState(25);
  // const [loading, setIsLoading] = useState(false);
  // const [tableKey, setTableKey] = useState(`tableWidget_${Date.now()}`);
  const [rankFrom, setRankFrom] = useState<
    | "PREVIOUS_DAY"
    | "PREVIOUS_WEEK"
    | "PREVIOUS_2_WEEKS"
    | "PREVIOUS_MONTH"
    | "PREVIOUS_3_MONTHS"
    | undefined
  >();
  const [tableStatus, setTableStatus] = useState({
    columns: false,
    built: false,
  });

  const tableReady = useMemo(() => {
    return tableStatus.columns && tableStatus.built;
  }, [tableStatus.built, tableStatus.columns]);

  const tableRef = useRef<{ getInstance: () => TableV2 }>();

  const { rankResults, rank, rankReducer, rankingCache } =
    useContext(ActionRankContext);

  const dataTotalCount = useMemo(() => {
    return rankResults.dataTotalCount;
  }, [rankResults.dataTotalCount]);
  const { broadcast } = useBroadcast();

  const containerRef = useRef<HTMLDivElement>(null);

  const environment = useEnvironment();

  useResizer({ ref: containerRef });

  const appEnvironment = useMemo(() => {
    return environment.get("setup");
  }, [environment]);
  const configuration = useMemo(
    () => appEnvironment["configuration"],
    [appEnvironment]
  );
  const configurationScreening = useMemo(
    () => configuration.get("ranking"),
    [configuration]
  );

  const listType = useMemo(() => portfolioType, [portfolioType]);
  const listName = useMemo(() => portfolioName, [portfolioName]);
  const listId = useMemo(() => portfolioId, [portfolioId]);

  const openRankDialog = useCallback(() => {
    setShowDialogRank(true);
  }, []);

  const closeRankDialog = useCallback(() => {
    setShowDialogRank(false);
  }, []);

  const refreshRank = useCallback(
    async (rankingParams) => {
      // setIsLoading(true);

      try {
        await rank(rankingParams);
      } catch (error) {
        console.error(error);
      }
    },
    [rank]
  );

  const onChangePage = useCallback(
    (page) => {
      setPage(page);

      refreshRank({
        page,
        sortField: sorter.field,
        rev: sorter.rev,
      });
    },
    [refreshRank, sorter.field, sorter.rev]
  );

  const onSelectItemPerPage = useCallback(
    (itemsPerPageValue) => {
      setItemsPerPage(itemsPerPageValue);
      setPage(1);

      refreshRank({ itemsPerPage: itemsPerPageValue, page: 1 });
    },
    [refreshRank]
  );

  const refreshSorter = useCallback(() => {
    setSorter({ field: "rank", rev: false });
    const table = tableRef.current?.getInstance();

    table?.sort("rank", "desc");
  }, []);

  const changeRankDateFrom = useCallback(
    (dateId) => {
      setPage(1);
      setRankFrom(dateId);
      refreshSorter();

      refreshRank({
        rev: false,
        sortField: "rank",
        page: 1,
        fromDate: dateId,
      });
    },
    [refreshRank, refreshSorter]
  );

  const onDataSorted = useCallback(
    async ({ field, direction }) => {
      setSorter({ field, rev: direction === "desc" });
      setPage(1);

      await refreshRank({
        rev: direction === "desc",
        sortField: field,
        page: 1,
      });
    },
    [refreshRank]
  );

  const getWidgetInfo = useCallback(
    (infoType: "columns" | "pagination" | "sortBy" | "dataTotalCount") => {
      switch (infoType) {
        case "pagination":
          return {
            page,
            rows: itemsPerPage,
          };

        case "sortBy":
          return {
            property: sorter.field,
            descending: sorter.rev,
          };
        case "dataTotalCount":
          return rankResults.dataTotalCount;

        case "columns": {
          let _column: any = null;
          const table = tableRef.current?.getInstance();
          let _columns: any = table
            ?.getColumns()
            .map((col) => col.getDefinition());
          let column: any = null;
          let columns: any = [];

          // _columns = getColumns(_columns);

          for (let i = 0, length = _columns.length; i < length; i++) {
            _column = _columns[i];

            if (_column["field"] !== undefined && _column["field"] != null) {
              column = {
                label: (_column?.["title"] ?? _column?.["label"] ?? "")
                  .replace(/<br\/>/gi, " - ")
                  .replace(/<br>/gi, " "),
                property: _column["field"],
              };
              columns.push(column);
            }
          }

          return columns;
        }

        default:
          console.warn(`Unrecognized widget type: ${infoType}`);
      }
    },
    [itemsPerPage, page, rankResults.dataTotalCount, sorter.field, sorter.rev]
  );

  const updateColumns = useCallback(
    (columns) => {
      rankReducer({
        type: "SET_COLUMNS",
        payload: columns,
      });

      const table = tableRef.current?.getInstance();
      table?.insertColumns(columns);
    },
    [rankReducer]
  );

  const onColumnsChange = useCallback(
    (columns) => {
      updateColumns(columns);

      refreshRank({
        sortField: sorter.field,
        rev: sorter.rev,
        page,
        columns: columns.map((col) => col.field),
      });
    },
    [page, refreshRank, sorter.field, sorter.rev, updateColumns]
  );

  /**
   * Load the current portfolio holdings as universe
   */
  useEffect(() => {
    if (listId) {
      const payload: any = {
        constraints: [
          [
            {
              dimension: "COLLECTION",
              operator: "relation",
              segments: [
                typeof listId === "number" ? listId : parseInt(listId),
              ],
            },
            {
              dimension: "type",
              logicalOperator: "not",
              operator: "equals",
              segments: ["ExpiredStock"],
            },
          ],
        ],
        page: {
          page: 1,
          rows: 20000,
        },
      };

      rankReducer({ type: "SET_CONSTRAINTS", payload });
    }
  }, [listId, rankReducer]);

  const insertDataAndColumnsAfterRank = useCallback(() => {
    const table = tableRef.current?.getInstance();

    if (rankResults?.columns?.length && table) {
      const currentSort = table?.getSorter();

      table?.insertColumns(
        rankResults.columns.map((col) => {
          if (typeof col === "string") {
            return { field: col };
          } else {
            return col;
          }
        })
      );

      if (currentSort && currentSort.length) {
        // Reapply sort on columns change
        table?.sort(currentSort[0].field, currentSort[0].dir);
      } else {
        table?.sort("rank", "desc");
      }

      table?.insertData(rankResults.data);
    }
  }, [rankResults.columns, rankResults.data]);

  useEffect(() => {
    insertDataAndColumnsAfterRank();
  }, [insertDataAndColumnsAfterRank]);

  /**
   * Manage workflow bar
   */
  useEffect(() => {
    let actions: any = [];

    let action: any = null;

    action = {
      componentJSX: (
        <li className="menu__item" onClick={openRankDialog}>
          Edit Rank
        </li>
      ),
    };

    actions.push(action);

    const exportFileName = `${listName}.csv`;

    action = {
      componentJSX: (
        <Export
          rankingCache={rankingCache}
          fileName={exportFileName}
          label={"Export"}
          list={{
            id: typeof listId === "number" ? listId : parseInt(listId),
            type: listType,
          }}
          widgets={{
            table: { get: getWidgetInfo },
          }}
          logFunction={(event) => {
            //**************** USAGE *******************
            var usage = window.App.usage;
            var info = {
              action: "EXPORT",
              actionParam: listId,
              function: "PORTFOLIO_RANKING",
            };
            usage.record(info);
            //**************** USAGE *******************
          }}
        />
      ),
    };

    actions.push(action);

    const listObjForReport = {
      id: listId,
      // name: portfolioName,
    };

    action = {
      componentJSX: (
        <ReportButton
          page={"analysisList"}
          target={listObjForReport}
          rankingCache={rankingCache}
          title={portfolioName ?? ""}
          usage={window.App.usage}
          widgets={{ table: { get: getWidgetInfo } }}
        />
      ),
    };

    actions.push(action);

    var message = {
      from: "analysisListsRank",
      content: {
        actions: actions,
      },
    };

    broadcast(config["channels"]["workflow"]["input"], message);

    return () => {
      var message = {
        from: "analysisListsRank",
        content: {
          actions: [],
        },
      };

      broadcast(config["channels"]["workflow"]["input"], message);
    };
  }, [
    broadcast,
    getWidgetInfo,
    listId,
    listName,
    listType,
    openRankDialog,
    portfolioName,
    rankingCache,
  ]);

  const tableEvents: TableEventsV2 = useMemo(
    () => ({
      headerSort: onDataSorted,
      onTableBuilt: () => {
        setTableStatus((current) => ({ ...current, built: true }));
      },
      onTableDestroyed: () => {
        setTableStatus((current) => ({ ...current, built: false }));
      },
      columnsLoaded: (columns) => {
        if (columns.length) {
          setTableStatus((current) => ({ ...current, columns: true }));
        }
      },
    }),
    [onDataSorted]
  );

  const toolsEvents = useMemo(
    () => ({
      onChangePage,
      onChangeRowNumber: onSelectItemPerPage,
      onColumnsEdit: onColumnsChange,
      onColumnsLoaded: updateColumns,
      onChangeRankDate: changeRankDateFrom,
    }),
    [
      changeRankDateFrom,
      onChangePage,
      onColumnsChange,
      onSelectItemPerPage,
      updateColumns,
    ]
  );

  useEffect(() => {
    if (tableReady) {
      const table = tableRef.current?.getInstance();
      const columns: any = [];
      const definitions =
        table?.getColumns().map((col) => col.getDefinition()) ?? [];

      for (const col of definitions) {
        if (col.field != null) {
          columns.push(col);
        }
      }

      rankReducer({
        type: "SET_COLUMNS",
        payload: columns,
      });
    }
  }, [rankReducer, tableReady]);

  return (
    <Box ref={containerRef}>
      {showDialogRank && (
        <RankDialog closeDialog={closeRankDialog} page={"portfolioAnalysis"} />
      )}
      <Card sx={{ height: "98%" }}>
        <CardContent sx={{ height: "100%", display: "flex", flex: 1 }}>
          <TrendratingTableV2
            ref={tableRef}
            tools={{
              addToButton: true,
              configurator: {
                hasToSkipLastApplied: false,
                defaultTemplateNameBase: "DEFAULT_SCREENING",
                configurations: configurationScreening.widgets.viewer,
                securityType: "security",
                isSaveLastUsedConfigurationColumnsEnabled: true,
              },
              rank: {
                showRankTools: true,
                dateInitValue: rankFrom,
              },
              pagination: {
                dataTotalCount,
              },
              viewAsListButton: true,
              rowsNumberSelect: {
                enabled: true,
                label: "Securities per page",
              },
            }}
            toolsEvents={toolsEvents}
            tableEvents={tableEvents}
            rowTooltipFormatter
          />
        </CardContent>
      </Card>
    </Box>
  );
};
