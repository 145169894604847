import { Box, Card, CardContent } from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import { CellComponent, ColumnDefinition } from "tabulator-tables";
import Block from "../../../../../../../components/LayoutElements/Block/Block";
import {
  TableEventsV2,
  TableV2,
} from "../../../../../../../components/table/v2/TableCoreV2";
import { TrendratingTableV2 } from "../../../../../../../components/table/v2/TableV2";
import { useEnvironment } from "../../../../../../../hooks/useEnvironment";
import { useFormatter } from "../../../../../../../hooks/useFormatter";
import { Loader } from "../../../../../components/Loader";
import DispersionBlock from "../../../../analysisMarkets/ETFs/widgets/ContentETFMarkets/widgets/DispersionView/DispersionBlock/DispersionBlock";
import styles from "./Dispersion.module.scss";
import DispersionChart from "./DispersionChart";
import { ControlsState } from "./TabControlsReducer";

type DispersionContentProps = {
  dispersionData: any;
  contentToShow: "content" | "loader" | "error" | "emptyData";
  state: ControlsState;
  constituentsType: any[];
};

const customSorter = (a, b) => {
  if (a === b) {
    return 0;
  }
  if (a === "Portfolio" || a === "Basket") {
    return -1;
  }
  if (b === "Portfolio" || b === "Basket") {
    return 1;
  }

  if (a > b) {
    return 1;
  } else if (a < b) {
    return -1;
  }

  return 0;
};

const formatCells = (
  cell: CellComponent,
  range: "top" | "middle" | "bottom",
  formatterFunction
) => {
  const data = cell.getData();
  const avg = data?.["dispersion"]?.[range]?.["average"] ?? null;

  const result = formatterFunction.custom("number", {
    options: {
      isPercentage: true,
      notAvailable: {
        input: null,
        output: "-",
      },
    },
    output: "HTML",
    value: avg,
    valueHelper: null,
  });

  return result;
};

const getLabelFromState = (segment, intervals) => {
  if (!segment || !intervals) {
    return { segment: "-", interval: "-" };
  }

  let defaultSegment = "market";
  let defaultInterval = "4";

  switch (segment) {
    case "etfgeo":
      defaultSegment = "Inv. Region";
      break;

    case "Country":
    default:
      break;

    case "etfclass":
    case "1 Industry":
      defaultSegment = "sector";

      break;

    case "3 Level":
      defaultSegment = "size";

      break;
  }

  switch (intervals) {
    case 25:
    default:
      break;

    case 10:
      defaultInterval = "10";

      break;

    case 5:
      defaultInterval = "20";

      break;
  }

  return { defaultSegment, defaultInterval };
};

const percentageSorter = (a, b, property: "top" | "middle" | "bottom") => {
  if (a?.[property]?.average > b?.[property]?.average) {
    return 1;
  } else if (a?.[property]?.average < b?.[property]?.average) {
    return -1;
  }

  return 0;
};

const ContentWizard = ({ contentToShow, data, state, constituentsType }) => {
  const { dispersion_widgets_row } = styles;
  const formatter = useFormatter();

  const { segment: s, intervals: i, etfSegment } = state;
  const isListMultiType = constituentsType.length > 1;
  const isOnlyEtf = !isListMultiType && constituentsType[0] === "ETF";
  const segmentByAssetType = isOnlyEtf ? etfSegment : s;
  const { defaultSegment: segment, defaultInterval: interval } =
    getLabelFromState(segmentByAssetType, i);
  const tableData = data ? Object.values<any>(data) : [];
  const sortedData = tableData?.sort((a, b) => customSorter(a.name, b.name));

  const chartToShow = useMemo(() => {
    if (isOnlyEtf) {
      if (state.etfSegment === "all_etf") {
        return "dispersionBlock";
      } else {
        return "histogram";
      }
    } else {
      if (state.segment === "all") {
        return "dispersionBlock";
      } else {
        return "histogram";
      }
    }
  }, [isOnlyEtf, state.etfSegment, state.segment]);

  //#region - TrendratingTableV2 SETUP AND HANDLERS
  const columnsConfig: ColumnDefinition[] = useMemo(() => {
    const intervalTitle = {
      25: {
        top: "Top 25%",
        mid: "Mid 50%",
        bottom: "Bottom 25%",
      },
      10: {
        top: "Top 10%",
        mid: "Mid 80%",
        bottom: "Bottom 10%",
      },
      5: {
        top: "Top 5%",
        mid: "Mid 90%",
        bottom: "Bottom 5%",
      },
    };

    return [
      {
        field: "name",
        title: "Sectors",
        formatter: (cell: CellComponent) => {
          const cellValue = cell.getValue();

          if (cellValue === "Portfolio" || cellValue === "Basket") {
            const row = cell.getRow();
            const rowHtml = row.getElement();

            if (rowHtml) {
              rowHtml.style.fontWeight = "bold";
              rowHtml.style.fontSize = "1.2em";
              rowHtml.style.backgroundColor = "rgba(255, 192, 1, 0.2)";
            }

            return `<strong>${cellValue}</strong>`;
          } else {
            return cellValue;
          }
        },
        sorter: customSorter,
        widthGrow: 3,
      },
      {
        title: "Costituents",
        field: "cardinality",
        headerHozAlign: "center",
        hozAlign: "center",
        sorter: "number",
      },
      {
        title: intervalTitle[state["intervals"]]["top"],
        field: "dispersion",
        headerHozAlign: "center",
        hozAlign: "center",
        formatter: (cell) => formatCells(cell, "top", formatter),
        sorter: (a, b) => percentageSorter(a, b, "top"),
      },
      {
        title: intervalTitle[state["intervals"]]["mid"],
        field: "dispersion",
        headerHozAlign: "center",
        hozAlign: "center",
        formatter: (cell) => formatCells(cell, "middle", formatter),
        sorter: (a, b) => percentageSorter(a, b, "middle"),
      },
      {
        title: intervalTitle[state["intervals"]]["bottom"],
        field: "dispersion",
        headerHozAlign: "center",
        hozAlign: "center",
        formatter: (cell) => formatCells(cell, "bottom", formatter),
        sorter: (a, b) => percentageSorter(a, b, "bottom"),
      },
    ];
  }, [formatter, state]);
  const tableRef = useRef<{ getInstance: () => TableV2 }>();
  const [tableReady, setTableReady] = useState(false);
  const tableOptions = useMemo(() => {
    return {
      tableOption: {
        ajaxSorting: false,
        movableColumns: false,
        columns: columnsConfig,
        selectableRows: false,
      },
    };
  }, [columnsConfig]);
  useEffect(() => {
    if (tableReady) {
      const table = tableRef.current?.getInstance();
      table?.insertData(sortedData);
    }
  }, [sortedData, tableReady]);
  const tableEvents: TableEventsV2 = useMemo(
    () => ({
      onTableBuilt: () => {
        setTableReady(true);
      },
      onTableDestroyed: () => {
        setTableReady(false);
      },
    }),
    []
  );
  //#endregion

  switch (contentToShow) {
    case "loader":
      return <Loader />;

    case "emptyData":
      return (
        <Block>
          <WarningMessage
            message={`There's no ${segment} that contains at least ${interval} costituents`}
          />
        </Block>
      );

    case "error":
      return (
        <Block>
          <ErrorMessage message="Sorry an error occured while loading data, please retry later" />
        </Block>
      );

    case "content":
    default:
      return (
        <section className={dispersion_widgets_row}>
          <Card sx={{ flex: 1 }}>
            <CardContent>
              <TrendratingTableV2
                ref={tableRef}
                tableEvents={tableEvents}
                tableOptions={tableOptions}
              />
            </CardContent>
          </Card>

          <Card sx={{ flex: 1 }}>
            <CardContent sx={{ height: "100%" }}>
              {chartToShow === "dispersionBlock" ? (
                <PortfolioBlock
                  intervals={state["intervals"]}
                  type={isOnlyEtf ? "ETF" : "Stock"}
                  data={data?.["Portfolio"] || data?.["Basket"]}
                />
              ) : (
                <DispersionChart
                  data={data}
                  interval={state["intervals"]}
                  timeframe={state["period"]}
                  chartType={state["viewType"]}
                />
              )}
            </CardContent>
          </Card>
        </section>
      );
  }
};

export default function DispersionContent({
  dispersionData,
  contentToShow,
  state,
  constituentsType,
}: DispersionContentProps) {
  return (
    <ContentWizard
      data={dispersionData}
      contentToShow={contentToShow}
      state={state}
      constituentsType={constituentsType}
    />
  );
}

const WarningMessage = ({ message }: { message?: string }) => {
  const { warning } = styles;
  return (
    <div className={warning}>
      <p>{message ?? "No data was found"}</p>
    </div>
  );
};

const ErrorMessage = ({ message }: { message?: string }) => {
  const { error } = styles;
  return (
    <div className={error}>
      <p>{message ?? "An error occured while loading data"}</p>
    </div>
  );
};

const PortfolioBlock = ({ data, intervals, type }) => {
  const interval = useMemo(() => {
    const DICT = {
      25: 4,
      10: 10,
      5: 20,
    };

    return DICT[intervals];
  }, [intervals]);

  const environment = useEnvironment();

  const showDispersionRating = useMemo(() => {
    const configuration =
      environment.get("account")["product"]["configuration"]["analysis_list"];
    const tabs = configuration?.overview_tabs ?? [];
    const dispersionTabConfig = tabs.find((item) => item.id === "dispersion");

    return dispersionTabConfig?.dipersionByRating ?? false;
  }, [environment]);

  return data ? (
    <Box
      mt={1}
      flex={1}
      display={"flex"}
      flexDirection={"column"}
      gap={1}
      height={"100%"}
    >
      <DispersionBlock
        data={data.dispersion}
        intervalType="top"
        intervalValue={interval}
        type={type}
        showRating={showDispersionRating}
      />
      <DispersionBlock
        data={data.dispersion}
        intervalType="middle"
        intervalValue={interval}
        type={type}
        showRating={showDispersionRating}
      />
      <DispersionBlock
        data={data.dispersion}
        intervalType="bottom"
        intervalValue={interval}
        type={type}
        showRating={showDispersionRating}
      />
    </Box>
  ) : (
    <></>
  );
};
