import { Box, Card, CardContent, Typography } from "@mui/material";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { decodePeerId } from "../../../../../../../../../api/utils";
import { AlertsSelect } from "../../../../../../../../../components/AlertsSelect/AlertsSelect";
import RatingCheckbox from "../../../../../../../../../components/RatingCheckbox/RatingCheckbox";
import { Spinner } from "../../../../../../../../../components/Spinner/Spinner";
import { useTaxonomyByType } from "../../../../../../../../../hooks/useTaxonomyByType";
import {
  ActionsETFMarketsControls,
  SecuritiesViewActionMarketsETF,
} from "../../../../AnalysisMarketsETF";
import InstrumentsTableForAnalytics from "../InstrumentsTableForAnalytics/InstrumentsTableForAnalytics";
import { SecuritiesTable } from "./SecuritiesTable/SecuritiesTable";

type SecuritiesViewProps = {
  segment:
    | "WWW"
    | "1 Industry"
    | "3 Sector"
    | "Area"
    | "Region"
    | "Country"
    | "4 Subsector";
  segmentReference: any;
  data: any;
  tableCellNameFormatter: (field) => Function;
  dispatch: (action: ActionsETFMarketsControls) => void;
  securitiesDispatcher: (action: SecuritiesViewActionMarketsETF) => void;
  rating: { A: boolean; B: boolean; C: boolean; D: boolean };
  currentSort: { field: string; rev: boolean };
  alert:
    | null
    | "Any"
    | "upgrades_today"
    | "upgrades_last_5_days"
    | "upgrades_last_20_days"
    | "upgrades_last_60_days"
    | "downgrades_today"
    | "downgrades_last_5_days"
    | "downgrades_last_20_days"
    | "downgrades_last_60_days"
    | "positive_movers"
    | "negative_movers";
  showTable: boolean;
  securitiesPeerId: string | undefined;
  page: number;
};

type Constraints = {
  pagination: { itemsPerPage: number; page: number };
  alert:
    | null
    | "Any"
    | "upgrades_today"
    | "upgrades_last_5_days"
    | "upgrades_last_20_days"
    | "upgrades_last_60_days"
    | "downgrades_today"
    | "downgrades_last_5_days"
    | "downgrades_last_20_days"
    | "downgrades_last_60_days"
    | "positive_movers"
    | "negative_movers";
  rating: { A: boolean; B: boolean; C: boolean; D: boolean };
};

type PeerTitleDescriptionProps = {
  data: any;
};

export function SecuritiesView({
  tableCellNameFormatter,
  segment,
  data,
  dispatch,
  securitiesDispatcher,
  rating,
  alert,
  currentSort,
  segmentReference,
  showTable,
  securitiesPeerId,
  page,
}: SecuritiesViewProps) {
  const [showTcrTable, setShowTcrTable] = useState(showTable);

  const tableRef = useRef<any>(null);
  // !ATTENTION: VERY PARTICULAR CASE
  // In this useEffect we take care about the appearence of the instrument table in this tab view.
  //
  // ?BUSINESS LOGIC:
  // When the xDim key, referred to the taxonomie's y axis, is setted to a node that  is a leaf, in taxonomie's hierarchy (has no children levels under itself) we want to hide the table with the resume of
  // instruments to avoid misunderstandigs by the user, because the switch of the drilldown from the yDimension
  // to the xDimension that can confuse.
  // But the drilldown possibilities are still valid, infact we can cluster the peer by geo dimension. So
  // for this reason if a user want to change the drilldown segment to see a cluster by geo dimension, we need to
  // make the table newly visible.
  //
  // ?TECH SOLUTION
  // In the AnalysisMarketsETF.tsx file is explained the fist part related to the management of this case and everything is documented.
  // We have stored a reference to the selected drilldown segment when the yDim of the peer is changed
  // into a leaf.
  // We show the table only when the stored segment is different from the current drilldown segment,
  // because if they are different it means that the user has changed the drilldown segment. But if the user
  // come back on the Country segment that is the default for this case, the table would be hided, if we don't
  // modify the value of the reference.
  // So for this when the reference and the current segments are equals (it can happend only at first render)
  // we change the reference value into another that cannot ever match the segment value.
  // useEffect(() => {
  //   if (segmentReference.current !== segment) {
  //     setShowTcrTable(true);
  //   } else {
  //     segmentReference.current = "any";
  //   }
  // }, [segment, segmentReference]);

  useEffect(() => {
    setShowTcrTable(segmentReference);
  }, [segmentReference]);

  const updateParamsWithPeerId = useCallback(
    (id) => {
      securitiesDispatcher({
        type: "SET_PEER_ID",
        payload: id,
      });
    },
    [securitiesDispatcher]
  );

  const setRating = useCallback(
    (ratingAsObject: Constraints["rating"]) => {
      securitiesDispatcher({
        type: "SET_RATING",
        payload: ratingAsObject,
      });
    },
    [securitiesDispatcher]
  );

  const setAlerts = useCallback(
    (value) => {
      securitiesDispatcher({ type: "SET_ALERT", payload: value });
    },
    [securitiesDispatcher]
  );

  const onSortChange = useCallback(
    (value) => {
      securitiesDispatcher({ type: "SET_SORT", payload: value });
    },
    [securitiesDispatcher]
  );

  const dataTotalCount = useMemo(() => {
    return data?.securities?.dataTotalCount ?? 0;
  }, [data?.securities?.dataTotalCount]);

  return (
    <Box
      mt={1}
      display={"flex"}
      flex={1}
      boxShadow={3}
      p={1}
      minHeight={0}
      borderRadius={2}
      minWidth={0}
    >
      {data?.securities ? (
        <>
          {data?.peer?.info?.cardinality > 0 ? (
            <Box flex={1} minHeight={0} display={"flex"} gap={1} minWidth={0}>
              {showTcrTable && (
                <Box flex={1} display={"flex"} minHeight={0}>
                  {/* Instruments Table */}
                  <InstrumentsTableForAnalytics
                    nameFormatter={tableCellNameFormatter}
                    segment={segment}
                    data={data}
                    type={"ETF"}
                    dispatch={dispatch}
                    paramsUpdate={updateParamsWithPeerId}
                    resetContextualParams={() =>
                      securitiesDispatcher({
                        type: "RESET_TO_DEFAULT",
                      })
                    }
                  />
                </Box>
              )}
              <Box
                flex={showTcrTable ? 3 : 1}
                minHeight={0}
                display={"flex"}
                flexDirection={"column"}
                minWidth={0}
              >
                <Box minHeight={0} display={"flex"} minWidth={0}>
                  {/* Constraints bar */}
                  <Card
                    sx={{
                      minWidth: 0,
                      marginBottom: "10px",
                      border: "1px solid #2a7090",
                      flex: 1,
                      minHeight: 0,
                    }}
                  >
                    <CardContent
                      sx={{
                        minWidth: 0,
                        minHeight: 0,
                        paddingTop: "5px",
                        paddingBottom: "5px!important",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <PeerTitleDescription
                        data={securitiesPeerId ?? data?.peer?.id}
                      />
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginLeft: "10px",
                        }}
                      >
                        <label
                          className="filter-bar__field-label"
                          style={{ color: "#2a7090" }}
                        >
                          Rating
                        </label>
                        <RatingCheckbox
                          stateGetter={setRating}
                          initState={rating}
                        />
                      </div>
                      <AlertsSelect setValue={setAlerts} initValue={alert} />
                    </CardContent>
                  </Card>
                </Box>
                {/* Securities drilldown table */}
                <Card
                  sx={{
                    minHeight: 0,
                    minWidth: 0,
                    display: "flex",
                    flex: 1,
                    alignItems: "stretch",
                  }}
                >
                  <CardContent
                    sx={{
                      display: "flex",
                      flex: 1,
                      flexDirection: "column",
                      minHeight: 0,
                      minWidth: 0,
                      height: "100%",
                    }}
                  >
                    <SecuritiesTable
                      type={"ETF"}
                      dt={dataTotalCount}
                      currentSort={currentSort}
                      data={data?.securities}
                      ref={tableRef}
                      onSortChange={onSortChange}
                    />
                  </CardContent>
                </Card>
              </Box>
            </Box>
          ) : (
            <Card sx={{ flex: 1 }}>
              <CardContent>
                <Typography fontSize={"14px"} fontWeight={"bold"}>
                  Any ETF was found
                </Typography>
              </CardContent>
            </Card>
          )}
        </>
      ) : (
        <Card sx={{ flex: 1 }}>
          <CardContent>
            <div
              style={{
                flex: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Spinner />
            </div>
          </CardContent>
        </Card>
      )}
    </Box>
  );
}

const PeerTitleDescription = ({ data }: PeerTitleDescriptionProps) => {
  const { rootNodeX, rootNodeY, taxonomiesMapX, taxonomiesMapY } =
    useTaxonomyByType("ETF");
  const name = useMemo(() => {
    const { where, what, zDimension } = decodePeerId(data);
    const whatName = taxonomiesMapY[what]?.name ?? what;
    const whereName = taxonomiesMapX[where]?.name ?? where;

    let name = "";

    if (whatName !== rootNodeY) {
      name = whatName;
    }

    if (whereName !== rootNodeX) {
      name += ` - ${whereName}`;
    }

    if (zDimension !== "ALL") {
      name += ` - ${zDimension}`;
    }

    if (name.length === 0) {
      name = "Any Asset Class";
    }

    return name;
  }, [data, rootNodeX, rootNodeY, taxonomiesMapX, taxonomiesMapY]);
  return <Typography>{name}</Typography>;
};
