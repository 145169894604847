import { CellComponent, RowComponent } from "tabulator-tables";
import { ClusterAnalytics } from "../../../api/compute/ClusterAnalytics";
import { Instruments } from "../../../api/compute/Instruments";
import { Peers } from "../../../api/compute/Peers";
import {
  formatTaxonPrefixingParent,
  getChildrenAtLevel,
  getTaxonById,
} from "../../../api/compute/Taxon";
import { decodePeerId } from "../../../api/utils";
import { Formatter } from "../../../trendrating/utils/Formatter";
import { Instrument } from "../../../types/Api";
import { AppEnvironment } from "../../../types/Defaults";
import { PeerDetailSegments } from "../pages/analysisMarkets/detail/PeerDetail";

type Peer = {
  alerts: {
    last3Months: {
      downgrades: number;
      ratio: number;
      upgrades: number;
    };
    lastMonth: {
      downgrades: number;
      ratio: number;
      upgrades: number;
    };
    lastWeek: {
      downgrades: number;
      ratio: number;
      upgrades: number;
    };
    today: {
      downgrades: number;
      ratio: number;
      upgrades: number;
    };
  };
  id: string;
  info: {
    cardinality: number;
    marketcap: number;
  };
  name: string;
  size: string;
  statistic: {
    abPercentage: {
      today: number;
      yesterday: number;
      lastWeek: number;
      lastMonth: number;
    };
    cdPercentage: {
      today: number | null;
      yesterday: number | null;
      lastWeek: number | null;
      lastMonth: number | null;
    };
    downgrades: {
      today: {
        number: number | null;
        percentage: number | null;
      };
      yesterday: {
        number: number | null;
        percentage: number | null;
      };
      lastWeek: {
        number: number | null;
        percentage: number | null;
      };
      lastMonth: {
        number: number | null;
        percentage: number | null;
      };
    };
    ratings: {
      today: {
        A: {
          number: number | null;
          percentage: number | null;
        };
        B: {
          number: number | null;
          percentage: number | null;
        };
        C: {
          number: number | null;
          percentage: number | null;
        };
        D: {
          number: number | null;
          percentage: number | null;
        };
      };
      yesterday: number | null;
      lastWeek: number | null;
      lastMonth: number | null;
    };
    quantiles: {
      pm: {
        q0: null;
        q1: null;
        q2: null;
        q3: null;
        q4: null;
      };
      pq: {
        q0: null;
        q1: null;
        q2: null;
        q3: null;
        q4: null;
      };
      py: {
        q0: null;
        q1: null;
        q2: null;
        q3: null;
        q4: null;
      };
      pw: {
        q0: null;
        q1: null;
        q2: null;
        q3: null;
        q4: null;
      };
    };
    upgrades: {
      today: {
        number: number | null;
        percentage: number | null;
      };
      yesterday: {
        number: number | null;
        percentage: number | null;
      };
      lastWeek: {
        number: number | null;
        percentage: number | null;
      };
      lastMonth: {
        number: number | null;
        percentage: number | null;
      };
    };
  };
  tcr: {
    today: number;
    yesterday: number;
    lastMonth: number;
    lastWeek: number;
  };
  type: "Stock" | "ETF";
  what: string;
  where: string;
};

type Alerts =
  | "Any"
  | null
  | "upgrades_today"
  | "upgrades_last_5_days"
  | "upgrades_last_20_days"
  | "upgrades_last_60_days"
  | "downgrades_today"
  | "downgrades_last_5_days"
  | "downgrades_last_20_days"
  | "downgrades_last_60_days"
  | "positive_movers"
  | "negative_movers";

type DispersionResponseRow = {
  A: number;
  "A_%": number;
  average: number;
  B: number;
  "B_%": number;
  C: number;
  "C_%": number;
  cardinality: number;
  D: number;
  "D_%": number;
  data: { symbol: string }[];
  dataTotalCount: number;
  max: number;
  min: number;
  N: number;
  "N_%": number;
};

type DispersionResponse = {
  top: DispersionResponseRow;
  middle: DispersionResponseRow;
  bottom: DispersionResponseRow;
};

type TableField =
  | "name"
  | "tcr"
  | "tcr_changes"
  | "abPerc"
  | "cdPerc"
  | "cardinality"
  | "ab_changes"
  | "cd_changes"
  | "upgrades"
  | "downgrades"
  | "upgrades_perc"
  | "downgrades_perc";

interface PeerDetailStorageInterface {
  getPeer: (params: {
    size: string;
    what: string;
    where: string;
    type: "stock" | "ETF";
  }) => Promise<Peer | undefined>;
  getPeerChildren: (
    params: {
      size: string;
      what: string;
      where: string;
      type: "stock" | "ETF";
    },
    segment: PeerDetailSegments
  ) => Promise<Peer[] | undefined>;
  getChildrenBySize: (params: {
    size: string;
    what: string;
    where: string;
    type: "stock" | "ETF";
  }) => Promise<Peer[] | undefined>;
  getPeerHistoy: (
    peerId: string,
    metrics: string[],
    years: number
  ) => Promise<
    | {
        d: number;
        t: number;
        A_n: number;
        B_n: number;
        C_n: number;
        D_n: number;
      }[]
    | undefined
  >;
  getPeerDispersion: (params: {
    peerId: string;
    performance: "1_week" | "1_month" | "3_months" | "6_months" | "12_months";
    intervals: 4 | 10 | 20;
    trimOutliers: boolean;
  }) => Promise<DispersionResponse | undefined>;
  getSecurities: (
    peerId: string,
    pagination: { itemsPerPage: number; page: number },
    alert: Alerts,
    rating: { A: boolean; B: boolean; C: Boolean; D: Boolean },
    sorter: { field: string; rev: boolean } | null
  ) => Promise<Instrument | undefined>;
  getDispersionBy: (params: {
    peerId: string | undefined;
    performance: "1_week" | "1_month" | "3_months" | "6_months" | "12_months";
    intervals: 4 | 10 | 20;
    segment: PeerDetailSegments;
    trimOutliers: boolean;
  }) => Promise<DispersionResponse | undefined>;
  getPerformanceSinceRated: (
    peerId: string,
    performanceAt: "sinceRated" | "3_months" | "6_months" | "12_months"
  ) => Promise<any>;
  tableFormatterByField: (field: TableField) => Function;
}

export class PeerDetailStorage implements PeerDetailStorageInterface {
  private apiPeer: Peers;
  private apiCluster: ClusterAnalytics;
  private apiInstrument: Instruments;
  private taxonomies: [];
  private taxonomiesFields: any;
  private formatter: Formatter;

  constructor(private setup: AppEnvironment) {
    this.apiPeer = new Peers(setup);
    this.apiCluster = new ClusterAnalytics(setup);
    this.apiInstrument = new Instruments(setup);
    this.taxonomies = setup["taxonomies"];
    this.taxonomiesFields = setup["taxonomyFields"];
    this.formatter = new Formatter(setup);
  }

  /**
   *
   * @param {object} params
   *
   * @returns an array of arrays with the peers analytics requested. Remember that the server always returns the asked aggregate
   * and if he doesn't find the peer on the database returns the payload instead of peer's analytics
   */
  public async getPeer(params) {
    try {
      const response = await this.apiPeer.get([[params]]);

      if (response) {
        //get the first object because we asked only for one peer
        const peer = response?.[0] ?? undefined;
        const id = peer[0]?.id;

        if (peer && id) {
          return (
            this.setName({ id, instrumentType: params.type }, peer)?.[0] ??
            undefined
          );
        } else {
          return undefined;
        }
      }
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  /**
   *
   * @param peerId
   *
   * @returns data for performance since rated tab
   */
  public async getPerformanceSinceRated(
    peerId,
    performanceAt: "sinceRated" | "3_months" | "6_months" | "12_months"
  ) {
    const peerInfo = decodePeerId(peerId);
    const data = {
      AB: {
        peerAvg: null,
        quartileAvg: {
          1: null,
          2: null,
          3: null,
          4: null,
        },
        securities: [],
        quartilesSymbols: {},
      },
      CD: {
        peerAvg: null,
        quartileAvg: {
          1: null,
          2: null,
          3: null,
          4: null,
        },
        securities: [],
        quartilesSymbols: {},
      },
      peerInfo,
      formatterSectorBy: null,
    };

    const analyticDict = {
      sinceRated: "pr#avg#false",
      "3_months": "pq#avg#false",
      "6_months": "ps#avg#false",
      "12_months": "py#avg#false",
    };

    const analytic = [analyticDict[performanceAt]];
    const clusters = [
      {
        dimension: "rc",
        transform: {
          function: "ranges",
          params: { segments: [{ "<": 0 }, { ">=": 0 }] },
        },
      },
      {
        dimension: "pr",
        transform: {
          function: "quantile",
          params: { n: 4, trimOutliers: false },
        },
      },
    ];

    let configuration = this.apiCluster
      .createConfiguration()
      .analytics(analytic)
      .clusters(clusters)
      .method("DRILL_DOWN")
      .universeFromPeer(peerId, [{ dimension: "lr", segments: [{ min: 10 }] }]);

    const result = await configuration.fetchAnalytics();

    const stats = result?.clustersStats?.stats ?? {};

    for (const [key, value] of Object.entries<any>(stats)) {
      const splittedKey = key.split("|");
      const isAB = splittedKey[0] === "1";
      const quartileNumber = parseInt(splittedKey["1"]);
      const accessor = isAB ? "AB" : "CD";

      data[accessor].quartileAvg[quartileNumber] = value[analytic[0]];
    }

    const tickersAB: any = [];
    const tickersCD: any = [];

    const clustersResult = result?.clustersStats?.clusters ?? {};
    for (const [key, value] of Object.entries<any>(clustersResult)) {
      const splittedKey = key.split("|");
      const isAB = splittedKey[0] === "1";

      const quartileAccessor = splittedKey[1];
      const breakQuartileAccessor = quartileAccessor.split(".");
      const quartileIndex = breakQuartileAccessor[0];

      if (isAB) {
        value.forEach(
          (symbol) =>
            (data.AB.quartilesSymbols[symbol] = parseInt(quartileIndex))
        );
        tickersAB.push(...value);
      } else {
        value.forEach(
          (symbol) =>
            (data.CD.quartilesSymbols[symbol] = parseInt(quartileIndex))
        );
        tickersCD.push(...value);
      }
    }

    data["AB"]["securities"] = tickersAB;
    data["CD"]["securities"] = tickersCD;

    // This is the key that came back from server when no cluster is specified
    // const noSpecifiedClustersKey = "ANY";

    // Make the last clusterAnalytics to get the cumulative avg
    if (tickersAB.length) {
      // configuration = this.apiCluster
      //   .createConfiguration()
      //   .analytics(analytic)
      //   .clusters([])
      //   .method("DRILL_DOWN")
      //   .universeFromInstruments(tickersAB, [
      //     { dimension: "lr", segments: [{ min: 10 }] },
      //   ]);

      // const cumulativeAvgAB = await configuration.fetchAnalytics();

      // data.AB.peerAvg =
      //   cumulativeAvgAB.clustersStats.stats?.[noSpecifiedClustersKey]?.[
      //     analytic?.[0]
      //   ] ?? null;

      let avgSum = 0;
      const clustersNumber = 4;

      for (const key in data["AB"].quartileAvg) {
        avgSum += data["AB"].quartileAvg[key];
      }

      let avg: any = avgSum / clustersNumber;
      data.AB.peerAvg = avg;
    }

    if (tickersCD.length) {
      // configuration = this.apiCluster
      //   .createConfiguration()
      //   .analytics(analytic)
      //   .clusters([])
      //   .method("DRILL_DOWN")
      //   .universeFromInstruments(tickersCD, [
      //     { dimension: "lr", segments: [{ min: 10 }] },
      //   ]);

      // const cumulativeAvgCD = await configuration.fetchAnalytics();

      // data.CD.peerAvg =
      //   cumulativeAvgCD.clustersStats.stats?.[noSpecifiedClustersKey]?.[
      //     analytic?.[0]
      //   ] ?? null;

      let avgSum = 0;
      const clustersNumber = 4;

      for (const key in data["CD"].quartileAvg) {
        avgSum += data["CD"].quartileAvg[key];
      }

      let avg: any = avgSum / clustersNumber;
      data.CD.peerAvg = avg;
    }

    const peerType = peerInfo.type.toLowerCase();
    const peerDimY = peerInfo.what;
    const env = this.setup;
    const taxonomies = env["taxonomies"];
    const fieldsMap = env["taxonomyFields"];
    const type = peerType === "etf" ? "ETF" : "security";
    const field = type === "ETF" ? "etfclass" : "icb";
    const taxonomyMapY = taxonomies[fieldsMap[type][field]];
    const node = taxonomyMapY[peerDimY];
    let segment: any = "1 Industry";

    switch (node.type) {
      default:
        segment = null;

        break;

      case "0 root":
        break;

      case "1 Industry":
        segment = "3 Sector";
    }

    data["formatterSectorBy"] = segment;

    if (data) {
      return data;
    }

    return undefined;
  }

  /**
   *
   * @param {string} peerId
   * @param {object} pagination
   * @param {string} alert
   * @param {object} rating
   *
   * @returns all the securities of a selected peer
   */
  public async getSecurities(
    peerId: string | undefined,
    pagination: { itemsPerPage: number; page: number },
    alert: Alerts,
    rating: { A: boolean; B: boolean; C: Boolean; D: Boolean },
    sorter: { field: string; rev: boolean } | null
  ) {
    if (!peerId) {
      return undefined;
    }

    const peerInfo = decodePeerId(peerId);
    const requestBody = {
      constraints: [
        [
          {
            dimension: "type",
            operator: "equals",
            segments: [peerInfo.type === "ETF" ? "ETF" : "Stock"],
          },
        ],
      ],
      page: {
        page: pagination.page ?? 1,
        rows: pagination.itemsPerPage ?? 20000,
      },
      sort: [{ dimension: "marketcap", rev: true }],
    };

    if (sorter) {
      requestBody.sort.unshift({ dimension: sorter.field, rev: sorter.rev });
    }

    const type = peerInfo.type === "ETF" ? "ETF" : "security";
    const fieldX = peerInfo.type === "ETF" ? "etfgeo" : "country";
    const fieldY = peerInfo.type === "ETF" ? "etfclass" : "icb";

    const whatTaxonomies = this.taxonomies[this.taxonomiesFields[type][fieldY]];
    const whereTaxonomies =
      this.taxonomies[this.taxonomiesFields[type][fieldX]];

    const rootNodeX = Object.values<any>(whereTaxonomies).find(
      (node) => node.parent == null
    );
    const rootNodeY = Object.values<any>(whatTaxonomies).find(
      (node) => node.parent == null
    );

    if (peerInfo.type.toLowerCase() === "stock") {
      requestBody.constraints[0].push({
        dimension: "stockclass",
        operator: "equals",
        segments: ["STOCK"],
      });
    }

    if (peerInfo.where !== rootNodeX.id) {
      requestBody.constraints[0].push({
        dimension: fieldX,
        operator: "equals",
        segments: [peerInfo.where],
      });
    }

    if (peerInfo.what !== rootNodeY.id) {
      requestBody.constraints[0].push({
        dimension: fieldY,
        operator: "equals",
        segments: [peerInfo.what],
      });
    }

    if (peerInfo.zDimension !== "ALL" && peerInfo.zDimension !== "microLarge") {
      requestBody.constraints[0].push({
        dimension: "sizeClassification",
        operator: "equals",
        segments: [peerInfo.zDimension],
      });
    }

    if (
      rating.A === true ||
      rating.B === true ||
      rating.C === true ||
      rating.D === true
    ) {
      const ratingPayload: any = {
        dimension: "rc",
        operator: "range",
        segments: [],
      };
      Object.entries(rating).forEach(([key, value]) => {
        if (value === true) {
          switch (key) {
            case "A": {
              ratingPayload.segments.push({ "<=": 2, ">=": 2 });
              break;
            }

            case "B": {
              ratingPayload.segments.push({ "<=": 1, ">=": 1 });
              break;
            }

            case "C": {
              ratingPayload.segments.push({ "<=": -1, ">=": -1 });
              break;
            }

            case "D": {
              ratingPayload.segments.push({ "<=": -2, ">=": -2 });
              break;
            }
          }
        }
      });

      requestBody.constraints[0].push(ratingPayload);
    }

    const alerts = this.alertsConverter(alert);

    if (alerts) {
      alerts.forEach((alert) => requestBody.constraints[0].push(alert));
    }

    const isPayloadInNewSyntax = true;

    const response = await this.apiInstrument.screening(
      requestBody,
      isPayloadInNewSyntax
    );

    if (response) {
      const symbols = response.data;

      if (symbols) {
        const properties = [
          {
            date: null,
            property: "name",
          },
          {
            date: null,
            property: "symbol",
          },
          {
            date: null,
            property: "vc",
          },
          {
            date: null,
            property: "ticker",
          },
          {
            date: null,
            property: "rc",
          },
          {
            date: null,
            property: type === "ETF" ? "etfclass" : "icb",
          },
          {
            date: null,
            property: "pr",
          },
          {
            date: null,
            property: "dr",
          },
        ];
        const data = await this.apiInstrument.fetch(
          {
            properties,
            symbols,
            type: "security",
          },
          true
        );

        if (data) {
          data["dataTotalCount"] = response["dataTotalCount"];
          return data;
        }
      }
    }

    return undefined;
  }

  /**
   *
   * @param {object} params
   *
   * @returns Peer dispersion by a specific segment (sector, industy, size)
   */
  public async getDispersionBy(params: {
    peerId: string | undefined;
    performance: "1_week" | "1_month" | "3_months" | "6_months" | "12_months";
    intervals: 4 | 10 | 20;
    segment: PeerDetailSegments;
    trimOutliers: boolean;
  }) {
    if (!params.peerId) {
      return undefined;
    }

    let analytic: any = null;

    switch (params.performance) {
      case "1_week":
        analytic = "pw";
        break;
      case "1_month":
        analytic = "pm";
        break;
      case "3_months":
        analytic = "pq";
        break;
      case "6_months":
        analytic = "ps";
        break;
      case "12_months":
        analytic = "py";
        break;
      default:
        throw new Error(`Unknown performance ${performance}`);
    }

    let percentage;

    switch (params.intervals) {
      case 4:
        percentage = 25;
        break;
      case 10:
        percentage = 10;
        break;
      case 20:
        percentage = 5;
        break;
      default:
        throw new Error(`Unknown percentage ${params.intervals}`);
    }

    let level = params.segment;

    const requestParams = {
      analytics: [
        `${analytic}#avg#false`,
        `${analytic}#max#false`,
        `${analytic}#min#false`,
        "A_%",
        "B_%",
        "C_%",
        "D_%",
        "N_%",
        "A",
        "B",
        "C",
        "D",
        "N",
        "cardinality",
      ],
      clusters: [
        {
          dimension: analytic,
          transform: {
            function: "topbottom",
            params: {
              perc: percentage,
              trimOutliers: params.trimOutliers,
            },
          },
        },
      ],
      peerId: params.peerId,
      segment: level,
    };

    const dispersion = await this.getClusters(requestParams);
    const generalDispersion = await this.getPeerDispersion({
      peerId: params.peerId,
      performance: params.performance,
      intervals: params.intervals,
      trimOutliers: params.trimOutliers,
    });

    if (dispersion) {
      dispersion[params.peerId] = {
        dispersion: generalDispersion,
        name: `${this.turnSegmentInLabel(level)}`,
        peer: { id: params.peerId },
      };
      return dispersion;
    }

    return undefined;
  }

  /**
   *
   * @param {object} param
   *
   * @returns data about the dispersion of a selected peer
   */
  public async getPeerDispersion({
    peerId,
    performance,
    intervals,
    trimOutliers,
  }: {
    peerId: string | undefined;
    performance: "1_week" | "1_month" | "3_months" | "6_months" | "12_months";
    intervals: 4 | 10 | 20;
    trimOutliers: boolean;
  }) {
    if (!peerId) {
      return undefined;
    }

    let analytic: string | null = null;

    switch (performance) {
      case "1_week":
        analytic = "pw";
        break;
      case "1_month":
        analytic = "pm";
        break;
      case "3_months":
        analytic = "pq";
        break;
      case "6_months":
        analytic = "ps";
        break;
      case "12_months":
        analytic = "py";
        break;
      default:
        throw new Error(`Unknown performance ${performance}`);
    }

    let percentage: number | null = null;

    switch (intervals) {
      case 4:
        percentage = 25;
        break;
      case 10:
        percentage = 10;
        break;
      case 20:
        percentage = 5;
        break;
      default:
        throw new Error(`Unknown percentage ${intervals}`);
    }

    const requestParams = {
      analytics: [
        `${analytic}#avg#false`,
        `${analytic}#max#false`,
        `${analytic}#min#false`,
        "A_%",
        "B_%",
        "C_%",
        "D_%",
        "N_%",
        "A",
        "B",
        "C",
        "D",
        "N",
        "cardinality",
      ],
      clusters: [
        {
          dimension: analytic,
          transform: {
            function: "topbottom",
            params: {
              perc: percentage,
              trimOutliers: trimOutliers,
            },
          },
        },
      ],
      peerId: peerId,
      segment: undefined,
    };

    const dispersion = await this.getClusters(requestParams);

    if (dispersion) {
      return dispersion;
    }

    return undefined;
  }

  /**
   *
   * @param {string} id
   * @param {object} metrics
   * @param {number} years
   *
   * @returns the rating history of a peer group based on the metrics passed through the payload
   */
  public async getPeerHistoy(id, metrics, years) {
    const keyYears = String(years);
    const historyObj: any = {};

    let metricsToAsk: string[] = [];

    historyObj[keyYears] = {};

    for (const metric of metrics) {
      metricsToAsk.push(metric);
    }

    // Temporary cache

    var params = [
      [
        {
          ...decodePeerId(id),
          metrics: metricsToAsk,
          years: years,
        },
      ],
    ];

    if (metricsToAsk.length > 0) {
      const historyResponse = await this.apiPeer.history(params, true);

      //this.stateHistory[keyYears]
      for (const metric of metrics) {
        historyObj[keyYears][metric] = historyResponse.data[0][0][metric];
      }
      if (historyObj[keyYears]["start"] == null) {
        historyObj[keyYears]["start"] = historyResponse.data[0][0]["start"];
      }
      if (historyObj[keyYears]["end"] == null) {
        historyObj[keyYears]["end"] = historyResponse.data[0][0]["end"];
      }

      // Restore full asked metrics
      const history = await this.apiPeer._decodeHistory(
        { ...params, metrics: metrics },
        {
          data: [[historyObj[keyYears]]],
        }
      );

      return history;
    }

    return undefined;
  }

  /**
   *
   * @param {object} params
   *
   * @returns all the peers (children) under the peer target clusterized by size
   */
  public async getChildrenBySize(params) {
    const taxonomies = Object.values<any>(
      this.taxonomies["SizeClassification"]
    );

    const paramsPeer: any = [[]];

    const children = taxonomies
      .filter((item) => item.type === "3 Level")
      .sort((a, b) => {
        if (a.name > b.name) {
          return 1;
        } else if (a.name < b.name) {
          return -1;
        }

        return 0;
      });

    for (let i = 0, length = children.length; i < length; i++) {
      paramsPeer[0].push({
        zDimension: children[i]["id"],
        type: "Stock",
        what: params["what"],
        where: params["where"],
      });
    }

    const response = await this.apiPeer.get(paramsPeer);

    const childrenSizes: any = [];
    for (const child of response[0]) {
      if (child["info"]["cardinality"] >= 1) {
        child["name"] = taxonomies.find((item) => item.id === child["size"])[
          "name"
        ];
        childrenSizes.push(child);
      }
    }

    return childrenSizes;
  }

  /**
   *
   * @param {object} params
   * @param {string} segment
   *
   * @returns all the peers (children) under the main peer clusterized by the selected drilldown
   */
  public async getPeerChildren(params, segment) {
    const { zDimension, what, where } = params;
    const taxonomies = this.taxonomies;
    const fields = this.taxonomiesFields;
    const typeOfPeer = "security";

    const whatTaxonomies: any = taxonomies[fields[typeOfPeer]["icb"]];
    const whereTaxonomies: any = taxonomies[fields[typeOfPeer]["country"]];

    const whatNode: any = whatTaxonomies[what];
    const whereNode: any = whereTaxonomies[where];

    // Check if there's an issue caused by an error or if the peer is a leaf in the taxonomies tree so cannot have children
    if (
      !whatNode ||
      !whereNode ||
      (whatNode.type === "3 Sector" && whereNode.type === "Country")
    ) {
      return;
    }

    let childrenPeers: {
      zDimension: string;
      what: string;
      where: string;
      type: "Stock";
    }[][] = [[]];
    switch (segment) {
      case "3 Level":
      case "1 Industry":
      case "3 Sector": {
        let children = this.getChildrenAtLevel(
          what,
          segment,
          "icb",
          "security"
        );

        children = children.sort((a, b) => {
          if (a.name > b.name) {
            return 1;
          } else if (a.name < b.name) {
            return -1;
          }

          return 0;
        });

        for (const child of children) {
          childrenPeers[0].push({
            zDimension,
            type: "Stock",
            what: child.id,
            where: where,
          });
        }

        break;
      }
      case "Area":
      case "Country":
      case "Region":
      case "World": {
        let children = this.getChildrenAtLevel(
          where,
          segment,
          "country",
          "security"
        );

        children = children.sort((a, b) => {
          if (a.name > b.name) {
            return 1;
          } else if (a.name < b.name) {
            return -1;
          }

          return 0;
        });

        for (const child of children) {
          childrenPeers[0].push({
            zDimension,
            type: "Stock",
            what: what,
            where: child.id,
          });
        }

        break;
      }
      default: {
        console.log("Unknown childrenType", segment);
      }
    }

    const apiPeer = this.apiPeer;
    const childrenResponse = await apiPeer.get(childrenPeers);

    const children: any = [];

    if (childrenResponse && childrenResponse[0]) {
      for (const child of childrenResponse[0]) {
        if (child.info.cardinality >= 1) {
          switch (segment) {
            case "1 Industry":
            case "3 Sector":
              child["name"] = whatTaxonomies?.[child.what]?.name ?? "";
              break;

            case "Area":
            case "Country":
            case "Region":
            case "World":
              child["name"] = whereTaxonomies?.[child.where]?.name ?? "";
              break;
          }
          children.push(child);
        }
      }

      return children;
    }

    return undefined;
  }

  /**
   *
   * @param {object} requestParams
   *
   * @returns a cluster made on the params passed to this method. This function makes call to the cluster analytics endpoint
   * to obtain analytics about the cluster created using the rules that are specified using requestParams object
   */
  private async getClusters(requestParams: {
    analytics: string[];
    clusters: {
      dimension: string;
      transform: {
        function: string;
        params: {
          perc: number;
          trimOutliers: boolean;
        };
      };
    }[];
    segment?: PeerDetailSegments;
    peerId: string;
  }) {
    let configuration = this.apiCluster
      .createConfiguration()
      .analytics(requestParams.analytics)
      .clusters(requestParams.clusters)
      .method("DRILL_DOWN")
      .universeFromPeer(requestParams.peerId);

    if (requestParams.segment) {
      configuration = configuration.segment(requestParams.segment);
    }

    const result = await configuration.fetchAnalytics();
    const dispersion = await this.apiCluster.decodeAnalytics(
      result,
      requestParams.peerId,
      requestParams.segment
    );

    if (dispersion) {
      return dispersion;
    }

    return undefined;
  }

  /**
   *
   * @param {string}node
   * @param {string} level
   * @param {string} field
   * @param {string} type
   *
   * @returns an array with the nodes that are children of a given node. The children are filtered by a specified level.
   * For example if you want all nodes who has Communications Service as parent but you specify that you want only the nodes that are
   * of type "3 Sector", you need to traverse the hierarchy tree and cut off the nodes that has level 2 supersector.
   */
  private getChildrenAtLevel(
    node: string,
    level: string,
    field: string,
    type: "ETF" | "security"
  ) {
    const hierarchy = this.taxonomies[this.taxonomiesFields[type][field]];

    return getChildrenAtLevel(node, level, hierarchy);
  }

  /**
   *
   * @param {object} params
   * @param {Peer[]} peers
   *
   * @returns the passed peers updated with the right name
   */
  private setName(
    params: { id: string; instrumentType: "ETF" | "stock" },
    peers: Peer[]
  ) {
    const instrumentType = params["instrumentType"].toLowerCase();
    let taxon: any = null;
    const taxonomies = this.taxonomies;
    const fieldMap = this.taxonomiesFields;
    const whatRootNode = Object.values<any>(
      taxonomies[
        fieldMap[instrumentType === "etf" ? "ETF" : "security"][
          instrumentType === "etf" ? "etfclass" : "sector"
        ]
      ]
    ).find((node) => node.parent === null)["id"];
    const whereRootNode = Object.values<any>(
      taxonomies[
        fieldMap[instrumentType === "etf" ? "ETF" : "security"][
          instrumentType === "etf" ? "etfgeo" : "country"
        ]
      ]
    ).find((node) => node.parent === null)["id"];
    let field: any = null;
    for (const peer of peers) {
      if (peer["what"] === whatRootNode && peer["where"] === whereRootNode) {
        peer["name"] = "__ROOT__"; // special name: managed by render
      } else if (
        peer["what"] === whatRootNode &&
        peer["where"] !== whereRootNode
      ) {
        // where only
        field =
          fieldMap[instrumentType === "etf" ? "ETF" : "security"][
            instrumentType === "etf" ? "etfgeo" : "country"
          ];
        taxon = Object.values<any>(taxonomies[field]).find(
          (item) => item.id === peer["where"]
        );
        peer["name"] = taxon["name"];
      } else if (
        peer["what"] !== whatRootNode &&
        peer["where"] === whereRootNode
      ) {
        // what only
        field =
          fieldMap[instrumentType === "etf" ? "ETF" : "security"][
            instrumentType === "etf" ? "etfclass" : "sector"
          ];
        taxon = Object.values<any>(taxonomies[field]).find(
          (item) => item.id === peer["what"]
        );
        peer["name"] = taxon["name"];
      } else {
        // cross: using , as separator. The UI can split the name
        // in substring for formatting purposes
        const whereField =
          fieldMap[instrumentType === "etf" ? "ETF" : "security"][
            instrumentType === "etf" ? "etfgeo" : "country"
          ];
        const whatField =
          fieldMap[instrumentType === "etf" ? "ETF" : "security"][
            instrumentType === "etf" ? "etfclass" : "sector"
          ];
        peer["name"] = [
          Object.values<any>(taxonomies[whereField]).find(
            (item) => item.id === peer["where"]
          )["name"],
          Object.values<any>(taxonomies[whatField]).find(
            (item) => item.id === peer["what"]
          )["name"],
        ].join(",");
      }
    }

    return peers;
  }

  /*********** FORMATTERS FOR UI ************/

  /**
   *
   * @param {string} field
   *
   * @returns a specific formatter for a desired table column. This method helps to format all the tables of the peer detail page
   */
  public tableFormatterByField = (field: TableField) => {
    switch (field) {
      case "name":
        return this.formatName;
      case "tcr":
        return this.formatTcr;
      case "cardinality":
        return this.formatCardinality;
      case "abPerc":
        return this.formatABCDPerc;
      case "cdPerc":
        return this.formatABCDPerc;
      case "ab_changes":
      case "cd_changes":
        return this.formatPercentageChanges;
      case "tcr_changes":
        return this.formatTcrChanges;
      case "upgrades":
        return this.formatUpgradeDowngradeNumber;
      case "downgrades":
        return this.formatUpgradeDowngradeNumber;
      case "upgrades_perc":
        return this.formatUpgradesDowngradesPerc;
      case "downgrades_perc":
        return this.formatUpgradesDowngradesPerc;
      default:
        throw new Error(
          "The field you are looking for seems not to be associated with any formatter"
        );
    }
  };

  /**
   *
   * @param {string} sg
   *
   * @returns The Ui label of the taxonomy level
   */
  private turnSegmentInLabel = (sg: PeerDetailSegments) => {
    switch (sg) {
      case "1 Industry":
        return "All Sectors";

      case "3 Sector":
        return "All Industries";

      case "Area":
        return "All Areas";

      case "Region":
        return "All Regions";

      case "Country":
        return "All Countries";

      case "3 Level":
        return "All Sizes";

      default:
        return sg;
    }
  };

  /**
   *
   * @param {number} timeframe
   *
   * @returns a string obtained from the decode the timeframe expressed in numeric format
   */
  private timeframeToPlainText = (timeframe) => {
    switch (timeframe) {
      case 0:
      default:
        return "today";

      case 4:
        return "lastWeek";

      case 19:
        return "lastMonth";
    }
  };

  private formatNumber = (num: number, isPerc?: boolean) => {
    return num !== 0
      ? this.formatter.custom("number", {
          options: {
            isPercentage: isPerc ?? false,
            notAvailable: {
              input: null,
              output: "",
            },
          },
          output: "HTML",
          value: num,
          valueHelper: {
            normalizationThreshold: 1,
          },
        }) ?? ""
      : "";
  };

  private formatTcr = (cell: CellComponent, timeframe: 0 | 4 | 19) => {
    const data = cell.getData();
    const tcr = (data as any).tcr;
    const timeSegment = this.timeframeToPlainText(timeframe);

    return this.formatter.tcr(tcr[timeSegment], "HTML") ?? "";
  };

  private formatTcrChanges = (cell: CellComponent, timeframe: 0 | 4 | 19) => {
    const data = cell.getData();
    const tcr = (data as any).tcr["today"];
    const timeframeKey = this.timeframeToPlainText(timeframe);
    const tcrFrom =
      timeframe === 0
        ? (data as any).tcr["yesterday"]
        : (data as any).tcr[timeframeKey];

    if (tcr !== tcrFrom) {
      const stringPrefix = tcrFrom > tcr ? "downgraded to" : "upgraded to";

      const formatterOutputFormat = "HTML";
      const tcrHTML = this.formatter.tcr(tcr, formatterOutputFormat);
      const tcrFromHTML = this.formatter.tcr(tcrFrom, formatterOutputFormat);

      return `${stringPrefix} ${tcrHTML} from ${tcrFromHTML}`;
    } else {
      return "";
    }
  };

  private formatCardinality = (cell: CellComponent) => {
    const data = cell.getData();
    const cardinality = (data as any).info.cardinality;

    return `${cardinality ?? ""}`;
  };

  private formatABCDPerc = (
    cell: CellComponent,
    fieldKey: "abPercentage" | "cdPercentage"
  ) => {
    const data = cell.getData();
    const statistics = (data as any).statistic;
    const isPercentage = true;
    const timeSegment = this.timeframeToPlainText("today");

    return this.formatNumber(statistics[fieldKey][timeSegment], isPercentage);
  };

  private formatPercentageChanges = (
    cell: CellComponent,
    timeframe: 0 | 4 | 19,
    field: "abPercentage" | "cdPercentage"
  ) => {
    const data = cell.getData();
    const timeSegment = this.timeframeToPlainText(timeframe);

    // Adjust timeframe for changes
    const percentageFrom =
      data["statistic"]?.[field]?.[
        timeSegment === "today" ? "yesterday" : timeSegment
      ];

    // 0: no change, 1: positive, -1: negative
    const percentageChanges =
      percentageFrom > 0 ? 1 : percentageFrom < 0 ? -1 : 0;

    let arrow = "";
    switch (percentageChanges) {
      case 1: {
        arrow = "&uarr;";

        break;
      }
      case -1: {
        arrow = "&darr;";

        break;
      }
      case 0:
      default: {
        arrow = "";

        break;
      }
    }

    const isPercentage = true;
    const percentageValueHTML = this.formatNumber(percentageFrom, isPercentage);

    return "<span>" + arrow + " " + percentageValueHTML + "</span>";
  };

  private formatUpgradeDowngradeNumber = (
    cell: CellComponent,
    fieldKey: "upgrades" | "downgrades",
    timeframe: 0 | 4 | 19
  ) => {
    const data = cell.getData();
    const timeSegment = this.timeframeToPlainText(timeframe);
    const numberByFieldKey = data["statistic"][fieldKey][timeSegment]["number"];
    const numberColor = fieldKey === "upgrades" ? "#008005" : "#F01100";

    return numberByFieldKey !== 0
      ? `<span style="cursor: pointer; color: ${numberColor}">${numberByFieldKey}</span>`
      : "";
  };

  private formatUpgradesDowngradesPerc = (
    cell: CellComponent,
    fieldKey: "upgrades" | "downgrades",
    timeframe: 0 | 4 | 19
  ) => {
    const data = cell.getData();
    const timeSegment = this.timeframeToPlainText(timeframe);
    const numberByFieldKey =
      data["statistic"][fieldKey][timeSegment]["percentage"];
    const isPercentage = true;

    return this.formatNumber(numberByFieldKey, isPercentage);
  };

  private formatName = (
    cell: CellComponent | RowComponent,
    segment: PeerDetailSegments
  ) => {
    const data = cell.getData();
    let name = (data as any).name;

    if (name === "__ROOT__") {
      if (name === "__ROOT__") {
        // if getRow property is in cell component it means that cell is of type CellComponent otherwise is of type RowComponent
        const row = "getRow" in cell ? cell?.getRow() : cell;
        const rowHtml = row?.getElement();
        rowHtml!.style.fontSize = "1.2em";
        rowHtml!.style.fontWeight = "bold";
        rowHtml!.style.backgroundColor = "rgba(255, 192, 1, 0.2)";
        return `${this.turnSegmentInLabel(segment)}`;
      }
      return `All ${this.turnSegmentInLabel(segment)}`;
    }

    const taxonomies = this.taxonomies;
    const taxonomiesFieldsMap = this.taxonomiesFields;
    const taxonomiesMapX =
      taxonomies[
        taxonomiesFieldsMap[(data as any).type === "ETF" ? "ETF" : "security"][
          (data as any).type === "ETF" ? "etfgeo" : "country"
        ]
      ];
    const taxonomiesMapY =
      taxonomies[
        taxonomiesFieldsMap[(data as any).type === "ETF" ? "ETF" : "security"][
          (data as any).type === "ETF" ? "etfclass" : "icb"
        ]
      ];

    switch (segment) {
      case "1 Industry":
      case "3 Sector": {
        const nameTag = (data as any).what;
        const taxonomyName = taxonomiesMapY?.[nameTag]?.["name"];

        if (segment === "1 Industry" || segment === "3 Sector") {
          name = taxonomyName;
        } else if (segment === "4 Subsector") {
          name = formatTaxonPrefixingParent(
            getTaxonById(nameTag, [taxonomiesMapY], 0),
            [taxonomiesMapY],
            "4 Subsector"
          );
        }

        break;
      }

      case "Area":
      case "Country":
      case "Region":
        const nameTag = (data as any).where;
        const taxonomyName = taxonomiesMapX?.[nameTag]?.["name"];
        name =
          segment === "Region"
            ? formatTaxonPrefixingParent(
                getTaxonById(nameTag, [taxonomiesMapX], 0),
                [taxonomiesMapX],
                "Region"
              )
            : taxonomyName;

        break;
    }

    return name;
  };

  private alertsConverter(alerts: Alerts) {
    let value: any = null;
    if (alerts && alerts !== "Any") {
      switch (alerts) {
        case "negative_movers": {
          // OLD Syntax before the inauguration of the mv analytic
          //
          // lr >= 19 && px == 0 && rc < 0
          //
          // value = [
          //     {
          //         dimension: "lr",
          //         segments: [
          //             {
          //                 le: null,
          //                 ge: 19,
          //             },
          //         ],
          //     },

          //     {
          //         dimension: "px", // px
          //         segments: [
          //             {
          //                 le: 0,
          //                 ge: 0,
          //             },
          //         ],
          //     },
          //     {
          //         dimension: "rc",
          //         segments: [
          //             {
          //                 le: 0,
          //                 ge: null,
          //             },
          //         ],
          //     },
          // ];

          value = [
            {
              dimension: "mv",
              operator: "range",
              segments: [{ ge: null, le: -19 }],
            },
          ];

          break;
        }
        case "positive_movers": {
          // OLD Syntax before the inauguration of the mv analytic
          //
          // lr >= 19 && px == 0 && rc >  0
          //
          // value = [
          //     {
          //         dimension: "lr", // lr
          //         segments: [
          //             {
          //                 le: null,
          //                 ge: 19,
          //             },
          //         ],
          //     },

          //     {
          //         dimension: "px", // px
          //         segments: [
          //             {
          //                 le: 0,
          //                 ge: 0,
          //             },
          //         ],
          //     },
          //     {
          //         dimension: "rc", // rc
          //         segments: [
          //             {
          //                 le: null,
          //                 ge: 0,
          //             },
          //         ],
          //     },
          // ];

          value = [
            {
              dimension: "mv",
              operator: "range",
              segments: [{ le: null, ge: 19 }],
            },
          ];

          break;
        }
        default:
          const alertsDict = {
            upgrades_today: { direction: { from: 0, to: 4 }, timeframe: 0 },
            upgrades_last_5_days: {
              direction: { from: 0, to: 4 },
              timeframe: 4,
            },
            upgrades_last_20_days: {
              direction: { from: 0, to: 4 },
              timeframe: 19,
            },
            upgrades_last_60_days: {
              direction: { from: 0, to: 4 },
              timeframe: 59,
            },
            downgrades_today: { direction: { from: -4, to: 0 }, timeframe: 0 },
            downgrades_last_5_days: {
              direction: { from: -4, to: 0 },
              timeframe: 4,
            },
            downgrades_last_20_days: {
              direction: { from: -4, to: 0 },
              timeframe: 19,
            },
            downgrades_last_60_days: {
              direction: { from: -4, to: 0 },
              timeframe: 59,
            },
          };

          const alert = alertsDict[alerts];

          value = [
            {
              dimension: "lr",
              operator: "range",
              segments: [{ ">=": 0, "<=": alert.timeframe }],
            },
            {
              dimension: "direction",
              operator: "range",
              segments: [
                { ">=": alert.direction.from, "<=": alert.direction.to },
              ],
            },
          ];
      }
    }

    return value;
  }

  private async getClusterData(
    symbols,
    alertType: "upgrade_20" | "upgrade_60" | "downgrade_20" | "downgrade_60",
    analytic: "pq" | "pm"
  ) {
    const perfAnalytic = `${analytic}#avg#false`;
    const analytics = [perfAnalytic, "cardinality"];

    const filters = [
      { dimension: "symbol", operator: "equals", segments: symbols },
    ];

    if (alertType.split("_")[0] === "upgrade") {
      if (alertType === "upgrade_20") {
        filters.push(
          {
            dimension: "lr",
            operator: "range",
            segments: [{ ">=": 0, "<=": 19 }],
          },
          {
            dimension: "direction",
            operator: "range",
            segments: [{ ">=": 0, "<=": 4 }],
          }
        );
      } else {
        filters.push(
          {
            dimension: "lr",
            operator: "range",
            segments: [{ ">=": 0, "<=": 59 }],
          },
          {
            dimension: "direction",
            operator: "range",
            segments: [{ ">=": 0, "<=": 4 }],
          }
        );
      }
    } else {
      if (alertType === "downgrade_20") {
        filters.push(
          {
            dimension: "lr",
            operator: "range",
            segments: [{ ">=": 0, "<=": 19 }],
          },
          {
            dimension: "direction",
            operator: "range",
            segments: [{ ">=": -4, "<=": 0 }],
          }
        );
      } else {
        filters.push(
          {
            dimension: "lr",
            operator: "range",
            segments: [{ ">=": 0, "<=": 59 }],
          },
          {
            dimension: "direction",
            operator: "range",
            segments: [{ ">=": -4, "<=": 0 }],
          }
        );
      }
    }

    const universe = {
      constraints: [filters],
      page: { page: 1, rows: 20000 },
      sort: [{ dimension: "marketcap", rev: false }],
    };

    let configuration = this.apiCluster
      .createConfiguration()
      .analytics(analytics)
      .clusters([])
      .method("DRILL_DOWN")
      .universeFromConstraints(universe);

    try {
      const result = await configuration.fetchAnalytics();
      const stats = result.clustersStats.stats["ANY"];
      return { count: stats["cardinality"], avg: stats[perfAnalytic] };
    } catch (error) {
      console.log(error);

      return { count: 0, avg: 0 };
    }
  }

  async avgPerfUpDown(peerId) {
    const result = {
      upgrades: {
        oneMonth: 0,
        threeMonths: 0,
        avgPerfOneMonth: 0,
        avgPerfThreeMonths: 0,
      },
      downgrades: {
        oneMonth: 0,
        threeMonths: 0,
        avgPerfOneMonth: 0,
        avgPerfThreeMonths: 0,
      },
    };

    const { zDimension, what, where, type } = decodePeerId(peerId);

    const filters: any = [];
    filters.push({
      dimension: "type",
      operator: "equals",
      segments: [type === "ETF" ? "ETF" : "Stock"],
    });

    const peerType = type === "ETF" ? "ETF" : "security";
    const whereField = type === "ETF" ? "etfgeo" : "country";
    const whatField = type === "ETF" ? "etfclass" : "icb";

    const whereTaxonomy =
      this.taxonomies[this.taxonomiesFields[peerType][whereField]];
    const whatTaxonomy =
      this.taxonomies[this.taxonomiesFields[peerType][whatField]];

    if (type !== "ETF") {
      filters.push(
        {
          dimension: "sizeClassification",
          operator: "equals",
          segments: [zDimension],
        },
        {
          dimension: "sizeClassification",
          operator: "equals",
          segments: [zDimension],
        },
        { dimension: "stockclass", operator: "equals", segments: ["STOCK"] }
      );
    }

    const isRootNode = (taxon, node) => {
      let isRoot = false;

      if (taxon?.[node]?.parent != null) {
        isRoot = false;
      } else {
        isRoot = true;
      }

      return isRoot;
    };

    if (!isRootNode(whereTaxonomy, where)) {
      filters.push({
        dimension: whereField,
        operator: "equals",
        segments: [where],
      });
    }

    if (!isRootNode(whatTaxonomy, what)) {
      filters.push({
        dimension: whatField,
        operator: "equals",
        segments: [what],
      });
    }

    const payload = {
      constraints: [filters],
      sort: [{ dimension: "marketcap", rev: false }],
      page: { page: 1, rows: 20000 },
    };

    try {
      const response = await this.apiInstrument.screening(payload, true);

      if (response?.data) {
        const universeSymbols = response.data;

        let upgradesClusterPm20 = this.getClusterData(
          universeSymbols,
          "upgrade_20",
          "pm"
        );
        let upgradesClusterPq60 = this.getClusterData(
          universeSymbols,
          "upgrade_60",
          "pq"
        );
        let downgradesClusterPm20 = this.getClusterData(
          universeSymbols,
          "downgrade_20",
          "pm"
        );
        let downgradesClusterPq60 = this.getClusterData(
          universeSymbols,
          "downgrade_60",
          "pq"
        );

        const promises = [
          upgradesClusterPm20,
          upgradesClusterPq60,
          downgradesClusterPm20,
          downgradesClusterPq60,
        ];

        const [up20Result, up60result, dw20result, dw60result] =
          await Promise.all(promises);

        result.upgrades.oneMonth = up20Result.count;
        result.upgrades.threeMonths = up60result.count;
        result.upgrades.avgPerfOneMonth = up20Result.avg;
        result.upgrades.avgPerfThreeMonths = up60result.avg;

        result.downgrades.oneMonth = dw20result.count;
        result.downgrades.threeMonths = dw60result.count;
        result.downgrades.avgPerfOneMonth = dw20result.avg;
        result.downgrades.avgPerfThreeMonths = dw60result.avg;
      }

      return result;
    } catch (error) {
      console.log(error);

      return result;
    }
  }
}
