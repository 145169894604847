import { Card, CardContent, Checkbox, Skeleton, Stack } from "@mui/material";
import Highcharts from "highcharts/highstock";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Instruments } from "../../../../../api/compute/Instruments";
import { Utils } from "../../../../../api/compute/Utils";
import { Properties } from "../../../../../api/Properties";
import { useEnvironment } from "../../../../../hooks/useEnvironment";
import { useFormatter } from "../../../../../hooks/useFormatter";
import { Entity } from "../../../../../js/app/pages/strategies/builder/editors/Advanced/Result/tabs/Holdings/utils/Entity";
import { ChartSerie } from "../../../../../js/app/utils";
import { widgetsConfiguration } from "../../../../../js/app/widgets/widgetsConfiguration";
import { TDate } from "../../../../../trendrating/date/TDate";
import { Chart } from "../../../../Chart";
import SecurityChartModal from "../../../../SecurityChartModal/SecurityChartModal";
import { TableV2 } from "../../TableCoreV2";
import styles from "./InstrumentsBoxListV2.module.scss";

type InstrumentsBoxListsV2Props = {
  showCheckbox: boolean;
  tableInstance: TableV2;
  selectedRows: any;
  onClickCard: (instrument) => void;
};

type SecurityBoxProps = {
  security: any;
  selectionHandler: (event, security) => any;
  selectedRowsMap: any;
  selectable: boolean;
  onClickCard: (instrument) => void;
};

type ExpiredChartProps = { security: any };

const flag = (milliseconds, rate) => {
  var entity = new Entity();
  var rateInfo = entity.rateScale[rate];
  var flag: any = [];
  switch (rate) {
    case 2:
    case 1:
    case -1:
    case -2:
      flag.push({
        x: milliseconds,
        title: rateInfo.label,
        text: rateInfo.label,
        fillColor: rateInfo.color,
      });
      break;
    // no default
  }
  return flag;
};

const selectedRowsToMap = (selectedRows) => {
  return selectedRows.reduce(
    (prev, current) => ({ ...prev, [current.symbol]: true }),
    {}
  );
};

const color = {
  default: "#2F7ED8",
  // blue

  A: "#008000",
  // green
  B: "#8bbc00",
  // light-green
  C: "#f48400",
  // orange
  D: "#f00000",
  // red
  equity: "#0da760",
  // green              blue #3da0ff
  benchmark: "#4572a7",
  // blue-dark
  security: "#f48400",
  // orange
  yearUp: "#FAF2CB", // light-yellow
};

(Highcharts as any).FastChart = function (node) {
  const defaultOptions: any = {
    xAxis: {
      //tickPixelInterval : 1,
      lineWidth: 1,
      tickWidth: 1,
      showFirstLabel: true,
      startOnTick: false,
      endOnTick: false,
      labels: {
        /*formatter : function() {
    return Highcharts.dateFormat("%b '%y", this.value);
  },*/
        enabled: true,
        style: {
          fontSize: "10px",
        },
      },
    },
    yAxis: [
      {
        id: "yCurve",
        lineWidth: 1,
        tickWidth: 1,
        //lineColor : 'transparent',
        tickPixelInterval: 40,
        gridLineColor: "transparent",
        showLastLabel: true,
        opposite: false,
        startOnTick: false,
        endOnTick: false,
        labels: {
          enabled: true,
          //x : -3,
          style: {
            fontSize: "10px",
          },
        },
      },
    ],

    chart: {
      height: 240,
      width: 380,
      renderTo: node,
      animation: false,

      //marginBottom : 0, // The margin between the bottom outer edge of the chart and the plot area. Use this to set a fixed pixel value for the margin as opposed to the default dynamic margin.
      spacingBottom: 5,
      spacingRight: 20,
      spacingLeft: 5,
      spacingTop: 20,
    },
    rangeSelector: {
      enabled: false,
    },
    navigator: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    exporting: {
      enabled: false,
    },

    tooltip: {
      enabled: false,
    },
    scrollbar: {
      enabled: false,
    },
    plotOptions: {
      series: {
        animation: false,
        marker: {
          enabled: true,
        },

        enableMouseTracking: false,
      },
    },

    series: [
      {
        name: "STOCK1",
        id: "STOCK1-ID",
        data: [],
        // predefined JavaScript array
        marker: {
          enabled: false,
        },
        lineColor: color["default"],
        lineWidth: 1,
      },
      {
        type: "flags",
        data: [],
        onSeries: "STOCK1-ID",
        shape: "circlepin",
        width: 12,
        y: -22,
        //				fillColor : Highcharts.getOptions().colors[2],
        style: {
          // text style
          color: "white",
          fontSize: "10px",
          "vertical-align": "middle",
        },
        color: color["default"],
      },
    ],
  };

  return new Highcharts.StockChart(defaultOptions);
};

export const InstrumentsBoxListsV2 = memo(
  ({
    showCheckbox,
    tableInstance,
    selectedRows,
    onClickCard,
  }: InstrumentsBoxListsV2Props) => {
    const fields = useMemo(
      () => ["name", "ticker", "rc", "dr", "type", "pr", "prr", "chart"],
      []
    );
    const environment = useEnvironment();
    const appSetup = useMemo(() => environment.get("setup"), [environment]);
    const instrumentsAPI = useMemo(() => new Instruments(appSetup), [appSetup]);
    const [selectedRowsMap, setSelectedRowsMap] = useState({});
    const loadedSymbols = useRef({});

    const rows = useMemo(() => {
      return tableInstance.getRows().map((row) => row.getData());
    }, [tableInstance]);

    const initItems = useMemo(() => {
      return rows?.map((r) => r.symbol) ?? [];
    }, [rows]);

    const [securities, setSecurities] = useState<any>(initItems);

    const onChangeSelection = useCallback(
      (event, security) => {
        const rows = tableInstance.getRows();
        const checked = event.target.checked;

        let row: any = null;

        for (let i = 0, N = rows.length; i < N; i++) {
          row = rows[i];

          if (row.getData()?.symbol === security.symbol) {
            break;
          }
        }

        if (row) {
          if (checked) {
            row.select();
          } else {
            row.deselect();
          }
        }
      },
      [tableInstance]
    );

    const getData = useCallback(
      async (symbols: string[]) => {
        const properties = fields.map((p) => ({ date: null, property: p }));

        try {
          const response = await instrumentsAPI.fetch({
            symbols: symbols,
            properties,
            type: "security",
          });

          if (response?.data?.[0]) {
            response.data[0]?.chart?.data?.sort((arrA, arrB) => {
              if (arrA[0] > arrB[0]) {
                return 1;
              } else if (arrA[0] < arrB[0]) {
                return -1;
              }

              return 0;
            });

            response.data[0]?.chart?.markers?.sort((a, b) => {
              if (a.x > b.x[1]) {
                return 1;
              } else if (a.x < b.x) {
                return -1;
              }

              return 0;
            });
          }

          const dataMap = response?.data?.reduce((prev, current) => {
            prev[current.symbol] = current;

            return prev;
          }, {});

          setItemsDataMap(dataMap);
        } catch (error) {
          console.log(error);
        }
      },
      [fields, instrumentsAPI]
    );

    useEffect(() => {
      setSelectedRowsMap(selectedRowsToMap(selectedRows));
    }, [selectedRows]);

    // Retrive data on landing
    useEffect(() => {
      if (securities.length) {
        getData(securities);
      }
    }, [getData, securities]);

    // Lazy load ------------------------------------------------
    const highchart = useRef<Highcharts.StockChart | null>(null);
    const chartHiddenNode = useRef<HTMLDivElement | null>(null);

    const scrollableArea = useRef<HTMLUListElement>(null);
    const cardRefs = useRef<{ [index: number]: HTMLLIElement }>({});

    const rowsMap = useMemo(() => {
      return rows.reduce((prev, current) => {
        prev[current.symbol] = null;

        return prev;
      }, {});
    }, [rows]);

    const [itemsDataMap, setItemsDataMap] = useState<{ [key: string]: any }>(
      rowsMap
    );

    const BUFFER = 4;

    const handleIntersection = useCallback(
      async (entries) => {
        let entry: any = null;

        let symbols: any = [];
        let indexMap: any = {};
        let _symbol: any = "";

        for (let i = 0; i < entries.length; i++) {
          entry = entries[i];

          if (entry.isIntersecting) {
            let sId = parseInt(entry.target?.dataset?.["scrollId"]);

            const id = sId;
            const buffer = id + BUFFER;

            for (sId; sId < buffer; sId++) {
              _symbol = cardRefs.current?.[sId]?.dataset?.symbol;

              if (_symbol) {
                symbols.push(_symbol);
                const chartNode =
                  cardRefs.current[sId].querySelector("#chartNode");

                indexMap[_symbol] = {
                  node: chartNode,
                  index: i,
                };
              }
            }
          }
        }

        // Avoid to ask again data on scroll back to top
        symbols = symbols.filter((s) => loadedSymbols.current[s] == null);

        if (!symbols.length) {
          return;
        }

        const data = itemsDataMap;
        let node: any = null;
        let d: any = null;
        for (const symbol in indexMap) {
          d = data[symbol];
          node = indexMap[symbol]["node"];

          if (node && d?.chart) {
            const chart = highchart.current;

            if (chart) {
              const graphList = d.chart;

              chart.series[0].update({ data: graphList.data } as any, false);
              if (graphList.markers) {
                for (let index = 0; index < graphList.markers.length; ++index) {
                  var markerH = graphList.markers[index];
                  markerH.text = 'Shape: "circlepin"';
                  markerH.fillColor = color[markerH.title];
                }
                chart.series[1].update(
                  { data: graphList.markers } as any,
                  false
                );
              } else {
                chart.series[1].update({ data: [] } as any, false);
              }
              // Get SVG method operate a redraw
              let svg = chart.getSVG();
              node.style.backgroundImage = `url("data:image/svg+xml,${encodeURIComponent(
                svg
              )}`;
            }

            loadedSymbols.current[symbol] = true;
          }
        }
      },
      [itemsDataMap]
    );

    // At first render we use the table rows but if the rows change we need to recalculate charts
    // (example: change page of the table or change rows number)
    const registerDataLoaded = useCallback(() => {
      tableInstance.registerEvents({
        dataLoaded: async (data) => {
          const symbols = data.map((item) => item.symbol);

          setSecurities(symbols);
          loadedSymbols.current = {};
        },
      });
    }, [tableInstance]);

    useEffect(() => {
      registerDataLoaded();
    }, [registerDataLoaded]);

    // Configuration Intersection Observer
    useEffect(() => {
      const observer = new IntersectionObserver(handleIntersection, {
        root: scrollableArea.current,
        threshold: 0.1,
      });

      // Disconnect old elements
      Object.values(cardRefs.current).forEach((card) =>
        observer.unobserve(card)
      );

      // Observe every card
      for (let i = 0; i < Object.values(cardRefs.current).length; i += BUFFER) {
        observer.observe(cardRefs.current[i]);
      }

      return () => {
        observer.disconnect();
      };
    }, [handleIntersection, securities]);

    // At component mount create a hidden div used to render a chart
    useEffect(() => {
      const div = document.createElement("div");
      div.style.display = "none";
      div.style.height = "240px";
      div.style.width = "380px";

      chartHiddenNode.current = div;

      document.body.appendChild(div);

      highchart.current = (Highcharts as any).FastChart(div);

      // On component unmount remove the div
      return () => {
        document.body.removeChild(div);
      };
    }, []);
    // ----------------------------------------------------------------

    return (
      <ul className={styles.mainList} ref={scrollableArea}>
        {securities?.map((symbol, index) => {
          return (
            <li
              id={`listItemCard__${symbol}`}
              ref={(el) => {
                if (el) {
                  cardRefs.current[index] = el;
                }
              }}
              key={symbol}
              className={styles.listItem}
              data-symbol={symbol}
              data-scroll-id={index}
            >
              <SecurityBox
                onClickCard={onClickCard}
                selectable={showCheckbox}
                selectedRowsMap={selectedRowsMap}
                security={itemsDataMap[symbol]}
                selectionHandler={onChangeSelection}
              />
            </li>
          );
        })}
      </ul>
    );
  }
);

const SecurityBox = ({
  security,
  selectionHandler,
  selectedRowsMap,
  selectable,
  onClickCard,
}: SecurityBoxProps) => {
  const [showModal, setShowModal] = useState(false);
  const [securityForModal, setSecurityForModal] = useState();
  const entity = useMemo(() => new Entity(), []);
  const formatter = useFormatter();
  const environment = useEnvironment();
  const appSetup = useMemo(() => environment.get("setup"), [environment]);
  const label = useMemo(
    () => new Properties({ properties: appSetup["properties"] }),
    [appSetup]
  );

  const instrumentsAPI = useMemo(() => new Instruments(appSetup), [appSetup]);
  const name = useMemo(() => (security ? security?.name : ""), [security]);
  const ticker = useMemo(() => (security ? security?.ticker : ""), [security]);

  const rate = useMemo(
    () => (security ? entity.get(security?.rc, "RATE") : ""),
    [entity, security]
  );

  const isLoading = useMemo(() => {
    return security == null;
  }, [security]);

  const datePrec = useMemo(() => {
    if (!security) {
      return "";
    }

    return formatter.html(
      "dr",
      "rated_on_iso",
      security?.["dr"],
      null,
      security?.["type"]
    );
  }, [formatter, security]);

  const pr = useMemo(
    () =>
      security
        ? formatter.html(
            "pr",
            "performance_since_rated",
            security?.["pr"],
            null,
            security?.["type"]
          )
        : "",
    [formatter, security]
  );
  const labelDatePrec = useMemo(
    () => label.get("dr", 0, security?.["type"]),
    [label, security]
  );

  const info = useMemo(() => {
    if (!security) {
      return "";
    }

    let info =
      labelDatePrec +
      ": " +
      datePrec +
      " | Since " +
      rate?.["label"] +
      ": " +
      pr;

    if (
      security.prr &&
      ((security?.rrr > 0 && security?.rc > 0) ||
        (security?.rrr < 0 && security?.rc < 0))
    ) {
      let rate = entity.get(security?.rrr, "RATE");
      const prr = formatter.html(
        "prr",
        "performance_previous_rate",
        security?.["prr"],
        null,
        security?.["type"]
      );
      info = info + " | Since " + rate?.["label"] + ": " + prr;
    }

    return info;
  }, [datePrec, entity, formatter, labelDatePrec, pr, rate, security]);

  const getSecurityProperiesByType = useCallback((response) => {
    const securityType =
      response?.["data"]?.[0]?.["type"]?.toLowerCase() ?? null;
    let properties;

    const config = widgetsConfiguration["widgets/security/Tooltip"];

    if (securityType) {
      switch (securityType) {
        case "etf":
        case "stock": {
          properties = config?.["properties_" + securityType];
          break;
        }
        default: {
          properties = config?.["properties"];
        }
      }

      return properties;
    }

    return null;
  }, []);

  const getInstrumentData = useCallback(async () => {
    const symbol = security?.symbol;

    if (!symbol) {
      return;
    }

    const properties: any = {
      properties: [
        {
          date: null,
          property: "type",
        },
      ],
      symbols: [symbol],
      type: "security",
    };

    const response = await instrumentsAPI.fetch(properties);
    const propertiesByType = getSecurityProperiesByType(response);

    return await instrumentsAPI.fetch({
      properties: propertiesByType,
      symbols: [symbol],
      type: "security",
    });
  }, [getSecurityProperiesByType, instrumentsAPI, security?.symbol]);

  const openModal = useCallback(async () => {
    const instrument = await getInstrumentData();

    setSecurityForModal(instrument?.data?.[0]);
    if (instrument) {
      if (onClickCard) {
        onClickCard(instrument?.data?.[0]);
      }
      setShowModal(true);
    }
  }, [getInstrumentData, onClickCard]);

  return (
    // HasCheckbox props has to be implemented and is useful for operations like add to new basket or portfolio
    <Card
      onClick={openModal}
      sx={{
        width: "100%",
        height: "100%",
        minHeight: isLoading ? "25em" : "none",
      }}
    >
      <CardContent sx={{ height: "100%", width: "100%" }}>
        <div
          style={{
            overflow: "visible",
            visibility: isLoading ? "hidden" : "visible",
          }}
        >
          {selectable && (
            <Checkbox
              checked={selectedRowsMap?.[security?.symbol] ?? false}
              onClick={(event) => event.stopPropagation()}
              onChange={(event) => {
                event.stopPropagation();
                selectionHandler(event, security);
              }}
            />
          )}
          {!isLoading && (
            <SecurityChartModal
              security={securityForModal}
              showModal={showModal}
              environment={environment}
              onClose={() => {
                setShowModal(false);
              }}
            />
          )}
          <div className="viewer-tile__tile-title-container viewer-tile__tile-title-container--no-margin">
            <p className={"viewer-tile__tile-rate"}>
              <strong className={rate?.["class"]}>{rate?.["label"]}</strong>
            </p>
            <div className="viewer-tile__tile-title">
              <h1>{name + " (" + ticker + ")"}</h1>

              <p dangerouslySetInnerHTML={{ __html: info }}></p>
            </div>
          </div>
        </div>
        <div className="viewer-tile__chart-container">
          {security?.type === "ExpiredStock" ? (
            <ExpiredChart security={security} />
          ) : (
            <div
              style={{
                backgroundPosition: "50% 50%",
                backgroundRepeat: "no-repeat",
                backgroundSize: "contain",
                height: 0,
                padding: "0 0 60%",
              }}
              className="viewer-tile__chart"
              id={"chartNode"}
            ></div>
          )}
        </div>
      </CardContent>
    </Card>
  );
};

const ExpiredChart = ({ security }: ExpiredChartProps) => {
  const [chartParams, setChartParams] = useState<any>();
  const [chartConfig, setChartConfig] = useState<any>();

  const environment = useEnvironment();
  const appSetup = useMemo(() => environment.get("setup"), [environment]);
  const instrumentsAPI = useMemo(() => new Instruments(appSetup), [appSetup]);
  const utilityAPI = useMemo(() => new Utils(appSetup), [appSetup]);
  const chartSerie = useMemo(() => new ChartSerie(), []);

  const getChartParams = useCallback(async () => {
    try {
      const historyResponse = await instrumentsAPI.historyOf(security);
      const todayResponse = await utilityAPI.today();

      const today = todayResponse["today"];
      const history = historyResponse;

      const cutFromDay = today - 252; // Cut from last year

      const cuttedHistory: any = [];
      for (let i = 0; i < history.length; i++) {
        if (history[i].d >= cutFromDay) {
          cuttedHistory.push(history[i]);
        }
      }

      const serieList = chartSerie.trendrating2HighchartSerie(cuttedHistory);

      var params = {
        security,
        history: serieList,
        max: TDate.daysToMilliseconds(today),
        height: 240,
        isLogarithmic: false,
      };

      setChartParams(params);
    } catch (error) {
      console.error(error);
    }
  }, [chartSerie, instrumentsAPI, security, utilityAPI]);

  const buildChart = useCallback((params) => {
    const history = params.history;

    if (history) {
      // calculate signal flags
      const flagdata: any = [];
      let date: any = null;
      for (let i = history.length - 1; i >= 0; i--) {
        if (i >= 1 && history[i]["r"] !== history[i - 1]["r"]) {
          date = history[i]["x"];
          let flagdataPrec = flag(date, history[i]["r"]);
          flagdata.push(flagdataPrec[0]);
        }
      }
      flagdata.reverse();
      // re-arrange axis to show last signal correctly
      let maxT = params.max || history[history.length - 1][0];
      if (flagdata[0] !== undefined) {
        var maxF = flagdata[0].x;

        var last5Days = 5 * 24 * 3600 * 1000;
        if (maxF > maxT - last5Days) {
          maxT = maxT + last5Days;
        }
      }

      let series: any = [];
      let sPrice = {
        color: "#2F7ED8",
        type: "line",
        id: "history",
        name: "Expired Stock",
        data: history,
        yAxis: 0,
        index: 0,
        lineWidth: 1,
        enableMouseTracking: false,
        tooltip: {
          enabled: false,
        },
        turboThreshold: 0,
      };
      series.push(sPrice);

      var flags = {
        type: "flags",
        name: "Flags on series",
        data: flagdata,
        yAxis: 0,
        index: 1,
        color: "#d3d3d3",
        onSeries: "history",
        shape: "circlepin",
        width: 14,
        style: { color: "white" },
        states: { hover: { fillColor: "#395C84", text: "signal" } },
        turboThreshold: 0,
      };
      series.push(flags);

      const config = {
        chart: {
          animation: false,
          borderWidth: 0,
          height: params.height ? params.height : null,
          width: params.width ? params.width : null,
          marginTop: 0,
          renderTo: params.node,
          spacingTop: 0,
        },
        credits: { enabled: false },
        exporting: { enabled: false },
        interactiveMode: {
          isEnabled: false,
          plugins: [],
        },
        scrollbar: {
          enabled: false,
        },
        rangeSelector: {
          enabled: false,
        },
        navigator: { enabled: false },
        plotOptions: {
          line: {
            animation: false,
            shadow: false,
          },
          series: {
            connectNulls: true,
          },
        },
        subtitle: {
          text: "Expired Stock",
        },
        tooltip: {
          shared: false,
        },
        xAxis: {
          type: "datetime",
          max: maxT,
          ordinal: false,
          plotLines: [
            {
              color: "#999",
              label: {
                style: {
                  color: "#666",
                  fontFamily: "'Open Sans', sans-serif",
                  fontSize: "9px",
                },
                text: "expired",
                verticalAlign: "middle",
                x: 4,
              },
              value: history[history.length - 1][0],
              width: 1,
            },
          ],
        },
        yAxis: {
          lineWidth: 1,
          gridLineWidth: 0,
          type: params.isLogarithmic ? "logarithmic" : "linear",
          opposite: false,
        },
        series: series,
      };

      setChartConfig(config);
    }
  }, []);

  useEffect(() => {
    getChartParams();
  }, [getChartParams]);

  useEffect(() => {
    if (chartParams) {
      buildChart(chartParams);
    }
  }, [buildChart, chartParams]);

  return chartConfig ? <Chart options={chartConfig} /> : <SkeletonLoader />;
};

const SkeletonLoader = () => {
  return (
    <Stack spacing={1}>
      <Skeleton
        variant="text"
        sx={{
          fontSize: "20px",
          marginBottom: "20px",
        }}
      />

      {/* For other variants, adjust the size with `width` and `height` */}
      <Skeleton variant="rectangular" width={"100%"} height={30} />
      <Skeleton variant="rectangular" width={"100%"} height={30} />
      <Skeleton variant="rectangular" width={"100%"} height={30} />
      <Skeleton variant="rectangular" width={"100%"} height={30} />
      <Skeleton variant="rectangular" width={"100%"} height={30} />
      <Skeleton variant="rectangular" width={"100%"} height={30} />
    </Stack>
  );
};
