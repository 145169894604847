import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { ColumnDefinition } from "tabulator-tables";
import {
  TableEventsV2,
  TableV2,
} from "../../../../../../../components/table/v2/TableCoreV2";
import { TrendratingTableV2 } from "../../../../../../../components/table/v2/TableV2";
import { useFormatter } from "../../../../../../../hooks/useFormatter";

type SummaryTableProps = {
  data: any;
  portfolio1Name: string;
  portfolio2Name: string;
  rowClickHandler?: (info) => void;
  hasPrice?: boolean;
};

const columnFormatter = (cell, formatter, property) => {
  const object = cell.getData();
  return formatter.table(property, "table", object);
};

const weightFormatter = (cell, field, key) => {
  const data = cell.getData();

  if (key != null) {
    if (data[key] === "BASKET") {
      if (key === "firstListType" && data["isInFirstList"] === true) {
        return '<span style="height: 10px; width: 10px; border-radius: 50%; background-color: #333; display: inline-block;"></span>';
      } else if (key === "secondListType" && data["isInSecondList"] === true) {
        return '<span style="height: 10px; width: 10px; border-radius: 50%; background-color: #333; display: inline-block;"></span>';
      } else {
        return "-";
      }
    } else {
      return data[field] != null && data[field] !== false
        ? `${(data[field] * 100).toFixed(2)}%`
        : "-";
    }
  }

  return data[field] != null && data[field] !== false
    ? `${(data[field] * 100).toFixed(2)}%`
    : "-";
};

const weightDiffFormatter = (cell) => {
  const element = cell.getElement();
  const data = cell.getData();

  let color = "";

  if (data.weight1 != null && data.weight2 == null) {
    color = "rgba(102, 255, 102, 0.4)";
  }

  if (data.weight1 == null && data.weight2 != null) {
    color = "rgba(255, 204, 204, 0.4)";
  }

  if (data.weight1 != null && data.weight2 != null) {
    let borderColor = "";

    if (data.weight1 > data.weight2) {
      borderColor = "green";
    } else if (data.weight1 < data.weight2) {
      borderColor = "red";
    } else {
      borderColor = "#ddd";
    }

    element.style.border = `1px solid ${borderColor}`;
  }

  element.style.background = color;

  return weightFormatter(cell, "weightDiff", null);
};

const SummaryTable = memo(function SummaryTable({
  data,
  portfolio1Name,
  portfolio2Name,
  hasPrice = true,
  rowClickHandler,
}: SummaryTableProps) {
  const formatter = useFormatter();

  const weightColumnSorter = useCallback(
    (a, b, aRow, bRow, column, dir, sorterParams) => {
      if (a == null && b == null) {
        const field = column.getField();
        const key = field === "weight1" ? "isInFirstList" : "isInSecondList";
        const firstRowData = aRow.getData();

        return firstRowData[key] === true ? -1 : 1;
      }

      if (a === null) {
        return -1;
      } else if (b === null) {
        return 1;
      }

      if (a > b) {
        return -1;
      } else if (a < b) {
        return 1;
      }

      return 0;
    },
    []
  );

  const columns: ColumnDefinition[] = useMemo(
    () =>
      hasPrice
        ? [
            {
              title: "Ticker",
              field: "ticker",
              widthGrow: 1,
              sorter: "string",
            },
            {
              title: "Name",
              field: "name",
              formatter: (cell) => columnFormatter(cell, formatter, "name"),
              widthGrow: 3,
              sorter: "string",
            },
            {
              title: "Price",
              field: "vc",
              formatter: (cell) => columnFormatter(cell, formatter, "vc"),
              widthGrow: 2,
              sorter: "number",
            },
            {
              title: "Rating",
              field: "rc",
              formatter: (cell) => columnFormatter(cell, formatter, "rc"),
              widthGrow: 1,
              sorter: "number",
            },
            {
              title: `${portfolio1Name}`,
              field: "weight1",
              sorter: weightColumnSorter,
              formatter: (cell) =>
                weightFormatter(cell, "weight1", "firstListType"),
              widthGrow: 1,
            },
            {
              title: `${portfolio2Name}`,
              field: "weight2",
              sorter: weightColumnSorter,
              formatter: (cell) =>
                weightFormatter(cell, "weight2", "secondListType"),
              widthGrow: 1,
            },
            {
              title: "Difference",
              field: "weightDiff",
              formatter: (cell) => weightDiffFormatter(cell),
              widthGrow: 2,
              sorter: "number",
            },
          ]
        : [
            {
              title: "Ticker",
              field: "ticker",
              widthGrow: 1,
              sorter: "string",
            },
            {
              title: "Name",
              field: "name",
              formatter: (cell) => columnFormatter(cell, formatter, "name"),
              widthGrow: 3,
              sorter: "string",
            },
            {
              title: "Rating",
              field: "rc",
              formatter: (cell) => columnFormatter(cell, formatter, "rc"),
              widthGrow: 1,
              sorter: "number",
            },
            {
              title: `${portfolio1Name}`,
              field: "weight1",
              sorter: weightColumnSorter,
              formatter: (cell) =>
                weightFormatter(cell, "weight1", "firstListType"),
              widthGrow: 1,
            },
            {
              title: `${portfolio2Name}`,
              field: "weight2",
              sorter: weightColumnSorter,
              formatter: (cell) =>
                weightFormatter(cell, "weight2", "secondListType"),
              widthGrow: 1,
            },
            {
              title: "Difference",
              field: "weightDiff",
              formatter: (cell) => weightDiffFormatter(cell),
              widthGrow: 2,
              sorter: "number",
            },
          ],
    [formatter, hasPrice, portfolio1Name, portfolio2Name, weightColumnSorter]
  );

  const onRowClick = useCallback(
    (e, row) => {
      const data = row.getData();

      if (data) {
        const securityTicker = data.ticker;
        const securityName = data.name;
        const symbol = data.symbol;
        const weightP1 = data.weight1 ? (data.weight1 * 100).toFixed(2) : "0";
        const weightP2 = data.weight2 ? (data.weight2 * 100).toFixed(2) : "0";

        if (rowClickHandler) {
          rowClickHandler({
            securityName,
            securityTicker,
            symbol,
            weightP1,
            weightP2,
          });
        }
      }
    },
    [rowClickHandler]
  );

  const tableOptions = useMemo(() => {
    return {
      tableOption: {
        ajaxSorting: false,
      },
    };
  }, []);

  const dataLoaded = useCallback(
    (data) => {
      const d = data?.[0];
      if (d) {
        const securityTicker = d.ticker;
        const securityName = d.name;
        const weightP1 = d.weight1 ? (d.weight1 * 100).toFixed(2) : "0";
        const weightP2 = d.weight2 ? (d.weight2 * 100).toFixed(2) : "0";

        if (rowClickHandler) {
          rowClickHandler({
            securityName,
            securityTicker,
            symbol: d.symbol,
            weightP1,
            weightP2,
          });
        }
      }
    },
    [rowClickHandler]
  );

  const tableRef = useRef<{ getInstance: () => TableV2 }>();

  const [tableReady, setTableReady] = useState(false);

  useEffect(() => {
    if (tableReady) {
      const table = tableRef.current?.getInstance();
      table?.insertColumns(columns);
      table?.insertData(data);
    }
  }, [columns, data, tableReady]);

  const tableEvents: TableEventsV2 = useMemo(() => {
    return {
      rowClick: onRowClick,
      onTableBuilt: () => setTableReady(true),
      onTableDestroyed: () => setTableReady(false),
      dataLoaded,
    };
  }, [dataLoaded, onRowClick]);

  return data && data.length ? (
    <TrendratingTableV2
      ref={tableRef}
      tableOptions={tableOptions}
      tableEvents={tableEvents}
      rowTooltipFormatter
    />
  ) : (
    <p>No security was found with the selected filters.</p>
  );
});

export { SummaryTable };
