import { Box, Skeleton, Stack, Typography } from "@mui/material";
import {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { PortfolioHomeStorage } from "../../../storage/PortfolioHomeStorage";
import { TableAlertsComponent } from "./createEdit/TableAlertsComponent";
import { ListEventsTitle } from "./ListEventsTitle";
import { ListTable } from "./ListTable";
import { PortfolioHomeContext } from "./PortfolioHome";
import { SortBy } from "./SortBy";

type BasketProps = {
  storage: PortfolioHomeStorage;
  updateWorkflow: (wf) => void;
  setSelectedList: (list) => void;
  selectedList: any;
  sort:
    | "name"
    | "name_rev"
    | "TCR"
    | "TCR_rev"
    | "CD"
    | "CD_rev"
    | "AB"
    | "AB_rev"
    | "upgrades"
    | "downgrades"
    | "movers_up"
    | "movers_down";
  setSort: (sorter) => void;
  setTimeframe: (timeframe) => void;
};

const LIST_TYPE = "BASKET";

export const Basket = memo(
  ({
    storage,
    updateWorkflow,
    setSelectedList,
    selectedList,
    sort,
    setSort,
    setTimeframe,
  }: BasketProps) => {
    const { t } = useTranslation();
    const context = useContext(PortfolioHomeContext);
    const timeframe = useMemo(
      () => context?.timeframe ?? "daily",
      [context?.timeframe]
    );

    const [alerts, setAlerts] = useState<any>(undefined);
    const [lists, setLists] = useState<any>(undefined);
    const [alertsForLeftTable, setAlertsForLeftTable] =
      useState<any>(undefined);
    const [alertsOfSingleList, setAlertOfSingleList] = useState<any>(undefined);
    const [isLoadingRightTable, setIsLoadingRightTable] = useState(true);
    const [isLoadingLeftTable, setIsLoadingLeftTable] = useState(true);

    const deselectItem = useCallback(() => {
      setSelectedList(undefined);
      updateWorkflow("s0");
    }, [setSelectedList, updateWorkflow]);

    const dataGet = useCallback(async () => {
      updateWorkflow("s2");
      const lists = await storage.getCollections("BASKET", timeframe);

      setIsLoadingLeftTable(true);
      setIsLoadingRightTable(true);
      const ids = lists.map((list) => list.id);
      const alertsResponse = await storage.getAlerts(ids, timeframe);
      const resumeAlertsTable = lists;

      setLists(lists);
      setAlerts(alertsResponse);
      setAlertsForLeftTable(resumeAlertsTable);
      setIsLoadingLeftTable(false);
      setIsLoadingRightTable(false);
      updateWorkflow("s0");
    }, [storage, timeframe, updateWorkflow]);

    useEffect(() => {
      dataGet();
    }, [dataGet]);

    const onListSelection = useCallback(
      async (list) => {
        if ("alerts" in list) {
          setAlertOfSingleList(list.alerts);
          return;
        } else {
          const alertsOfList: any = await storage.getAlerts(
            [list.id],
            timeframe
          );

          setAlertOfSingleList(alertsOfList);
        }
      },
      [storage, timeframe]
    );

    const selectTableRow = useCallback(
      (list) => {
        updateWorkflow("s1");
        setSelectedList(list);
        onListSelection(list);
      },
      [onListSelection, setSelectedList, updateWorkflow]
    );

    useEffect(() => {
      setSelectedList(undefined);
    }, [setSelectedList, timeframe]);

    useEffect(() => {
      storage.invalidateCollections();
    }, [storage, timeframe]);

    const listTable = useMemo(() => {
      return (
        <ListTable
          setSort={setSort}
          sort={sort}
          timeframe={timeframe}
          listType={LIST_TYPE}
          data={alertsForLeftTable}
          rowClickListener={selectTableRow}
        />
      );
    }, [alertsForLeftTable, selectTableRow, setSort, sort, timeframe]);
    return (
      <Box
        className="tPageAnalysisListsBasket"
        p={1}
        display={"flex"}
        flex={1}
        minHeight={0}
      >
        {lists && Object.keys(lists).length === 0 ? (
          <div
            className="tPageAnalysisListsBasket-feedback"
            data-dojo-attach-point="nodeFeedback"
          >
            <span
              dangerouslySetInnerHTML={{ __html: t("c_feedback_no_baskets") }}
            />
          </div>
        ) : (
          <div
            className="tLayout"
            data-dojo-attach-point="nodeViewer"
            style={{
              display: "flex",
              minHeight: 0,
              height: "100%",
              width: "100%",
              gap: "8px",
            }}
          >
            <Box
              minWidth={0}
              height={"100%"}
              flex={1}
              bgcolor={"white"}
              padding={1}
              boxShadow={3}
              borderRadius={"6px"}
              display={"flex"}
              flexDirection={"column"}
              minHeight={0}
            >
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
                height={"50px"}
              >
                <h1>
                  <Typography sx={{ fontSize: "16px" }}>
                    <strong>{t("c_list_basket_monitoring")}</strong>
                  </Typography>
                </h1>
                <SortBy sort={sort} setSort={setSort} />
              </Box>
              {/* <div className="tPageAnalysisListsBasket-scrollable">
                  <div className="tPageAnalysisListsBasket-viewerContainer">
                    <div
                      className="tPageAnalysisListsBasket-viewer"
                      data-dojo-attach-point="nodeContainerViewerTable"
                    > */}
              {/* <div data-dojo-attach-point="nodeViewerTable"> */}
              {isLoadingLeftTable ? (
                <Stack spacing={1}>
                  <Skeleton
                    variant="text"
                    sx={{
                      fontSize: "20px",
                      marginBottom: "20px",
                    }}
                  />

                  {/* For other variants, adjust the size with `width` and `height` */}
                  <Skeleton variant="rectangular" width={"100%"} height={30} />
                  <Skeleton variant="rectangular" width={"100%"} height={30} />
                  <Skeleton variant="rectangular" width={"100%"} height={30} />
                  <Skeleton variant="rectangular" width={"100%"} height={30} />
                  <Skeleton variant="rectangular" width={"100%"} height={30} />
                  <Skeleton variant="rectangular" width={"100%"} height={30} />
                  <Skeleton variant="rectangular" width={"100%"} height={30} />
                </Stack>
              ) : (
                <Box sx={{ display: "flex", height: "100%", minHeight: 0 }}>
                  {listTable}
                </Box>
              )}
            </Box>

            <Box
              minWidth={0}
              bgcolor={"white"}
              padding={1}
              boxShadow={3}
              flex={1}
              borderRadius={"6px"}
              height={"100%"}
              display={"flex"}
              flexDirection={"column"}
              minHeight={0}
            >
              <ListEventsTitle
                selectedItem={selectedList}
                timeframe={timeframe}
                setTimeframe={setTimeframe}
                deselectItem={deselectItem}
              />
              {isLoadingRightTable ? (
                <Stack spacing={1}>
                  <Skeleton
                    variant="text"
                    sx={{
                      fontSize: "20px",
                      marginBottom: "20px",
                    }}
                  />

                  {/* For other variants, adjust the size with `width` and `height` */}
                  <Skeleton variant="rectangular" width={"100%"} height={30} />
                  <Skeleton variant="rectangular" width={"100%"} height={30} />
                  <Skeleton variant="rectangular" width={"100%"} height={30} />
                  <Skeleton variant="rectangular" width={"100%"} height={30} />
                  <Skeleton variant="rectangular" width={"100%"} height={30} />
                  <Skeleton variant="rectangular" width={"100%"} height={30} />
                  <Skeleton variant="rectangular" width={"100%"} height={30} />
                  <Skeleton variant="rectangular" width={"100%"} height={30} />
                  <Skeleton variant="rectangular" width={"100%"} height={30} />
                  <Skeleton variant="rectangular" width={"100%"} height={30} />
                  <Skeleton variant="rectangular" width={"100%"} height={30} />
                  <Skeleton variant="rectangular" width={"100%"} height={30} />
                </Stack>
              ) : (
                <TableAlertsComponent
                  autoResize={true}
                  timeframe={timeframe}
                  alerts={selectedList ? alertsOfSingleList ?? [] : alerts}
                />
              )}
            </Box>
          </div>
        )}
      </Box>
    );
  }
);
