import { Preferences } from "../../../../api/account/Preferences";
import { ClusterAnalytics } from "../../../../api/compute/ClusterAnalytics";
import { Instruments } from "../../../../api/compute/Instruments";
import { Peers } from "../../../../api/compute/Peers";
import { Rankings } from "../../../../api/compute/Rankings";
import { TemplatePreferenceRank } from "../../../../api/compute/TemplatePreferenceRank";
import {
  dataInjector,
  extractForDataIngestion,
  extractSymbols,
} from "../../../../api/compute/commons";
import { deepClone } from "../../../../deepClone";
import i18n from "../../../../i18n";
import { AppEnvironment } from "../../../../types/Defaults";
import { widgetsConfiguration } from "../../widgets/widgetsConfiguration";

export class Storage {
  instrumentsAPI: Instruments;
  clusterAnalyticsAPI: ClusterAnalytics;
  peersAPI: Peers;
  rankingsAPI: Rankings;
  environment: AppEnvironment;
  rankingTemplatesAPI: TemplatePreferenceRank;
  preferencesAPI: Preferences;

  constructor(environment: AppEnvironment) {
    this.instrumentsAPI = new Instruments(environment);
    this.clusterAnalyticsAPI = new ClusterAnalytics(environment);
    this.peersAPI = new Peers(environment);
    this.rankingsAPI = new Rankings(environment);
    this.environment = environment;
    this.rankingTemplatesAPI = new TemplatePreferenceRank(environment);
    this.preferencesAPI = new Preferences(environment);
  }

  /**
   * Get the instrument data, starting with getting the type, then the other fields depending of its type
   * It can return null if the instrument is not in the system.
   *
   * @param {object} params - parameters
   * @param {object} params.instrument - the instrument
   * @param {string} params.instrument.symbol - the symbol
   * @param {string} params.instrument.type - the type
   * @param {object} params.constraints - same as filter
   */
  async alternatives(params) {
    const response = await this.instrumentsAPI.alternativesTo(params);

    if (response["data"].length === 0) {
      return response;
    }
    var properties;
    var instrumentType = params["instrument"]["type"].toLowerCase();
    var widgetConfiguration = widgetsConfiguration["widgets/analysis/security"];

    switch (instrumentType) {
      case "etf":
      case "stock": {
        properties = widgetConfiguration["properties_" + instrumentType];
        break;
      }
      default: {
        properties = widgetConfiguration["properties"];
      }
    }

    return this.instrumentsAPI.fetch({
      properties: properties,
      symbols: response["data"],
      type: "security",
    });
  }

  /**
   * Get dispersion from a list of symbols
   *
   * @param {object}   params
   * @param {string}   params.performanceTimeframe - one of
   *      "1_month", "3_months", "6_months", "12_months".
   *      Default is "12_months"
   * @param {number}   params.intervals - number of quantiles.
   *      Default is 4
   * @param {string[]} params.symbols list of symbols
   *
   * @returns {dojo/promise/Promise} a promise fulfilled with the
   *       handled data of the response
   */
  async dispersion(params) {
    const analytics = [
      "pr#avg#false",
      "pr#min#false",
      "pr#max#false",
      "rc",
      "card",
    ];

    let analytic;
    switch (params["performanceTimeframe"]) {
      case "1_week":
        analytic = "pw";
        break;
      case "1_month":
        analytic = "pm";
        break;
      case "3_months":
        analytic = "pq";
        break;
      case "6_months":
        analytic = "ps";
        break;
      case "12_months":
        analytic = "py";
        break;
      default:
        throw new Error(
          `Unknown performance ${params["performanceTimeframe"]}`
        );
    }

    const clusters = [
      {
        dimension: analytic,
        transform: {
          function: "quantile",
          params: {
            n: params.intervals,
            trimOutliers: params.trimOutliers ?? false,
          },
        },
      },
    ];

    return this.clusterAnalyticsAPI
      .createConfiguration()
      .analytics(analytics)
      .clusters(clusters)
      .method("DRILL_DOWN")
      .universeFromInstruments(params.symbols)
      .fetchQuantiles();
  }

  /**
   * Get the instrument data, starting with getting the type, then the other fields depending of its type
   * It can return null if the instrument is not in the system.
   *
   * @param {object} params Parameters
   * @param {string} params.symbol The instrument symbol
   */
  async instrument(params) {
    params["symbols"] = [params.symbol];
    const response = await this.instruments(params);
    return response != null ? response[0] : response;
  }

  /**
   * Get the instrument data, starting with getting the type, then the other fields depending of its type
   *
   * @param {object} params Parameters
   * @param {string} params.symbols List of symbols
   */
  async instruments(params) {
    let response = await this.instrumentsAPI.fetch({
      properties: [
        {
          date: null,
          property: "type",
        },
      ],
      symbols: params.symbols,
      type: "security",
    });

    if (response["data"].length === 0) {
      return {
        data: [],
      };
    }
    var properties;
    // Just need the first instrument to get type for everyone
    var securityType = response["data"][0]["type"].toLowerCase();
    var widgetConfiguration = widgetsConfiguration["widgets/analysis/security"];

    switch (securityType) {
      case "etf":
      case "stock": {
        properties = widgetConfiguration["properties_" + securityType];
        break;
      }
      default: {
        properties = widgetConfiguration["properties"];
      }
    }

    response = await this.instrumentsAPI.fetch({
      properties: properties,
      symbols: params.symbols,
      type: "security",
    });

    return response["data"];
  }

  /**
   * Get history of instrument
   *
   * @param {object} params Parameters
   * @param {object} params.symbol The symbol to use
   * @param {number} params.years The period, optional
   */
  instrumentHistory(params) {
    const _params = deepClone(params);
    if (_params.years == null) {
      _params.years = 99;
    }

    return this.instrumentsAPI.historyOf(_params);
  }

  /**
   * Get peer related to the intrument
   *
   * @param {object} params Parameters
   * @param {string} params.country
   * @param {string} params.icb
   * @param {string} params.sizeClassification
   * @param {string} params.type
   *
   * @returns {dojo/promise/Promise} a promise fulfilled with the
   *       handled data of the response
   */
  async peer(params) {
    var _params = [
      [
        {
          zDimension:
            params["sizeClassification"] != null
              ? params["sizeClassification"]
              : "microLarge",
          type: params["type"],
          what: params["icb"] || params["etfclass"],
          where: params["country"] || params["etfgeo"],
        },
      ],
    ];

    const response = await this.peersAPI.get(_params);
    return response[0][0];
  }

  /**
   * Get peer related to the security
   * It needs to call the Security.peer method, and use the normalize function of Peer.
   * The normalize function expects a matrix as input data, so the response is wrapped in a double array.
   *
   * @param {object} params Parameters
   * @param {string} params.symbol The instrument symbol
   */
  peerByInstrument(instrument) {
    return this.peersAPI.ofInstrument(instrument);
  }

  async peerSymbols(params) {
    // Uses 100000 as pagination limit because we don't need a limit
    var filterParams = {
      filters: [
        {
          dimension: "type",
          segments: [params.peer["type"]],
        },
      ],
      page: { page: 1, rows: 100000 },
      sort: { dimension: "marketcap", rev: true },
    };
    if (params.peer["type"] === "Stock") {
      filterParams["filters"].push({
        dimension: "stockclass",
        segments: ["STOCK"],
      });
    }

    var dimensions =
      window.App.user.product.configuration.analysis_instrument.peers.request[
        params.peer["type"]
      ];
    if (dimensions != null) {
      this._peerDimensionUiToApi(params["peer"], dimensions, filterParams);
    }

    const response = await this.instrumentsAPI.filter(filterParams);
    return response["data"];
  }

  /**
   * Get ranking of symbols from a specified rule
   *
   * @param {object} params Parameters
   * @param {object} params.type - type
   * @param {object} params.rules - rules to apply on ranking
   * @param {object} params.symbols - list of symbols to sort
   */
  async rank(params) {
    if (params.type == null) {
      throw new Error("Rank needs the instrument type specified");
    }

    let instruments: any = [];
    for (let i = 0, length = params["symbols"].length; i < length; i++) {
      instruments.push({
        symbol: params["symbols"][i],
        type: params["type"],
      });
    }

    let _params = {
      fromDate: null,
      instruments: instruments,
      rules: params["rules"],
    };

    const rankedUniverse = await this.rankingsAPI.ranking(_params);

    const response = await this._rankingSorted(params, rankedUniverse);

    return response;
  }

  /**
   * Returns the ranking rules for a specific template id.
   * If there is no ranking rule found, just return the default one.
   *
   * @param {object} params
   * @param {object} params.templateId - The template id to search
   */
  async rankingTemplate(params) {
    const rankingTemplates = await this.rankingTemplates();
    // Check if there is a previous selected active template
    var template = rankingTemplates["default"]["configuration"]["ranking"];
    if (params.templateId != null) {
      for (var i = 0; i < rankingTemplates["templates"].length; i++) {
        var rankingTemplate = rankingTemplates["templates"][i];
        if (rankingTemplate["id"] === params.templateId) {
          template = rankingTemplate["configuration"]["ranking"];
        }
      }
    }
    return template;
  }

  async rankingTemplates() {
    var screening = this.environment["configuration"].get("screening");

    var defaultTemplate = {
      configuration: {
        ranking: deepClone(
          screening["widgets"]["ranking"]["edit"]["defaultTemplate"]
        ),
      },
      name: i18n["default_template_ranking"],
      id: null,
    };

    var templates = [defaultTemplate];

    // presetTemplates
    const presetTemplates = deepClone(
      screening["widgets"]["ranking"]["edit"]["presetTemplates"]
    );
    if (presetTemplates != null) {
      for (let i = 0; i < presetTemplates.length; i++) {
        var presetTemplate = presetTemplates[i];
        /* id is set to name, used to recover used template
                   when loading back the dialog */
        var preparedTemplate = {
          configuration: {
            ranking: presetTemplate.rules,
          },
          name: presetTemplate.name,
          id: presetTemplate.name,
          isEditable: false,
        };
        templates.push(preparedTemplate);
      }
    }

    const response = await this.rankingTemplatesAPI.get();
    for (let i = 0, length = response.length; i < length; i++) {
      templates.push(response[i]);
    }
    let filteredTemplates: any = [];
    for (let i = 0; i < templates.length; i++) {
      let template = deepClone(templates[i]);
      if (
        template?.name != null &&
        template["name"].indexOf("LAST_USED_") === 0
      ) {
        // Ignore all selected templates
        continue;
      } else {
        filteredTemplates.push(template);
      }
    }
    let result = {
      default: defaultTemplate,
      templates: filteredTemplates,
    };
    return result;
  }
  // ----------------------------------------------------- private methods
  _peerDimensionUiToApi(peer, propertiesApi, target) {
    // Stock: ["icb", "country", "sizeClassification"]
    // ETF: ["etfclass", "etfgeo"]

    let propertyApi: any = null;
    let propertyUi: any = null;
    for (let i = 0; i < propertiesApi.length; i++) {
      propertyApi = propertiesApi[i];

      switch (propertyApi) {
        case "country":
        case "etfgeo": {
          propertyUi = "where";

          break;
        }
        case "icb":
        case "etfclass": {
          propertyUi = "what";

          break;
        }
        case "sizeClassification": {
          propertyUi = "size";

          break;
        }
        default: {
          propertyUi = null;
        }
      }

      if (peer[propertyUi] != null) {
        target["filters"].push({
          dimension: propertyApi,
          segments: [peer[propertyUi]],
        });
      }
    }
  }

  async _rankingInjectProperties(data, currentInstrument, response) {
    // var instrumentsParams = {
    //     symbols: response["data"],
    // };

    const currentTargetIndex = response?.data?.indexOf(
      currentInstrument?.symbol
    );

    const top3 = response?.data?.filter(
      (item) => response?.data?.indexOf(item) < 3
    );
    const bottom3 = response?.data?.filter(
      (item) => response?.data?.indexOf(item) > response?.data?.length - 4
    );

    const firstArrItem =
      currentTargetIndex > 0 ? currentTargetIndex - 1 : currentTargetIndex;
    const lastArrItem =
      currentTargetIndex < response?.data?.length
        ? currentTargetIndex
        : response?.data?.length - 1;
    const middle3: any = [
      response?.data?.[firstArrItem],
      response?.data?.[currentTargetIndex],
      response?.data?.[lastArrItem],
    ];

    const arr = [].concat(top3).concat(middle3).concat(bottom3);
    //remove possible duplicates
    const symbols = [...new Set(arr)];

    const instrumentResponse = await this.instrumentsAPI.fetch({
      properties: [
        { date: null, property: "ticker" },
        { date: null, property: "name" },
      ],
      symbols,
      type: "security",
    });

    const obj = deepClone(instrumentResponse?.data);
    obj["total"] = data.length;
    return dataInjector(obj, data, ["rank"]);

    // return this.instruments(instrumentsParams).then(
    //     lang.hitch(this, function (instrumentResponse) {
    //         return dataInjector(instrumentResponse, data, ["rank"]);
    //     })
    // );
  }

  async _rankingSorted(params, response) {
    var data = response["data"];
    var sortPropertyId = "rank" + new Date().getTime() + ":rank";
    // Uses 100000 as pagination limit because we don't need a limit
    var filterParams = {
      filters: [
        {
          dimension: "type",
          segments: [params.type],
        },
        {
          dimension: "symbol",
          segments: extractSymbols(data),
        },
      ],
      injestion: {
        data: extractForDataIngestion(data, "symbol", "rank"),
        field: sortPropertyId,
        type: "number",
      },
      page: { page: 1, rows: 100000 },
      sort: {
        dimension: sortPropertyId,
        rev: false,
      },
    };

    const instruments = await this.instrumentsAPI.screening(filterParams);

    const result = await this._rankingInjectProperties(
      data,
      params?.currentInstrument,
      instruments
    );

    return result;
  }

  async addToRecents(symbol: string) {
    await this.preferencesAPI.addToRecentSecurityQueue(symbol);
  }

  async getRecents() {
    return await this.preferencesAPI.getRecentlyVisitedSecurity();
  }
}
