import { useCallback, useEffect, useState } from "react";
import { Chart } from "../../../../../components/Chart";
import { useFormatter } from "../../../../../hooks/useFormatter";
import { TDate } from "../../../../../trendrating/date/TDate";
import Highcharts from "highcharts";

type SystematicProductChartProps = {
  chartInteractiveMode?: {
    isEnabled: boolean;
    plugins: string[];
  };
  isLogarithmicMeasure: boolean;
  data: any;
  onSystematicChartClick?: (event) => void;
};

export function SystematicProductChart({
  chartInteractiveMode,
  isLogarithmicMeasure,
  data,
  onSystematicChartClick,
}: SystematicProductChartProps) {
  const format = useFormatter();
  const [config, setConfig] = useState<any>();

  const listenerClick = useCallback(
    (event) => {
      var x = 0;

      if (event["point"] != null) {
        x = event["point"]["x"];
      } else {
        x = event["xAxis"][0]["value"];
      }

      event["value"] = {
        date: TDate.millisecondsToDays(x),
      };

      if (onSystematicChartClick) {
        onSystematicChartClick(event);
      }
    },
    [onSystematicChartClick]
  );

  /**
   * Clickable plugin (for chart configuration)
   * Enable click event
   *
   * @param {object} configuration - Highchart configuration to be read / modified by this function
   * @param {object} value - Object containing the complete chart data
   *
   * @returns {object} a highchart configuration
   */
  const configurationChartPluginClickable = useCallback(
    (configuration) => {
      if (
        configuration &&
        configuration["plotOptions"] &&
        configuration["plotOptions"] &&
        configuration["plotOptions"]["series"]
      ) {
        if (
          typeof configuration["plotOptions"]["series"]["events"] ===
          "undefined"
        ) {
          configuration["plotOptions"]["series"]["events"] = {};
        }
        if (
          typeof configuration["plotOptions"]["series"]["events"]["click"] !==
          "undefined"
        ) {
          console.warn(
            "_configurationChartPluginClickable: click event already defined!"
          );
        }
        configuration["plotOptions"]["series"]["events"]["click"] =
          listenerClick;
      }

      configuration["chart"]["events"] = {
        click: listenerClick,
      };

      return configuration;
    },
    [listenerClick]
  );

  /**
   * Plotlines plugin (for chart configuration)
   * Enable plot lines
   *
   * @param {object} configuration - Highchart configuration to be read / modified by this function
   * @param {object} value - Object containing the complete chart data
   *
   * @returns {object} a highchart configuration
   */
  const configurationChartPluginPlotlines = useCallback(
    (configuration, value) => {
      const plotLines: any = [];

      for (var i = 0, N = value.response.POS.length; i < N; i++) {
        var pos = value.response.POS[i];
        plotLines.push({
          value: parseFloat(TDate.daysToMilliseconds(pos.d) as any),
          color: "#d3d3d3",
          width: 1,
          id: "p_" + pos.d,
        });
      }
      if (!Array.isArray(configuration["xAxis"])) {
        console.warn(
          "xAxis is not an array! Cannot update with plotlines data."
        );
      } else {
        if (configuration["xAxis"].length > 0) {
          configuration["xAxis"][0]["plotLines"] = plotLines;
        } else {
          console.warn("xAxis is empty, added the default configuration.");
          configuration["xAxis"].push({
            title: { text: null },
            labels: { style: { fontSize: 9 } },
            plotLines: plotLines,
          });
        }
      }
      return configuration;
    },
    []
  );

  /**
   * Tooltip plugin (for chart configuration)
   * Enable tooltips
   *
   * @param {object} configuration - Highchart configuration to be read / modified by this function
   * @param {object} value - Object containing the complete chart data
   *
   * @returns {object} a highchart configuration
   */
  const configurationChartPluginTooltip = useCallback(
    (configuration) => {
      configuration["tooltip"] = {
        enabled: true,
        formatter: function () {
          const self: any = this;
          return self.points.reduce(function (s, point) {
            var formattedValue = format.custom("numberBigFmt", {
              options: {
                notAvailable: {
                  input: "",
                  output: "",
                },
              },
              output: "HTML",
              value: point.y,
              valueHelper: null,
            });
            return (
              s +
              '<br/><span style="color:' +
              point.color +
              '">\u25CF</span> ' +
              point.series.name +
              ": <strong>" +
              formattedValue +
              "</strong>"
            );
          }, '<style="font-size: 11px">' +
            Highcharts.dateFormat("%A, %b %e, %Y", self.x) +
            "</b>");
        },
        shared: true,
      };
      return configuration;
    },
    [format]
  );

  /**
   * Zoom plugin (for chart configuration)
   * Enable zoom
   *
   * @param {object} configuration - Highchart configuration to be read / modified by this function
   * @param {object} value - Object containing the complete chart data
   *
   * @returns {object} a highchart configuration
   */
  const configurationChartPluginZoomX = useCallback((configuration) => {
    configuration["chart"]["zoomType"] = "x";

    return configuration;
  }, []);

  const buildChartConfig = useCallback(() => {
    const params = {
      interactiveMode: {
        isEnabled: chartInteractiveMode?.isEnabled ?? false,
        plugins: chartInteractiveMode?.plugins ?? [],
      },
      isLogarithmic: isLogarithmicMeasure ?? false,
    };

    const interactiveMode = params.interactiveMode;
    const isLogarithmic = params.isLogarithmic;

    if (data && interactiveMode != null) {
      const series: any = [];

      if (data.list != null) {
        series.push({
          color: "#2a7092",
          data: data?.list?.["serie"] ?? [],
          enableMouseTracking: interactiveMode.isEnabled,
          lineWidth: 2,
          name: data?.list?.["name"] ?? "",
          type: "line",
          yAxis: 0,
        });
      }

      if (data.benchmark != null) {
        series.push({
          color: "#ffcc00",
          data: data?.benchmark?.["serie"] ?? [],
          enableMouseTracking: interactiveMode.isEnabled,
          lineWidth: 2,
          name: data?.benchmark?.["name"] ?? "",
          type: "line",
          yAxis: 0,
        });
      }

      var configuration = {
        chart: {
          alignTicks: false,
          animation: false,
          //   height: height,
          marginTop: 30,
        },
        credits: { enabled: false },
        exporting: { enabled: false },
        legend: {
          enabled: true,
          layout: "horizontal",
        },
        navigator: { enabled: false },
        plotOptions: {
          series: {
            animation: false,
            cursor: "pointer",
          },
        },
        rangeSelector: { enabled: false },
        scrollbar: { enabled: false },
        series: series,
        title: { text: null },
        tooltip: {
          enabled: false,
        },
        xAxis: [
          {
            labels: {
              style: {
                fontSize: 9,
              },
            },
            title: { text: null },
          },
        ],
        yAxis: [
          {
            endOnTick: false,
            type: isLogarithmic ? "logarithmic" : "linear",
            labels: {
              formatter: function () {
                const self: any = this;
                return format.custom("numberBigFmt", {
                  options: {
                    notAvailable: {
                      input: "",
                      output: "",
                    },
                  },
                  output: "HTML",
                  value: self.value,
                  valueHelper: null,
                });
              },
              style: {
                fontSize: 9,
              },
            },
            maxPadding: interactiveMode.isEnabled ? 0.01 : 0,
            minPadding: interactiveMode.isEnabled ? 0.01 : 0,
            opposite: false,
            startOnTick: false,
            title: { text: null },
          },
        ],
      };

      // separation between backtest and the product
      if (data["backtestEndsAt"] != null) {
        var plotLines = [
          {
            color: "transparent",
            id: "backtestSeparatorAfter",
            label: {
              style: {
                color: "#666",
                fontFamily: "'Open Sans', sans-serif",
                fontSize: "9px",
              },
              text: "actual",
              verticalAlign: "middle",
              x: 4,
            },
            value:
              data?.["backtestEndsAt"] != null
                ? parseFloat(
                    TDate.daysToMilliseconds(data["backtestEndsAt"]) as any
                  )
                : null,
            width: 1,
          },
          {
            color: "#d3d3d3",
            id: "backtestSeparator",
            label: {
              align: "right",
              rotation: 0,
              style: {
                color: "#666",
                fontFamily: "'Open Sans', sans-serif",
                fontSize: "9px",
              },
              text: "backtest",
              x: -5,
            },
            value:
              data?.["backtestEndsAt"] != null
                ? parseFloat(
                    TDate.daysToMilliseconds(data["backtestEndsAt"]) as any
                  )
                : null,
            width: 1,
          },
        ];

        configuration["xAxis"][0]["plotLines"] = plotLines;
      }

      if (interactiveMode.isEnabled) {
        if (Array.isArray(interactiveMode.plugins)) {
          if (interactiveMode.plugins.indexOf("clickable") !== -1) {
            configuration = configurationChartPluginClickable(configuration);
          }
          if (interactiveMode.plugins.indexOf("plotlines") !== -1) {
            configuration = configurationChartPluginPlotlines(
              configuration,
              data
            );
          }
          if (interactiveMode.plugins.indexOf("tooltip") !== -1) {
            configuration = configurationChartPluginTooltip(configuration);
          }
          if (interactiveMode.plugins.indexOf("zoomX") !== -1) {
            configuration = configurationChartPluginZoomX(configuration);
          }
        }
      }
      setConfig(configuration);
    }
  }, [
    chartInteractiveMode?.isEnabled,
    chartInteractiveMode?.plugins,
    configurationChartPluginClickable,
    configurationChartPluginPlotlines,
    configurationChartPluginTooltip,
    configurationChartPluginZoomX,
    data,
    format,
    isLogarithmicMeasure,
  ]);

  useEffect(() => {
    buildChartConfig();
  }, [buildChartConfig]);

  return config ? <Chart options={config} /> : <></>;
}
