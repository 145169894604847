import { Box, Button, Card, CardContent, Typography } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Instruments } from "../../../../../../api/compute/Instruments";
import { SecurityPriceChart } from "../../../../../../components/SecurityPriceChart/SecurityPriceChart";
import { useEnvironment } from "../../../../../../hooks/useEnvironment";
import { useFormatter } from "../../../../../../hooks/useFormatter";
import { _getETFClass, _getIntervalLabel } from "./utils";

type Props = {
  data: any;
  hasActions?: boolean;
  hasWeight?: boolean;
  onHold?: () => void;
  onSell?: () => void;
  onSwap?: (data) => void;
  isHighlighted?: boolean;
  hasLogo?: boolean;
};

export default function Issues({
  data,
  onHold,
  onSell,
  onSwap,
  hasActions = true,
  hasWeight = true,
  hasLogo = false,
  isHighlighted = false,
}: Props) {
  const environment = useEnvironment();
  const envSetup = useMemo(() => environment.get("setup"), [environment]);
  const instrumentsAPI = useMemo(() => new Instruments(envSetup), [envSetup]);
  const format = useFormatter();
  const { t } = useTranslation();
  const [hover, setHover] = useState(false);

  const formatTypeWhatWhere = useCallback(
    (holding) => {
      var taxonomies = environment.get("rawTaxonomies");
      var taxonomyDomicile = taxonomies["Markets"];
      const fieldsMap = envSetup["taxonomyFields"];

      var taxonomyWhat =
        holding.type === "ETF"
          ? taxonomies[fieldsMap["ETF"]["etfclass"]]
          : taxonomies[fieldsMap["security"]["sector"]];
      var taxonomyWhere =
        holding.type === "ETF"
          ? taxonomies[fieldsMap["ETF"]["etfgeo"]]
          : taxonomies[fieldsMap["security"]["country"]];

      var what =
        taxonomyWhat[holding.type === "ETF" ? holding.etfclass : holding.icb];
      var where =
        taxonomyWhere[
          holding.type === "ETF" ? holding.etfgeo : holding.country
        ];
      var domicile = taxonomyDomicile[holding.domicile];

      var typeWhatWhere = "";
      switch (holding.type) {
        case "Stock": {
          typeWhatWhere =
            where.id !== domicile.id
              ? where.name + "(" + domicile.name + ")"
              : where.name;
          typeWhatWhere +=
            "<br/>" +
            _getIntervalLabel(holding.marketcap, null, format) +
            (what ? " | " + what.name : "");

          break;
        }
        case "ETF": {
          typeWhatWhere = where.name;

          what = _getETFClass(holding.etfclass, environment, envSetup);

          typeWhatWhere +=
            "<br/>" +
            (what.length === 1
              ? what[0].name
              : what[what.length - 1].name + " " + what[0].name) +
            " | " +
            where.name;

          break;
        }
        default: {
          typeWhatWhere = holding.type + " : " + where.name;
        }
      }

      return typeWhatWhere;
    },
    [envSetup, environment, format]
  );
  const typeWhatWhere = useMemo(
    () => formatTypeWhatWhere(data),
    [data, formatTypeWhatWhere]
  );
  const formatRating = useCallback(
    (holding) => {
      var rating = [
        format.html(
          "rc",
          "rating",
          holding["rc"],
          {
            rateChange: holding["lr"],
            rateDate: holding["dr"],
            ratePrev: holding["rrr"],
          },
          holding["type"]
        ),
        t("from"),
        format.html(
          "dr",
          "rated_on_iso",
          holding["dr"],
          holding,
          holding["type"]
        ),
      ].join(" ");

      return rating;
    },
    [format, t]
  );
  const formatPrice = useCallback(
    (holding) => {
      return format.html(
        "vc",
        "price",
        holding["vc"],
        holding,
        holding["type"]
      );
    },
    [format]
  );
  // const imageURL = useMemo(
  //   () => ,
  //   [data, instrumentsAPI]
  // );

  const [logo, setLogo] = useState<any>(null);
  useEffect(() => {
    if (hasLogo) {
      instrumentsAPI.logo(data).then((response) => setLogo(response));
    }
  }, [data, hasLogo, instrumentsAPI]);

  return (
    <Card
      sx={{
        boxShadow: 1,
        backgroundColor: isHighlighted ? "#f6f6f6" : "#fff",
        overflow: "unset",
      }}
      onMouseEnter={() => {
        if (hasActions) {
          setHover(true);
        }
      }}
      onMouseLeave={() => {
        if (hasActions) {
          setHover(false);
        }
      }}
    >
      <CardContent>
        <Box
          position={"relative"}
          display={"flex"}
          justifyContent={"space-between"}
          gap={1}
        >
          {hover && hasActions && (
            <Box
              display={"flex"}
              gap={1}
              style={{
                position: "absolute",
                bottom: 0,
                left: "50%",
                transform: "translate(-50%)",
                zIndex: 2,
              }}
            >
              <Button onClick={onHold} sx={{ boxShadow: 3 }} size="small">
                <Typography>Hold</Typography>
              </Button>
              <Button onClick={onSell} sx={{ boxShadow: 3 }} size="small">
                <Typography>Sell</Typography>
              </Button>
              <Button
                onClick={() => (onSwap ? onSwap(data) : null)}
                sx={{ boxShadow: 3 }}
                size="small"
              >
                <Typography>Swap</Typography>
              </Button>
            </Box>
          )}
          {hasWeight && (
            <Box width={"20%"} display={"flex"} alignItems={"center"}>
              <Typography>
                <strong>{(data.weight * 100).toFixed(2)}%</strong>
              </Typography>
            </Box>
          )}
          <Box
            display={"flex"}
            gap={1}
            width={"100%"}
            flexDirection={"column"}
            justifyContent={"center"}
          >
            <Typography variant="subtitle1">
              <strong>{data.name}</strong>
            </Typography>
            <span dangerouslySetInnerHTML={{ __html: typeWhatWhere }}></span>
            <span
              dangerouslySetInnerHTML={{ __html: formatRating(data) }}
            ></span>
            <span
              dangerouslySetInnerHTML={{ __html: formatPrice(data) }}
            ></span>
          </Box>
          {logo && hasLogo && (
            <Box display={"flex"} alignItems={"center"} p={2}>
              <Box boxShadow={2} borderRadius={2} overflow={"hidden"}>
                <img width={"100px"} height={"auto"} src={logo} alt="" />
              </Box>
            </Box>
          )}

          <Box width={"100%"} display={"flex"} alignItems={"center"}>
            <div style={{ height: "100%", width: "100%" }}>
              <SecurityPriceChart symbol={data.symbol} />
            </div>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}
