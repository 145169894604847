import { ColumnComponent } from "tabulator-tables";
import { Properties } from "../../../../api/Properties";
import { AppEnvironment } from "../../../../types/Defaults";

export class TableHelperCommon {
  protected propertiesAPI: Properties;
  protected environment: AppEnvironment;

  constructor(environment: AppEnvironment) {
    this.environment = environment;
    this.propertiesAPI = new Properties({
      properties: environment["properties"],
    });
  }

  getSortInitialDirection(property: string, type: string) {
    const properties = this.environment["properties"];
    // First check for specificity
    let propertyType =
      properties?.[type?.toLowerCase()]?.[property]?.formatter?.table?.type;

    // if there is none, try the generic "security"
    // propertyType = properties?.["security"]?.[property]?.formatter?.table?.type;
    propertyType = properties?.[property]?.formatter?.table?.type;

    switch (propertyType) {
      case "number":
      case "numberBig":
      case "durationMagnitudeUpi":
      case "price":
      case "rating":
        return "desc";
      case "taxon":
      case "string":
      case "date":
      case "newHighNewLow":
      case "bar":
      default:
        return "asc";
    }
  }

  prepareHeaderClickTapListener(eventCallback?: Function) {
    return function headerClickTapListener(
      e: UIEvent,
      headerColumn: ColumnComponent
    ) {
      const sorters = headerColumn.getTable().getSorters();

      let actualDirection = headerColumn.getDefinition().headerSortStartingDir;
      if (sorters.length > 0 && sorters[0].field === headerColumn.getField()) {
        actualDirection = sorters[0].dir === "asc" ? "desc" : "asc";
      }

      if (eventCallback) {
        eventCallback({
          type: "sort",
          value: {
            direction: actualDirection,
            field: headerColumn.getField(),
          },
        });
      }
    };
  }

  prepareInputColumns(columns) {
    const output: any = [];
    for (const col of columns) {
      if (col) {
        if (typeof col === "string") {
          output.push({
            field: col,
            label: this.propertiesAPI.get(col, 0, "auto") ?? "",
            sortable: false,
          });
        } else {
          output.push(col);
        }
      }
    }

    return output;
  }
}
