import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  Box,
  Button,
  InputAdornment,
  Popover,
  Slider,
  TextField,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { deepClone } from "../../../../../../../deepClone";
import { useFormatter } from "../../../../../../../hooks/useFormatter";
import { FormOptions } from "../../../../../../trendrating-widgets/form/FormOptions";
import styles from "./MarketCapFilter.module.scss";

type MarketCapFilterProps = {
  isNullIfAreAllSelected?: boolean;
  hasRangeSlider?: boolean;
  updateValue: (size) => any;
  initValue?: { ge?: number | null; le?: number | null } | null;
};

type MarketCapWidgetProps = {
  options: {
    ge: number;
    le: number;
    checked: boolean;
    innerHTML: string;
    label: string;
    node: null;
    title: string;
  }[];
  initRangeSlider?: { le: number | null; ge: number | null };
  setValue: (value) => void;
  value: any;
  hasRangeSlider?: boolean;
};

export function MarketCapFilter({
  isNullIfAreAllSelected = true,
  hasRangeSlider = true,
  updateValue,
  initValue,
}: MarketCapFilterProps) {
  const options = useMemo(() => {
    return FormOptions.get("MARKET_CAP");
  }, []);

  const onSelectSize = useCallback(
    (size) => {
      updateValue(size);
    },
    [updateValue]
  );

  const convertInitValue = useCallback(
    (size) => {
      if (!size) {
        return null;
      }

      const selectedValues: number[] = options.map((item, index) => index);

      let leftPoint = -1;
      let rightPoint = -1;

      if (size.ge) {
        const option = options.find((opt) => opt.ge === size.ge);

        if (option) {
          leftPoint = options.indexOf(option);
        }
      }

      // This check is necessary because in options last interval has ge = null
      // so if size.ge !== null and size.le === null it means that the selected interval is the last one
      if (size.le || size.ge != null) {
        const option = options.find((opt) => opt.le === size.le);

        if (option) {
          rightPoint = options.indexOf(option);
        }
      }

      //If size interval is not null but the interval is not in options is a custom interval for rage widget
      if (
        size.le !== null &&
        size.ge !== null &&
        rightPoint === -1 &&
        leftPoint === -1
      ) {
        return size;
      }

      const result = selectedValues.reduce((prev, current) => {
        if (current >= leftPoint && current <= rightPoint) {
          prev[current] = true;
        } else {
          prev[current] = false;
        }

        return prev;
      }, {});

      if (isNullIfAreAllSelected && isNullIfAreAllSelected === true) {
        if (result["0"] === true && result[`${options.length - 1}`] === true) {
          const newValue = selectedValues.reduce(
            (prev, current, index) => ({ ...prev, [index]: false }),
            {}
          );

          return newValue;
        }
      }

      return result;
    },
    [isNullIfAreAllSelected, options]
  );

  return (
    <Box
      component={"ul"}
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
    >
      <MarketCapWidget
        options={options}
        setValue={onSelectSize}
        value={convertInitValue(initValue)}
        hasRangeSlider={hasRangeSlider}
        initRangeSlider={convertInitValue(initValue)}
      />
    </Box>
  );
}

function MarketCapWidget({
  setValue,
  value,
  options,
  initRangeSlider,
  hasRangeSlider = false,
}: MarketCapWidgetProps) {
  const [intMap, setIntMap] = useState<any>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const formatter = useFormatter();
  const [sl, setSl] = useState([50 * 1e6, 2 * 1e12]);
  const [inpMin, setInpMin] = useState<any>(`50.00M`);
  const [inpMax, setInpMax] = useState<any>(`2.00T`);
  const [descriptiveLabel, setDescriptiveLabel] = useState<string | null>(null);

  const getIntervalFromMap = useCallback(
    (intMap) => {
      const intMapKeys: number[] = [];

      for (const [key, value] of Object.entries(intMap)) {
        if (value === true) {
          intMapKeys.push(parseInt(key));
        }
      }

      const leftInterval = Math.min(...intMapKeys);
      const rightInterval = Math.max(...intMapKeys);

      const intervalObj: any = {
        le: options?.[rightInterval]?.le ?? null,
        ge: options?.[leftInterval]?.ge ?? null,
      };

      if (intMap["0"] === true && intMap[`${options.length - 1}`] === true) {
        intervalObj["le"] = null;
        intervalObj["ge"] = null;
      }

      return intervalObj;
    },
    [options]
  );

  const sendValue = useCallback(
    (intMap) => {
      const valueToSend = getIntervalFromMap(intMap);
      setValue(valueToSend);
    },
    [getIntervalFromMap, setValue]
  );

  // const resetSlider = useCallback(() => {
  //     setSl([50 * 1e6, 2 * 1e12]);
  //     setInpMin(`50.00M`);
  //     setInpMax(`2.00T`);
  // }, []);

  const setCustomRange = useCallback(() => {
    const value: any = {};

    if (sl[0]) {
      value["ge"] = sl[0] / 1e6;
    }

    if (sl[1]) {
      value["le"] = sl[1] / 1e6;
    }

    setValue(value);
  }, [setValue, sl]);

  const handleChangeSlider = useCallback(
    (event: Event, newValue: number | number[]) => {
      setSl(newValue as number[]);
      const minValue = formatter.custom("numberBigFmt", {
        options: {
          decimals: 2,
          notAvailable: {
            input: null,
            output: "",
          },
        },
        output: "TEXT",
        value: newValue[0],
        valueHelper: null,
      });
      const maxValue = formatter.custom("numberBigFmt", {
        options: {
          decimals: 2,
          notAvailable: {
            input: null,
            output: "",
          },
        },
        output: "TEXT",
        value: newValue[1],
        valueHelper: null,
      });

      setInpMax(maxValue);
      setInpMin(minValue);
    },
    [formatter]
  );

  const openPopover = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    },
    []
  );

  const formatBigNumbers = useCallback(
    (value) =>
      formatter.custom("numberBigFmt", {
        options: {
          decimals: 2,
          notAvailable: {
            input: null,
            output: "",
          },
        },
        output: "TEXT",
        value: value * 1e6,
        valueHelper: null,
      }),
    [formatter]
  );

  const closePopover = useCallback(() => {
    // resetSlider();
    setAnchorEl(null);
  }, []);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const getIntervalLabel = useCallback(
    (interval: { le: number; ge: number }) => {
      let label = "";

      if (interval.le && interval.ge) {
        label = `${formatBigNumbers(interval.ge)} - ${formatBigNumbers(
          interval.le
        )}`;
      }

      if (interval.le && !interval.ge) {
        label = `Less ${formatBigNumbers(interval.le)}`;
      }

      if (interval.ge && !interval.le) {
        label = `Over ${formatBigNumbers(interval.ge)}`;
      }

      return label;
    },
    [formatBigNumbers]
  );

  const setIntervalLabel = useCallback(
    (interval: { le: number; ge: number }) => {
      const label = getIntervalLabel(interval);

      setDescriptiveLabel(label);
    },
    [getIntervalLabel]
  );

  useEffect(() => {
    let checkboxMap: any = null;
    if (!value) {
      checkboxMap = options.reduce(
        (prev, current, index) => ({ ...prev, [index]: false }),
        {}
      );
    } else {
      if ("le" in value || "ge" in value) {
        const updatedSl: any = [];

        if (value.ge) {
          updatedSl[0] = value.ge * 1e6;
        }

        if (value.le) {
          updatedSl[1] = value.le * 1e6;
        }

        setSl(updatedSl);
        setIntervalLabel(value);
        setInpMin(formatBigNumbers(value.ge));
        setInpMax(formatBigNumbers(value.le));
      } else {
        checkboxMap = value;
      }
    }

    if (checkboxMap) {
      setIntMap(checkboxMap);
    } else {
      setIntMap({ 0: false, 1: false, 2: false, 3: false, 4: false });
    }
  }, [formatBigNumbers, options, setIntervalLabel, value]);

  const range = useCallback((start, end) => {
    if (start === end) return [start];
    return [start, ...range(start + 1, end)];
  }, []);

  const updateMap = useCallback(
    (index) => {
      const newMap = deepClone(intMap);
      newMap[index] = !newMap[index];
      let indexStart: any = -1;
      let indexEnd: any = -1;

      for (const [key, value] of Object.entries(newMap)) {
        if (value === true) {
          if (indexStart === -1) {
            indexStart = key;
          } else {
            indexEnd = key;
          }
        }
      }

      for (const key of Object.keys(newMap)) {
        if (indexStart <= parseInt(key) && parseInt(key) <= indexEnd) {
          newMap[key] = true;
        }
      }

      setIntMap(newMap);
      sendValue(newMap);
    },
    [intMap, sendValue]
  );

  useEffect(() => {
    if (intMap) {
      const intervalObject = getIntervalFromMap(intMap);
      setIntervalLabel(intervalObject);
    }
  }, [getIntervalFromMap, intMap, setIntervalLabel]);

  const isSelected = useCallback(
    (index: number) => {
      return intMap?.[index] ?? false;
    },
    [intMap]
  );

  const getMultiplier = useCallback((abbr) => {
    let multiplier = 1;

    switch (abbr) {
      case "T": {
        multiplier = 1 * 1e12;
        break;
      }
      case "B": {
        multiplier = 1 * 1e9;
        break;
      }
      case "M": {
        multiplier = 1 * 1e6;
        break;
      }
      case "K": {
        multiplier = 1 * 1e3;
        break;
      }

      default:
        return multiplier;
    }

    return multiplier;
  }, []);

  const adjustInputValue = useCallback(
    (str: string) => {
      if (str === "") {
        return null;
      }

      const pattern = /[TBMK]/g;
      const isValid = pattern.test(str);

      if (!isValid) {
        return null;
      }

      const abbr = str.slice(-1);
      const multiplier = getMultiplier(abbr);
      const numericValue = str.slice(0, -1);

      return parseFloat(numericValue) * multiplier;
    },
    [getMultiplier]
  );

  const handleInputChange = (event, type: "min" | "max") => {
    const value = event.target.value;
    const adjustedValue = adjustInputValue(value);
    const sliderValue = [...sl];

    if (type === "max") {
      setInpMax(value);
      sliderValue[1] = adjustedValue ? adjustedValue : 2 * 1e12;
    } else {
      setInpMin(value);
      sliderValue[0] = adjustedValue ? adjustedValue : 50 * 1e6;
    }

    setSl(sliderValue);
  };

  return options ? (
    <Box display={"flex"} flexDirection={"column"}>
      <Box component={"ul"} display={"flex"}>
        {options.map((opt, index) => {
          return (
            <li
              onClick={() => updateMap(index)}
              key={uuidv4()}
              title={options[index].title}
              className={`${styles.listItems} ${
                hasRangeSlider ? styles.listItemsWithRange : ""
              } ${isSelected(index) ? styles.active : ""}`}
            >
              <Typography>{opt.innerHTML}</Typography>
            </li>
          );
        })}
        {hasRangeSlider && (
          <>
            <button
              className={styles.openSliderButton}
              aria-describedby={id}
              onClick={openPopover}
            >
              <KeyboardArrowDownIcon
                sx={{ fontSize: "0.9vw" }}
                color={"primary"}
              />
            </button>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={closePopover}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <Box sx={{ width: 300 }} p={2}>
                <Box>
                  <Slider
                    getAriaLabel={() => "Market Cap. range"}
                    value={sl as any}
                    onChange={handleChangeSlider}
                    valueLabelDisplay="off"
                    size="small"
                    step={1}
                    min={50 * 1e6}
                    max={2 * 1e12}
                  />
                  <Box display={"flex"} justifyContent={"space-between"}>
                    <Box flex={1} maxWidth={"70px"}>
                      <TextField
                        value={inpMin}
                        onChange={(e) => handleInputChange(e, "min")}
                        size={"small"}
                        InputProps={{
                          classes: {
                            input: styles.inputSlider,
                          },
                          style: {
                            paddingRight: 5,
                          },
                          endAdornment: (
                            <InputAdornment
                              sx={{
                                marginLeft: "0!important",
                              }}
                              position="end"
                            >
                              $
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Box>
                    <Box flex={1} maxWidth={"70px"}>
                      <TextField
                        value={inpMax}
                        onChange={(e) => handleInputChange(e, "max")}
                        size={"small"}
                        InputProps={{
                          classes: {
                            input: styles.inputSlider,
                          },
                          style: { paddingRight: 5 },
                          endAdornment: (
                            <InputAdornment
                              sx={{
                                marginLeft: "0!important",
                              }}
                              position="end"
                            >
                              $
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Box>
                  </Box>
                </Box>
                <Box
                  display={"flex"}
                  justifyContent={"flex-end"}
                  gap={1}
                  mt={2}
                >
                  <Button
                    onClick={setCustomRange}
                    size={"small"}
                    sx={{
                      height: "auto",
                      paddingY: "2px!important",
                    }}
                  >
                    Apply
                  </Button>
                  <Button
                    size={"small"}
                    variant={"tr_button_cancel"}
                    sx={{
                      height: "auto",
                      paddingY: "2px!important",
                    }}
                    onClick={closePopover}
                  >
                    Cancel
                  </Button>
                </Box>
              </Box>
            </Popover>
          </>
        )}
      </Box>
      {descriptiveLabel && (
        <Typography
          onClick={openPopover}
          sx={{
            cursor: "pointer",
            "&:hover": {
              color: "#2a7090",
            },
          }}
        >
          {descriptiveLabel}
        </Typography>
      )}
    </Box>
  ) : (
    <Typography>No options was provided</Typography>
  );
}
