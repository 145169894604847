import {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { TableHelpers } from "../../../../../../../../../../components/table/v2/Helpers/TableHelpers";
import {
  TableEventsV2,
  TableV2,
} from "../../../../../../../../../../components/table/v2/TableCoreV2";
import { TrendratingTableV2 } from "../../../../../../../../../../components/table/v2/TableV2";
import { useEnvironment } from "../../../../../../../../../../hooks/useEnvironment";

type SecuritiesTableProps = {
  data: any;
  type: "ETF" | "Stock";
  onSortChange: (value: { field: string; rev: boolean }) => void;
  currentSort: { field: string; rev: boolean };
  dt: number;
};

export const SecuritiesTable = memo(
  forwardRef(
    (
      { data, type, onSortChange, currentSort, dt }: SecuritiesTableProps,
      ref
    ) => {
      const [status, setStatus] = useState({ columns: false, built: false });
      const tableData = useMemo(() => data?.data ?? null, [data]);
      const tableRef = useRef<{ getInstance: () => TableV2 }>();

      const tableReady = useMemo(
        () => status.columns && status.built,
        [status.built, status.columns]
      );

      const tableBuilt = useMemo(() => status.built, [status.built]);

      const environment = useEnvironment();
      const setup = useMemo(() => {
        return environment.get("setup");
      }, [environment]);
      const tableHelper = useMemo(() => new TableHelpers(setup), [setup]);

      const onSort: any = useCallback(
        ({ field, direction }) => {
          const sortObj = {
            field,
            rev: direction === "desc",
          };

          onSortChange(sortObj);
        },
        [onSortChange]
      );

      const columns: any = useMemo(() => {
        const commonCols = tableHelper.generateFromFields(
          [
            "ticker",
            "name",
            "vc",
            "rc",
            "dr",
            type === "ETF" ? "etfclass" : "icb",
            "pr",
          ].map((colField) => ({
            field: colField,
          }))
        );

        return commonCols;
      }, [tableHelper, type]);

      const tableSetup = useMemo(
        () => ({ tableOption: { ajaxSorting: false } }),
        []
      );

      const toolsSetup = useMemo(() => {
        return {
          addToButton: true,
          pagination: { dataTotalCount: dt },
          rowsNumberSelect: {
            enabled: false,
            initialValue: dt,
          },
        };
      }, [dt]);

      const tableEvents: TableEventsV2 = useMemo(
        () => ({
          onTableBuilt: () =>
            setStatus((current) => ({ ...current, built: true })),
          onTableDestroyed: () =>
            setStatus((current) => ({ ...current, built: false })),
          columnsLoaded: (columns) => {
            if (columns.length) {
              setStatus((current) => ({ ...current, columns: true }));
            }
          },
          headerSort: onSort,
        }),
        [onSort]
      );

      useEffect(() => {
        const table = tableRef.current?.getInstance();

        if (tableBuilt) {
          table?.insertColumns(columns);
        }
      }, [columns, tableBuilt]);

      useEffect(() => {
        const table = tableRef.current?.getInstance();

        if (tableReady) {
          table?.insertData(tableData);
          table?.sort(
            currentSort.field,
            currentSort.rev === true ? "asc" : "desc"
          );
        }
      }, [currentSort, tableData, tableReady]);

      const table = useMemo(
        () => (
          <TrendratingTableV2
            ref={tableRef}
            rowTooltipFormatter
            tableOptions={tableSetup}
            tableEvents={tableEvents}
            tools={toolsSetup}
          />
        ),
        [tableEvents, tableSetup, toolsSetup]
      );

      return tableData ? (
        <div
          style={{
            minHeight: 0,
            minWidth: 0,
            padding: "5px",
            display: "flex",
            height: "100%",
          }}
        >
          {table}
        </div>
      ) : (
        <p>No data to display</p>
      );
    }
  )
);
