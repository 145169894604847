import { Environment } from "../../../../../../../Environment";
import { Instruments } from "../../../../../../../api/compute/Instruments";
import { Peers } from "../../../../../../../api/compute/Peers";
import { extractSymbols } from "../../../../../../../api/compute/commons";
import { widgetsConfiguration } from "../../../../../widgets/widgetsConfiguration";

export type AlternativesParams = {
  instrument: any;
  constraints: {
    filters: FilterStock | FilterETF;
    page: { page: 1; rows: 99999 };
    ranges: RangesStock | RangesETF;
  };
};

type FilterStock = [
  { dimension: "type"; segments: ["stock"] },
  { dimension: "country"; segments: string[] },
  { dimension: "icb"; segments: string[] }
];

type FilterETF = [
  { dimension: "type"; segments: ["ETF"] },
  { dimension: "etfclass"; segments: string[] },
  { dimension: "etfgeo"; segments: string[] }
];

type RangesStock = [
  {
    dimension: "rc";
    segments: { max: 0 | 1 | 2 | -1 | -2; min: 0 | 1 | 2 | -1 | -2 }[];
  },
  { dimension: "pr"; segments: { max: null | number; min: null | number }[] },
  {
    dimension: "marketcap";
    segments: { max: null | number; min: null | number }[];
  }
];

type RangesETF = [
  {
    dimension: "rc";
    segments: { max: 0 | 1 | 2 | -1 | -2; min: 0 | 1 | 2 | -1 | -2 }[];
  },
  { dimension: "pr"; segments: { max: null | number; min: null | number }[] }
];

/**
 * Helper class useful to manage all necessary data about alternativesTab
 */
export class AlternativesDataManager {
  environment: Environment;
  apiInstrument: Instruments;
  apiPeers: Peers;
  SECURITY_FETCH_PROPERTIES: { date: null; property: string }[];

  constructor(environment) {
    this.environment = environment;
    this.apiInstrument = environment.get("http")["instruments"];
    this.apiPeers = environment.get("http")["peers"];
    this.SECURITY_FETCH_PROPERTIES =
      widgetsConfiguration["widgets/security/Tooltip"]["properties"];
  }

  rankingTemplatesList: any = null;

  /**
   *
   * @param symbol
   * @returns {Promise}
   *
   * getInstrumentData returns a promise with the analytics of a list of symbols.
   */
  public async getInstrumentData(
    positions: { symbol: string; weight: number }[]
  ) {
    if (positions == null || !positions.length) {
      return [];
    }

    const apiInstrument = this.apiInstrument;

    const propertiesFetch = this.SECURITY_FETCH_PROPERTIES;
    const positionsTypes = ["Stock", "ETF"];

    const params: any = {
      filters: [
        {
          dimension: "type",
          segments: positionsTypes,
        },
        {
          dimension: "symbol",
          segments: extractSymbols(positions),
        },
      ],
      ranges: [
        {
          dimension: "rc",
          segments: [{ max: -1, min: -2 }],
        },
      ],
      page: {
        page: 1,
        rows: 100000,
      },
    };

    const instruments = await apiInstrument.filterAndFetch(
      params,
      "security",
      propertiesFetch
    );

    return instruments?.data;
  }

  public async getBetterAlternatives(params: AlternativesParams) {
    const apiInstrument = this.apiInstrument;

    const response = await apiInstrument.alternativesTo(params);

    if (response["data"].length === 0) {
      return response;
    }
    var properties;
    var instrumentType = params["instrument"]["type"].toLowerCase();
    var widgetConfiguration = widgetsConfiguration["widgets/analysis/security"];

    switch (instrumentType) {
      case "etf":
      case "stock": {
        properties = widgetConfiguration["properties_" + instrumentType];
        break;
      }
      default: {
        properties = widgetConfiguration["properties"];
      }
    }

    const alternatives = await apiInstrument.fetch({
      properties: properties,
      symbols: response["data"],
      type: "security",
    });

    return alternatives?.data;
  }
}
