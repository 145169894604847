import { Box, Card, CardContent } from "@mui/material";
import React, { useMemo } from "react";
import { useEnvironment } from "../../../../../../../../../../../hooks/useEnvironment";
import { useFormatter } from "../../../../../../../../../../../hooks/useFormatter";
import { TransparencyButton } from "../../../../../../Transparency/TransparencyComponents";
import {
  _formatPeerName,
  _formatPercentage,
  _getHoldingsWeightedPerformanceNumDen,
} from "./utils";

type AllocationProps = {
  state: any;
  // getRationaleData: any;
  // getRationaleBase: any;
  hideRationaleBtn?: boolean;
};

export default function Allocation({
  state,
  // getRationaleData,
  // getRationaleBase,
  hideRationaleBtn = false,
}: AllocationProps) {
  // const [rationale, setRationale] = useState(null);
  // const [loadingRationale, setLoadingRationale] = useState(false);
  // const [showRationale, setShowRationale] = useState(false);
  const environment = useEnvironment();
  const format = useFormatter();
  const strategy = useMemo(() => {
    return state.strategy;
  }, [state.strategy]);
  const currentAllocationIdx = useMemo(() => {
    return state.frame.frameIndex;
  }, [state.frame.frameIndex]);
  const POS = useMemo(
    () => state.strategyResult.POS,
    [state.strategyResult.POS]
  );
  const component = useMemo(
    () => _renderBoxAllocation(state, format, environment),
    [environment, format, state]
  );

  const rebalanceDate = useMemo(() => {
    return POS[currentAllocationIdx]?.d;
  }, [POS, currentAllocationIdx]);

  const exAntePortfolio = useMemo(() => {
    let allocation = POS[currentAllocationIdx];
    let exAnte = { d: allocation.d, v: [] };

    if (currentAllocationIdx > 0) {
      let exantePos = POS[currentAllocationIdx - 1];
      let exAntePortfolio: any = [];
      let exAnteSecurities: any = [];
      for (let i = 0, N = exantePos.v.length; i < N; i++) {
        exAntePortfolio.push({ S: exantePos.v[i].S, A: exantePos.v[i].A });
        exAnteSecurities.push(exantePos.v[i].S);
      }
      exAnte = { d: exantePos.d, v: exAntePortfolio };
    }

    return exAnte;
  }, [POS, currentAllocationIdx]);

  return (
    <Card sx={{ boxShadow: 3, flex: 2 }}>
      <CardContent
        sx={{
          display: "flex",
          justifyContent: "space-between",
          p: 1,
          pb: "8px !important",
        }}
      >
        <Box display={"flex"} flexDirection="column">
          {component.map((item, index) => (
            <React.Fragment key={index}>{item}</React.Fragment>
          ))}
        </Box>
        {hideRationaleBtn === false && (
          <Box display={"flex"} flexDirection={"column"} gap={1}>
            <TransparencyButton
              rebalanceDate={rebalanceDate}
              exAntePortfolio={exAntePortfolio}
              strategy={strategy}
            />
            {/* <Button onClick={getDataForRationale} size="small">
              Rationale
            </Button> */}
          </Box>
        )}
      </CardContent>
    </Card>
  );
}

const _renderBoxAllocation = (state, format, environment) => {
  var frame = state?.frame;
  let output: React.ReactElement[] = [];
  if (frame) {
    output.push(
      <div>
        Allocation:{" "}
        <strong>{_formatPercentage(frame.allocation, format)}</strong>
      </div>
    );
  } else {
    output.push(_formatPercentage(0, format));
  }

  var peer = state?.contributionsFiltered?.peer;
  var holdings = state?.contributionsFiltered?.holdings;
  if (peer && holdings) {
    var numDen = _getHoldingsWeightedPerformanceNumDen(holdings);

    output.push(
      <div>
        {_formatPeerName(peer, environment)}
        {": "} <strong>{_formatPercentage(numDen.denominator, format)}</strong>
      </div>
    );
  }
  return output;
};
