import { Box } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Instruments } from "../../../../../api/compute/Instruments";
import { getIntervalLabel } from "../../../../../components/SecurityTooltip/SecurityTooltipCore";
import { useEnvironment } from "../../../../../hooks/useEnvironment";
import { widgetsConfiguration } from "../../../widgets/widgetsConfiguration";
import SecurityChartModal from "../../../../../components/SecurityChartModal/SecurityChartModal";
import { SecurityPriceChart } from "../../../../../components/SecurityPriceChart/SecurityPriceChart";

type SecurityPanelProps = {
  symbol: string;
};

export function SecurityPanel({ symbol }: SecurityPanelProps) {
  const [securityInfo, setSecurityInfo] = useState<any>();
  const [logoPath, setLogoPath] = useState<string>("");
  const [error, setError] = useState(false);
  const [expandChart, setExpandChart] = useState(false);

  const environment = useEnvironment();
  const widgetConfiguration = useMemo(
    () => widgetsConfiguration["widgets/security/PerformanceOverview"],
    []
  );

  const appSetup = useMemo(() => {
    return environment.get("setup");
  }, [environment]);

  const taxonomies = useMemo(() => appSetup.taxonomies, [appSetup.taxonomies]);
  const taxonomiesFields = useMemo(
    () => appSetup.taxonomyFields,
    [appSetup.taxonomyFields]
  );

  const apiInstrument = useMemo(() => new Instruments(appSetup), [appSetup]);

  const getSecurityInfo = useCallback(async () => {
    try {
      setError(false);
      var properties = widgetConfiguration["properties"];

      const etfProperty = [
        { date: null, property: "etfgeo" },
        { date: null, property: "etfclass" },
      ];

      properties.push(...etfProperty);

      const response = await apiInstrument.fetch({
        properties: properties,
        symbols: [symbol],
        type: "security",
      });

      const security = response["data"][0];

      setSecurityInfo(security);
    } catch (error) {
      console.log(error);
      setError(true);
    }
  }, [apiInstrument, symbol, widgetConfiguration]);

  const getLogo = useCallback(async () => {
    const logoImg = await apiInstrument.logo({ symbol });

    setLogoPath(logoImg ?? "");
  }, [apiInstrument, symbol]);

  const name = useMemo(() => securityInfo?.name ?? "", [securityInfo?.name]);
  const market = useMemo(() => {
    if (securityInfo) {
      const taxonMap = taxonomies[taxonomiesFields["security"]["country"]];

      const mktNode = taxonMap[securityInfo["country"]];

      return (
        mktNode ?? {
          id: securityInfo["country"],
          name: securityInfo["country"],
        }
      );
    }

    return { id: "", name: "" };
  }, [securityInfo, taxonomies, taxonomiesFields]);

  const country = useMemo(() => {
    if (securityInfo) {
      const field = "domicile";

      const taxonMap = taxonomies[taxonomiesFields["security"][field]];
      const countryNode = taxonMap[securityInfo["domicile"]];

      return (
        countryNode ?? {
          id: securityInfo["domicile"],
          name: securityInfo["domicile"],
        }
      );
    }

    return { id: "", name: "" };
  }, [securityInfo, taxonomies, taxonomiesFields]);

  const sector = useMemo(() => {
    if (securityInfo) {
      const field = securityInfo.type === "ETF" ? "etfclass" : "icb";
      const type = securityInfo.type === "ETF" ? "ETF" : "security";

      const taxonMap = taxonomies[taxonomiesFields[type][field]];

      const sectorNode = taxonMap[securityInfo[field]];

      return (
        sectorNode ?? { id: securityInfo[field], name: securityInfo[field] }
      );
    }

    return { id: "", name: "" };
  }, [securityInfo, taxonomies, taxonomiesFields]);

  const subtitleInnerHTML = useMemo(() => {
    let innerHTML = "";

    if (securityInfo?.type === "Stock") {
      innerHTML =
        market.id !== country.id
          ? market.name + " (" + country.name + ")"
          : market.name;
      innerHTML +=
        " | " +
        getIntervalLabel(securityInfo?.marketcap, true, environment) +
        (sector ? " | " + sector.name : "");
    } else {
      innerHTML = securityInfo?.type + " | " + market.name;
    }

    return innerHTML;
  }, [
    country.id,
    country.name,
    environment,
    market.id,
    market.name,
    sector,
    securityInfo?.marketcap,
    securityInfo?.type,
  ]);

  useEffect(() => {
    getSecurityInfo();
    getLogo();
  }, [getLogo, getSecurityInfo]);

  return (
    <Box display={"flex"} flex={1} position={"relative"}>
      <SecurityChartModal
        security={securityInfo}
        onClose={() => setExpandChart(false)}
        showModal={expandChart}
        environment={environment}
      />
      {error ? (
        <></>
      ) : (
        <>
          <div
            data-dojo-attach-point="_nodeRoot"
            style={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
              width: "100%",
              overflow: "visible",
            }}
          >
            <div>
              <h1 className="security-panel__ticker-name">
                <span
                  className="security-panel__ticker"
                  data-dojo-attach-point="_nodeTicker"
                >
                  {securityInfo?.ticker ?? ""}
                </span>
                <span
                  className="security-panel__name"
                  data-dojo-attach-point="_nodeName"
                >
                  {name}
                </span>
              </h1>
              {logoPath && (
                <div
                  className="security-panel__logo-container"
                  data-dojo-attach-point="_nodeLogo"
                >
                  <div
                    className="security-panel__logo"
                    style={{ backgroundImage: "url(" + logoPath + ")" }}
                  ></div>
                </div>
              )}
              <p className="security-panel__info">
                <span
                  data-dojo-attach-point="_nodeInfo"
                  dangerouslySetInnerHTML={{ __html: subtitleInnerHTML }}
                ></span>
              </p>
            </div>
            <div className="security-panel__chart">
              <span
                onClick={() => setExpandChart(!expandChart)}
                className="i-enlarge button--expand button--expand-screening"
                data-dojo-attach-point="_nodeChartExpand"
                title="Expand chart"
              ></span>
              <div
                style={{ height: "100%", width: "100%", overflow: "visible" }}
              >
                <SecurityPriceChart symbol={symbol} />
              </div>
            </div>
          </div>
        </>
      )}
    </Box>
  );
}
