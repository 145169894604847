import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  CircularProgress,
  Fade,
  FormControlLabel,
  FormGroup,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import List from "../../../../../../../../../../components/DragAndDropList/List";
import Modal from "../../../../../../../../../../components/Modal/Modal";
import {
  TableEventsV2,
  TableV2,
} from "../../../../../../../../../../components/table/v2/TableCoreV2";
import { TrendratingTableV2 } from "../../../../../../../../../../components/table/v2/TableV2";
import { useBroadcast } from "../../../../../../../../../../hooks/useBroadcast";
import { useEnvironment } from "../../../../../../../../../../hooks/useEnvironment";
import { useFormatter } from "../../../../../../../../../../hooks/useFormatter";
import { TDate } from "../../../../../../../../../../trendrating/date/TDate";
import { config } from "../../../../../../../../config-ts";
import { Product } from "../../../../../../../../storage/SystematicPortfoliosStorage";
import { messageError, messageSuccess } from "../../../../../../../../utils";
import { useActionModal } from "../../../../../../../../utils/useActionModal";
import Search from "../../../../../../../../widgets/ReactSearch/Search";
import { AllocationEditor } from "./AllocationEditor/AllocationEditor";
import styles from "./HistoricalAllocation.module.scss";
import { RowComponent } from "tabulator-tables";

type ColumnsEditorProps = {
  onColumnsChange: (olumns: string[]) => void;
  show: boolean;
  close: () => void;
};

type HistoricalAllocationProps = {
  product: Product;
  editable?: boolean;
};

export function HistoricalAllocation({
  product,
  editable = true,
}: HistoricalAllocationProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [tableReady, setTableReady] = useState(false);
  const [isReadOnly, setIsReadOnly] = useState(false);
  const { t } = useTranslation();
  const formatter = useFormatter();
  const [needRefresh, setNeedRefresh] = useState(false);
  const [anchorEl, setAnchorEl] = useState<any>();
  const [showColumnEditor, setShowColumnEditor] = useState(false);

  const closeColumnEditor = useCallback(() => {
    setShowColumnEditor(false);
  }, []);

  const open = useMemo(() => Boolean(anchorEl), [anchorEl]);

  const { setShowModal, setContent, setModalTitle, setOptions } =
    useActionModal();
  const { broadcast } = useBroadcast();

  const tableRef = useRef<{ getInstance: () => TableV2 }>();
  const rowActionsTarget = useRef<any>();

  const environment = useEnvironment();

  const configuration = useMemo(() => {
    return environment.get("account").product.configuration;
  }, [environment]);

  const defaultColumns = useMemo(() => {
    const config = configuration?.["systematic_portfolios"]?.["tabs"];

    if (config) {
      const historicalTabConfiguration = config.find(
        (tab) => tab.id === "historicalPortfolios"
      );

      if (historicalTabConfiguration) {
        const columnsDefault =
          historicalTabConfiguration.widgets.viewer.table.columns.security.map(
            (col) => col.field
          );

        return columnsDefault;
      }
    }

    return [];
  }, [configuration]);

  const activeColumns = useRef<string[]>(defaultColumns);

  const getCurrentData = useCallback(() => {
    return collectionData.current;
  }, []);

  const sortByTaxon = useCallback(
    (aRow: any, bRow: any, dir, field: string, etf?: boolean) => {
      const aData = aRow.getData();
      const bData = bRow.getData();

      let aValue = "";
      let bValue = "";

      if (field === "industry" || field === "sector" || etf === true) {
        aValue = formatter.table(field, "table", aData);
        bValue = formatter.table(field, "table", bData);

        if (aData[etf === true ? field : "icb"] == null) {
          return dir === "asc" ? 1 : -1;
        }

        if (bData[etf === true ? field : "icb"] == null) {
          return dir === "asc" ? -1 : 1;
        }
      } else {
        const taxonomy = environment.get("rawTaxonomies");
        const fields = environment.get("taxonomyFields");

        const theTaxon = taxonomy[fields[etf ? "ETF" : "security"][field]];

        aValue = formatter.custom("taxon", {
          options: {
            notAvailable: {
              input: null,
              output: "",
            },
            taxonomy: theTaxon,
          },
          output: "TEXT",
          value: aData[field],
          valueHelper: null,
        });

        bValue = formatter.custom("taxon", {
          options: {
            notAvailable: {
              input: null,
              output: "",
            },
            taxonomy: theTaxon,
          },
          output: "TEXT",
          value: bData[field],
          valueHelper: null,
        });

        if (aValue === "") {
          return dir === "asc" ? 1 : -1;
        }

        if (bValue === "") {
          return dir === "asc" ? -1 : 1;
        }
      }

      if (aValue > bValue) {
        return -1;
      } else if (aValue < bValue) {
        return 1;
      }

      return 0;
    },
    [environment, formatter]
  );

  const getData = useCallback(async () => {
    try {
      const productObj = await product.history();

      if (productObj?.history) {
        collectionData.current = productObj.history["prepared"]["collection"];
        return productObj.history;
      }
    } catch (error) {
      console.log(error);
    }
  }, [product]);

  const datePropertyToDate = useCallback((dateProperty) => {
    return parseInt(dateProperty.replace("date_", ""));
  }, []);

  const properties = useMemo(() => {
    const properties = environment.get("properties").properties;
    const flatProperties = {};

    for (const key in properties) {
      for (const property in properties[key]) {
        flatProperties[property] = properties[key][property];
      }
    }

    return flatProperties;
  }, [environment]);

  const formatDate = useCallback(
    (value) => {
      var formatted = formatter.custom("date", {
        options: {
          notAvailable: {
            input: null,
            output: "",
          },
        },
        output: "HTML",
        value: value,
        valueHelper: null,
      });

      return formatted;
    },
    [formatter]
  );

  const formatWeight = useCallback(
    (cell) => {
      const cellElement = cell.getElement();
      const data = cell.getData();

      if (data.status === "edited") {
        cellElement.classList.add(styles.edited);
      }

      const value = cell.getValue();

      var innerHTML = formatter.custom("number", {
        options: {
          isPercentage: true,
          notAvailable: {
            input: null,
            output: "",
          },
          zero: false,
        },
        output: "HTML",
        value: value,
        valueHelper: null,
      });

      return innerHTML;
    },
    [formatter]
  );

  const styleAndFormatFirstWeightCell = useCallback(
    (cell) => {
      const cellHTML = cell.getElement();

      cellHTML.style.borderLeft = "2px solid #2a7090";

      return formatWeight(cell);
    },
    [formatWeight]
  );

  const styleCasses = useMemo(
    () => ({
      expired: styles.expired,
      edited: styles.edited,
      rebalanced: styles.rebalanced,
      unavailable: styles.unavailable,
    }),
    []
  );

  const formatName = useCallback(
    (cell) => {
      const name = cell.getValue();
      const status = cell.getData()["status"];

      if (status === "expired") {
        return `<p title="Expired Stock" class=${styleCasses[status]}>${name}</p>`;
      }

      if (status === "unavailable") {
        return `<p title="Unavailable Stock" class=${styleCasses[status]}>${name}</p>`;
      }

      return `<p class=${styleCasses[status]}>${name}</p>`;
    },
    [styleCasses]
  );

  const refreshData = useCallback(async () => {
    setNeedRefresh(true);
  }, []);

  const openModal = useCallback(
    (content: JSX.Element) => {
      setOptions({
        stylesOptions: { bodyCustomClass: styles.modalBodyOverride },
      });
      setShowModal(true);
      setContent(content);
    },
    [setContent, setOptions, setShowModal]
  );

  const removeFeedback = useCallback(
    (date) => {
      const formattedDate = formatter.custom("date", {
        options: {
          notAvailable: {
            input: null,
            output: "",
          },
        },
        output: "HTML",
        value: date,
        valueHelper: null,
      });
      const text = `<strong>${formattedDate}</strong> has been deleted.`;

      const [channel, msg] = messageSuccess(text);
      broadcast(channel as string, msg);
    },
    [broadcast, formatter]
  );

  const saveFeedback = useCallback(
    (operationType: "column" | "row", params) => {
      var text = t("c_unknown");

      switch (operationType) {
        case "column": {
          const formattedDate = formatter.custom("date", {
            options: {
              notAvailable: {
                input: null,
                output: "",
              },
            },
            output: "HTML",
            value: parseInt(params.date.replace("date_", "")),
            valueHelper: null,
          });
          text = `<strong>${formattedDate}</strong> has been updated.`;

          break;
        }
        case "row": {
          if (params["target"] == null) {
            text = `<strong>${params["source"]["name"]}</strong> has been deleted.`;
          } else {
            text = `<strong>${params["target"]["name"]}</strong> has replaced <strong>${params["source"]["name"]}</strong>`;
          }
          break;
        }

        // no default
      }

      const [channel, msg] = messageSuccess(text);
      broadcast(channel as string, msg);
    },
    [broadcast, formatter, t]
  );

  const closeModal = useCallback(() => {
    setShowModal(false);
  }, [setShowModal]);

  const saveAllocation = useCallback(
    async (value) => {
      try {
        const response = await product.updateAllocation(value);

        //************************** USAGE *****************************
        var info = {
          action: "EDIT_HISTORY",
          actionParam: response.id,
          function: "SYSTEMATIC_PORTFOLIO",
        };
        var usage = window.App.usage;
        usage.record(info);
        //************************** USAGE *****************************

        closeModal();
        saveFeedback("column", { date: value.property });

        // Trigger refresh
        refreshData();
      } catch (error) {
        let errorMsg = "Cannot save changes, please retry.";
        if (getCurrentData().length === 0) {
          errorMsg = "Cannot save changes, allocation is empty";
        }
        const [channel, msg] = messageError(errorMsg);
        broadcast(channel as string, msg);
        closeModal();
      }
    },
    [broadcast, closeModal, getCurrentData, product, refreshData, saveFeedback]
  );

  const saveInsertAllocation = useCallback(
    async (value) => {
      try {
        const response = await product.addAllocation(value);

        //************************** USAGE *****************************
        var info = {
          action: "EDIT_HISTORY",
          actionParam: response.id,
          function: "SYSTEMATIC_PORTFOLIO",
        };
        var usage = window.App.usage;
        usage.record(info);
        //************************** USAGE *****************************

        closeModal();
        saveFeedback("column", { date: value.property });

        // Trigger refresh
        refreshData();
      } catch (error) {
        let errorMsg = "Cannot save changes, please retry.";
        if (value.collection.length === 0) {
          errorMsg = "Cannot save changes, allocation is empty";
        }
        const [channel, msg] = messageError(errorMsg);
        broadcast(channel as string, msg);
        closeModal();
      }
    },
    [broadcast, closeModal, product, refreshData, saveFeedback]
  );

  const listenerRemove = useCallback(
    async (dateProperty) => {
      try {
        await product.removeAllocation(dateProperty);
        closeModal();
        removeFeedback(datePropertyToDate(dateProperty));

        refreshData();
      } catch (error) {
        closeModal();

        const [channel, msg] = messageError(
          `Cannot remove allocation at ${formatDate(
            datePropertyToDate(dateProperty)
          )}`
        );

        broadcast(channel as string, msg);
      }
    },
    [
      broadcast,
      closeModal,
      datePropertyToDate,
      formatDate,
      product,
      refreshData,
      removeFeedback,
    ]
  );

  const insertAllocation = useCallback(
    (e, col) => {
      setModalTitle("Insert allocation");
      const property = col.field;
      const table = tableRef.current?.getInstance();

      const data = table?.getRows().map((row) => row.getData()) ?? [];

      openModal(
        <AllocationEditor
          onCancel={closeModal}
          onSave={saveInsertAllocation}
          collection={data}
          property={property}
          mode="insert"
          startEmpty={true}
        />
      );
    },
    [closeModal, openModal, saveInsertAllocation, setModalTitle]
  );

  const editAllocation = useCallback(
    (e, col) => {
      const table = tableRef.current?.getInstance();

      const collection = table?.getRows().map((row) => row.getData()) ?? [];
      const property = col.field;

      setModalTitle(`Edit ${col.title}`);
      openModal(
        <AllocationEditor
          mode="edit"
          onCancel={closeModal}
          onSave={saveAllocation}
          collection={collection}
          property={property}
        />
      );
    },
    [closeModal, openModal, saveAllocation, setModalTitle]
  );

  const removeAllocation = useCallback(
    (e, col) => {
      setShowModal(true);
      setModalTitle(`Remove ${col.title}`);
      setContent(
        <RemoveColumnModal
          onRemove={listenerRemove}
          onCancel={closeModal}
          property={col.field}
        />
      );
    },
    [closeModal, listenerRemove, setContent, setModalTitle, setShowModal]
  );

  const onReplaceInstrumentsSave = useCallback(
    async (target, source) => {
      try {
        await product.replaceInstrument(source, target);

        closeModal();
        saveFeedback("row", { target, source });

        refreshData();
      } catch (error) {
        closeModal();
      }
    },
    [closeModal, product, refreshData, saveFeedback]
  );

  const duplicateAllocation = useCallback(
    (e, col) => {
      setModalTitle("Insert allocation");

      const table = tableRef.current?.getInstance();
      const data = table?.getRows().map((row) => row.getData()) ?? [];

      openModal(
        <AllocationEditor
          onCancel={closeModal}
          onSave={saveInsertAllocation}
          collection={data}
          property={col.field}
          mode="insert"
        />
      );
    },
    [closeModal, openModal, saveInsertAllocation, setModalTitle]
  );

  const actionImport = useCallback(() => {
    setModalTitle("Insert allocation");

    const yesterday = TDate.yesterday() - 1;
    const property = "date_" + yesterday;

    openModal(
      <AllocationEditor
        onCancel={closeModal}
        onSave={saveInsertAllocation}
        collection={getCurrentData()}
        property={property}
        mode="insert"
      />
    );
  }, [
    closeModal,
    getCurrentData,
    openModal,
    saveInsertAllocation,
    setModalTitle,
  ]);

  const onDeallocation = useCallback(() => {
    const instrument = rowActionsTarget.current;

    if (instrument) {
      setAnchorEl(undefined);
      setModalTitle(`Deallocate ${instrument.name}`);
      setShowModal(true);
      setContent(
        <Box>
          <Typography>Are you sure to deallocate {instrument.name}</Typography>
          <Box display={"flex"} gap={2} p={1} justifyContent={"flex-end"}>
            <Button
              variant="contained"
              onClick={() => onReplaceInstrumentsSave(null, instrument)}
            >
              Save
            </Button>
            <Button variant="tr_button_cancel" onClick={closeModal}>
              Cancel
            </Button>
          </Box>
        </Box>
      );
    }
  }, [
    closeModal,
    onReplaceInstrumentsSave,
    setContent,
    setModalTitle,
    setShowModal,
  ]);

  const onReplaceInstruments = useCallback(() => {
    const instrument = rowActionsTarget.current;

    if (instrument) {
      setAnchorEl(undefined);
      setModalTitle(`Replace ${instrument.name}`);
      setShowModal(true);
      setContent(
        <ReplaceModalContent
          onSave={(target) => onReplaceInstrumentsSave(target, instrument)}
          onCancel={closeModal}
        />
      );
    }
  }, [
    closeModal,
    onReplaceInstrumentsSave,
    setContent,
    setModalTitle,
    setShowModal,
  ]);

  const handleHeaderMenuClick = useCallback(
    (e, col, tag) => {
      switch (tag) {
        case "edit": {
          editAllocation(e, col.getDefinition());

          break;
        }
        case "insert": {
          insertAllocation(e, col.getDefinition());

          break;
        }
        case "duplicate": {
          duplicateAllocation(e, col.getDefinition());

          break;
        }
        case "remove": {
          removeAllocation(e, col.getDefinition());

          break;
        }

        default:
          console.log(`unhandled action: ${tag}`);
      }
    },
    [duplicateAllocation, editAllocation, insertAllocation, removeAllocation]
  );

  const readOnlyMenu = useMemo(() => {
    return [
      {
        label: "&#8593; Sort Ascending",
        action: (e, col) => {
          const table = tableRef.current?.getInstance();
          const field = col.getDefinition().field;
          table?.sort(field, "asc");
        },
      },
      {
        label: "&#8595; Sort Descending",
        action: (e, col) => {
          const table = tableRef.current?.getInstance();
          const field = col.getDefinition().field;
          table?.sort(field, "desc");
        },
      },
    ];
  }, []);

  const headerMenu = useMemo(() => {
    return [
      {
        label: "&#9019; Edit Allocation",
        action: (e, col) => handleHeaderMenuClick(e, col, "edit"),
      },
      {
        label: "&#8703; Insert Allocation",
        action: (e, col) => handleHeaderMenuClick(e, col, "insert"),
      },
      {
        label: "&#9707; Duplicate Allocation",
        action: (e, col) => handleHeaderMenuClick(e, col, "duplicate"),
      },
      {
        label: "&#8998; Remove Allocation",
        action: (e, col) => handleHeaderMenuClick(e, col, "remove"),
      },
      {
        label: "&#8593; Sort Ascending",
        action: (e, col) => {
          const table = tableRef.current?.getInstance();
          const field = col.getDefinition().field;
          table?.sort(field, "asc");
        },
      },
      {
        label: "&#8595; Sort Descending",
        action: (e, col) => {
          const table = tableRef.current?.getInstance();
          const field = col.getDefinition().field;
          table?.sort(field, "desc");
        },
      },
    ];
  }, [handleHeaderMenuClick]);

  const dataToTable = useCallback(async () => {
    setIsLoading(true);
    const portfolio = await getData();
    const table = tableRef.current?.getInstance();

    if (portfolio != null) {
      const data: any = [];

      if (!portfolio?.prepared?.collection) {
        setIsLoading(false);
        return;
      }

      for (const row of portfolio?.prepared?.collection) {
        for (const key in row) {
          if (row[key] === 0) {
            row[key] = null;
          }
        }

        data.push(row);
      }

      const columnsMnemonic = [...portfolio?.prepared?.dateProperties];

      if (data) {
        // Default columns that identify a security
        const columns: any[] = [
          {
            title: "",
            headerSort: false,
            frozen: true,
            hozAlign: "right",
            formatter: "rownum",
            titleFormatter: (cell, p, onRendered) => {
              const btn = `<div id="columnEditBtnHsPortfolio" class="tableColumnConfigurator">
                              <button
                                      class="${styles.openDialogButton}"
                                      title="Customize table fields"
                                    >
                                      <span class="i-edit"></span>
                                    </button>
                                  </div>`;

              onRendered(() => {
                const btn = document.getElementById("columnEditBtnHsPortfolio");

                if (btn) {
                  btn.addEventListener("click", () =>
                    setShowColumnEditor(true)
                  );
                }
              });

              return btn;
            },
          },
        ];

        for (const field of activeColumns.current) {
          switch (field) {
            case "ticker":
              columns.push({
                title: "Ticker",
                field: "ticker",
                sorter: "string",
                frozen: true,
                minWidth: 60,
              });

              break;

            case "name":
              columns.push({
                title: "Name",
                minWidth: 180,
                field: "name",
                sorter: "string",
                formatter: (cell) => formatName(cell),
                frozen: true,
              });

              break;

            case "currency":
              columns.push({
                minWidth: 120,
                field: "currency",
                hozAlign: "left",
                sorterParams: { alignEmptyValues: "bottom" },
                sorter: "string",
                frozen: true,
              });

              break;

            case "sector":
              columns.push({
                minWidth: 180,
                field: "sector",
                frozen: true,
                sorterParams: { alignEmptyValues: "bottom" },
                sorter: (a, b, aRow, bRow, column, dir) =>
                  sortByTaxon(aRow, bRow, dir, "sector"),
              });

              break;

            case "country":
              columns.push({
                minWidth: 180,
                field: "country",
                sorterParams: { alignEmptyValues: "bottom" },
                frozen: true,
                sorter: (a, b, aRow, bRow, col, dir) =>
                  sortByTaxon(aRow, bRow, dir, "country"),
              });

              break;

            case "industry":
              columns.push({
                minWidth: 180,
                field: "industry",
                sorterParams: { alignEmptyValues: "bottom" },
                sorter: (a, b, aRow, bRow, col, dir) =>
                  sortByTaxon(aRow, bRow, dir, "industry"),
                frozen: true,
              });

              break;

            case "etfclass":
              columns.push({
                minWidth: 180,
                sorterParams: { alignEmptyValues: "bottom" },
                field: "etfclass",
                title: properties?.["etfclass"]?.name?.[1] ?? undefined,
                sorter: (a, b, aRow, bRow, col, dir) =>
                  sortByTaxon(aRow, bRow, dir, "etfclass", true),
                frozen: true,
              });

              break;
            case "etfgeo":
              columns.push({
                minWidth: 180,
                sorterParams: { alignEmptyValues: "bottom" },
                field: "etfgeo",
                title: properties?.["etfgeo"]?.name?.[1] ?? undefined,
                frozen: true,
                sorter: (a, b, aRow, bRow, col, dir) =>
                  sortByTaxon(aRow, bRow, dir, "etfgeo", true),
              });

              break;
          }
        }

        let dateToFormat = 0;
        let dateFormatted = "";
        let dateStr: any = null;
        const cols = columnsMnemonic.reverse();
        for (let i = 0; i < cols.length; i++) {
          dateStr = cols[i];
          dateToFormat = datePropertyToDate(dateStr);
          dateFormatted = formatDate(dateToFormat);

          columns.push({
            field: dateStr,
            title: dateFormatted,
            sorter: "number",
            minWidth: 120,
            sorterParams: { alignEmptyValues: "bottom" },
            formatter: i === 0 ? styleAndFormatFirstWeightCell : formatWeight,
            headerMenu: isReadOnly ? readOnlyMenu : headerMenu,
            titleFormatter: (cell, p, onRendered) => {
              const headerEl = cell.getElement();
              headerEl.classList.add(styles.headerWithMenu);

              return cell.getValue();
            },
            headerMenuIcon: `<span style="font-size: 0.8vw">&#9776;</span>`,
          });
        }
        table?.insertColumns(columns);
        table?.freezeColumn("idx");
        table?.insertData(data);
        table?.sort("name", "asc");
      }

      setIsLoading(false);
    }
  }, [
    datePropertyToDate,
    formatDate,
    formatName,
    formatWeight,
    getData,
    headerMenu,
    isReadOnly,
    properties,
    readOnlyMenu,
    sortByTaxon,
    styleAndFormatFirstWeightCell,
  ]);

  const collectionData = useRef<any>(undefined);

  useEffect(() => {
    if (tableReady) {
      dataToTable();
    }
  }, [dataToTable, tableReady]);

  // Handle table refresh
  useEffect(() => {
    if (needRefresh) {
      dataToTable();

      setNeedRefresh(false);
    }
  }, [dataToTable, needRefresh]);

  useEffect(() => {
    const info = product.info();

    if (editable === true) {
      setIsReadOnly(info.isReadOnly);
    } else {
      setIsReadOnly(true);
    }
  }, [editable, product]);

  useEffect(() => {
    var message = {
      from: "systematicProducts",
      content: {
        actions: [
          {
            componentJSX: (
              <li className={"menu__item"} onClick={actionImport}>
                Import
              </li>
            ),
          },
        ],
      },
    };

    if (isReadOnly) {
      message = {
        from: "systematicProducts",
        content: {
          actions: [],
        },
      };
    }
    broadcast(config["channels"]["workflow"]["input"], message);
  }, [actionImport, broadcast, isReadOnly]);

  const closeRowCtxMenu = useCallback(() => {
    setAnchorEl(undefined);
    rowActionsTarget.current = undefined;
  }, []);

  const handleRowClick = useCallback((e, row) => {
    if (e.target) {
      if (e.target.classList.contains("i-edit_allocation")) {
        row.deselect();
        setAnchorEl(e.target);
        rowActionsTarget.current = row.getData();
      }
    }
  }, []);

  const tableEvents: TableEventsV2 = useMemo(() => {
    return {
      onTableBuilt: () => setTableReady(true),
      onTableDestroyed: () => setTableReady(false),
      dataLoaded: () => setIsLoading(false),
      rowClick: handleRowClick,
    };
  }, [handleRowClick]);

  const tableOptions = useMemo(() => {
    return {
      tableOption: {
        selectableRows: 1,
        renderHorizontal: "virtual", //enable horizontal virtual DOM
        rowFormatter: (row: RowComponent) => {
          if (!isReadOnly) {
            const rowElement = row.getElement();
            rowElement.classList.add(styles.rowHistoricalTable);
            const menu = document.createElement("p");
            menu.innerHTML = "&#9776;";

            rowElement.style.position = "relative";

            menu.classList.add("i-edit_allocation");
            menu.style.position = "absolute";
            menu.style.zIndex = "3";
            menu.style.top = "20%";
            menu.style.left = "5px";
            menu.style.fontSize = "0.8vw";

            const cells = row.getCells();
            const firstCell = cells[0];
            const cellElement = firstCell.getElement();
            cellElement.classList.add(styles.cellIdx);
            cellElement.append(menu);
          }
        },
      },
    };
  }, [isReadOnly]);

  const editColumns = useCallback(
    (columns: string[]) => {
      const table = tableRef.current?.getInstance();

      if (table) {
        const currentColumns = table
          .getColumns()
          .map((col) => col.getDefinition());
        const newColumns: any[] = [];

        for (const col of currentColumns) {
          // Filter all identifiers columns
          if (!activeColumns.current.includes(col.field!)) {
            newColumns.push(col);
          }
        }

        // Insert at start the new columns
        for (const col of [...columns].reverse()) {
          let column: any = { field: col };

          switch (col) {
            case "ticker":
              column = {
                title: "Ticker",
                field: "ticker",
                sorter: "string",
                frozen: true,
                minWidth: 60,
              };

              break;

            case "name":
              column = {
                title: "Name",
                minWidth: 180,
                field: "name",
                sorter: "string",
                formatter: (cell) => formatName(cell),
                frozen: true,
              };

              break;

            case "currency":
              column = {
                minWidth: 120,
                field: "currency",
                hozAlign: "left",
                sorterParams: { alignEmptyValues: "bottom" },
                sorter: "string",
                frozen: true,
              };

              break;

            case "sector":
              column = {
                minWidth: 180,
                sorterParams: { alignEmptyValues: "bottom" },
                field: "sector",
                sorter: (a, b, aRow, bRow, col, dir) =>
                  sortByTaxon(aRow, bRow, dir, "sector"),
                frozen: true,
              };

              break;

            case "country":
              column = {
                minWidth: 180,
                field: "country",
                sorterParams: { alignEmptyValues: "bottom" },
                sorter: (a, b, aRow, bRow, col, dir) =>
                  sortByTaxon(aRow, bRow, dir, "country"),
                frozen: true,
              };

              break;

            case "industry":
              column = {
                minWidth: 180,
                field: "industry",
                sorterParams: { alignEmptyValues: "bottom" },
                sorter: (a, b, aRow, bRow, col, dir) =>
                  sortByTaxon(aRow, bRow, dir, "industry"),
                frozen: true,
              };

              break;

            case "etfclass":
              column = {
                minWidth: 180,
                field: "etfclass",
                title: properties?.["etfclass"]?.name?.[1] ?? undefined,
                sorterParams: { alignEmptyValues: "bottom" },
                sorter: (a, b, aRow, bRow, col, dir) =>
                  sortByTaxon(aRow, bRow, dir, "etfclass", true),
                frozen: true,
              };

              break;
            case "etfgeo":
              column = {
                minWidth: 180,
                field: "etfgeo",
                title: properties?.["etfgeo"]?.name?.[1] ?? undefined,
                sorterParams: { alignEmptyValues: "bottom" },
                sorter: (a, b, aRow, bRow, col, dir) =>
                  sortByTaxon(aRow, bRow, dir, "etfgeo", true),
                frozen: true,
              };

              break;
          }

          newColumns.splice(1, 0, column);
        }

        activeColumns.current = [...columns];
        table.insertColumns(newColumns);
      }
    },
    [formatName, properties, sortByTaxon]
  );

  return (
    <Box height={"100%"}>
      <ColumnEditor
        onColumnsChange={editColumns}
        close={closeColumnEditor}
        show={showColumnEditor}
      />
      <Box sx={{ width: "100%", height: "100%" }}>
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            minHeight: 0,
            minWidth: 0,
            position: "relative",
          }}
        >
          <Box
            sx={{ transition: "all .5" }}
            height={isLoading ? "100%" : 0}
            width={isLoading ? "100%" : 0}
            position={"absolute"}
            overflow={"hidden"}
            top={0}
            left={0}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            gap={1}
            bgcolor={"white"}
            zIndex={2}
          >
            <CircularProgress sx={{ color: "#2a7090" }} />
            <Typography sx={{ fontWeight: "bold" }}>Loading...</Typography>
          </Box>
          {/* This is the row menu that appear by clicking on the row menu icon */}
          <Menu
            id="fade-menu"
            MenuListProps={{
              "aria-labelledby": "fade-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={closeRowCtxMenu}
            TransitionComponent={Fade}
          >
            <MenuItem sx={{ fontSize: "0.7vw" }} onClick={onReplaceInstruments}>
              &#9019; Replace
            </MenuItem>
            <MenuItem sx={{ fontSize: "0.7vw" }} onClick={onDeallocation}>
              &#10007; Deallocate
            </MenuItem>
          </Menu>
          <Box
            height={"100%"}
            width={"100%"}
            minHeight={0}
            minWidth={0}
            display={"flex"}
          >
            <TrendratingTableV2
              ref={tableRef}
              tableEvents={tableEvents}
              tableOptions={tableOptions}
              rowTooltipFormatter
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

const RemoveColumnModal = ({
  property,
  onRemove,
  onCancel,
}: {
  property: string;
  onRemove: Function;
  onCancel: Function;
}) => {
  const formatter = useFormatter();
  const formatDate = useCallback(
    (value) => {
      var formatted = formatter.custom("date", {
        options: {
          notAvailable: {
            input: null,
            output: "",
          },
        },
        output: "HTML",
        value: value,
        valueHelper: null,
      });

      return formatted;
    },
    [formatter]
  );

  const removeAllocation = useCallback(() => {
    onRemove(property);
  }, [onRemove, property]);

  const close = useCallback(() => {
    onCancel();
  }, [onCancel]);

  return (
    <Box>
      <Typography>
        Are you sure to delete allocation{" "}
        {formatDate(parseInt(property.replace("date_", "")))}
      </Typography>
      <Box display={"flex"} gap={2} p={1} justifyContent={"flex-end"}>
        <Button variant="contained" onClick={removeAllocation}>
          Remove
        </Button>
        <Button variant="tr_button_cancel" onClick={close}>
          Cancel
        </Button>
      </Box>
    </Box>
  );
};

const ReplaceModalContent = ({
  onSave,
  onCancel,
}: {
  onSave: (value) => void;
  onCancel: () => void;
}) => {
  const [selectedInstrument, setSelectedInstrument] = useState<any>();

  const selectInstrument = useCallback((value) => {
    if (value) {
      setSelectedInstrument(value);
    }
  }, []);

  return (
    <Box>
      <Box display={"flex"} alignItems={"center"} gap={1}>
        <Typography>Replace with: </Typography>
        <Search
          showInstrumentInfoOnSelect={false}
          onSelectInstrument={selectInstrument}
        />
      </Box>
      <Box display={"flex"} gap={2} p={1} justifyContent={"flex-end"}>
        <Button variant="contained" onClick={() => onSave(selectedInstrument)}>
          Save
        </Button>
        <Button variant="tr_button_cancel" onClick={onCancel}>
          Cancel
        </Button>
      </Box>
    </Box>
  );
};

const ColumnEditor = ({ onColumnsChange, show, close }: ColumnsEditorProps) => {
  const environment = useEnvironment();

  const configuration = useMemo(() => {
    return environment.get("account").product.configuration;
  }, [environment]);

  const columns = useMemo(() => {
    const config = configuration?.["systematic_portfolios"]?.["tabs"];

    if (config) {
      const historicalTabConfiguration = config.find(
        (tab) => tab.id === "historicalPortfolios"
      );

      if (historicalTabConfiguration) {
        const columnsDefault =
          historicalTabConfiguration.widgets.viewer.table.columns.security.map(
            (col) => col.field
          );

        const columnsAvailable =
          historicalTabConfiguration.widgets.viewer.table.columns_available;

        return { columnsAvailable, columnsDefault };
      }
    }

    return { columnsAvailable: [], columnsDefault: [] };
  }, [configuration]);

  // const [show, setShow] = useState(false);
  const [emptyError, setEmptyError] = useState(false);
  const [activatedColumns, setActivatedColumns] = useState<string[]>(
    columns.columnsDefault
  );

  const dndRef = useRef<any>();

  const properties = useMemo(() => {
    const properties = environment.get("properties").properties;
    const flatProperties = {};

    for (const key in properties) {
      for (const property in properties[key]) {
        flatProperties[property] = properties[key][property];
      }
    }

    return flatProperties;
  }, [environment]);

  const closeModal = useCallback(() => close(), [close]);

  const onClickApply = useCallback(() => {
    if (dndRef.current) {
      const currentColumns = dndRef.current
        ?.getState()
        .map((item) => item.value);

      setActivatedColumns([...currentColumns]);

      if (!currentColumns.length) {
        setEmptyError(true);
        return;
      }

      onColumnsChange(currentColumns);
      closeModal();
    }
  }, [closeModal, onColumnsChange]);

  const modalBtns = useMemo(
    () => [
      { name: "Apply", callback: onClickApply },
      { name: "Cancel", callback: closeModal, variant: "cancel" },
    ],
    [closeModal, onClickApply]
  );

  const onCheckboxChange = useCallback((event, checked) => {
    const field = event.target.value;

    if (checked) {
      setActivatedColumns((current) => {
        const newState = [...current];

        newState.push(field);

        return newState;
      });
    } else {
      setActivatedColumns((current) => {
        return current.filter((checkbox) => checkbox !== field);
      });
    }
  }, []);

  return show ? (
    <Modal
      headerConfig={{
        headerContent: "Configure table",
        hasBackground: true,
      }}
      customCss={{ minWidth: "auto" }}
      onClose={closeModal}
      buttonsEnalbed
      buttons={modalBtns}
      closeIcon={false}
    >
      {emptyError === true ? (
        <Typography sx={{ color: "red", marginBottom: "8px" }}>
          Please select at least one column
        </Typography>
      ) : (
        <></>
      )}
      <Box className={styles.modalContentBox} mb={1}>
        <Box className={styles.checkboxesCol}>
          <Card>
            <CardContent className={styles.editorContentCardContent}>
              <Box className={styles.editorContentBoxHeader}>
                Available fields
              </Box>
              <Box className={styles.editorContentBoxContent}>
                <Box className={styles.columnsAvailableBox}>
                  <Box className={styles.checkboxGroupWrapper}>
                    <FormGroup className={styles.checkboxGroup}>
                      {columns.columnsAvailable &&
                        columns.columnsAvailable.map((p) => (
                          <FormControlLabel
                            key={uuidv4()}
                            control={
                              <Checkbox
                                size="small"
                                checked={activatedColumns.includes(p.field)}
                                value={p.field}
                                onChange={onCheckboxChange}
                              />
                            }
                            label={
                              properties?.[p.field]?.name[p?.panelIndex ?? 0] ??
                              ""
                            }
                          />
                        ))}
                    </FormGroup>
                  </Box>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Box>
      </Box>
      <Box>
        <Card sx={{ boxShadow: 3 }}>
          <CardContent>
            <Typography>Drag and drop column to change order.</Typography>
            <List
              ref={dndRef}
              content={activatedColumns.map((field) => {
                const property = columns.columnsAvailable.find(
                  (col) => col.field === field
                );

                return {
                  value: field,
                  content: (
                    <Box
                      sx={{ bgcolor: "white" }}
                      border={"solid 1px #2a7092"}
                      p={1}
                      borderRadius={1}
                      flex={1}
                      whiteSpace={"nowrap"}
                      overflow={"hidden"}
                      textOverflow={"ellipsis"}
                    >
                      <Typography>
                        {properties?.[field]?.name[property?.panelIndex ?? 0] ??
                          ""}
                      </Typography>
                    </Box>
                  ),
                };
              })}
              orientation={"horizontal"}
            />
          </CardContent>
        </Card>
      </Box>
    </Modal>
  ) : (
    <></>
  );
};
