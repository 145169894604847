import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  InputAdornment,
  Slider,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Instruments } from "../../../../../api/compute/Instruments";
import { Lists } from "../../../../../api/compute/Lists";
import { Strategies } from "../../../../../api/compute/Strategies";
import { SystematicProducts } from "../../../../../api/compute/SystematicProducts";
import { Utils } from "../../../../../api/compute/Utils";
import { dataInjector } from "../../../../../api/compute/commons";
import { deepClone } from "../../../../../deepClone";
import { useEnvironment } from "../../../../../hooks/useEnvironment";
import { useFormatter } from "../../../../../hooks/useFormatter";
import { httpAll } from "../../../../../httpAll";
import { TDate } from "../../../../../trendrating/date/TDate";
import { config } from "../../../config-ts";
import { messageError, messageSuccess, removeLoader } from "../../../utils";
import { CSV } from "../../../utils/SingletonCSV";
import { widgetsConfiguration } from "../../../widgets/widgetsConfiguration";
import DifferenceTable from "./DifferenceTable";
import LastAllocationsTable from "./LastAllocationsTable";
import { _formatAction, _prepareThresholdValue } from "./utils";
import { useBroadcast } from "../../../../../hooks/useBroadcast";
import { styled } from "@mui/system";
import { PorfolioAmountInput } from "../../../../../components/PortfolioAmountInput/PorfolioAmountInput";
import { InfoTooltip } from "../../alerts/InfoTooltip";
import { TransparencyButton } from "../../strategies/builder/Transparency/TransparencyComponents";
import { Currency } from "../../../../../types/Api";

type Amounts = {
  ordersAmountsInfo: {
    total: { slippage: number };
    columnValues: {
      A: number;
      S: string;
      MG: number;
      SH: number;
      MGW: number;
      Val: number;
      TruncVal: number;
    }[];
  };
  rebalancedAmountsInfo: {
    total: { slippage: number };
    columnValues: {
      A: number;
      S: string;
      MG: number;
      SH: number;
      MGW: number;
      Val: number;
      TruncVal: number;
    }[];
  };
  currency: Currency;
};

const StyledInput = styled((props: any) => (
  <TextField
    onChange={(e) => {
      props.setThreshold(e.target.value);
      props.refreshSlider(parseFloat(e.target.value));
      props.thresholdHandler(parseFloat(e.target.value));
    }}
    value={props.threshold}
    className={props.className}
    size="small"
    type="number"
    inputProps={{
      step: 0.05,
      max: props.maxSliderAndInput,
      maxLength: 3,
      min: props.minSliderAndInput,
    }}
    InputProps={{
      endAdornment: <InputAdornment position="end">%</InputAdornment>,
    }}
  />
))({
  "& input[type=number]": {
    "-moz-appearance": "textfield",
  },
  "& input[type=number]::-webkit-outer-spin-button": {
    "-webkit-appearance": "none",
    margin: 0,
  },
  "& input[type=number]::-webkit-inner-spin-button": {
    "-webkit-appearance": "none",
    margin: 0,
  },
});

export default function Rebalance(/*{}: Props*/) {
  const urlParams = useParams();
  const formatter = useFormatter();
  const { t } = useTranslation();
  const { broadcast } = useBroadcast();
  const [todayDate, setTodayDate] = useState();

  const productID = useMemo(() => {
    if (urlParams.id) {
      return parseInt(urlParams?.id);
    }
    return null;
  }, [urlParams.id]);
  const environment = useEnvironment();
  const envSetup = useMemo(() => environment.get("setup"), [environment]);

  //#region - APIS
  const smsAPI = useMemo(() => new SystematicProducts(envSetup), [envSetup]);
  const listAPI = useMemo(() => new Lists(envSetup), [envSetup]);
  const utilsAPI = useMemo(() => new Utils(envSetup), [envSetup]);
  const instrumentsAPI = useMemo(() => new Instruments(envSetup), [envSetup]);
  const strategyAPI = useMemo(() => new Strategies(envSetup), [envSetup]);
  //#endregion

  const [product, setProduct] = useState<any>(null);
  const [rebalancedProduct, setRebalancedProduct] = useState<any>(null);
  const [productSnapshot, setProductSnapshot] = useState<any>(null);
  const [strategy, setStrategy] = useState<any>(null);
  const [_data, setData] = useState<any>(null);
  const [currentTab, setCurrentTab] = useState<"orders" | "portfolio">(
    "orders"
  );
  const [showSideBar, setShowSidebar] = useState(true);
  const [amounts, setAmounts] = useState<Amounts>();
  const [portfolioWgetKey, setPortfolioWgetKey] = useState(Date.now());

  const ordersAmounts = useMemo(
    () => amounts?.ordersAmountsInfo.columnValues ?? undefined,
    [amounts?.ordersAmountsInfo.columnValues]
  );
  const rebalancedAmounts = useMemo(
    () => amounts?.rebalancedAmountsInfo.columnValues ?? undefined,
    [amounts?.rebalancedAmountsInfo.columnValues]
  );
  const amountsCurrency = useMemo(() => {
    return amounts?.currency;
  }, [amounts?.currency]);

  const refreshPortfolioInput = useCallback(() => {
    setPortfolioWgetKey(Date.now());
  }, []);

  const userID = useMemo(
    () => environment.get("account")["user"]["id"],
    [environment]
  );

  const exAnteRebalancePortfolio = useMemo(() => {
    let exAnte: any = {
      d: null,
      v: [],
    };

    if (product) {
      const todayPos = JSON.parse(JSON.stringify(product["POS"][0]));

      let exantePos = todayPos;
      let exAntePortfolio: any = [];
      for (let i = 0, N = exantePos.v.length; i < N; i++) {
        exAntePortfolio.push({ S: exantePos.v[i].S, A: exantePos.v[i].A });
      }
      exAnte = { d: exantePos.d, v: exAntePortfolio };
    }

    return exAnte;
  }, [product]);

  const onChangeTab = useCallback((event, value) => {
    setCurrentTab(value);
  }, []);

  //#region GET DATA AND PROCESS DATA
  const _dataPrepare = useCallback(
    (dataCompare, response) => {
      var dataLast = dataInjector(
        response["last"]["data"],
        dataCompare["result"]["positions"],
        ["weight"]
      );
      var position: any = null;
      var taxonomies = window.App.taxonomies;
      for (let i = 0, length = dataLast.length; i < length; i++) {
        position = dataLast[i];
        // replace ICB code with string to enable dgrid native sort
        // avoiding calls to server
        position["_synthWhat"] = formatter.custom("taxon", {
          options: {
            ancestorAtLevel: "1 Industry",
            notAvailable: {
              input: null,
              output: "",
            },
            taxonomy: taxonomies["ICB"],
          },
          output: "TEXT",
          value: position["icb"],
          valueHelper: null,
        });
      }
      var dataDiff: any = response["diff"]["data"];
      var diffMap: any = {};
      for (let i = 0; i < dataDiff.length; i++) {
        position = dataDiff[i];
        // enriching dataDiff with synthetic fields
        position["_synthAction"] = null;
        position["_synthActionValue"] = null;
        diffMap[position["symbol"]] = i;
      }
      var dataRate = response["rate"];
      var difference = dataCompare["diff"];
      var index: any = null;
      for (let i = 0; i < difference.length; i++) {
        position = difference[i];
        index = diffMap[position["symbol"]];
        if (index != null) {
          dataDiff[index]["_synthActionValue"] = position["delta"];
          if (position["from"] === 0 && position["to"] > 0) {
            dataDiff[index]["_synthAction"] = "buy";
          } else if (position["from"] > 0 && position["to"] === 0) {
            dataDiff[index]["_synthAction"] = "sell";
          } else if (position["delta"] > 0) {
            dataDiff[index]["_synthAction"] = "increase";
          } else {
            dataDiff[index]["_synthAction"] = "decrease";
          }
        }
      }
      var data = {
        diff: dataDiff,
        last: dataLast,
        rate: dataRate,
        turnover: dataCompare["turnover"],
      };
      setData(data);
      setStrategy(response["strategy"]);
    },
    [formatter]
  );
  const _dataGetRebalanceResult = useCallback(
    async (response, snapshotOfProduct, product) => {
      let responseToday: any = null;
      responseToday = await utilsAPI.today();
      var data = deepClone(response);
      // var snapshot = deepClone(snapshotOfProduct);
      setTodayDate(responseToday.today);
      var today = TDate.daysToIso8601(responseToday.today);
      /* Data cleanup, removing diff symbols with delta 0 */
      var newDiff: any[] = [];
      // var maximumDiff = 0;
      if (data["diff"] != null) {
        for (let i = 0; i < data["diff"].length; i++) {
          if (data["diff"][i]["delta"] !== 0) {
            newDiff.push(data["diff"][i]);
          }
        }
        data["diff"] = newDiff;
      }
      // last allocation
      var propertiesLast =
        widgetsConfiguration["widgets/systematic-product/rebalance/last"][
          "properties"
        ];
      var symbols: any[] = [];
      var positions = data["result"]["positions"];
      for (let i = 0; i < positions.length; i++) {
        symbols.push(positions[i]["symbol"]);
      }
      var reqLast = instrumentsAPI.fetch({
        properties: propertiesLast,
        symbols: symbols,
        type: "security",
      });
      // difference (rebalance orders)
      var propertiesDiff =
        widgetsConfiguration["widgets/systematic-product/rebalance/diff"][
          "properties"
        ];
      symbols = [];
      positions = data["diff"];
      for (let i = 0; i < positions.length; i++) {
        symbols.push(positions[i]["symbol"]);
      }
      var reqDiff = instrumentsAPI.fetch({
        properties: propertiesDiff,
        symbols: symbols,
        type: "security",
      });
      // Statistics from target portfolio
      positions = data["result"]["positions"];
      var pos: any[] = [];
      for (let i = 0; i < positions.length; i++) {
        pos.push({
          S: positions[i].symbol,
          A: positions[i].weight,
        });
      }
      var reqRating = smsAPI.ratingAt({
        adjustWeights: true,
        asOf: today,
        // currency: snapshot.weightsManagement.currency,
        equalWeighted: false,
        normalize: false,
        perfMetricMode: "active",
        v: pos,
        // weightsAsOf: snapshot.weightsManagement.date,
        productId: product.id,
      });

      // const pp = new Product(envSetup, product);
      // await pp.initProduct();
      var params = {
        diff: reqDiff,
        last: reqLast,
        rate: reqRating,
        strategy: product.strategy /*pp.getStrategy()*/,
      };
      const dataCompare = await httpAll(params);
      _dataPrepare(data, dataCompare);
    },
    [_dataPrepare, instrumentsAPI, smsAPI, utilsAPI]
  );
  const _dataGetCompare = useCallback(
    async (rebalancedProduct, snapshotOfProduct, product) => {
      var threshold = _prepareThresholdValue(0);
      var paramsCompare = {
        source: {
          name: "Snapshot",
          positions: smsAPI._rebalance(snapshotOfProduct)?.["positions"] ?? [],
        },
        target: {
          name: "Last rebalance",
          positions: rebalancedProduct["positions"],
        },
        threshold: threshold,
        thresholdInOut: threshold,
      };
      const listCompareResponse = await listAPI.compare(paramsCompare);
      _dataGetRebalanceResult(listCompareResponse, snapshotOfProduct, product);
    },
    [_dataGetRebalanceResult, listAPI, smsAPI]
  );
  const _dataGetCompareWithThreshold = useCallback(
    async (threshold) => {
      var _threshold = _prepareThresholdValue(threshold);
      if (rebalancedProduct && productSnapshot) {
        var paramsCompare = {
          source: {
            name: "Snapshot",
            positions: smsAPI._rebalance(productSnapshot)?.["positions"] ?? [],
          },
          target: {
            name: "Last rebalance",
            positions: rebalancedProduct?.["positions"] ?? [],
          },
          threshold: _threshold,
          thresholdInOut: _threshold,
        };
        const listCompareResponse = await listAPI.compare(paramsCompare);
        _dataGetRebalanceResult(listCompareResponse, productSnapshot, {
          ...product,
          strategy: strategy,
        });
      }
    },
    [
      _dataGetRebalanceResult,
      listAPI,
      product,
      productSnapshot,
      rebalancedProduct,
      smsAPI,
      strategy,
    ]
  );

  //#endregion

  const dataGet = useCallback(async () => {
    if (productID) {
      const data = await smsAPI.fetch({
        ids: [productID],
        properties: [
          "positionsToday",
          "strategyId",
          "historicalPortfolioId",
          "name",
          "ownerId",
        ],
      });
      const strategyID = data[0].strategyId;

      setProduct({
        ...data[0],
        isReadOnly: userID !== data[0].ownerId,
      });
      const _strategy = await strategyAPI.getById(strategyID);
      setStrategy(_strategy);
      let THETODAYDATE: any = await utilsAPI.today();
      let THEPOSITIONTODAY = data?.[0]?.POS?.[0];
      if (THEPOSITIONTODAY) {
        THEPOSITIONTODAY.d = THETODAYDATE.today;
      } else {
        THEPOSITIONTODAY = {};
        THEPOSITIONTODAY["d"] = THETODAYDATE.today;
        THEPOSITIONTODAY["v"] = [];
      }
      const THESTRATEGY = strategyAPI.prepareParamsForRun(
        _strategy.params,
        null
      )["strategy"];
      const THESTRATEGYUNIVERSE = strategyAPI.prepareParamsForRun(
        _strategy.params,
        null
      )["universe"];
      const param = {
        date: THETODAYDATE.today,
        exAntePortfolio: THEPOSITIONTODAY,
        strategy: THESTRATEGY,
        universe: THESTRATEGYUNIVERSE,
        verbose: false,
      };
      let THENEWPORTFOLIO = await smsAPI.listRebalance(param);
      THENEWPORTFOLIO = smsAPI._rebalance(THENEWPORTFOLIO);
      setRebalancedProduct(THENEWPORTFOLIO);
      setProductSnapshot(THEPOSITIONTODAY);
      _dataGetCompare(THENEWPORTFOLIO, THEPOSITIONTODAY, {
        ...data[0],
        strategy: _strategy,
      });
    }
  }, [_dataGetCompare, productID, smsAPI, strategyAPI, userID, utilsAPI]);

  const getValuesConversion = useCallback(
    async (currency, value, allocation) => {
      const date = envSetup.today.today;
      setAmounts(undefined);

      const response = await smsAPI.convertInValues(
        date,
        allocation,
        currency,
        value
      );

      return response;
    },
    [envSetup.today.today, smsAPI]
  );

  const getRebalancedAndOrdersValues = useCallback(
    async (currency, value) => {
      const ordersPortfolio = _data.diff.map((pos) => ({
        ...pos,
        weight: pos._synthActionValue,
      }));
      const rebalancedPortfolio = _data.last;

      const ordersAmounts = await getValuesConversion(
        currency,
        value,
        ordersPortfolio
      );
      const rebalancedAmounts = await getValuesConversion(
        currency,
        value,
        rebalancedPortfolio
      );

      const ordersAmountsInfo = {
        total: { slippage: ordersAmounts?.total?.MGW ?? 0 },
        columnValues: ordersAmounts.v,
      };
      const rebalancedAmountsInfo = {
        total: { slippage: rebalancedAmounts?.total?.MGW ?? 0 },
        columnValues: rebalancedAmounts.v,
      };

      setAmounts({ ordersAmountsInfo, rebalancedAmountsInfo, currency });
    },
    [_data?.diff, _data?.last, getValuesConversion]
  );

  useEffect(() => {
    removeLoader();
    dataGet();
  }, [dataGet]);

  const formatNumber = useCallback(
    (value, perc = false, hasSign = false) =>
      formatter.custom("number", {
        options: {
          hasPositiveSign: hasSign,
          isPercentage: perc,
          notAvailable: {
            input: null,
            output: "",
          },
        },
        output: "TEXT",
        value: value,
        valueHelper: null,
      }),
    [formatter]
  );

  //#region
  const loading = useMemo(() => {
    return <CircularProgress size={"0.9em"} />;
  }, []);
  const loadingTable = useMemo(() => {
    return (
      <Box
        height={"100%"}
        width={"100%"}
        display={"flex"}
        alignItems={"center"}
        flexDirection={"column"}
        justifyContent={"center"}
        gap={2}
      >
        <CircularProgress />
        <Typography>Loading table </Typography>
      </Box>
    );
  }, []);
  const tcr = useMemo(() => {
    if (_data && _data["rate"]["statistics"]) {
      const _tcr = formatter.tcr(_data["rate"]["statistics"]["tcr"], "HTML");
      return <span dangerouslySetInnerHTML={{ __html: _tcr }}></span>;
    }
    return loading;
  }, [_data, formatter, loading]);
  const allocation = useMemo(() => {
    if (!_data) {
      return loading;
    }
    const rate = _data["rate"];
    if (rate == null || rate["statistics"] == null) {
      return "0";
    } else {
      var totalWeight = rate["statistics"]["weightTotal"];
      const _alocation = formatter.custom("number", {
        options: {
          hasPositiveSign: false,
          isPercentage: true,
          notAvailable: {
            input: null,
            output: "",
          },
        },
        output: "HTML",
        value: totalWeight,
        valueHelper: null,
      });
      return (
        <strong>
          <span dangerouslySetInnerHTML={{ __html: _alocation }}></span>
        </strong>
      );
    }
  }, [_data, formatter, loading]);
  const numberOfHoldings = useMemo(() => {
    if (!_data) {
      return loading;
    }
    const _numberOfHoldings = _data.rate.positions.length;
    return (
      <strong>
        <span dangerouslySetInnerHTML={{ __html: _numberOfHoldings }}></span>
      </strong>
    );
  }, [_data, loading]);
  //#endregion

  //#region THRESHOLD
  const [threshold, setThreshold] = useState("0.00");
  const thresholdHandler = useCallback(
    (thr) => {
      setAmounts(undefined);
      refreshPortfolioInput();

      if (!isNaN(thr)) {
        if (thr !== 0) {
          _dataGetCompareWithThreshold(thr / 100);
        } else {
          _dataGetCompareWithThreshold(thr);
        }
      }
    },
    [_dataGetCompareWithThreshold, refreshPortfolioInput]
  );
  //#endregion
  const rebalanceTurnover = useMemo(() => {
    if (!_data) {
      return loading;
    }
    return <strong> {(_data.turnover * 100).toFixed(2)}%</strong>;
  }, [_data, loading]);

  //#region CSV HANDLERS
  const listenerExportOrders = useCallback(() => {
    if (_data == null) {
      return;
    }
    var data = _data["diff"];
    var filename =
      product["name"] + " - " + t("w_systematic_product_rebalance_orders");
    var lines = [
      [
        '"ISIN"',
        '"Ticker"',
        '"Name"',
        '"Rating"',
        '"Currency"',
        '"Market"',
        '"Mkt. Cap."',
        '"Action"',
        '"Quantity"',
      ],
    ];

    if (ordersAmounts != null) {
      lines[0].push('"Amount"', '"Shares"');
    }

    var line: any = null;
    var position: any = null;
    for (let i = 0, length = data.length; i < length; i++) {
      line = [];
      position = data[i];

      line.push('"' + position["isin"] + '"');
      line.push('"' + position["ticker"] + '"');
      line.push('"' + position["name"] + '"');
      line.push(
        '"' +
          formatter.text(
            "rc",
            "table",
            position["rc"],
            position,
            position["type"]
          ) +
          '"'
      );
      line.push('"' + position["currency"] + '"');
      line.push('"' + position["country"] + '"');
      line.push(
        '"' +
          formatter.text(
            "numberBigFmt",
            "table",
            position["marketcap"],
            position,
            position["type"]
          ) +
          '"'
      );
      line.push('"' + _formatAction("TEXT", position["_synthAction"], t) + '"');
      line.push(
        '"' +
          formatter.custom("number", {
            options: {
              hasPositiveSign: true,
              isPercentage: true,
              notAvailable: {
                input: null,
                output: "",
              },
            },
            output: "TEXT",
            value: position["_synthActionValue"],
            valueHelper: null,
          }) +
          '"'
      );

      if (ordersAmounts != null) {
        // Amounts lines
        line.push(
          '"' +
            formatter.custom("amount", {
              options: {
                notAvailable: {
                  input: null,
                  output: "",
                },
              },
              output: "TEXT",
              value: position["TruncVal"],
              valueHelper: { currency: amountsCurrency },
            }) +
            '"'
        );
        line.push(
          '"' +
            formatter.custom("number", {
              options: {
                hasPositiveSign: false,
                isPercentage: false,
                decimals: 0,
                notAvailable: {
                  input: null,
                  output: "",
                },
              },
              output: "HTML",
              value: position["SH"],
              valueHelper: null,
            }) +
            '"'
        );
      }

      lines.push(line);
    }
    CSV.create(lines, filename);
  }, [_data, amountsCurrency, formatter, ordersAmounts, product, t]);
  const listenerExportNewAllocation = useCallback(() => {
    var data = deepClone(_data["last"]);

    var filename =
      product["name"] +
      " - " +
      t("w_systematic_product_rebalance_new_allocation");
    var lines = [
      [
        '"Weight"',
        '"ISIN"',
        '"Ticker"',
        '"Name"',
        '"Rating"',
        '"Currency"',
        '"Market"',
        '"Mkt. Cap."',
      ],
    ];

    if (rebalancedAmounts != null) {
      lines[0].splice(1, 0, '"Amount"', '"Shares"');
    }

    var line: any = null;
    var position: any = null;
    for (let i = 0, length = data.length; i < length; i++) {
      line = [];
      position = data[i];

      line.push(
        '"' +
          formatter.text(
            "weight",
            "table",
            position["weight"],
            position,
            position["type"]
          ) +
          '"'
      );

      if (rebalancedAmounts != null) {
        const rebalancedAmountsMap = rebalancedAmounts.reduce(
          (prev, current) => {
            prev[current.S] = { ...current };

            return prev;
          },
          {}
        );

        // Amounts lines
        line.push(
          '"' +
            formatter.custom("amount", {
              options: {
                notAvailable: {
                  input: null,
                  output: "",
                },
              },
              output: "TEXT",
              value:
                // This fallback guarantees that the amounts columuns are exported with the right values even if the new orders tab
                // has not been open yet
                position["TruncVal"] ||
                rebalancedAmountsMap?.[position?.["symbol"]]["TruncVal"] ||
                "",
              valueHelper: { currency: amountsCurrency },
            }) +
            '"'
        );
        line.push(
          '"' +
            formatter.custom("number", {
              options: {
                hasPositiveSign: false,
                isPercentage: false,
                decimals: 0,
                notAvailable: {
                  input: null,
                  output: "",
                },
              },
              output: "HTML",
              value:
                // This fallback guarantees that the amounts columuns are exported with the right values even if the new orders tab
                // has not been open yet
                position["SH"] ||
                rebalancedAmountsMap?.[position?.["symbol"]]["SH"] ||
                "",
              valueHelper: null,
            }) +
            '"'
        );
      }

      line.push('"' + position["isin"] + '"');
      line.push('"' + position["ticker"] + '"');
      line.push('"' + position["name"] + '"');
      line.push(
        '"' +
          formatter.text(
            "rc",
            "table",
            position["rc"],
            position,
            position["type"]
          ) +
          '"'
      );
      line.push('"' + position["currency"] + '"');
      line.push('"' + position["country"] + '"');
      line.push(
        '"' +
          formatter.text(
            "numberBigFmt",
            "table",
            position["marketcap"],
            position,
            position["type"]
          ) +
          '"'
      );

      lines.push(line);
    }

    CSV.create(lines, filename);
  }, [_data, amountsCurrency, formatter, product, rebalancedAmounts, t]);
  //#endregion

  //#region ConfirmRebalance
  const listenerConfirmRebalance = useCallback(() => {
    var paramsRebalanceAdd = {
      rebalance: {
        date: rebalancedProduct["date"],
        positions: _data["last"],
      },
      product: product,
    };
    smsAPI.rebalanceAdd(paramsRebalanceAdd).then(
      () => {
        const [channel, msg] = messageSuccess(
          `${product.name} has been rebalanced successfully!`
        );
        broadcast(channel as string, msg);
        (window as any).__page_navigate(`/systematic-portfolios/${productID}`);
      },
      (error) => {
        const [channel, msg] = messageError(
          `${product.name} can't be reabalanced... contact Trendrating.`
        );
        broadcast(channel as string, msg);
        (window as any).__page_navigate(`/systematic-portfolios/${productID}`);
      }
    );
    //************************** USAGE *****************************
    var info = {
      action: "SAVE",
      actionParam: productID,
      function: "SYSTEMATIC_PORTFOLIO_REBALANCE",
    };
    var usage = window.App.usage;
    usage.record(info);
    //************************** USAGE *****************************
  }, [_data, broadcast, product, productID, rebalancedProduct, smsAPI]);

  //#endregion

  //#region WORKFLOW BAR
  const [workflow, setWorkflow] = useState("s0");
  useEffect(() => {
    const actions: any = [];
    let action: any = null;
    switch (workflow) {
      case "s0": {
        action = {
          componentJSX: (
            <li
              className={"menu__item"}
              onClick={() => listenerBackAction(productID, setWorkflow)}
            >
              Back
            </li>
          ),
        };
        actions.push(action);

        if (product?.["isReadOnly"] === false) {
          action = {
            componentJSX: (
              <li className={"menu__item"} onClick={listenerExportOrders}>
                Export rebalance orders
              </li>
            ),
          };
          actions.push(action);

          action = {
            componentJSX: (
              <li
                className={"menu__item"}
                onClick={listenerExportNewAllocation}
              >
                Export new allocation
              </li>
            ),
          };
          actions.push(action);

          action = {
            componentJSX: (
              <li className={"menu__item"} onClick={listenerConfirmRebalance}>
                Confirm
              </li>
            ),
            isHighlighted: true,
          };
          actions.push(action);
        }

        break;
      }
      // case "s1": {
      //   // When going back, used to hide everything because it
      //   // still loading data but previous actions were visible

      //   break;
      // }
      // no default
    }
    if (productID) {
      var message = {
        from: "rebalance",
        content: {
          actions: actions,
        },
      };
      broadcast(config["channels"]["workflow"]["input"], message);
    }
  }, [
    broadcast,
    listenerConfirmRebalance,
    listenerExportNewAllocation,
    listenerExportOrders,
    product,
    productID,
    workflow,
  ]);
  //#endregion

  const [sliderValue, setSliderValue] = useState(0);
  const refreshSlider = useCallback((val) => {
    setSliderValue(val);
  }, []);

  const openSideBar = useCallback(() => {
    setShowSidebar(true);
  }, []);
  const closeSideBar = useCallback(() => {
    setShowSidebar(false);
  }, []);

  const toggleSideBar = useCallback(() => {
    if (showSideBar) {
      closeSideBar();
    } else {
      openSideBar();
    }
  }, [closeSideBar, openSideBar, showSideBar]);

  const maxSliderAndInput = useMemo(() => 2, []);
  const minSliderAndInput = useMemo(() => 0, []);

  const handleSubmitAmount = useCallback(
    async (input) => {
      const isAmountValid = input.amount > 0;

      if (input.currency && isAmountValid) {
        await getRebalancedAndOrdersValues(input.currency, input.amount);
      }
    },
    [getRebalancedAndOrdersValues]
  );

  const handleResetAmount = useCallback(() => {
    setAmounts(undefined);
  }, []);

  const tableBoxWidth = useMemo(() => {
    return "100%";
  }, []);

  const lastAllocationsTable = useMemo(
    () => (
      <LastAllocationsTable
        amounts={rebalancedAmounts}
        toggleSidebar={toggleSideBar}
        data={_data?.last}
        currency={amountsCurrency}
      />
    ),
    [_data?.last, amountsCurrency, rebalancedAmounts, toggleSideBar]
  );

  const onChangeTresholdSlider = useCallback(
    (_, value) => {
      thresholdHandler(value);
      setThreshold(value.toString());
    },
    [thresholdHandler]
  );

  return (
    <Box
      height={"100%"}
      overflow={"hidden"}
      display={"flex"}
      flexDirection={"column"}
    >
      <Box display={"flex"} gap={1} alignItems={"center"}>
        {product && (
          <Typography variant="subtitle1">
            <strong>{product.name}</strong>
          </Typography>
        )}

        <Tabs onChange={onChangeTab} value={currentTab}>
          <Tab label={"Rebalance orders"} value={"orders"} />
          <Tab label={"Rebalanced Portfolio"} value={"portfolio"} />
        </Tabs>
      </Box>
      <Box
        height={"100%"}
        width={"100%"}
        overflow={"hidden"}
        display={"flex"}
        gap={1}
        p={1}
      >
        <SideBar show={showSideBar}>
          <>
            {product && (
              <Box
                display={"flex"}
                flexDirection={"column"}
                gap={2}
                height={"100%"}
              >
                <Card>
                  <CardContent>
                    <Box display={"flex"} flexDirection={"column"} gap={1}>
                      <Typography>
                        <strong>Order threshold</strong>
                      </Typography>
                      <Box
                        minWidth={"30%"}
                        gap={2}
                        display={"flex"}
                        alignItems={"center"}
                      >
                        <Slider
                          value={sliderValue}
                          step={0.1}
                          max={maxSliderAndInput}
                          min={minSliderAndInput}
                          valueLabelDisplay="auto"
                          onChange={(e: any) => {
                            setSliderValue(e.target.value);
                          }}
                          onChangeCommitted={onChangeTresholdSlider}
                        />
                        <Box width={"30%"}>
                          <StyledInput
                            setThreshold={setThreshold}
                            refreshSlider={refreshSlider}
                            thresholdHandler={thresholdHandler}
                            threshold={threshold}
                            maxSliderAndInput={maxSliderAndInput}
                            minSliderAndInput={minSliderAndInput}
                          />
                        </Box>
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
                <Card>
                  <CardContent>
                    <InfoTooltip
                      title={"Portfolio Amount"}
                      text="Insert your portfolio value to see the amounts"
                      child={
                        <Typography>
                          <strong>
                            Portfolio Value <span className="i-help"></span>
                          </strong>
                        </Typography>
                      }
                    />
                    <Box mt={1}>
                      <PorfolioAmountInput
                        key={portfolioWgetKey}
                        show={true}
                        onSubmit={handleSubmitAmount}
                        onReset={handleResetAmount}
                      />
                    </Box>
                  </CardContent>
                </Card>
                <Card sx={{ flex: 3 }}>
                  <CardContent
                    sx={{
                      height: "100%",
                      display: "flex",
                      flex: 1,
                      flexDirection: "column",
                    }}
                  >
                    <Box display={"flex"} flexDirection={"column"} flex={1}>
                      <Card>
                        <CardContent>
                          <Typography>
                            <strong>Rebalanced Portfolio</strong>
                          </Typography>
                          <table style={{ width: "100%", marginTop: "10px" }}>
                            <tbody>
                              <tr>
                                <td>TCR</td>
                                <td>{tcr}</td>
                              </tr>
                              <tr>
                                <td>Allocation</td>
                                <td>{allocation}</td>
                              </tr>
                              <tr>
                                <td>Holdings</td>
                                <td>{numberOfHoldings}</td>
                              </tr>
                              <tr>
                                <td>Rebalance Turnover</td>
                                <td>{rebalanceTurnover}</td>
                              </tr>
                              {amounts != null && (
                                <tr>
                                  <td>Residual balance</td>
                                  <td>
                                    <Box display={"flex"} gap={1}>
                                      <strong>
                                        {currentTab === "orders"
                                          ? formatNumber(
                                              amounts?.ordersAmountsInfo.total
                                                .slippage,
                                              true
                                            )
                                          : formatNumber(
                                              amounts?.rebalancedAmountsInfo
                                                .total.slippage,
                                              true
                                            )}
                                      </strong>
                                      <InfoTooltip
                                        title="Residual Rebalance"
                                        text="This value represents the remaining balance after executing the transaction, accounting for the rounded share quantities"
                                        child={<span className="i-info" />}
                                      />
                                    </Box>
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </CardContent>
                      </Card>
                    </Box>
                    <Box
                      flex={1}
                      display={"flex"}
                      alignItems={"flex-end"}
                      justifyContent={"flex-end"}
                    >
                      {exAnteRebalancePortfolio.v.length &&
                      todayDate &&
                      strategy ? (
                        <TransparencyButton
                          exAntePortfolio={exAnteRebalancePortfolio}
                          strategy={strategy}
                          rebalanceDate={todayDate}
                        />
                      ) : (
                        <></>
                      )}
                    </Box>
                  </CardContent>
                </Card>
              </Box>
            )}
          </>
        </SideBar>

        <Box
          display={"flex"}
          flexDirection={"column"}
          overflow={"hidden"}
          height={"100%"}
          width={"100%"}
        >
          {currentTab === "portfolio" && (
            <Box
              flex={1}
              height={"100%"}
              width={tableBoxWidth}
              overflow={"hidden"}
            >
              {!_data ? loadingTable : lastAllocationsTable}
            </Box>
          )}

          {currentTab === "orders" && (
            <>
              <Box
                flex={1}
                height={"100%"}
                width={tableBoxWidth}
                overflow={"hidden"}
              >
                {!_data ? (
                  loadingTable
                ) : (
                  <DifferenceTable
                    toggleSidebar={toggleSideBar}
                    data={_data.diff}
                    amounts={ordersAmounts}
                    currency={amountsCurrency}
                  />
                )}
              </Box>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
}

const listenerBackAction = (productID, setWorflow) => {
  if (productID == null) {
    // error
    (window as any).__page_navigate("/systematic-portfolios/");
  } else {
    setWorflow("s1");
    (window as any).__page_navigate(`/systematic-portfolios/${productID}`);
  }
};

type SideBarProps = {
  show: boolean;
  children: JSX.Element;
};

const SideBar = ({ show, children }: SideBarProps) => {
  return (
    <Box
      sx={{
        transition: "all .2s",
        width: show ? "30%" : "0",
        position: "relative",
        overflow: "visible",
        marginRight: show ? 0 : "40px",
      }}
    >
      <Box
        width={show ? "100%" : 0}
        sx={{ transition: "all .2s" }}
        overflow={"hidden"}
        height={"100%"}
      >
        {children}
      </Box>
    </Box>
  );
};
