import { Box } from "@mui/material";
import { ActionsETFMarketsControls } from "../AnalysisMarketsETF";
import { FiltersBarMarketDetail } from "../widgets/FiltersBarMarketDetail/FiltersBarMarketDetail";

type AnalysisMarketsEtfTemplateProps = {
  children: JSX.Element | JSX.Element[];
  dispatch: (action: ActionsETFMarketsControls) => void;
  segment:
    | "WWW"
    | "1 Industry"
    | "3 Sector"
    | "4 Subsector"
    | "Area"
    | "Region"
    | "Country";
  analytic:
    | "tcr"
    | "history"
    | "dispersion"
    | "securities"
    | "dispersionBy"
    | "performanceSinceRated";
  getOptionsByPeer: Function;
  timeframe: 0 | 4 | 19;
};

export default function AnalysisMarketsEtfTemplate({
  children,
  analytic,
  segment,
  dispatch,
  getOptionsByPeer,
  timeframe,
}: AnalysisMarketsEtfTemplateProps) {
  return (
    <Box
      sx={{ display: "flex", minHeight: 0, flexDirection: "column", flex: 1 }}
    >
      {/* Filter bar */}
      <Box display={"flex"} minHeight={0}>
        <FiltersBarMarketDetail
          segment={segment}
          dispatch={dispatch}
          getOptionsByPeerType={getOptionsByPeer}
          analytic={analytic}
          timeframe={timeframe}
        />
      </Box>

      <Box display={"flex"} flex={1} flexDirection={"column"} minHeight={0}>
        {/* Dashboard Content */}
        {children}
      </Box>
    </Box>
  );
}
