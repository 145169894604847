import { useCallback, useEffect, useMemo, useRef } from "react";
import Highcharts, { Series } from "highcharts/highstock";
import { useEnvironment } from "../../hooks/useEnvironment";
import { Instruments } from "../../api/compute/Instruments";

type SecurityPriceChartProps = {
  symbol: string;
  title?: string;
};

const color = {
  default: "#2F7ED8",
  // blue

  A: "#008000",
  // green
  B: "#8bbc00",
  // light-green
  C: "#f48400",
  // orange
  D: "#f00000",
  // red
  equity: "#0da760",
  // green              blue #3da0ff
  benchmark: "#4572a7",
  // blue-dark
  security: "#f48400",
  // orange
  yearUp: "#FAF2CB", // light-yellow
};

(Highcharts as any).FastChart = function (node) {
  const defaultOptions: any = {
    xAxis: {
      //tickPixelInterval : 1,
      lineWidth: 1,
      tickWidth: 1,
      showFirstLabel: true,
      startOnTick: false,
      endOnTick: false,
      labels: {
        /*formatter : function() {
      return Highcharts.dateFormat("%b '%y", this.value);
    },*/
        enabled: true,
        style: {
          fontSize: "10px",
        },
      },
    },
    yAxis: [
      {
        id: "yCurve",
        lineWidth: 1,
        tickWidth: 1,
        //lineColor : 'transparent',
        tickPixelInterval: 40,
        gridLineColor: "transparent",
        showLastLabel: true,
        opposite: false,
        startOnTick: false,
        endOnTick: false,
        labels: {
          enabled: true,
          //x : -3,
          style: {
            fontSize: "10px",
          },
        },
      },
    ],

    chart: {
      // height: 240,
      // width: 380,
      renderTo: node,
      animation: false,

      //marginBottom : 0, // The margin between the bottom outer edge of the chart and the plot area. Use this to set a fixed pixel value for the margin as opposed to the default dynamic margin.
      spacingBottom: 5,
      spacingRight: 20,
      spacingLeft: 5,
      spacingTop: 20,
    },
    rangeSelector: {
      enabled: false,
    },
    navigator: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    exporting: {
      enabled: false,
    },

    tooltip: {
      enabled: false,
    },
    scrollbar: {
      enabled: false,
    },
    plotOptions: {
      series: {
        animation: false,
        marker: {
          enabled: true,
        },

        enableMouseTracking: false,
      },
    },

    series: [
      {
        name: "STOCK1",
        id: "STOCK1-ID",
        data: [],
        // predefined JavaScript array
        marker: {
          enabled: false,
        },
        lineColor: color["default"],
        lineWidth: 1,
      },
      {
        type: "flags",
        data: [],
        id: "FLAGS_SERIE",
        onSeries: "STOCK1-ID",
        shape: "circlepin",
        width: 12,
        y: -22,
        //				fillColor : Highcharts.getOptions().colors[2],
        style: {
          // text style
          color: "white",
          fontSize: "10px",
          "vertical-align": "middle",
        },
        color: color["default"],
      },
    ],
  };

  return new Highcharts.StockChart(defaultOptions);
};

export function SecurityPriceChart({ symbol, title }: SecurityPriceChartProps) {
  const nodeRef = useRef<HTMLDivElement | null>(null);
  const highchart = useRef<Highcharts.StockChart | null>(null);

  const environment = useEnvironment();
  const instrumentsAPI = useMemo(
    () => new Instruments(environment.get("setup")),
    [environment]
  );

  const onComponentMount = useCallback(async () => {
    const response = await instrumentsAPI.fetch({
      type: "security",
      symbols: [symbol],
      properties: [{ date: null, property: "chart" }],
    });

    if (response?.data?.[0]) {
      const graphData = response?.data?.[0];

      graphData?.data?.sort((arrA, arrB) => {
        if (arrA[0] > arrB[0]) {
          return 1;
        } else if (arrA[0] > arrB[0]) {
          return -1;
        }

        return 0;
      });
      graphData?.markers?.sort((a, b) => {
        if (a["x"] > b["x"]) {
          return 1;
        } else if (a["x"] > b["x"]) {
          return -1;
        }

        return 0;
      });

      const chart = highchart.current;

      const graphList = graphData.chart;

      if (chart) {
        (chart.get("STOCK1-ID") as Series).setData(
          graphList.data,
          false,
          false
        );

        (chart.get("FLAGS_SERIE") as Series).setData([], false, false);

        if (graphList.markers) {
          for (let index = 0; index < graphList.markers.length; ++index) {
            var markerH = graphList.markers[index];
            markerH.text = 'Shape: "circlepin"';
            markerH.fillColor = color[markerH.title];
          }
          (chart.get("FLAGS_SERIE") as Series).setData(
            graphList.markers,
            false,
            false
          );
          // } else {
          //   (chart.get("FLAGS_SERIE") as Series).setData([], false, false);
        }
        chart.redraw();
        let svg = chart.getSVG();
        svg = `url("data:image/svg+xml,${encodeURIComponent(svg)}")`;

        if (nodeRef.current) {
          nodeRef.current.style.backgroundImage = svg;
        }
      }
    }
  }, [instrumentsAPI, symbol]);

  useEffect(() => {
    onComponentMount();
  }, [onComponentMount]);

  // At component mount create a hidden div used to render a chart
  useEffect(() => {
    const div = document.createElement("div");
    div.style.display = "none";
    div.style.height = "240px";
    div.style.width = "380px";

    document.body.appendChild(div);

    highchart.current = (Highcharts as any).FastChart(div);

    // On component unmount remove the div
    return () => {
      document.body.removeChild(div);
    };
  }, []);

  return (
    <div
      style={{
        backgroundPosition: "50% 50%",
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
        height: "100%",
        width: "100%",
      }}
      id={"chartNode"}
      ref={nodeRef}
    />
  );
}
