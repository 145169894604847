/**
 * @author Trendrating <info@trendrating.net>
 *
 * @module app/App
 * @summary Boot application retrieving all commons data: user info,
 *          user collections, user strategies, markets, countries, sectors, etc.
 */

// Other imports
// #region --------------------------------------------------- TO BE REMOVED
// #endregion --------------------------------------------------------------
// #region ------------------------------------------------------ singletons
import Http from "../app/Http";
import { decodeUser } from "../../api/account/Decoder";
import { Preferences } from "../../api/account/Preferences";
import { Sessions } from "../../api/account/Sessions";
import { Users } from "../../api/account/Users";
import { Structures } from "../../api/compute/Structures";
import { Utils } from "../../api/compute/Utils";
import { Configuration } from "../../api/Configuration";
import { deepClone } from "../../deepClone";
import { httpAll } from "../../httpAll";
import { AppEnvironment } from "../../types/Defaults";
import { UserListStorage } from "./UserListStorage";
import { PageStatus } from "../../api/account/PageStatus";
import { appCookie } from "./Cookies";
import { config } from "./config-ts";
import { Product } from "../../api/account/Product";
import { widgetsConfiguration } from "./widgets/widgetsConfiguration";
import { recordMap } from "../trendrating-usage/recordMap";
import { Usage } from "../trendrating-usage/Usage";
import { Taxonomy } from "../../trendrating/formatter/Taxonomy";
import { Formatter } from "../../trendrating/utils/Formatter";
import { AppPreferences } from "./preferences";

// #endregion --------------------------------------------------------------

const queryToObject = (queryStr: string) => {
  const queries = queryStr.split("&");

  return queries.reduce((prev, current) => {
    const [key, value] = current.split("=");

    prev[key] = value;

    return prev;
  }, {});
};

// const config = appConfig;

export class System {
  environment: AppEnvironment;

  structuresApi;
  utilsApi;
  sessionApi: Sessions;
  usersApi: Users;
  appPreferences: AppPreferences;

  constructor() {
    //
    // Properties are set step by step
    //
    this.environment = {
      account: {
        // MUST BE REMOVE - use configuration instead
        product: null,

        user: null,
      },
      api: appConfig.api,
      configuration: null,
      customerCare: {
        isImpersonating: false,
        userId: null,
      },
      properties: null,
      taxonomies: null,
      taxonomyFields: null,
      taxonomiesUi: null,
    };

    // this.environment is filled later with user data
    this.sessionApi = new Sessions(this.environment);
    this.usersApi = new Users(this.environment);
    this.appPreferences = new AppPreferences();

    // Check local window.App variable
    if (window.App?.system?.node === "__SERVER_NODE__") {
      window.App.system.node = "local";
    }
  }

  // ----------------------------------------------------- private methods

  _error(error) {
    if (window.location.pathname === "/") {
      // Do nothing
      return;
    }

    // login form
    var url = window.location.href;
    var pattern = RegExp("https?://" + appConfig.app.domain);
    var patternLocal = RegExp("https?://localhost:3000");
    var next = url.replace(pattern, "").replace(patternLocal, "");

    var targetUrl = config.routes.appAccount;
    // Exclude double (or more) nexts (by bug...)
    if (next !== "" && !next.includes("next")) {
      targetUrl = targetUrl + "?next=" + next;
    }

    window.location.href = targetUrl;
  }

  async checkSession() {
    // Logout
    // await this.sessionApi.logout(); // not working!
    console.log("check logout");
    const user = await this.sessionApi.isValid();
    return user;
  }

  async loadProduct(responseSession) {
    var url = window.location.href;
    var queryString = url.substring(url.indexOf("?") + 1, url.length);
    var queryObject: any = queryToObject(queryString);

    var impersonate = appCookie("impersonate", null);
    if ("impersonate" in queryObject) {
      impersonate = queryObject.impersonate;
    }

    if (impersonate != null && impersonate !== "login-reset") {
      appCookie("impersonate", impersonate);

      var userId = parseInt(impersonate);

      this.environment.customerCare.isImpersonating = true;
      this.environment.customerCare.userId = userId;

      const responseImpersonate = await this.usersApi.impersonate(userId);

      // fix server API: impersonate endpoint
      var normalizedResponse = {
        data: {
          JSESSIONID: responseSession.additionalCookies[0].value,
          user: responseImpersonate,
        },
      };

      var userImpersonate = decodeUser(normalizedResponse);

      return this._setUser(userImpersonate);
    }

    return this._setUser(responseSession);
  }

  // a user can have multiple products. The root product product is the
  // main product
  _getRootProduct(user, products) {
    var rootProduct = null;
    var rootProductId = user.productId;

    for (let i = 0, length = products.length; i < length; i++) {
      const product = products[i];
      if (product.id === rootProductId) {
        rootProduct = product;
        break;
      }
    }

    return rootProduct;
  }

  async _setUser(user) {
    this.environment.account.user = user;

    var productId = appCookie("productId", null);

    if (productId != null && productId !== "login-reset") {
      const responseProduct = await this.sessionApi.setProduct(productId);
      user.product = responseProduct.data.product;
    }

    const supportedVariables = {
      permissions: user!.permissions,
      productCode: user.product.productCode,
    };

    this.sessionApi.productCompile(user.product, supportedVariables);

    return user;
  }

  _singletonInit(environment) {
    Http.set("environment", environment);
    this.appPreferences.set("environment", environment);
  }

  // was loadStructure, but it returns a complete environment object now
  async loadEnvironment(user) {
    this.environment.account.product = user["product"];
    this.structuresApi = new Structures(this.environment);
    this.utilsApi = new Utils(this.environment);
    const product = new Product(this.environment);

    const response = await httpAll({
      products: product.all(),
      properties: this.structuresApi.properties(),
      tags: this.structuresApi.tags(),
      taxonomyFields: this.structuresApi.taxonomyFields(),
      taxonomies: this.structuresApi.taxonomies(),
      today: this.utilsApi.today(),
    });

    return this._loadEnvironment(response);
  }

  async _loadEnvironment(response) {
    var properties = response["properties"];
    var tags = response["tags"];
    var taxonomyFields = response["taxonomyFields"];
    var taxonomies = this._prepareTaxonomies(
      response["taxonomies"],
      taxonomyFields
    );
    var today = response["today"];

    var user = this.environment.account.user;
    // #region --------------------------------------------- environment
    if (user == null) {
      throw new Error("User cannot be null at this stage");
    }
    user.rootProduct = this._getRootProduct(
      user,
      response["products"]["data"]["products"]
    );
    this.environment.configuration = new Configuration({
      product: user.product,
      properties: properties,
    });
    this.environment.properties = properties;
    this.environment.tags = tags;
    this.environment.taxonomies = taxonomies;
    this.environment.taxonomyFields = taxonomyFields;
    this.environment.today = today;
    this.environment.preferenceStatus = PageStatus;

    var url = window.location.href;
    var queryString = url.substring(url.indexOf("?") + 1, url.length);
    var queryObject = queryToObject(queryString);
    if ("debug" in queryObject) {
      this.environment.api.isDebug = true;
    }

    // #endregion ------------------------------------------------------

    // ---------------------------------------------------------- LEGACY
    window.App["properties"] = properties;
    window.App["taxonomies"] = taxonomies;
    window.App["user"] = this.environment.account.user;
    // ------------------------------------------------------ END LEGACY

    this._singletonInit(this.environment);

    var apiPreferences = new Preferences(this.environment);

    // lists, strategies and others user's stored objects must be
    // requested always on the fly.
    var requests = {
      //lists: singletonHttp["lists"].get(),
      preferences: apiPreferences.get(),
      alertsNewsFilters: apiPreferences.get("ALERTS_NEWS_FILTER"),
    };

    const response_1 = await httpAll(requests);
    return this._start(response_1);
  }

  _start(data) {
    window.App["user"]["preferences"] = data["preferences"];
    window.App["user"]["alertsNewsFilters"] = data["alertsNewsFilters"];
    window.App["usage"] =
      this.environment.customerCare.isImpersonating === true
        ? {
            record: function () {
              return true;
            },
          }
        : new Usage({
            clientIp: window.App.client.ip,
            debug: appConfig.isDebug,
            product: "Trendrating Web App",
            productFlavorId: window.App.user.product.id,
            productVersion: window.App.system.version,
            recordMap: recordMap,
            storeKey: appConfig.api.usage.storeKey,
            storeUrl: appConfig.api.usage.storeUrl,
            userId: window.App.user.id,
            userType: window.App.user.group,
          });

    window.App["user"]["data"] = {
      collections: new UserListStorage(Http["lists"]),
    };

    // locale settings
    window.App.user.locale = "en-us";

    console.log(
      "-------- Trendrating Momentum Analytics Platform --------",
      "                                                          \n" +
        "                                                          \n" +
        "  _______                 _           _   _               \n" +
        " |__   __|               | |         | | (_)              \n" +
        "    | |_ __ ___ _ __   __| |_ __ __ _| |_ _ _ __   __ _   \n" +
        "    | | '__/ _ \\ '_ \\ / _  | '__/ _' | __| | '_ \\ / _' |  \n" +
        "    | | | |  __/ | | | (_| | | | (_| | |_| | | | | (_| |  \n" +
        "    |_|_|  \\___|_| |_|\\__,_|_|  \\__,_|\\__|_|_| |_|\\__, |  \n" +
        "                                                   __/ |  \n" +
        "                                 (C) 2013 - " +
        new Date().getFullYear() +
        "  |___/   \n" +
        "                                                          \n",
      appConfig.isDebug ? window.App : "",
      "\n----------------------------------------------------------"
    );

    this.environment.http = Http;
    this.environment.preferences = this.appPreferences;
    this.environment.taxon = new Taxonomy();
    this.environment.widgetsConfiguration = widgetsConfiguration;
    this.environment.formatter = new Formatter(this.environment);

    return this.environment;
  }

  _prepareTaxonomies(taxonomies, fields) {
    var _taxonomies = {};

    // this is only to maintain back compatibility
    // for code who still accesses to window.App (deprecated)
    function arrayToMap(input) {
      var elements = deepClone(input);
      var output = {};

      for (let i = 0, length = elements.length; i < length; i++) {
        const element = elements[i];
        output[element.id] = element;
      }

      return output;
    }

    const taxonFields = fields;

    // TODO REFACTORING - ALL AS dstore
    _taxonomies["Markets"] = arrayToMap(taxonomies["Markets"]);
    _taxonomies[taxonFields["security"]["country"]] = arrayToMap(
      taxonomies[taxonFields["security"]["country"]]
    );
    _taxonomies[taxonFields["ETF"]["etfgeo"]] = arrayToMap(
      taxonomies[taxonFields["ETF"]["etfgeo"]]
    );
    _taxonomies[taxonFields["ETF"]["etfclass"]] = arrayToMap(
      taxonomies[taxonFields["ETF"]["etfclass"]]
    );
    _taxonomies[taxonFields["security"]["icb"]] = arrayToMap(
      taxonomies[taxonFields["security"]["icb"]]
    );
    _taxonomies["Indicesclassification"] = arrayToMap(
      taxonomies["Indicesclassification"]
    );
    _taxonomies["SizeClassification"] = arrayToMap(
      taxonomies["SizeClassification"]
    );

    var Structures = this.structuresApi;
    var countries = Structures.where(deepClone(taxonomies["Markets"]));
    var markets = Structures.where(
      deepClone(taxonomies[taxonFields["security"]["country"]])
    );
    var sectors = deepClone(taxonomies[taxonFields["security"]["icb"]]);
    var ui_sectors = Structures.whatUi(
      deepClone(taxonomies[taxonFields["security"]["icb"]])
    );
    var ui_etf_class = deepClone(taxonomies[taxonFields["ETF"]["etfclass"]]);

    _taxonomies["countries"] = countries;
    _taxonomies["markets"] = markets;
    _taxonomies["sectors"] = sectors;
    _taxonomies["ui_sectors"] = ui_sectors;
    _taxonomies["ui_etf_asset_class"] = ui_etf_class;

    var uiEtfClassification: any = [];
    var _uiEtfClassificationNode: any = null;
    for (
      var i = 0, length = taxonomies[taxonFields["ETF"]["etfclass"]].length;
      i < length;
      i++
    ) {
      _uiEtfClassificationNode = deepClone(
        taxonomies[taxonFields["ETF"]["etfclass"]][i]
      );

      if (
        _uiEtfClassificationNode.showInTree &&
        _uiEtfClassificationNode.showInWidget
      ) {
        uiEtfClassification.push(_uiEtfClassificationNode);
      }
    }
    _taxonomies["ui_etf_classification"] = uiEtfClassification;

    var etfProviders: any = [];
    var _etfProviders = taxonomies["ETFProviders"];
    _taxonomies["ETFProviders"] = arrayToMap(
      deepClone(taxonomies["ETFProviders"])
    );
    for (var key in _etfProviders) {
      const datum = _etfProviders[key];
      datum["showInTree"] = true;
      etfProviders.push(datum);
    }
    etfProviders.sort(function (a, b) {
      var propertyL1 = "rank";
      var propertyL2 = "name";

      if (a[propertyL1] > b[propertyL1]) {
        return 1;
      }

      if (a[propertyL1] < b[propertyL1]) {
        return -1;
      }

      if (a[propertyL2] > b[propertyL2]) {
        return 1;
      }

      if (a[propertyL2] < b[propertyL2]) {
        return -1;
      }

      return 0;
    });
    // _taxonomies["ui_etf_provider"] = etfProviders;

    _taxonomies["StockClassification"] = [];
    for (let id in taxonomies["StockClassification"]) {
      _taxonomies["StockClassification"].push(
        taxonomies["StockClassification"][id]
      );
    }

    return _taxonomies;
  }
}
