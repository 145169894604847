import { createContext } from "react";
import { RankOutput } from "../../Ranking";
import {
  RankReducerActionType,
  useRank,
} from "../../../../../../hooks/useRank";

export type ActionRankContextType = {
  rankReducer: (action: RankReducerActionType) => void;
  rankResults: RankOutput;
  constraints: any;
  currentTemplateId: any;
  rankingRules: any[] | undefined;
  highlightListId: number | undefined;
  rankingCache: any;
  universeFrom: "screenerETF" | "screenerStock" | "whiteList" | undefined;
  optionalUniverseFrom:
    | "screenerETF"
    | "screenerStock"
    | "whiteList"
    | undefined;
  optionalConstraintsEnabled: boolean;
  optionalConstraints: any;
  getCurrentState: () => {
    rules: any;
    highlightListId?: number;
    constraints: any;
    universeFrom: "screenerETF" | "screenerStock" | "whiteList";
    fromDate:
      | undefined
      | "PREVIOUS_DAY"
      | "PREVIOUS_WEEK"
      | "PREVIOUS_2_WEEKS"
      | "PREVIOUS_MONTH"
      | "PREVIOUS_3_MONTHS";
    columnsFields: any[];
  };
  rank: (params?: {
    columns?: any[];
    constraints?: any[];
    page?: number;
    itemsPerPage?: number;
    sortField?: string;
    rev?: boolean;
    rules?: any[];
    fromDate?:
      | undefined
      | "PREVIOUS_DAY"
      | "PREVIOUS_WEEK"
      | "PREVIOUS_2_WEEKS"
      | "PREVIOUS_MONTH"
      | "PREVIOUS_3_MONTHS";
  }) => Promise<RankOutput | undefined>;
  getRowsFromRanking: (
    symbols: string[],
    sortField?: string,
    direction?: "desc" | "asc",
    pageNumber?: number,
    rowsPerPage?: number
  ) => Promise<{ total: number; rows: any[] }>;
};

export const ActionRankContext = createContext<ActionRankContextType>(
  undefined!
);

export function RankContextProvider({ children }) {
  const rankContextValue = useRank();
  return (
    <ActionRankContext.Provider value={rankContextValue}>
      {children}
    </ActionRankContext.Provider>
  );
}
