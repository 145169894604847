import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { ColumnDefinition } from "tabulator-tables";
import { filterStockState } from "../../../../../../components/FilterWidget/filterReducer";
import { FilterWidget } from "../../../../../../components/FilterWidget/FilterWidget";
import {
  _getUserPreferences,
  getInitialState,
} from "../../../../../../components/FilterWidget/filterWidgetHelper";
import {
  TableEventsV2,
  TableV2,
} from "../../../../../../components/table/v2/TableCoreV2";
import { TrendratingTableV2 } from "../../../../../../components/table/v2/TableV2";
import { useEnvironment } from "../../../../../../hooks/useEnvironment";
import { useFormatter } from "../../../../../../hooks/useFormatter";

type StocksWidgetProps = {
  data: any;
  stocksProps: {
    stateChangeHandler: (filters: any) => void;
    saveHandler: (filters: any) => void;
  };
  accordionHandler: Function;
  accordionState: boolean;
};

const formatterTcr = (cell, formatter) => {
  const object = cell.getData();
  const value = cell.getValue();

  const tcr = [
    formatter.custom("rating", {
      options: {
        hasTrendArrow: false,
        notAvailable: {
          input: 0,
          output: "",
        },
      },
      output: "HTML",
      value: object["rrr"],
      valueHelper: {
        rateChange: null,
        rateDate: null,
        ratePrev: null,
      },
    }),
    '<span class="tTableAlerts-arrow i-arrow-r"></span>',
    formatter.custom("rating", {
      options: {
        hasTrendArrow: false,
        notAvailable: {
          input: 0,
          output: "",
        },
      },
      output: "HTML",
      value: value,
      valueHelper: {
        rateChange: null,
        rateDate: null,
        ratePrev: null,
      },
    }),
  ].join(" ");

  return tcr;
};

export function StocksWidget({
  data,
  stocksProps,
  accordionHandler,
  accordionState,
}: StocksWidgetProps) {
  const [tableReady, setTableReady] = useState(false);
  const { saveHandler, stateChangeHandler } = stocksProps;
  const formatter = useFormatter();
  const environment = useEnvironment();
  const tableRef = useRef<{ getInstance: () => TableV2 }>();

  const securityLink =
    environment.get("configuration")["configuration"]["pages"]["alerts"][
      "securityLink"
    ];

  const onRowClickHandler = useCallback(
    (event, row) => {
      if (securityLink !== "popup_instrument" && event.type === "rowClick") {
        const data = row.getData();
        const symbol = data?.symbol ?? null;
        if (symbol) {
          const url = `/app/analysis/instrument/${symbol}/`;
          window.location.href = url;
        }
      }
    },
    [securityLink]
  );

  const defaultFilters = useMemo(() => {
    const savedNewsFilters = window.App.user.alertsNewsFilters?.filters ?? null;
    const userPreferencesFromSettings = _getUserPreferences(environment);

    return getInitialState(
      filterStockState,
      savedNewsFilters,
      userPreferencesFromSettings,
      "stock"
    );
  }, [environment]);

  useEffect(() => {
    stateChangeHandler(defaultFilters);
  }, [defaultFilters, stateChangeHandler]);

  useEffect(() => {
    if (tableReady) {
      const table = tableRef.current?.getInstance();
      table?.insertData(data);
    }
  }, [data, tableReady]);

  const taxonomies = useMemo(() => {
    const taxon = environment.get("rawTaxonomies");
    const fields = environment.get("taxonomyFields");

    return {
      what: taxon[fields["security"].icb],
      where: taxon[fields["security"].country],
    };
  }, [environment]);

  const columns: ColumnDefinition[] = useMemo(() => {
    return [
      {
        field: "ticker",
        title: "Ticker",
        resizable: false,
        formatter: (cell) => {
          return (
            formatter.table(cell.getField(), "table", cell.getData()) ?? ""
          );
        },
        widthGrow: 1,
        sorter: "string",
      },
      {
        field: "name",
        title: "Name",
        resizable: false,
        formatter: (cell) => {
          return (
            formatter.table(cell.getField(), "table", cell.getData()) ?? ""
          );
        },
        widthGrow: 3,
        sorter: "string",
      },
      {
        field: "rc",
        title: "Rating",
        resizable: false,
        formatter: (cell) => formatterTcr(cell, formatter),
        sorter: "number",
      },
      {
        field: "country",
        title: "Market",
        resizable: false,
        widthGrow: 2,
        sorter: (a, b) => {
          a = taxonomies.where[a].name.toLowerCase();
          b = taxonomies.where[b].name.toLowerCase();

          if (a > b) {
            return 1;
          } else if (a < b) {
            return -1;
          }

          return 0;
        },
      },
      {
        field: "icb",
        resizable: false,
        title: "Sector",
        formatter: (cell) => {
          return (
            formatter.table(cell.getField(), "table", cell.getData()) ?? ""
          );
        },
        widthGrow: 2,
        sorter: (a, b) => {
          a = taxonomies.what[a].name.toLowerCase();
          b = taxonomies.what[b].name.toLowerCase();

          if (a > b) {
            return 1;
          } else if (a < b) {
            return -1;
          }

          return 0;
        },
      },
      {
        field: "marketcap",
        title: "Mtk. Cap.",
        resizable: false,
        formatter: (cell) => {
          return (
            formatter.table(cell.getField(), "table", cell.getData()) ?? ""
          );
        },
        widthGrow: 2,
        sorter: "number",
      },
    ];
  }, [formatter, taxonomies.what, taxonomies.where]);

  const tableOptions = useMemo(() => {
    return {
      tableOption: {
        columns,
        ajaxSorting: false,
        movableColumns: false,
        resizableColumns: false,
      },
    };
  }, [columns]);

  const tableEvents: TableEventsV2 = useMemo(
    () => ({
      onTableBuilt: () => setTableReady(true),
      rowClick: onRowClickHandler,
      onTableDestroyed: () => {
        setTableReady(false);
      },
    }),
    [onRowClickHandler]
  );

  return (
    <div className="tNews-item">
      <div className="tNews-accordionTitle" onClick={() => accordionHandler()}>
        <h2 className="tNews-accordionTitle-title">
          Stocks
          <div className="tooltip-wrapper-icon"></div>
        </h2>
        <span
          className={`i-${accordionState ? "down" : "up"} tNews-accordionIcon`}
        ></span>
      </div>
      {accordionState && (
        <div>
          <div>
            <FilterWidget
              setFiltersStock={stateChangeHandler}
              saveFiltersStock={saveHandler}
            />
          </div>
          {data && data.length ? (
            <div className="table-with-filters">
              <TrendratingTableV2
                ref={tableRef}
                tableEvents={tableEvents}
                tableOptions={tableOptions}
                rowTooltipFormatter
              />
            </div>
          ) : (
            <div className="empty-news-data">No alert found</div>
          )}
        </div>
      )}
    </div>
  );
}
