import { Box, Button, Card, CardContent, Typography } from "@mui/material";
import { differenceInDays } from "date-fns";
import { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ColumnDefinition } from "tabulator-tables";
import { Preferences } from "../../../../../../api/account/Preferences";
import { Strategies } from "../../../../../../api/compute/Strategies";
import { Chart } from "../../../../../../components/Chart";
import { StrategySummaryModal } from "../../../../../../components/StrategySummary/StrategySummaryModal";
import { TrendratingTableV2 } from "../../../../../../components/table/v2/TableV2";
import { deepClone } from "../../../../../../deepClone";
import { useBroadcast } from "../../../../../../hooks/useBroadcast";
import { useEnvironment } from "../../../../../../hooks/useEnvironment";
import { useFormatter } from "../../../../../../hooks/useFormatter";
import { TDate } from "../../../../../../trendrating/date/TDate";
import { messageSuccess } from "../../../../utils";
import { reactFormatter } from "../../../../../../components/table/v2/TableCoreV2";

type Props = {
  strategy: any;
  analytics: any;
  curves: any;
  onStopTracking: Function;
};

export default function TrackedItem({
  strategy,
  analytics,
  onStopTracking,
  curves,
}: Props) {
  const strategyName = useMemo(() => strategy.name, [strategy.name]);
  const format = useFormatter();
  const navigate = useNavigate();
  const environment = useEnvironment();
  const setup = useMemo(() => environment.get("setup"), [environment]);
  const strategiesAPI = useMemo(() => new Strategies(setup), [setup]);
  const accountPreferencesAPI = useMemo(() => new Preferences(setup), [setup]);

  const date = useMemo(() => new TDate(), []);
  const rebalanceDate = useMemo(() => {
    const today = TDate.daysToDate(TDate.today());
    var _rebalanceDate: any = null;
    switch (strategy.params.strategy.rebalance) {
      case "05_DAYS": {
        _rebalanceDate = date.getForward1Week();
        break;
      }
      case "20_DAYS": {
        _rebalanceDate = date.getForward1Month();
        break;
      }
      case "60_DAYS": {
        _rebalanceDate = date.getForward3Months();
        break;
      } // no default
    }
    var year = _rebalanceDate.getUTCFullYear();
    var month = _rebalanceDate.getUTCMonth();
    var day = _rebalanceDate.getUTCDate();
    let nextRebalance = differenceInDays(_rebalanceDate, today);
    const innerHTML = [
      format.custom("date", {
        options: {
          isMillisecond: true,
          notAvailable: {
            input: null,
            output: "",
          },
        },
        output: "HTML",
        value: Date.UTC(year, month, day),
        valueHelper: null,
      }),
    ];
    if (nextRebalance) {
      innerHTML.push(
        "(in " + nextRebalance + " day" + (nextRebalance > 1 ? "s" : "") + ")"
      );
    } else {
      innerHTML.push("(today)");
    }
    return innerHTML;
  }, [date, format, strategy.params.strategy.rebalance]);
  const data = useMemo(() => {
    let _date = new Date(strategy.params.tracking.trackingDate);
    _date.setDate(_date.getDate() + 1);
    var year = _date.getFullYear();
    var month = _date.getMonth() + 1; // Aggiungiamo 1 perché i mesi partono da 0
    var day = _date.getDate();
    var dataFormattata = year + "-" + month + "-" + day;

    const obj = {
      avgPerfSinceDate: `Since ${dataFormattata}`,
      avgPerfDaily: "1 day",
      avgPerfMonhtly: "1 month",
      avgPerfQuarter: "3 month",
      // avgPerfSixMonths: "6 month",
      avgPerfYearly: "12 month",
      avgPerfYTD: "Year to date",
    };
    const keys = Object.keys(obj);
    let arr: any[] = [];
    keys.forEach((key) => {
      arr.push({
        label: obj[key],
        H: analytics.H[key],
        B: analytics.B[key],
        D: analytics.D[key],
      });
    });
    return arr;
  }, [
    analytics.B,
    analytics.D,
    analytics.H,
    strategy.params.tracking.trackingDate,
  ]);
  const numberFormatter = useCallback(
    (value) =>
      format.custom("number", {
        options: {
          colored: "positive",
          isPercentage: true,
          notAvailable: {
            input: null,
            output: "",
          },
        },
        output: "HTML",
        value: value,
        valueHelper: null,
      }),
    [format]
  );
  const columns = useMemo(() => {
    let cols: ColumnDefinition[] = [
      {
        title: "Return",
        field: "label",
        widthGrow: 1.5,
        hozAlign: "left",
        sorter: "string",
      },
      {
        title: "strategy",
        field: "H",
        formatter: (cell) => {
          const data = cell?.getRow().getData().H;
          return numberFormatter(data);
        },
        sorter: "number",
      },
      {
        title: "Benchmark",
        field: "B",
        formatter: (cell) => {
          const data = cell?.getRow().getData().B;
          return numberFormatter(data);
        },
        sorter: "number",
      },
      {
        title: "Difference",
        field: "D",
        formatter: (cell) => {
          const data = cell?.getRow().getData().D;
          return numberFormatter(data);
        },
        sorter: "number",
      },
      {
        title: "",
        formatter: reactFormatter(<Bars />),
        sorter: "number",
      },
    ];
    return cols;
  }, [numberFormatter]);

  const tracking = useMemo(() => {
    var trackingDay = strategy.params.tracking.trackingDate;
    var fromDateTokens = trackingDay.split("-");
    var fromDate = new Date(
      fromDateTokens[0],
      fromDateTokens[1] - 1, // 0 - Jan, 11 - Dec
      fromDateTokens[2]
    ).getTime();
    var fromDays = TDate.millisecondsToDays(fromDate);
    var fromDateFormatted = format.custom("date", {
      options: {
        isMillisecond: true,
        notAvailable: {
          input: null,
          output: "",
        },
      },
      output: "HTML",
      value: fromDate,
      valueHelper: null,
    });
    return {
      fromDays: fromDays,
      fromDateFormatted: fromDateFormatted,
    };
  }, [format, strategy]);

  const chart = useMemo(() => {
    var fromDays = tracking.fromDays;
    // data
    var pboundaries = { max: 0, min: 999999 };
    var benchmark = null;
    var trackingDays = Math.max(
      0,
      curves.H[curves.H?.length - 1]?.d - fromDays
    );
    var interval = Math.min(260, Math.max(20, trackingDays));
    var rawPrice = curves.H.slice(curves.H?.length - interval);
    var rawBenchmark: any = null;
    if (curves.B && curves.B?.length) {
      rawBenchmark = curves.B.slice(curves.B?.length - interval);
      var factorIndex = 0;
      let i = 0;
      for (i = 0; i < rawPrice.length - 1; i++) {
        if (rawPrice[i].d >= fromDays) {
          break;
        }
      }
      factorIndex = i;
      var factor = rawPrice[factorIndex].v / rawBenchmark[factorIndex].v;
      for (let i = 0, length = rawBenchmark.length; i < length; i++) {
        rawBenchmark[i].v *= factor;
      }
      benchmark = fromJsonToSerieAndMinMax(rawBenchmark, pboundaries);
    }

    var price = fromJsonToSerieAndMinMax(rawPrice, pboundaries);
    var _tracking = fromJsonToSerieAndMinMax(rawPrice, pboundaries);
    _tracking = _tracking.slice(
      Math.max(0, _tracking.length - 1 - trackingDays)
    );
    var curveFirstDay = price[0][0];
    var toDate = new Date(curveFirstDay).getTime();

    var xAxis = {
      endOnTick: false,
      labels: {
        enabled: true,
        style: {
          fontSize: "10px",
        },
      },
      lineWidth: 1,
      plotBands: [
        {
          from: TDate.daysToMilliseconds(fromDays),
          to: toDate,
          color: "rgba(255, 255, 255, 0.5)",
          zIndex: 10,
        },
      ],
      plotLines: [
        {
          color: "#d3d3d3",
          value: TDate.daysToMilliseconds(fromDays),
          width: 1,
        },
      ],
      showFirstLabel: true,
      startOnTick: false,
      tickWidth: 1,
    };

    var yAxis = {
      endOnTick: false,
      gridLineColor: "transparent",
      labels: {
        enabled: true,
        style: {
          fontSize: "10px",
        },
      },
      lineWidth: 1,
      opposite: false,
      showFirstLabel: true,
      showLastLabel: true,
      startOnTick: false,
      tickPixelInterval: 40,
      tickWidth: 1,
    };

    var series: any = [];
    var curveStrategy = {
      lineColor: "#2a7092",
      data: price,
      id: "price",
      lineWidth: 1,
    };
    var curveTracking = {
      lineColor: "#2a7092",
      data: _tracking,
      id: "tracking",
      lineWidth: 2,
    };
    series.push(curveStrategy);
    series.push(curveTracking);
    if (benchmark) {
      var curveBenchmark = {
        lineColor: "#ffcc00",
        data: benchmark,
        id: "benchmark",
        lineWidth: 1,
      };
      series.push(curveBenchmark);
    }
    return (
      <Chart
        options={{
          chart: {
            borderWidth: 0,
            height: 180,
            spacingBottom: 5,
            spacingRight: 12,
            spacingLeft: 5,
            spacingTop: 8,
          },
          xAxis: xAxis,
          yAxis: yAxis,
          series: series,
          credits: { enabled: false },
          exporting: { enabled: false },
          navigator: { enabled: false },
          rangeSelector: { enabled: false },
          plotOptions: {
            line: {
              animation: false,
              enableMouseTracking: false,
            },
          },
          title: { text: null },
          tooltip: { enabled: false },
          scrollbar: { enabled: false },
        }}
      />
    );
  }, [curves.B, curves.H, tracking.fromDays]);

  const [isHover, setIsHover] = useState(false);

  const openStrategy = useCallback(() => {
    const TYPES_DICT = {
      ALPHA: "alpha",
      BUILDER: "advanced",
      COMBINED_STRATEGY: "combined",
      SECTOR_ROTATION: "macro-rotation",
      SMART_BETA: "smart-beta",
    };

    const strategyType = TYPES_DICT?.[strategy.entity_type] ?? "unknown";
    navigate(`/app/strategies/builder/${strategyType}/${strategy.id}/`);
  }, [navigate, strategy.entity_type, strategy.id]);

  const { broadcast } = useBroadcast();

  const stopTracking = useCallback(() => {
    var strategyToUpdate = deepClone(strategy);
    if (strategy.LEGACY != null) {
      delete strategyToUpdate.LEGACY.params.indexContext.trackingDay;
    } else {
      strategyToUpdate.params.tracking = null;
    }

    return strategiesAPI.update(strategyToUpdate).then((strategyUpdated) => {
      var trackedStrategies = accountPreferencesAPI.getTrackedStrategies();
      if (trackedStrategies) {
        var trackedStrategiesUpdated = trackedStrategies.filter(function (
          item
        ) {
          if (item !== strategyUpdated.id) {
            return true;
          }

          return false;
        });
        return accountPreferencesAPI
          .setPreferenceKey("indexes", trackedStrategiesUpdated)
          .then((preferencesUpdated) => {
            // ************************* USAGE *************************
            var usage = window.App.usage;
            var info = {
              action: "STOP_TRACKING",
              actionParam: strategy.id,
              function: "STRATEGY_BUILDER",
            };
            usage.record(info);
            // ************************* USAGE *************************
            const [channel, msg] = messageSuccess(
              "Successfully stopped tracking on <strong>" +
                strategy.name +
                "</strong>."
            );

            broadcast(channel as string, msg);

            onStopTracking(strategy.id);
            return {
              isTracked: false,
              strategy: strategyUpdated,
            };
          });
      }
    });
  }, [
    accountPreferencesAPI,
    broadcast,
    onStopTracking,
    strategiesAPI,
    strategy,
  ]);

  const [showStrategySummary, setShowStrategySummary] = useState(false);

  const tableOptions = useMemo(
    () => ({
      tableOption: {
        columns: columns,
        data,
      },
    }),
    [columns, data]
  );

  return (
    <Card
      sx={{ width: "calc(50% - 4px)", boxShadow: 3, alignSelf: "baseline" }}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <CardContent sx={{ position: "relative" }}>
        {showStrategySummary && (
          <StrategySummaryModal
            strategyId={strategy.id}
            onClose={() => setShowStrategySummary(false)}
          />
        )}
        <Box display={"flex"} flexDirection={"column"} gap={1}>
          <Typography
            fontSize={"0.9vw"}
            sx={{ textTransform: "none" }}
            variant="tr_subtitle_blue"
          >
            <strong>{strategyName}</strong>{" "}
            <span
              style={{ cursor: "pointer" }}
              onClick={() => setShowStrategySummary(true)}
              className="i-info"
            />
          </Typography>
          <Box display={"flex"} gap={1} alignItems={"center"}>
            <Box flex={1} display={"flex"} flexDirection={"column"} gap={1}>
              {chart}
              <Typography sx={{ display: "flex", alignItems: "center" }}>
                Next rebalance <strong>{rebalanceDate}</strong>
              </Typography>
            </Box>
            <Box flex={1} display={"flex"}>
              <TrendratingTableV2 tableOptions={tableOptions} />
            </Box>
          </Box>
        </Box>
        {isHover && (
          <Box
            sx={{ backgroundColor: "rgba(0,0,0,0.7)" }}
            display={"flex"}
            justifyContent={"center"}
            position={"absolute"}
            width={"100%"}
            bottom={0}
            left={0}
          >
            <Box display={"flex"} gap={1} p={1}>
              <Button onClick={openStrategy}>Strategy</Button>
              <Button onClick={stopTracking}>Stop tracking</Button>
            </Box>
          </Box>
        )}
      </CardContent>
    </Card>
  );
}

const fromJsonToSerieAndMinMax = (
  /*Array*/ data: any[],
  /*Object*/ boundaries,
  /*Number*/ translationFactor?
) => {
  var serie: any = [];
  var vy: any = null;
  for (let i = 0, length = data.length; i < length; i++) {
    vy = parseFloat(data[i]["v"]);
    if (vy > boundaries.max) {
      boundaries.max = vy;
    }
    if (vy < boundaries.min) {
      boundaries.min = vy;
    }
    serie.push([
      TDate.daysToMilliseconds(data[i]["d"]), // the date
      translationFactor ? vy / translationFactor : vy, // close
    ]);
  }
  return serie;
};

const Bars = (props) => {
  const data = useMemo(() => {
    let _data = props.cell.getRow().getData();
    return [_data.H, _data.B, _data.D];
  }, [props.cell]);

  const bars = useMemo(() => {
    if (data?.[1] != null) {
      const baseClass = "tPageStrategiesTrackedItem";
      var max: number = 0;
      var value: any = null;
      for (let i = 0, length = data.length; i < length; i++) {
        value = Math.abs(data[i]);
        if (value > max) {
          max = value;
        }
      }
      let arr: any[] = [];
      for (let i = 0, length = data.length; i < length; i++) {
        value = ((data[i] / max) * 100) / 2;
        arr.push(
          <div
            key={i}
            className={`${baseClass + "-bar-container"} ${
              i === 0
                ? baseClass + "-bar-container--index"
                : i === 1
                ? baseClass + "-bar-container--benchmark"
                : ""
            }`}
          >
            {value > 0 && (
              <>
                <span
                  className={baseClass + "-bar " + baseClass + "-bar--empty"}
                  style={{ width: "50%" }}
                />

                <span
                  className={baseClass + "-bar " + baseClass + "-bar--positive"}
                  style={{ width: value + "%" }}
                />
              </>
            )}

            {value < 0 && (
              <>
                <span
                  className={baseClass + "-bar " + baseClass + "-bar--empty"}
                  style={{ width: 50 + value + "%" }}
                />
                <span
                  className={baseClass + "-bar " + baseClass + "-bar--negative"}
                  style={{ width: Math.abs(value) + "%" }}
                />

                <span
                  className={baseClass + "-bar " + baseClass + "-bar--empty"}
                  style={{ width: "50%" }}
                />
              </>
            )}
          </div>
        );
      }
      return <div className={`${baseClass}-bars`}>{arr}</div>;
    } else {
      return <></>;
    }
  }, [data]);
  return <>{bars}</>;
};
