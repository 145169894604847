import { useCallback, useEffect, useMemo, useState } from "react";
import { v4 as uuidv4 } from "uuid";

type PaginatorProps = {
  dataTotalCount: number;
  itemsPerPage: number;
  initValue?: number;
  handlePaginationChange: Function;
  disableAutoPageRefresh?: boolean;
};

export default function Paginator({
  dataTotalCount,
  itemsPerPage,
  initValue = 1,
  handlePaginationChange,
  disableAutoPageRefresh = false,
}: PaginatorProps) {
  const [currentPage, setCurrentPage] = useState(initValue);

  useEffect(() => {
    // Only used in ETF markets (securities View)
    if (!disableAutoPageRefresh) {
      setCurrentPage(1);
    }
  }, [dataTotalCount, disableAutoPageRefresh]);

  const nextPage = useCallback(() => {
    if (currentPage !== dataTotalCount) {
      setCurrentPage(currentPage + 1);
      handlePaginationChange(currentPage + 1);
    }
  }, [currentPage, dataTotalCount, handlePaginationChange]);

  const previousPage = useCallback(() => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
      handlePaginationChange(currentPage - 1);
    }
  }, [currentPage, handlePaginationChange]);

  const getOffset = useCallback(
    (total: number, startFromZero?: boolean) => {
      startFromZero = startFromZero === undefined ? true : startFromZero;
      var correction = 1;
      if (!startFromZero) {
        correction = 0;
      }

      var offset = {
        start: total ? currentPage - correction : 0,
        end: total ? itemsPerPage : 0,
      };

      if (currentPage > 1) {
        offset.end = currentPage * itemsPerPage + 1 - correction;
        offset.start = offset.end - itemsPerPage;

        if (offset.end > total) {
          offset.end = total;
        }
      }

      return offset;
    },
    [currentPage, itemsPerPage]
  );

  const pages = useMemo(
    () => Math.ceil(dataTotalCount / itemsPerPage),
    [dataTotalCount, itemsPerPage]
  );

  const numericSequence = (start, stop, step) =>
    Array.from(
      { length: (stop - start) / step + 1 },
      (_, i) => start + i * step
    );

  const offset = getOffset(dataTotalCount, false);
  const indicatorLabel = useMemo(
    () =>
      offset.start +
      " - " +
      Math.min(offset.end, dataTotalCount) +
      " of " +
      dataTotalCount,
    [dataTotalCount, offset.end, offset.start]
  );

  // Offsets used to get the page to show in navigator
  const offsetStart = useMemo(() => {
    if (currentPage - 2 <= 1) {
      return 2;
    }

    return currentPage - 2;
  }, [currentPage]);

  const offsetEnd = useMemo(() => {
    if (currentPage + 2 >= pages) {
      return pages - 1;
    }

    return currentPage + 2;
  }, [currentPage, pages]);

  const changePage = useCallback(
    (pg) => {
      setCurrentPage(pg);
      handlePaginationChange(pg);
    },
    [handlePaginationChange]
  );

  return (
    <div
      className="pagination"
      style={{
        display: "flex",
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div className="pagination__total">
        <span>{indicatorLabel}</span>
      </div>
      {currentPage === 1 && pages === currentPage ? (
        <></>
      ) : (
        <div className="pagination__navigator">
          {currentPage === 1 ? (
            <>
              <span className="i-left pagination__arrow pagination__arrow--disabled"></span>
              <strong className="pagination__page pagination__page--selected">
                1
              </strong>
            </>
          ) : (
            <>
              <span
                className="i-left pagination__arrow"
                onClick={previousPage}
              ></span>
              <span className="pagination__page" onClick={() => changePage(1)}>
                1 {currentPage - 2 > 1 ? " ..." : ""}
              </span>
            </>
          )}
          {numericSequence(offsetStart, offsetEnd, 1).map((page) => {
            if (page === currentPage) {
              return (
                <strong
                  key={uuidv4()}
                  className="pagination__page pagination__page--selected"
                >
                  {page}
                </strong>
              );
            } else {
              return (
                <span
                  key={uuidv4()}
                  className="pagination__page"
                  onClick={() => changePage(page)}
                >
                  {page}
                </span>
              );
            }
          })}
          {currentPage === pages ? (
            <>
              <strong className="pagination__page pagination__page--selected">
                {pages}
              </strong>
              <span className="i-right pagination__arrow pagination__arrow--disabled"></span>
            </>
          ) : (
            <>
              <span
                className="pagination__page"
                onClick={() => changePage(pages)}
              >
                {(currentPage + 2 < pages ? "... " : " ") + pages}
              </span>
              <span
                className="i-right pagination__arrow"
                onClick={nextPage}
              ></span>
            </>
          )}
        </div>
      )}
    </div>
  );
}
