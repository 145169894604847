/**
 * @author Trendrating <info@trendrating.net>
 *
 * @module api/accounts/Decoder
 * @summary Decode server object as UI suitable object
 *
 */

import { User } from "../../types/Api";

export const decodeUser = (response: any): User => {
  const rawData = response["data"]["user"];

  const user: User = {
    //
    // these cookies have the path '/trendrating-rest'
    // and are used by Web App Python API to perform session
    // validation againt Core API to protect endpoints such as send
    // emails to support and generate PDF reports
    //
    additionalCookies: [
      {
        name: "JSESSIONID",
        path: "/trendrating-rest",
        value: response["data"]["JSESSIONID"],
      },
    ],
    client: rawData.group,
    dailyReportV1: rawData.emailSubscription,
    dailyReportV2: rawData.emailSubscriptionV2,
    email: rawData.email,
    firstName: rawData.firstName,
    permissions: rawData.permissions.map((perm) => perm.permission),

    // DEPRECATED - use groups
    group: rawData.level,

    groups: [rawData.level, rawData.role],
    id: rawData.id,
    lastName: rawData.lastName,
    preferences: null,
    product: rawData.product,
    productId: rawData.productId,
  };

  return user;
};
