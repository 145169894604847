import {
  Box,
  Button,
  Card,
  CardContent,
  Switch,
  Typography,
} from "@mui/material";
import {
  CSSProperties,
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { v4 as uuidv4 } from "uuid";
import { ErrorBoundary } from "../../../../../../ErrorBoundary";
import { TemplatePreferenceRank } from "../../../../../../api/compute/TemplatePreferenceRank";
import { deepClone } from "../../../../../../deepClone";
import { useEnvironment } from "../../../../../../hooks/useEnvironment";
import { DialogSaveComponent } from "../../../../ui/commons/DialogSave/DialogSaveComponent";
import { ActionRankContext } from "../../actions/ActionRankContext/ActionRankContext";
import { EditorTable } from "../EditorTable/EditorTable";
import { OptionalRow } from "../OptionalRow/OptionalRow";
import styles from "./TemplateRow.module.scss";

type TemplateRowProps = {
  idInit?: any;
  getRules: (rules) => void;
  currentRules?: any[];
  page: "screening" | "rank" | "portfolioAnalysis";
};

// Do not change this, it is used as common ID to identify different
// "last used templates"
// Update the lastUsedTemplateSuffix field to have another (or
// reuse) template name
//
// If you change it, edit all occurrences where is used
//
const LAST_USED_TEMPLATE_PREFIX = "LAST_USED_";
const LAST_USED_TEMPLATE_SUFFIX = "RANK_PAGE";
const DEFAULT_TEMPLATE_NAME = "Default Ranking";

const getLastUsedTemplateName = () => {
  var lastUsedTemplateSuffix = LAST_USED_TEMPLATE_SUFFIX || "";
  return LAST_USED_TEMPLATE_PREFIX + lastUsedTemplateSuffix;
};

export function TemplateRow({
  idInit,
  getRules,
  currentRules,
  page,
}: TemplateRowProps) {
  const [templates, setTemplates] = useState();
  const [activeTemplate, setActiveTemplate] = useState<any>(idInit);
  const [selectedItem, setSelectedItem] = useState("");
  const [showSaveDialog, setShowSaveDialog] = useState(false);
  const [dialogSaveConfig, setDialogSaveConfig] = useState(0);
  const [updatedRules, setUpdatedRules] = useState([]);
  const [areRulesChanged, setAreRulesChanged] = useState(false);

  const environment = useEnvironment();
  const appSetup = useMemo(() => environment.get("setup"), [environment]);
  const rankTemplateAPI = useMemo(
    () => new TemplatePreferenceRank(appSetup),
    [appSetup]
  );
  const { rankReducer } = useContext(ActionRankContext);

  const pageConfiguration = useMemo(
    () => appSetup["configuration"].get("analysisList"),
    [appSetup]
  );
  const configurationWidgetRanking = useMemo(
    () =>
      pageConfiguration["tabs"][pageConfiguration["tabsIndex"]["ranking"]][
        "widgets"
      ]["ranking"],
    [pageConfiguration]
  );

  const rankingTemplate = useMemo(() => {
    const template: any = templates?.[activeTemplate ?? "default_template"];

    if (template) {
      const newTemplate = deepClone(template);
      if (currentRules != null && currentRules.length) {
        newTemplate.configuration.ranking = currentRules;
      }

      return newTemplate;
    }

    return template;
  }, [activeTemplate, currentRules, templates]);

  const presetTemplates = useMemo(() => {
    const defaultTemplateObject = {
      configuration: {
        ranking: deepClone(
          configurationWidgetRanking["edit"]["defaultTemplate"]
        ),
      },
      name: "Default Ranking",
      id: null,
    };

    let presetTemplates = [
      ...configurationWidgetRanking["edit"]["presetTemplates"],
      { ...defaultTemplateObject },
    ];

    return presetTemplates;
  }, [configurationWidgetRanking]);

  const prepareTemplates = useCallback(
    (collectedTemplates?) => {
      let userTemplates = collectedTemplates.map((template) => ({
        ...template,
        isEditable: true,
      }));

      let templates: any = [];

      // presetTemplates
      const presetTemplatesList = presetTemplates;
      if (presetTemplates != null) {
        for (let i = 0; i < presetTemplatesList.length; i++) {
          var presetTemplate = presetTemplatesList[i];

          if (presetTemplate.name !== "Default Ranking") {
            /* id is set to name, used to recover used template
                 when loading back the dialog */
            var preparedTemplate = {
              configuration: {
                ranking: presetTemplate.rules,
              },
              name: presetTemplate.name,
              id: presetTemplate.name,
              isEditable: false,
            };
            templates.push(preparedTemplate);
          } else {
            templates.push(presetTemplate);
          }
        }
      }

      return [...userTemplates, ...templates];
    },
    [presetTemplates]
  );

  const searchTemplate = useCallback(
    (key: "default" | "last_used", templates) => {
      const dict = {
        default: "Default Ranking",
        last_used: getLastUsedTemplateName(),
        screeningKey: "LAST_USED_SCREENING",
      };

      if (templates) {
        const templatesList: any = [...Object.values(templates)];

        let lastUsed = templatesList.find((item) => item.name === dict[key]);

        if (key === "last_used" && lastUsed === undefined) {
          // It can be LAST_USED_RANK or LAST_USED_SCREENING based on where they are used
          // If it's already undefined try to search with the other key
          lastUsed = templatesList.find(
            (item) => item.name === dict["screeningKey"]
          );
        }

        return lastUsed;
      }
    },
    []
  );

  const getUserTemplates = useCallback(async () => {
    const _templates = await rankTemplateAPI.get();
    const prepared = prepareTemplates(_templates);

    const templatesMap = prepared.reduce(
      (prev, current) => ({
        ...prev,
        [current?.id ?? "default_template"]: current,
      }),
      {}
    );

    setTemplates(templatesMap);
  }, [prepareTemplates, rankTemplateAPI]);

  useEffect(() => {
    getUserTemplates();

    return () => {
      setTemplates(undefined);
      setSelectedItem("");
      setActiveTemplate(null);
    };
  }, [getUserTemplates]);

  useEffect(() => {
    if (activeTemplate === undefined) {
      // const lastUsedTemplate = searchTemplate("last_used", templates);
      const defaultTemplate = searchTemplate("default", templates)?.id;
      setActiveTemplate(defaultTemplate);

      if (defaultTemplate != null) {
        setSelectedItem(defaultTemplate.name);
      }
    } else if (templates != null) {
      if (activeTemplate !== null) {
        //   setSelectedItem(DEFAULT_TEMPLATE_NAME);
        // } else {
        const currentTemplate: any = templates[activeTemplate];

        setSelectedItem(currentTemplate?.name);
      }
    }
  }, [activeTemplate, searchTemplate, templates]);

  const onChangeTemplate = useCallback(
    (name) => {
      setSelectedItem(name);

      const template = Object.values<any>(templates ?? {}).find(
        (item) => item.name === name
      );

      if (template) {
        setActiveTemplate(template.id);
        rankReducer({ type: "SET_RANKING_RULES", payload: [] });
      }
    },
    [rankReducer, templates]
  );

  const openDialog = useCallback(
    (dialogPreset: "unknown" | "saveAs" | "edit") => {
      const preset = {
        unknown: 0,
        saveAs: 1,
        edit: 2,
      };

      setDialogSaveConfig(preset[dialogPreset]);
      setShowSaveDialog(true);
    },
    []
  );

  const openSaveAsDialog = useCallback(() => {
    openDialog("saveAs");
  }, [openDialog]);

  const openEditDialog = useCallback(() => {
    openDialog("edit");
  }, [openDialog]);

  const onCloseDialogSave = useCallback(() => {
    setDialogSaveConfig(0);
    setShowSaveDialog(false);
  }, []);

  const onRenameTemplate = useCallback(
    async (templateName) => {
      const currentTemplate = templates?.[activeTemplate]
        ? deepClone(templates?.[activeTemplate ?? "default_template"])
        : null;
      const templateToSave: any = currentTemplate
        ? deepClone(currentTemplate)
        : null;

      if (templateToSave) {
        templateToSave["foreignId"] = null;
        templateToSave["name"] = templateName;
        templateToSave["configuration"]["ranking"] = [...updatedRules];
        templateToSave["configuration"]["portfolio"] = null;

        try {
          const savedTemplate = await rankTemplateAPI.save(templateToSave);
          onCloseDialogSave();
          getUserTemplates();
          setSelectedItem(savedTemplate.name);
        } catch (error) {
          console.error(error);
        }
      }
    },
    [
      activeTemplate,
      getUserTemplates,
      onCloseDialogSave,
      rankTemplateAPI,
      templates,
      updatedRules,
    ]
  );

  const updateTemplate = useCallback(async () => {
    const currentTemplate = templates?.[activeTemplate]
      ? deepClone(templates?.[activeTemplate])
      : null;
    const templateToSave: any = currentTemplate
      ? deepClone(currentTemplate)
      : null;

    if (templateToSave) {
      templateToSave["configuration"]["ranking"] = [...updatedRules];
      templateToSave["configuration"]["portfolio"] = null;

      try {
        const savedTemplate = await rankTemplateAPI.save(templateToSave);
        onCloseDialogSave();

        setActiveTemplate(() => {
          getUserTemplates();

          const id = JSON.stringify(savedTemplate.id);
          return id;
        });

        setSelectedItem(savedTemplate.name);
      } catch (error) {
        console.error(error);
      }
    }
  }, [
    activeTemplate,
    getUserTemplates,
    onCloseDialogSave,
    rankTemplateAPI,

    templates,
    updatedRules,
  ]);

  const onSaveNewTemplate = useCallback(
    async (templateName) => {
      const currentTemplate = templates?.[activeTemplate ?? "default_template"]
        ? deepClone(templates?.[activeTemplate ?? "default_template"])
        : null;
      const templateToSave: any = currentTemplate
        ? deepClone(currentTemplate)
        : null;

      if (templateToSave) {
        templateToSave["id"] = null;
        templateToSave["foreignId"] = null;
        templateToSave["name"] = templateName;
        templateToSave["configuration"]["ranking"] = [...updatedRules];
        templateToSave["configuration"]["portfolio"] = null;

        try {
          const savedTemplate = await rankTemplateAPI.save(templateToSave);
          onCloseDialogSave();

          setActiveTemplate(() => {
            getUserTemplates();

            const id = JSON.stringify(savedTemplate.id);
            return id;
          });

          setSelectedItem(savedTemplate.name);
        } catch (error) {
          console.error(error);
        }
      }
    },
    [
      activeTemplate,
      getUserTemplates,
      onCloseDialogSave,
      rankTemplateAPI,

      templates,
      updatedRules,
    ]
  );

  const removeTemplate = useCallback(async () => {
    await rankTemplateAPI.remove({ id: activeTemplate });
    await getUserTemplates();
    onChangeTemplate(DEFAULT_TEMPLATE_NAME);
  }, [activeTemplate, getUserTemplates, onChangeTemplate, rankTemplateAPI]);

  const dialogSaveCallbacks = useMemo(() => {
    const callbacks = {
      0: {
        onSave: null,
        onSaveAs: null,
        onRename: null,
      },
      1: {
        onSave: null,
        onSaveAs: onSaveNewTemplate,
        onRename: null,
      },
      2: {
        onSave: updateTemplate,
        onSaveAs: null,
        onRename: onRenameTemplate,
      },
    };

    return callbacks[dialogSaveConfig];
  }, [dialogSaveConfig, onRenameTemplate, onSaveNewTemplate, updateTemplate]);

  const defaultAtFirst = useCallback((a, b) => {
    if (a.id == null) {
      return -1;
    }

    if (b.id == null) {
      return 1;
    }

    return 0;
  }, []);

  useEffect(() => {
    getRules(updatedRules);
  }, [getRules, updatedRules]);

  useEffect(() => {
    rankReducer({ type: "SET_CURRENT_TEMPLATE_ID", payload: activeTemplate });
  }, [activeTemplate, rankReducer]);

  return (
    <ErrorBoundary fallback={<></>}>
      {showSaveDialog ? (
        <DialogSaveComponent
          item={{
            name: "",
          }}
          dialogType={"Ranking Template"}
          onSave={dialogSaveCallbacks.onSave}
          onSaveAs={dialogSaveCallbacks.onSaveAs}
          onRename={dialogSaveCallbacks.onRename}
          hide={onCloseDialogSave}
        />
      ) : (
        <></>
      )}

      <Box className={styles.templateRowContent}>
        <SectionWithTitle label={"Saved Templates"}>
          <TemplatesList
            onEdit={openEditDialog}
            onDelete={removeTemplate}
            activeItem={selectedItem}
            selectTemplate={onChangeTemplate}
            list={Object.values(templates ?? {}).sort(defaultAtFirst)}
          />
          {areRulesChanged && (
            <Box display={"flex"} justifyContent={"flex-end"} p={1}>
              <Button variant="contained" onClick={openSaveAsDialog}>
                Save as new template
              </Button>
            </Box>
          )}
        </SectionWithTitle>

        <Box display={"flex"} gap={1} flex={4}>
          <Box flex={1} display={"flex"} flexDirection={"column"} gap={1}>
            <SectionWithTitle
              number={page === "rank" ? 2 : undefined}
              subtitle={
                page !== "rank" ? "Rank by the following rules" : undefined
              }
              label={"Ranking rules"}
            >
              {templates && (
                <EditorTable
                  getIsTemplateChanged={setAreRulesChanged}
                  getUpdatedRules={setUpdatedRules}
                  rankingTemplate={rankingTemplate}
                />
              )}
            </SectionWithTitle>

            <OptionalRow page={page} />
          </Box>
        </Box>
      </Box>
    </ErrorBoundary>
  );
}

const TemplatesList = ({
  list,
  activeItem,
  selectTemplate,
  onEdit,
  onDelete,
}: {
  list: any;
  activeItem: string;
  selectTemplate: Function;
  onDelete?: () => void;
  onEdit?: () => void;
}) => {
  const onClickItem = useCallback(
    (name) => {
      selectTemplate(name);
    },
    [selectTemplate]
  );

  const onClickToolsBtn = useCallback(
    (e, toolType: "editBtn" | "deleteBtn") => {
      e.stopPropagation();

      if (toolType === "editBtn") {
        if (onEdit) {
          onEdit();
        }
      } else {
        if (onDelete) {
          onDelete();
        }
      }
    },
    [onDelete, onEdit]
  );

  return (
    <Box className={styles.cardContentListBox}>
      <ul>
        {list.map((listItem) => {
          const highlighted = activeItem === listItem.name;

          if (listItem.name !== "LAST_USED_SCREENING") {
            return (
              <li
                key={uuidv4()}
                onClick={() => onClickItem(listItem.name)}
                className={highlighted ? styles.activeTemplateItem : ""}
              >
                {listItem.isEditable === false ? (
                  <Box display={"flex"} alignItems={"center"} flex={1} gap={1}>
                    <span className="sharedObjectIndicator"></span>{" "}
                    <Typography>{listItem.name}</Typography>
                  </Box>
                ) : (
                  <Typography>{listItem.name}</Typography>
                )}

                {listItem.isEditable && highlighted && (
                  <Box className={styles["cardContentListBox__edit-tools"]}>
                    <Box
                      className={styles["cardContentListBox__edit-tools-btn"]}
                      onClick={(e) => onClickToolsBtn(e, "editBtn")}
                    >
                      <span className="i-edit"></span>
                    </Box>
                    <Box
                      className={styles["cardContentListBox__edit-tools-btn"]}
                      onClick={(e) => onClickToolsBtn(e, "deleteBtn")}
                    >
                      <span className="i-delete"></span>
                    </Box>
                  </Box>
                )}
              </li>
            );
          }

          return <Fragment key={uuidv4()} />;
        })}
      </ul>
    </Box>
  );
};

export const SectionWithTitle = ({
  label,
  children,
  subtitle,
  number,
  wrapperCustomStyle,
  handleSwitch,
  isActive,
}: {
  label: string;
  children: any;
  subtitle?: string;
  number?: number;
  wrapperCustomStyle?: CSSProperties;
  handleSwitch?: (value) => void;
  isActive?: boolean;
}) => {
  const handleSwitchChange = useCallback(
    (e) => {
      const value = e.target.checked;

      if (handleSwitch) {
        handleSwitch(value);
      }
    },
    [handleSwitch]
  );

  return (
    <Box
      className={styles.templateListContainer}
      boxShadow={3}
      sx={{ ...wrapperCustomStyle }}
    >
      <Card sx={{ flex: 1, display: "flex" }}>
        <CardContent className={styles.cardContent}>
          <Box className={styles.cardHeader}>
            {number != null ? (
              <>
                <Box display={"flex"} gap={1} alignItems={"center"}>
                  <Box className={styles.numberBox}>{number}</Box>
                  <Typography>{label}</Typography>
                  {subtitle && (
                    <Typography
                      sx={{
                        textTransform: "none",
                        color: "#5f6368",
                        marginLeft: "20px",
                      }}
                    >
                      {subtitle}
                    </Typography>
                  )}
                </Box>
                <></>
              </>
            ) : (
              <Box>
                <Typography>{label}</Typography>
                {subtitle && (
                  <Typography
                    sx={{
                      textTransform: "none",
                      color: "#5f6368",
                      marginLeft: "20px",
                    }}
                  >
                    {subtitle}
                  </Typography>
                )}
              </Box>
            )}
            {handleSwitch && (
              <Switch
                size="small"
                checked={isActive}
                onChange={handleSwitchChange}
              />
            )}
          </Box>
          {children}
        </CardContent>
      </Card>
    </Box>
  );
};
