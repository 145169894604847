import {
  Box,
  Button,
  MenuItem,
  Pagination,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { ColumnDefinition } from "tabulator-tables";
import { sortAtLevel } from "../../../../api/compute/Taxon";
import {
  TableEventsV2,
  TableV2,
} from "../../../../components/table/v2/TableCoreV2";
import { TrendratingTableV2 } from "../../../../components/table/v2/TableV2";
import { useTaxonomyByType } from "../../../../hooks/useTaxonomyByType";
import { TaxonomyFieldRow } from "../../pages/strategies/builder/editors/advancedWidgets/AdvancedFieldControllers/InvestmentUniverseController/widgets/ScreenerPanel/ScreenerPanel";

type Props = {
  setShowModal: Function;
  setPageNumber: Function;
  setInputContent: Function;
  renderAll: Function;
  onFilterByAssetClass: Function;
  inputContent: string;
  dropdownValue: string;
  assetClassDropdownItems: { label: string; value: string }[];
  pageNumber: number;
  dataTotalCount: any;
  taxonomiesMapX: any;
  allResults: any[];
  onSelectInstrument: Function;
};

export default function ModalContent({
  setPageNumber,
  setInputContent,
  renderAll,
  inputContent,
  assetClassDropdownItems,
  dataTotalCount,
  pageNumber,
  taxonomiesMapX,
  allResults,
  setShowModal,
  onFilterByAssetClass,
  dropdownValue,
  onSelectInstrument,
}: Props) {
  const [selectedMarkets, setSelectedMarkets] = useState([]);
  const { advancedNodesSelector } = useTaxonomyByType("stock");

  //#region - TrendratingTableV2 SETUP AND HANDLERS
  const tableRef = useRef<{ getInstance: () => TableV2 }>();
  const [tableReady, setTableReady] = useState(false);
  const columns: ColumnDefinition[] = useMemo(() => {
    return [
      {
        title: "Ticker",
        field: "ticker",
        sorter: "string",
      },
      { title: "Name", field: "name", sorter: "string" },
      { title: "Asset class", field: "type", sorter: "string" },
      {
        title: "Market",
        field: "country",
        formatter: (e) => {
          let value = e.getValue();
          var market = taxonomiesMapX[value];
          return market ? market.name : "";
        },
        sorter: (a, b) => sortAtLevel(a, b, taxonomiesMapX, "Country"),
      },
    ];
  }, [taxonomiesMapX]);
  useEffect(() => {
    if (tableReady) {
      const table = tableRef?.current?.getInstance();
      table?.insertData(allResults);
    }
  }, [allResults, tableReady]);
  const tableOptions = useMemo(() => {
    return {
      tableOption: {
        ajaxSorting: false,
        movableColumns: false,
        height: "100%",
        columns: columns,
      },
      tableTooltipParams: {
        enabled: true,
      },
    };
  }, [columns]);
  const tableEvents: TableEventsV2 = useMemo(
    () => ({
      onTableBuilt: () => {
        setTableReady(true);
      },
      onTableDestroyed: () => {
        setTableReady(false);
      },
      rowClick: (e: any, row: any) => {
        onSelectInstrument(row.getData());
      },
    }),
    [onSelectInstrument]
  );
  //#endregion

  return (
    <Box
      maxHeight={500}
      height={"100%"}
      overflow={"hidden"}
      display={"flex"}
      flexDirection={"column"}
      gap={1}
    >
      <Box display={"flex"} gap={2}>
        <TextField
          value={inputContent ?? ""}
          onChange={(e) => {
            setPageNumber(1);
            setInputContent(e.target.value);
            renderAll(
              e.target.value,
              1,
              dropdownValue !== "any" ? [dropdownValue] : undefined,
              selectedMarkets
            );
            // renderAll(e.target.value, 1, selectedMarkets);
          }}
          size="small"
        />
        <Box display={"flex"} gap={2} alignItems={"center"}>
          <Typography>Asset class</Typography>
          <Select
            onChange={(e) => {
              onFilterByAssetClass(e.target.value);
            }}
            size="small"
            autoFocus={false}
            value={dropdownValue ?? "any"}
          >
            {assetClassDropdownItems.map((item, index) => (
              <MenuItem key={`${item}_${index}`} value={item.value}>
                <Typography>{item.label}</Typography>
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box display={"flex"} gap={2} alignItems={"center"}>
          <Typography>Market</Typography>
          <TaxonomyFieldRow
            label={"Markets"}
            handleValuesSelection={(e) => {
              setSelectedMarkets(
                advancedNodesSelector(e[0].value.segments, "country", true)
              );
              renderAll(
                undefined,
                1,
                dropdownValue !== "any" ? [dropdownValue] : undefined,
                advancedNodesSelector(e[0].value.segments, "country", true)
              );
            }}
            taxonomyMap={taxonomiesMapX}
            selectedValues={selectedMarkets}
            taxonomyType={"security"}
            taxonomyField={"country"}
            phraseOnEmpty="World wide"
          />
        </Box>
      </Box>
      <Box>
        <Pagination
          color="primary"
          page={pageNumber}
          onChange={(event: React.ChangeEvent<unknown>, value: number) => {
            setPageNumber(value);
            renderAll(
              undefined,
              value,
              dropdownValue !== "any" ? [dropdownValue] : undefined,
              selectedMarkets
            );
          }}
          count={
            dataTotalCount / 25 > parseInt((dataTotalCount / 25).toFixed())
              ? parseInt((dataTotalCount / 25).toFixed()) + 1
              : parseInt((dataTotalCount / 25).toFixed())
          }
        />
      </Box>
      <Box overflow={"auto"}>
        <TrendratingTableV2
          rowTooltipFormatter
          ref={tableRef}
          tableEvents={tableEvents}
          tableOptions={tableOptions}
        />
      </Box>
      <Box display={"flex"} justifyContent={"flex-end"}>
        <Button
          onClick={() => {
            setShowModal(false);
            setPageNumber(1);
          }}
        >
          Close
        </Button>
      </Box>
    </Box>
  );
}
