/**
 * @author Trendrating <info@trendrating.net>
 *
 * @module trendrating-report/data/Peer
 * @summary Retrieves data about peer sections
 *
 */

import {
  formatTaxonPrefixingParent,
  getTaxonById,
} from "../../../api/compute/Taxon";
import { httpAll } from "../../../httpAll";
import { decodePeerId } from "../../../api/utils";
import { deepClone } from "../../../deepClone";

export class Peer {
  storage: any;

  constructor(storage: any) {
    if (storage == null) {
      throw new Error("[CRITICAL] No storage!");
    }
    this.storage = storage;
  }

  sortListByProperty(list, property, ascending?) {
    const isAscending = ascending ?? true;

    if (isAscending) {
      return list.sort((a, b) => {
        if (a[property] < b[property]) {
          return -1;
        }

        if (a[property] > b[property]) {
          return 1;
        }

        return 0;
      });
    } else {
      return list.sort((a, b) => {
        if (a[property] > b[property]) {
          return -1;
        }

        if (a[property] < b[property]) {
          return 1;
        }

        return 0;
      });
    }
  }

  sortBySize(peerSerie, isAscending, key) {
    const sizeOrder = ["Large", "Mid", "Small", "Micro"];
    const orderedList: any = [];

    for (const level of sizeOrder) {
      const levelInSerie = peerSerie.find((serie) => serie[key] === level);
      if (levelInSerie != null) {
        orderedList.push(levelInSerie);
      }
    }

    return isAscending === false ? orderedList.reverse() : orderedList;
  }

  getDispersion(peer, section, peerType) {
    let analytic: string;

    switch (section["content"]["timeframe"]) {
      case "1_week":
        analytic = "pw";
        break;
      case "1_month":
        analytic = "pm";
        break;
      case "3_months":
        analytic = "pq";
        break;
      case "6_months":
        analytic = "ps";
        break;
      case "12_months":
        analytic = "py";
        break;
      default:
        throw new Error(
          `Unknown performance ${section["content"]["timeframe"]}`
        );
    }

    let percentage;
    let interval =
      typeof section["content"]["intervals"] === "string"
        ? parseInt(section["content"]["intervals"])
        : section["content"]["intervals"];

    switch (interval) {
      case 4:
        percentage = 25;
        break;
      case 10:
        percentage = 10;
        break;
      case 20:
        percentage = 5;
        break;
      default:
        throw new Error(
          `Unknown percentage ${section["content"]["intervals"]}`
        );
    }

    return this.storage.analytics({
      analytics: [
        `${analytic}#avg#false`,
        `${analytic}#max#false`,
        `${analytic}#min#false`,
        "cardinality",
      ],
      clusters: [
        {
          dimension: analytic,
          transform: {
            function: "topbottom",
            params: {
              perc: percentage,
              trimOutliers: false,
            },
          },
        },
      ],
      peerId: peer["id"],
      segment: null,
    });
  }

  async getDispersionRating(peer, section) {
    const peerId = peer.id;
    const clusterParams = {
      analytics: ["TCR", "A_%", "B_%", "C_%", "D_%", "cardinality"],
      peerId,
      segment: section["content"]["segment"],
    };

    const peerType = peer.type;

    if (peerType === "ETF") {
      const segmentsETF = {
        "1 Industry": "AssetClass",
        "3 Sector": "Specialty",
        "4 Subsector": "Theme",
        Country: "etfgeo",
        Region: "etfgeoRegion",
        Area: "etfgeoArea",
      };

      clusterParams["segment"] = segmentsETF[section["content"]["segment"]];
    }
    const storage = this.storage;
    const peerData = await storage.analyticsBySegment(clusterParams);

    //Format response
    const clustersStats = peerData["clustersStats"];
    const stats = clustersStats["stats"];

    const data = Object.entries(stats).map(([key, value]: any) => ({
      ...stats[key],
      name: key,
      AB: (value?.["A_%"] ?? 0) + (value?.["B_%"] ?? 0),
      CD: (value?.["C_%"] ?? 0) + (value?.["D_%"] ?? 0),
    }));

    const sortingMethod = section["content"]["sort"];

    const { property, descending } = sortingMethod;

    let criteria = "name";

    switch (property) {
      case "_s_label":
      default:
        break;
      case "cardinality":
        criteria = "cardinality";

        break;

      case "percentageAB":
        criteria = "AB";

        break;

      case "percentageCD":
        criteria = "CD";

        break;

      case "tcr":
        criteria = "TCR";
    }

    const isAscending = !descending;
    const segment = section["content"]["segment"];

    const orderedList =
      segment === "3 Level" && criteria === "name"
        ? this.sortBySize(data, isAscending, "name")
        : this.sortListByProperty(data, criteria, isAscending);

    return {
      dispersion: orderedList,
      section,
      peerType,
    };
  }

  async getDispersionRatio(peer, section) {
    const peerId = peer.id;
    let segment: string = section["content"]["segment"];
    const timeframe: "today" | "lastWeek" | "lastMonth" =
      section["content"]["timeframe"];

    let upgradesAnalitic = "upgrades";
    let downgradesAnalitic = "downgrades";

    switch (timeframe) {
      case "today":
      default:
        break;
      case "lastWeek": {
        upgradesAnalitic += "_W";
        downgradesAnalitic += "_W";

        break;
      }
      case "lastMonth": {
        upgradesAnalitic += "_M";
        downgradesAnalitic += "_M";

        break;
      }
    }

    const peerType = peer.type;

    if (peerType === "ETF") {
      const segmentsETF = {
        "1 Industry": "AssetClass",
        "3 Sector": "Specialty",
        "4 Subsector": "Theme",
        Country: "etfgeo",
        Region: "etfgeoRegion",
        Area: "etfgeoArea",
      };

      segment = segmentsETF[segment];
    }

    const response = await this.storage.analyticsBySegment({
      analytics: ["TCR", upgradesAnalitic, downgradesAnalitic],
      peerId: peerId,
      segment: segment,
    });

    const stats = response["clustersStats"]["stats"];

    const data = Object.entries<any>(stats).map(([key, value]) => {
      const upgrades = value[upgradesAnalitic];
      const downgrades = value[downgradesAnalitic];

      return {
        upgrades: upgrades,
        downgrades: downgrades,
        ratio: upgrades !== 0 ? upgrades / (upgrades + downgrades) : upgrades,
        tcr: value["TCR"],
        name: key,
      };
    });

    return {
      data:
        segment !== "3 Level"
          ? this.sortListByProperty(data, "name")
          : this.sortBySize(data, false, "name"),
      peerType,
    };
  }

  async getDispersionBySegment(peer, section) {
    const trimOutliers = section["content"]["trimOutliers"];
    const peerId = peer.id;
    let segment: string = section["content"]["segment"];

    const peerType = peer.type;

    if (peerType === "ETF") {
      const segmentsETF = {
        "1 Industry": "AssetClass",
        "3 Sector": "Specialty",
        "4 Subsector": "Theme",
        Country: "etfgeo",
        Region: "etfgeoRegion",
        Area: "etfgeoArea",
      };

      segment = segmentsETF[segment];
    }

    const aggregatedDispersionTitleDict = {
      "1 Industry": "All Sectors",
      "3 Sector": "All Industries",
      "4 Subsector": "All Themes",
      Country: "All Countries",
      Region: "All Regions",
      Area: "All Areas",
      AssetClass: "All Asset Classes",
      Specialty: "All Specialties",
      Theme: "All Themes",
      etfgeo: "All Inv. Regions",
      etfgeoRegion: "All Regions",
      etfgeoArea: "All Areas",
    };

    let analytic: string;

    switch (section["content"]["timeframe"]) {
      case "1_week":
        analytic = "pw";
        break;
      case "1_month":
        analytic = "pm";
        break;
      case "3_months":
        analytic = "pq";
        break;
      case "6_months":
        analytic = "ps";
        break;
      case "12_months":
        analytic = "py";
        break;
      default:
        throw new Error(
          `Unknown performance ${section["content"]["timeframe"]}`
        );
    }

    let percentage;
    const intervals =
      typeof section["content"]["intervals"] === "string"
        ? parseInt(section["content"]["intervals"])
        : section["content"]["intervals"];
    switch (intervals) {
      case 4:
        percentage = 25;
        break;
      case 10:
        percentage = 10;
        break;
      case 20:
        percentage = 5;
        break;
      default:
        throw new Error(
          `Unknown percentage ${section["content"]["intervals"]}`
        );
    }

    const aggregatedDispersion = await this.storage.analytics({
      analytics: [
        `${analytic}#avg#false`,
        `${analytic}#max#false`,
        `${analytic}#min#false`,
        "cardinality",
      ],
      clusters: [
        {
          dimension: analytic,
          transform: {
            function: "topbottom",
            params: {
              perc: percentage,
              trimOutliers,
            },
          },
        },
      ],
      peerId: peerId,
      segment: null,
    });

    const aggregateDispersionInfo = {
      dispersion: aggregatedDispersion,
      name: aggregatedDispersionTitleDict[segment] ?? "All",
      peer: { id: peerId },
    };

    const response = await this.storage.analytics({
      analytics: [
        `${analytic}#avg#false`,
        `${analytic}#max#false`,
        `${analytic}#min#false`,
        "cardinality",
      ],
      clusters: [
        {
          dimension: analytic,
          transform: {
            function: "topbottom",
            params: {
              perc: percentage,
              trimOutliers,
            },
          },
        },
      ],
      peerId: peerId,
      segment: segment,
    });

    response["All"] = aggregateDispersionInfo;

    const rawTaxonomies = this.storage.environment["taxonomies"];
    const txFields = this.storage.environment["taxonomyFields"];
    const txWhere = rawTaxonomies[txFields["security"]["country"]];
    const txWhat = rawTaxonomies[txFields["security"]["sector"]];
    const txWhereEtf = rawTaxonomies[txFields["ETF"]["etfgeo"]];
    const txWhatEtf = rawTaxonomies[txFields["ETF"]["etfclass"]];

    const taxonomies = [
      txWhere,
      txWhat,
      txWhatEtf,
      txWhereEtf,
      rawTaxonomies["SizeClassification"],
    ];

    if (segment === "Region" || segment === "etfgeoRegion") {
      for (const [key, value] of Object.entries<any>(response)) {
        const { where } = decodePeerId(key);

        const tx = getTaxonById(where, taxonomies, null);
        const name =
          tx != null
            ? formatTaxonPrefixingParent(tx, taxonomies, "Region")
            : key;

        value["name"] = name ?? key;
      }
    }

    if (segment === "Theme") {
      for (const [key, value] of Object.entries<any>(response)) {
        const { what } = decodePeerId(key);

        const tx = getTaxonById(what, taxonomies, null);
        value["name"] =
          tx != null
            ? formatTaxonPrefixingParent(tx, taxonomies, "4 Subsector")
            : key;
      }
    }

    if (segment === "Specialty") {
      for (const [key, value] of Object.entries<any>(response)) {
        const { what } = decodePeerId(key);

        const tx = getTaxonById(what, taxonomies, null);
        value["name"] =
          tx != null
            ? formatTaxonPrefixingParent(tx, taxonomies, "3 Sector")
            : key;
      }
    }

    return { data: response, peerType };
  }

  async getDispersionChildren(peer, section) {
    var peerId = peer.id;
    var peerInfo = this.storage.getWhatWhereInfo(peerId);
    var whatType = peerInfo.whatType;
    var whereType = peerInfo.whereType;
    const peerType = peer.type === "ETF" ? "ETF" : "stock";
    const peerFocus = section.content.focusOn === "what" ? whatType : whereType;

    const segmentTransformationMap = {
      ETF: {
        what: {
          "0 root": "AssetClass",
          "1 Industry": "Specialty",
          "3 Sector": "Theme",
          "4 Subsector": "Theme",
        },
        where: {
          World: "etfgeoArea",
          Area: "etfgeoRegion",
          Country: "etfgeo",
          Region: "etfgeo",
        },
      },
      stock: {
        what: {
          "0 root": "1 Industry",
          "1 Industry": "3 Sector",
          "3 Sector": "3 Sector",
        },
        where: {
          Area: "Region",
          Country: "Country",
          Region: "Country",
        },
      },
    };

    let segment =
      segmentTransformationMap[peerType][section.content.focusOn][peerFocus];

    let analytic: string;

    switch (section["content"]["timeframe"]) {
      case "1_week":
        analytic = "pw";
        break;
      case "1_month":
        analytic = "pm";
        break;
      case "3_months":
        analytic = "pq";
        break;
      case "6_months":
        analytic = "ps";
        break;
      case "12_months":
        analytic = "py";
        break;
      default:
        throw new Error(
          `Unknown performance ${section["content"]["timeframe"]}`
        );
    }
    const interval =
      typeof section["content"]["intervals"] === "string"
        ? parseInt(section["content"]["intervals"])
        : section["content"]["intervals"];

    let percentage;
    switch (interval) {
      case 4:
        percentage = 25;
        break;
      case 10:
        percentage = 10;
        break;
      case 20:
        percentage = 5;
        break;
      default:
        throw new Error(
          `Unknown percentage ${section["content"]["intervals"]}`
        );
    }

    return this.storage.analytics({
      analytics: [
        `${analytic}#avg#false`,
        `${analytic}#max#false`,
        `${analytic}#min#false`,
        "cardinality",
      ],
      clusters: [
        {
          dimension: analytic,
          transform: {
            function: "topbottom",
            params: {
              perc: percentage,
              trimOutliers: false,
            },
          },
        },
      ],
      peerId: peerId,
      segment: segment,
    });
  }

  getMarketAbChanges(peer, section) {
    return this._getPeerWithChildren(peer, section);
  }

  getMarketOverview(peer, section) {
    return this._getPeerWithChildren(peer, section);
  }

  getMarketTcrChanges(peer, section) {
    return this._getPeerWithChildren(peer, section);
  }

  getMarketUpgradesDowngrades(peer, section) {
    return this._getPeerWithChildren(peer, section);
  }

  getSectorAbChanges(peer, section) {
    return this._getPeerWithChildren(peer, section);
  }

  getSectorOverview(peer, section) {
    return this._getPeerWithChildren(peer, section);
  }

  getOverview(peer, section) {
    return this.getPeerUsingSegment(peer, section);
  }

  getTcrChanges(peer, section) {
    return this.getPeerUsingSegment(peer, section);
  }

  getAbChanges(peer, section) {
    return this.getPeerUsingSegment(peer, section);
  }

  getTcrFocus(peer, section) {
    return this.getPeerUsingSegment(peer, section);
  }

  getUpgradesDowngradesFocus(peer, section) {
    return this.getPeerUsingSegment(peer, section);
  }

  getPeerUpgradesDowngrades(peer, section) {
    return this.getPeerUsingSegment(peer, section);
  }

  getSectorTcrChanges(peer, section) {
    return this._getPeerWithChildren(peer, section);
  }

  getSectorUpgradesDowngrades(peer, section) {
    return this._getPeerWithChildren(peer, section);
  }

  // getTcr: function (peer, section) {},
  prepareDispersionBySector(data, section) {
    var prepared = {
      bottom: {
        average: data["bottom"]["average"],
        cardinality: data["bottom"]["dataTotalCount"],
        max: data["bottom"]["max"],
        min: data["bottom"]["min"],
      },
      middle: {
        average: data["middle"]["average"],
        cardinality: data["middle"]["dataTotalCount"],
        max: data["middle"]["max"],
        min: data["middle"]["min"],
      },
      top: {
        average: data["top"]["average"],
        cardinality: data["top"]["dataTotalCount"],
        max: data["top"]["max"],
        min: data["top"]["min"],
      },
    };

    return prepared;
  }

  prepareDispersion(data, section, peerType) {
    var total =
      data["bottom"]["dataTotalCount"] +
      data["middle"]["dataTotalCount"] +
      data["top"]["dataTotalCount"];

    if (total < 10) {
      // irrelevant data
      return {
        bottom: null,
        middle: null,
        top: null,
        peerType: peerType,
      };
    }

    var prepared = {
      bottom: {
        average: data["bottom"]["average"],
        cardinality: data["bottom"]["dataTotalCount"],
        max: data["bottom"]["max"],
        min: data["bottom"]["min"],
      },
      middle: {
        average: data["middle"]["average"],
        cardinality: data["middle"]["dataTotalCount"],
        max: data["middle"]["max"],
        min: data["middle"]["min"],
      },
      top: {
        average: data["top"]["average"],
        cardinality: data["top"]["dataTotalCount"],
        max: data["top"]["max"],
        min: data["top"]["min"],
      },
    };

    return { dispersion: prepared, peerType };
  }

  prepareSeriesDispersionChart(data, section) {
    const values = this.prepareDispersionBySegment(data, section);
    const peerSerie: any = [];
    const segment = section["content"]["segment"];

    for (const [key, peer] of Object.entries<any>(values)) {
      if (
        peer?.dispersion?.top?.average === 0 &&
        peer?.dispersion?.middle?.average === 0 &&
        peer?.dispersion?.bottom?.average === 0
      ) {
        continue; // Ignore if there is no data
      }
      const name = peer?.label ?? peer?.name ?? key;
      // Filter "Any" field
      if (name.toLowerCase() === "any") {
        continue;
      }
      peerSerie.push({
        name: name,
        _s_label: peer?._s_label ?? name,
        top: peer?.dispersion?.top?.average,
        topCardinality: peer?.dispersion?.top?.dataTotalCount,
        middle: peer?.dispersion?.middle?.average,
        middleCardinality: peer?.dispersion?.middle?.dataTotalCount,
        bottom: peer?.dispersion?.bottom?.average,
        bottomCardinality: peer?.dispersion?.bottom?.dataTotalCount,
        maxBottom: peer?.dispersion?.bottom?.max,
        maxTop: peer?.dispersion?.top?.max,
        minBottom: peer?.dispersion?.bottom?.min,
        minTop: peer?.dispersion?.top?.min,
        id: key,
      });
    }

    const sort = section["content"]["sort"];
    const { descending, property } = sort ?? {
      descending: true,
      property: "dispersionAverageTop",
    };
    let criteria = "_s_label";

    switch (property) {
      case "_s_label":
      default:
        break;

      case "dispersionAverageTop":
        criteria = "top";

        break;

      case "dispersionAverageMid":
        criteria = "middle";

        break;

      case "dispersionAverageBottom":
        criteria = "bottom";

        break;
    }

    const isAscending = !descending;

    return segment === "3 Level" && criteria === "_s_label"
      ? this.sortBySize(peerSerie, isAscending, "_s_label")
      : this.sortListByProperty(peerSerie, criteria, isAscending);
  }

  prepareDispersionBySegment(data, section) {
    var dispersions: any = [];

    if (data == null) {
      return dispersions;
    }

    for (const peer of Object.values(data) as any[]) {
      if (peer.dispersion != null) {
        dispersions.push({
          name: peer.name,
          dispersion: this.prepareDispersionBySector(peer.dispersion, section),
        });
      }
    }

    const sort = section["content"]["sort"];
    const { descending, property } = sort ?? {
      descending: false,
      property: "_s_label",
    };
    let criteria = "name";

    switch (property) {
      case "_s_label":
      default:
        break;

      case "dispersionAverageTop":
        criteria = "top";

        break;

      case "dispersionAverageMid":
        criteria = "middle";

        break;

      case "dispersionAverageBottom":
        criteria = "bottom";

        break;
    }

    const isAscending = !descending;
    const segment = section["content"]["segment"];

    return segment === "3 Level" && criteria === "name"
      ? this.sortBySize(dispersions, isAscending, "name")
      : dispersions.sort((a, b) => {
          const secondElement =
            criteria === "name"
              ? a["name"]
              : a["dispersion"][criteria]["average"];
          const firstElement =
            criteria === "name"
              ? b["name"]
              : b["dispersion"][criteria]["average"];

          if (isAscending) {
            if (firstElement < secondElement) {
              return 1;
            }

            if (firstElement > secondElement) {
              return -1;
            }

            return 0;
          } else {
            if (firstElement > secondElement) {
              return 1;
            }

            if (firstElement < secondElement) {
              return -1;
            }

            return 0;
          }
        });
  }

  prepareDispersionChildren(data, section, peerType) {
    var dispersions: any = [];

    if (data == null) {
      return dispersions;
    }

    for (const peer of Object.values(data) as any[]) {
      if (peer.dispersion != null) {
        dispersions.push({
          name: peer.name,
          dispersion: this.prepareDispersion(peer.dispersion, section, peerType)
            .dispersion,
        });
      }
    }

    return dispersions;
  }

  prepareMarketAbChanges(peers, section) {
    function prepareItem(peer, section, itemType) {
      var timeframe = section["content"]["timeframe"];
      timeframe = timeframe === "today" ? "yesterday" : timeframe;

      var abPercentage = peer["statistic"]["abPercentage"]["today"];
      var abPercentageFrom = peer["statistic"]["abPercentage"][timeframe];
      // 0: no change, 1: positive, -1: negative
      var abPercentageChange =
        abPercentageFrom > 0 ? 1 : abPercentageFrom < 0 ? -1 : 0;

      return {
        abPercentage: abPercentage,
        abPercentageChange: abPercentageChange,
        abPercentageFrom: abPercentageFrom,
        itemType: itemType,
        name: peer["name"],
      };
    }

    return this._preparePeerWithChildren(peers, section, prepareItem);
  }

  prepareMarketOverview(data, section) {
    function prepareItem(peer, section, itemType) {
      var timeframe = "today";

      return {
        abPercentage: peer["statistic"]["abPercentage"][timeframe],
        cardinality: peer["info"]["cardinality"],
        itemType: itemType,
        name: peer["name"],
        tcr: peer["tcr"][timeframe],
      };
    }

    return this._preparePeerWithChildren(data, section, prepareItem);
  }

  prepareMarketTcrChanges(peers, section) {
    function prepareItem(peer, section, itemType) {
      var timeframe = section["content"]["timeframe"];

      return {
        itemType: itemType,
        name: peer["name"],
        tcr: peer["tcr"]["today"],
        tcrFrom: peer["tcr"][timeframe === "today" ? "yesterday" : timeframe],
      };
    }

    return this._preparePeerWithChildren(peers, section, prepareItem);
  }

  prepareMarketUpgradesDowngrades(peers, section) {
    function prepareItem(peer, section, itemType) {
      var timeframe = section["content"]["timeframe"];

      var downgrades = peer["alerts"][timeframe]["downgrades"];
      var upgrades = peer["alerts"][timeframe]["upgrades"];
      var ratio = peer["alerts"][timeframe]["ratio"];

      // same logic in
      // app/pages/analysisMarkets/widgets/PeerChildren
      // should be unified
      ratio = ratio < 0 || ratio >= 1000 ? null : ratio;

      return {
        downgrades: downgrades,
        itemType: itemType,
        name: peer["name"],
        ratio: ratio,
        upgrades: upgrades,
      };
    }

    return this._preparePeerWithChildren(peers, section, prepareItem);
  }

  prepareUpgradesDowngradesFocus(peers, section) {
    function prepareItem(peer, section, itemType) {
      const timeframe = section["content"]["timeframe"];

      const upgrades = peer["statistic"]["upgrades"][timeframe]["number"];
      const upgradesPerc =
        peer["statistic"]["upgrades"][timeframe]["percentage"];
      const downgrades = peer["statistic"]["downgrades"][timeframe]["number"];
      const downgradesPerc =
        peer["statistic"]["downgrades"][timeframe]["percentage"];

      return {
        upgrades,
        upgradesPerc,
        downgrades,
        downgradesPerc,
        name: peer["name"],
      };
    }

    return this._preparePeerWithChildren(peers, section, prepareItem);
  }

  preparePeerTable(peer, section) {
    function prepareItem(peer, section, itemType) {
      const timeframe = section["content"]["timeframe"];

      const name = peer["name"];
      const tcr = peer["tcr"]["today"];
      const cardinality = peer["info"]["cardinality"];
      const abPercentage = peer["statistic"]["abPercentage"]["today"];
      const cdPercentage = peer["statistic"]["cdPercentage"]["today"];
      const tcrInfo = deepClone(peer["tcr"]);
      const upgrades = peer["statistic"]["upgrades"][timeframe]["number"];
      const upgradesPerc =
        peer["statistic"]["upgrades"][timeframe]["percentage"];
      const downgrades = peer["statistic"]["downgrades"][timeframe]["number"];
      const downgradesPerc =
        peer["statistic"]["downgrades"][timeframe]["percentage"];

      // Calculate abChanges
      const timeSegment = timeframe;

      // Adjust timeframe for changes
      const percentageFrom =
        peer["statistic"]?.["abPercentage"]?.[
          timeSegment === "today" ? "yesterday" : timeSegment
        ];

      // 0: no change, 1: positive, -1: negative
      const abChanges = percentageFrom > 0 ? 1 : percentageFrom < 0 ? -1 : 0;

      return {
        name,
        tcr,
        cardinality,
        abPercentage,
        cdPercentage,
        tcrInfo,
        upgrades,
        downgrades,
        upgradesPerc,
        downgradesPerc,
        abChanges,
        abChangesPercentageFrom: percentageFrom,
      };
    }

    return this._preparePeerWithChildren(peer, section, prepareItem);
  }

  prepareTcrFocus(peers, section) {
    function prepareItem(peer, section, itemType) {
      const abPercentage = peer["statistic"]["abPercentage"]["today"];
      const cdPercentage = peer["statistic"]["cdPercentage"]["today"];
      const tcr = peer["tcr"]["today"];
      const cardinality = peer["info"]["cardinality"];

      return {
        abPercentage: abPercentage,
        cdPercentage: cdPercentage,
        tcr,
        cardinality,
        itemType: itemType,
        name: peer["name"],
      };
    }

    return this._preparePeerWithChildren(peers, section, prepareItem);
  }

  prepareSectorAbChanges(peers, section) {
    function prepareItem(peer, section, itemType) {
      var timeframe = section["content"]["timeframe"];
      timeframe = timeframe === "today" ? "yesterday" : timeframe;

      var abPercentage = peer["statistic"]["abPercentage"]["today"];
      var abPercentageFrom = peer["statistic"]["abPercentage"][timeframe];
      // 0: no change, 1: positive, -1: negative
      var abPercentageChange =
        abPercentageFrom > 0 ? 1 : abPercentageFrom < 0 ? -1 : 0;

      return {
        abPercentage: abPercentage,
        abPercentageChange: abPercentageChange,
        abPercentageFrom: abPercentageFrom,
        itemType: itemType,
        name: peer["name"],
      };
    }

    return this._preparePeerWithChildren(peers, section, prepareItem);
  }

  prepareSectorOverview(data, section) {
    function prepareItem(peer, section, itemType) {
      var timeframe = "today";

      return {
        abPercentage: peer["statistic"]["abPercentage"][timeframe],
        cardinality: peer["info"]["cardinality"],
        itemType: itemType,
        name: peer["name"],
        tcr: peer["tcr"][timeframe],
      };
    }

    return this._preparePeerWithChildren(data, section, prepareItem);
  }

  prepareSectorTcrChanges(peers, section) {
    function prepareItem(peer, section, itemType) {
      var timeframe = section["content"]["timeframe"];

      return {
        itemType: itemType,
        name: peer["name"],
        tcr: peer["tcr"]["today"],
        tcrFrom: peer["tcr"][timeframe === "today" ? "yesterday" : timeframe],
      };
    }

    return this._preparePeerWithChildren(peers, section, prepareItem);
  }

  prepareSectorUpgradesDowngrades(peers, section) {
    function prepareItem(peer, section, itemType) {
      var timeframe = section["content"]["timeframe"];

      var downgrades = peer["alerts"][timeframe]["downgrades"];
      var upgrades = peer["alerts"][timeframe]["upgrades"];
      var ratio = peer["alerts"][timeframe]["ratio"];

      // same logic in
      // app/pages/analysisMarkets/widgets/PeerChildren
      // should be unified
      ratio = ratio < 0 || ratio >= 1000 ? null : ratio;

      return {
        downgrades: downgrades,
        itemType: itemType,
        name: peer["name"],
        ratio: ratio,
        upgrades: upgrades,
      };
    }

    return this._preparePeerWithChildren(peers, section, prepareItem);
  }

  prepareTcr(peer, section) {
    var prepared = {
      cardinality: 0,
      downgrades: 0,
      ratingWeights: {
        2: 0,
        1: 0,
        "-1": 0,
        "-2": 0,
      },
      size: null,
      tcr: null,
      upgrades: 0,
      peerType: peer.type,
    };

    prepared["cardinality"] = peer["info"]["cardinality"];

    var dataType = "percentage";
    var timeframe = "today";
    prepared["ratingWeights"]["2"] =
      peer["statistic"]["ratings"][timeframe]["A"][dataType];
    prepared["ratingWeights"]["1"] =
      peer["statistic"]["ratings"][timeframe]["B"][dataType];
    prepared["ratingWeights"]["-1"] =
      peer["statistic"]["ratings"][timeframe]["C"][dataType];
    prepared["ratingWeights"]["-2"] =
      peer["statistic"]["ratings"][timeframe]["D"][dataType];

    if (peer["type"] === "ETF") {
      const rawTaxonomies = this.storage.peers.environment["taxonomies"];
      const txFields = this.storage.peers.environment["taxonomyFields"];

      const etfSubtype = rawTaxonomies[txFields["ETF"]["subtype"]];

      if (etfSubtype[peer["size"]]) {
        prepared["size"] = etfSubtype[peer["size"]]?.["name"];
      }
    } else {
      prepared["size"] = peer["size"];
    }

    prepared["tcr"] = peer["tcr"][timeframe];

    dataType = "number";
    timeframe = section["content"]["timeframe"];
    prepared["downgrades"] =
      peer["statistic"]["downgrades"][timeframe][dataType];
    prepared["upgrades"] = peer["statistic"]["upgrades"][timeframe][dataType];

    return prepared;
  }
  // ----------------------------------------------------- private methods
  async getPeerUsingSegment(peer, section) {
    const peerId = peer.id;
    const segment = section?.["content"]?.["segment"] ?? "1 Industry";

    const requests: any = {
      children:
        segment !== "3 Level"
          ? this.storage.getChildren(peerId, segment)
          : this.storage.getChildrenSizes(peerId, segment),
      childrenType: segment,
      what: null,
      where: null,
    };

    const response = await httpAll(requests);
    return this._response(response);
  }

  async _getPeerWithChildren(peer, section) {
    var peerId = peer.id;
    var peerInfo = this.storage.getWhatWhereInfo(peerId);
    var whereType = peerInfo.whereType;

    var requests: any = {
      children: null,
      childrenType: null,
      what: null,
      where: null,
    };

    switch (section["type"]) {
      case "REPORT_PEER_WHERE_AB_CHANGES":
      case "REPORT_PEER_WHERE_OVERVIEW":
      case "REPORT_PEER_WHERE_TCR_CHANGES":
      case "REPORT_PEER_WHERE_UPGRADES_DOWNGRADES": {
        switch (whereType) {
          case "Area": {
            requests["children"] = this.storage.getChildren(peerId, "Region");
            requests["childrenType"] = "Region";

            break;
          }
          case "Country": {
            requests["childrenType"] = "Country";
            requests["where"] = this.storage.getById(peerId);

            break;
          }
          case "Region": {
            requests["children"] = this.storage.getChildren(peerId, "Country");
            requests["childrenType"] = "Country";

            break;
          }
          case "World":
          default: {
            requests["children"] = this.storage.getChildren(peerId, "Area");
            requests["childrenType"] = "Area";
          }
        }

        break;
      }
      case "REPORT_PEER_DISPERSION": {
        // not managed here. See getDispersion() method

        break;
      }
      case "REPORT_PEER_DISPERSION_CHILDREN": {
        // not managed here. See getDispersionChildren() method

        break;
      }
      case "REPORT_PEER_WHAT_AB_CHANGES":
      case "REPORT_PEER_WHAT_OVERVIEW":
      case "REPORT_PEER_WHAT_TCR_CHANGES":
      case "REPORT_PEER_WHAT_UPGRADES_DOWNGRADES": {
        //
        // Sectors have type "1 Industry"
        // Industries have type "3 Sector"
        //
        switch (peerInfo["whatType"]) {
          case "0 root": {
            // ICB
            const hasChildren =
              section["content"]["headline"]["hasChildren"] ||
              section["content"]["hasChildren"];

            if (hasChildren) {
              requests["children"] = this.storage
                .getChildren(peerId, "1 Industry")
                .then((response) => {
                  var industriesRequests = {};
                  for (var i = 0; i < response.data.length; i++) {
                    industriesRequests[response.data[i]["id"]] =
                      this.storage.getChildren(
                        response.data[i]["id"],
                        "3 Sector"
                      );
                  }

                  return httpAll(industriesRequests).then(
                    (industriesResponse) => {
                      for (var i = 0; i < response.data.length; i++) {
                        response.data[i]["children"] =
                          industriesResponse[response.data[i]["id"]]["data"];
                      }
                      return response;
                    }
                  );
                });
            } else {
              requests["children"] = this.storage.getChildren(
                peerId,
                "1 Industry"
              );
            }
            requests["childrenType"] = "1 Industry";

            break;
          }
          case "1 Industry": {
            // Sector
            requests["children"] = this.storage.getChildren(
              peerId,
              "3 Sector" // Industries
            );
            requests["childrenType"] = "1 Industry";
            requests["what"] = peer;

            break;
          }
          case "3 Sector": {
            // Industry
            requests["childrenType"] = "3 Sector";
            requests["what"] = this.storage.getById(peerId);

            break;
          }
          default: {
          }
        }

        break;
      }
      default: {
        // nothing to do
      }
    }

    const response = await httpAll(requests);
    return this._response(response);
  }

  _response(response) {
    var data: any = {
      children: null,
      childrenType: null,
      what: null,
      where: null,
    };

    if (response["children"] != null) {
      data["children"] = response["children"]["data"];
    }

    if (response["childrenType"] != null) {
      data["childrenType"] = response["childrenType"];
    }

    if (response["what"] != null) {
      data["what"] = response["what"];
    }

    if (response["where"] != null) {
      data["where"] = response["where"];
    }

    return data;
  }

  _preparePeerWithChildren(data, section, functionPrepareItem) {
    var prepared: any = {
      items: [],
      childrenType: null,
    };

    if (data["what"] != null) {
      // first item to insert if available
      prepared["items"].push(
        functionPrepareItem(data["what"], section, "main")
      );
    }

    if (data["where"] != null) {
      // first item and the only one to insert if available
      prepared["items"].push(
        functionPrepareItem(data["where"], section, "main")
      );
    }

    if (data["children"] != null) {
      var peers = data["children"];
      for (let i = 0, length = peers.length; i < length; i++) {
        const peer = peers[i];
        if (peer["children"] != null) {
          prepared["items"].push(functionPrepareItem(peer, section, "main"));
          var peerChildren = null;
          for (var j = 0, lengthJ = peer["children"].length; j < lengthJ; j++) {
            peerChildren = peer["children"][j];
            prepared["items"].push(
              functionPrepareItem(peerChildren, section, "sub")
            );
          }
        } else {
          prepared["items"].push(
            functionPrepareItem(
              peer,
              section,
              data["what"] == null ? "normal" : "sub"
            )
          );
        }
      }
    }

    if (data["childrenType"] != null) {
      prepared["childrenType"] = data["childrenType"];
    }

    const segment = section?.["content"]?.["segment"] ?? null;

    if (segment && segment === "3 Level") {
      const sizeOrder = ["Large", "Mid", "Small", "Micro"];
      const orderedItems: any = [];
      const items = prepared["items"];

      for (const element of sizeOrder) {
        const match = items.find((el) => el.name === element);

        if (match) {
          orderedItems.push(match);
        }
      }

      prepared["items"] = orderedItems;
    }

    return prepared;
  }

  async trendsTracker(target, section) {
    const response = await this.storage.trendsTracker(target.id);

    return response;
  }
}
